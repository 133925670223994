import {
  Row, Col, Space, Select,
} from 'antd';
import lodash from 'lodash';
import cn from 'classnames';
import { PlusOutlined, FilterFilled, ReloadOutlined } from '@ant-design/icons/lib/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import Input from 'antd/lib/input/Input';
import style from './pricelist.module.css';

import CustomButton from '../../components/CustomButton/CustomButton';
import PriceTable from './PriceTable';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestPricelists, setPricelistsData } from '../../store/redux_slice/pricelists/pricelistsSlice';
import Loader from '../../components/Loader';
import AssociateModal from './AssociateModal/AssociateModal';
import { requestResetFilter, requestSetFilter } from '../../store/redux_slice/pricelists/filterSlice';
import { PathEnums } from 'enums';

const Pricelist = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const [isAssociate, setIsAssociate] = useState<boolean>(false);
  const [pricelistId, setPricelistId] = useState<number | null>(null);
  const [assignedUsers, setAssignedUsers] = useState<string[]>([]);
  const pricelists = useAppSelector(
    (state) => state.pricelists.pricelists,
  );
  const filterPrices = useAppSelector((state) => state.filterPriceList.filter);

  useEffect(() => {
    dispatch(requestPricelists());
    dispatch(requestResetFilter());
    return () => {
      dispatch(setPricelistsData(null));
    };
  }, [dispatch]);

  if (!pricelists) {
    return <Loader/>;
  }

  return (
    <div className={style.pricelist_container}>
      <Row className={style.height_container}>
        <Col span={12}>
          <h1 className={style.bold}>
            {`${t('pricelist.pricelist')} (${pricelists.items.length})`}
          </h1>
          <div className={style.filter_container}>
            <Input
              className={style.search}
              placeholder={t('pricelist.search')}
              onChange={(e) => dispatch(requestSetFilter({
                search: e.target.value,
              }))}
              value={filterPrices.search}
            />
            <CustomButton
              className={cn(style.searchBtn, style.btn)}
              icon={<FilterFilled/>}
              onClick={() => setIsAdvanced(!isAdvanced)}
            >
              {t('pricelist.advancedSearch')}
            </CustomButton>
            <CustomButton
              className={cn(style.resetBtn, style.btn)}
              icon={<ReloadOutlined/>}
              onClick={() => {
                dispatch(requestResetFilter());
              }}
            >
              {t('pricelist.reset')}
            </CustomButton>
          </div>
        </Col>
        <Col span={4} offset={8}>
          <CustomButton
            type="primary"
            icon={<PlusOutlined/>}
            className={style.newQuoteBtn}
            href={PathEnums.New_pricelist}
          >
            {t('pricelist.newPricelist')}
          </CustomButton>
        </Col>
      </Row>
      {isAdvanced && (
        <Space className={style.advancedSearch}>
          <Space direction="vertical">
            <p className={style.select}>{t('pricelist.creationDate')}</p>
            <Select
              className={style.filter_select}
              value={filterPrices.date}
              options={[...lodash.uniq(pricelists.items
                .map((pricelist) => format(new Date(Date.parse(pricelist.createdAt)), 'dd-MM-yyyy')))
                .sort()
                .map((createdAt, index) => ({
                  title: createdAt,
                  value: createdAt,
                }))]
              }
              onChange={(date) => dispatch(requestSetFilter({
                date,
              }))}
            />
          </Space>
          <Space direction="vertical">
            <p className={style.select}>{t('pricelist.data')}</p>
            <Select
              className={style.filter_select}
              value={filterPrices.users_count}
              options={[...lodash.uniq(pricelists.items
                .map((pricelist) => pricelist.users_count))
                .map((usersCount) => ({
                  title: usersCount,
                  value: usersCount,
                }))]}
              onChange={(v) => dispatch(requestSetFilter({
                users_count: Number.isNaN(+v) ? '-' : +v,
              }))}
            />
          </Space>
          <Space direction="vertical">
            <p className={style.select}>{t('pricelist.shipmentsGenerated')}</p>
            <Select
              className={style.filter_select}
              value={filterPrices.shipments_count}
              options={[...lodash.uniq(pricelists.items
                .map((pricelist) => pricelist.shipments_count))
                .map((shipmentsCount) => ({
                  title: shipmentsCount,
                  value: shipmentsCount,
                }))]
              }
              onChange={(v) => dispatch(requestSetFilter({
                shipments_count: Number.isNaN(+v) ? '-' : +v,
              }))}
            />
          </Space>
        </Space>
      )}
      <AssociateModal
        setIsAssociate={setIsAssociate}
        isAssociate={isAssociate}
        pricelistId={pricelistId}
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
        isSendRequest={true}
      />
      <PriceTable
        pricelists={pricelists}
        setIsAssociate={setIsAssociate}
        setPricelistId={setPricelistId}
      />
    </div>
  );
};

export default Pricelist;
