import React from 'react';
import { EstimationFieldModal, useModalState } from '../Estimations/hooks';
import { ContractorSelect } from '../../components/ContractorSelect/ContractorSelect';
import { EditContractorModal } from '../../components/ContractorSelect/EditContractorModal';
import { ContractorType } from '../../store/types';

interface Props {
  initialValue: string | number;
  onChange: (contractorId: string | number) => void;
  addPallex?: boolean;
  contractors: ContractorType[];
}

export const ContractorsSelect: React.FC<Props> = ({
  initialValue, addPallex, onChange, contractors,
}) => {
  const {
    openModal,
    closeModal,
    isOpen,
  } = useModalState();

  const onEditHandler = () => {
    openModal(EstimationFieldModal.openContractor);
  };

  return (
    <>
      <ContractorSelect
        initialValue={initialValue}
        addPallex={addPallex}
        onChange={onChange}
        onEdit={onEditHandler}
        contractors={contractors}
      />
      {
        EstimationFieldModal.openContractor && <EditContractorModal
          visible={isOpen(EstimationFieldModal.openContractor)}
          onCancel={() => {
            closeModal();
          }}
        />
      }
    </>
  );
};
