import { createAction } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import { put, takeLatest } from 'redux-saga/effects';
import { PostDownloadQrCodes } from '../../api/shipping';
import { setMessage } from '../redux_slice/messageErrorSlice';

export const requestDownloadQrCodesAction = createAction<string>('requestDownloadQrCodesFromPrivateAreaAction');

export function* downloadQrCodesForShippingRequestSaga(action: {
  payload: string
}) {
  try {
    const { data } = yield PostDownloadQrCodes(action.payload);
    data.forEach((item) => {
      window.open(item, '_blank');
      window.focus();
      const splitLink = item.split('/');
      const fileName = splitLink[splitLink.length - 1];
      saveAs(item, fileName);
    });
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

export function* downloadQrCodesSaga() {
  yield takeLatest(requestDownloadQrCodesAction, downloadQrCodesForShippingRequestSaga);
}
