import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import React from 'react';
import OptionsButton from '../../components/OptionsButton';
import { makeTransportsTableColumns } from './TableColumns';
import { TransportStructure } from '../../store/types';
import { requestGetTransportById } from '../../store/redux_slice/transport/transportByIdSlice';
import { useAppDispatch } from '../../store/store';

const TransportsTable: React.FC<{
    transport: TransportStructure[],
    setIsTransportForm: (bool: number | null) => void,
    setSelectId: (id: string) => void,
    setIsDeleteModal: (bool: boolean) => void,
    setIsUnavailableModal: (bool: boolean) => void,
    contractorsFilter: string,
    numberPalletFilter: string,
    unavailableFilter: boolean | null,
    search: string,
}> = ({
  transport, setIsTransportForm, setIsDeleteModal, setSelectId, setIsUnavailableModal,
  contractorsFilter, unavailableFilter, numberPalletFilter, search,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const openUpdateUserForm = (id: string) => {
    setIsTransportForm(Date.now());
    dispatch(requestGetTransportById(id));
  };
  const openDeleteModal = (id: string) => {
    setIsDeleteModal(true);
    setSelectId(id);
  };
  const openUnavailableModal = (id: string) => {
    setIsUnavailableModal(true);
    setSelectId(id);
  };
  const optionsNotBusy = [
    {
      title: t('forms.transports.make_unavailable'),
      action: (id: string) => openUnavailableModal(id),
    },
    {
      title: t('forms.transports.update'),
      action: (id: string) => openUpdateUserForm(id),
    },
    {
      title: t('forms.transports.delete'),
      action: (id: string) => openDeleteModal(id),
    },
  ];
  const optionsBusy = [
    {
      title: t('forms.transports.update'),
      action: (id: string) => openUpdateUserForm(id),
    },
    {
      title: t('forms.transports.delete'),
      action: (id: string) => openDeleteModal(id),
    },
  ];
  const dataSource = transport
    .filter((mezzo) => {
      const { plate_number: plateNumber, driver, note } = mezzo;
      let isValid = true;
      if (!note?.toLowerCase().includes(search.toLowerCase())
          && !plateNumber?.toLowerCase().includes(search.toLowerCase())
          && !driver?.name?.toLowerCase().includes(search.toLowerCase())
      ) {
        return false;
      }
      if (contractorsFilter) {
        isValid = isValid && mezzo.contractor === contractorsFilter;
      } if (numberPalletFilter) {
        isValid = isValid && mezzo.pallet_capacity === +numberPalletFilter;
      } if (unavailableFilter !== null) {
        isValid = isValid && mezzo.busy === unavailableFilter;
      } return isValid;
    })
    .map((i, k) => (
      {
        key: i.id,
        license_plate: i.plate_number,
        type: i.truck_type?.type,
        description: i.note,
        drivers_name: i.driver?.name,
        contractor: i.contractor,
        number_pallets_max: i.pallet_capacity,
        availability: { busy: i.busy, busy_days: i.booking_date },
        options: <OptionsButton id={i.id.toString()} options={i.busy ? optionsBusy : optionsNotBusy}/>,
        date_create: i.createdAt,
      }
    ))
    .sort((a, b) => b.date_create.localeCompare(a.date_create));
  const columns = makeTransportsTableColumns(t);
  return (
    <Table columns={columns} dataSource={dataSource} pagination={false}/>
  );
};

export default TransportsTable;
