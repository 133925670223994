import {
  PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip,
} from 'recharts';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import { useAppSelector } from '../../store/store';
import style from './dashboard.module.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF5042'];

const LegendWithValues = (legend) => (
  legend.payload.map((item) => (
    <div
      key={item.value}
      className={style.legend_content}
      style={{ color: item.color }}
    >
      {item.value} {' '}-{' '}
      <strong>{item.payload.qty}</strong>
    </div>
  ))
);

const ChartTotalNumberOfQuotes = () => {
  const { archivedLoding, archivedData } = useAppSelector((state) => state.dashboards);
  if (archivedLoding) return <Loader/>;

  if (!archivedData?.data.length) return <NoData/>;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={archivedData?.data}
          labelLine={false}
          dataKey="qty"
        >
          {archivedData?.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
          ))}
        </Pie>
        <Tooltip />
        <Legend
          align="left"
          verticalAlign="middle"
          layout="vertical"
          wrapperStyle={{ left: 150 }}
          content={LegendWithValues}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ChartTotalNumberOfQuotes;
