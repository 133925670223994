import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  Col, Form, Row,
} from 'antd';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import CustomTabs from 'components/CustomTabs';
import CustomButton from 'components/CustomButton/CustomButton';
import DeleteModal from 'components/DeleteModal';
import Loader from 'components/Loader';
import UsersTable from './UsersTable';
import NewUserForm from './NewUserForm';
import UserProfile from './UserProfile';
import UsersFilter from './UsersFilter';
import { useAppDispatch, useAppSelector } from 'store/store';
import { requestUsersList, setUsersList } from 'store/redux_slice/users/usersListSlice';
import { requestGetPriceListTemplates } from 'store/redux_slice/priceListTemplatesSlice';
import {
  requestCreateUser, requestDeleteUser, clearUser, requestUpdateUser, requestGetAccessPrivate,
} from 'store/redux_slice/users/userSlice';
import { FilterUsers } from 'store/types';
import { requestUsersCompanyList } from 'store/redux_slice/users/usersCompanyListSlice';
import { RoleEnums, UserTypology } from 'enums';
import InviteModal from './InviteModal';
import style from './users.module.css';

const UsersContainer = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState('tab1');
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [typology, setTypology] = useState<string>(UserTypology.Company);
  const [isUserForm, setIsUserForm] = useState<boolean>(false);
  const [isUserProfile, setIsUserProfile] = useState<boolean>(false);
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [isInviteModal, setIsInviteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string| null>(null);
  const [userId, setUserId] = useState<string>('');
  const [filter, _setFilter] = useState<Partial<FilterUsers>>({});
  const user = useAppSelector((state) => state.user?.user);
  const usersList = useAppSelector((state) => state.usersList.usersList);
  const priceListTemplates = useAppSelector((state) => state.priceListTemplates?.priceListTemplates);
  const usersCompanyList = useAppSelector((state) => state.usersCompanyList.usersCompanyList);
  const [form] = Form.useForm();
  const setFilter = (data) => {
    _setFilter(data);
    setPage(1);
  };
  useEffect(() => {
    dispatch(requestUsersList({
      page, page_size: pageSize, typology, filter,
    }));
  }, [dispatch, page, pageSize, typology, filter]);
  useEffect(() => {
    dispatch(requestGetPriceListTemplates());
  }, [dispatch]);
  useEffect(() => {
    dispatch(requestUsersCompanyList({ typology: 'Company' }));
  }, [dispatch]);
  useEffect(() => {
    if (searchParams.get('utente_id')) {
      setIsUserProfile(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get('utente_id') && user?.typology === 'Company') {
      setActiveTab('tab1');
    }
    if (searchParams.get('utente_id') && user?.typology === 'Private_person') {
      setActiveTab('tab2');
    }
  }, [searchParams, user?.typology]);

  useEffect(() => () => {
    if (isUserForm) {
      if (!searchParams.get('utente_id')) {
        setIsUserForm(false);
        dispatch(clearUser());
      }
    }
  }, [dispatch, usersList, searchParams, isUserForm]);

  useEffect(() => () => {
    dispatch(setUsersList(null));
  }, [dispatch]);

  if (!usersList || !usersCompanyList || !priceListTemplates) {
    return <Loader/>;
  }
  const createNewUser = async (values) => {
    const data = {
      typology: values.pickup_typology,
      email: values.email,
      phone: values.phone,
      name: values.business_name,
      codica_cliente: values.codica_cliente || null,
      vat_number: values.vat_number,
      address: {
        ...values.pickup_address.location,
        post_code: values.pickup_address_post_code,
        full_address: [
          values.pickup_address_street,
          values.pickup_address.location.city,
          values.pickup_address.location.region,
        ].join(', '),
      },
      note: values.note,
      price_list_id: values.price_list,
      pallex_company_id: values.pallex_name || null,
      pallexId: user?.codica_cliente !== values.codica_cliente ? null : user?.pallexId,
    };
    if (user) {
      const updateData = {
        id: user?.id,
        data,
      };
      dispatch(requestUpdateUser({ user: updateData, typology }));
    } else {
      const createData = {
        ...data,
        role: values.private_area ? RoleEnums.USER : RoleEnums.GUEST,
      };
      dispatch(requestCreateUser({ user: createData, typology }));
    }
  };
  const onDeleteUser = () => {
    setIsDeleteModal(false);
    if (isUserProfile) {
      setIsUserProfile(false);
    }
    dispatch(requestDeleteUser({ id: deleteId ?? '', typology }));
  };
  const onCancelNewUserForm = () => {
    setIsUserForm(false);
    dispatch(clearUser());
  };
  const onCancelUserProfile = () => {
    setIsUserProfile(false);
    dispatch(clearUser());
    searchParams.delete('utente_id');
    setSearchParams(searchParams);
  };
  const onChangePageSize = (current, size) => {
    setPageSize(size);
  };
  const sentInviteToUsersArea = () => {
    const findUser = usersList?.items.find((el) => el.id.toString() === userId);
    if (findUser) {
      dispatch(requestGetAccessPrivate({ email: findUser?.email, typology }));
    }
    setIsInviteModal(false);
  };
  const pagination = {
    current: page,
    pageSize,
    pageSizeOptions: [10, 15, 20],
    showSizeChanger: true,
    total: typology === UserTypology.Company ? usersList?.countCompany : usersList?.countPrivatePerson,
    onChange: setPage,
    onShowSizeChange: onChangePageSize,
  };
  return (
    <>
      <DeleteModal
        title={t('forms.users.delete_client')}
        description={t('forms.users.delete_client_description')}
        visible={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        onDelete={onDeleteUser}/>
      <InviteModal
        title={t('forms.users.invite_title')}
        description={t('forms.users.invite_description')}
        submitText={t('forms.users.invite_submit')}
        cancelText={t('forms.users.invite_cancel')}
        visible={isInviteModal}
        onCancel={() => setIsInviteModal(false)}
        onInvite={sentInviteToUsersArea}
      />
      <NewUserForm
        form={form}
        user={user}
        visible={isUserForm}
        onFinish={createNewUser}
        onCancel={onCancelNewUserForm}
        usersCompanyList={usersCompanyList?.items}
      />
      <UserProfile
        visible={isUserProfile}
        typology={typology}
        onCancel={onCancelUserProfile}
        setIsDeleteModal={setIsDeleteModal}
        setDeleteId={setDeleteId}
        setIsUserForm={setIsUserForm}
        usersCompanyList={usersCompanyList.items}
      />
      <div className={style.users_container}>
        <Row className={style.margin_container}>
          <Col span={12}>
            <CustomTabs
              activeTab={activeTab}
              setActiveTab={(tab) => {
                setActiveTab(tab);
              }}
              setChange={setTypology}
              leftText={t('forms.users.companies')}
              leftNumber={usersList.countCompany}
              rightText={t('forms.users.private')}
              rightNumber={usersList.countPrivatePerson}
              setPage={setPage}
            />
            <UsersFilter
              setFilter={setFilter}
            />
          </Col>
          <Col span={4} offset={8}>
            <CustomButton
              type="primary"
              icon={<PlusOutlined/>}
              className={style.newUserBtn}
              onClick={() => setIsUserForm(true)}
            >
              {t('forms.users.new_client')}
            </CustomButton>
          </Col>
        </Row>
        <UsersTable
          usersList={usersList?.items}
          typology={typology}
          onOpenProfile={() => setIsUserProfile(true)}
          setIsDeleteModal={setIsDeleteModal}
          setDeleteId={setDeleteId}
          setIsUserForm={setIsUserForm}
          pagination={pagination}
          setUserId={setUserId}
          setIsInviteModal={setIsInviteModal}
        />
      </div>
    </>
  );
};

export default UsersContainer;
