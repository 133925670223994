import { Contractor, NewContractor } from '../../store/types';

export interface ContractorUpdateDto {
  name: string,
  email: string,
  phone: string,
  vat_number: string,
  address: string,
  fiscal_number: number,
  is_address_different: boolean,
}

export class ContractorDTOMapper {
  static toCreateDto(contractor: NewContractor): ContractorUpdateDto {
    return {
      name: contractor.name,
      email: contractor.email,
      phone: contractor.phone || '',
      vat_number: contractor.vat_number,
      address: contractor.address,
      fiscal_number: +contractor.fiscal_number,
      is_address_different: contractor.is_address_different || false,
    };
  }

  static toUpdateDto(contractor: Contractor): ContractorUpdateDto {
    return {
      name: contractor.name,
      email: contractor.email,
      phone: contractor.phone,
      vat_number: contractor.vat_number,
      address: contractor.address,
      fiscal_number: contractor.fiscal_number,
      is_address_different: contractor.is_address_different,
    };
  }
}
