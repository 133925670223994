import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TrucksStructure, ShipmentStructure, TripStructure, ManifestoUnitedType,
  PostAssignTripPayload, PatchTripPayload, DeleteTripPayload, SplittetPalletsPayloadActionType,
  GetFileDownloadPayload, GetTrucksScaricoPayloadType, TrucksFilterType,
} from '../types';

export const requestGetTransportTrucksAction = createAction<GetTrucksScaricoPayloadType>('requestGetTransportTrucksAction');
export const requestPostAssignTripToTruckAction = createAction<PostAssignTripPayload>('requestPostAssignTripToTruckAction');
export const requestPatchTripAction = createAction<PatchTripPayload>('requestPatchTripAction');
export const requestGetTripByIdAction = createAction<number>('requestGetTripByIdAction');
export const requestSplitTripAction = createAction<SplittetPalletsPayloadActionType>('requestSplitTrip');
export const requestDeleteTripByIdAction = createAction<DeleteTripPayload>('requestDeleteTripByIdAction');
export const requestGetTripCsvAction = createAction<GetFileDownloadPayload>('requestGetTripCsvAction');
export const requestGetTripPdfAction = createAction<GetFileDownloadPayload>('requestGetTripPdfAction');

type State = {
  loading: boolean,
  trucks: TrucksStructure[] | null,
  tripsToStart: ShipmentStructure[] | null,
  trucksFilter: TrucksFilterType | null,
  trip: TripStructure | null,
  tripWithoutTruck: ShipmentStructure | null,
  tripLoading: boolean,
}

const initialState: State = {
  loading: false,
  trucks: null,
  tripsToStart: null,
  trucksFilter: null,
  trip: null,
  tripWithoutTruck: null,
  tripLoading: false,
};

const tripsSlice = createSlice({
  name: 'tripsSlice',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTripLoading: (state, action: PayloadAction<boolean>) => {
      state.tripLoading = action.payload;
    },
    setTrucksData: (state, action: PayloadAction<ManifestoUnitedType>) => {
      const newTrucksArr = action.payload.trucks.map((item) => ({
        ...item,
        color: `#${(`${Math.random().toString(16)}000000`).substring(2, 8).toUpperCase()}`,
      }));
      state.trucks = newTrucksArr;
      state.tripsToStart = action.payload.to_start;
    },
    setTruckFilter: (state) => {
      if (!state.trucks || !state.tripsToStart) return;
      const filterTrucks = state.trucks.map(({
        plate_number: plateNumber, id, pallet_capacity: palletCapacity, trips,
      }) => ({
        plateNumber,
        id,
        palletCapacity,
        palletQty: trips
          .map((tripItem) => tripItem.shipping_request.pallets
            .filter((palletItem) => {
              const truckIdDeliveryType = `truck_id_${tripItem.delivery_type?.toLowerCase()}`;
              const isDeliveredDeliveryType = `is_delivered_${tripItem.delivery_type?.toLowerCase()}`;
              if (tripItem.date_end) {
                return palletItem[isDeliveredDeliveryType];
              }
              return palletItem[truckIdDeliveryType] === id && !palletItem[isDeliveredDeliveryType];
            }))
          .flat()
          .reduce((summ, cur) => summ + +cur.item_quantity, 0),
      }));

      if (state.trucksFilter) {
        state.trucksFilter = {
          ...state.trucksFilter,
          truckInfo: filterTrucks,
        };
      } else {
        const mappedTrucks = state.trucks.map((item) => item.trips).flat().map((item) => item.shipping_request);
        const trackingCodeArray = [...state.tripsToStart, ...mappedTrucks].map((item) => ({
          value: item.tracking_code, label: item.tracking_code,
        })).filter((obj, index, self) => index === self.findIndex((o) => o.value === obj.value));

        const orderIdArray = [...state.tripsToStart, ...mappedTrucks].map((item) => ({
          value: item.spedizione_id, label: item.spedizione_id,
        })).filter((obj, index, self) => index === self.findIndex((o) => o.value === obj.value));
        state.trucksFilter = {
          truckInfo: filterTrucks,
          trackingCodeFilter: trackingCodeArray,
          orderIdFilter: orderIdArray,
        };
      }
    },
    clearManifestoData: (state) => {
      state.tripsToStart = null;
      state.trucks = null;
      state.trucksFilter = null;
    },
    setTripById: (state, action: PayloadAction<TripStructure>) => {
      state.trip = action.payload;
      state.tripWithoutTruck = null;
    },
    setTripWithoutTruck: (state, action: PayloadAction<ShipmentStructure>) => {
      state.tripWithoutTruck = action.payload;
      state.trip = null;
    },
  },
});

export const {
  setLoading, setTrucksData,
  setTripById, setTripLoading, clearManifestoData,
  setTripWithoutTruck, setTruckFilter,
} = tripsSlice.actions;

export default tripsSlice.reducer;
