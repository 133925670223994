import {
  useEffect, useMemo, useState, createContext, useCallback,
} from 'react';
import { Modal, Row, Col } from 'antd';
import { format } from 'date-fns';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../../../components/DeleteModal';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Loader from '../../../components/Loader';
import BodyContent from './BodyContent';
import { useAppSelector, useAppDispatch } from '../../../store/store';
import { requestDeleteTripByIdAction, requestSplitTripAction } from '../../../store/redux_slice/tripsSlice';
import { Pallet, SplittedPalletsDataType, ScaricoFilterType } from '../../../store/types';
import { checkIsSplit, inPendingDelivery, getPalletsSumm } from '../utils';
import SuccessIconSvg from '../../../assests/svg/SuccessIconSvg';
import styles from './DetailsModal.module.css';
import './ant.css';

type Props = {
  visible: boolean,
  setVisibleModal: (bool: boolean) => void,
  scaricoFilter: ScaricoFilterType,
}

type SplitPalletsContextType = {
  splitPallets: boolean,
  splittedPalletInfo: SplittedPalletsDataType[]
  setPalletsData: (newState) => void,
};

export const SplitPalletsContext = createContext<SplitPalletsContextType>({
  splitPallets: false,
  splittedPalletInfo: [],
  setPalletsData: () => { /* */ },
});

const DetailsModal = ({ visible, setVisibleModal, scaricoFilter }: Props) => {
  const { t } = useTranslation();
  const [palletsData, setPalletsData] = useState<SplittedPalletsDataType[] | []>([]);
  const [splitPallets, setSplitPallets] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [copyExtraPallets, setCopyExtraPallets] = useState<Pallet[]>([]);

  const { tripLoading, trip, tripWithoutTruck } = useAppSelector((state) => state.unloadingTrips);
  const dispatch = useAppDispatch();

  const shipmentInformation = useMemo(() => trip?.shipping_request || tripWithoutTruck, [trip, tripWithoutTruck]);

  const palletSum = useMemo(() => (
    shipmentInformation ? getPalletsSumm(shipmentInformation?.pallets, trip) : 0
  ), [shipmentInformation, trip]);

  const closeDetailsModal = useCallback(() => {
    setVisibleModal(false);
    setSplitPallets(false);
  }, [setVisibleModal]);

  useEffect(() => {
    if (shipmentInformation && !splitPallets) {
      setPalletsData([{
        truck_id: trip?.truck_id || null,
        shipping_id: shipmentInformation.id,
        pallets: trip?.truck_id
          ? shipmentInformation.pallets.filter((item) => {
            const truckIdDeliveryType = `truck_id_${trip.delivery_type?.toLowerCase()}`;
            const isDeliveredDeliveryType = `is_delivered_${trip.delivery_type?.toLowerCase()}`;
            if (trip.date_end) {
              return item[isDeliveredDeliveryType];
            }
            return item[truckIdDeliveryType] === trip.truck_id && !item[isDeliveredDeliveryType];
          })
          : shipmentInformation.pallets,
        delivery_type: trip?.delivery_type || shipmentInformation.delivery_type,
        date_end: trip ? trip?.date_end : null,
      }]);
      if (trip?.truck_id) {
        setCopyExtraPallets(shipmentInformation.pallets.filter((item) => {
          const truckIdDeliveryType = `truck_id_${trip.delivery_type?.toLowerCase()}`;
          const isDeliveredDeliveryType = `is_delivered_${trip.delivery_type?.toLowerCase()}`;
          return (item[isDeliveredDeliveryType]
            && (item[truckIdDeliveryType] === trip.truck_id || item[truckIdDeliveryType] !== trip.truck_id))
          || item[truckIdDeliveryType] !== trip.truck_id;
        }));
      }
    }
  }, [shipmentInformation, splitPallets, trip]);

  const checkPossibilityToSplit = useCallback(() => {
    if (palletsData.length > 1) {
      for (let i = 0; i < palletsData.length; i += 1) {
        if (!palletsData[i].truck_id) return false;
        if (!palletsData[i].pallets.length) return false;
      }
      return true;
    }
    return false;
  }, [palletsData]);

  useEffect(() => {
    if (checkPossibilityToSplit()) {
      dispatch(requestSplitTripAction({
        data: palletsData,
        main_trip_id: trip?.id || null,
        copyExtraPallets,
        ...scaricoFilter,
      }));
      closeDetailsModal();
    }
  }, [checkPossibilityToSplit, closeDetailsModal, copyExtraPallets, dispatch, palletsData, scaricoFilter, trip?.id]);

  const splitPalletsHandler = () => {
    setSplitPallets(!splitPallets);
    if (shipmentInformation && !splitPallets) {
      setPalletsData((prevState) => ([
        ...prevState,
        {
          truck_id: null,
          shipping_id: trip ? trip.shipping_id : shipmentInformation.id,
          delivery_type: trip ? trip.delivery_type : shipmentInformation.delivery_type,
          date_end: trip?.date_end || null,
          pallets: [],
        },
      ]));
    }
  };
  if (!shipmentInformation) return null;

  return (
    <>
      <DeleteModal
        title={t('forms.deliveries.delete_shipping')}
        description={t('forms.deliveries.delete_shipping_description')}
        visible={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        onDelete={() => dispatch(requestDeleteTripByIdAction({
          truck_id: trip?.truck_id || 0,
          trip_id: trip?.id || 0,
          ...scaricoFilter,
        }))}
      />
      <Modal
        className={cn(styles.scarico_details_modal_container, 'scarico_details_modal_container')}
        width="630px"
        visible={visible}
        onCancel={() => closeDetailsModal()}
        footer={false}
      >
        { tripLoading ? <Loader/> : (
          <>
            <div className={styles.badge}>
              <h3 className={styles.title}>{t('manifest.order')} {' '}
                #{ shipmentInformation?.spedizione_id || shipmentInformation?.id }
              </h3>
              <span className={cn(styles.badge_text, styles.deliveryType)}>{ trip?.delivery_type }</span>
              <span className={styles.badge_text}>N° pallet: {' '}
                { getPalletsSumm(shipmentInformation?.pallets, trip) }
              </span>
              {inPendingDelivery(trip) && <span className={styles.badge_text}>{t('manifest.on_the_road')}</span>}
            </div>
            <Row className={styles.header}>
              <Col span={7} offset={9} className={styles.description}>
                <span>{t('manifest.startDate')}</span>
                <div className={styles.address}>
                  <span>{shipmentInformation?.pickup_address?.company_name}</span>
                  <span>{shipmentInformation?.pickup_address?.name_and_surname_reference}</span>
                  <span>
                    {shipmentInformation?.pickup_address?.location.full_address
                      ? `${shipmentInformation?.pickup_address?.location.full_address},` : ''
                    }
                    {shipmentInformation?.pickup_address?.location.post_code
                      ? `${shipmentInformation?.pickup_address?.location.post_code},` : ''
                    }
                    {shipmentInformation?.pickup_address?.location.region},{' '}
                    {shipmentInformation?.pickup_address?.location.province}
                  </span>
                </div>
              </Col>
              <Col span={7} offset={1} className={styles.description}>
                <span>{t('manifest.withdrawalDate')}</span>
                <div className={styles.address}>
                  <span>{shipmentInformation?.shipping_address?.company_name}</span>
                  <span>{shipmentInformation?.shipping_address?.name_and_surname_reference}</span>
                  <span>
                    {shipmentInformation?.shipping_address?.location.full_address
                      ? `${shipmentInformation?.shipping_address?.location.full_address},` : ''
                    }
                    {shipmentInformation?.shipping_address?.location.post_code
                      ? `${shipmentInformation?.shipping_address?.location.post_code},` : ''
                    }
                    {shipmentInformation?.shipping_address?.location.region},{' '}
                    {shipmentInformation?.shipping_address?.location.province}
                  </span>
                </div>
              </Col>
            </Row>
            { trip?.date_end && (
              <div className={styles.d_flex_end}>
                <div className={styles.success_badge}>
                  <SuccessIconSvg color="#fff" />
                  <div className={styles.success_badge_content}>
                    <span className={styles.status}>{ trip.status }</span>
                    <span className={styles.badge_date}>
                      {format(new Date(trip.date_end), 'dd-MM-yyy')} {' '}|{' '}
                      {format(new Date(trip.date_end), 'HH:mm')}
                    </span>
                  </div>
                </div>
              </div>
            )}
            <SplitPalletsContext.Provider value={{ splitPallets, splittedPalletInfo: palletsData, setPalletsData }}>
              { palletsData.map((item, i) => (
                <BodyContent
                  key={`body-content-${i}`}
                  palletItemData={item}
                  tableIndex={i}
                  completedTrip={!!trip?.date_end}
                  scaricoFilter={scaricoFilter}
                  tripId={trip?.id || null}
                  inPendingDelivery={inPendingDelivery(trip)}
                />
              ))}
            </SplitPalletsContext.Provider>

            <Row>
              <Col span={12}>
                {trip && !inPendingDelivery(trip) && (
                  <CustomButton
                    htmlType="submit"
                    type="primary"
                    onClick={() => setIsDeleteModal(true)}
                    className={styles.modal_btn}
                  >
                    {t('manifest.remove')}
                  </CustomButton>
                )}
              </Col>
              <Col className={styles.d_flex_end} span={6} offset={6}>
                {(!trip?.date_end && checkIsSplit(trip)) && (
                  <CustomButton
                    htmlType="submit"
                    type="primary"
                    className={styles.modal_btn}
                    onClick={splitPalletsHandler}
                    disabled={palletSum < 2}
                  >
                    { splitPallets ? t('manifest.merge') : t('manifest.divide') }
                  </CustomButton>
                )}
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default DetailsModal;
