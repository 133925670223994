import { useEffect } from 'react';
import {
  Row, Col, Modal, DatePicker, Form,
} from 'antd';
import { CalendarFilled } from '@ant-design/icons/lib/icons';
import { format, formatISO } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CalculationBtn from 'components/CalculationBtn';
import { EstimationFieldModal, useModalState } from 'containers/Estimations/hooks';
import { OpenModalButton } from 'containers/Estimations/OpenModalButton';
import { ChangeShippingEstimateModal } from 'containers/Estimations/FieldModals/ChangeShippingEstimateModal';
import Loader from 'components/Loader';
import {
  PalletsTable, ExtraServiceTable, AddebitosTable,
} from 'components/EditableTable/Tables';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from '../TableInputs/CustomInput';
import { inputValuesTypes } from '../../InvoicesTable';
import { useAppSelector } from 'store/store';
import { FinanceSectionType } from 'store/types';
import { DeliveryType } from 'enums';
import styles from './orderDetailsModal.module.css';

type ExtraFinanceModalType = {
  visible: boolean,
  onCancel: () => void,
  orderDetails: FinanceSectionType | null,
  onCalculateOrder: (orderDetails: FinanceSectionType) => void,
  onEditOrderDetails: (data: FinanceSectionType) => void,
  onUpdateOrderDetailsModal: () => void,
  openUserSendModal: (arg: inputValuesTypes) => void,
}

const OrderDetailsModal = ({
  visible, onCancel, orderDetails, onCalculateOrder, onEditOrderDetails,
  onUpdateOrderDetailsModal, openUserSendModal,
}: ExtraFinanceModalType) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { updateDetailsLoading } = useAppSelector((state) => state.financeSection);
  const {
    openModal,
    closeModal,
    isOpen,
  } = useModalState();

  useEffect(() => {
    if (orderDetails) {
      form.setFieldsValue({
        n_ordine: orderDetails.n_ordine || '',
        codice_cliente: orderDetails.codice_cliente || '',
        numero_ddt: orderDetails.numero_ddt || '',
        fluet: orderDetails.fluet || '',
        scontistica: orderDetails.scontistica || '',
        extra: orderDetails.extra.value || '',
        extraSuffix: orderDetails.extra.type || '€',
      });
    }
  }, [form, orderDetails]);

  const onInputChange = (arg: inputValuesTypes) => {
    const value = arg.name === 'extra' ? {
      type: form.getFieldValue('extraSuffix'),
      value: +form.getFieldValue('extra'),
    } : arg.value;
    if (orderDetails) {
      onEditOrderDetails({
        ...orderDetails,
        [arg.name]: value,
      });
    }

    if (arg.name === 'fluet') {
      openUserSendModal(arg);
    }
  };

  const onDateChange = (date) => {
    if (orderDetails) {
      onEditOrderDetails({
        ...orderDetails,
        approvato_il: formatISO(new Date(date)),
      });
    }
  };

  const updateOptionHandler = (data) => {
    if (orderDetails) {
      onEditOrderDetails({
        ...orderDetails,
        ...data,
      });
    }
  };

  const updatePriceHandler = (data) => {
    if (orderDetails) {
      onEditOrderDetails({
        ...orderDetails,
        price: data.price,
      });
    }
  };

  if (!orderDetails) return null;
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      width={'80%'}
      className="custom_modal"
      footer={false}
    >
      { updateDetailsLoading ? <Loader/> : (
        <>
          <ChangeShippingEstimateModal
            id={orderDetails.orderId}
            visible={isOpen(EstimationFieldModal.openPriceModal)}
            onCancel={closeModal}
            shippingEstimate={true}
            estimationCost={orderDetails.price}
            onRequestUpdateShippingEstimate={updatePriceHandler}
          />
          <Row className={styles.modal_head_container}>
            <Col span={11} className={styles.gap_container}>
              <h2 className={cn('main-title-30px', styles.modal_title)}>
                {t('forms.deliveries.shipping')}{' '}
                <span className={styles.shipping_status_id}>
                  #{orderDetails.request.spedizione_id || orderDetails.orderId}
                </span>
              </h2>
              <p className={styles.light_text}>
                {t('forms.deliveries.customer')}:
                <span className={styles.bold_text}>
                  {orderDetails.request?.user?.name || '-'}
                </span>
              </p>
              <p className={styles.light_text}>
                {t('forms.deliveries.approved_date')}:
                <span className={styles.bold_text}>
                  {format(new Date(orderDetails.approvato_il), 'dd-MM-yyyy')}
                </span>
              </p>
              <div className={styles.date_picker_container}>
                <p className={styles.light_text}>{t('invoices.to_be_invoices')}:</p>
                <DatePicker
                  format="MMMM"
                  className={styles.date_picker}
                  onChange={onDateChange}
                  value={moment(orderDetails.approvato_il)}
                  disabled={orderDetails.isApproved}
                />
              </div>
              <Row className={styles.address_container}>
                <Col span={11} className={styles.address_column}>
                  <p className={styles.light_text}>{t('forms.estimations.sender')}:</p>
                  <div className={styles.gap_container}>
                    <p className={styles.bold_text}>
                      {orderDetails.request.pickup_address.company_name
                        || orderDetails.request.pickup_address.name_and_surname_reference}
                    </p>
                    <p className={styles.light_text}>{orderDetails.pickup_address}</p>
                    <p className={styles.light_text}>
                      <CalendarFilled className={styles.primary_color}/>
                      <span className={styles.bold_text}>
                        { orderDetails.request.start_shipment_date ? (
                          format(new Date(orderDetails.request.start_shipment_date), 'dd-MM-yyyy')
                        ) : '-' }
                      </span>
                    </p>
                  </div>
                </Col>
                <Col span={11} offset={2} className={styles.address_column}>
                  <p className={styles.light_text}>{t('forms.estimations.destination')}:</p>
                  <div className={styles.gap_container}>
                    <p className={styles.bold_text}>
                      {orderDetails.request.shipping_address.company_name
                          || orderDetails.request.shipping_address.name_and_surname_reference}
                    </p>
                    <p className={styles.light_text}>{orderDetails.shipping_address}</p>
                    <p className={styles.light_text}>
                      <CalendarFilled className={styles.primary_color}/>
                      <span className={styles.bold_text}>
                        { orderDetails.request.withdrawal_date ? (
                          format(new Date(orderDetails.request.withdrawal_date), 'dd-MM-yyyy')
                        ) : '-' }
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={2}>
              <div className={styles.gap_container}>
                <div className={styles.flex_end}>
                  <span className={cn(styles.cost_text, styles.align_right)}>{t('forms.newEstimation.tot_cost')}</span>
                  <span className={cn(styles.price, styles.min_width_160)}>
                    {new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(orderDetails.price)}
                    <OpenModalButton
                      modal={EstimationFieldModal.openPriceModal}
                      openModal={openModal}
                    />
                  </span>
                  <CalculationBtn onCalculateHandler={() => onCalculateOrder(orderDetails)}/>
                </div>
                <p className={styles.flex_end}>
                  <span className={cn(styles.align_right, styles.light_text)}>{t('forms.estimations.order_type')}:</span>
                  <span className={cn(styles.bold_text, styles.min_width_160)}>{orderDetails.type}</span>
                </p>
                <p className={styles.flex_end}>
                  <span className={cn(styles.align_right, styles.light_text)}>{t('contractorsTable.handledBy')}:</span>
                  <span className={cn(styles.bold_text, styles.min_width_160)}>
                    { (orderDetails.request?.contractor && orderDetails.request?.contractor[0]?.name) ? (
                      orderDetails.request?.contractor[0]?.name
                    ) : '-' }
                  </span>
                </p>
                <p className={styles.flex_end}>
                  <span className={cn(styles.align_right, styles.light_text)}>{t('forms.newEstimation.price_list')}:</span>
                  <span className={cn(styles.bold_text, styles.min_width_160)}>
                    {orderDetails.request.user.price_list.name}
                  </span>
                </p>
              </div>
              <Form form={form}>
                <div className={styles.modal_input_container}>
                  <CustomInput
                    label={t('invoices.table.n_ordine')}
                    defaultValue={{ shipment: orderDetails }}
                    name="n_ordine"
                    onChange={onInputChange}
                  />
                  <CustomInput
                    label={t('invoices.table.codice_cliente')}
                    defaultValue={{ shipment: orderDetails }}
                    name="codice_cliente"
                    onChange={onInputChange}
                  />
                  <CustomInput
                    label={t('invoices.table.numero_ddt')}
                    defaultValue={{ shipment: orderDetails }}
                    name="numero_ddt"
                    onChange={onInputChange}
                  />
                </div>

                <div className={styles.modal_input_container}>
                  <CustomInput
                    label={t('invoices.table.fluet')}
                    defaultValue={{ shipment: orderDetails }}
                    name="fluet"
                    onChange={onInputChange}
                    suffix
                  />
                  <CustomInput
                    defaultValue={{ shipment: orderDetails }}
                    label={t('invoices.table.extra')}
                    name="extra"
                    onChange={onInputChange}
                    addonAfter
                  />
                  <CustomInput
                    label={t('invoices.table.scontistica')}
                    defaultValue={{ shipment: orderDetails }}
                    name="scontistica"
                    onChange={onInputChange}
                    suffix
                  />
                </div>
              </Form>
            </Col>
          </Row>
          <PalletsTable
            dataArray={orderDetails.pallets || []}
            updateOptionHandler={updateOptionHandler}
            palletEstimate={{
              price_list_id: orderDetails.request.price_list_id,
              province: orderDetails.type === DeliveryType.CONSEGNA || orderDetails.type === DeliveryType.TRIANG
                ? orderDetails.request.shipping_address.location.province
                : orderDetails.request.pickup_address.location.province,
              region: orderDetails.type === DeliveryType.CONSEGNA || orderDetails.type === DeliveryType.TRIANG
                ? orderDetails.request.shipping_address.location.region
                : orderDetails.request.pickup_address.location.region,
            }}
            editable={!orderDetails.isApproved}
            deliveryType={orderDetails.type}
            options={orderDetails.options}
          />
          <ExtraServiceTable
            dataArray={orderDetails.options || []}
            updateOptionHandler={updateOptionHandler}
            editable={!orderDetails.isApproved}
          />
          <AddebitosTable
            dataArray={orderDetails.addebitos || []}
            updateOptionHandler={updateOptionHandler}
            editable={!orderDetails.isApproved}
          />
          { !orderDetails.isApproved && (
            <div className={styles.footer_styles}>
              <CustomButton
                className="defaultBtn"
                type="primary"
                onClick={() => {
                  onCancel();
                  form.resetFields();
                }}
              >
                {t('buttons.cancel')}
              </CustomButton>
              <CustomButton
                type="primary"
                onClick={onUpdateOrderDetailsModal}
              >
                {t('buttons.confirm')}
              </CustomButton>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default OrderDetailsModal;
