import { Empty } from 'antd';
import style from './noData.module.css';

const NoData = () => (
  <div className={style.emptyContainer}>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  </div>
);

export default NoData;
