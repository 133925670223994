import { TFunction } from 'react-i18next';
import styles from './ExtraServicesTable.module.css';

interface ExtraServicesTableInterface {
  service_name: string,
  service_details: string,
  price: string,
  apply_to: string,
  options: JSX.Element,
}

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editable: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text' | 'price' | 'select_category' | 'fixedMinPrice' | 'requested_to';
  align: 'center' | 'left' | 'right';
  width: string,
  record: ExtraServicesTableInterface;
  key: string;
  children: React.ReactNode;
  render: (c, record) => React.ReactNode,
}

export const makeExtraServicesTableColumnsWithoutOptions = (t: TFunction, editableRequested: boolean):Array<Partial<EditableCellProps>> => [
  {
    title: t('forms.newEstimation.service_name'),
    dataIndex: 'service_name',
    key: 'service_name',
    editable: false,
    render: (_, record) => (
      <div className={styles.extra_name}>
        <span>{record.service_name}</span>
        <span className={styles.not_available}>{record.notAvailable}</span>
        <span className={styles.not_available}>{record.isNonStopInDangerZone}</span>
      </div>
    ),
  },
  {
    title: t('forms.newEstimation.service_details'),
    dataIndex: 'service_details',
    key: 'service_details',
    editable: false,
  },
  {
    title: t('forms.newEstimation.requested_to'),
    dataIndex: 'requested_to',
    key: 'requested_to',
    inputType: 'requested_to',
    width: '180px',
    editable: editableRequested,
  },
  {
    title: t('forms.newEstimation.price'),
    dataIndex: 'price',
    key: 'price',
    align: 'center',
    width: '180px',
    inputType: 'price',
    editable: true,
  },
  {
    title: t('forms.newEstimation.fixed_minimum'),
    dataIndex: 'fixedMin',
    key: 'fixedMin',
    align: 'center',
    width: '180px',
    inputType: 'fixedMinPrice',
    editable: true,
  },
  {
    title: t('forms.newEstimation.apply_to'),
    dataIndex: 'apply_to',
    key: 'apply_to',
    align: 'center',
    editable: false,
  },
];

export const makeExtraServicesTableColumns = (t: TFunction, editableRequested: boolean, optionsRender: (c, record) => React.ReactNode, columns: Array<Partial<EditableCellProps>> = []):
  Array<Partial<EditableCellProps>> => {
  const c = columns.length ? columns : makeExtraServicesTableColumnsWithoutOptions(t, editableRequested);
  return [
    ...c,
    {
      title: '',
      dataIndex: 'options',
      key: 'options',
      align: 'center',
      render: optionsRender,
      editable: false,
    },
  ];
};
