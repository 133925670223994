import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';

interface ShipmentDetailsInterface {
    quantity: number,
    width: number,
    depth: number,
    height: number,
    weight: number,
}

export const makeShipmentDetailsTableColumns = (t:TFunction): ColumnsType<ShipmentDetailsInterface> => [
  {
    title: '',
    dataIndex: 'empty',
    key: 'empty',
  },
  {
    title: t('forms.shipmentDetails.quantity'),
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: t('forms.shipmentDetails.shortSide'),
    dataIndex: 'width',
    key: 'width',
  },
  {
    title: t('forms.shipmentDetails.longSide'),
    dataIndex: 'depth',
    key: 'depth',
  },
  {
    title: t('forms.shipmentDetails.height'),
    dataIndex: 'height',
    key: 'height',
  },
  {
    title: t('forms.shipmentDetails.weight'),
    dataIndex: 'weight',
    key: 'weight',
  },
];
