import { put, takeLatest } from 'redux-saga/effects';
import {
  addPallet, clearPallets, requestAddPallet, requestClearPallets,
} from '../redux_slice/palletsSlice';
import { Pallet } from '../types';

function* clearPalletsSaga() {
  yield put(clearPallets());
}

function* addPalletSaga(action: {
  payload: Pallet[]
}) {
  yield put(addPallet(action.payload));
}

export function* palletsSaga() {
  yield takeLatest(requestClearPallets, clearPalletsSaga);
  yield takeLatest(requestAddPallet, addPalletSaga);
}
