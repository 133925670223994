import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams, Link } from 'react-router-dom';
import classnames from 'classnames';
import style from '../LoginContainer/login.module.css';
import CustomButton from '../../components/CustomButton/CustomButton';
import { requestUpdateUserPassword } from '../../store/redux_slice/authSlice';
import { useAppDispatch } from '../../store/store';

const NewPassForm = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const query = params.get('query');
  const onFinish = (values) => {
    if (query) {
      dispatch(requestUpdateUserPassword({ query, password: values.newPass }));
    }
  };
  return (
    <div className={classnames(style.login, 'customForm')}>
      <Form
        layout="vertical"
        name="newPassForm"
        onFinish={onFinish}
      >
        <div className={style.password_form_container}>
          <Form.Item
            label={t('operatorSettings.new_password')}
            name="newPass"
            rules={[{ required: true, message: t('operatorSettings.newPass') },
              { whitespace: true, message: t('validation.empty') },
              { min: 8, message: t('validation.password') },
            ]}
          >
            <Input.Password className={style.input} />
          </Form.Item>
          <Form.Item
            label={t('operatorSettings.rewrite_new_password')}
            name="confirmPass"
            dependencies={['newPass']}
            rules={
              [{ required: true, message: t('operatorSettings.confirmPass') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPass') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('validation.confirmPassword')));
                  },
                })]}
          >
            <Input.Password className={style.input}/>
          </Form.Item>
          <div className={style.flex}>
            <CustomButton
              type="primary"
              htmlType="submit"
              className={style.confirmBtn}
            >
              {t('operatorSettings.update_password')}
            </CustomButton>
            <Link to="/" className={style.return_link}>
              {t('forms.login.signIn')}
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default NewPassForm;
