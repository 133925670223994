import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import CustomButton from '../CustomButton/CustomButton';
import boxSuccessIcon from '../../assests/images/boxSuccessIcon.png';
import style from './confirmContainer.module.css';

type ConfirmContainerProps = {
  title: string,
  trackingNumber?: number,
  confirmEmail?: string,
}

const ConfirmContainer = ({
  title, trackingNumber, confirmEmail,
}: ConfirmContainerProps): JSX.Element => {
  const { t } = useTranslation();
  const handleContactUs = () => {
    window.location.href = 'mailto:info@pesantisrl.it';
  };
  return (
    <div className={classnames('customForm', style.shipment_container)}>
      <img src={boxSuccessIcon} alt="boxSuccess"/>
      <div className={classnames('main-title-25px', style.shipment_title)}>
        {title}
      </div>
      { !trackingNumber && (
        <p className={style.shipment_description}>
          {t('forms.shipmentRequest.confirm_description')}
          <span className={style.shipment_email}> {confirmEmail} </span>
        </p>
      )}
      { trackingNumber && (
        <div className={style.tracking_number_container}>
          <div className="title-20px">{t('forms.shipmentRequest.trackingNumber')}</div>
          <div className={style.number_container}>
            <span className={style.number_text}>{trackingNumber}</span>
          </div>
        </div>
      )}
      <div className={classnames('extra-bolt-corsivo', style.shipment_info)}>
        {t('forms.shipmentRequest.information')}
      </div>
      <CustomButton
        htmlType="submit"
        type="primary"
        icon={<span/>}
        className={classnames('extra-bolt-corsivo', style.shipment_button)}
        onClick={handleContactUs}
      >
        {t('forms.shipmentRequest.contactUs')}
      </CustomButton>
    </div>
  );
};

export default ConfirmContainer;
