import React from 'react';
import style from './dashboard.module.css';

const InfoContainer: React.FC<{
  title: string,
  count: number,
  chart?: JSX.Element,
}> = ({
  title, count, chart,
}) => (
  <div className={style.info_container}>
    <div className={style.grey_container}>
      {chart}
    </div>
    <div className={style.color_container}>
      <div className={style.title_style}>{title}</div>
      <div className={style.count_style}>{count}</div>
    </div>
  </div>
);
export default InfoContainer;
