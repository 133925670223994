import React, { useEffect, useState } from 'react';
import {
  Form, FormInstance, Modal, TimePicker,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import CustomButton from '../../../components/CustomButton/CustomButton';
import style from '../estimations.module.css';

let defaultTimeValue: [Moment, Moment] | null = null;

const parseDate = (date) => {
  const timeStartStr = date ? date.split(' - ')[0].trim() : '';
  const timeEndStr = date ? date.split(' - ')[1].trim() : '';
  if (timeStartStr && timeEndStr) {
    defaultTimeValue = [
      moment(timeStartStr, 'HH:mm'),
      moment(timeEndStr, 'HH:mm'),
    ];
  }
  return defaultTimeValue;
};

export const ChangeOreNoteModal: React.FC<{
    visible: boolean,
    onCancel: () => void,
    onChange: (v: string) => void,
    initialValue: string,
    form?: FormInstance,
    id?: string,
}> = ({
  visible,
  onChange,
  onCancel,
  initialValue,
  form,
  id,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>(initialValue);
  const onChangeHandler = (values, formatString: [string, string]) => {
    setValue(formatString.join(' - '));
  };
  useEffect(() => {
    if (!visible) return;
    form?.setFieldsValue({
      value: initialValue === ' - ' || initialValue.length === 0 ? null : parseDate(initialValue),
    });
  }, [form, initialValue, visible]);

  const submit = () => {
    onChange(value);
    onCancel();
  };

  return (
    <Modal title={t('forms.estimations.change_ore_note')}
      visible={visible}
      onOk={submit}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        key={`${id}-${visible}`}
        form={form}
        onFinish={submit}
        name="orNoteForm"
      >
        <Form.Item name="value">
          <TimePicker.RangePicker value={form?.getFieldValue('value')} onChange={onChangeHandler} format={'HH:mm'} allowClear/>
        </Form.Item>
        <CustomButton
          className={style.modal_button}
          type="primary"
          htmlType="submit"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};
