import { useTranslation } from 'react-i18next';
import { Col, Row, Select } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import cn from 'classnames';
import { formatISO } from 'date-fns';
import DeleteModal from 'components/DeleteModal';
import CustomButton from 'components/CustomButton/CustomButton';
import { EditContractorModal } from 'components/ContractorSelect/EditContractorModal';
import { OpenModalCustomButton } from './OpenModalCustomButton';
import { EstimationFieldModal } from './hooks';
import { ChangeContractorModal } from './FieldModals/ChangeContractorModal';
import { RequestSentSuccessfullyModal } from './FieldModals/RequestSentSuccessfullyModal';
import { useAppDispatch, useAppSelector } from 'store/store';
import { approveToBeApprovedShipmentAction } from 'store/redux_slice/shipping/shippingBeforeApproveSlice';
import { requestGetPriceListTemplates } from 'store/redux_slice/priceListTemplatesSlice';
import { requestRefusedShippingRequest } from 'store/redux_slice/shipmentSlice';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import { requestApproveEstimation, requestClearSentShippingRequest } from 'store/redux_slice/estimationSlice';
import { PatchEstimation, ShipmentStructure } from 'store/types';
import { selectContractorType } from 'functions';
import { DeliveryType, TPStatuses, RoleEnums } from 'enums';
import {
  GDO, AMAZON, CONTRASSEGNO, NON_STOP, ZONE_DISAGIATE, triangServices,
} from 'pesantiConstants';
import style from './estimations.module.css';

const { Option } = Select;

const AssignPriceListForm:React.FC<{
  estimation: ShipmentStructure,
  openModal: (modalId: EstimationFieldModal) => void;
  closeModal: () => void;
  isOpen: (modalId: EstimationFieldModal) => boolean;
  isEstimation: boolean,
  onRequestUpdateEstimation: (data: PatchEstimation) => void,
  onRequestCreateShippingEstimate: (v: string) => void,
  setVisible?: () => void,
  available: boolean,
  estimationCost: string,
}> = ({
  estimation, openModal, isOpen, closeModal, isEstimation,
  onRequestUpdateEstimation, onRequestCreateShippingEstimate, setVisible, available,
  estimationCost,
}) => {
  const { t } = useTranslation();
  const orderTypePallexData = [DeliveryType.CONSEGNA, DeliveryType.RITIRO, DeliveryType.TRIANG];
  const [contractorType, setContractorType] = useState<string | number>('');
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const priceListTemplates = useAppSelector((state) => state.priceListTemplates?.priceListTemplates);
  const dispatch = useAppDispatch();
  const priceLists = priceListTemplates.filter((el) => el.isSpecial);
  const userPriceList = priceListTemplates?.find(((el) => el.id === estimation?.price_list_id));
  const contractors = useAppSelector((state) => state.contractors?.contractors);
  const pallexItalia = contractors.find((el) => el.name === 'Pallex Italia');
  const findContractor = contractors.find((el) => el.id === estimation.contractor[0]?.ResContract?.contractor_id);
  const findSubContractor = contractors.find((el) => (
    el.id === estimation.sub_contractor[0]?.ResSubContract?.contractor_id
  ));
  const pallexTP = findContractor?.name === 'Pallex TP' || findSubContractor?.name === 'Pallex TP';

  const isNonStopInDangerZone = useMemo(() => (
    estimation?.options?.filter((s) => (s.title.toUpperCase() === ZONE_DISAGIATE.toUpperCase()
      || s.title.toUpperCase() === NON_STOP.toUpperCase()) && s.isActive).length === 2
  ), [estimation?.options]);

  const hasGDO = useMemo(() => (
    estimation?.options.some((o) => (
      (o.isActive && o.title?.toUpperCase() === GDO.toUpperCase())
       || (o.isActive && o.title?.toUpperCase() === AMAZON.toUpperCase())
    ))
  ), [estimation?.options]);

  const hasContrassegno = useMemo(() => (
    estimation?.options.some((o) => (
      (o.isActive && o.title?.toUpperCase() === CONTRASSEGNO.toUpperCase())
    ))
  ), [estimation?.options]);

  const disableEstimationByGdo = useMemo(() => {
    if (estimation.delivery_type === DeliveryType.RITIRO && hasGDO) {
      return !estimation.pickup_address.gdo_name;
    }
    if (estimation.delivery_type === DeliveryType.CONSEGNA && hasGDO) {
      return !estimation.shipping_address.gdo_name;
    }
    if (estimation.delivery_type === DeliveryType.TRIANG && hasGDO) {
      return !estimation.pickup_address.gdo_name || !estimation.shipping_address.gdo_name;
    }
    return false;
  }, [estimation, hasGDO]);

  useEffect(() => {
    dispatch(requestGetPriceListTemplates());
  }, [dispatch]);

  const changeDelivrtyTypeForOptions = (orderDeliveryType: string, serviceTitle: string, serviceDeliveryType: DeliveryType | null) => {
    switch (orderDeliveryType) {
    case DeliveryType.RITIRO:
      return DeliveryType.RITIRO;
    case DeliveryType.CONSEGNA:
      return DeliveryType.CONSEGNA;
    case DeliveryType.TRIANG:
      return triangServices?.includes(serviceTitle.toUpperCase()) ? serviceDeliveryType : null;
    default:
      return null;
    }
  };

  const onOrderTypeChange = (newOrderType: string) => {
    const selectedTypes = selectContractorType(
      estimation?.contractor[0]?.ResContract?.contractor_id,
      newOrderType,
    );
    const noteRitiro = newOrderType === DeliveryType.RITIRO
      ? { note_ritiro: `${t('forms.shipmentRequest.start_shipment_time_note')}: ${estimation.start_shipment_time}` } : {};

    const mappedOptions = estimation.options.map((item) => ({
      ...item,
      triangolize: changeDelivrtyTypeForOptions(newOrderType, item.title, item.triangolize || null),
      values: {
        ritiro: newOrderType !== DeliveryType.CONSEGNA ? (item.values?.ritiro || null) : null,
        consegna: newOrderType !== DeliveryType.RITIRO ? (item.values?.consegna || null) : null,
      },
    }));
    const data: Partial<ShipmentStructure> = {
      delivery_type: newOrderType,
      options: mappedOptions,
      shipping_address: {
        ...estimation?.shipping_address,
        gdo_name: newOrderType !== DeliveryType.RITIRO ? estimation?.shipping_address.gdo_name : null,
        gdo_id: newOrderType !== DeliveryType.RITIRO ? estimation?.shipping_address.gdo_id : null,
      },
      pickup_address: {
        ...estimation?.pickup_address,
        gdo_name: newOrderType !== DeliveryType.CONSEGNA ? estimation?.pickup_address.gdo_name : null,
        gdo_id: newOrderType !== DeliveryType.CONSEGNA ? estimation?.pickup_address.gdo_id : null,
      },
      ...noteRitiro,
    };

    if (contractorType === pallexItalia?.id && newOrderType === DeliveryType.TRIANG) {
      data.idHub = null;
    }
    if (estimation?.contractor[0]?.ResContract?.contractor_id) {
      data.contractor = [
        {
          ResContract: {
            ...estimation.contractor[0].ResContract,
            contractor_id: estimation.contractor[0].ResContract.contractor_id,
            type: selectedTypes[0],
            show: newOrderType === DeliveryType.TRIANG ? false : estimation.contractor[0].ResContract.show,
          },
        },
      ];
    }
    if (estimation?.sub_contractor[0]?.ResSubContract?.contractor_id) {
      data.sub_contractor = [
        {
          ResSubContract: {
            ...estimation.sub_contractor[0].ResSubContract,
            contractor_id: estimation.sub_contractor[0].ResSubContract.contractor_id,
            type: selectedTypes[1],
            show: newOrderType === DeliveryType.TRIANG ? false : estimation.sub_contractor[0].ResSubContract.show,
          },
        },
      ];
    }
    if (newOrderType === DeliveryType.TRIANG && estimation?.contractor[0]?.ResContract?.contractor_id) {
      data.sub_contractor = [
        {
          ResSubContract: {
            request_id: estimation.contractor[0].ResContract.request_id,
            id: estimation.sub_contractor[0]?.ResSubContract
              ? estimation.sub_contractor[0]?.ResSubContract.id : undefined,
            del_date: estimation.contractor[0].ResContract.del_date
              ? estimation.contractor[0].ResContract.del_date : null,
            contractor_id: estimation.contractor[0].ResContract.contractor_id,
            type: selectedTypes[1],
            show: newOrderType === DeliveryType.TRIANG ? false : estimation.sub_contractor[0].ResSubContract.show,
          },
        },
      ];
    }
    if (newOrderType === DeliveryType.RITIRO && hasContrassegno) {
      data.options = estimation?.options.map((option) => (
        option.title === CONTRASSEGNO ? { ...option, isActive: false } : option
      ));
      data.contrassegno = null;
    }

    onRequestUpdateEstimation({
      id: estimation?.id,
      data,
    });
  };

  const onChangeContractorType = (type: number | string) => {
    const selectedTypes = selectContractorType(Number(type), estimation?.delivery_type);
    const contractor = contractors.find((el) => el.id === type);
    if (contractor) {
      const data = {
        contractor: [
          {
            ResContract: {
              request_id: estimation?.contractor[0]?.ResContract ? estimation.contractor[0].ResContract.request_id
                : estimation.id,
              contractor_id: Number(type),
              type: selectedTypes[0],
              del_date: estimation?.contractor[0]?.ResContract ? estimation.contractor[0].ResContract.del_date : null,
              show: false,
            },
          },
        ],
        sub_contractor: estimation.delivery_type === DeliveryType.TRIANG
          ? [
            {
              ResSubContract: {
                request_id: estimation?.contractor[0]?.ResContract ? estimation.contractor[0].ResContract.request_id
                  : estimation.id,
                contractor_id: Number(type),
                type: selectedTypes[1],
                del_date: estimation?.contractor[0]?.ResContract ? estimation.contractor[0].ResContract.del_date : null,
                show: false,
              },
            },
          ]
          : undefined,
      };
      if (!estimation?.contractor[0]?.ResContract.contractor_id
          || estimation.contractor[0].ResContract.contractor_id !== pallexItalia?.id) {
        onRequestUpdateEstimation({
          id: estimation?.id,
          data,
        });
      }
    }
  };

  const onPriceListChange = (value: string) => {
    if (value) {
      const data = {
        price_list_id: +value,
      };
      onRequestUpdateEstimation({
        patchListElement: {
          options: estimation.options,
          priceListId: +value,
        },
        id: estimation?.id,
        data,
      });
    }
  };

  const onChangeTracking = (value: string) => {
    const data = {
      tracking: value,
    };
    onRequestUpdateEstimation({
      id: estimation?.id,
      data,
    });
  };

  const onApproveOrder = () => {
    if (estimation.options.some((i) => i.unavailable)) {
      dispatch(setMessage({
        title: t('messages.attention'),
        description: t('messages.need_to_remove_unavailable_services'),
      }));
    } else if (isNonStopInDangerZone) {
      dispatch(setMessage({
        title: t('messages.attention'),
        description: t('messages.need_to_remove_non_stop'),
      }));
    } else if (isEstimation) {
      dispatch(requestApproveEstimation({
        id: estimation?.id,
        isUser: estimation.user?.role === RoleEnums.USER,
      }));
      openModal(EstimationFieldModal.openRequestSentSuccessfullyModal);
      if (setVisible) {
        setVisible();
      }
    } else {
      const data = {
        status: estimation.start_shipment_date ? TPStatuses.In_attesa_di_ritiro : TPStatuses.In_attesa_di_data,
        isApproved: true,
        approvato_il: formatISO(new Date()),
      };

      dispatch(approveToBeApprovedShipmentAction({
        id: estimation?.id,
        data,
      }));
      if (setVisible) {
        setVisible();
      }
    }
  };

  const onRejectHamdler = () => {
    if (isEstimation) {
      dispatch(requestRefusedShippingRequest({
        id: estimation?.id, prevenivi: true,
      }));
    } else {
      dispatch(requestRefusedShippingRequest({
        id: estimation?.id, da_approvare: true,
      }));
    }
    if (setVisible) {
      setVisible();
    }
  };

  useEffect(() => {
    setContractorType(Number(estimation.contractor[0]?.ResContract?.contractor_id));
    if (!estimation?.price_list_id) {
      onPriceListChange(estimation?.user?.price_list_id?.toString());
    }
  }, []);

  return (
    <>
      <DeleteModal
        title={t('forms.deliveries.delete_estimation')}
        description={t('forms.deliveries.delete_estimation_description')}
        visible={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        onDelete={onRejectHamdler}
      />
      {
        // This is an optimization until we get rid of the carousel component
        isOpen(EstimationFieldModal.openContractors) && <ChangeContractorModal
          id={estimation?.id}
          visible={isOpen(EstimationFieldModal.openContractors)}
          initialValue={estimation?.contractor[0]?.ResContract.contractor_id || ''}
          onEdit={() => {
            openModal(EstimationFieldModal.openContractor);
          }}
          onCancel={() => {
            closeModal();
          }}
          onRequestUpdateEstimation={onRequestUpdateEstimation}
          deliveryType={estimation.delivery_type}
          estimation={estimation}
        />
      }
      {
        // This is an optimization until we get rid of the carousel component
        isOpen(EstimationFieldModal.openContractor) && <EditContractorModal
          visible={isOpen(EstimationFieldModal.openContractor)}
          onCancel={() => {
            closeModal();
          }}
        />
      }
      {
        // This is an optimization until we get rid of the carousel component
        isOpen(EstimationFieldModal.openRequestSentSuccessfullyModal) && <RequestSentSuccessfullyModal
          visible={isOpen(EstimationFieldModal.openRequestSentSuccessfullyModal)}
          onCancel={() => {
            closeModal();
            dispatch(requestClearSentShippingRequest());
          }}
        />
      }
      <Row className={style.price_list_container}>
        <Col>
          <p>{t('forms.estimations.order_type')}</p>
          <Select className={style.select_input}
            defaultValue={estimation?.delivery_type}
            onChange={onOrderTypeChange}
          >
            {
              orderTypePallexData.map((i) => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))
            }
          </Select>
        </Col>
        <Col offset={1}>
          <p>{t('forms.estimations.assign_price_list')}</p>
          <Select className={style.select_input}
            onChange={onPriceListChange}
            defaultValue={(estimation?.price_list_id || estimation?.user?.price_list_id)?.toString() || ''}
          >
            {
              userPriceList && priceLists.includes(userPriceList)
                ? priceLists
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((p) => (
                    <Option key={p.id} value={p.id.toString()}>
                      {p.name}
                    </Option>
                  ))
                : <>
                  { userPriceList && (
                    <Option key={userPriceList?.id} value={userPriceList?.id.toString()}>
                      {userPriceList?.name}
                    </Option>
                  )}
                  {
                    priceLists
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((p) => (
                        <Option key={p.id} value={p.id.toString()}>
                          {p.name}
                        </Option>
                      ))
                  }
                </>
            }
          </Select>
        </Col>
        <Col offset={1}>
          <p>{t('forms.estimations.tracking')}</p>
          <Select
            className={style.select_input}
            value={estimation.tracking}
            disabled={pallexTP}
            onChange={onChangeTracking}
          >
            <Option key={'Disponsible'} value={'Disponsible'}>
              {t('forms.estimations.disponsible')}
            </Option>
            <Option key={'NonDisponsible'} value={'NonDisponsible'}>
              {t('forms.estimations.non_disponsible')}
            </Option>
          </Select>
        </Col>
      </Row>
      <Row className={style.gap_container}>
        <Col>
          <p className={style.contractors_container_title}>{t('forms.newEstimation.manage_with')}:</p>
          <Row className={style.gap_container}>
            <OpenModalCustomButton
              modal={EstimationFieldModal.openContractors}
              openModal={openModal}
              icon={<></>}
              className={
                !estimation.contractor[0]?.ResContract?.contractor_id
                || estimation.contractor[0]?.ResContract?.contractor_id !== pallexItalia?.id
                  ? cn(style.detail_button, 'defaultBtn')
                  : cn(style.detail_button, 'defaultBtn', style.disabled_button)
              }
            >
              {t('forms.estimations.manage_with_external')}
            </OpenModalCustomButton>
            {
              pallexItalia
                && <CustomButton type="primary" className={
                  !estimation.contractor[0]?.ResContract?.contractor_id
                  || estimation.contractor[0]?.ResContract?.contractor_id === pallexItalia?.id
                    ? style.detail_button
                    : cn(style.detail_button, style.disabled_button)
                }
                onClick={() => {
                  setContractorType(pallexItalia.id);
                  onChangeContractorType(pallexItalia.id);
                }}
                >
                  {t('forms.estimations.manage_with_pallex')}
                </CustomButton>
            }
          </Row>
        </Col>
        <Col>
          <p className={style.contractors_container_title}>{t('forms.newEstimation.shipping_actions')}:</p>
          <Row className={style.gap_container}>
            <CustomButton
              disabled={
                estimationCost === '--€' || estimation.contractor.length === 0
                  || estimation.sub_contractor.length === 0
                    || !estimation?.start_shipment_date || disableEstimationByGdo
              }
              className={style.button_style}
              type="primary"
              htmlType="submit"
              onClick={onApproveOrder}
            >
              { isEstimation ? t('forms.estimations.approve') : t('forms.deliveries.approve') }
            </CustomButton>
            <CustomButton
              className={style.button_style}
              type="link"
              onClick={() => setIsDeleteModal(true)}
            >
              {t('buttons.cancel')}
            </CustomButton>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AssignPriceListForm;
