import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import React from 'react';
import { makeMonitoringTableColumns } from './TableColumns';
import { MonitoringStructure } from '../../store/types';

const TableMonitoring:React.FC<{
  monitoringList: MonitoringStructure,
    page: number,
    pageSize: number,
    setPage: (page: number) => void,
    setPageSize: (pageSize: number) => void,
}> = ({
  monitoringList, page, pageSize, setPage, setPageSize,
}) => {
  const { t } = useTranslation();
  const columns = makeMonitoringTableColumns(t);

  const onChangePageSize = (current, size) => {
    setPageSize(size);
  };

  const pagination = {
    current: page,
    pageSize,
    pageSizeOptions: [10, 15, 20],
    showSizeChanger: true,
    total: monitoringList.count,
    onChange: setPage,
    onShowSizeChange: onChangePageSize,
  };
  const dataSource = monitoringList.items?.map((i) => (
    {
      key: i.id,
      name: i.user?.name || i.user?.email,
      description: i.action_type,
      section: i.section,
      ip: i.ip_address,
      date: i.date,
    }
  ));
  return (
    <Table columns={columns}
      pagination={pagination}
      dataSource={dataSource}
    />
  );
};

export default TableMonitoring;
