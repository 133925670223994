import { put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  UpdateShippingRequest,
  CreateRequestEstimate,
} from 'api';
import { PatchEstimation, PallexErrorModalPayload } from 'store/types';
import { setLoading } from 'store/redux_slice/estimationsListSlice';
import {
  DeleteShippingRequest, GetPallexShipping, GetShippingRequest, PatchPallexShipping,
} from 'api/shipping';
import {
  patchApprovedSlice,
  updateApprovedShipmentAction, deleteArchivedShipmentAction, setPallexErrorLoading,
  getApprovedPallexShippingRequestAction,
} from 'store/redux_slice/shipping/shippingApproveSlice';
import { setShipmentDataFromNotification } from 'store/redux_slice/notificationsSlice';
import { setMessage } from 'store/redux_slice/messageErrorSlice';

function* patchApprovedShipmentSaga(action: {
    payload: PatchEstimation
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield UpdateShippingRequest(action.payload);
    yield put(patchApprovedSlice({
      id: data.id,
      data,
    }));
    if (action.payload.data.contractor || action.payload.data.sub_contractor) {
      const { data: dataShipments } = (yield GetShippingRequest(action.payload.id));
      yield put(patchApprovedSlice({
        id: dataShipments.id,
        data: dataShipments,
      }));
      yield put(setShipmentDataFromNotification(dataShipments));
    }
    if (action.payload.recalculate) {
      const priceData = yield CreateRequestEstimate(data.id);
      yield put(patchApprovedSlice({
        id: data.id,
        data: {
          ...data,
          shipping_estimate: priceData.data,
        },
      }));
      yield put(setShipmentDataFromNotification({
        ...data,
        shipping_estimate: priceData.data,
      }));
    }
    if (action.payload.data.status === 'In attesa di data' && action.payload.data.start_shipment_date) {
      const dataDate = (yield UpdateShippingRequest({
        id: action.payload.id,
        data: { status: 'In attesa di ritiro' },
      }));
      yield put(patchApprovedSlice({
        id: dataDate.data.id,
        data: dataDate.data,
      }));
    }
  } catch (e) {
    if (e.response.data.message === 'Epal too much!') {
      yield put(setMessage({ description: i18n.t('messages.epal_error'), title: i18n.t('messages.attention') }));
    }
  }
  yield put(setLoading(false));
}

function* deleteShipmentArchiveSaga(action: {
    payload: { id: string }
}) {
  yield put(setLoading(true));
  try {
    yield DeleteShippingRequest(action.payload.id);
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getApprovedPallexShippingRequestSaga(action: {
    payload: PallexErrorModalPayload
}) {
  yield put(setPallexErrorLoading(true));
  try {
    if (action.payload.trackingId) {
      const { data } = yield PatchPallexShipping(action.payload.orderId);
      const errorMessage = data.filter((item) => item.response.status === 400)
        .map((item) => (`${item.name}: ${item.response.response}`))
        .join(';');
      yield put(patchApprovedSlice({
        id: action.payload.orderId,
        data: {
          pallex_error: !errorMessage ? null : { message: errorMessage },
        },
      }));
      const { data: dataShipments } = (yield GetShippingRequest(action.payload.orderId));
      yield put(patchApprovedSlice({
        id: dataShipments.id,
        data: dataShipments,
      }));
    } else {
      const { data } = yield GetPallexShipping(action.payload.orderId);
      const responseData = data.codiceSpedizione ? {
        pallex_error: null,
        tracking_code: data.codiceSpedizione,
      } : { pallex_error: data };

      yield put(patchApprovedSlice({
        id: action.payload.orderId,
        data: responseData,
      }));
    }
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setPallexErrorLoading(false));
}

export function* shippingApproveSaga() {
  yield takeLatest(updateApprovedShipmentAction, patchApprovedShipmentSaga);
  yield takeLatest(deleteArchivedShipmentAction, deleteShipmentArchiveSaga);
  yield takeLatest(getApprovedPallexShippingRequestAction, getApprovedPallexShippingRequestSaga);
}
