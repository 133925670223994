import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { TransportTypePayload, TransportTypeStructure } from '../../types';

export const requestCreateTransportType = createAction<{ type: string }>('requestCreateTransportType');
export const requestGetTransportType = createAction<string>('requestGetTransportType');
export const requestDeleteTransportType = createAction<string>('requestDeleteTransportType');
export const requestUpdateTransportType = createAction<TransportTypePayload>('requestUpdateTransportType');

type State = {
    transportType: TransportTypeStructure | null
    loading: boolean,
}
const initialState: State = {
  transportType: null,
  loading: false,
};

const transportTypeSlice = createSlice({
  name: 'transportType',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTransportType: (state, action: PayloadAction<TransportTypeStructure>) => {
      state.transportType = action.payload;
    },
    clearTransportType: (state) => {
      state.transportType = null;
    },
  },
});

export const { setTransportType, setLoading, clearTransportType } = transportTypeSlice.actions;

export default transportTypeSlice.reducer;
