import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PatchEstimation, ShipmentStructure, ShippingRequestCreatePayload, ShippingRequestRefusePayload, ShippingEstimate,
} from '../types';

export const requestShippingCreate = createAction<ShippingRequestCreatePayload>('requestShippingCreate');
export const requestShippingGetShippingRequest = createAction<string>('requestShippingGetShippingRequest');
export const requestShippingUpdateShippingRequest = createAction<PatchEstimation>('requestShippingUpdateShippingRequest');
export const requestRefusedShippingRequest = createAction<ShippingRequestRefusePayload>('requestRefusedShippingRequest');

type State = {
  shippingRequest: ShipmentStructure | null,
  shippingCost: ShippingEstimate | null,
  loading: boolean,
  confirmationEmail: string | null,
  refused: boolean,
}
const initialState: State = {
  shippingRequest: null,
  shippingCost: null,
  loading: false,
  confirmationEmail: null,
  refused: false,
};

const shipmentSlice = createSlice({
  name: 'shipmentRequest',
  initialState,
  reducers: {
    setConfirmationEmail: (state, action: PayloadAction<string | null>) => {
      state.confirmationEmail = action.payload;
    },
    setShippingCost: (state, action: PayloadAction<ShippingEstimate>) => {
      state.shippingCost = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShipmentData: (state, action: PayloadAction<ShipmentStructure>) => {
      state.shippingRequest = action.payload;
    },
    setRefused: (state, action: PayloadAction<boolean>) => {
      state.refused = action.payload;
    },
    setCleanData: (state) => {
      state.shippingRequest = null;
    },
  },
});

export const {
  setShipmentData, setLoading, setConfirmationEmail, setCleanData,
  setRefused, setShippingCost,
} = shipmentSlice.actions;

export default shipmentSlice.reducer;
