import classnames from 'classnames';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { Tooltip } from 'antd';
import infoIcon from 'assests/images/infoIcon.svg';
import StatusSelect from 'components/StatusSelect';
import Status from 'components/Status';
import { ShipmentStructure } from 'store/types';
import { DeliveryType, PallexStatuses, TPStatuses } from 'enums';
import style from '../../deliveries.module.css';

interface ApproveTableColumnsInterface {
  id: string,
  pallex_id: string,
  number_pallet: string[],
  pickupName: string,
  shippingName: string,
  approved_date: string,
  recipient_name: string | undefined,
  withdrawal_date: string,
  start_date: string,
  type: { type : string, applicant: string },
  applicant: {nonStop: boolean, applicant: string},
  status: { shipment: ShipmentStructure, applicant: string },
  drivers: string[] | string,
  options: JSX.Element,
}

interface ApproveArchiveTableColumnsInterface {
  id: string,
  number_pallet: number[],
  pickupName: string,
  shippingName: string,
  approved_date: string,
  recipient_name: string | undefined,
  withdrawal_date: string,
  start_date: string,
  status: { shipment: ShipmentStructure, applicant: string },
  price: string | number,
  options: JSX.Element,
}

interface ToBeApprovedTableColumnsInterface {
  start_shipment_date: string,
  id: string,
  pickupName: string,
  shippingName: string,
  number_pallet: string[],
  recipient_name: string | undefined,
  measures: string[],
  from_to: string,
  options: JSX.Element,
  isNonStop: boolean,
}

interface RejectedTableColumnsInterface {
  id: string,
  recipient_name: string | undefined,
  number_pallet: string[],
  measures: string[]
  rejected_date: string,
  status: string,
  price: string | number,
  options: JSX.Element,
  isNonStop: boolean,
}

const renderType = (type, applicant) => {
  if (applicant === 'Pallex') {
    if (type === DeliveryType.CONSEGNA) {
      return <div className={classnames(style.pallex_style, style.green)}>{type}</div>;
    }
    if (type === DeliveryType.RITIRO) {
      return <div className={classnames(style.pallex_style, style.blue)}>{type}</div>;
    }
    return type;
  }
  return type;
};

export const PallexStatusesArray = [
  { value: PallexStatuses.In_attesa_di_ritiro, label: PallexStatuses.In_attesa_di_ritiro },
  { value: PallexStatuses.Ritiro_effettuato, label: PallexStatuses.Ritiro_effettuato },
  { value: PallexStatuses.Presso_HUB, label: PallexStatuses.Presso_HUB },
  { value: PallexStatuses.In_consegna, label: PallexStatuses.In_consegna },
  { value: PallexStatuses.Consegnato, label: PallexStatuses.Consegnato },
  { value: TPStatuses.Annullata, label: TPStatuses.Annullata },
];

export const TransportiStatusesArray = [
  { value: TPStatuses.In_attesa_di_ritiro, label: TPStatuses.In_attesa_di_ritiro },
  { value: TPStatuses.Ritirato, label: TPStatuses.Ritirato },
  { value: TPStatuses.In_magazzino, label: TPStatuses.In_magazzino },
  { value: TPStatuses.In_consegna, label: TPStatuses.In_consegna },
  { value: TPStatuses.Consegnato, label: TPStatuses.Consegnato },
  { value: TPStatuses.Annullata, label: TPStatuses.Annullata },
];

const statusesColumn = (shipment, applicant?, onChange?, contractorIsPallex?, contractorIsTPPallex?) => {
  if (!contractorIsPallex) {
    return <Status status={TPStatuses.Gestito_da_TP} className={style.left}/>;
  }
  if (applicant && applicant !== 'Pallex') {
    return <StatusSelect
      statusName={shipment.status}
      value={shipment.status}
      onChange={(value: string) => onChange(value, shipment)}
      options={
        !contractorIsTPPallex
          ? PallexStatusesArray.map((item) => {
            const { value, label } = item;
            return {
              value,
              label: <p className={classnames('bold', style.align)}>{label}</p>,
            };
          })
          : TransportiStatusesArray.map((item) => {
            const { value, label } = item;
            return {
              value,
              label: <p className={classnames('bold', style.align)}>{label}</p>,
            };
          })
      }
    />;
  }
  return <Status status={shipment.status} className={style.left}/>;
};

export const makeColumnsApprove = (t:TFunction, onChange: (status, shipment) => void)
    : ColumnsType<ApproveTableColumnsInterface> => [
  {
    title: t('forms.deliveries.id'),
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    className: style.cell_styles,
  },
  {
    title: t('forms.deliveries.pallex_id'),
    dataIndex: 'pallex_id',
    key: 'pallex_id',
    align: 'center',
    className: style.cell_styles,
  },
  {
    title: t('forms.deliveries.number_pallets'),
    dataIndex: 'number_pallet',
    key: 'number_pallet',
    align: 'center',
    sorter: (a, b) => (
      a.number_pallet.reduce((prev, next) => prev + +next.split('-')[0], 0) - b.number_pallet.reduce((prev, next) => prev + +next.split('-')[0], 0)
    ),
    render: (numbers) => (
      numbers.map((number, k) => (
        <div className={classnames(style.primary_color, 'bolt-14px')} key={`${k} ${number}`}>
          {number}
        </div>
      ))),
    className: style.cell_styles,
  },
  {
    title: t('shipmentList.pickupName'),
    dataIndex: 'pickupName',
    key: 'pickupName',
    align: 'center',
    className: style.cell_styles,
  },
  {
    title: t('shipmentList.shippingName'),
    dataIndex: 'shippingName',
    key: 'shippingName',
    align: 'center',
    className: style.cell_styles,
  },
  {
    title: t('forms.deliveries.approved_date'),
    dataIndex: 'approved_date',
    key: 'approved_date',
    align: 'center',
    sorter: (a, b) => new Date(a.approved_date).getTime() - new Date(b.approved_date).getTime(),
    render: (date) => (date ? format(new Date(date), 'dd-MM-yyyy') : ''),
    className: style.cell_styles,
  },
  {
    title: t('forms.deliveries.client_name'),
    dataIndex: 'recipient_name',
    key: 'recipient_name',
    align: 'center',
    sorter: (a, b) => {
      if (a && a.recipient_name && a.recipient_name.length && b && b.recipient_name && b.recipient_name.length) {
        return a.recipient_name.length - b.recipient_name.length;
      } if (a && a.recipient_name && a.recipient_name.length) {
        return -1;
      } if (b && b.recipient_name && b.recipient_name.length) {
        return 1;
      }
      return 0;
    },
    render: (text) => <span className="bolt-14px">{text}</span>,
    className: style.cell_styles,
  },
  {
    title: t('forms.deliveries.date'),
    dataIndex: 'start_date',
    key: 'start_date',
    render: (date) => (date ? format(new Date(date), 'dd-MM-yyyy') : ''),
    sorter: (a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
    align: 'center',
    className: style.cell_styles,
  },
  {
    title: t('forms.deliveries.delivery_date'),
    dataIndex: 'withdrawal_date',
    key: 'withdrawal_date',
    render: (date) => (date ? format(new Date(date), 'dd-MM-yyyy') : ''),
    sorter: (a, b) => new Date(a.withdrawal_date).getTime() - new Date(b.withdrawal_date).getTime(),
    align: 'center',
    className: style.cell_styles,
  },
  {
    title: t('forms.deliveries.type'),
    dataIndex: 'type',
    align: 'center',
    sorter: (a, b) => {
      if (a && a.type.type && a.type.type.length && b && b.type && b.type.type.length) {
        return a.type.type.length - b.type.type.length;
      } if (a && a.type && a.type.type.length) {
        return -1;
      } if (b && b.type && b.type.type.length) {
        return 1;
      }
      return 0;
    },
    render: ({ type, applicant }) => renderType(type, applicant),
    className: style.cell_styles,
  },
  {
    title: t('forms.deliveries.applicant'),
    dataIndex: 'applicant',
    key: 'applicant',
    align: 'center',
    render: ({ applicant, nonStop }) => <div>
      {
        applicant === 'Pallex'
          ? <div className={classnames(style.pallex_style, style.red)}>{applicant}</div>
          : applicant
      }
      {
        nonStop && <div className={classnames('non_stop', style.non_stop)}>Non-Stop</div>
      }
    </div>,
    className: style.cell_styles,
  },
  {
    title: <span>
      {t('forms.deliveries.status')}
      <Tooltip title={t('messages.info_shipment_column')}>
        <img src={infoIcon} alt="info" className={style.table_icon}/>
      </Tooltip>
    </span>,
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: ({
      shipment, applicant, contractorIsPallex, contractorIsTPPallex,
    }) => statusesColumn(shipment, applicant, onChange, contractorIsPallex, contractorIsTPPallex),
    className: style.cell_styles,
  },
  {
    title: 'Assegnato a',
    dataIndex: 'drivers',
    key: 'drivers',
    sorter: (a, b) => {
      if (a && a.drivers && a.drivers.length && b && b.drivers && b.drivers.length) {
        return a.drivers.length - b.drivers.length;
      } if (a && a.drivers && a.drivers.length) {
        return -1;
      } if (b && b.drivers && b.drivers.length) {
        return 1;
      }
      return 0;
    },
    render: (drivers: string[] | string) => (
      <>
        { typeof drivers !== 'string'
          ? drivers?.map((driver, k) => (
            <div className="bolt-14px" key={k}>
              {driver}
            </div>
          ))
          : <span className="bolt-14px">
            {drivers}
          </span>
        }
      </>
    ),
    align: 'center',
    className: style.cell_styles,
  },
  {
    title: '',
    dataIndex: 'pallex_error',
    key: 'pallex_error',
    align: 'center',
    className: style.cell_styles,
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    align: 'center',
    className: style.cell_styles,
  },
];

export const makeColumnsApproveArchive = (t:TFunction, onChange: (status, shipment) => void)
    : ColumnsType<ApproveArchiveTableColumnsInterface> => [
  {
    title: t('forms.deliveries.id'),
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  },
  {
    title: t('forms.deliveries.number_pallets'),
    dataIndex: 'number_pallet',
    key: 'number_pallet',
    align: 'center',
    sorter: (a, b) => (
      a.number_pallet.reduce((prev, next) => prev + next) - b.number_pallet.reduce((prev, next) => prev + next)
    ),
    render: (numbers) => (
      numbers.map((number, k) => (
        <div className={classnames(style.primary_color, 'bolt-14px')} key={`${k} ${number}`}>
          {number}
        </div>
      ))),
  },
  {
    title: t('shipmentList.pickupName'),
    dataIndex: 'pickupName',
    key: 'pickupName',
    align: 'center',
  },
  {
    title: t('shipmentList.shippingName'),
    dataIndex: 'shippingName',
    key: 'shippingName',
    align: 'center',
  },
  {
    title: t('forms.deliveries.approved_date'),
    dataIndex: 'approved_date',
    key: 'approved_date',
    align: 'center',
    sorter: (a, b) => new Date(a.approved_date).getTime() - new Date(b.approved_date).getTime(),
    render: (date) => (date ? format(new Date(date), 'dd-MM-yyyy') : ''),
  },
  {
    title: t('forms.deliveries.client_name'),
    dataIndex: 'recipient_name',
    key: 'recipient_name',
    align: 'center',
    sorter: (a, b) => {
      if (a && a.recipient_name && a.recipient_name.length && b && b.recipient_name && b.recipient_name.length) {
        return a.recipient_name.length - b.recipient_name.length;
      } if (a && a.recipient_name && a.recipient_name.length) {
        return -1;
      } if (b && b.recipient_name && b.recipient_name.length) {
        return 1;
      }
      return 0;
    },
    render: (text) => <span className="bolt-14px">{text}</span>,
  },
  {
    title: t('forms.deliveries.date'),
    dataIndex: 'start_date',
    key: 'start_date',
    render: (date) => (date ? format(new Date(date), 'dd-MM-yyyy') : ''),
    sorter: (a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
    align: 'center',
  },
  {
    title: t('forms.deliveries.delivery_date'),
    dataIndex: 'withdrawal_date',
    key: 'withdrawal_date',
    render: (date) => (date ? format(new Date(date), 'dd-MM-yyyy') : ''),
    sorter: (a, b) => new Date(a.withdrawal_date).getTime() - new Date(b.withdrawal_date).getTime(),
    align: 'center',
  },
  {
    title: <span>
      {t('forms.deliveries.status')}
      <Tooltip title={t('messages.info_shipment_column')}>
        <img src={infoIcon} alt="info" className={style.table_icon}/>
      </Tooltip>
    </span>,
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: ({
      shipment, applicant, contractorIsPallex, contractorIsTPPallex,
    }) => statusesColumn(shipment, applicant, onChange, contractorIsPallex, contractorIsTPPallex),
  },
  {
    title: t('forms.deliveries.price'),
    dataIndex: 'price',
    key: 'price',
    render: (text) => <span className="bolt-14px">
      {text}
    </span>,
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    align: 'center',
  },
];

export const maceColumnsToBeApproved = (t:TFunction)
  : ColumnsType<ToBeApprovedTableColumnsInterface> => [
  {
    title: t('forms.deliveries.date'),
    dataIndex: 'start_shipment_date',
    key: 'start_shipment_date',
    render: (date) => (date ? format(new Date(date), 'dd-MM-yyyy') : ''),
    sorter: (a, b) => new Date(a.start_shipment_date).getTime() - new Date(b.start_shipment_date).getTime(),
    align: 'center',
  },
  {
    title: t('forms.deliveries.id'),
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  },
  {
    title: t('shipmentList.pickupName'),
    dataIndex: 'pickupName',
    key: 'pickupName',
    align: 'center',
  },
  {
    title: t('shipmentList.shippingName'),
    dataIndex: 'shippingName',
    key: 'shippingName',
    align: 'center',
  },
  {
    title: t('forms.deliveries.number_pallets'),
    dataIndex: 'number_pallet',
    key: 'number_pallet',
    sorter: (a, b) => (
      a.number_pallet.reduce((prev, next) => prev + +next.split('-')[0], 0) - b.number_pallet.reduce((prev, next) => prev + +next.split('-')[0], 0)
    ),
    align: 'center',
    render: (numbers) => (
      numbers.map((number, k) => (
        <div className={classnames(style.primary_color, 'bolt-14px')} key={`${k} ${number}`}>
          {number}
        </div>
      ))
    ),
  },
  {
    title: t('forms.deliveries.client_name'),
    dataIndex: 'recipient_name',
    key: 'recipient_name',
    sorter: (a, b) => {
      if (a && a.recipient_name && a.recipient_name.length && b && b.recipient_name && b.recipient_name.length) {
        return a.recipient_name.length - b.recipient_name.length;
      } if (a && a.recipient_name && a.recipient_name.length) {
        return -1;
      } if (b && b.recipient_name && b.recipient_name.length) {
        return 1;
      }
      return 0;
    },
    align: 'center',
    render: (text) => <span className="bolt-14px">{text}</span>,
  },
  {
    title: t('forms.estimations.measures'),
    dataIndex: 'measures',
    key: 'measures',
    render: (measures) => (
      measures.map((measure, k) => (
        <div key={`${k} ${measure}`}>{measure}</div>
      ))
    ),
    align: 'center',
  },
  {
    title: (
      <span>
        {t('forms.deliveries.status')}
        <Tooltip title={t('messages.info_shipment_column')}>
          <img src={infoIcon} alt="info" className={style.table_icon}/>
        </Tooltip>
      </span>
    ),
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: ({ shipment }) => <Status status={shipment.status} />,
  },
  {
    title: t('forms.estimations.from_to'),
    dataIndex: 'from_to',
    key: 'from_to',
    render: (text) => <span className="bolt-14px">{text}</span>,
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    align: 'center',
  },
];

export const makeColumnsRejected = (t:TFunction, onChange: (status, shipment) => void)
    : ColumnsType<RejectedTableColumnsInterface> => [
  {
    title: t('forms.deliveries.id'),
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  },
  {
    title: t('forms.deliveries.client_name'),
    dataIndex: 'recipient_name',
    key: 'recipient_name',
    sorter: (a, b) => {
      if (a && a.recipient_name && a.recipient_name.length && b && b.recipient_name && b.recipient_name.length) {
        return a.recipient_name.length - b.recipient_name.length;
      } if (a && a.recipient_name && a.recipient_name.length) {
        return -1;
      } if (b && b.recipient_name && b.recipient_name.length) {
        return 1;
      }
      return 0;
    },
    align: 'center',
    render: (text) => <span className="bolt-14px">{text}</span>,
  },
  {
    title: t('forms.deliveries.number_pallets'),
    dataIndex: 'number_pallet',
    key: 'number_pallet',
    sorter: (a, b) => (
      a.number_pallet.reduce((prev, next) => prev + +next.split('-')[0], 0) - b.number_pallet.reduce((prev, next) => prev + +next.split('-')[0], 0)
    ),
    align: 'center',
    render: (numbers) => (
      numbers.map((number, k) => (
        <div className={classnames(style.primary_color, 'bolt-14px')} key={`${k} ${number}`}>
          {number}
        </div>
      ))),
  },
  {
    title: t('forms.deliveries.measures'),
    dataIndex: 'measures',
    key: 'measures',
    render: (measures) => (
      measures.map((measure, k) => (
        <div key={`${k} ${measure}`}>{measure}</div>
      ))
    ),
    align: 'center',
  },
  {
    title: t('forms.deliveries.rejected_date'),
    dataIndex: 'rejected_date',
    key: 'rejected_date',
    render: (date) => (date ? format(new Date(date), 'dd-MM-yyyy') : ''),
    sorter: (a, b) => new Date(a.rejected_date).getTime() - new Date(b.rejected_date).getTime(),
    align: 'center',
  },
  {
    title: t('forms.estimations.status'),
    dataIndex: 'status',
    key: 'status',
    render: (status) => <Status status={status}/>,
    align: 'center',
  },
  {
    title: t('forms.deliveries.price'),
    dataIndex: 'price',
    key: 'price',
    render: (text) => <span className="bolt-14px">{text}</span>,
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    align: 'center',
  },
];
