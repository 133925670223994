import {
  Col, DatePicker, Form, Input, Row,
} from 'antd';
import { FilterFilled, ReloadOutlined } from '@ant-design/icons/lib/icons';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import _ from 'lodash';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CustomSelect';
import { FilterToBeApproveShippingRequest } from 'store/types';
import { TPStatuses } from 'enums';
import style from '../deliveries.module.css';

type ShippingToBeApproveFilterType = {
  setFilter: (obj) => void,
  rejected?: boolean
}
const ShippingToBeApproveFilter:React.FC<ShippingToBeApproveFilterType> = ({
  setFilter, rejected = false,
}) => {
  const { t } = useTranslation();
  const [advancedForm] = Form.useForm();
  const [nameForm] = Form.useForm();
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const onChangeFilterHandler = _.debounce((param: keyof FilterToBeApproveShippingRequest, value) => {
    setFilter((prevState) => ({ ...prevState, ...{ [param]: value } }));
  }, 500);
  const orResetFilterHandler = () => {
    setFilter({});
    advancedForm.resetFields();
    nameForm.resetFields();
  };
  return (
    <>
      <div className={style.filter_container}>
        <Form form={nameForm}>
          { !rejected && (
            <Form.Item name="tracking_status" className={style.status_select}>
              <CustomSelect
                placeholder={t('forms.estimations.select_placeholder')}
                onChange={(v) => onChangeFilterHandler('tracking_status', v || undefined)}
                options={[
                  { title: TPStatuses.In_attesa_di_approvazione, value: TPStatuses.In_attesa_di_approvazione },
                  { title: TPStatuses.In_attesa_di_pagamento, value: TPStatuses.In_attesa_di_pagamento },
                ]}
              />
            </Form.Item>
          )}
          <Form.Item name="search_bar" className={style.status_select}>
            <Input
              onChange={(v) => onChangeFilterHandler('search_bar', v.target.value || undefined)}
              className={style.select_width}
              placeholder={t('forms.users.search')}
            />
          </Form.Item>
        </Form>
        <CustomButton
          className={style.searchBtn}
          onClick={() => setIsAdvanced(!isAdvanced)}
          icon={<FilterFilled/>}
        >
          {t('forms.estimations.filter_button')}
        </CustomButton>
        <CustomButton
          className={style.resetBtn}
          icon={<ReloadOutlined/>}
          onClick={orResetFilterHandler}
        >
          {t('forms.estimations.reset_button')}
        </CustomButton>
      </div>
      {isAdvanced && (
        <Form form={advancedForm} className={style.filter_form_container}>
          <Row className={style.height_container}>
            <Col>
              <p className={style.labelSearch}>{t('forms.deliveries.client_name')}</p>
              <Form.Item name="userName">
                <Input onChange={(v) => { onChangeFilterHandler('userName', v.target.value || undefined); }} />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <p className={style.labelSearch}>{t('shipmentList.collectionDateSearch')}</p>
              <Form.Item name="withdrawal_date">
                <DatePicker className="date_input" onChange={(v) => { onChangeFilterHandler('withdrawal_date', v ? v.utc().startOf('day').toISOString() : undefined); }}/>
              </Form.Item>
            </Col>
            <Col offset={1}>
              <p className={style.labelSearch}>{t('shipmentList.deliveryDateSearch')}</p>
              <Form.Item name="start_shipment_date">
                <DatePicker className="date_input" onChange={(v) => { onChangeFilterHandler('start_shipment_date', v ? v.utc().startOf('day').toISOString() : undefined); }}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ShippingToBeApproveFilter;
