import { useCallback, useMemo } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import OptionsButton, { Option } from '../../../../components/OptionsButton';
import { makeColumnsFinanceExtra } from './TableColumns';
import { ExtraFinanceType } from '../../../../store/types';
import SuccessIconSvg from '../../../../assests/svg/SuccessIconSvg';
import invoicesStyles from '../../invoices.module.css';

type InvoicesTablePropsType = {
  extraData: ExtraFinanceType[],
  approve: (id:number) => void,
  disapprove: (id:number) => void,
  editModal: (data: number) => void,
  deleteExtra: (data: number) => void,
}

const FinanceExtraTable = ({
  extraData, approve, disapprove, editModal, deleteExtra,
}: InvoicesTablePropsType) => {
  const { t } = useTranslation();

  const approveOrDisapproveRender = useCallback((isApproved: boolean, id: number) => {
    if (isApproved) {
      return <button className={invoicesStyles.disapprove_btn} onClick={() => disapprove(id)}></button>;
    }
    return <button className={invoicesStyles.finance_table_btn} onClick={() => approve(id)}><SuccessIconSvg/></button>;
  }, [approve, disapprove]);

  const options: Array<Option> = useMemo(() => [
    {
      title: t('forms.estimations.edit'),
      action: (id: string) => editModal(+id),
    },
    {
      title: t('forms.estimations.delete'),
      action: (id: string) => deleteExtra(+id),
    },
  ], [deleteExtra, editModal, t]);

  const newTableData = useMemo(() => (
    extraData?.map((item) => ({
      key: item.id,
      isApproved: item.isApproved,
      orderId: item.request.spedizione_id,
      approvato_il: format(new Date(item.approvato_il), 'dd-MM-yyy'),
      description: item.description,
      quantity: item.quantity,
      price: item.price,
      approveOrDisapprove: (
        <div className={invoicesStyles.finance_btn_container}>
          {approveOrDisapproveRender(item.isApproved, item.id)}
        </div>
      ),
      options: (
        <OptionsButton
          id={item.id.toString()}
          options={options}
        />
      ),
    }))
  ), [extraData, approveOrDisapproveRender, options]);

  const columns = makeColumnsFinanceExtra(t);

  return (
    <Table
      rowClassName={(record, index) => (record.isApproved ? 'row_approved' : '')}
      columns={columns}
      dataSource={newTableData}
      pagination={false}
    />
  );
};

export default FinanceExtraTable;
