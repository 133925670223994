import React, { useState } from 'react';
import {
  Button, Dropdown, Space, Menu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import style from '../../containers/Estimations/estimations.module.css';
import { EditableOption } from './EditableOption';
import { AddButton } from './AddButton';
import { Option } from '../../store/types';

interface Props {
  initialValue: string | number;
  options: Option[];
  onCheck: (value: string) => void;
  onAdd: () => void;
  onEdit: (value: string) => void;
  onDelete: (value: string) => void;
}

type EditableSelectStatelessProps = Omit<Props, 'initialValue'> & {name: string | number, setName?: (v:string | number)=>void};

export const EditableSelectStateless: React.FC<EditableSelectStatelessProps> = ({
  options,
  onCheck,
  onAdd,
  onEdit,
  onDelete,
  name,
  setName,
}) => {
  const onCheckHandler = (v: string) => {
    const option = options.find((o) => o.id.toString() === v) || options[0];
    if (setName) setName(option.name);
    onCheck(v);
  };

  const menu = (<Menu
    defaultSelectedKeys={[name.toString()]}
    items={options.map((o) => ({
      key: o.name,
      label: (<EditableOption
        label={o.name}
        value={o.id.toString()}
        isEditable={o.isEditable || false}
        onCheck={onCheckHandler}
        onEdit={onEdit}
        onDelete={onDelete}
      />),
    })).concat({
      key: '-1',
      label: (<AddButton onClick={onAdd}/>),
    })}/>);

  return (
    <Dropdown overlay={menu} className={style.contractorSelector}
      trigger={['click']}
    >
      <Button onClick={(e) => e.preventDefault()}>
        <Space className={style.contractorSelectorArrow}>
          {name}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export const EditableSelect: React.FC<Props> = ({
  initialValue, ...props
}) => {
  const [name, setName] = useState<string | number>(initialValue);
  return <EditableSelectStateless {...props} name={name} setName={setName}/>;
};
