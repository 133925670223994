import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ShipmentsListStructure,
} from '../../types';

export const requestDeleteRejectedShipmentAction = createAction<string>('requestDeleteRejectedShipmentAction');

type State = {
  rejectedShipments: ShipmentsListStructure | null,
  loading: boolean,
}

const initialState: State = {
  rejectedShipments: null,
  loading: false,
};

const shippingRejcetedSlice = createSlice({
  name: 'shippingRejceted',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setRejectedShipmentsData: (state, action: PayloadAction<ShipmentsListStructure | null>) => {
      state.rejectedShipments = action.payload;
    },
  },
});

export const {
  setRejectedShipmentsData, setLoading,
} = shippingRejcetedSlice.actions;

export default shippingRejcetedSlice.reducer;
