import { useTranslation } from 'react-i18next';
import { Row, Col, Modal } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { useEffect, useState } from 'react';
import style from '../deliveries.module.css';
import CustomButton from '../../../components/CustomButton/CustomButton';
import ShippingToBeApprovedTable from './ShippingToBeApprovedTable';
import ModalWithCarousel from '../../../components/ModalWithCarousel';
import { useAppSelector, useAppDispatch } from '../../../store/store';
import Loader from '../../../components/Loader';
import { requestUsers } from '../../../store/redux_slice/users/usersSlice';
import { ModalInfoToBeApproved } from '../../Estimations/ModalInfo';
import { requestGetShippingServices } from '../../../store/redux_slice/shippingServicesSlice';
import { requestGetPallexHubs } from '../../../store/redux_slice/pallexHubsSlice';
import { requestGetPriceListTemplates } from '../../../store/redux_slice/priceListTemplatesSlice';
import { requestOperatorsList } from '../../../store/redux_slice/operators/operatorsListSlice';
import {
  setToBeApprovedShipmentsData,
} from '../../../store/redux_slice/shipping/shippingBeforeApproveSlice';
import { setCleanShipmentDataFromNotification } from '../../../store/redux_slice/notificationsSlice';
import ShippingToBeApproveFilter from './ShippingToBeApproveFilter';
import { FilterToBeApproveShippingRequest } from '../../../store/types';
import { useSocket } from '../../../hooks/useSocket';
import { requestGetContractors } from '../../../store/redux_slice/contractors/contractorsSlice';
import { useFixedOrder } from '../../../hooks/useFixedOrder';
import { PathEnums } from 'enums';

const ShippingToBeApprovedContainer = () => {
  const { t } = useTranslation();
  const socket = useSocket();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(10);
  const [filter, _setFilter] = useState<Partial<FilterToBeApproveShippingRequest>>({});
  const [selectedId, setSelectedId] = useState<string|undefined>();
  const [visible, setVisible] = useState(false);
  const setFilter = (data) => {
    _setFilter(data);
    setPage(1);
  };
  const toBeApprovedShipments = useAppSelector(
    (state) => state.shippingBeforeApprove.toBeApproved,
  );
  const users = useAppSelector(
    (state) => state.users.users,
  );
  const {
    shimpentDataFromNotification, tableTypeLoading, notificationShipmentLoading,
  } = useAppSelector((state) => state.notifications);

  useEffect(() => {
    socket.emit('DAAPPROVATE', {
      ...filter,
      page,
      page_size: count,
    });
  }, [socket, dispatch, page, count, filter]);

  useEffect(() => {
    socket.connect();
    socket.on('DAAPPROVATE', (data) => {
      dispatch(setToBeApprovedShipmentsData(data?.message));
    });
    return () => {
      socket.off('DAAPPROVATE');
      dispatch(setToBeApprovedShipmentsData(null));
    };
  }, [socket, dispatch]);

  useEffect(() => {
    dispatch(requestGetShippingServices());
    dispatch(requestGetPallexHubs());
    dispatch(requestGetPriceListTemplates());
    dispatch(requestUsers());
    dispatch(requestGetContractors());
    dispatch(requestOperatorsList(true));
  }, [dispatch]);

  const initialSlide = toBeApprovedShipments?.items?.findIndex((e) => String(e.id) === String(selectedId)) ?? -1;

  const itemsWithFixedOrder = useFixedOrder(
    visible,
    toBeApprovedShipments?.items ?? [],
    selectedId ? String(selectedId) : undefined,
    () => setVisible(false),
  );
  if (!toBeApprovedShipments || !users) {
    return <Loader/>;
  }

  return (
    <>
      <Modal
        centered
        visible={!!searchParams.get('dettaglio')}
        footer={false}
        width={'80%'}
        className="custom_modal"
        onCancel={() => {
          searchParams.delete('dettaglio');
          setSearchParams(searchParams);
          dispatch(setCleanShipmentDataFromNotification());
        }}
      >
        { notificationShipmentLoading || tableTypeLoading ? <Loader/> : (
          shimpentDataFromNotification && <ModalInfoToBeApproved
            estimation={shimpentDataFromNotification}
            isEstimation={false}
            setVisible={() => {
              setVisible(false);
              setSelectedId(undefined);
            }}
          />
        )}
      </Modal>
      <Row className={style.shipping_approved_container}>
        <ModalWithCarousel
          key={selectedId}
          initialSlide={initialSlide}
          visible={visible}
          setVisible={() => {
            setVisible(false);
            setSelectedId(undefined);
          }}
          content={
            itemsWithFixedOrder.map((shipment) => (
              <ModalInfoToBeApproved
                key={shipment.id}
                estimation={shipment}
                isEstimation={false}
                setVisible={() => {
                  setVisible(false);
                  setSelectedId(undefined);
                }}
              />
            ))
          }
        />
        <Col span={12}>
          <div className={style.to_be_approved_title}>
            {`${t('forms.deliveries.shipments_to_be_approved')} (${toBeApprovedShipments.count})`}
          </div>
          <ShippingToBeApproveFilter
            setFilter={setFilter}/>
        </Col>
        <Col span={4} offset={8}>
          <CustomButton
            type="primary"
            icon={<PlusOutlined/>}
            className={style.newShipmentBtn}
            href={PathEnums.New_shipment_deliveries}
          >
            {t('forms.deliveries.new_shipment')}
          </CustomButton>
        </Col>
      </Row>
      <ShippingToBeApprovedTable
        setVisible={() => setVisible(true)}
        toBeApprovedShipments={toBeApprovedShipments}
        // setInitialSlide={setInitialSlide}
        setSelectedId={setSelectedId}
        page={page}
        count={count}
        setPage={setPage}
        setCount={setCount}
      />
    </>
  );
};

export default ShippingToBeApprovedContainer;
