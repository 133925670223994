import { put, takeLatest } from 'redux-saga/effects';
import { CreateTransportPayload, UpdateTransportPayload } from '../../types';
import {
  setLoading,
  requestCreateNewTransport,
  requestGetTransportById,
  requestUpdateTransportById,
  requestDeleteTransportById,
  setTransportById,
  requestGetTransportByShippingId,
} from '../../redux_slice/transport/transportByIdSlice';
import {
  CreateNewTransport,
  GetTransportList,
  UpdateTransportById,
  DeleteTransportById,
  GetTransportById,
  GetTransportByDriverId,
} from '../../../api/transtort';
import { setTransportData } from '../../redux_slice/transport/transportSlice';
import { GetSippingRequestTruck } from '../../../api/shipping';
import {
  clearTransportByDriverId,
  requestGetTransportByDriverId,
  setTransportByDriverId,
} from '../../redux_slice/transport/transportByDriverIdSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* createNewTransportSaga(action: {
    payload: CreateTransportPayload,
}) {
  yield put(setLoading(true));
  try {
    if (action.payload.busyTransport) {
      if (action.payload.truckTypeId) {
        const { data } = (yield GetTransportById(action.payload.truckTypeId.toString()));
        const updateData = {
          id: data.id,
          data: {
            plate_number: data.plate_number,
            note: data.note,
            contractor: data.contractor,
            booking_date: data.booking_date,
            truck_type_id: data.truck_type.id,
            pallet_capacity: data.pallet_capacity,
            busy: data.busy,
            driver_id: null,
          },
        };
        (yield UpdateTransportById(updateData));
      }
    }
    (yield CreateNewTransport(action.payload.data));
    const { data } = (yield GetTransportList());
    yield put(setTransportData(data));
    yield put(clearTransportByDriverId());
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getTransportByIdSaga(action: {
    payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetTransportById(action.payload));
    yield put(setTransportById(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getTransportByDriverIdSaga(action: {
  payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetTransportByDriverId(action.payload));
    yield put(setTransportByDriverId(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* deleteTransportByIdSaga(action: {
    payload: string,
}) {
  yield put(setLoading(true));
  try {
    (yield DeleteTransportById(action.payload));
    const { data } = (yield GetTransportList());
    yield put(setTransportData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* updateTransportByIdSaga(action: {
    payload: UpdateTransportPayload,
}) {
  yield put(setLoading(true));
  try {
    (yield UpdateTransportById(action.payload));
    const { data } = (yield GetTransportList());
    yield put(setTransportData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getTransportByShippingIdSaga(action: {
  payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetSippingRequestTruck(action.payload));
    yield put(setTransportById(data.truck));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* transportByIdSaga() {
  yield takeLatest(requestCreateNewTransport, createNewTransportSaga);
  yield takeLatest(requestGetTransportById, getTransportByIdSaga);
  yield takeLatest(requestDeleteTransportById, deleteTransportByIdSaga);
  yield takeLatest(requestUpdateTransportById, updateTransportByIdSaga);
  yield takeLatest(requestGetTransportByShippingId, getTransportByShippingIdSaga);
  yield takeLatest(requestGetTransportByDriverId, getTransportByDriverIdSaga);
}
