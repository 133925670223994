import { useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { inputValuesTypes } from '../../InvoicesTable';
import { FinanceSectionType } from '../../../../store/types';
import styles from './inputs.module.css';
import './input.css';

type InputProps = {
  defaultValue: {
    shipment: FinanceSectionType,
    value?: number | null,
    type?: string,
  },
  name: string,
  label?: string,
  onChange: (arg: inputValuesTypes) => void
  suffix?: boolean,
  addonAfter?: boolean,
}

const { Option } = Select;

const CustomInput = ({
  defaultValue, name, label, onChange, suffix = false, addonAfter = false,
}: InputProps) => {
  const { t } = useTranslation();
  const [valueState, setValue] = useState<number | string>(defaultValue?.value || '');
  const [error, setError] = useState<boolean>(false);
  const [type, setType] = useState<string>('PERCENT');

  const onChangeValueHandler = _.debounce((e) => {
    const { value } = e.target;

    if (!value || +value === 0 || (value && !Number.isNaN(value) && value > 0)) {
      if (!Number.isInteger(+value)) {
        setError(true);
        return;
      }
      setError(false);
      setValue(+value);
      onChange({
        value: +value,
        name,
        shipment: defaultValue.shipment,
        type,
      });
    } else {
      setError(true);
    }
  }, 500);

  const onChangeTypeHandler = (selectedType) => {
    setType(selectedType);
    onChange({
      value: +valueState,
      name,
      shipment: defaultValue.shipment,
      type: selectedType,
    });
  };

  const selectAfter = (
    <Form.Item name="extraSuffix" noStyle>
      <Select
        defaultValue={defaultValue.shipment.extra.type}
        onChange={onChangeTypeHandler}
        disabled={defaultValue.shipment.isApproved}
      >
        <Option value="PERCENT">%</Option>
        <Option value="DIGIT">€</Option>
      </Select>
    </Form.Item>
  );
  return (
    <div className={styles.input_container}>
      { label && <p className={styles.label}>{label}</p>}
      <Form.Item name={name} noStyle>
        <Input
          value={valueState}
          className={cn(styles.input_styles, 'finance_input_styles')}
          onChange={onChangeValueHandler}
          disabled={defaultValue.shipment.isApproved}
          suffix={suffix && <span className="suffix">%</span>}
          addonAfter={addonAfter && selectAfter}
        />
      </Form.Item>
      {error && <p className={styles.error_message}>{t('invoices.table.isNumberValidation')}</p>}
    </div>
  );
};

export default CustomInput;
