export default {
  en: {
    translation: {
      errors: {
        pallet_price_calculation_error: 'Pallet price calculation error. Contact technical support',
        creating_price_list_error: 'Error creating price list',
      },
      menu: {
        trackingShipments: 'Tracking Shipments',
        signIn: 'Sign in',
        whoWeAre: 'Who we are',
        services: 'Services',
        environment: 'Environment',
        gruppoStorti: 'Gruppo storti',
        contacts: 'Contacts',
        shippingEstimates: 'Shipping estimates',
        privacyPolicy: 'Privacy Policy',
      },
      privateMenu: {
        dashboard: 'Dashboard',
        deliveries: 'Withdrawals and deliveries',
        estimates: 'Estimates',
        priceList: 'Price lists',
        personalData: 'Personal data',
        machines: 'Machines',
        manifest: 'Unloading and loading manifest',
        profile: 'Personal profile',
        operators: 'Operators',
        logOut: 'Log out',
        notifications: 'Notifications',
        invoices: 'Invoices to be sent',
        monitoring: 'Monitoring',
      },
      banner: {
        logIn: 'Log in to your account',
        forgotPassword: 'Password recovery',
        restorePassword: 'Restore password',
        shipmentRequestStep3: 'Confirm quote request',
        trackingSearch: 'Tracking search',
        trackingNumber: 'Tracking',
        shipmentConfirm: 'Confirm shipping',
        estimationConfirm: 'Confirm estimation',
        shipmentEstimate: 'Estimate',
      },
      buttons: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        add: 'Add',
        save: 'Save',
        ok: 'Ok',
        reset: 'Reset',
        reject: 'Reject',
        print_label: 'Pallex label printing',
      },
      req_statuses: {
        Da_Elaborare: 'New',
        Inviato: 'Envoy',
        Inviato_follow_up: 'Sent follow up',
        Confermato: 'Confirmed',
        Rifiutato: 'Rejected',
        Scaduto: 'Expired',
      },
      forms: {
        contractor: {
          name: 'Business name',
          email: 'Email',
          phone: 'Phone number',
          vatNumber: 'VAT number',
          address: 'Registered office address',
          is_address_different: 'Operational headquarters address different from the registered office address',
          confirm: 'Confirm',
        },
        login: {
          emailAdress: 'Email address',
          password: 'Password',
          forgotPassword: 'Forgot password?',
          signIn: 'Sign in',
        },
        restorePassword: {
          email: 'Enter your email',
          description: 'Enter your email and we will send you a link with which it will be possible to recover your password.',
          back: 'Back',
          restore: 'Restore',
        },
        shipmentRequest: {
          enter_order_name: 'Enter your company name',
          enter_order_address: 'Enter your legal address',
          from: 'Sender data:',
          from_full: 'Goods collection location:',
          to_full: 'Goods delivery location:',
          to: 'Recipient data:',
          quantity: 'Quantity',
          shortSide: 'Short side',
          longSide: 'Long side',
          height: 'Height',
          weight: 'Single pallet weight',
          addPallets: 'Add pallets',
          pickupDate: 'Goods ready date',
          requestPrice: 'Request price',
          enterPostCode: 'Enter postcode or city',
          extraServices: 'Extra services',
          dayAndTime: 'Day and time set for delivery',
          expressServices: 'Non-stop express service',
          highwayDelivery: 'Highway delivery',
          contactEmail: 'Contact email',
          phoneNumber: 'Phone number',
          confirmRequest: 'Confirmation request',
          back: 'Back',
          quoteSent: 'Your request for a quote has been sent!',
          confirm_description: 'Within a few hours your order will be processed and you will be updated via email',
          information: 'Do you need more information?',
          contactUs: 'Contact us now',
          trackingNumber: 'YOUR TRACKING NUMBER',
          companyName: 'Company name or Name and Surname',
          nameAndSurname: 'Contact person (only if company)',
          shippingType: 'Shipping type',
          withdrawal: 'Withdrawal',
          delivery: 'Delivery',
          triangulation: 'Triangulation',
          checkbox_title: 'Press here if the address corresponds to the operational headquarters',
          start_shipment_date: 'Goods ready date',
          start_shipment_time: 'Goods ready time',
          start_shipment_time_note: 'Goods ready time',
          non_stop_date_ritiro: 'Compulsory collection date (for NON STOP collections only)',
          non_stop_date_ritiro_2: 'Compulsory collection date',
          non_stop_date_consegna: 'Compulsory delivery date (for NON STOP collections only)',
          non_stop_date_consegna_2: 'Compulsory delivery date',
          telefonica_ritiro: 'Enter phone number (Withdraw)',
          telefonica_consegna: 'Enter phone number (Delivery)',
          facchinaggio_ritiro: 'Enter porterage (Withdraw)',
          facchinaggio_consegna: 'Enter porterage (Delivery)',
          select_delivery_for_service: 'Select order type requirement',
          withdrawal_date: 'Possible reserved delivery date (not mandatory)',
          withdrawal_date_guest: 'Compulsory delivery date (only if non-stop/express service is required)',
          client_code: 'Client code',
          shipping_client_code: 'Shipping customer code',
          informazioni_aggiuntive: 'Additional Information',
          order_number: 'N° Order',
          ddt_number: 'DDT number',
          note_ritiro: 'Pickup notes',
          note_consegna: 'Delivery notes',
          optional: 'Optional',
        },
        shipmentDetails: {
          title: 'shipping detail',
          VAT: 'vat',
          quantity: 'Quantity',
          shortSide: 'Short side',
          longSide: 'Long side',
          height: 'Height',
          retirementDate: 'Goods ready date',
          delivery: 'Expected delivery date',
          pickupAddress: 'Location of collection of goods',
          shippingAddress: 'Location of delivery of goods',
          extraServices: 'Extra services',
          confirmEstimation: 'Confirm quote',
          pickUpAddress: 'Pickup address',
          destinationAddress: 'Destination address',
          agency: 'Agency',
          private: 'Private',
          street: 'Street',
          businessName: 'Business name',
          referenceName: 'Reference Name and Surname',
          twoStepButtonTitle: 'Confirm addresses',
          pallet: 'pallet',
          billingInfo: 'Billing information',
          notification: 'Our system will extrapolate the data necessary to carry out the electronic invoice by querying the public databases',
          vatNumber: 'VAT number',
          streetAndCity: 'Enter street and city',
          n: 'n°',
          sameAdress: 'The address of the registered office is the same with the collection office',
          payment: 'Payment method',
          card: 'Credit card',
          bank_check: 'Bank receipt',
          bank_transfer: 'Bank transfer',
          send: 'Send',
          unavailable: 'unavailable',
          IVA_text: 'The above price is VAT excluded and is to be considered for delivery on the street and in an accessible area. Fuel Surcharge excluded.',
          cancel_quote: 'Cancel quote',
          confirmation_title: 'Your shipment is confirmed',
          no_data: 'No Data',
          select_gdo: 'GDO',
          select_company: 'GDO information',
          select_who_is_gdo: 'Select who is the GDO',
          add_telephone: 'Telephone reservation',
          telephonica: 'Booking p.',
        },
        tracking: {
          enterTrackingNumber: 'Enter tracking number',
          send: 'Send',
          waiting: 'Waiting to be withdrawn',
          withdrawn: 'Withdrawn',
          onWay: 'In transition',
          delivering: 'Delivering',
          delivered: 'Delivered',
          required: 'Enter your tracking number',
        },
        estimations: {
          estimates: 'Estimates',
          archived: 'Archived',
          select_placeholder: 'Filter by status',
          filter_button: 'Advanced search',
          reset_button: 'Reset',
          new_estimates: 'New quote',
          date: 'Withdrawal date',
          id: 'Id',
          email: 'Email',
          number_pallet: 'N°pallet',
          measures: 'Measures',
          from_to: 'From > To',
          status: 'Status',
          cost: 'Cost',
          edit: 'Edit',
          delete: 'Delete',
          withdrawal_request_detail: 'withdrawal request detail',
          checkbox_title: 'The required shipment is in triangulation',
          sender: 'Sender',
          destination: 'Destination',
          both: 'Both',
          define_medium: 'Define medium',
          manage_with_pallex: 'Pallex Italy',
          manage_with_external: 'TP or supplier',
          assign_price_list: 'Assign price list',
          send: 'Send quote',
          add_extra: 'Add extra service',
          update_address: 'Update address',
          update_date: 'Update date',
          update_time: 'Update time',
          select_extra_services: 'Select extra services',
          select_hub: 'Select HUB',
          change_ecological_pallet: 'Change the number of ecological pallets',
          change_fasci: 'Change the number of fasci',
          text_fasci: 'N.B. Maximum dimensions 300x30x30 maximum weight 30 Kg.',
          fasci_validation: 'It is not possible to request more goods in bundles than the number of pallets making up the shipment.',
          change_contrassegno: 'Change the contrassegno',
          contrassegno_value_validation: 'Value must be at least 1',
          change_facchinaggio: 'Change the facchinaggio',
          change_non_stop: 'Change the Non-Stop',
          change_ore_note: 'Change the Ore Prestabilita',
          update_price: 'Update price',
          order_type: 'Order type',
          update: 'Update',
          contractor_type: 'Contractor Type',
          delivery_type: 'Delivery Type',
          user_price_list: 'User Price List',
          approve: 'Send Quote',
          update_contractor: 'Select which external service you want to use',
          pallex_estimate: 'Pallex estimate',
          hub: 'Hub',
          ecological_pallet: 'Epal number assigned',
          fasci: 'Fasci',
          non_stop: 'Non-Stop',
          contrassegno: 'Contrassegno',
          facchinaggio: 'Facchinaggio',
          ore_note: 'Ore Prestabilita',
          quote_detail: 'quote detail',
          notAvailableExtra: 'Not available for the chosen price list',
          non_stop_in_danger_zone: 'Remove the NON-STOP due to Zone disagiate service',
          extra_service_error: 'Price is required',
          deleted_user: 'User deleted',
          tracking: 'Tracking',
          disponsible: 'Available',
          non_disponsible: 'Unavailable',
        },
        newEstimation: {
          new_quote: 'new quote',
          fixed_minimum: 'Fixed minimum',
          requested_to: 'Requested to',
          email: 'Contact email',
          phone: 'Telephone number',
          from: 'Indicate the place of collection of goods',
          to: 'Indicates place of delivery of goods',
          placeholder: 'Enter postcode or city',
          date: 'Withdrawal date',
          price_list: 'Associated price list',
          manage: 'Manage with subcontractor',
          transportation: 'Heavy Transportation',
          required_shipment: 'The required shipment is in triangulation',
          extra_service_title: 'Extra services',
          extra_service_title_with_triang: 'Extra services(R: Withdraw, C:Delivery)',
          total_cost: 'Total cost Estimated Pallex:',
          tot_cost: 'Tot. cost:',
          number_pallet: 'N° pallet',
          weight: 'Single pallet weight',
          adr: 'Dangerous Goods',
          hub: 'Hub',
          optional: 'Optional',
          quantity: 'Quantity',
          shortSide: 'Short side',
          longSide: 'Long side',
          height: 'Height',
          price: 'Price',
          add_pallet: 'Add pallets',
          proceed: 'Upload the XLSX file',
          cancel: 'Cancel',
          or: 'Or',
          manual_loading: 'Manual loading',
          confirmation: 'Shipping confirmation',
          service_name: 'Accessory service name',
          service_details: 'Accessory service description',
          apply_to: 'To apply to',
          shipping_type: 'Shipping type',
          withdrawal: 'Withdrawal',
          delivery: 'Delivery',
          triangulation: 'Triangulation',
          checkbox_title: 'Press here if the address corresponds to the operational headquarters',
          manage_with: 'Manage with',
          shipping_actions: 'Shipping Actions',
          base_price: 'Base price',
          merce_adr_limited_qty: 'Limited quantity',
          merce_adr_number_onu: 'ONU number',
          merce_adr_qty: 'Quantity (kl/Lt)',
          merce_adr_packages: 'N° Packages',
          merce_adr_packaging_description: 'Packaging description',
          merce_adr_description_nos: 'Description N.O.S',
        },
        deliveries: {
          refferer: 'Referrer',
          email: 'Email',
          shipments_approved: 'Approved shipments',
          shipments_to_be_approved: 'Shipments to be approved',
          shipments_rejected: 'Rejected shipments',
          archived: 'Archived',
          approved: 'Approver',
          approved_date: 'Approved the',
          to_be_approved: 'To be approved',
          rejected: 'Rejected',
          approve: 'Approve',
          not_approved: 'Not approved',
          id: 'Id',
          new_shipment: 'New shipment',
          number_pallets: 'N°pallet',
          recipient_name: 'Recipient name',
          date: 'Withdrawal date',
          delivery_date: 'Delivery date',
          rejected_date: 'Rejected the',
          status: 'Status',
          measures: 'Measures',
          type: 'Type',
          price: 'Price',
          details: 'See detail',
          customer_data: 'Customer data',
          archive: 'Archive',
          customer: 'Customer',
          total_cost_pallex: 'Total cost Pallex',
          shipping: 'shipping',
          driver: 'Driver',
          truck: 'Truck',
          address: 'Address',
          labelDownload: 'Download label',
          client_name: 'Client name',
          applicant: 'Applicant',
          delete_shipping: 'Delete Shipping',
          delete_shipping_description: 'Are you sure you want to delete this shipping?',
          delete_estimation: 'Delete the estimate',
          delete_estimation_description: 'Are you sure you want to delete this estimate',
          change_status_for_archive: 'Select a status for archiving',
          pallex_id: 'Pallex code',
        },
        newShipment: {
          new_shipment: 'new shipment',
          customer: 'Customer',
          recipient: 'Recipient',
          contact_name: 'Contact name and surname',
          business_name: 'Business name',
          nameIsRquired: 'Enter your name',
          name_min_length: 'The name must be at least 3 characters',
          phone_number: 'Telephone number (opt)',
          details: 'Details',
          from: 'From',
          to: 'To',
          name: 'Name',
          street_and_city: 'Enter street and city',
          n: 'n°',
          price_list: 'Associated price list',
          manage: 'Manage with TP',
          fiscal_number: 'Fiscal number is required',
          select_user: 'Select a person',
        },
        helpForm: {
          submitSupportRequest: 'submit support request',
          requestSubject: 'Request subject',
          placeholder: 'Write your request here ...',
          requiredSubject: 'Please enter subject',
          requiredBody: 'Please enter your request',
          confirmEmail: 'Please check your mailbox.',
          faqButton: 'Go read the faq',
          submitButton: 'Send',
          faqText1: 'Have you tried',
          faqText2: 'to consult our',
          faqText3: 'guides?',
          faq: 'Faq',
          download_guide: 'Download the guide',
        },
        palletForm: {
          validationError: 'Please input',
          quantityError: 'Please input quantity',
          depthError: 'Please input depth',
          widthError: 'Please input width',
          heightError: 'Please input height',
          weightError: 'Please input weight',
        },
        users: {
          companies: 'Companies',
          private: 'Private',
          codica_cliente: 'Customer code (check on Teseo\'s database, if the customer exists, copy and paste his “customer code“ here)',
          new_client: 'New client',
          search: 'Free search',
          creation_date: 'Creation date',
          company_name: 'Company name',
          vat_number: 'VAT number',
          shipment_generated: 'Shipment generated',
          associated_price_list: 'Associated price list',
          status: 'Status',
          access_private: 'Access to private area',
          invite_private: 'Invite in private area',
          see_profile: 'See profile',
          update: 'Update',
          delete: 'Delete',
          create_new_client: 'add new customer',
          business_name: 'Business name',
          name: 'Name and surname',
          email: 'Email',
          notes: 'Notes (opt)',
          office_address: 'Registered office address',
          access_private_area: 'Allow access to "private area"',
          create: 'Create registry',
          close: 'Close the window',
          delete_client: 'Delete client',
          delete_client_description: 'Are you sure you want to delete this client?',
          update_client: 'update client',
          order_number: 'No. of orders',
          fiscal_code: 'Fiscal_code',
          not_exist: 'The user with this ID does not exist',
          billing_title: 'Billing',
          billing_description: '*Specify the company name and VAT number to be used to send orders to Pallex Italia. The information used in the billing section of the software will remain unchanged',
          pallex_company_name: 'Company name used for orders sent to Pallex Italia',
          pallex_vat_number: 'VAT number used for orders sent to Pallex Italia',
          invite_title: 'Attention',
          invite_description: 'Are you sure you want to invite the customer to the private area?',
          invite_submit: 'Submit',
          invite_cancel: 'Cancel',
          no_company: 'No company name',
        },
        monitoring: {
          title: 'Monitoring',
          csvButton: 'Export to CSV',
          searchInputPlaceholder: 'Operator search, description, IP',
          operator: 'Operator',
          from: 'From',
          to: 'To',
          operatorName: 'Operator name',
          details: 'Event detail',
          section: 'Section',
          ip: 'IP',
          dateAndTime: 'Date and time',
          withPagination: 'with pagination',
          withoutPagination: 'without pagination',
        },
      },
      oops: 'Oops!',
      notFound: 'The page you are looking for does not exist.',
      returnHome: 'Return home',
      estimation: {
        title: {
          request_sent_successfully_modal: 'Request sent successfully',
        },
        text: {
          request_sent_successfully: 'Request sent successfully',
        },
      },
      uploadFile: {
        uploadCSV: 'Upload .csv file',
        uploadXLSX: 'Upload .xlsx file',
        drag: 'or drag it here',
        or: 'Or',
        createNew: 'Create new price list from available template',
        proceed: 'Proceed',
        uploadPalletsList: 'Upload the pallet list in Xlsx',
        uploadPriceList: 'Upload price list csv',
        uploadExtraServices: 'Upload extra services csv',
      },
      pricelist: {
        pricelist: 'Pricelist',
        search: 'Search',
        advancedSearch: 'Advanced search',
        reset: 'Reset',
        newPricelist: 'New pricelist',
        edit: 'Edit',
        duplicate: 'Duplicate',
        personalData: 'Associate personal data',
        delete: 'Delete',
        creationDate: 'Date created',
        listName: 'List name',
        data: 'Associated data',
        shipmentsGenerated: 'Shipments generated',
        related: 'Related to quotes',
        back: 'Come back',
        correlation: 'Correlates the price list to the estimates',
        confirm: 'Confirm price list',
        delivery: 'Highway delivery',
        description: 'Highway delivery description',
        total: 'Total',
        pallet: 'Pallet',
        esternamente: 'External',
        service: 'Accessory service name',
        accessoryDescription: 'Accessory service description',
        price: 'Price',
        apply: 'Apply to',
        associate: 'Associate',
        confirmUpdate: 'Confirm',
        delete_extra: 'Delete extra service',
        delete_extra_description: 'Are you sure you want to delete this extra service?',
        csvPriceTemplate: 'Download price.CSV template file',
        delete_modal_title: 'Delete the price list',
        delete_modal_description: 'Are you sure you want to delete this price list?',
      },
      shipmentList: {
        id: 'Id',
        typology: 'Typology',
        palletNumber: 'N°pallet',
        shippingName: 'Recipient name',
        pickupName: 'Sender name',
        withdrawalDate: 'Goods ready date',
        deliveryDate: 'Any delivery date booked',
        status: 'Status',
        tracking: 'Tracking',
        modalTracking: 'shipment tracking',
        WaitingToBePickedUp: 'Waiting to be picked up',
        sent: 'Request sent',
        delivered: 'Delivered',
        rejected: 'Rejected',
        processing: 'Processing',
        delivering: 'Delivering',
        envoy: 'Envoy',
        followUp: 'Sent follow up',
        confirmed: 'Confirmed',
        managed: 'Managed by tp',
        expired: 'Expired',
        canceled: 'Canceled',
        administrator: 'Administrator',
        role: 'Role',
        customer: 'Customer',
        lead: 'Lead',
        toElebore: 'To be processed',
        shipmentsList: 'Shipments list',
        help: 'Help',
        newShipment: 'New shipment',
        newEstimation: 'New estimation',
        seeDetails: 'See details',
        download: 'Download label',
        requestSupport: 'Request support',
        delete: 'Delete',
        xlsxPalletTemplate: 'Download pallet.XLSX template file',
        xlsxOptionsTemplate: 'Download extra services.XLSX template file',
        scvInstructions: 'Shippment must be imported via uploading a precompiled .CSV file with all the necessary details.',
        xlsxInstructions: 'Shippment must be imported via uploading a precompiled .XLSX file with all the necessary details.',
        manual: 'Manual loadong',
        info: 'Shipments can also be uploaded via FTP service.',
        details: 'Details',
        name: 'Name',
        contactName: 'Contact name and surname',
        phoneNumber: 'Telephone number (opt)',
        recipientNameSearch: 'Recipient name',
        collectionDateSearch: 'Collection date',
        deliveryDateSearch: 'Delivery date',
        select_placeholder: 'Filter by status',
        notTrackingText: 'It is not possible to view the tracking for this shipment. For more information write to',
      },
      privateAreaShipmentDetails: {
        total_cost: 'Total cost:',
        sender: 'Sender:',
        recipient: 'Recipient:',
        number_pallet: 'N° pallet:',
        weight: 'Weight:',
        deliveryDate: 'Goods ready date',
        deliveryTime: 'Goods ready time',
        withdrawalDate: 'Possible delivery date booked',
        shipment: 'Shipment',
        estimation: 'Estimation',
        bankCheck: 'Bank receipt',
        bankTransfer: 'Bank transfer',
        cardPayment: 'Card Payment',
        payment: 'Enter payment method',
        estimation_in_pending: 'The estimation has been sent and is under review. You will be updated as soon as possible.',
        order_in_pending: 'The shipment has been sent and is under review. You will be updated as soon as possible.',
        confirm_or_not: 'The quote has been reviewed by an operator. Please check this and confirm if you wish to proceed with shipping.',
        delete_attention: 'Attention!',
        delete_description: 'Unfinished shipments cannot be deleted.',
        start_date_validation: 'Enter the ready date',
        start_time_validation: 'Enter the ready time',
        non_stop_date_ritiro_validation: 'Enter the mandatory collection date',
        non_stop_date_consegna_validation: 'Enter the mandatory delivery date',
        fuel_info: 'Fuel increase included in the quote',
      },
      transports: {
        fleet_management: 'Fleet Management',
        free_search: 'Free search',
        new_medium: 'New medium',
        creation_date: 'Creation date',
        license_plate: 'License plate',
        type: 'Type',
        description: 'Description',
        drivers_name: 'Drivers name',
        max_pallet_number: 'No. of pallets max',
        availability: 'Availability',
        make_unavailable: 'Make Unavailable',
        update: 'Update',
        delete: 'Delete',
        add_new_medium: 'add new medium',
        max_transport_number: 'Maximum number of transportable pallets',
        available: 'Available',
        yes: 'Yes',
        no: 'No',
        invite_operator: 'Invite operator',
        transport_description: 'Transport description',
        available_in: 'Available in:',
        number_days: 'N ° days',
        days: 'days',
        delete_transport: 'Delete transport',
        delete_transport_description: 'Are you sure you want to delete this transport?',
        update_transport: 'update transport',
        transport_type_name: 'Transport type name',
        delete_transport_type: 'Delete transport type',
        delete_transport_type_description: 'Are you sure you want to delete this transport type?',
      },
      manifest: {
        title: 'UNLOADING AND LOADING MANIFESTO',
        order: 'Order',
        startDate: 'Withdraw:',
        withdrawalDate: 'Delivery:',
        remove: 'Remove from today`s manifesto',
        merge: 'Merge',
        divide: 'Divide',
        move: 'Move',
        quantity: 'Qty',
        shortSide: 'Short side',
        longSide: 'Long side',
        height: 'Height',
        weight: 'Single pallet weight',
        associated: 'Shipments to be associated',
        allTrucks: 'All trucks',
        selectTruck: 'Select truck',
        selectTruckValidator: 'Choose the truck you need',
        downloadCsv: 'Download tour of drivers',
        downloadAllPdf: 'Press',
        downloadTruckPdf: 'Press',
        palletCapacityError: 'Too many pallets on one truck',
        sameTruckError: 'Select another truck',
        actions: 'Actions',
        required_split_error: 'Entered value must be a number bigger than 0',
        is_not_bigger_value_split_error: 'The number must not exceed the maximum number of pallets',
        table_dnd_error: 'Drag and drop pallets here',
        on_the_road: 'On the road',
        tracking_code: 'Select tracking code',
        select_id: 'Select ID',
      },
      notifications: {
        shippingNotFound: 'There is no shipment with this id',
      },
      pallexError: {
        errorText: 'The shipment could not be sent to Pallex.',
        errorBtnText: 'View error.',
        error: 'Error!',
        description1: 'The Pallex portal has not accepted the shipping request.',
        description2: 'This is the error message that was received:',
        back: 'Back',
        resendError: 'Retry sending to Pallex',
        errorTextUpdate: 'There is a new shipping update to send to Pallex Italia.',
        errorBtnTextUpdate: 'Update shipping',
        update: 'UPDATE!',
        resendUpdate: 'Send Update',
      },
    },
    operatorSettings: {
      my_data: 'my data',
      email: 'Email',
      update_password: 'Update password',
      old_password: 'Old password',
      new_password: 'New password',
      rewrite_new_password: 'Rewrite new password',
      notification_management: 'Notification management',
      each_notification: 'send me an email with each notification',
      summary_notification: 'send me a summary email every:',
      once: 'At once',
      day: 'Day',
      week: 'Week',
      send: 'Send',
      your_data: 'your data',
      user_email: 'Contact email',
      user_phone: 'Phone number',
      cancel_account: 'request account cancellation',
      send_request: 'Send request to ',
      support: 'support',
      oldPass: 'Old password is required',
      newPass: 'New password is required',
      confirmPass: 'Confirm password is required',
      period: 'Period is required',
      notMatch: 'Passwords do not match',
      support_data: 'Contacts for support',
      phone: 'Phone',
      contact_update: 'Update contacts',
    },
    operatorsList: {
      operators: 'Operators',
      creation_date: 'Creation date',
      email: 'Email',
      phone: 'Phone number',
      name: 'Name and surname',
      status: 'Status',
      new_operator: 'New operator',
      add_new_operator: 'add new operator',
      update_operator: 'update operator',
      password: 'Password (min 8 characters)',
      login_code: 'Login code',
      role: 'Role',
      administrator: 'Administrator',
      invite_operator: 'Invite operator',
      auto_generation: 'Auto generation',
    },
    validation: {
      empty: 'Empty field',
      email: 'You must enter the email',
      phone: 'You need to enter a phone number',
      address: 'An address must be entered',
      companyName: 'You must enter the company name',
      nameAndSurname: 'You must enter the name and surname of a contact person',
      vatNumber: 'It is necessary to enter the VAT number',
      fiscalCode: 'You must enter the fiscal code',
      number: 'This is not a number',
      password: 'Password must be at least 8 characters',
      confirmPassword: 'The two passwords that you entered do not match!',
      epal_error: 'Wrong number of epal pallets',
      delivery_type: 'You must select the type of delivery',
      contractor_type: 'You must select the contractor',
      withdrawal_date: 'The delivery date must not be less than the collection date',
      start_shipment_date: 'The collection date must not be bigger than the delivery date',
      ddt_number: 'You must enter the letters or numbers',
      street_and_house_number: 'Please enter the street and house number',
      street_and_house_number_invalid: 'Invalid street format',
      date_pronto_merce: 'Please enter date',
      easy_password: 'Password too weak',
      isNumberValidation: 'The entered value must be a number, with a maximum of two decimal places',
      number_not_bigger_1999: 'The entered value must be no more than 1999',
    },
    roles: {
      operator: 'Operator',
      administrator: 'Administrator',
      super_administrator: 'Super Administrator',
      user: 'User',
      driver: 'Driver',
      guest: 'Guest',
      billing_operator: 'Billing operator',
      estimating_operator: 'Estimating operator',
    },
    messages: {
      change_email: 'Email has been successfully changed. Need to pass for further successful work',
      change_password: 'The password has been successfully changed.',
      info: 'Info',
      attention: 'Attention!',
      login_error: 'Something went wrong. Check the data entered.',
      info_extra_services: 'Additional services that may be required for shipping. The cost for these services will be shown in the summary of the estimate as they are variable costs.',
      info_shipment_column: 'Current status of the shipment',
      recovery_password_title: 'E-mail sent',
      recovery_password: 'We have sent you an email to your address containing the link to recover your password!',
      create_user_error: 'A field you entered is already associated with another customer. The values that must be unique are: VAT number, email and telephone number.'
          + ' Check and try again.',
      access_private: 'An email has been sent to the selected user containing the link to access the platform',
      access_private_error: 'Error sending a request for access to the private part',
      assign_driver: 'The selected driver is already assigned to another vehicle, are you sure you want to remove it and assign it to a new vehicle?',
      epal_error: 'It is not possible to request more epals than the number of pallets that make up the shipment.',
      accept_invoice: 'Are you sure you want to send approved invoices? once sent they will no longer be visible.',
      accept_invoice_error: 'You must approve at least one shipment to send the invoice',
      fuel_message: 'Fuel surcharge',
      save_to_user_1: 'Do you want to set the',
      save_to_user_2: 'standard fuel surcharge for customer orders',
      delete_contrassegno: 'Are you sure you want to remove the flag? Once deleted it will no longer be possible to add it to the shipment',
      re_creation_contrassegno: 'The flag cannot be re-added to the shipment as it was previously added and subsequently removed',
      need_to_remove_non_stop: 'You need to remove NON STOP service to confirm shipment',
      re_creation_non_stop: 'The flag cannot be re-added to the shipment due to Zone Disagiate service',
      need_to_remove_unavailable_services: 'There are some extra services not present in the price list assigned to the customer. You must remove them before continuing.',
    },
    dashboard: {
      filterPallets: 'All types of pallets',
      estimatesTitle: 'Total number of quotes',
      palletTitle: 'Total number of pallets',
      deliveriesTitle: 'Number of delays in deliveries and collections',
      warehouseTitle: 'Number of orders in stock',
      time_in_stock: 'Time in stock',
      withdrawals: 'Withdrawals',
      delays: 'Delays',
      quantity: 'Quantity',
      in_stock: 'In stock',
      delivering: 'Delivering',
    },
    invoices: {
      send_invoice: 'Send invoices',
      last_submition: 'Last submission',
      send_period: 'Sent period',
      table: {
        options_title: 'Ancillary service name',
        orderId: 'Order id',
        pallexId: 'Pallex Italy shipping number',
        approvato_il: 'Posted date',
        data_ritiro: 'Withdrawal date',
        type: 'Typology',
        pickup_address: 'Sender',
        shipping_address: 'Recipient',
        n_ordine: 'Order N°',
        codice_cliente: 'Client code',
        numero_ddt: 'DDT number',
        fluet: 'Fuel surcharge',
        extra: 'Extra',
        scontistica: 'Discounts',
        total_price: 'Total cost',
        isNumberValidation: 'Entered value must be a number',
        description: 'Description',
        quantity: 'Quantity',
        price: 'Finale cost',
        charge_description: 'Charge description',
        unit_cost: 'Unit cost',
        repriced: 'Repriced',
        delete_invoice_title: 'Attention!',
        delete_invoice_description: 'Are you sure you want to delete this invoice? Once deleted, it will no longer be possible to recover it.',
      },
      add_extra: 'Add extras',
      field_is_required: 'Enter this field',
      to_be_invoices: 'To be invoiced to',
      select_list_error: 'Please choose from the list',
      input_error: 'Please enter a value',
      add_invoice_pallets: 'Add pallets',
      add_pallets: 'Add pallets',
      add_admin_pallets: 'Add pallets',
      add_private_area_pallets: 'Add pallets',
      add_options: 'Add extra services',
      add_private_area_options: 'Add extra services',
      add_addebitos: 'Add charge',
      add_merce_adr: 'Add merce adr',
      select_user: 'Select user',
      select_all: 'Select All',
    },
    placeholder: {
      cityOrCode: 'Enter postcode or city',
      enter_street: 'Enter street and number separated by a comma',
      enter_city: 'Enter city',
      enterAddress: 'Enter address',
      note: 'Enter any notes',
      reasonAnnulation: 'Enter the reason for canceling the order',
    },
    upload: {
      uploading: 'Uploading...',
      removeFile: 'Remove file',
      uploadError: 'Upload error',
      previewFile: 'Preview file',
      downloadFile: 'Download file',
    },
    contractorsTable: {
      shippingStages: 'Shipping stages',
      handledBy: 'Handled by',
      cargoManifest: 'Show in cargo manifest',
      transportDate: 'Transportation date',
    },
    monitoringSections: {
      Generic: 'Generic',
      Ritiri_e_consegne: 'Collections and deliveries',
      Ritiri_e_consegne_Da_approvare: 'Collections and deliveries - To be approved',
      Ritiri_e_consegne_Approvate: 'Collections and deliveries - Approved',
      Preventivi: 'Estimates',
      Veicoli: 'Vehicles',
      Anagrafica: 'Users',
      Listini: 'Price lists ',
      Manifesto_di_carico_e_scarico: 'Loading and unloading manifest',
      Operatori: 'Operators',
      Fatturazione: 'Billing',
    },
  },
  ita: {
    translation: {
      errors: {
        pallet_price_calculation_error: 'Errore di calcolo del prezzo del pallet. Contatta il supporto tecnico',
        creating_price_list_error: 'Errore durante la creazione del listino prezzi',
      },
      menu: {
        trackingShipments: 'tracking spedizioni',
        signIn: 'Accedi',
        whoWeAre: 'Chi Siamo',
        services: 'Servizi',
        environment: 'Ambiente',
        gruppoStorti: 'Gruppo storti',
        contacts: 'Contatti',
        shippingEstimates: 'Richiedi Preventivo',
        privacyPolicy: 'Privacy Policy',
      },
      privateMenu: {
        dashboard: 'Dashboard',
        deliveries: 'Ritiri e consegne',
        estimates: 'Preventivi',
        priceList: 'Listini',
        personalData: 'Anagrafiche',
        machines: 'Flotta',
        manifest: 'Manifesto di scarico e carico',
        profile: 'Profilo personale',
        operators: 'Operatori',
        logOut: 'Log out',
        notifications: 'Notifiche',
        invoices: 'Fatture da inviare',
        monitoring: 'Monitoring',
      },
      banner: {
        logIn: 'Accedi alla tua area privata',
        forgotPassword: 'Recupera password',
        restorePassword: 'Ripristinare la password',
        shipmentRequestStep3: 'Conferma richiesta preventivo',
        trackingSearch: 'Ricerca tracking',
        trackingNumber: 'Tracking',
        shipmentConfirm: 'Conferma spedizione',
        estimationConfirm: 'Richiedi preventivo',
        shipmentEstimate: 'Costo stimato',
      },
      buttons: {
        confirm: 'Conferma',
        cancel: 'Annulla',
        add: 'Aggiungi',
        save: 'Salva',
        ok: 'Ok',
        reset: 'Ripristina',
        reject: 'Rifiuta',
        print_label: 'Stampa etichetta Pallex',
      },
      req_statuses: {
        Da_Elaborare: 'Da Elaborare',
        Inviato: 'Inviato',
        Inviato_follow_up: 'Inviato follow up',
        Confermato: 'Confermato',
        Rifiutato: 'Rifiutato',
        Scaduto: 'Scaduto',
      },
      forms: {
        contractor: {
          name: 'Ragione sociale',
          email: 'Email',
          phone: 'Telephone number',
          vatNumber: 'Partita iva',
          address: 'Indirizzo sede legale',
          is_address_different: 'Indirizzo sede operativa diverso dall’indirizzo della sede legale',
          confirm: 'Crea  anagrafica',
        },
        login: {
          emailAdress: 'Indirizzo email',
          password: 'Password',
          forgotPassword: 'Hai dimenticato la password?',
          signIn: 'Accedi',
        },
        restorePassword: {
          email: 'Inserisci la tua mail',
          description: 'Inserisci la tua mail e ti invieremo un link con il quale sarà possibile recuperare la tua password.',
          back: 'Indietro',
          restore: 'Recupera',
        },
        shipmentRequest: {
          enter_order_name: 'Inserisci la tua ragione sociale',
          enter_order_address: 'Inserisci il tuo indirizzo legale',
          from: 'Dati mittente:',
          from_full: 'Località ritiro merce :',
          to_full: 'Località consegna merce :',
          to: 'Dati destinatario:',
          quantity: 'Qtà',
          shortSide: 'Lato corto',
          longSide: 'Lato lungo',
          height: 'Altezza',
          weight: 'Peso singolo bancale',
          addPallets: 'Aggiungi pallet',
          pickupDate: 'Data pronto merce',
          requestPrice: 'Richiedi preventivo',
          enterPostCode: 'Inserisci cap o città',
          extraServices: 'SERVIZI EXTRA',
          dayAndTime: 'Giorno e orario stabilito per la consegna',
          expressServices: 'Servizio no stop espresso',
          highwayDelivery: 'Consegna in autostrada',
          contactEmail: 'Email di contatto',
          phoneNumber: 'Telefono',
          confirmRequest: 'Conferma richiesta',
          back: 'Torna indietro',
          quoteSent: 'La tua richiesta di preventivo È stata inviata!',
          confirm_description: 'Entro poche ore il tuo ordine verrà preso in carico e verrai aggiornato tramite email',
          information: 'Hai necessità di ulteriori informazioni?',
          contactUs: 'Contattaci subito',
          trackingNumber: 'IL TUO NUMERO DI TRACKING',
          companyName: 'Ragione Sociale o Nome e Cognome',
          nameAndSurname: 'Referente (solo se azienda)',
          shippingType: 'Tipo di spedizione',
          withdrawal: 'Ritiro',
          delivery: 'Consegna',
          triangulation: 'Triangolazione',
          checkbox_title: 'Premi qui se l’inidirzzo corrisponde alla sede operativa',
          start_shipment_date: 'Data pronto merce',
          start_shipment_time: 'Ora pronto merce',
          start_shipment_time_note: 'Orario pronto merce',
          non_stop_date_ritiro: 'Data ritiro tassativa (solo per ritiri NON STOP)',
          non_stop_date_ritiro_2: 'Data di ritiro tassativa',
          non_stop_date_consegna: 'Data consegna tassativa (solo per consegne NON STOP)',
          non_stop_date_consegna_2: 'Data consegna tassativa',
          telefonica_ritiro: 'Inserisci numero di telefono (Ritiro)',
          telefonica_consegna: 'Inserisci numero di telefono (Consegna)',
          facchinaggio_ritiro: 'Facchinaggio (Ritiro)',
          facchinaggio_consegna: 'Facchinaggio (Consegna)',
          select_delivery_for_service: 'Seleziona il requisito del tipo di ordine',
          withdrawal_date: 'Eventuale data di consegna prenotata (non obbligatoria)',
          withdrawal_date_guest: 'Data di consegna tassativa (solo se richiesto il servizio non-stop/espresso)',
          client_code: 'Codice cliente',
          shipping_client_code: 'Cod. Cliente spedizione',
          informazioni_aggiuntive: 'Informazioni aggiuntive',
          order_number: 'N° Ordine',
          ddt_number: 'Numero DDT',
          note_ritiro: 'Ritiro note',
          note_consegna: 'Consegna note',
          optional: 'Facoltativo',
        },
        shipmentDetails: {
          title: 'dettaglio spedizione',
          VAT: 'iva',
          quantity: 'Qtà',
          shortSide: 'Lato corto',
          longSide: 'Lato lungo',
          height: 'Altezza',
          retirementDate: 'Data pronto merce',
          delivery: 'Data presunta consegna',
          pickupAddress: 'Località ritiro merce',
          shippingAddress: 'Località consegna merce',
          extraServices: 'Servizi extra',
          confirmEstimation: 'Conferma preventivo',
          pickUpAddress: 'Indirizzo di ritiro',
          destinationAddress: 'Indirizzo di destinazione',
          agency: 'Azienda',
          private: 'Privato',
          street: 'Via',
          businessName: 'Ragione sociale',
          referenceName: 'Nome e Cognome riferimento',
          twoStepButtonTitle: 'Conferma indirizzi',
          pallet: 'pallet',
          billingInfo: 'Dati di fatturazione',
          vatNumber: 'Partita Iva',
          notification: 'Il nostro sistema andrà ad estrapolare i dati necessari per effettuare la fattura elettronica interrogando i database pubblici',
          streetAndCity: 'Inserisci via e città',
          n: 'CAP',
          sameAdress: 'L’indirizzo della sede legale è lo stesso della sede di ritiro',
          payment: 'Medoto di pagamento',
          card: 'Carta di credito',
          bank_check: 'Ricevuta bancaria',
          bank_transfer: 'Bonifico bancario',
          send: 'Spedisci',
          unavailable: 'non disponibile',
          cancel_quote: 'Annulla preventivo',
          confirmation_title: 'La tua spedizione è confermata',
          IVA_text: 'Il prezzo sopra indicato è IVA esclusa ed è da considerarsi per consegna in strada e in zona agibile. Escluso Fuel Surcharge (Adeguamento Carburante).',
          no_data: 'Nessun Dato',
          select_gdo: 'GDO',
          select_company: 'Informazioni GDO',
          select_company_label_ritiro: 'Seleziona GDO (Ritiro)',
          select_company_label_consegna: 'Seleziona GDO (Consegna)',
          select_who_is_gdo: 'Seleziona chi è la GDO',
          add_telephone: 'Prenotazione telefonica',
          telephonica: 'Prenotazione t.',
        },
        tracking: {
          enterTrackingNumber: 'Inserisci numero di tracking',
          send: 'Invia',
          waiting: 'In attesa di essere ritirata',
          withdrawn: 'Ritirata',
          onWay: 'In transito',
          delivering: 'In consegna',
          delivered: 'Consegnata',
          required: 'Inserisci il tuo codice di tracking',
        },
        estimations: {
          estimates: 'Preventivi',
          archived: 'Archiviate',
          select_placeholder: 'Filtra per status',
          filter_button: 'Ricerca avanzata',
          reset_button: 'Reset',
          new_estimates: 'Nuova preventivo',
          date: 'Data pronto merce',
          id: 'Id',
          email: 'Email',
          number_pallet: 'N°pallet',
          measures: 'Misure',
          from_to: 'Da > A',
          status: 'Status',
          cost: 'Costo',
          edit: 'Modifica',
          delete: 'Elimina',
          withdrawal_request_detail: 'dettaglio spedizione',
          checkbox_title: 'La spedizione richiesta è in triangolazione',
          sender: 'Mittente',
          destination: 'Destinatario',
          both: 'Entrambi',
          define_medium: 'Definisci mezzo',
          manage_with_pallex: 'Pallex Italia',
          manage_with_external: 'TP o fornitore',
          assign_price_list: 'Assegna listino',
          send: 'Invia preventivo',
          add_extra: 'Aggiungi servizio extra',
          update_address: 'Aggiorna indirizzo',
          update_date: 'Modifica data',
          update_time: 'Modifica ora',
          select_hub: 'Seleziona HUB',
          change_ecological_pallet: 'Modificare il numero di pallet ecologici',
          change_fasci: 'Modificare il numero di fasci',
          text_fasci: 'N.B. Dimensioni massime 300x30x30 peso massimo 30 Kg.',
          fasci_validation: 'Non è possibile richiedere un numero di merci in fasci superiore al numero di pallet che compongono la spedizione.',
          change_contrassegno: 'Modificare il contrassegno',
          contrassegno_value_validation: 'Il valore deve avere almeno 1 e non più di 2 cifre decimali',
          change_facchinaggio: 'Modificare il facchinaggio',
          change_non_stop: 'Modificare il Non-Stop',
          change_ore_note: 'Modificare il Ore Prestabilita',
          update_price: 'Aggiorna prezzo',
          order_type: 'Tipo di ordine',
          update: 'Aggiorna',
          contractor_type: 'Tipo di appaltatore',
          delivery_type: 'Tipo di consegna',
          user_price_list: 'Listino prezzi utente',
          select_extra_services: 'Seleziona servizi extra',
          approve: 'Invia Preventivo',
          update_contractor: 'Seleziona quale servizio esterno vuoi utilizzare',
          pallex_estimate: 'Preventivo Pallex',
          hub: 'Hub',
          ecological_pallet: 'N° epal affidati',
          fasci: 'Fasci',
          non_stop: 'Non-Stop',
          contrassegno: 'Contrassegno',
          facchinaggio: 'Facchinaggio',
          ore_note: 'Ore Prestabilita',
          quote_detail: 'dettaglio preventivo',
          notAvailableExtra: 'Non disponibile per il listino scelto',
          non_stop_in_danger_zone: 'Rimuovere il NON-STOP dovuto Zone disagiate servizio',
          extra_service_error: 'Il prezzo è richiesto',
          deleted_user: 'Utente eliminato',
          tracking: 'Tracking',
          disponsible: 'Disponibile',
          non_disponsible: 'Non Disponibile',
        },
        newEstimation: {
          new_quote: 'nuovo preventivo',
          fixed_minimum: 'Fisso minimo',
          requested_to: 'Richiesto per',
          email: 'Email di contatto',
          phone: 'Numero di telefono',
          from: 'Indica località di ritiro merce',
          to: 'Indica località di consegna merce',
          placeholder: 'Inserisci Cap o città',
          date: 'Data ritiro',
          price_list: 'Listino associato',
          manage: 'Gestisci con terzista',
          transportation: 'Trasporti Pesanti',
          required_shipment: 'La spedizione richiesta è in triangolazione',
          extra_service_title: 'Servizi extra',
          extra_service_title_with_triang: 'Servizi extra (R: Ritiro, C: Consegna)',
          total_cost: 'Costo tot. stimato Pallex:',
          tot_cost: 'Costo tot.:',
          number_pallet: 'N° pallet',
          weight: 'Peso singolo bancale',
          adr: 'Merce Pericolosa',
          hub: 'Hub',
          optional: 'Optional',
          quantity: 'Qtà',
          shortSide: 'Lato corto',
          longSide: 'Lato lungo',
          height: 'Altezza',
          price: 'Prezzo',
          add_pallet: 'Aggiungi pallet',
          proceed: 'Carica il file XLSX',
          cancel: 'Annulla',
          or: 'Oppure',
          manual_loading: 'Caricamento manuale',
          confirmation: 'Conferma spedizione',
          service_name: 'Denominazione servizio accessorio',
          service_details: 'Descrizione servizio accessorio',
          apply_to: 'Da applicare a',
          shipping_type: 'Tipo di spedizione',
          withdrawal: 'Ritiro',
          delivery: 'Consegna',
          triangulation: 'Triangolazione',
          checkbox_title: 'Premi qui se l’inidirzzo corrisponde alla sede operativa',
          manage_with: 'Gestisci con',
          shipping_actions: 'Azioni spedizione',
          base_price: 'Costo base',
          merce_adr_limited_qty: 'Quantità limitata',
          merce_adr_number_onu: 'Numero ONU',
          merce_adr_qty: 'Quantità (kl/Lt)',
          merce_adr_packages: 'N° Colli',
          merce_adr_packaging_description: 'Descrizione imballaggio',
          merce_adr_description_nos: 'Descrizione N.A.S',
        },
        deliveries: {
          refferer: 'Referente',
          email: 'Mail',
          shipments_approved: 'Spedizioni approvate',
          shipments_to_be_approved: 'Ritiri da approvare',
          shipments_rejected: 'Spedizioni rifiutate',
          archived: 'Archiviate',
          approved: 'Approvati',
          approved_date: 'Approvato il',
          to_be_approved: 'Da approvare',
          rejected: 'Rifiutati',
          approve: 'Approvare',
          not_approved: 'Non approvati',
          id: 'Id',
          new_shipment: 'Nuova spedizione',
          number_pallets: 'N°pallet',
          recipient_name: 'Nome destinatario',
          date: 'Data pronto merce',
          delivery_date: 'Eventuale data di consegna prenotata',
          rejected_date: 'Rifiutato il',
          status: 'Status',
          measures: 'Misure',
          type: 'Tipo',
          price: 'Prezzo',
          details: 'Vedi dettaglio',
          customer_data: 'Anagrafica cliente',
          archive: 'Archivia',
          customer: 'Cliente',
          total_cost_pallex: 'Costo tot. Pallex',
          shipping: 'spedizione',
          driver: 'Autista',
          truck: 'Camion',
          address: 'Indirizzo',
          labelDownload: 'Scarica etichetta',
          client_name: 'Nome cliente',
          applicant: 'Richiedente',
          delete_shipping: 'Elimina la spedizione',
          delete_shipping_description: 'Sei sicuro di voler eliminare questa spedizione?',
          delete_estimation: 'Elimina il preventivo',
          delete_estimation_description: 'Sei sicuro di voler eliminare questo preventivo',
          change_status_for_archive: 'Selezionare uno stato per l\'archiviazione',
          pallex_id: 'Codice Pallex',
        },
        newShipment: {
          new_shipment: 'nuova spedizione',
          customer: 'Cliente',
          recipient: 'Destinatario',
          contact_name: 'Nome e  Cognome referente',
          business_name: 'Ragione sociale',
          nameIsRquired: 'Inserisci Nominativo',
          name_min_length: 'La nome deve essere di almeno 3 caratteri',
          phone_number: 'Numero di telefono (opz)',
          details: 'Dettagli',
          from: 'Da',
          to: 'A',
          name: 'Nominativo',
          street_and_city: 'Inserisci via e città',
          n: 'n°',
          price_list: 'Listino associato',
          manage: 'Gestisci con TP',
          fiscal_number: 'È richiesto il numero civico',
          select_user: 'Seleziona una persona',
        },
        helpForm: {
          submitSupportRequest: 'invia richiesta di supporto',
          requestSubject: 'Oggetto richiesta',
          placeholder: 'Scrivi qui la tua richiesta...',
          requiredSubject: 'Si prega di inserire oggetto',
          requiredBody: 'SPer favore inserisci la tua richiesta',
          confirmEmail: 'Si prega di controllare la propria casella mail.',
          faqButton: 'Vai a leggere le faq',
          submitButton: 'Invia',
          faqText1: 'Hai provato a',
          faqText2: 'consultare le nostre',
          faqText3: 'guide?',
          faq: 'Faq',
          download_guide: 'Scarica la guida',
        },
        pallet: {
          validationError: 'Si prega di inserire',
          quantityError: 'Si prega di inserire la quantità',
          depthError: 'Si prega di inserire la profondità',
          widthError: 'Si prega di inserire la larghezza',
          heightError: 'Si prega di inserire l`altezza',
          weightError: 'Si prega di inserire il peso',
        },
        users: {
          companies: 'Aziende',
          private: 'Privati',
          new_client: 'Nuovo cliente',
          search: 'Ricerca libera',
          codica_cliente: 'Codice cliente (verifica su anagrafica di Teseo, se il cliente esiste copia e incolla qui il suo “codice cliente”)',
          creation_date: 'Data creazione',
          company_name: 'Nome azienda',
          vat_number: 'Partita iva',
          shipment_generated: 'Spedizione generate',
          associated_price_list: 'Listino associato',
          status: 'Status',
          access_private: 'Accesso area privata',
          invite_private: 'Invita in area privata',
          see_profile: 'Vedi profilo',
          update: 'Modifica',
          delete: 'Elimina',
          create_new_client: 'aggiungi nuovo cliente',
          business_name: 'Ragione sociale',
          name: 'Nome e Cognome',
          email: 'Email',
          notes: 'Note (opz)',
          office_address: 'Indirizzo sede legale',
          access_private_area: 'Consenti accesso ad “area privata”',
          create: 'Crea  anagrafica',
          close: 'Chiudi finistra',
          delete_client: 'Elimina cliente',
          delete_client_description: 'Sei sicuro di voler eliminare questo cliente?',
          update_client: 'aggiornare il cliente',
          order_number: 'N° ordini',
          fiscal_code: 'Codice fiscale',
          not_exist: 'L`utente con questo ID non esiste',
          billing_title: 'Fatturazione',
          billing_description: '*Specificare la ragione sociale e PIVA che si vogliono utilizzare per mandare gli ordini a Pallex Italia. Le infromazioni utilizzate nella sezione fatturazione del software rimaranno invariate.',
          pallex_company_name: 'Ragione sociale utilizzata per ordini inviati a Pallex Italia',
          pallex_vat_number: 'Partita iva utilizzata per ordini inviati a Pallex Italia',
          invite_title: 'Attenzione',
          invite_description: 'Confermi di voler invitare il cliente all\'area privata?',
          invite_submit: 'Conferma',
          invite_cancel: 'Annulla',
          no_company: 'Nessuna ragione sociale',
        },
        transports: {
          fleet_management: 'Gestione flotta',
          free_search: 'Ricerca libera',
          new_medium: 'Nuovo mezzo',
          creation_date: 'Data creazione',
          license_plate: 'Targa',
          type: 'Tipo',
          transport_description: 'Descrizione veicolo',
          description: 'Descrizione',
          drivers_name: 'Nome autista',
          max_pallet_number: 'N° pallet max',
          availability: 'Disponibilità',
          make_unavailable: 'Rendi non disponibile',
          update: 'Modifica',
          delete: 'Elimina',
          add_new_medium: 'aggiungi nuovo mezzo',
          max_transport_number: 'Numero massimo pallet trasportabili',
          available: 'Disponibile',
          yes: 'Si',
          no: 'No',
          invite_operator: 'Invita operatore',
          available_in: 'Disponibile tra:',
          number_days: 'N° Giorni',
          days: 'giorni',
          delete_transport: 'Eliminare il mezzo',
          delete_transport_description: 'Sei sicuro di voler eliminare questo trasporto?',
          update_transport: 'aggiornare il mezzo',
          transport_type_name: 'Nome del tipo di trasporto',
          add_transport_type: 'Aggiungi',
          delete_transport_type: 'Elimina tipo di trasporto',
          delete_transport_type_description: 'Sei sicuro di voler eliminare questo tipo di trasporto?',
        },
        monitoring: {
          title: 'Monitoring',
          csvButton: 'Esporta in CSV',
          searchInputPlaceholder: 'Ricerca operatore, descrizione, IP',
          operator: 'Operatore',
          from: 'Da',
          to: 'A',
          operatorName: 'Nome operatore',
          details: 'Dettaglio evento',
          section: 'Sezione',
          ip: 'IP',
          dateAndTime: 'Data e ora',
          withPagination: 'con impaginazione',
          withoutPagination: 'senza impaginazione',
        },
      },
      oops: 'Ops!',
      notFound: 'La pagina che stai cercando non esiste.',
      returnHome: 'Ritorna alla home',
      estimation: {
        title: {
          request_sent_successfully_modal: 'Richiesta inviata con successo',
        },
        text: {
          request_sent_successfully: 'Richiesta inviata con successo',
        },
      },
      uploadFile: {
        uploadCSV: 'Carica file .csv',
        uploadXLSX: 'Carica file .xlsx',
        drag: 'o trascinalo qui',
        or: 'Oppure',
        createNew: 'Crea nuovo listino da template disponibile',
        proceed: 'Procedi',
        uploadPalletsList: 'Carica l\'elenco dei pallet in Xlsx',
        uploadPriceList: 'Carica listino xlsx',
        uploadExtraServices: 'Carica servizi extra xlsx',
      },
      pricelist: {
        pricelist: 'Listino',
        search: 'licerca libera',
        advancedSearch: 'Ricerca avanzata',
        reset: 'Reset',
        newPricelist: 'Nuovo listino',
        edit: 'Modifica',
        duplicate: 'Duplica',
        personalData: 'Associa anagrafiche',
        delete: 'Elimina',
        creationDate: 'Data creazione',
        listName: 'Nome listino',
        data: 'Anagrafiche associate',
        shipmentsGenerated: 'Spedizioni generate',
        related: 'Correlato a preventivi',
        back: 'Torna indietro',
        correlation: 'Correla listino ai preventivi',
        confirm: 'Conferma listino',
        delivery: 'Consegna in autostrada',
        description: 'Descrizione della consegna in autostrada',
        total: 'Total',
        pallet: 'Pallet',
        esternamente: 'Esternamente',
        service: 'Denominazione servizio accessorio',
        accessoryDescription: 'Descrizione servizio accessorio',
        price: 'Prezzo',
        apply: 'Da applicare a',
        associate: 'Associa',
        confirmUpdate: 'Conferma',
        delete_extra: 'Eliminare servizio extra',
        delete_extra_description: 'Sei sicuro di voler eliminare questo servizio extra?',
        csvPriceTemplate: 'Scarica template file price.CSV',
        delete_modal_title: 'Elimina la listino',
        delete_modal_description: 'Sei sicuro di voler eliminare questa listino?',
      },
      shipmentList: {
        id: 'Id',
        typology: 'Tipologia',
        palletNumber: 'N°pallet',
        shippingName: 'Nome destinatario',
        pickupName: 'Nome mittente',
        withdrawalDate: 'Data pronto merce',
        pickupDate: 'Data di ritiro',
        deliveryDate: 'Eventuale data di consegna prenotata',
        status: 'Status',
        tracking: 'Tracking',
        modalTracking: 'tracking spedizione',
        WaitingToBePickedUp: 'In attesa di ritiro',
        sent: 'Inviata ricgiesta',
        delivered: 'Consegnato',
        rejected: 'Respinta',
        processing: 'In elaborazione',
        delivering: 'In consegna',
        envoy: 'Inviato',
        followUp: 'Inviato follow up',
        confirmed: 'Confermato',
        managed: 'Gestito da tp',
        expired: 'Scaduta',
        canceled: 'Annullata',
        administrator: 'Amministratore',
        role: 'Ruolo',
        customer: 'Cliente',
        lead: 'Lead',
        toElebore: 'Da elaborare',
        shipmentsList: 'Lista spedizioni',
        help: 'Aiuto',
        newShipment: 'Nuova spedizione',
        newEstimation: 'Nuovo preventivo',
        seeDetails: 'Vedi dettaglio',
        download: 'Scarica etichetta',
        requestSupport: 'Richiedi supporto',
        delete: 'Elimina',
        xlsxPalletTemplate: 'Scarica template file listino.XLSX',
        xlsxOptionsTemplate: 'Scarica template file servizi extra.XLSX',
        scvInstructions: 'La spedizione deve essere importata tramite il caricamento di un file .CSV precompilato con tutti i dettagli necessari.',
        xlsxInstructions: 'La spedizione deve essere importata tramite il caricamento di un file .XLSX precompilato con tutti i dettagli necessari.',
        manual: 'Caricamento manuale',
        info: 'Le spedizioni possono essere anche caricare tramite servizio FTP.',
        details: 'Dettagli',
        name: 'Nominativo',
        contactName: 'Nome e  Cognome referente',
        phoneNumber: 'Numero di telefono (opz)',
        recipientNameSearch: 'Nome destinatario',
        collectionDateSearch: 'Eventuale data di consegna ...',
        deliveryDateSearch: 'Data pronto merce',
        select_placeholder: 'Filtra per status',
        notTrackingText: 'Non è possibile visualizzare il tracking per questa spedizione. Per maggiori informazioni scrivi a',
      },
      privateAreaShipmentDetails: {
        total_cost: 'Costo tot. stimato:',
        sender: 'Mittente:',
        recipient: 'Destinatario:',
        number_pallet: 'N° pallet:',
        weight: 'Peso:',
        deliveryDate: 'Data pronto merce',
        deliveryTime: 'Ora pronto merce',
        withdrawalDate: 'Eventuale data di consegna prenotata',
        shipment: 'Spedizione',
        estimation: 'Preventivo',
        bankCheck: 'Ricevuta bancaria',
        bankTransfer: 'Bonifico bancario',
        cardPayment: 'Carta di credito',
        payment: 'Inserisci il metodo di pagamento',
        estimation_in_pending: 'La preventivo è stata inviata ed è in revisione. Sarai aggiornato il prima possibile.',
        order_in_pending: 'La spedizione è stata inviata ed è in revisione. Sarai aggiornato il prima possibile',
        confirm_or_not: 'Il preventivo è stato revisionato da un operatore. Ti preghiamo di verificarlo e confermare se desideri procedere con la spedizione.',
        delete_attention: 'Attenzione!',
        delete_description: 'Non è possibile eliminare le spedizioni non ancora terminate.',
        start_date_validation: 'Inserire la data pronto merce',
        start_time_validation: 'Inserire la ora pronto merce',
        non_stop_date_ritiro_validation: 'Inserire la data di ritiro tassativa',
        non_stop_date_consegna_validation: 'Inserire la data consegna tassativa',
        fuel_info: 'Incremento carburante incluso nel preventivo',
      },
      operatorSettings: {
        my_data: 'i miei dati',
        email: 'Email',
        update_password: 'Aggiorna password',
        old_password: 'Vecchia password',
        new_password: 'Nuova password',
        rewrite_new_password: 'Riscrivi nuova password',
        notification_management: 'Gestione notifiche',
        each_notification: 'inviami un’email ad ogni notifica',
        summary_notification: 'inviami un’email riepilogativa ogni:',
        once: 'Una volta',
        day: 'Giorno',
        week: 'Debole',
        send: 'Spedire',
        your_data: 'i tuoi dati',
        user_email: 'Email di contatto',
        user_phone: 'Numero di telefono',
        cancel_account: 'richiedi cancellazione account',
        send_request: 'Invia richiesta al ',
        support: 'supporto',
        oldPass: 'È richiesta la vecchia password',
        newPass: 'È richiesta una nuova password',
        confirmPass: 'È richiesta la conferma della password',
        period: 'Il periodo è richiesto',
        notMatch: 'Le passwords non corrispondono',
        support_data: 'Contatti per supporto',
        phone: 'Telefono',
        contact_update: 'Aggiorna contatti',
      },
      operatorsList: {
        operators: 'Operatori',
        creation_date: 'Data creazione',
        email: 'Email',
        phone: 'Telefono',
        name: 'Nome e Cogome',
        status: 'Status',
        new_operator: 'Nuovo operatore',
        add_new_operator: 'aggiungi nuovo operatore',
        update_operator: 'Aggiorna l’operatore',
        password: 'Password (min 8 caratteri)',
        login_code: 'Codice di accesso',
        role: 'Ruolo',
        administrator: 'Amministratore',
        invite_operator: 'Invita operatore',
        auto_generation: 'Autogenera',
      },
      validation: {
        empty: 'Campo vuoto',
        email: 'È necessario inserire la mail',
        phone: 'È necessario inserire un numero di telefono',
        address: 'È necessario inserire un indirizzo',
        companyName: 'È necessario inserire la ragione sociale dell’azienda',
        nameAndSurname: 'È necessario inserire il nome e cognome di un referente',
        vatNumber: 'È necessario inserire la partita iva',
        fiscalCode: 'È necessario inserire la codice fiscale',
        number: 'Questo non è un numero',
        password: 'La password deve essere di almeno 8 caratteri',
        confirmPassword: 'Le due password che hai inserito non corrispondono!',
        epal_error: 'Numero errato di epal pallet',
        delivery_type: 'È necessario selezionare il tipo di consegna',
        contractor_type: 'È necessario selezionare il contraente',
        start_shipment_date: 'La data di ritiro non deve essere superiore alla data di consegna',
        withdrawal_date: 'La data di consegna non deve essere inferiore alla data di ritiro',
        non_stop_date_ritiro: 'La data di ritiro tassativa non deve essere superiore alla data di consegna tassativa',
        non_stop_date_consegna: 'La data di consegna tassativa non deve essere inferiore alla data di ritiro tassativa',
        ddt_number: 'È necessario inserire la lettere o numeri',
        street_and_house_number: 'Si prega di inserire la via',
        street_and_house_number_invalid: 'Formato stradale non valido',
        date_pronto_merce: 'Inserisci le data pronto merce',
        easy_password: 'Password troppo semplice',
        isNumberNotWhole: 'Il valore immesso deve essere un numero, con un massimo di due cifre decimali',
        number_not_bigger_1999: 'Il valore inserito non deve essere superiore a 1999',
      },
      roles: {
        operator: 'Operatore',
        administrator: 'Amministratore',
        super_administrator: 'Super amministratore',
        user: 'Cliente',
        driver: 'Autista',
        guest: 'Ospite',
        billing_operator: 'Operatore fatturazione',
        estimating_operator: 'Operatore preventivazione',
      },
      messages: {
        change_email: 'L\'e-mail è stata modificata con successo. Bisogno di passare per un ulteriore lavoro di successo.',
        change_password: 'La password è stata modificata con successo.',
        info: 'Informazioni',
        attention: 'Attenzione!',
        login_error: 'Qualcosa è andato storto. Сontrollare i dati inseriti.',
        info_extra_services: 'Servizi aggiuntivi che possono essere richiesti per la spedizione. Il costo per questi servizi verrà mostrato nel riepilogo del preventivo essendo costi variabili.',
        info_shipment_column: 'Stato attuale della spedizione',
        recovery_password_title: 'Mail inviata',
        recovery_password: 'Ti abbiamo inviato una mail al tuo indirizzo contenente il link per poter recuperare la tua password!',
        create_user_error: 'Un campo che hai inserito è già associato ad un altro cliente. I valori che devono essere univoci sono : P.IVA, email e numero di telefono. '
            + 'Verifica e riprova.',
        access_private: 'É stata inviata una mail all\'utente selezionato contenente il link per accedere alla piattaforma',
        access_private_error: 'Errore nell\'invio di una richiesta di accesso alla parte privata',
        assign_driver: 'L’autista selezionato è già assegnato ad un altro veicolo, sei sicuro di volerlo rimuovere e assegnarlo ad un nuovo veicolo?',
        epal_error: 'Non è possibile richiedere più epal del numero di pallet che compongono la spedizione.',
        accept_invoice: 'Confermi di voler inviare le fatture approvate? Una volta inviate non saranno più visibili.',
        accept_invoice_error: 'Devi approvare almeno una spedizione per inviare la fattura',
        fuel_message: 'Fuel surcharge',
        save_to_user_1: 'Vuoi impostare il',
        save_to_user_2: 'di Fuel surcharge standard per gli ordini del cliente',
        delete_contrassegno: 'Confermi di voler eliminare il contrassegno? Una volta eliminato non sarà più possibile aggiungerlo alla spedizione',
        re_creation_contrassegno: 'Non è possibile aggiungere nuovamente il contrassegno alla spedizione poiché era stato precedentemente aggiunto e successivamente rimosso',
        need_to_remove_non_stop: 'È necessario rimuovere il servizio NON STOP per confermare la spedizione',
        re_creation_non_stop: 'Non è possibile aggiungere il NON-STOP alla spedizione grazie al servizio Zone disagiate',
        need_to_remove_unavailable_services: 'Sono presenti alcuni servizi extra non presenti nel listino assegnato al cliente. È necessario rimuoverli prima di continuare.',
      },
      dashboard: {
        filterPallets: 'Tutti i tipi di pallet',
        estimatesTitle: 'Numero di preventivi totali',
        palletTitle: 'Numero di pallet totali',
        deliveriesTitle: 'Numero di ritardi nelle consegne e nei ritiri',
        warehouseTitle: 'Numero di ordini in magazzino',
        time_in_stock: 'Tempo in magazzino',
        withdrawals: 'Ritiri',
        delays: 'Ritardi',
        quantity: 'Quantità',
        in_stock: 'In Magazzino',
        delivering: 'In Consegna',
      },
      invoices: {
        send_invoice: 'Invia fatture',
        last_submition: 'Ultimo invio',
        send_period: 'Periodo inviato',
        table: {
          options_title: 'Nome servizio accessorio',
          orderId: 'Order id',
          pallexId: 'Numero spedizione Pallex Italia',
          approvato_il: 'Data inserimento',
          data_ritiro: 'Data ritiro',
          type: 'Tipologia',
          pickup_address: 'Mittente',
          shipping_address: 'Destinatario',
          n_ordine: 'N° Ordine',
          codice_cliente: 'Codice cliente',
          numero_ddt: 'Numero DDT',
          fluet: 'Fuel surcharge',
          extra: 'Extra',
          scontistica: 'Scontistica',
          total_price: 'Costo Totale',
          isNumberValidation: 'Il valore inserito deve essere un numero',
          description: 'Descrizione',
          quantity: 'Quantità',
          price: 'Costo finale',
          charge_description: 'Descrizione addebito',
          unit_cost: 'Costo unitario',
          repriced: 'Riprezzato',
          delete_invoice_title: 'Attenzione!',
          delete_invoice_description: 'Confermi di voler eliminare questa fattura? Una volta eliminata non sarà più possibile recuperarla.',
        },
        add_extra: 'Aggiungi extra',
        field_is_required: 'Inserisci questo campo',
        to_be_invoices: 'Da fatturare a',
        select_list_error: 'Si prega di scegliere dalla lista',
        input_error: 'Si prega di inserire un valore',
        add_pallets: 'Aggiungi pallet',
        add_invoice_pallets: 'Aggiungi pallet',
        add_admin_pallets: 'Aggiungi pallet',
        add_private_area_pallets: 'Aggiungi pallet',
        add_options: 'Aggiungi servizio extra',
        add_private_area_options: 'Aggiungi servizio extra',
        add_addebitos: 'Aggiungi addebito',
        add_merce_adr: 'Aggiungi merce adr',
        select_user: 'Seleziona utente',
        select_all: 'Tutti',
      },
      placeholder: {
        cityOrCode: 'Inserisci cap o città',
        enter_street: 'Inserisci via e numero separati da una virgola',
        enter_city: 'Inserisci città',
        enterAddress: 'Inserisci indirizzo',
        note: 'Inserisci eventuali note',
        reasonAnnulation: 'Inserire il motivo dell\'annullamento dell\'ordine',
      },
      upload: {
        uploading: 'Caricamento...',
        removeFile: 'Rimuovi il file',
        uploadError: 'Errore di caricamento',
        previewFile: 'Anteprima file',
        downloadFile: 'Download file',
      },
      manifest: {
        title: 'MANIFESTO DI SCARICO E CARICO',
        order: 'Ordine',
        startDate: 'Ritiro:',
        withdrawalDate: 'Consegna:',
        remove: 'Elimina dal manifesto odierno',
        merge: 'Unisci',
        divide: 'Dividi',
        move: 'Sposta',
        quantity: 'Qtà',
        shortSide: 'Lato corto',
        longSide: 'Lato lungo',
        height: 'Altezza',
        weight: 'Peso singolo bancale',
        associated: 'Spedizioni da associare',
        allTrucks: 'Tutti i camion',
        selectTruck: 'Seleziona camion',
        selectTruckValidator: 'Scegli il camion di cui hai bisogno',
        downloadCsv: 'Scarica giro autisti',
        downloadAllPdf: 'Stampa',
        downloadTruckPdf: 'Stampa',
        palletCapacityError: 'Troppi pallet su un camion',
        sameTruckError: 'Seleziona un altro camion',
        actions: 'Azioni',
        required_split_error: 'È necessario inserire essere un numero maggiore di 0',
        is_not_bigger_value_split_error: 'Il numero non deve essere superiore al numero massimo di pallet',
        table_dnd_error: 'Trascina e rilascia i pallet qui',
        on_the_road: 'Sulla strada',
        tracking_code: 'Seleziona il codice di monitoraggio',
        select_id: 'Selezionare id',
      },
      notifications: {
        shippingNotFound: 'Non ci sono spedizioni con questo ID',
      },
      pallexError: {
        errorText: 'Non è stato possibile inviare la spedizione a Pallex.',
        errorBtnText: 'Visualizza errore.',
        error: 'ERRORE!',
        description1: 'Il portale di Pallex non ha accettato la richiesta di spedizione.',
        description2: 'Questo è il messaggio di errore che è stato ricevuto:',
        back: 'Indietro',
        resendError: 'Riprova l’inivio a Pallex',
        errorTextUpdate: 'Cè un nuovo aggiornamento della spedizione da inviare a Pallex Italia',
        errorBtnTextUpdate: 'Aggiorna spedizione',
        update: 'AGGIORNA!',
        resendUpdate: 'Invia Aggiornamento',
      },
      contractorsTable: {
        shippingStages: 'Fasi della spedizione',
        handledBy: 'Gestito da',
        cargoManifest: 'Mostra nel manifesto di carico',
        transportDate: 'Data del trasporto',
      },
      monitoringSections: {
        Generic: 'Generic',
        Ritiri_e_consegne: 'Ritiri e consegne',
        Ritiri_e_consegne_Da_approvare: 'Ritiri e consegne - Da approvare',
        Ritiri_e_consegne_Approvate: 'Ritiri e consegne - Approvate',
        Preventivi: 'Preventivi',
        Veicoli: 'Veicoli',
        Anagrafica: 'Anagrafica',
        Listini: 'Listini ',
        Manifesto_di_carico_e_scarico: 'Manifesto di carico e scarico',
        Operatori: 'Operatori',
        Fatturazione: 'Fatturazione',
      },
    },
  },
};
