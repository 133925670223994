import { Contractor, NewContractor } from '../../store/types';
import { instance } from '../index';
import { ContractorDTOMapper } from './contractor.dto';

export const GetContractors = () => instance.get('contractors');

export const DeleteContractor = (id: string) => instance.delete(`contractors/${id}`);

export const CreateContractor = (contractor: NewContractor): Promise<Contractor> => instance.post('contractors', ContractorDTOMapper.toCreateDto(contractor));

export const UpdateContractor = (contractor: Contractor): Promise<Contractor> => instance.patch(`contractors/${contractor.id}`, ContractorDTOMapper.toUpdateDto(contractor));

export const GetContractorByID = (id: string) => instance.get(`contractors/${id}`);
