import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import CustomButton from '../../components/CustomButton/CustomButton';

import style from './restore.module.css';
import { useAppDispatch } from '../../store/store';
import { requestRecoveryPassword } from '../../store/redux_slice/authSlice';
import { LoginPayload } from '../../store/types';

const RestoreForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onFinish = (values: LoginPayload) => {
    dispatch(requestRecoveryPassword(values.email));
  };
  return (<div className={classnames(style.login, 'customForm')}>
    <Form layout="vertical"
      name="restorePassword"
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        style={{ margin: 0 }}
        label={t('forms.restorePassword.email')}
        rules={
          [
            { required: true, message: t('validation.email') },
            { type: 'email', message: t('validation.email') },
          ]
        }
      >
        <Input className={style.input} type="email"/>
      </Form.Item>
      <div className={style.description_text}>
        {t('forms.restorePassword.description')}
      </div>
      <div className={style.flex}>
        <Form.Item>
          <CustomButton className={style.login__button}
            htmlType="submit"
            type="primary"
          >
            {t('forms.restorePassword.restore')}
          </CustomButton>
        </Form.Item>
        <Link to="/" className={style.password__forgot}>
          {t('forms.restorePassword.back')}
        </Link>
      </div>
    </Form>
  </div>);
};

export default RestoreForm;
