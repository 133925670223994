import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import CustomButton from 'components/CustomButton/CustomButton';
import { getApprovedPallexShippingRequestAction } from 'store/redux_slice/shipping/shippingApproveSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { PallexError, PallexErrorModalPayload } from 'store/types';
import style from './pallexError.module.css';

type Props = {
  setVisiblePallesErrorModal: (boolean) => void,
  visiblePallesErrorModal: boolean,
  pallexError: PallexError | null,
  shippingIds: PallexErrorModalPayload,
  checkUpdatePallexError: boolean,
}

const PallexErrorModal = ({
  visiblePallesErrorModal, setVisiblePallesErrorModal, pallexError, shippingIds, checkUpdatePallexError,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pallexErrorLoading } = useAppSelector((store) => store.shippingApprove);
  return (
    <Modal
      title={checkUpdatePallexError ? t('pallexError.update') : t('pallexError.error')}
      visible={visiblePallesErrorModal}
      onCancel={() => {
        if (!pallexErrorLoading) {
          setVisiblePallesErrorModal(false);
        }
      }}
      footer={false}
    >
      { !checkUpdatePallexError && (
        <p className={style.modal_pallex_error}>
          {t('pallexError.description1')} <br/>
          {t('pallexError.description2')}
        </p>
      )}
      <p className={style.modal_pallex_error}>
        { pallexError?.message }
      </p>
      <div className={style.btn_container}>
        <CustomButton
          className={classnames('defaultBtn', style.btn)}
          onClick={() => setVisiblePallesErrorModal(false)}
          disabled={pallexErrorLoading}
        >
          {t('pallexError.back')}
        </CustomButton>
        <CustomButton
          type="primary"
          className={classnames(style.btn)}
          onClick={() => dispatch(getApprovedPallexShippingRequestAction(shippingIds))}
          disabled={pallexErrorLoading}
        >
          {checkUpdatePallexError ? t('pallexError.resendUpdate') : t('pallexError.resendError')}
        </CustomButton>
      </div>
    </Modal>
  );
};

export default PallexErrorModal;
