import { put, takeLatest } from 'redux-saga/effects';
import { UsersListPayload } from '../../types';
import {
  setLoading, setCompanyUsersList, requestUsersCompanyList,
} from '../../redux_slice/users/usersCompanyListSlice';
import { GetUsersList } from '../../../api/users';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* getUsersCompanyListSaga(action: {
    payload: UsersListPayload
}) {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetUsersList(action.payload));
    yield put(setCompanyUsersList(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* usersCompanyListSaga() {
  yield takeLatest(requestUsersCompanyList, getUsersCompanyListSaga);
}
