import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContrassegnoDto } from '../../types';

export const requestGetContrassegno = createAction('requestGetContrassegno');

type State = {
  contrassegnoList: ContrassegnoDto[],
  loading: boolean,
}

const initialState: State = {
  contrassegnoList: [],
  loading: false,
};

const contrassegnoSlice = createSlice({
  name: 'contrassegno',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setContrassegnoSliceData: (state, action: PayloadAction<ContrassegnoDto[]>) => {
      state.contrassegnoList = action.payload;
    },
  },
});

export const { setContrassegnoSliceData, setLoading } = contrassegnoSlice.actions;

export default contrassegnoSlice.reducer;
