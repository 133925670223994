import { Link } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import { PathEnums } from 'enums';
import styles from './styles.module.css';

export const makeUserMenu = (t:TFunction, onChange: () => void) => [
  {
    label: (
      <Link className={styles.link_button} to={PathEnums.User_profile}>
        {t('privateMenu.profile')}
      </Link>
    ),
    key: 'profile',
  },
  {
    label: (
      <button className={styles.link_button} onClick={onChange}>
        {t('privateMenu.logOut')}
      </button>
    ),
    key: 'logOut',
  },
];

export const makeOperatorMenu = (t:TFunction, onChange: () => void) => [
  {
    label: (
      <Link className={styles.link_button} to={PathEnums.Operator_profile}>
        {t('privateMenu.profile')}
      </Link>
    ),
    key: 'profile',
  },
  {
    label: (
      <button className={styles.link_button} onClick={onChange}>
        {t('privateMenu.logOut')}
      </button>
    ),
    key: 'logOut',
  },
];

export const makeAdminMenu = (t:TFunction, onChange: () => void) => [
  {
    label: (
      <Link className={styles.link_button} to={PathEnums.Operator_profile}>
        {t('privateMenu.profile')}
      </Link>
    ),
    key: 'profile',
  },
  {
    label: (
      <Link className={styles.link_button} to={PathEnums.Operator_List}>
        {t('privateMenu.operators')}
      </Link>
    ),
    key: 'operators',
  },
  {
    label: (
      <button className={styles.link_button} onClick={onChange}>
        {t('privateMenu.logOut')}
      </button>
    ),
    key: 'logOut',
  },
];

export const makeSuperAdminMenu = (t:TFunction, onChange: () => void) => [
  {
    label: (
      <Link className={styles.link_button} to={PathEnums.Operator_profile}>
        {t('privateMenu.profile')}
      </Link>
    ),
    key: 'profile',
  },
  {
    label: (
      <Link className={styles.link_button} to={PathEnums.Operator_List}>
        {t('privateMenu.operators')}
      </Link>
    ),
    key: 'operators',
  },
  {
    label: (
      <Link className={styles.link_button} to={PathEnums.Monitoring}>
        {t('privateMenu.monitoring')}
      </Link>
    ),
    key: 'monitoring',
  },
  {
    label: (
      <button className={styles.link_button} onClick={onChange}>
        {t('privateMenu.logOut')}
      </button>
    ),
    key: 'logOut',
  },
];
