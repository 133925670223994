import { all } from 'redux-saga/effects';
import { authSaga } from './authSaga';
import { estimationsListSaga } from './estimationsListSaga';
import { shippingRequestSaga } from './shipmentRequestSaga';
import { shippingServicesSaga } from './shippingServicesSaga';
import { pricelistSaga } from './pricelists/pricelistsSaga';
import { usersSaga, AssignUserSaga, currentUserSaga } from './users/usersSaga';
import { priceListTemplateSaga } from './priceListTemplatesSaga';
import { userPriceListSaga } from './userPriceListSaga';
import { palletsSaga } from './palletsSaga';
import { estimationSaga } from './estimationSaga';
import { contractorsSaga } from './contractors/contractorsSaga';
import { pallexHubsSaga } from './pallexHubsSaga';
import { extraServicesByPriceListSaga } from './extraByPriceListSaga';
import { contractorSaga } from './contractors/contractorSaga';
import { paymentLinkSaga } from './paymentLinkSaga';
import { userPriceListByIdSaga } from './pricelists/userPriceListByIdSaga';
import { settingsSaga } from './settingsSaga';
import { usersListSaga } from './users/usersListSaga';
import { userSaga } from './users/userSaga';
import { operatorsListSaga } from './operators/operatorsListSaga';
import { operatorSaga } from './operators/operatorSaga';
import { numeroOnuSaga } from './pallex/numeroOnu';
import { contrassegnoSaga } from './pallex/contrassegnoSaga';
import { facchinaggioSaga } from './pallex/facchinaggioSaga';
import { nonStopSaga } from './pallex/nonStopSaga';
import { gdoSaga } from './pallex/gdoSaga';
import { transportSaga } from './transport/transportSaga';
import { transportTypesSaga } from './transport/transportTypesSaga';
import { transportByIdSaga } from './transport/transportByIdSaga';
import { transportTypeSaga } from './transport/transportTypeSaga';
import { shippingBeforeApproveSaga } from './shipping/shippingBeforeApproveSaga';
import { shippingApproveSaga } from './shipping/shippingAprroveSaga';
import { shippingRejectedSaga } from './shipping/shippingRejectedSaga';
import { shippingStatusSaga } from './shipping/shippingStatusSaga';
import { privateAreaSaga } from './privateAreaSaga';
import { downloadQrCodesSaga } from './downloadQrCodesSaga';
import { shipmentTrackingStatusSaga } from './shipmentTrackingStatusSaga';
import { resetFilterPriceListsSaga, setFilterPriceListsSaga } from './pricelists/filterSaga';
import { tripsSaga } from './tripsSaga';
import { dashboardsSaga } from './dashboardSaga';
import { notificationsSaga } from './notificationsSaga';
import { superAdminSaga } from './superAdminSaga';
import { financeSectionSaga } from './financeSaga';
import { monitoringSaga } from './monitoringSaga';
import { usersCompanyListSaga } from './users/usersCompanyListSaga';

function* rootSaga() {
  yield all([
    authSaga(),
    estimationsListSaga(),
    shippingRequestSaga(),
    shippingServicesSaga(),
    pricelistSaga(),
    usersSaga(),
    currentUserSaga(),
    AssignUserSaga(),
    priceListTemplateSaga(),
    userPriceListSaga(),
    palletsSaga(),
    estimationSaga(),
    contractorsSaga(),
    pallexHubsSaga(),
    extraServicesByPriceListSaga(),
    contractorSaga(),
    paymentLinkSaga(),
    userPriceListByIdSaga(),
    settingsSaga(),
    usersListSaga(),
    userSaga(),
    operatorsListSaga(),
    operatorSaga(),
    transportSaga(),
    transportTypesSaga(),
    transportByIdSaga(),
    transportTypeSaga(),
    contrassegnoSaga(),
    facchinaggioSaga(),
    nonStopSaga(),
    gdoSaga(),
    shippingBeforeApproveSaga(),
    shippingApproveSaga(),
    shippingRejectedSaga(),
    shippingStatusSaga(),
    privateAreaSaga(),
    downloadQrCodesSaga(),
    shipmentTrackingStatusSaga(),
    setFilterPriceListsSaga(),
    resetFilterPriceListsSaga(),
    tripsSaga(),
    dashboardsSaga(),
    notificationsSaga(),
    superAdminSaga(),
    financeSectionSaga(),
    monitoringSaga(),
    usersCompanyListSaga(),
    numeroOnuSaga(),
  ]);
}

export default rootSaga;
