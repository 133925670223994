import { useEffect, useMemo } from 'react';
import * as _ from 'lodash';
import {
  Form,
  Input, Select,
  Space, TimePicker,
  Row, Col, DatePicker,
} from 'antd';
import { format } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate } from 'react-router-dom';
import cn from 'classnames';
import BackButton from 'components/BackButton';
import CustomButton from 'components/CustomButton/CustomButton';
import ExtraServices from 'components/ExtraServices';
import GooglePlacesComponent from 'components/GooglePlacesComponent';
import { useAppSelector, useAppDispatch } from 'store/store';
import { setShippingPayloadData } from 'store/redux_slice/privateAreaSlice';
import { requestGetFacchinaggio } from 'store/redux_slice/pallex/facchinaggioSlice';
import { requestGetUser } from 'store/redux_slice/users/userSlice';
import { ExtraServiceStructure } from 'store/types';
import { triangolizeConditionHandler } from 'functions';
import {
  OrderType, PathEnums, AddressFields, DeliveryType, PaymentMethodEnum,
} from 'enums';
import {
  NON_STOP, CONTRASSEGNO, FACCHINAGGIO, TELEFONICA,
  CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA, GDO, AMAZON,
  SPONDA_IDRAULICA,
} from 'pesantiConstants';
import style from './createShipmentManual.module.css';

const { Option } = Select;

const CreateShipmentManual = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { facchinaggioList } = useAppSelector((state) => state.facchinaggioList);
  const { shippingPayloadData, orderType } = useAppSelector((state) => state.privateArea);
  const extraServices = useAppSelector((state) => state.extraServices?.shippingServices);
  const userData = useAppSelector((state) => state.settings.userData);
  const optionsWatch = Form.useWatch('extra_services', form);
  const deliveryTypeWatch:DeliveryType = Form.useWatch('delivery_type', form);

  const hasNonStop = useMemo(() => (
    optionsWatch?.some((title) => title.toUpperCase() === NON_STOP.toUpperCase())
  ), [optionsWatch]);

  const filteredOptions: ExtraServiceStructure[] = useMemo(() => (
    extraServices ? extraServices.map((service) => {
      const isContrassegno = optionsWatch?.includes(CONTRASSEGNO)
        && deliveryTypeWatch !== DeliveryType.RITIRO;
      const isActive = optionsWatch?.includes(service.title);
      const isRitiroTriang = optionsWatch?.includes(`${service.title}&${DeliveryType.RITIRO}`);
      const isConsegnaTriang = optionsWatch?.includes(`${service.title}&${DeliveryType.CONSEGNA}`);
      return {
        ...service,
        triangolize: triangolizeConditionHandler(isRitiroTriang, isConsegnaTriang),
        isActive: service.title === CONTRASSEGNO ? isContrassegno : isActive,
      };
    }) : []
  ), [deliveryTypeWatch, extraServices, optionsWatch]);

  const serviceTelefonica = useMemo(() => (
    (shippingPayloadData?.options || filteredOptions)
      ?.find((item) => item.title.toUpperCase() === TELEFONICA.toUpperCase() && item.isActive)
  ), [shippingPayloadData?.options, filteredOptions]);

  const serviceFacchinaggio = useMemo(() => (
    (shippingPayloadData?.options || filteredOptions)
      ?.find((item) => item.title.toUpperCase() === FACCHINAGGIO.toUpperCase() && item.isActive)
  ), [shippingPayloadData?.options, filteredOptions]);

  useEffect(() => {
    if (userData && userData.id) {
      dispatch(requestGetUser(userData.id.toString()));
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (optionsWatch) {
      const reducedOptionsWatch = optionsWatch.reduce((result, item) => {
        if (item.includes('&')) {
          const substr = item.split('&')[0];
          if (!result.includes(substr)) {
            result.push(substr);
          }
        }
        if (!result.includes(item)) {
          result.push(item);
        }
        return result;
      }, []);
      form.setFieldsValue({ extra_services: reducedOptionsWatch });
    }
  }, [optionsWatch, form]);

  useEffect(() => {
    dispatch(requestGetFacchinaggio());
  }, [dispatch]);

  const onFinish = () => {
    const values = form.getFieldsValue();
    const payment = orderType === OrderType.SHIPPING ? { payment_method: PaymentMethodEnum.BANK_TRANSFER } : {};
    const mappedServices = filteredOptions.map((item) => {
      switch (item.title.toUpperCase()) {
      case TELEFONICA.toUpperCase():
        return {
          ...item,
          values: { ritiro: values.telefonica_ritiro || null, consegna: values.telefonica_consegna || null },
        };
      case FACCHINAGGIO.toUpperCase():
        return {
          ...item,
          values: { ritiro: values.facchinaggio_ritiro || null, consegna: values.facchinaggio_consegna || null },
        };
      case CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA.toUpperCase():
      case GDO.toUpperCase():
      case AMAZON.toUpperCase():
      case SPONDA_IDRAULICA.toUpperCase():
        return {
          ...item,
          values: {
            ritiro: values?.extra_services?.includes(`${item.title}&${DeliveryType.RITIRO}`) || null,
            consegna: values?.extra_services?.includes(`${item.title}&${DeliveryType.CONSEGNA}`) || null,
          },
        };
      default:
        return item;
      }
    });

    const servicesDeliveryType = mappedServices.map((item) => item.triangolize).filter((item) => item);
    const isRitiro = servicesDeliveryType.includes(DeliveryType.RITIRO);
    const isConsegna = servicesDeliveryType.includes(DeliveryType.CONSEGNA);
    const isBoth = servicesDeliveryType.includes(DeliveryType.BOTH);
    const serviceDelivery = triangolizeConditionHandler(isRitiro, isConsegna, isBoth);
    const orderDelivery = (serviceDelivery === DeliveryType.BOTH)
      ? DeliveryType.TRIANG : serviceDelivery as string;

    const data = {
      ...payment,
      delivery_type: orderDelivery || DeliveryType.CONSEGNA,
      pickup_address: {
        location: {
          ...values?.pickup_address?.location,
          post_code: values.pickup_address_post_code,
        },
        typology: 'Company',
        company_name: values.pickup_address_name,
        gdo_id: values.pickup_address?.gdo_id || undefined,
        gdo_name: values.pickup_address?.gdo_name || undefined,
      },
      shipping_address: {
        location: {
          ...values?.shipping_address?.location,
          post_code: values.shipping_address_post_code,
        },
        typology: 'Company',
        company_name: values.shipping_address_name,
        gdo_id: values.shipping_address?.gdo_id || undefined,
        gdo_name: values.shipping_address?.gdo_name || undefined,
      },
      options: mappedServices.map((item) => ({
        ...item, triangolize: !item.triangolize ? (serviceDelivery || DeliveryType.CONSEGNA) : item.triangolize,
      })),
      price_list_id: userData?.price_list_id ?? 0,
      email: userData?.email ?? '',
      phone: userData?.phone ?? '',
      start_shipment_date: values.start_shipment_date ? format(new Date(values.start_shipment_date), 'yyyy-MM-dd') : null,
      start_shipment_time: values.start_shipment_time ? format(new Date(values.start_shipment_time), 'HH:mm') : null,
      withdrawal_date: values.withdrawal_date ? format(new Date(values.withdrawal_date), 'yyyy-MM-dd') : null,
      non_stop_date_ritiro: values.non_stop_date_ritiro ? format(new Date(values.non_stop_date_ritiro), 'yyyy-MM-dd') : null,
      non_stop_date_consegna: values.non_stop_date_consegna ? format(new Date(values.non_stop_date_consegna), 'yyyy-MM-dd') : null,
      telefonica: values.telefonica || null,
      client_code: values.client_code || null,
      order_number: values.order_number || null,
      ddt_number: values.ddt_number || null,
      note_ritiro: values.note_ritiro,
      note_consegna: values.note_consegna,
      codice_cliente_spedizione: values.codice_cliente_spedizione,
      informazioni_aggiuntive: values.informazioni_aggiuntive,
    };
    dispatch(setShippingPayloadData(data));
    navigate(PathEnums.Create_shipment_details);
  };

  if (!orderType) return <Navigate to="/"/>;

  return (
    <div className={style.form_container}>
      <BackButton href={PathEnums.Create_shipment}/>
      <div className={style.title}>
        { orderType === OrderType.SHIPPING ? t('shipmentList.newShipment') : t('shipmentList.newEstimation')}
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          pickup_address: shippingPayloadData?.pickup_address || null,
          pickup_address_name: shippingPayloadData?.pickup_address?.company_name,
          pickup_address_street: shippingPayloadData?.pickup_address?.location?.street
            ? `${shippingPayloadData?.pickup_address?.location?.street}, ${shippingPayloadData?.pickup_address?.location?.house_number}` : '',
          pickup_address_post_code: shippingPayloadData?.pickup_address?.location?.post_code,
          pickup_address_gdo: shippingPayloadData?.pickup_address?.gdo_name,
          shipping_address: shippingPayloadData?.shipping_address || null,
          shipping_address_name: shippingPayloadData?.shipping_address?.company_name,
          shipping_address_street: shippingPayloadData?.shipping_address?.location?.street
            ? `${shippingPayloadData?.shipping_address?.location?.street}, ${shippingPayloadData?.shipping_address?.location?.house_number}` : '',
          shipping_address_post_code: shippingPayloadData?.shipping_address?.location?.post_code,
          shipping_address_gdo: shippingPayloadData?.shipping_address?.gdo_name,
          start_shipment_date: shippingPayloadData?.start_shipment_date
            ? moment(shippingPayloadData?.start_shipment_date) : null,
          start_shipment_time: shippingPayloadData?.start_shipment_time
            ? moment(shippingPayloadData.start_shipment_time, 'HH:mm') : null,
          withdrawal_date: shippingPayloadData?.withdrawal_date
            ? moment(shippingPayloadData?.withdrawal_date) : null,
          non_stop_date_ritiro: shippingPayloadData?.non_stop_date_ritiro
            ? moment(shippingPayloadData?.non_stop_date_ritiro) : null,
          non_stop_date_consegna: shippingPayloadData?.non_stop_date_consegna
            ? moment(shippingPayloadData?.non_stop_date_consegna) : null,
          telefonica: shippingPayloadData?.telefonica,
          client_code: shippingPayloadData?.client_code,
          codice_cliente_spedizione: shippingPayloadData?.codice_cliente_spedizione,
          informazioni_aggiuntive: shippingPayloadData?.informazioni_aggiuntive,
          order_number: shippingPayloadData?.order_number,
          ddt_number: shippingPayloadData?.ddt_number,
          note_ritiro: shippingPayloadData?.note_ritiro,
          note_consegna: shippingPayloadData?.note_consegna,
          telefonica_ritiro: serviceTelefonica?.values?.ritiro || '',
          telefonica_consegna: serviceTelefonica?.values?.consegna || '',
          facchinaggio_ritiro: serviceFacchinaggio?.values?.ritiro || '',
          facchinaggio_consegna: serviceFacchinaggio?.values?.consegna || '',
          extra_services: shippingPayloadData?.options?.filter((item) => item.isActive)
            .flatMap((item) => {
              let additionalItems:string[] = [];
              if (item.values) {
                additionalItems = Object.entries(item.values)
                  .filter(([, value]) => value !== null)
                  .map(([key]) => `${item.title}&${DeliveryType[key.toUpperCase()]}`);
              }
              return [item.title, ...additionalItems];
            }),
        }}
      >
        <p className={style.subtitle}>{t('shipmentList.details')}</p>
        <Row>
          <Col span={11}>
            <GooglePlacesComponent
              addressField={AddressFields.PICKUP_ADDRESS}
              deliveryType={DeliveryType.RITIRO}
              orderData={{
                delivery_type: deliveryTypeWatch,
                options: filteredOptions,
                city: shippingPayloadData?.pickup_address?.location.city
                  ? `${shippingPayloadData?.pickup_address?.location.city}, ${shippingPayloadData?.pickup_address?.location.province}, ${shippingPayloadData?.pickup_address?.location.country || 'Italia'}`
                  : '',
              }}
              privateArea
            />
          </Col>
          <Col span={11} offset={2}>
            <GooglePlacesComponent
              addressField={AddressFields.SHIPPING_ADDRESS}
              deliveryType={DeliveryType.CONSEGNA}
              orderData={{
                delivery_type: deliveryTypeWatch,
                options: filteredOptions,
                city: shippingPayloadData?.shipping_address?.location.city
                  ? `${shippingPayloadData?.shipping_address?.location.city}, ${shippingPayloadData?.shipping_address?.location.province}, ${shippingPayloadData?.shipping_address?.location.country || 'Italia'}`
                  : '',
              }}
              privateArea
            />
          </Col>
        </Row>
        <Row>
          <Col span={5} className={style.input_container}>
            <Form.Item
              label={t('forms.shipmentRequest.start_shipment_date')}
              name="start_shipment_date"
              rules={[
                { required: true, message: t('validation.date_pronto_merce') },
                ({ getFieldValue }) => ({
                  validator(rules, value) {
                    if (getFieldValue('withdrawal_date') && value && moment(getFieldValue('withdrawal_date')) < value) {
                      return Promise.reject(new Error(t('validation.start_shipment_date')));
                    }
                    if (!value) {
                      return Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                className="date_input"
                placeholder=""
                disabledDate={(current) => (
                  current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                )}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
          { hasNonStop && (
            <Col span={5} offset={1} className={style.input_container}>
              <Form.Item
                label={t('forms.shipmentRequest.non_stop_date_ritiro')}
                name="non_stop_date_ritiro"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rules, value) {
                      if (getFieldValue('non_stop_date_consegna') && value && moment(getFieldValue('non_stop_date_consegna')) < value) {
                        return Promise.reject(new Error(t('validation.non_stop_date_ritiro')));
                      }
                      if (!value) {
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  })]}
              >
                <DatePicker
                  className="date_input"
                  placeholder=""
                  disabledDate={(current) => (
                    current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                  )}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={5} offset={1} className={style.input_container}>
            <Form.Item
              label={t('forms.shipmentRequest.withdrawal_date')}
              name="withdrawal_date"
              rules={
                [
                  ({ getFieldValue }) => ({
                    validator(rules, value) {
                      if (getFieldValue('start_shipment_date') && value && moment(getFieldValue('start_shipment_date')) > value) {
                        return Promise.reject(new Error(t('validation.withdrawal_date')));
                      }
                      if (!value) {
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  })]}
            >
              <DatePicker
                className="date_input"
                placeholder=""
                disabledDate={(current) => (
                  current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                )}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
          { hasNonStop && (
            <Col span={5} offset={1} className={style.input_container}>
              <Form.Item
                label={t('forms.shipmentRequest.non_stop_date_consegna')}
                name="non_stop_date_consegna"
                rules={
                  [
                    ({ getFieldValue }) => ({
                      validator(rules, value) {
                        if (getFieldValue('non_stop_date_ritiro') && value && moment(getFieldValue('non_stop_date_ritiro')) > value) {
                          return Promise.reject(new Error(t('validation.non_stop_date_consegna')));
                        }
                        if (!value) {
                          return Promise.resolve();
                        }
                        return Promise.resolve();
                      },
                    })]}
              >
                <DatePicker
                  className="date_input"
                  placeholder=""
                  disabledDate={(current) => (
                    current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                  )}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={5} className={style.input_container}>
            <Form.Item
              name="start_shipment_time"
              label={t('forms.shipmentRequest.start_shipment_time')}
              rules={[
                { required: true, message: t('validation.empty') },
              ]}
            >
              <TimePicker
                placeholder=""
                format={'HH:mm'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Form.Item
              label={t('forms.shipmentRequest.client_code')}
              name="client_code"
            >
              <Input placeholder={t('forms.shipmentRequest.optional')}/>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item
              label={t('forms.shipmentRequest.shipping_client_code')}
              name="codice_cliente_spedizione"
            >
              <Input placeholder={t('forms.shipmentRequest.optional')}/>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item
              label={t('forms.shipmentRequest.order_number')}
              name="order_number"
            >
              <Input placeholder={t('forms.shipmentRequest.optional')}/>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item
              label={t('forms.shipmentRequest.ddt_number')}
              name="ddt_number"
            >
              <Input placeholder={t('forms.shipmentRequest.optional')}/>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.options_gap_container}>
          { (serviceTelefonica?.triangolize === DeliveryType.RITIRO
          || serviceTelefonica?.triangolize === DeliveryType.BOTH) && (
            <Col span={5}>
              <Form.Item
                label={t('forms.shipmentRequest.telefonica_ritiro')}
                name="telefonica_ritiro"
                rules={[
                  { required: true, message: t('validation.empty') },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>
          )}
          { (serviceTelefonica?.triangolize === DeliveryType.CONSEGNA
          || serviceTelefonica?.triangolize === DeliveryType.BOTH) && (
            <Col span={5}>
              <Form.Item
                label={t('forms.shipmentRequest.telefonica_consegna')}
                name="telefonica_consegna"
                rules={[
                  { required: true, message: t('validation.empty') },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>
          )}

          { (serviceFacchinaggio?.triangolize === DeliveryType.RITIRO
          || serviceFacchinaggio?.triangolize === DeliveryType.BOTH) && (
            <Col span={5}>
              <Form.Item
                label={t('forms.shipmentRequest.facchinaggio_ritiro')}
                name="facchinaggio_ritiro"
                rules={[
                  { required: true, message: t('validation.empty') },
                ]}
              >
                <Select>
                  <Option key={0} value={0}>-</Option>
                  { facchinaggioList.map((c) => (
                    <Option key={c.id} value={c.id}>{c.description}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          { (serviceFacchinaggio?.triangolize === DeliveryType.CONSEGNA
          || serviceFacchinaggio?.triangolize === DeliveryType.BOTH) && (
            <Col span={5}>
              <Form.Item
                label={t('forms.shipmentRequest.facchinaggio_consegna')}
                name="facchinaggio_consegna"
                rules={[
                  { required: true, message: t('validation.empty') },
                ]}
              >
                <Select>
                  <Option key={0} value={0}>-</Option>
                  { facchinaggioList.map((c) => (
                    <Option key={c.id} value={c.id}>{c.description}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <ExtraServices deliveryType={DeliveryType.TRIANG} isUser/>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<div className={style.note_style}>
                {t('forms.shipmentRequest.informazioni_aggiuntive')}
              </div>}
              name="informazioni_aggiuntive"
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item
              label={<div className={style.note_style}>
                {t('forms.shipmentRequest.note_ritiro')}
              </div>}
              name="note_ritiro"
            >
              <Input.TextArea placeholder={t('placeholder.note')} />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label={<div className={style.note_style}>
                {t('forms.shipmentRequest.note_consegna')}
              </div>}
              name="note_consegna"
            >
              <Input.TextArea placeholder={t('placeholder.note')} />
            </Form.Item>
          </Col>
        </Row>
        <Space className={style.buttons}>
          <CustomButton
            htmlType="submit"
            className={style.proceed}
            type="primary"
          >
            {t('uploadFile.proceed')}
          </CustomButton>
          <CustomButton
            className={cn('defaultBtn', style.cancel)}
          >
            {t('forms.newEstimation.cancel')}
          </CustomButton>
        </Space>
      </Form>
    </div>
  );
};

export default CreateShipmentManual;
