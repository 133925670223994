import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import classnames from 'classnames';
import { User } from '../../store/types';
import style from './profile.module.css';

const UserForm:React.FC<{userData: User | null}> = ({ userData }) => {
  const { t } = useTranslation();
  return (
    <div className={style.profile_container}>
      <div className={classnames('main-title-30px', style.blue)}>
        {t('operatorSettings.your_data')}
      </div>
      <div className={ classnames(style.user_title, 'extra-bolt-corsivo', style.blue)}>
        { userData?.name }
      </div>
      <div className={classnames(style.text_style, style.primary_dark)}>{ userData?.address?.full_address }</div>
      <p className={classnames(style.user_title, style.user_left, style.text_style)}>
        {userData?.typology === 'Company' ? 'P. Iva' : t('forms.users.fiscal_code') }
        <span className={style.bolt_text}>{userData?.typology === 'Company' && ' IT' } {userData?.vat_number}</span>
      </p>
      <Row>
        <Col>
          <div className={style.text_style}>{t('operatorSettings.user_email')}</div>
          <div className={classnames(style.primary, style.bolt_text)}>
            { userData?.email }
          </div>
        </Col>
        <Col offset={2}>
          <div className={style.text_style}>{t('operatorSettings.user_phone')} </div>
          <div className={style.bolt_text}>
            { userData?.phone }
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserForm;
