import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Col, DatePicker, Form, Input, Row, Modal,
} from 'antd';
import { FilterFilled, PlusOutlined, ReloadOutlined } from '@ant-design/icons/lib/icons';
import * as _ from 'lodash';
import style from './estimations.module.css';
import ModalWithCarousel from '../../components/ModalWithCarousel';
import Loader from '../../components/Loader';
import ModalInfo from './ModalInfo';
import CustomTabs from '../../components/CustomTabs';
import CustomSelect from '../../components/CustomSelect';
import CustomButton from '../../components/CustomButton/CustomButton';
import EstimationsTable from './EstimationsTable';
import { useAppSelector, useAppDispatch } from '../../store/store';
import { setEstimationsList } from '../../store/redux_slice/estimationsListSlice';
import { setCleanShipmentDataFromNotification } from '../../store/redux_slice/notificationsSlice';
import { EstimationStructure, FilterApproveShippingRequest } from '../../store/types';
import { EstimationStatuses, PathEnums } from '../../enums';
import { useFixedOrder } from '../../hooks/useFixedOrder';

interface EstimationInfoInterface {
    estimationsList: EstimationStructure,
    page: number,
    pageSize: number,
    setPage: (number) => void,
    setPageSize: (number) => void,
    setIsArchived:(boolean) => void,
    onChangeFilter: (obj) => void,
    isArchived: boolean
}

const EstimationsInfo:React.FC<EstimationInfoInterface> = ({
  estimationsList,
  page, pageSize,
  setPage, setPageSize, setIsArchived, onChangeFilter,
  isArchived,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    shippingType, shimpentDataFromNotification, tableTypeLoading, notificationShipmentLoading,
  } = useAppSelector((state) => state.notifications);
  const [advancedForm] = Form.useForm();
  const [statusesForm] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<Array<EstimationStatuses>>([]);
  const [activeTab, setActiveTab] = useState('tab1');
  const [selectedId, setSelectedId] = useState<string|undefined>();

  const onSelectedEstimations = (id) => {
    setSelectedId(id);
    setVisible(true);
  };

  useEffect(() => {
    setStatuses(!isArchived ? [
      EstimationStatuses.DA_ELABORARE,
      EstimationStatuses.INVIATO,
      EstimationStatuses.INVIATO_FOLLOW_UP,
    ] : [
      EstimationStatuses.CONFERMATO,
      EstimationStatuses.RIFIUTATO,
      EstimationStatuses.SCADUTO,
    ]);
  }, [isArchived]);

  useEffect(() => {
    if (shippingType) {
      if (shippingType?.type === 'preventivi') {
        setActiveTab('tab1');
        setIsArchived(false);
      } else if (shippingType?.type === 'preventivi-archivate') {
        setActiveTab('tab2');
        setIsArchived(true);
      } else {
        navigate(`${PathEnums.Deliveries}?dettaglio=${shippingType?.id}`);
      }
    }
  }, [setIsArchived, shippingType, navigate]);

  const onChangeFilterHandler = useCallback(_.debounce((param: keyof FilterApproveShippingRequest, value) => {
    onChangeFilter((prevState) => ({ ...prevState, ...{ [param]: value } }));
  }, 500), []);

  const orResetFilterHandler = () => {
    onChangeFilter({});
    advancedForm.resetFields();
    statusesForm.resetFields();
  };

  const initialSlide = estimationsList?.items?.findIndex((e) => String(e.id) === String(selectedId)) ?? -1;

  const itemsWithFixedOrder = useFixedOrder(
    visible,
    estimationsList?.items ?? [],
    selectedId ? String(selectedId) : undefined,
    () => setVisible(false),
  );

  if (!estimationsList) {
    return <Loader/>;
  }
  return (
    <div className={style.estimations_container}>
      <Modal
        centered
        visible={!!searchParams.get('dettaglio') && !!shimpentDataFromNotification}
        footer={false}
        width={'80%'}
        className="custom_modal"
        onCancel={() => {
          searchParams.delete('dettaglio');
          setSearchParams(searchParams);
          dispatch(setCleanShipmentDataFromNotification());
        }}
      >
        { notificationShipmentLoading || tableTypeLoading ? <Loader/> : (
          shimpentDataFromNotification && <ModalInfo
            setVisible={() => {
              setVisible(false);
              setSelectedId(undefined);
            }}
            estimation={shimpentDataFromNotification}
            isEstimation={true}
            isArchived={isArchived}
          />
        )}
      </Modal>
      <ModalWithCarousel
        key={selectedId}
        initialSlide={initialSlide ?? -1}
        visible={visible}
        setVisible={() => {
          setVisible(false);
          setSelectedId(undefined);
        }}
        content={
          itemsWithFixedOrder?.map((shipment, k) => (
            <ModalInfo key={shipment.id}
              setVisible={() => {
                setVisible(false);
                setSelectedId(undefined);
              }}
              estimation={shipment}
              isEstimation={true}
            />
          ))
        }
      />
      <Row className={style.height_container}>
        <Col span={12}>
          <CustomTabs
            activeTab={activeTab}
            setActiveTab={(tab) => {
              setActiveTab(tab);
              dispatch(setEstimationsList(null));
            }}
            setIsChange={setIsArchived}
            leftText={t('forms.estimations.estimates')}
            leftNumber={estimationsList?.countNotArchived}
            rightText={t('forms.estimations.archived')}
            rightNumber={estimationsList?.countArchived}
            setPage={setPage}
          />
          <div className={style.filter_container}>
            <Form form={statusesForm}>
              <Form.Item name="status" className={style.status_select}>
                <CustomSelect
                  onChange={(v) => onChangeFilterHandler('req_status', v || undefined) }
                  placeholder={t('forms.estimations.select_placeholder')}
                  options={statuses.map((s) => ({
                    title: t(`req_statuses.${s}`),
                    value: s,
                  }))}
                />
              </Form.Item>
            </Form>
            <CustomButton
              className={style.searchBtn}
              icon={<FilterFilled/>}
              onClick={() => setIsAdvanced(!isAdvanced)}
            >
              {t('forms.estimations.filter_button')}
            </CustomButton>
            <CustomButton
              className={style.resetBtn}
              icon={<ReloadOutlined/>}
              onClick={orResetFilterHandler}
            >
              {t('forms.estimations.reset_button')}
            </CustomButton>
          </div>
        </Col>
        <Col span={4} offset={8}>
          <CustomButton
            type="primary"
            icon={<PlusOutlined/>}
            className={style.newQuoteBtn}
            href={PathEnums.New_estimation}
          >
            {t('forms.estimations.new_estimates')}
          </CustomButton>
        </Col>
      </Row>
      {isAdvanced && (
        <Form form={advancedForm}>
          <Row className={style.height_container}>
            <Col>
              <p className={style.labelSearch}>{t('shipmentList.recipientNameSearch')}</p>
              <Form.Item name="recipientName">
                <Input onChange={(v) => { onChangeFilterHandler('recipientName', v.target.value || undefined); }} />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <p className={style.labelSearch}>{t('shipmentList.collectionDateSearch')}</p>
              <Form.Item name="withdrawal_date">
                <DatePicker className="date_input" onChange={(v) => { onChangeFilterHandler('withdrawal_date', v ? v.utc().startOf('day').toISOString() : undefined); }} format="DD/MM/YYYY"/>
              </Form.Item>
            </Col>
            <Col offset={1}>
              <p className={style.labelSearch}>{t('shipmentList.deliveryDateSearch')}</p>
              <Form.Item name="start_shipment_date">
                <DatePicker className="date_input" onChange={(v) => { onChangeFilterHandler('start_shipment_date', v ? v.utc().startOf('day').toISOString() : undefined); }} format="DD/MM/YYYY"/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      <EstimationsTable
        page={page}
        onSelectedEstimations={onSelectedEstimations}
        pageSize={pageSize}
        estimationsList={estimationsList}
        setPage={setPage}
        setPageSize={setPageSize}
        isArchived={isArchived}
      />
    </div>
  );
};

export default EstimationsInfo;
