import React, { useEffect } from 'react';
import {
  Checkbox,
  Col, Form, Input, Modal, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import style from './ContractorSelect.module.css';
import { useAppDispatch, useAppSelector } from '../../store/store';
import CustomButton from '../CustomButton/CustomButton';
import {
  requestClearContractor,
  requestCreateContractor,
  requestUpdateContractor,
} from '../../store/redux_slice/contractors/contractorSlice';

interface Props {
  visible: boolean,
  onCancel: () => void
}

export const EditContractorModal: React.FC<Props> = ({
  visible, onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const contractor = useAppSelector((state) => state.contractor?.contractor);

  useEffect(() => {
    form.setFieldsValue(contractor || {});
  }, [contractor?.id]);

  const finish = () => {
    onCancel();
    form.resetFields();
    dispatch(requestClearContractor());
  };

  const onFinish = async (v) => {
    if (contractor) {
      dispatch(requestUpdateContractor({
        ...contractor,
        ...v,
      }));
    } else {
      dispatch(requestCreateContractor({
        ...v,
      }));
    }
    finish();
  };

  const onCancelHandler = () => {
    finish();
  };

  return (
    <Modal title={t('forms.estimations.update_contractor')}
      visible={visible}
      onCancel={onCancelHandler}
      footer={false}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('forms.contractor.name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('validation.empty'),
                },
              ]}
            >
              <Input type="text"/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('forms.contractor.email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('validation.empty'),
                },
              ]}
            >
              <Input type="text"/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('forms.contractor.phone')}
              name="phone"
            >
              <Input type="text"/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('forms.contractor.vatNumber')}
              name="vat_number"
              rules={[
                {
                  required: true,
                  message: t('validation.empty'),
                },
              ]}
            >
              <Input type="text"/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={21}>
            <Form.Item
              label={t('forms.contractor.address')}
              name="address"
              rules={[
                {
                  required: true,
                  message: t('validation.empty'),
                },
              ]}
            >
              <Input type="text"/>
            </Form.Item>
          </Col>
          <Col span={2} offset={1}>
            <Form.Item
              label=" "
              name="fiscal_number"
              rules={[
                {
                  required: true,
                  message: t('validation.empty'),
                },
              ]}
            >
              <Input type="text" placeholder="CAP"/>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row> */}
        {/*  <Col span={24}> */}
        {/*    <Form.Item name="is_address_different"> */}
        {/*      <Checkbox className="form_checkbox" checked={contractor?.is_address_different || false}> */}
        {/*        {t('forms.contractor.is_address_different')} */}
        {/*      </Checkbox> */}
        {/*    </Form.Item> */}
        {/*  </Col> */}
        {/* </Row> */}
        <Row>
          <Col>
            <CustomButton
              type="primary"
              className={style.proceedBtn}
              htmlType={'submit'}
            >
              {t('forms.contractor.confirm')}
            </CustomButton>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
