import { useTranslation } from 'react-i18next';
import { Input, Select, Space } from 'antd';
import { FilterFilled, ReloadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import lodash from 'lodash';
import CustomButton from '../../components/CustomButton/CustomButton';
import style from './transports.module.css';
import { useAppSelector } from '../../store/store';

const FilterContainer: React.FC<{
    search: string,
    setSearch: (value: string) => void,
    setContractorsFilter: (value: string) => void,
    setNumberPalletFilter: (value: string) => void,
    setUnavailableFilter: (value: boolean | null) => void,
    contractorsFilter: string,
    numberPalletFilter: string,
    unavailableFilter: boolean | null,
}> = ({
  search, setContractorsFilter, setUnavailableFilter, setNumberPalletFilter, setSearch,
  contractorsFilter, unavailableFilter, numberPalletFilter,
}) => {
  const { t } = useTranslation();
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const transport = useAppSelector((state) => state.transport.transport);
  const { Option } = Select;
  return (
    <>
      <div className={style.filter_container}>
        <Input
          value={search}
          className={style.filter_input}
          placeholder={t('forms.transports.free_search')}
          onChange={(e) => setSearch(e.target.value)}
        />
        <CustomButton
          className={style.searchBtn}
          icon={<FilterFilled/>}
          onClick={() => setIsAdvanced(!isAdvanced)}
        >
          {t('forms.estimations.filter_button')}
        </CustomButton>
        <CustomButton
          className={style.resetBtn}
          icon={<ReloadOutlined/>}
          onClick={() => {
            setSearch('');
            setContractorsFilter('');
            setNumberPalletFilter('');
            setUnavailableFilter(null);
          }}
        >
          {t('forms.estimations.reset_button')}
        </CustomButton>
      </div>
      {isAdvanced && transport && (
        <Space className={style.advancedSearch} size="large">
          <Space direction="vertical">
            <p className={style.left}>{t('forms.shipmentDetails.agency')}</p>
            <Select className={style.select_filter}
              onChange={(value: string) => setContractorsFilter(value)}
              value={contractorsFilter}
            >
              {lodash.uniq(transport
                .map((mezzo) => mezzo.contractor))
                .map((contactor) => (
                  <Option key={contactor} value={contactor}>{contactor}</Option>
                ))}
            </Select>
          </Space>
          <Space direction="vertical">
            <p className={style.left}>{t('forms.transports.max_pallet_number')}</p>
            <Select className={style.select_filter}
              onChange={(value: string) => setNumberPalletFilter(value)}
              value={numberPalletFilter}
            >
              {lodash.uniq(transport
                .map((mezzo) => mezzo.pallet_capacity))
                .map((capacity) => (
                  <Option key={capacity} value={capacity}>{capacity}</Option>
                ))}
            </Select>
          </Space>
          <Space direction="vertical">
            <p className={style.left}>{t('forms.transports.availability')}</p>
            <Select className={style.select_filter}
              onChange={(value: boolean) => setUnavailableFilter(value)}
              value={unavailableFilter}
            >
              <Option value={false}>{t('forms.transports.yes')}</Option>
              <Option value={true}>{t('forms.transports.no')}</Option>
            </Select>
          </Space>
        </Space>
      )}
    </>
  );
};
export default FilterContainer;
