import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShipmentTrackingStatuses } from '../types';

export const requestShippingTrackingStatueAction = createAction<string>('requestShippingTrackingStatueAction');

type State = {
  trackingStatus: ShipmentTrackingStatuses | null,
  trackingLoading: boolean,
}
const initialState: State = {
  trackingStatus: null,
  trackingLoading: false,
};

const shipmentTrackingStatusSlice = createSlice({
  name: 'shipmentTrackingStatusSlice',
  initialState,
  reducers: {
    setTrackingLoading: (state, action: PayloadAction<boolean>) => {
      state.trackingLoading = action.payload;
    },
    setTrackingStatus: (state, action: PayloadAction<ShipmentTrackingStatuses>) => {
      state.trackingStatus = action.payload;
    },
  },
});

export const {
  setTrackingLoading, setTrackingStatus,
} = shipmentTrackingStatusSlice.actions;

export default shipmentTrackingStatusSlice.reducer;
