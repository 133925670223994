import { put, takeLatest } from 'redux-saga/effects';
import { setLoading, setTransportTypeData, requestTransportTypes } from '../../redux_slice/transport/transportTypesSlice';
import { GetTransportTypes } from '../../../api/transtort';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* getTransportTypesSaga() {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetTransportTypes());
    yield put(setTransportTypeData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* transportTypesSaga() {
  yield takeLatest(requestTransportTypes, getTransportTypesSaga);
}
