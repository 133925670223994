import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { UserStructure } from '../../types';

export const requestOperatorsList = createAction<boolean>('requestOperatorsList');

type State = {
    operatorsList: UserStructure[] | null
    loading: boolean,
}
const initialState : State = {
  operatorsList: null,
  loading: false,
};

const operatorsListSlice = createSlice({
  name: 'operatorsList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOperatorsList: (state, action: PayloadAction<UserStructure[]>) => {
      state.operatorsList = action.payload;
    },
  },
});

export const { setOperatorsList, setLoading } = operatorsListSlice.actions;

export default operatorsListSlice.reducer;
