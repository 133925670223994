import { useMemo } from 'react';
import EditableTable from '../index';
import { GetFinancePalletEstimation } from 'api';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import { useAppDispatch } from 'store/store';
import { Pallet, ExtraServiceStructure } from 'store/types';
import { EditableDetailsTableTypes } from 'enums';

type PalletsTableProps = {
  dataArray: Pallet[],
  updateOptionHandler: (data) => void,
  palletEstimate: {
    price_list_id: number,
    province: string,
    region: string
  } | null,
  editable: boolean,
  deliveryType: string,
  options: ExtraServiceStructure[],
}

const PalletsTable = ({
  dataArray, updateOptionHandler, palletEstimate, editable, deliveryType, options,
}: PalletsTableProps) => {
  const dispatch = useAppDispatch();
  const newTableData = useMemo(() => (
    dataArray.map((sourceItem, i) => ({
      key: `pallets_${i}`,
      item_quantity: sourceItem.item_quantity,
      depth: sourceItem.depth,
      width: sourceItem.width,
      height: sourceItem.height,
      weight: sourceItem.weight,
      show: sourceItem.show ? 'Si' : 'No',
      base_price: sourceItem.base_price || '-',
      coef: sourceItem.coef || 0,
      price: sourceItem.price,
    }))
  ), [dataArray]);

  const parceSavedData = async (row, key: string) => {
    const findPallet = newTableData.find((item) => item.key === key);
    let initPallets:Pallet = {
      item_quantity: Math.trunc(row.item_quantity),
      depth: +row.depth,
      width: +row.width,
      height: +row.height,
      weight: +row.weight,
      base_price: findPallet?.base_price || '-',
      coef: findPallet?.coef || 0,
      price: row.price ? row.price : 0,
      show: row.show === 'Si',
    };
    if (palletEstimate && !row.price) {
      try {
        const { data } = await GetFinancePalletEstimation({
          ...palletEstimate,
          items: [initPallets],
          options,
        });
        initPallets = data[0] as Pallet;
      } catch (e) {
        dispatch(setMessage({ description: e.response.data.message }));
      }
    }
    let newDataArr:Pallet[] = [];
    const rowIsAvailable = dataArray.find((item, i) => `${EditableDetailsTableTypes.invoice_pallets}_${i}` === key);
    if (rowIsAvailable) {
      newDataArr = dataArray.map((item, i) => (
        (`${EditableDetailsTableTypes.invoice_pallets}_${i}` === key) ? {
          ...initPallets,
        } : item));
    } else {
      newDataArr = [...dataArray, initPallets];
    }
    updateOptionHandler({ [EditableDetailsTableTypes.invoice_pallets]: newDataArr });
    return newDataArr;
  };

  const deleteHandler = (key:string) => {
    const data = {
      [EditableDetailsTableTypes.invoice_pallets]: dataArray.filter((item, i) => `${EditableDetailsTableTypes.invoice_pallets}_${i}` !== key),
    };
    updateOptionHandler(data);
  };

  return (
    <EditableTable
      tableType={EditableDetailsTableTypes.invoice_pallets}
      dataSource={newTableData}
      parceSavedData={parceSavedData}
      deleteHandler={deleteHandler}
      initialFields={{
        key: `${EditableDetailsTableTypes.invoice_pallets}_${newTableData.length}`,
      }}
      editable={editable}
      deliveryType={deliveryType}
    />
  );
};

export default PalletsTable;
