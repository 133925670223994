import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { UserPriceListPayload, UsersListPayload, UsersListStructure } from '../../types';

export const requestUsersList = createAction<UsersListPayload>('requestUsersList');
export const requestChangeUserPriceList = createAction<UserPriceListPayload>('requestChangeUserPriceList');

type State = {
    usersList: UsersListStructure | null
    loading: boolean,
}
const initialState : State = {
  usersList: null,
  loading: false,
};

const usersListSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUsersList: (state, action: PayloadAction<UsersListStructure | null>) => {
      state.usersList = action.payload;
    },
  },
});

export const { setUsersList, setLoading } = usersListSlice.actions;

export default usersListSlice.reducer;
