import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import classnames from 'classnames';
import { CalendarFilled, ClockCircleFilled } from '@ant-design/icons/lib/icons';
import { format } from 'date-fns';
import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import style from './estimations.module.css';
import { OpenModalButton } from './OpenModalButton';
import { EstimationFieldModal, useModalState } from './hooks';
import AssignPriceListForm from './AssignPriceListForm';
import CalculationBtn from 'components/CalculationBtn';
import { useAppDispatch, useAppSelector } from '../../store/store';
import {
  ExtraServiceStructure,
  PatchEstimation,
  PriceListTemplate,
  ShipmentStructure,
  ShippingEstimateCreatePayload,
  UpdateShippingEstimateRequest,
} from '../../store/types';
import { ChangeAddressModal } from './FieldModals/ChangeAddressModal';
import { ChangeDateModal } from './FieldModals/ChangeDateModal';
import { ChangeShippingEstimateModal } from './FieldModals/ChangeShippingEstimateModal';
import { ChangeTimeModal } from './FieldModals/ChangeTimeModal';
import { ChangeDDTNumberModal } from './FieldModals/ChangeDDTNumberModal';
import {
  requestGetServicesByPriceList,
} from '../../store/redux_slice/extraByPriceListSlice';
import {
  requestCreatePriceShippingEstimate,
  requestCreateShippingEstimate,
  requestUpdateEstimation,
  requestUpdateShippingEstimate,
} from '../../store/redux_slice/estimationsListSlice';
import {
  requestCreatePriceToBeApprovedShippingEstimate,
  requestCreateToBeApprovedShippingEstimate,
  requestUpdateToBeApprovedShippingEstimate,
  updateToBeApprovedShipmentAction,
} from '../../store/redux_slice/shipping/shippingBeforeApproveSlice';
import { isEditable } from './ModalInfo';
import Status from '../../components/Status';
import { AddressFields, DeliveryType } from 'enums';

const ModalInfoContainerForm:React.FC<{
  estimation: ShipmentStructure,
  isEstimation: boolean,
  priceListTemplates: PriceListTemplate[],
  services: ExtraServiceStructure[] | null,
  onRequestGetServicesByPriceList: (id: number) => void,
  onRequestUpdateEstimation: (data: PatchEstimation) => void,
  onRequestUpdateShippingEstimate: (data: UpdateShippingEstimateRequest) => void,
  onRequestCreatePriceShippingEstimate: (data: ShippingEstimateCreatePayload) => void,
  onRequestCreateShippingEstimate: (v: string) => void,
  setVisible?: () => void,
  available:boolean,
}> = ({
  estimation,
  isEstimation,
  priceListTemplates,
  services,
  onRequestUpdateEstimation,
  onRequestUpdateShippingEstimate,
  onRequestCreateShippingEstimate,
  onRequestCreatePriceShippingEstimate,
  setVisible,
  available,
}) => {
  const { t } = useTranslation();
  const [priceListName, setPriceListName] = useState<string | undefined>('');
  const contractors = useAppSelector((state) => state.contractors?.contractors);
  const shipmentContractor = contractors.find((el) => el.id === estimation?.contractor?.[0]?.ResContract.contractor_id);
  const {
    openModal,
    closeModal,
    isOpen,
  } = useModalState();
  const estimationCost = useMemo(() => {
    if (estimation?.shipping_estimate?.price) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(estimation?.shipping_estimate?.price);
    }
    return '--€';
  }, [estimation?.shipping_estimate?.price]);

  useEffect(() => {
    if ((estimation?.user?.price_list_id || estimation?.price_list_id) && priceListTemplates.length) {
      const priceListId = estimation?.price_list_id || estimation?.user?.price_list_id;
      const userPriceList = priceListTemplates.find((p) => p.id === priceListId);
      setPriceListName(userPriceList?.name);
    }
  }, [estimation?.price_list_id, estimation?.user?.price_list_id, priceListTemplates]);

  const getShipmentOrEstimationId = () => (
    isEstimation ? estimation.preventivi_id : estimation.spedizione_id
  );

  const onUpdateAddress = (address: Partial<ShipmentStructure>) => {
    onRequestUpdateEstimation({
      id: estimation.id,
      data: { ...address },
    });
  };

  const onUpdateTime = (time: string) => {
    const noteRitiro = estimation.delivery_type === DeliveryType.RITIRO
      ? { note_ritiro: `${t('forms.shipmentRequest.start_shipment_time_note')}: ${time}` } : {};
    onRequestUpdateEstimation({
      id: estimation.id,
      data: {
        start_shipment_time: time,
        ...noteRitiro,
      },
    });
  };

  const onCalculateOrder = () => {
    onRequestUpdateEstimation({
      id: estimation.id,
      data: { ...estimation },
      recalculate: true,
    });
  };

  return (
    <>
      <ChangeAddressModal
        orderData={estimation}
        field={AddressFields.PICKUP_ADDRESS}
        deliveryType={DeliveryType.RITIRO}
        visible={isOpen(EstimationFieldModal.openPickupAddressModal)}
        onCancel={closeModal}
        onRequestUpdateOrder={onUpdateAddress}
        hideName
      />
      <ChangeAddressModal
        orderData={estimation}
        visible={isOpen(EstimationFieldModal.openShippingAddressModal)}
        field={AddressFields.SHIPPING_ADDRESS}
        deliveryType={DeliveryType.CONSEGNA}
        onCancel={closeModal}
        onRequestUpdateOrder={onUpdateAddress}
        hideName
      />
      <ChangeDateModal
        id={estimation?.id}
        field="start_shipment_date"
        initialValue={estimation?.start_shipment_date}
        visible={isOpen(EstimationFieldModal.openPickupDateModal)}
        onCancel={closeModal}
        onRequestUpdateEstimation={onRequestUpdateEstimation}
        consegnaDate={estimation?.withdrawal_date}
      />
      <ChangeDateModal
        id={estimation?.id}
        initialValue={estimation?.withdrawal_date}
        field="withdrawal_date"
        visible={isOpen(EstimationFieldModal.openShippingDateModal)}
        onCancel={closeModal}
        onRequestUpdateEstimation={onRequestUpdateEstimation}
        ritiroDate={estimation?.start_shipment_date}
      />
      <ChangeTimeModal
        visible={isOpen(EstimationFieldModal.openTimeModal)}
        onCancel={closeModal}
        initialValue={estimation?.start_shipment_time}
        onUpdateTime={onUpdateTime}
      />
      <ChangeShippingEstimateModal
        id={estimation?.id}
        visible={isOpen(EstimationFieldModal.openPriceModal)}
        onCancel={closeModal}
        shippingEstimate={!!estimation.shipping_estimate}
        estimationCost={estimation?.shipping_estimate?.price || 0}
        onRequestCreatePriceShippingEstimate={onRequestCreatePriceShippingEstimate}
        onRequestUpdateShippingEstimate={onRequestUpdateShippingEstimate}
      />
      <ChangeDDTNumberModal
        id={estimation.id}
        visible={isOpen(EstimationFieldModal.openDDTNumberModal)}
        onCancel={closeModal}
        onRequestUpdateEstimation={onRequestUpdateEstimation}
        ddtNumberValue={estimation.ddt_number}
      />
      <Row>
        <Col span={14}>
          <div className="main-title-30px">
            {isEstimation ? t('forms.estimations.quote_detail') : t('forms.estimations.withdrawal_request_detail')}
            <span className={style.shipping_status_id}>{' '}
              #{getShipmentOrEstimationId() || estimation.id}
            </span>
          </div>
          <div className={classnames('semi-bold', style.filter_container)}>
            {t('forms.deliveries.customer')}:
            <span className="extra-bolt-corsivo">
              {estimation.pallex_client_name || estimation?.user?.name}
            </span>
          </div>
          { isEstimation && (
            <div className={classnames('semi-bold', style.filter_container)}>
              {t('forms.deliveries.refferer')}:
              <span className="extra-bolt-corsivo">
                {estimation.shipping_address.name_and_surname_reference || estimation.shipping_address.company_name}
              </span>
            </div>
          )}
          { isEstimation && (
            <div className={classnames('semi-bold', style.filter_container)}>
              {t('forms.deliveries.email')}:
              <span className="extra-bolt-corsivo">
                {estimation.user.email}
              </span>
            </div>
          )}
          <Row className={style.detail_top_container}>
            <Col span={10} className={classnames('semi-bold', style.address_column)}>
              <div className={style.col_container}>
                <span className={style.paragraph}>{t('forms.estimations.sender')}:</span>
                {estimation.pickup_address?.gdo_id && (
                  <span className={style.gdo_label}>GDO</span>
                )}
              </div>
              <div>
                <div className={style.address_styles}>
                  <span className="extra-bolt-corsivo">
                    {estimation?.pickup_address?.company_name || estimation?.pickup_address?.name_and_surname_reference}
                  </span>
                  <span>
                    {`${estimation?.pickup_address?.location?.street} ${estimation?.pickup_address?.location?.house_number || ''},`}
                  </span>
                  <span>
                    {estimation?.pickup_address?.location?.province}
                  </span>
                  <span className={style.on_edit_container}>
                    {estimation?.pickup_address?.location?.post_code} {estimation?.pickup_address?.location?.city}
                    {isEditable(isEstimation, estimation?.isArchived)
                      && <OpenModalButton
                        modal={EstimationFieldModal.openPickupAddressModal}
                        openModal={openModal}
                        className={style.on_edit_btn}
                      />
                    }
                  </span>
                </div>
                <div className={classnames(style.date_margin, style.on_edit_container, 'extra-bolt-corsivo')}>
                  {
                    estimation?.start_shipment_date
                      && <>
                        <CalendarFilled className={style.primary_color}/>
                        {format(new Date(estimation?.start_shipment_date), 'dd-MM-yyyy')}
                      </>
                  }
                  {
                    isEditable(isEstimation, estimation?.isArchived)
                      && <OpenModalButton
                        modal={EstimationFieldModal.openPickupDateModal}
                        openModal={openModal}
                        className={style.on_edit_btn}
                      />
                  }
                </div>
                <div className={classnames(style.date_margin, style.on_edit_container, 'extra-bolt-corsivo')}>
                  { estimation?.start_shipment_time
                      && <>
                        <ClockCircleFilled className={style.primary_color}/>
                        {estimation?.start_shipment_time}
                      </>
                  }
                  {isEditable(isEstimation, estimation?.isArchived)
                      && <OpenModalButton
                        modal={EstimationFieldModal.openTimeModal}
                        openModal={openModal}
                        className={style.on_edit_btn}
                      />
                  }
                </div>
              </div>
            </Col>
            <Col span={10} offset={2} className={classnames('semi-bold', style.address_column)}>
              <div className={style.col_container}>
                <span className={style.paragraph}>{t('forms.estimations.destination')}:</span>
                {estimation.shipping_address?.gdo_id && (
                  <span className={style.gdo_label}>GDO</span>
                )}
              </div>
              <div>
                <div className={style.address_styles}>
                  <span className="extra-bolt-corsivo" >
                    {estimation?.shipping_address?.company_name
                        || estimation?.shipping_address?.name_and_surname_reference}
                  </span>
                  <span>
                    {`${estimation?.shipping_address?.location?.street} ${estimation?.shipping_address?.location?.house_number || ''},`}
                  </span>
                  <span>
                    {estimation?.shipping_address?.location?.province}
                  </span>
                  <span className={style.on_edit_container}>
                    {estimation?.shipping_address?.location?.post_code} {estimation?.shipping_address?.location?.city}
                    { isEditable(isEstimation, estimation?.isArchived)
                      && <OpenModalButton
                        modal={EstimationFieldModal.openShippingAddressModal}
                        openModal={openModal}
                        className={style.on_edit_btn}
                      />
                    }
                  </span>
                </div>
                <div className={classnames(style.date_margin, style.on_edit_container, 'extra-bolt-corsivo')}>
                  {
                    estimation?.withdrawal_date
                      && <>
                        <CalendarFilled className={style.primary_color}/>
                        {format(new Date(estimation?.withdrawal_date), 'dd-MM-yyyy')}
                      </>
                  }
                  {
                    isEditable(isEstimation, estimation?.isArchived)
                      && <OpenModalButton
                        modal={EstimationFieldModal.openShippingDateModal}
                        openModal={openModal}
                        className={style.on_edit_btn}
                      />
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <p className={classnames(style.cost_text, style.left_text)}>
            {t('forms.newEstimation.total_cost')}
            <span className={style.price_text}>
              {estimationCost}
            </span>
            {
              isEditable(isEstimation, estimation?.isArchived)
                && <OpenModalButton
                  modal={EstimationFieldModal.openPriceModal}
                  openModal={openModal}
                />
            }
            <CalculationBtn onCalculateHandler={onCalculateOrder}/>
          </p>
          <div className={style.left_text}>
            <p>{t('forms.shipmentRequest.ddt_number')}:</p>
            <span className="extra-bolt-corsivo">
              {' '} {estimation.ddt_number ? estimation.ddt_number : '--'}
            </span>
            {
              isEditable(isEstimation, estimation?.isArchived)
                && <OpenModalButton
                  modal={EstimationFieldModal.openDDTNumberModal}
                  openModal={openModal}
                />
            }
          </div>
          { estimation.codice_cliente_spedizione && (
            <div className={style.left_text}>
              <p>{t('forms.shipmentRequest.shipping_client_code')}:</p>
              <span className="extra-bolt-corsivo">
                {' '} {estimation.codice_cliente_spedizione}
              </span>
            </div>
          )}
          { !isEstimation && (
            <div className={classnames(style.status_top, style.left_text, style.inline)}>
              <p>Stato:</p>
              <div className={style.status_container}>
                <Status status={estimation.status}/>
              </div>
            </div>
          )}
          {
            isEditable(isEstimation, estimation?.isArchived)
              ? <div>
                <AssignPriceListForm
                  estimation={estimation}
                  openModal={openModal}
                  closeModal={closeModal}
                  isOpen={isOpen}
                  isEstimation={isEstimation}
                  onRequestUpdateEstimation={onRequestUpdateEstimation}
                  onRequestCreateShippingEstimate={onRequestCreateShippingEstimate}
                  setVisible={setVisible}
                  available={available}
                  estimationCost={estimationCost}
                />
              </div>
              : <div className={style.detail_top_container}>
                <p className={classnames(style.options_text, style.user_info)}>
                  {t('forms.estimations.contractor_type')}:
                  <span className={style.options_bolt_text}>
                    {shipmentContractor?.name}
                  </span>
                </p>
                <p className={classnames(style.options_text, style.user_info)}>
                  {t('forms.estimations.delivery_type')}:
                  <span className={style.options_bolt_text}>
                    {estimation?.delivery_type}
                  </span>
                </p>
                <p className={classnames(style.options_text, style.user_info)}>
                  {t('forms.estimations.user_price_list')}:
                  <span className={style.options_bolt_text}>
                    {priceListName}
                  </span>
                </p></div>
          }
        </Col>
      </Row>
    </>
  );
};

export type ModalInfoContainerProps = {
  estimation: ShipmentStructure,
  isEstimation: boolean,
  setVisible?: () => void,
  available: boolean,
}

export const ModalInfoContainer:React.FC<ModalInfoContainerProps> = (props) => {
  const dispatch = useAppDispatch();
  const priceListTemplates = useAppSelector((state) => state.priceListTemplates?.priceListTemplates);
  const services = useAppSelector((state) => state.extraServicesByPriceList?.services);
  const onRequestGetServicesByPriceList = useCallback(
    (priceListId: number) => dispatch(requestGetServicesByPriceList(priceListId)),
    [dispatch],
  );
  const onRequestUpdateEstimation = useCallback(
    (data: PatchEstimation) => dispatch(requestUpdateEstimation(data)),
    [dispatch],
  );
  const onRequestUpdateShippingEstimate = useCallback(
    (data: UpdateShippingEstimateRequest) => dispatch(requestUpdateShippingEstimate(data)),
    [dispatch],
  );
  const onRequestCreateShippingEstimate = useCallback(() => {
    const { estimation } = props;
    dispatch(requestCreateShippingEstimate(estimation));
  }, [dispatch, props]);

  const onRequestCreatePriceShippingEstimate = useCallback(
    (data: ShippingEstimateCreatePayload) => {
      dispatch(requestCreatePriceShippingEstimate(data));
    },
    [dispatch],
  );
  return <ModalInfoContainerForm
    {...props}
    priceListTemplates={priceListTemplates}
    services={services }
    onRequestGetServicesByPriceList={onRequestGetServicesByPriceList}
    onRequestUpdateEstimation={onRequestUpdateEstimation}
    onRequestUpdateShippingEstimate={onRequestUpdateShippingEstimate}
    onRequestCreateShippingEstimate={onRequestCreateShippingEstimate}
    onRequestCreatePriceShippingEstimate={onRequestCreatePriceShippingEstimate}
  />;
};

export const ModalInfoContainerToBeApproved:React.FC<ModalInfoContainerProps> = (props) => {
  const dispatch = useAppDispatch();
  const priceListTemplates = useAppSelector((state) => state.priceListTemplates?.priceListTemplates);
  const services = useAppSelector((state) => state.extraServicesByPriceList?.services);
  const onRequestGetServicesByPriceList = useCallback(
    (priceListId: number) => dispatch(requestGetServicesByPriceList(priceListId)),
    [dispatch],
  );
  const onRequestUpdateEstimation = useCallback(
    (data: PatchEstimation) => dispatch(updateToBeApprovedShipmentAction(data)),
    [dispatch],
  );

  const onRequestUpdateShippingEstimate = useCallback(
    (data: UpdateShippingEstimateRequest) => {
      dispatch(requestUpdateToBeApprovedShippingEstimate(data));
    },
    [dispatch],
  );
  const onRequestCreatePriceShippingEstimate = useCallback(
    (data: ShippingEstimateCreatePayload) => {
      dispatch(requestCreatePriceToBeApprovedShippingEstimate(data));
    },
    [dispatch],
  );
  const onRequestCreateShippingEstimate = useCallback(() => {
    const { estimation } = props;
    dispatch(requestCreateToBeApprovedShippingEstimate(estimation));
  }, [dispatch, props]);

  return <ModalInfoContainerForm
    {...props}
    priceListTemplates={priceListTemplates}
    services={services }
    onRequestGetServicesByPriceList={onRequestGetServicesByPriceList}
    onRequestUpdateEstimation={onRequestUpdateEstimation}
    onRequestUpdateShippingEstimate={onRequestUpdateShippingEstimate}
    onRequestCreatePriceShippingEstimate={onRequestCreatePriceShippingEstimate}
    onRequestCreateShippingEstimate={onRequestCreateShippingEstimate}
  />;
};

export default ModalInfoContainer;
