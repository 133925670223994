import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterPriceLists } from '../../types';

export const requestSetFilter = createAction<Partial<FilterPriceLists>>('requestSetFilter');
export const requestResetFilter = createAction('requestResetFilter');

type State = {
  filter: FilterPriceLists
}

const initialState: State = {
  filter: {
    shipments_count: '',
    date: '',
    users_count: '',
    search: '',
  },
};

const filterPriceListSlice = createSlice({
  name: 'filterPriceListSlice',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<Partial<FilterPriceLists>>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetFilter: (state) => {
      state.filter = { ...initialState.filter };
    },
  },
});

export const {
  setFilter,
  resetFilter,
} = filterPriceListSlice.actions;

export default filterPriceListSlice.reducer;
