import { put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import { UpdateUserPayload, UserPayload } from '../../types';
import {
  setLoading, setUser, requestCreateUser, requestGetUser, requestDeleteUser, requestUpdateUser, requestGetAccessPrivate,
} from '../../redux_slice/users/userSlice';
import {
  CreateNewUser, GetUsersList, GetUserById, DeleteUserById, UpdateUser,
} from '../../../api/users';
import { setUsersList } from '../../redux_slice/users/usersListSlice';
import { GetAccessPrivate } from '../../../api';
import { setMessage } from '../../redux_slice/messageErrorSlice';
import { setCompanyUsersList } from '../../redux_slice/users/usersCompanyListSlice';

function* createUserSaga(action: {
    payload: {
        user: UserPayload,
        typology: string,
    }
}) {
  yield put(setLoading(true));
  try {
    (yield CreateNewUser(action.payload.user));
    const { data } = (yield GetUsersList({
      typology: action.payload.typology,
    }));
    yield put(setUsersList(data));
    const { data: dataUsers } = (yield GetUsersList({
      typology: 'Company',
    }));
    yield put(setCompanyUsersList(dataUsers));
  } catch (e) {
    yield put(setMessage({ description: i18n.t('messages.create_user_error'), title: i18n.t('messages.attention') }));
  }
  yield put(setLoading(false));
}

function* getUserSaga(action: {
    payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetUserById(action.payload));
    yield put(setUser(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* deleteUserSaga(action: {
    payload: {
        id: string,
        typology: string,
    }
}) {
  yield put(setLoading(true));
  try {
    (yield DeleteUserById(action.payload.id));
    const { data } = (yield GetUsersList({
      typology: action.payload.typology,
    }));
    yield put(setUsersList(data));
    const { data: dataUsers } = (yield GetUsersList({
      typology: 'Company',
    }));
    yield put(setCompanyUsersList(dataUsers));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* updateUserSaga(action: {
    payload: {
        user: UpdateUserPayload,
        typology: string,
    }
}) {
  yield put(setLoading(true));
  try {
    (yield UpdateUser(action.payload.user));
    const { data } = (yield GetUsersList({
      typology: action.payload.typology,
    }));
    yield put(setUsersList(data));
  } catch (e) {
    yield put(setMessage({ description: i18n.t('messages.create_user_error'), title: i18n.t('messages.attention') }));
  }
  yield put(setLoading(false));
}

function* getUserAccessPrivateSaga(action: {
    payload: {
        email: string,
        typology: string,
    }
}) {
  yield put(setLoading(true));
  try {
    (yield GetAccessPrivate(action.payload.email));
    const { data } = (yield GetUsersList({
      typology: action.payload.typology,
    }));
    yield put(setUsersList(data));
    yield put(setMessage({ description: i18n.t('messages.access_private') }));
  } catch (e) {
    yield put(setMessage({ description: i18n.t('messages.access_private_error') }));
  }
  yield put(setLoading(false));
}

export function* userSaga() {
  yield takeLatest(requestCreateUser, createUserSaga);
  yield takeLatest(requestGetUser, getUserSaga);
  yield takeLatest(requestDeleteUser, deleteUserSaga);
  yield takeLatest(requestUpdateUser, updateUserSaga);
  yield takeLatest(requestGetAccessPrivate, getUserAccessPrivateSaga);
}
