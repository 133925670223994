import cn from 'classnames';
import {
  Select,
} from 'antd';
import { CustomSelectProps } from './CustomSelect';
import style from './CustomSelect.module.css';

const { Option } = Select;

const CustomSelect = ({
  options,
  placeholder,
  value,
  defaultValue,
  onChange,
  width,
  disabled = false,
  withEmpty = true,
} : CustomSelectProps): JSX.Element => {
  const valueProps = value === undefined
    ? { defaultValue: defaultValue ?? '' }
    : { value: value ?? '' };
  return (
    <div
      className={style.select}
      style={width ? { width, margin: 'auto' } : {}}
    >
      <Select {...valueProps} onChange={onChange} className="sub-title" disabled={disabled}>
        {
          withEmpty && <Option value="" className={cn({ [style.option_placeholder]: !!value })}>
            {placeholder ?? ''}
          </Option>
        }
        {options.map((opt) => (
          <Option key={opt.key || opt.value} value={opt.value} className="option">
            {opt.title}
          </Option>
        ))}
      </Select>
      <span className={style.focus}/>
      <span className={style.select_btn}>
        <span className={style.select_icon}/>
      </span>
    </div>
  );
};

export default CustomSelect;
