import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import DeleteModal from '../../../components/DeleteModal';
import { useSocket } from '../../../hooks/useSocket';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Loader from '../../../components/Loader';
import ModalWithCarousel from '../../../components/ModalWithCarousel';
import ShippingModal from '../components/ShippingModal';
import ShippingToBeApproveFilter from '../ShippingToBeApprovedContainer/ShippingToBeApproveFilter';
import RejectedTable from './RejectedTable';
import { useAppSelector, useAppDispatch } from '../../../store/store';
import { setRejectedShipmentsData, requestDeleteRejectedShipmentAction } from '../../../store/redux_slice/shipping/shippingRejectedSlice';
import { setCleanShipmentDataFromNotification } from '../../../store/redux_slice/notificationsSlice';
import { updateStatusAction } from '../../../store/sagas/shipping/shippingStatusSaga';
import { FilterToBeApproveShippingRequest } from '../../../store/types';
import { RoleEnums, PathEnums } from '../../../enums';
import style from '../deliveries.module.css';
import { useFixedOrder } from '../../../hooks/useFixedOrder';

const RejectedContainer = () => {
  const { t } = useTranslation();
  const socket = useSocket();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    shimpentDataFromNotification, tableTypeLoading, notificationShipmentLoading,
  } = useAppSelector((state) => state.notifications);
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(10);
  const [filter, _setFilter] = useState<Partial<FilterToBeApproveShippingRequest>>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedId, setSelectedId] = useState<string|undefined>();
  const [visible, setVisible] = useState(false);
  const role = useAppSelector((state) => state.auth.tokenStructure?.role);
  const { rejectedShipments, loading } = useAppSelector((store) => store.shippingRejceted);

  const openDeleteModal = (id: string) => {
    setIsDeleteModal(true);
    setDeleteId(id);
  };
  const onDeleteArchiveShipment = () => {
    if (deleteId) {
      dispatch(requestDeleteRejectedShipmentAction(deleteId));
      setIsDeleteModal(false);
    }
  };

  const adminArrayOptions = [
    {
      title: t('forms.deliveries.customer_data'),
      type: 'user_data',
      action: (id: string) => {
        navigate(`${PathEnums.Users}?utente_id=${id}`);
      },
    },
  ];

  const rejectedOptions = [
    {
      title: t('forms.deliveries.details'),
      action: (id: string) => {
        setSelectedId(id);
        setVisible(true);
      },
    },
    {
      title: t('shipmentList.delete'),
      action: (id: string) => openDeleteModal(id),
    },
  ];

  const tableOptions = role === RoleEnums.OPERATOR
    ? rejectedOptions : [...rejectedOptions, ...adminArrayOptions];

  useEffect(() => {
    socket.emit('RIFIUTATO', {
      ...filter,
      page,
      page_size: count,
    });
  }, [socket, dispatch, page, count, filter]);

  useEffect(() => {
    socket.connect();
    socket.on('RIFIUTATO', (data) => {
      dispatch(setRejectedShipmentsData(data?.message));
    });
    return () => {
      socket.off('RIFIUTATO');
      dispatch(setRejectedShipmentsData(null));
    };
  }, [socket, dispatch]);

  const setFilter = (data) => {
    _setFilter(data);
    setPage(1);
  };

  const onStatusChange = (status, shipment) => {
    dispatch(updateStatusAction({
      id: shipment.id,
      data: { status },
    }));
  };
  const initialSlide = rejectedShipments?.items?.findIndex((e) => String(e.id) === String(selectedId)) ?? -1;
  const itemsWithFixedOrder = useFixedOrder(
    visible,
    rejectedShipments?.items ?? [],
    selectedId ? String(selectedId) : undefined,
    () => setVisible(false),
  );

  if (loading || !rejectedShipments) {
    return <Loader/>;
  }

  return (
    <>
      <DeleteModal
        title={t('forms.deliveries.delete_shipping')}
        description={t('forms.deliveries.delete_shipping_description')}
        visible={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        onDelete={onDeleteArchiveShipment}
      />
      <Modal
        centered
        visible={!!searchParams.get('dettaglio') && !!shimpentDataFromNotification}
        footer={false}
        width={'80%'}
        className="custom_modal"
        onCancel={() => {
          searchParams.delete('dettaglio');
          setSearchParams(searchParams);
          dispatch(setCleanShipmentDataFromNotification());
        }}
      >
        { notificationShipmentLoading || tableTypeLoading ? <Loader/> : (
          shimpentDataFromNotification && <ShippingModal shipment={shimpentDataFromNotification} />
        )}
      </Modal>
      <ModalWithCarousel
        key={selectedId}
        initialSlide={initialSlide ?? -1}
        visible={visible}
        setVisible={() => {
          setVisible(false);
          setSelectedId(undefined);
        }}
        content={
          itemsWithFixedOrder?.map((shipment) => (
            <ShippingModal
              key={shipment.id}
              shipment={shipment}
            />
          ))
        }
      />
      <Row className={style.shipping_approved_container}>
        <Col span={12}>
          <div className={style.to_be_approved_title}>
            {`${t('forms.deliveries.shipments_rejected')} (${rejectedShipments?.count})`}
          </div>
          <ShippingToBeApproveFilter
            setFilter={setFilter}
            rejected
          />
        </Col>
        <Col span={4} offset={8}>
          <CustomButton
            type="primary"
            icon={<PlusOutlined/>}
            className={style.newShipmentBtn}
            href={PathEnums.New_shipment_deliveries}
          >
            {t('forms.deliveries.new_shipment')}
          </CustomButton>
        </Col>
      </Row>
      <RejectedTable
        options={tableOptions}
        rejectedShipments={rejectedShipments.items}
        page={page}
        count={count}
        total={rejectedShipments.count}
        setPage={setPage}
        setCount={setCount}
        onStatusChange={onStatusChange}
      />
    </>
  );
};

export default RejectedContainer;
