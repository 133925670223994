import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pallet } from '../types';

export const requestClearPallets = createAction('requestClearPallets');
export const requestAddPallet = createAction<Pallet[]>('requestAddPallet');

type State = {
  pallets: Pallet[]
}

const initialState: State = {
  pallets: [],
};

const palletsSlice = createSlice({
  name: 'pallets',
  initialState,
  reducers: {
    clearPallets: (state) => {
      state.pallets = [];
    },
    addPallet: (state, action: PayloadAction<Pallet[]>) => {
      state.pallets = action.payload;
    },
  },
});

export const { addPallet, clearPallets } = palletsSlice.actions;

export default palletsSlice.reducer;
