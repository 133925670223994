import {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Table, Button } from 'antd';
import { CheckOutlined, DeleteFilled, PlusOutlined } from '@ant-design/icons/lib/icons';
import OptionsButton, { Option } from '../OptionsButton';
import { makeColumnsEditableTable } from './components/TableColums';
import EditableCell from './components/EditableCell';
import { priceMapper, ServicePrice } from 'functions';
import { EditableDetailsTableTypes } from 'enums';
import { EditableTableSourceType } from 'store/types';
import styles from './editableTable.module.css';

type EditableTableType = {
  dataSource: EditableTableSourceType[],
  tableType: EditableDetailsTableTypes,
  parceSavedData: (row:EditableTableSourceType, key:string) => void,
  openModal?: () => void,
  initialFields?: Partial<EditableTableSourceType>,
  deleteHandler: (key: string) => void,
  editable: boolean,
  deliveryType?: string,
}

const EditableTable = ({
  dataSource, tableType, parceSavedData, openModal,
  initialFields, deleteHandler, editable, deliveryType,
}: EditableTableType) => {
  const adrIsLimited = useMemo(() => (
    !!(tableType === EditableDetailsTableTypes.merce_adr && dataSource.length && dataSource.find((item) => item.limited_qty === 'Si'))
  ), [dataSource, tableType]);

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [editingKey, setEditingKey] = useState<string>('');
  const [dataSourceState, setDataSourceState] = useState<EditableTableSourceType[] | null>(null);
  const [addNew, setAddNew] = useState(false);
  const [editableCell, setEditableCell] = useState(!adrIsLimited);
  const [richiedeNasSelected, setRichiedeNasSelected] = useState(false);

  const columns = makeColumnsEditableTable(t, tableType, editableCell);

  useEffect(() => {
    if (dataSource.length) {
      setDataSourceState(dataSource);
      setAddNew(false);
    }
  }, [dataSource]);

  const isEditing = useCallback((key: string | undefined) => key === editingKey, [editingKey]);

  const onRemoveLastRow = useCallback(() => {
    if (addNew && dataSourceState) {
      setDataSourceState([...dataSourceState.slice(0, dataSourceState.length - 1),
        ...dataSourceState.slice(dataSourceState.length)]);
      setAddNew(false);
    }
  }, [addNew, dataSourceState]);

  const edit = useCallback((key: string) => {
    const selectedRow = dataSourceState?.find((item) => (
      item?.key?.toUpperCase() === key.toUpperCase()
    ));
    if (tableType !== EditableDetailsTableTypes.invoice_pallets) {
      const price: ServicePrice = priceMapper(selectedRow?.price?.toString() || '');
      form.setFieldsValue({
        ...selectedRow,
        priceValue: price.value || '-',
        priceUnit: price.unit || '€',
      });
    } else {
      form.setFieldsValue(selectedRow);
    }

    setEditingKey(key);
  }, [tableType, dataSourceState, form]);

  const save = useCallback(async (key) => {
    try {
      const row = (await form.validateFields());
      const updatedData = await parceSavedData(row, key);
      const newSource = dataSourceState?.map((item, i) => (item.key === key ? { key, ...updatedData[i] } : item));
      setDataSourceState(newSource as EditableTableSourceType[]);
      setEditingKey('');
      setRichiedeNasSelected(false);
      form.resetFields();
    } catch (errInfo) {
      // error
    }
  }, [dataSourceState, form, parceSavedData]);

  const onAddRowHandler = () => {
    if (openModal) {
      openModal();
      setAddNew(false);
    }
    if (!openModal && initialFields?.key) {
      setDataSourceState(dataSourceState ? [...dataSourceState, initialFields] : [initialFields]);
      setAddNew(true);
      form.resetFields();
      edit(initialFields.key);
    }
  };

  const editRowHandler = useCallback((key:string) => {
    edit(key);
    onRemoveLastRow();
  }, [edit, onRemoveLastRow]);

  const deleteRowHandler = useCallback((key:string) => {
    if (dataSourceState) {
      deleteHandler(key);
      setDataSourceState(dataSourceState?.filter((item) => item.key !== key));
    }
  }, [deleteHandler, dataSourceState]);

  const options: Array<Option> = useMemo(() => ([
    {
      title: t('forms.estimations.edit'),
      action: (key) => editRowHandler(key),
    },
    {
      title: t('forms.estimations.delete'),
      action: (key) => deleteRowHandler(key),
    },
  ]), [t, editRowHandler, deleteRowHandler]);

  const mergedColumns = useMemo(() => (
    columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          deliveryType,
          title: col.title,
          richiedeNasSelected,
          onDescrizioneNasChange: (value: boolean) => {
            if (!value) {
              form.setFieldsValue({ descrizioneNas: '' });
            }
            setRichiedeNasSelected(value);
          },
          onAdrLimitChange: (value: boolean) => {
            setEditableCell(value);
          },
          inputType: col.inputType || 'text',
          dataIndex: col.dataIndex,
          editing: isEditing(record.key),
        }),
      };
    })
  ), [columns, deliveryType, form, isEditing, richiedeNasSelected]);

  const newDataSource = useMemo(() => (
    dataSourceState?.map((source) => (editable ? {
      ...source,
      options: (
        isEditing(source?.key) ? (
          <div className={styles.editable_btn_container}>
            <Button
              type="primary"
              shape="round"
              onClick={() => save(source?.key)}
              icon={<CheckOutlined/>}
            />
            <Button
              type="primary"
              shape="round"
              icon={<DeleteFilled/>}
              className={styles.deleteBtn}
              onClick={() => {
                setEditingKey('');
                onRemoveLastRow();
                setRichiedeNasSelected(false);
              }}
            />
          </div>
        ) : (
          <OptionsButton
            id={source?.key || ''}
            options={options}
          />
        )
      ),
    } : { ...source }))
  ), [dataSourceState, editable, isEditing, onRemoveLastRow, options, save]);

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={newDataSource}
          pagination={false}
        />
      </Form>
      { editable && (
        <div className={styles.footer_table}>
          <Button
            icon={<PlusOutlined/>}
            disabled={addNew || adrIsLimited}
            className={styles.add_pallets_btn}
            type="link"
            onClick={onAddRowHandler}
          >
            {t(`invoices.add_${tableType}`)}
          </Button>
        </div>
      )}
    </>
  );
};

export default EditableTable;
