import { instance } from './index';
import {
  FinanceListPayloadType, FinanceSectionType, ExtraFinanceType,
  ExtraServicePatchPayload, ConfirmCreationInvoicePayloadType, ConfirmDefaultUserPayloadType,
  FinanceUsersPayloadType,
} from '../store/types';

export const GetFinancelist = (data:FinanceListPayloadType) => instance.get('/finance', {
  params: { ...data },
});

export const GetFinanceLastPush = () => instance.get('/finance/last-push');

export const PostFinanceApprove = (id:number) => instance.post(`/finance/${id}/approve`);

export const PostFinanceDisapprove = (id:number) => instance.post(`/finance/${id}/disapprove`);

export const PatchOrderFinanceById = ({ id, ...data }: Partial<FinanceSectionType>) => instance.patch(`/finance/${id}`, data);

export const GetFinanceExtralist = (data:FinanceListPayloadType) => instance.get('/extra-finance', {
  params: { ...data },
});

export const PostFinanceExtra = (data:ExtraFinanceType) => instance.post('/extra-finance', data);

export const DeleteFinanceExtra = (id:number) => instance.delete(`extra-finance/${id}`);

export const PatchFinanceExtralist = ({ id, ...data }:ExtraServicePatchPayload) => instance.patch(`/extra-finance/${id}`, data.data);

export const PostExtraFinanceApprove = (id:number) => instance.post(`/extra-finance/${id}/approve`);

export const PostExtraFinanceDisapprove = (id:number) => instance.post(`/extra-finance/${id}/disapprove`);

export const PostFinanceDefaultUser = (data:ConfirmDefaultUserPayloadType) => instance.post('/finance/default/user', data);

export const PostFinanceCalculate = (id: number) => instance.post(`/finance/${id}/calculate`);

export const PostSendInvoice = (data: ConfirmCreationInvoicePayloadType) => instance.post(`/finance/send?month=${data.month}`);

export const GetFinanceUsers = (data: FinanceUsersPayloadType) => instance.get('/finance/users', {
  params: { ...data },
});

export const DeleteFinance = (id: number) => instance.delete(`/finance/${id}`);
