import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from 'antd';
import classnames from 'classnames';
import back from '../../assests/images/arrow_icon.png';
import style from './backButton.module.css';

const CustomButton = ({ text, ...props }) => (
  <Button
    className={classnames(style.button_text)}
    icon={
      <img src={back} alt="go back" className={style.button_img}/>
    }
    type="link"
    {...props}
  >
    {text}
  </Button>
);

const BackButton = ({ className, href, ...props } : ButtonProps) : JSX.Element => {
  const { t } = useTranslation();

  if (href) {
    return <Link to={href} className={classnames(style.link, className)}>
      <CustomButton
        text={t('forms.shipmentRequest.back')}
        {...props}
      />
    </Link>;
  }
  return (
    <CustomButton
      text={t('forms.shipmentRequest.back')}
      {...props}
    />
  );
};

export default BackButton;
