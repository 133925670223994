import { put, takeLatest } from 'redux-saga/effects';
import { GetNumeroOnu } from 'api/pallex';
import { requestGetNumeroOnuAction, setNumeroOnuData, setLoading } from 'store/redux_slice/pallex/numeroOnuSlice';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import { NumeroOnuPayloadType } from 'store/types';

function* getNumeroOnuSaga(action: {
  payload: NumeroOnuPayloadType,
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetNumeroOnu(action.payload);
    yield put(setNumeroOnuData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* numeroOnuSaga() {
  yield takeLatest(requestGetNumeroOnuAction, getNumeroOnuSaga);
}
