import React, { useEffect, useState } from 'react';
import { InputNumber, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/CustomButton/CustomButton';
import { ShippingEstimateCreatePayload, UpdateShippingEstimateRequest } from 'store/types';
import style from '../estimations.module.css';

export const ChangeShippingEstimateModal:React.FC<{
  id: string,
  visible: boolean,
  onCancel: () => void,
  onRequestUpdateShippingEstimate: (data: UpdateShippingEstimateRequest) => void,
  onRequestCreatePriceShippingEstimate?: (data: ShippingEstimateCreatePayload) => void,
  estimationCost: number,
  shippingEstimate?: boolean,
}> = ({
  id,
  visible,
  onCancel,
  onRequestUpdateShippingEstimate,
  onRequestCreatePriceShippingEstimate,
  shippingEstimate,
  estimationCost,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (estimationCost) {
      setValue(estimationCost);
    }
  }, [estimationCost]);

  const onChange = (price: number) => {
    const regex = /^\d+(\.\d{1,2})?$/;
    if (regex.test(price.toString())) {
      setError(false);
      setValue(price);
    } else {
      setError(true);
    }
  };

  const submit = () => {
    const data = {
      id,
      price: value,
    };
    const createData = {
      shipping_id: id,
      price: value,
      currency: 'eur',
    };
    if (shippingEstimate) {
      onRequestUpdateShippingEstimate(data);
    }
    if (!shippingEstimate && onRequestCreatePriceShippingEstimate) {
      onRequestCreatePriceShippingEstimate(createData);
    }
    onCancel();
    setError(false);
  };
  return (
    <Modal title= {t('forms.estimations.update_price')}
      visible={visible}
      onOk={submit}
      onCancel={onCancel}
      footer={false}
    >
      <InputNumber
        className={style.input_number_width}
        value={value ? +value.toFixed(2) : 0}
        onChange={onChange}
        max={1000000}
        min={0}
      />
      {error && <p className={style.error_message}>{t('validation.isNumberNotWhole')}</p>}
      <CustomButton
        className={style.modal_button}
        type="primary"
        onClick={submit}
      >
        {t('forms.estimations.update')}
      </CustomButton>
    </Modal>
  );
};
