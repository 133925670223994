import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestGetContractors } from '../../store/redux_slice/contractors/contractorsSlice';
import {
  requestClearContractor,
  requestDeleteContractor,
  requestSetContractor,
} from '../../store/redux_slice/contractors/contractorSlice';
import { EditableSelect } from '../EditableSelect/EditableSelect';
import { ContractorType } from '../../store/types';

interface Option {
  id: number;
  name: string;
  isEditable: boolean;
}

interface Props {
  initialValue: string | number;
  onChange: (contractorId: number) => void;
  onEdit: () => void;
  addPallex?: boolean;
  contractors: ContractorType[];
}

export const ContractorSelect: React.FC<Props> = ({
  initialValue, addPallex, onChange, onEdit, contractors,
}) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (contractors) {
      const initOptions: Option[] = contractors.map((c) => ({ id: c.id, name: c.name, isEditable: c.is_editable }));
      setOptions(initOptions);

      if (!initialValue && contractors.length) {
        setValue(contractors[0].name);
      }
    }
  }, [contractors]);

  const onCheckHandler = (v: string) => {
    const contractor = options.find((c) => c.id.toString() === v.toString());
    if (contractor) {
      setValue(contractor.name);
      onChange(contractor.id);
    }
  };

  const onAddHandler = () => {
    dispatch(requestClearContractor());
    onEdit();
  };

  const onEditHandler = (v: string) => {
    const contractor = contractors.find((c) => c.id.toString() === v.toString());
    if (contractor) {
      dispatch(requestSetContractor(contractor));
      onEdit();
    }
  };

  const onDeleteHandler = (v: string) => {
    const contractor = contractors.find((c) => c.id.toString() === v.toString());
    if (contractor) {
      dispatch(requestDeleteContractor(contractor));
    }
  };

  return (
    <EditableSelect
      initialValue={value}
      options={options}
      onCheck={onCheckHandler}
      onAdd={onAddHandler}
      onEdit={onEditHandler}
      onDelete={onDeleteHandler}
    />
  );
};
