import {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { Select, Space, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { formatISO } from 'date-fns';
import CardContainer from './DragNDrop/CardContainer';
import Card from './DragNDrop/Card';
import DetailsModal from './DetailsModal';
import MapboxContainer from './MapboxContainer';
import ManofestFilters from './ManofestFilters';
import Loader from '../../components/Loader';
import {
  requestGetTransportTrucksAction, requestPatchTripAction,
  requestPostAssignTripToTruckAction,
  requestGetTripByIdAction, requestGetTripCsvAction,
  requestGetTripPdfAction, setTripWithoutTruck, clearManifestoData,
} from '../../store/redux_slice/tripsSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { ShipmentStructure, ScaricoFilterType } from '../../store/types';
import styles from './manifests.module.css';
import './select.css';

const { Option } = Select;

const Manifests = () => {
  const { t } = useTranslation();
  const [visible, setVisibleModal] = useState<boolean>(false);
  const [scaricoFilter, setScaricoFilter] = useState<ScaricoFilterType>({
    date_start: formatISO(new Date()),
    filter_truck_id: null,
  });
  const dispatch = useAppDispatch();
  const {
    loading, trucks: trucksColumns, tripsToStart, trucksFilter,
  } = useAppSelector((state) => state.unloadingTrips);
  const trucksForMap = useMemo(() => trucksColumns?.filter((truck) => truck.trips.length), [trucksColumns]);

  useEffect(() => {
    if (loading) {
      setVisibleModal(false);
    }
  }, [loading]);

  useEffect(() => {
    dispatch(requestGetTransportTrucksAction({
      ...scaricoFilter,
      truck_id: scaricoFilter.filter_truck_id,
    }));
  }, [dispatch, scaricoFilter]);

  useEffect(() => () => {
    dispatch(clearManifestoData());
  }, [dispatch]);

  // eslint-disable-next-line max-len
  const moveCardToColumn = useCallback((shippingRequest: ShipmentStructure, tripId: number | null, truckId: number, oldTruckId: number | null) => {
    if (tripId && oldTruckId) {
      dispatch(requestPatchTripAction({
        trip_id: tripId,
        truck_id: truckId,
        old_truck_id: oldTruckId,
        pallets: shippingRequest.pallets,
        delivery_type: shippingRequest.delivery_type,
        ...scaricoFilter,
      }));
    } else {
      dispatch(requestPostAssignTripToTruckAction({
        truck_id: truckId,
        shipping_id: shippingRequest.id,
        pallets: shippingRequest.pallets,
        delivery_type: shippingRequest.delivery_type,
        ...scaricoFilter,
      }));
    }
  }, [dispatch, scaricoFilter]);

  const handleFilterTrucks = (value) => {
    setScaricoFilter((prevState) => ({
      ...prevState,
      filter_truck_id: value,
    }));
  };

  const onChangeDateHandler = (date) => {
    const startDate = date ? formatISO(new Date(date)) : formatISO(new Date());
    setScaricoFilter((prevState) => ({
      ...prevState,
      date_start: startDate,
    }));
  };

  const handleActionsSelect = (value) => {
    if (value === 'download_csv') {
      dispatch(requestGetTripCsvAction({
        date_start: scaricoFilter.date_start,
      }));
    }
    if (value === 'download_pdf') {
      dispatch(requestGetTripPdfAction({
        date_start: scaricoFilter.date_start,
      }));
    }
  };

  const openShippingInfoModalHandler = (id: string, deliveryType: string) => {
    if (tripsToStart) {
      const toStartDetails = tripsToStart.find((item) => item.id === id && item.delivery_type === deliveryType);
      setVisibleModal(true);
      dispatch(setTripWithoutTruck(toStartDetails as ShipmentStructure));
    }
  };

  const openTripInfoModalHandler = (id: number) => {
    setVisibleModal(true);
    dispatch(requestGetTripByIdAction(id));
  };

  return (
    <>
      <div className={styles.manifest_header}>
        <div>
          <h1 className={styles.title}>{t('manifest.title')}</h1>
          <DatePicker placeholder={t('forms.transports.number_days')}
            value={moment(scaricoFilter.date_start)}
            disabledDate={(current) => (
              current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
            )}
            onChange={onChangeDateHandler}
            className={styles.date_picker}
            format="DD/MM/YYYY"
          />
          <ManofestFilters
            scaricoFilter={scaricoFilter}
            setScaricoFilter={setScaricoFilter}
          />
        </div>
        <div className={styles.select_container}>
          <Select
            className="action_select"
            onSelect={handleActionsSelect}
            placeholder={t('manifest.actions')}
          >
            <Option value="download_csv">
              {t('manifest.downloadCsv')}
            </Option>
            <Option value="download_pdf">
              {t('manifest.downloadAllPdf')}
            </Option>
          </Select>
          <Select
            className="truck_select"
            defaultValue={t('manifest.allTrucks')}
            onSelect={handleFilterTrucks}
          >
            <Option value={null}>
              { t('manifest.allTrucks') }
            </Option>
            { trucksFilter?.truckInfo?.map(({ id, plateNumber }) => (
              <Option key={id} value={id}>
                { plateNumber }
              </Option>
            ))}
          </Select>
        </div>
      </div>
      { loading ? <Loader/> : (
        <>
          <Space className={styles.dnd_container} size={[35, 0]}>
            <CardContainer
              title={t('manifest.associated')}
              moveCardToColumn={moveCardToColumn}
            >
              { tripsToStart?.map((card) => (
                <Card
                  key={`${card.delivery_type}_${card.id}`}
                  shippingRequest={card}
                  showDetailsModal={() => openShippingInfoModalHandler(card.id, card.delivery_type)}
                />
              ))}
            </CardContainer>
            { trucksColumns?.map((truck) => (
              <CardContainer
                key={truck.id}
                truck={truck}
                moveCardToColumn={moveCardToColumn}
                dateStart={scaricoFilter.date_start}
                trucksFilter={trucksFilter}
              >
                {truck.trips.map((trip) => (
                  <Card
                    key={trip.id}
                    trip={trip}
                    shippingRequest={trip.shipping_request}
                    showDetailsModal={() => openTripInfoModalHandler(trip.id)}
                  />
                ))}
              </CardContainer>
            ))}
          </Space>

          <DetailsModal
            visible={visible}
            setVisibleModal={setVisibleModal}
            scaricoFilter={scaricoFilter}
          />
          <MapboxContainer trucks={trucksForMap || []}/>
        </>
      )}
    </>
  );
};

export default Manifests;
