import { TFunction } from 'react-i18next';
import { EditableCellProps } from '../../../../components/ExtraServicesTable/TableCell';

export const makeColumns = (t: TFunction): Partial<EditableCellProps>[] => [
  {
    title: t('pricelist.service'),
    dataIndex: 'service_name',
    key: 'service_name',
    inputType: 'text',
    editable: true,
  },
  {
    title: t('pricelist.accessoryDescription'),
    dataIndex: 'service_details',
    key: 'service_details',
    inputType: 'text',
    editable: true,
  },
  {
    title: t('pricelist.price'),
    dataIndex: 'price',
    key: 'price',
    inputType: 'price',
    editable: true,
  },
  {
    title: t('forms.newEstimation.fixed_minimum'),
    dataIndex: 'fixedMin',
    key: 'fixedMin',
    inputType: 'fixedMinPrice',
    editable: true,
  },
  {
    title: t('pricelist.apply'),
    dataIndex: 'apply_to',
    key: 'apply_to',
    inputType: 'select_category',
    editable: true,
  },
];
