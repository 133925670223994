import { Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { PatchEstimation } from '../../../../store/types';
import style from '../../deliveries.module.css';

const TrackingModal:React.FC<{
    visible: boolean,
    onCancel: () => void,
    tracking: string | null,
    onRequestUpdateEstimation: (data: PatchEstimation) => void,
    id: string,
}> = ({
  visible, onCancel, tracking, onRequestUpdateEstimation, id,
}) => {
  const { t } = useTranslation();
  const [valueTracking, setValueTracking] = useState<string | null>(tracking);
  const { Option } = Select;
  const onChangeTracking = (value: string) => {
    setValueTracking(value);
  };
  const onSubmit = () => {
    const data = {
      tracking: valueTracking,
    };
    onRequestUpdateEstimation({
      id,
      data,
    });
    onCancel();
  };
  return (
    <Modal
      title={t('forms.estimations.tracking')}
      visible={visible}
      onCancel={onCancel}
      onOk={onSubmit}
    >
      <Select
        className={style.tracking_input}
        defaultValue={tracking}
        onChange={onChangeTracking}
      >
        <Option key={'Disponsible'} value={'Disponsible'}>
          {t('forms.estimations.disponsible')}
        </Option>
        <Option key={'NonDisponsible'} value={'NonDisponsible'}>
          {t('forms.estimations.non_disponsible')}
        </Option>
      </Select>
    </Modal>
  );
};

export default TrackingModal;
