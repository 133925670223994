import { put, takeLatest } from 'redux-saga/effects';
import {
  GetDasboardArchived, GetDasboardPallets,
  GetDasboardCollcted, GetDasboardWarehouse,
} from '../../api/dashboards';
import {
  getDashboardArchivedAction, setArchivedLoading, setArchivedData,
  getDashboardPalletsAction, setPalletsLoading, setPalletsData,
  getDashboardCollectedAction, setCollctedLoading, setCollectedData,
  getDashboardWarehouseAction, setWarehouseLoading, setWarehouseData,
} from '../redux_slice/dashboardSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';
import { DashboardsPayloadType } from '../types';

function* getDashboardArchivedSaga(action:{
  payload: DashboardsPayloadType
}) {
  yield put(setArchivedLoading(true));
  try {
    const { data } = yield GetDasboardArchived(action.payload);
    yield put(setArchivedData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setArchivedLoading(false));
}

function* getDashboardPalletsSaga(action:{
  payload: DashboardsPayloadType
}) {
  yield put(setPalletsLoading(true));
  try {
    const { data } = yield GetDasboardPallets(action.payload);
    yield put(setPalletsData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setPalletsLoading(false));
}

function* getDashboardCollectedSaga(action:{
  payload: DashboardsPayloadType
}) {
  yield put(setCollctedLoading(true));
  try {
    const { data } = yield GetDasboardCollcted(action.payload);
    yield put(setCollectedData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setCollctedLoading(false));
}

function* getDashboardWarehouseSaga(action:{
  payload: DashboardsPayloadType
}) {
  yield put(setWarehouseLoading(true));
  try {
    const { data } = yield GetDasboardWarehouse(action.payload);
    yield put(setWarehouseData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setWarehouseLoading(false));
}
export function* dashboardsSaga() {
  yield takeLatest(getDashboardArchivedAction, getDashboardArchivedSaga);
  yield takeLatest(getDashboardPalletsAction, getDashboardPalletsSaga);
  yield takeLatest(getDashboardCollectedAction, getDashboardCollectedSaga);
  yield takeLatest(getDashboardWarehouseAction, getDashboardWarehouseSaga);
}
