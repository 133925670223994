import { useTranslation } from 'react-i18next';
import {
  Checkbox, Empty, List, Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import style from './shipmentDetails.module.css';
import './antdStyles.css';
import { ExtraServiceStructure, ShipmentStructure } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { setShipmentData } from '../../store/redux_slice/shipmentSlice';
import { requestShippingUpdateShippingRequest } from 'store/redux_slice/shipmentSlice';
import { requestUserPriceListById } from '../../store/redux_slice/pricelists/userPriceListByIdSlice';
import infoIcon from '../../assests/images/infoIcon.svg';

const ExtraServicesListContainer: React.FC<{
    shipmentRequest: ShipmentStructure,
    setNewOptions: (values: ExtraServiceStructure[]) => void,
}> = ({ shipmentRequest, setNewOptions }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userPriceList = useAppSelector((state) => state.extraServicesByPriceList.services);
  useEffect(() => {
    dispatch(requestUserPriceListById(shipmentRequest?.user_id));
  }, [dispatch, shipmentRequest?.user_id]);
  const [shipmentExtra] = useState<ExtraServiceStructure[]>(
    shipmentRequest.options.filter((service) => (!service.isAdmin && service.isActive)),
  );
  const availableName = userPriceList?.filter((service) => !service.isAdmin).map((e) => e.title?.toUpperCase()) ?? [];
  const extraServiceOnChange = (e) => {
    const newOptions = shipmentRequest.options.map((option) => (
      option.title.toUpperCase() === e.target.value.toUpperCase()
        ? { ...option, isActive: !option.isActive }
        : option
    ));

    dispatch(setShipmentData({
      ...shipmentRequest,
      options: newOptions,
    }));
    setNewOptions(newOptions);
    dispatch(requestShippingUpdateShippingRequest({
      id: shipmentRequest?.id,
      recalculate: true,
      data: {
        options: newOptions,
      },
    }));
  };
  return (
    <div className={style.extra_services_container}>
      <div className={style.extra_title}>
        {t('forms.shipmentDetails.extraServices')}
        <Tooltip title={t('messages.info_extra_services')}>
          <img src={infoIcon} alt="info"/>
        </Tooltip>
      </div>
      <div className="list_container">
        <Checkbox.Group defaultValue={shipmentExtra?.map((i) => i.title) ?? []}
          key={shipmentRequest && userPriceList ? 1 : 0}>
          <List
            className={shipmentExtra.length === 0 ? style.no_data_style : ''}
            locale={{
              emptyText: <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('forms.shipmentDetails.no_data')}
              />,
            }}
            dataSource={shipmentExtra}
            renderItem={(item) => (
              <List.Item>
                <Checkbox
                  disabled={!availableName?.includes(item.title?.toUpperCase())}
                  value={item.title}
                  onChange={extraServiceOnChange}
                >
                  {item.title}{' '}-{' '}
                  {item.values?.ritiro && (
                    <span className={style.delivery_text}>R,</span>
                  )}{' '}
                  {item.values?.consegna && (
                    <span className={style.delivery_text}>C</span>
                  )}
                </Checkbox>
              </List.Item>
            )}
          />
        </Checkbox.Group>
      </div>
    </div>
  );
};

export default ExtraServicesListContainer;
