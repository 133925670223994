import { useState, useEffect, useMemo } from 'react';
import * as _ from 'lodash';
import { Form, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/CustomButton/CustomButton';
import { ShipmentStructure, Address } from 'store/types';
import { useAppSelector, useAppDispatch } from 'store/store';
import { getExtraServeceGDOAction, clearGDOList } from 'store/redux_slice/pallex/gdoSlice';
import { GDO, AMAZON } from 'pesantiConstants';
import { DeliveryType } from 'enums';
import style from '../estimations.module.css';

const { Option } = Select;

type FunctionParamsType = {
  pickup_address?: Address,
  shipping_address?: Address,
}

export const ChangeGdoOrAmazonModal: React.FC<{
  visible: boolean,
  orderData: Partial<ShipmentStructure>,
  onCancel: () => void,
  onGdoChange: (data: FunctionParamsType) => void,
  privateArea?: boolean,
}> = ({
  visible,
  orderData,
  onCancel,
  onGdoChange,
  privateArea = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [companyType, setCompanyType] = useState<string>('');
  const { gdoList } = useAppSelector((state) => state.gdo);

  const serviceGDO = useMemo(() => (
    orderData?.options?.find((item) => (item.title?.toUpperCase() === GDO.toUpperCase() && item.isActive)
    || (item.title?.toUpperCase() === AMAZON.toUpperCase() && item.isActive))
  ), [orderData?.options]);

  useEffect(() => {
    if (privateArea && orderData.delivery_type) {
      setCompanyType(orderData.delivery_type);
    }
  }, [privateArea, orderData.delivery_type]);

  useEffect(() => () => {
    dispatch(clearGDOList());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      company_type: orderData.delivery_type || '',
      pickupGdo: orderData.pickup_address?.gdo_name || '',
      shippingGdo: orderData.shipping_address?.gdo_name || '',
    });
  }, [form, orderData]);

  const onGdoSearchHandler = _.debounce((e) => {
    if (e) {
      dispatch(getExtraServeceGDOAction({ gdoName: e }));
    }
  }, 500);

  const companyTypeChange = (value) => {
    setCompanyType(value);
  };

  const submit = (values) => {
    const { pickupGdo, shippingGdo } = values;
    const pickupInfo = gdoList?.find((item) => item.description === pickupGdo);
    const shippingInfo = gdoList?.find((item) => item.description === shippingGdo);

    let pickupPayload:Address | undefined;
    let shippingPayload:Address | undefined;

    if (pickupInfo?.address.location && pickupInfo?.address.company_name) {
      pickupPayload = {
        location: pickupInfo?.address.location,
        company_name: pickupInfo?.address.company_name,
        gdo_id: pickupInfo?.id,
        gdo_name: pickupInfo?.description,
      };
    }

    if (shippingInfo?.address.location && shippingInfo?.address.company_name) {
      shippingPayload = {
        location: shippingInfo?.address.location,
        company_name: shippingInfo?.address.company_name,
        gdo_id: shippingInfo?.id,
        gdo_name: shippingInfo?.description,
      };
    }
    onGdoChange({
      pickup_address: pickupPayload || orderData.pickup_address,
      shipping_address: shippingPayload || orderData.shipping_address,
    });

    onCancel();
  };
  return (
    <Modal
      title={t('forms.shipmentDetails.select_company')}
      visible={visible}
      onOk={submit}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        onFinish={submit}
      >
        { privateArea && (
          <Form.Item
            name="company_type"
            rules={[{ required: true, message: t('validation.empty') }]}
          >
            <Select
              placeholder={'Seleziona chi è la GDO'}
              onChange={companyTypeChange}
            >
              <Option value={DeliveryType.RITIRO}>{t('forms.estimations.sender')}</Option>
              <Option value={DeliveryType.CONSEGNA}>{t('forms.estimations.destination')}</Option>
              <Option value={DeliveryType.TRIANG}>{t('forms.estimations.both')}</Option>
            </Select>
          </Form.Item>
        )}
        {((privateArea && (companyType === DeliveryType.RITIRO || companyType === DeliveryType.TRIANG))
        || (!privateArea
          && (serviceGDO?.triangolize === DeliveryType.RITIRO || serviceGDO?.triangolize === DeliveryType.BOTH))) && (
          <>
            <h5 className={style.modal_label}>{t('forms.shipmentDetails.select_company_label_ritiro')}</h5>
            <Form.Item
              name="pickupGdo"
              rules={
                [() => ({
                  validator(rule, value) {
                    if (!value && orderData.delivery_type !== DeliveryType.TRIANG) {
                      return Promise.reject(new Error(t('validation.empty')));
                    }
                    return Promise.resolve();
                  },
                })]
              }
            >
              <Select
                showSearch
                filterOption={false}
                placeholder={t('forms.shipmentDetails.select_gdo')}
                onSearch={(e) => onGdoSearchHandler(e)}
              >
                {gdoList?.map((gdo) => (
                  <Option key={gdo.id} value={gdo.description}>
                    <p className={style.gdo_select_name}>{gdo.description}</p>
                    <p className={style.gdo_select_address}>{gdo.address.location.full_address}</p>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
        {((privateArea && (companyType === DeliveryType.CONSEGNA || companyType === DeliveryType.TRIANG))
        || (!privateArea
        && (serviceGDO?.triangolize === DeliveryType.CONSEGNA || serviceGDO?.triangolize === DeliveryType.BOTH))) && (
          <>
            <h5 className={style.modal_label}>{t('forms.shipmentDetails.select_company_label_consegna')}</h5>
            <Form.Item
              name="shippingGdo"
              rules={
                [() => ({
                  validator(rule, value) {
                    if (!value && orderData.delivery_type !== DeliveryType.TRIANG) {
                      return Promise.reject(new Error(t('validation.empty')));
                    }
                    return Promise.resolve();
                  },
                })]
              }
            >
              <Select
                showSearch
                filterOption={false}
                placeholder={t('forms.shipmentDetails.select_gdo')}
                onSearch={(e) => onGdoSearchHandler(e)}
              >
                {gdoList?.map((gdo) => (
                  <Option key={gdo.id} value={gdo.description}>
                    <p className={style.gdo_select_name}>{gdo.description}</p>
                    <p className={style.gdo_select_address}>{gdo.address.location.full_address}</p>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <CustomButton
          className={style.modal_button}
          htmlType="submit"
          type="primary"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};
