import { useMemo } from 'react';
import { EstimationFieldModal, useModalState } from 'containers/Estimations/hooks';
import { ChangeExtraServicesModalView } from 'containers/Estimations/FieldModals/ChangeExtraServicesModal';
import EditableTable from '../index';
import { ExtraServiceStructure } from 'store/types';
import { EditableDetailsTableTypes, DeliveryType } from 'enums';
import { triangServices } from 'pesantiConstants';

type ExtraServiceTableProps = {
  dataArray: ExtraServiceStructure[],
  updateOptionHandler: (data) => void,
  editable: boolean,
}

const ExtraServiceTable = ({
  dataArray, updateOptionHandler, editable,
}: ExtraServiceTableProps) => {
  const {
    openModal,
    isOpen,
    closeModal,
  } = useModalState();

  const newTableData = useMemo(() => (
    dataArray.filter((i) => i.isActive).map((sourceItem) => ({
      key: sourceItem?.title?.toString() || '',
      quantity: sourceItem.quantity,
      title: sourceItem.title,
      description: sourceItem.description,
      price: sourceItem.price,
      fixedMin: sourceItem.fixedMin?.split('€')[0] || '',
      category: sourceItem.category,
      requested_to: sourceItem.triangolize === DeliveryType.BOTH ? `${DeliveryType.RITIRO}/${DeliveryType.CONSEGNA}` : sourceItem.triangolize || '-',
    }))
  ), [dataArray]);

  const parceSavedData = (row, key: string) => {
    const price = row.priceValue.toString().replace(',', '.') + row.priceUnit;
    const newOptions = dataArray.map((item) => (
      item.title?.toUpperCase() === key.toString().toUpperCase() ? ({
        ...item,
        quantity: Math.trunc(row.quantity),
        description: row.description,
        price,
        fixedMin: (row.priceUnit === '%' && row.fixedMin) ? `${row.fixedMin}€` : '',
        category: row.category,
        triangolize: row.requested_to === `${DeliveryType.RITIRO}/${DeliveryType.CONSEGNA}` ? DeliveryType.BOTH : row.requested_to,
        values: triangServices.includes(item.title.toLocaleUpperCase())
          && item.triangolize !== row.requested_to ? { ritiro: null, consegna: null } : item.values,
      }) : item
    ));
    updateOptionHandler({ [EditableDetailsTableTypes.options]: newOptions });
    return newOptions;
  };

  const deleteHandler = (key:string) => {
    const data = {
      [EditableDetailsTableTypes.options]: dataArray.map((option) => (
        option?.title?.toUpperCase() === key?.toUpperCase() ? ({
          ...option,
          isActive: false,
        }) : option
      )),
    };
    updateOptionHandler(data);
  };

  return (
    <>
      <EditableTable
        tableType={EditableDetailsTableTypes.options}
        dataSource={newTableData}
        parceSavedData={parceSavedData}
        openModal={() => openModal(EstimationFieldModal.openExtraServices)}
        deleteHandler={deleteHandler}
        editable={editable}
      />
      <ChangeExtraServicesModalView
        visible={isOpen(EstimationFieldModal.openExtraServices)}
        onCancel={() => closeModal()}
        orderOptions={dataArray}
        onAddOptionHandler={updateOptionHandler}
      />
    </>
  );
};

export default ExtraServiceTable;
