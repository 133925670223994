import { useEffect, useState, useContext } from 'react';
import { Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import PalletsTable from '../PalletsTable';

import { SplitPalletsContext } from '../index';
import { SplittedPalletsDataType, ScaricoFilterType } from '../../../../store/types';
import { requestPostAssignTripToTruckAction, requestPatchTripAction } from '../../../../store/redux_slice/tripsSlice';
import { setMessage } from '../../../../store/redux_slice/messageErrorSlice';
import { useAppSelector, useAppDispatch } from '../../../../store/store';
import styles from '../DetailsModal.module.css';

const { Option } = Select;

const BodyContent = ({
  palletItemData, tableIndex, completedTrip, scaricoFilter, tripId, inPendingDelivery,
} : {
  palletItemData: SplittedPalletsDataType,
  tableIndex: number,
  completedTrip: boolean
  scaricoFilter: ScaricoFilterType,
  tripId: number | null,
  inPendingDelivery: boolean
}) => {
  const { t } = useTranslation();
  const [selectedTruckId, setSelectedTrucId] = useState<number | null>(palletItemData.truck_id);
  const [palletError, setPalletError] = useState<boolean>(false);
  const { trucksFilter: selectTruckFilter } = useAppSelector((state) => state.unloadingTrips);
  const { splitPallets, setPalletsData, splittedPalletInfo } = useContext(SplitPalletsContext);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      truck_id: palletItemData.truck_id || '',
    });
  }, [form, palletItemData.truck_id]);

  const onFinish = (values) => {
    if (values.truck_id === palletItemData?.truck_id) {
      dispatch(setMessage({ description: t('manifest.sameTruckError') }));
    } else if (splitPallets) {
      if (palletItemData.pallets.length) {
        setPalletsData((prevState) => (
          prevState.map((prevPalletItem, i) => {
            if (tableIndex === i) {
              return {
                ...prevPalletItem,
                truck_id: values.truck_id,
                pallets: [
                  ...prevPalletItem.pallets.map((pallet) => (
                    { ...pallet, [`truck_id_${palletItemData.delivery_type.toLowerCase()}`]: values.truck_id }
                  )),
                ],
              };
            }
            return prevPalletItem;
          })
        ));
        setPalletError(false);
      } else {
        setPalletError(true);
      }
    } else if (tripId && palletItemData.truck_id && !splitPallets) {
      dispatch(requestPatchTripAction({
        trip_id: tripId,
        truck_id: values.truck_id,
        old_truck_id: palletItemData.truck_id,
        pallets: palletItemData?.pallets,
        delivery_type: palletItemData.delivery_type,
        ...scaricoFilter,
      }));
    } else if (!palletItemData.truck_id && !splitPallets) {
      dispatch(requestPostAssignTripToTruckAction({
        truck_id: values.truck_id,
        shipping_id: palletItemData.shipping_id,
        pallets: palletItemData.pallets,
        delivery_type: palletItemData.delivery_type,
        ...scaricoFilter,
      }));
    }
  };
  const onChangeSelectHandler = (e) => {
    setSelectedTrucId(e);
  };

  return (
    <>
      { !completedTrip && (
        <Form
          form={form}
          onFinish={onFinish}
          className={cn('scarico_form', styles.d_flex_end)}
        >
          <Form.Item
            name="truck_id"
            rules={[{
              required: true,
              message: t('manifest.selectTruckValidator'),
            }]}
          >
            <Select
              className="scarico_details_select"
              onChange={onChangeSelectHandler}
              disabled={inPendingDelivery}
            >
              {selectTruckFilter?.truckInfo.map(({
                id, plateNumber, palletQty, palletCapacity,
              }) => (
                <Option
                  className={styles.details_option}
                  key={id}
                  value={id}
                >
                  { plateNumber }
                  <sub>{`(${palletQty}/${palletCapacity})`}</sub>
                </Option>
              ))}
            </Select>
          </Form.Item>
          { !inPendingDelivery && (
            <CustomButton
              htmlType="submit"
              type="primary"
              className={styles.modal_btn}
              disabled={!!splittedPalletInfo.find((item) => item.truck_id === selectedTruckId)}
            >
              {t('manifest.move')}
            </CustomButton>
          )}

        </Form>
      )}
      <PalletsTable
        palletsInfo={palletItemData}
        tableIndex={tableIndex}
      />
      { palletError && <p className={styles.error_message}>{t('manifest.table_dnd_error')}</p>}
    </>
  );
};

export default BodyContent;
