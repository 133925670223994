import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  format, formatISO, lastDayOfMonth, getDate,
} from 'date-fns';
import moment, { Moment } from 'moment';
import {
  Col, Row, DatePicker, Select,
} from 'antd';
import CustomButton from 'components/CustomButton/CustomButton';
import { useAppSelector, useAppDispatch } from 'store/store';
import { FinanceListPayloadType, FinanceLastPushType } from 'store/types';
import { clearFinanceSection } from 'store/redux_slice/financeSlice';
import style from './invoicesHeader.module.css';

type InvoicesInvoicesHeadBlockType = {
  onSendInvoice: () => void,
  lastMonth: FinanceLastPushType | null,
  filterDate: FinanceListPayloadType,
  setFilterDate: (date: FinanceListPayloadType) => void,
}

const { RangePicker } = DatePicker;

type RangeValue = [Moment | null, Moment | null] | null;

const InvoicesHeadBlock = ({
  onSendInvoice, lastMonth, filterDate, setFilterDate,
}: InvoicesInvoicesHeadBlockType) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { financeUsers } = useAppSelector((store) => store.financeSection);
  const [dates, setDates] = useState<RangeValue>(null);
  const usersList = useMemo(() => (
    financeUsers ? [
      { value: '', label: t('invoices.select_all') },
      ...financeUsers,
    ] : []
  ), [financeUsers, t]);

  const tooLateFunc = (dateFrom, current) => {
    const currDay = getDate(new Date(dateFrom.toString()));
    const endMonthDay = getDate(lastDayOfMonth(new Date(dateFrom.toString())));
    return dateFrom && current.diff(dateFrom, 'days') > endMonthDay - currDay;
  };

  const tooEarlyFunc = (dateTo, current) => {
    const currDay = getDate(new Date(dateTo.toString()));
    return dateTo && moment(dateTo).diff(current, 'days') > currDay - 1;
  };

  const disabledDate = (current) => {
    const notBiggerThatToday = current && current.valueOf() > new Date().setDate(new Date().getDate());
    let tooLate;
    let tooEarly;
    let initTooLate;
    let initTooEarly;
    if (dates && dates[0]) {
      tooLate = tooLateFunc(dates[0], current);
    }

    if (dates && dates[1]) {
      tooEarly = tooEarlyFunc(dates[1], current);
    }

    if (filterDate.date_from && filterDate.date_to) {
      initTooLate = tooLateFunc(filterDate.date_from, current);
      initTooEarly = tooEarlyFunc(filterDate.date_to, current);
    }

    return notBiggerThatToday || tooEarly || tooLate || initTooLate || initTooEarly;
  };

  const onDatePickerChange = (date) => {
    if (date) {
      setFilterDate({
        ...filterDate,
        date_from: formatISO(new Date(date[0])),
        date_to: formatISO(new Date(date[1])),
      });
    } else {
      dispatch(clearFinanceSection());
      setFilterDate({
        date_from: null,
        date_to: null,
        user_id: null,
      });
    }
  };

  const dateRenderer = (date) => (
    date ? format(new Date(date), 'dd-MM-yyyy') : '-'
  );

  const onUserChange = (id: number) => {
    setFilterDate({
      ...filterDate,
      user_id: id,
    });
  };

  return (
    <Row className={style.invoices_head_container}>
      <Col span={12}>
        <div className={style.invoices_title}>
          {t('privateMenu.invoices')}
        </div>
        <div className={style.date_picker_container}>
          <RangePicker
            className={style.date_picker}
            value={[filterDate.date_from ? moment(filterDate.date_from) : null,
              filterDate.date_to ? moment(filterDate.date_to) : null,
            ]}
            format="DD/MM/YYYY"
            onOpenChange={() => setDates(null)}
            onCalendarChange={(val) => setDates(val)}
            onChange={onDatePickerChange}
            disabledDate={disabledDate}
          />

          <Select
            showSearch
            className={style.select_user}
            placeholder={t('invoices.select_user')}
            onChange={onUserChange}
            value={filterDate.user_id}
            options={usersList}
            filterOption={(input, option) => (
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            )}
            disabled={!financeUsers}
          />
        </div>
      </Col>
      <Col span={12} className={style.btn_container}>
        <CustomButton
          type="primary"
          onClick={onSendInvoice}
          className={style.invoice_btn}
        >
          {t('invoices.send_invoice')}
        </CustomButton>
        <p className={style.d_flex}>
          <span>{t('invoices.last_submition')}:</span>{' '}
          <span className={style.bold}>{dateRenderer(lastMonth?.created)}</span>
        </p>
        <p className={style.d_flex}>
          <span>{t('invoices.send_period')}:</span>
          <span className={style.d_flex_column}>
            <span className={style.bold}>{dateRenderer(lastMonth?.range.from)}</span>
            <span className={style.bold}>{dateRenderer(lastMonth?.range.to)}</span>
          </span>
        </p>
      </Col>
    </Row>
  );
};

export default InvoicesHeadBlock;
