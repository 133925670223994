import classnames from 'classnames';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { Tooltip } from 'antd';
import infoIcon from 'assests/images/infoIcon.svg';
import Status from 'components/Status';
import { TPStatuses } from 'enums';
import style from './shipmentsTable.module.css';

export interface PrivateAreaColumnsInterface {
  id: string,
  type: string,
  palletNumber: number,
  pickupName: string,
  shippingName: string,
  start_shipment_date: string,
  withdrawal_date: string,
  status: string | null,
  tracking: string,
  options: JSX.Element,
}

export const makeColumnsApprove = (t:TFunction)
    : ColumnsType<PrivateAreaColumnsInterface> => [
  {
    title: t('shipmentList.id'),
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  },
  {
    title: t('shipmentList.typology'),
    dataIndex: 'type',
    key: 'type',
    align: 'center',
  },
  {
    title: t('shipmentList.palletNumber'),
    dataIndex: 'palletNumber',
    key: 'palletNumber',
    align: 'center',
    className: classnames(style.red, style.bold),
  },
  {
    title: t('shipmentList.pickupName'),
    dataIndex: 'pickupName',
    key: 'pickupName',
    align: 'center',
    className: style.bold,
  },
  {
    title: 'GDO',
    dataIndex: 'pickupGDO',
    key: 'pickupGDO',
    align: 'center',
    className: classnames(style.red, style.bold),
    render: (isGdo) => (
      isGdo ? <span className={style.gdo_label}>GDO</span> : '-'
    ),
  },
  {
    title: t('shipmentList.shippingName'),
    dataIndex: 'shippingName',
    key: 'shippingName',
    align: 'center',
    className: style.bold,
  },
  {
    title: 'GDO',
    dataIndex: 'shippingGDO',
    key: 'shippingGDO',
    align: 'center',
    className: classnames(style.red, style.bold),
    render: (isGdo) => (
      isGdo ? <span className={style.gdo_label}>GDO</span> : '-'
    ),
  },
  {
    title: t('privateAreaShipmentDetails.deliveryDate'),
    dataIndex: 'start_shipment_date',
    key: 'start_shipment_date',
    align: 'center',
  },
  {
    title: t('privateAreaShipmentDetails.withdrawalDate'),
    dataIndex: 'withdrawal_date',
    key: 'withdrawal_date',
    align: 'center',
  },
  {
    title: (<>
      {t('shipmentList.status')}
      <Tooltip title={t('messages.info_shipment_column')}>
        <img className={style.info} src={infoIcon} alt="info" />
      </Tooltip>
    </>),
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (status) => (
      status === 'Rifiutato' ? <Status status={TPStatuses.Annullata}/> : <Status status={status} />
    ),
  },
  {
    title: t('shipmentList.tracking'),
    dataIndex: 'tracking',
    key: 'tracking',
    align: 'center',
    className: classnames(style.red, style.bold),
  },
  {
    title: t('forms.shipmentRequest.shipping_client_code'),
    dataIndex: 'codice_cliente_spedizione',
    key: 'codice_cliente_spedizione',
    align: 'center',
    className: classnames(style.red, style.bold),
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    align: 'center',
  },
];
