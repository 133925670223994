import { put, takeLatest } from 'redux-saga/effects';
import { GetPriceListTemplates } from '../../api';
import { setLoading, setPriceListTemplateSliceData, requestGetPriceListTemplates } from '../redux_slice/priceListTemplatesSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';

function* getPriceListTemplatesSaga(action) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetPriceListTemplates();
    yield put(setPriceListTemplateSliceData(data.items));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* priceListTemplateSaga() {
  yield takeLatest(requestGetPriceListTemplates, getPriceListTemplatesSaga);
}
