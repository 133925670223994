import { put, takeLatest } from 'redux-saga/effects';
import {
  GetUsers,
  GetUserById,
  GetAssignUserToPricelist,
  PostSupport,
  SearchUsers, GetPricelists,
} from '../../../api';
import {
  requestUsers,
  setUsersData,
  requestAssignUser,
  setCurrentUser,
  requestCurrentUserAction,
  requestUserSupport,
  setUserSupportSuccess,
  requestAssignUserAndUpdateList,
} from '../../redux_slice/users/usersSlice';
import { AssignUserPayload, UserSupport } from '../../types';
import { requestSearchUsers, setUsersSearchData } from '../../redux_slice/users/usersSearchSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';
import { setPricelistsData } from '../../redux_slice/pricelists/pricelistsSlice';

function* getUsersSaga() {
  try {
    const { data } = yield GetUsers();
    yield put(setUsersData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* getUserByIdSaga(action : {
  payload: number
}) {
  try {
    const { data } = yield GetUserById(action.payload);
    yield put(setCurrentUser(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* getAssignUserToPricelistSaga(action: {
  payload: AssignUserPayload
}) {
  try {
    const { userId, pricelistId } = action.payload;
    yield GetAssignUserToPricelist({ pricelistId, userId });
    const { data } = yield GetPricelists();
    yield put(setPricelistsData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* getAssignUserToPricelistAndUpdateListSaga(action: {
  payload: AssignUserPayload
}) {
  try {
    const { userId, pricelistId } = action.payload;
    yield GetAssignUserToPricelist({ pricelistId, userId });
    yield put(requestSearchUsers(''));
    const { data } = yield GetPricelists();
    yield put(setPricelistsData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* postToSupportFromUser(action: {
  payload: UserSupport
}) {
  yield put(setUserSupportSuccess(false));
  try {
    const { subject, body } = action.payload;
    const { data } = yield PostSupport({ subject, body });
    yield put(setUserSupportSuccess(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* searchUsersSaga(action: {
  payload: string
}) {
  try {
    const { data } = yield SearchUsers({ search: action.payload });
    yield put(setUsersSearchData(data));
  } catch (e) {
    yield put(setUsersSearchData([]));
  }
}

export function* usersSaga() {
  yield takeLatest(requestUsers, getUsersSaga);
  yield takeLatest(requestUserSupport, postToSupportFromUser);
  yield takeLatest(requestSearchUsers, searchUsersSaga);
}

export function* currentUserSaga() {
  yield takeLatest(requestCurrentUserAction, getUserByIdSaga);
}

export function* AssignUserSaga() {
  yield takeLatest(requestAssignUser, getAssignUserToPricelistSaga);
  yield takeLatest(requestAssignUserAndUpdateList, getAssignUserToPricelistAndUpdateListSaga);
}
