import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import {
  NotificationStructure, ShipmentStructure, ShippingEstimate, Pallet,
} from '../types';

export const getShippingTableTypeAction = createAction<string>('getShippingTableTypeAction');
export const getShippingDataFromNotificationAction = createAction<string>('getShippingDataFromNotificationAction');

interface NotificationsState {
  notificationsList: NotificationStructure[] | [],
  shippingType: {
    type: string,
    id: string,
  } | null,
  tableTypeLoading: boolean,
  shimpentDataFromNotification: ShipmentStructure | null,
  notificationShipmentLoading: boolean,
  checkNotificationAfterLogin: boolean,
}

const initialState: NotificationsState = {
  notificationsList: [],
  shippingType: null,
  tableTypeLoading: false,
  shimpentDataFromNotification: null,
  notificationShipmentLoading: false,
  checkNotificationAfterLogin: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<NotificationStructure[]>) => {
      state.notificationsList = action.payload;
    },
    setNewNotifications: (state, action: PayloadAction<NotificationStructure[]>) => {
      state.notificationsList = [...action.payload, ...state.notificationsList];
    },
    readNotification: (state, action: PayloadAction<NotificationStructure>) => {
      const newArr = state.notificationsList.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, type: action.payload.type };
        }
        return item;
      });
      state.notificationsList = newArr;
    },
    clearNotifications: (state) => {
      state.notificationsList = [];
    },
    setShippingType: (state, action) => {
      state.shippingType = action.payload;
    },
    setTableTypeLoading: (state, action) => {
      state.tableTypeLoading = action.payload;
    },
    setNotificationShipmentLoading: (state, action) => {
      state.notificationShipmentLoading = action.payload;
    },
    setShipmentDataFromNotification: (state, action: PayloadAction<ShipmentStructure>) => {
      state.shimpentDataFromNotification = action.payload;
    },
    setShipmentEstimateFromNotification: (state, action: PayloadAction<{
      shipping_estimate: ShippingEstimate,
      pallets: Pallet[],
    }>) => {
      if (state.shimpentDataFromNotification) {
        state.shimpentDataFromNotification = {
          ...state.shimpentDataFromNotification,
          shipping_estimate: action.payload.shipping_estimate,
          pallets: action.payload.pallets,
        };
      }
    },
    setCleanShipmentDataFromNotification: (state) => {
      state.shimpentDataFromNotification = null;
      state.shippingType = null;
    },
    setCheckNotificationsAfterLogin: (state, action: PayloadAction<boolean>) => {
      state.checkNotificationAfterLogin = action.payload;
    },
  },
});

export const {
  setNotifications, setNewNotifications, clearNotifications, readNotification, setShippingType,
  setTableTypeLoading, setShipmentDataFromNotification, setShipmentEstimateFromNotification,
  setCleanShipmentDataFromNotification, setNotificationShipmentLoading, setCheckNotificationsAfterLogin,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
