import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExtraServiceStructure } from '../types';

export const requestGetShippingServices = createAction('requestGetShippingServices');
export const requestGetShippingServicesByPriceList = createAction<number>('requestGetShippingServicesByPriceList');

type State = {
  shippingServices: ExtraServiceStructure[] | null
  loading: boolean,
}

const initialState: State = {
  shippingServices: null,
  loading: false,
};

const shipmentSlice = createSlice({
  name: 'shippingServices',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setServicesData: (state, action: PayloadAction<ExtraServiceStructure[] | null>) => {
      state.shippingServices = action.payload;
    },
  },
});

export const { setServicesData, setLoading } = shipmentSlice.actions;

export default shipmentSlice.reducer;
