import { Button } from 'antd';
import calculationIcon from 'assests/images/calculationIcon.png';
import style from './calculationBtn.module.css';

type CalculationBtnType = {
  onCalculateHandler: () => void;
}

export const CalculationBtn = ({ onCalculateHandler } : CalculationBtnType): JSX.Element => (
  <Button
    className={style.calculate_btn}
    type="primary"
    icon={<img src={calculationIcon} alt="calculationIcon"/>}
    onClick={onCalculateHandler}
  />
);

export default CalculationBtn;
