import {
  useCallback, useEffect, useMemo,
} from 'react';
import { Form, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExtraServiceStructure, PatchEstimation } from 'store/types';
import CustomButton from 'components/CustomButton/CustomButton';
import { toUpperCaseFirst } from 'functions';
import { useAppDispatch } from 'store/store';
import { updateToBeApprovedShipmentAction } from 'store/redux_slice/shipping/shippingBeforeApproveSlice';
import { requestUpdateEstimation } from 'store/redux_slice/estimationsListSlice';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import { DeliveryType } from 'enums';
import { CONTRASSEGNO, ZONE_DISAGIATE, NON_STOP } from 'pesantiConstants';
import style from '../estimations.module.css';

const { Option } = Select;

export const ChangeExtraServicesModalView: React.FC<{
    visible: boolean,
    onCancel: () => void,
    orderOptions: ExtraServiceStructure[],
    onAddOptionHandler: (data) => void,
    contrassegnoId?: boolean,
    deliveryType?: string,
}> = ({
  visible,
  onCancel,
  orderOptions,
  onAddOptionHandler,
  contrassegnoId,
  deliveryType,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const isDangerZone = useMemo(() => (
    orderOptions?.find((s) => s.title.toUpperCase() === ZONE_DISAGIATE.toUpperCase())
  ), [orderOptions]);

  const activeOptions = useMemo(() => {
    let returnOptions:ExtraServiceStructure[] | [] = [];
    returnOptions = orderOptions.filter((item) => !item.isActive);
    if (deliveryType === DeliveryType.RITIRO) {
      return returnOptions.filter((item) => !item.isActive && item.title !== CONTRASSEGNO);
    }
    return returnOptions.filter((item) => !item.isActive);
  }, [orderOptions, deliveryType]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        extra_service: '',
      });
    }
  }, [form, visible]);

  const submit = (values) => {
    if (contrassegnoId && values.extra_service.toUpperCase() === CONTRASSEGNO.toUpperCase()) {
      dispatch(setMessage({
        title: t('messages.attention'),
        description: t('messages.re_creation_contrassegno'),
      }));
    } else if (isDangerZone?.isActive && values.extra_service.toUpperCase() === NON_STOP.toUpperCase()) {
      dispatch(setMessage({
        title: t('messages.attention'),
        description: t('messages.re_creation_non_stop'),
      }));
    } else {
      const selectedExtra = orderOptions?.find((service) => (
        service?.title?.toUpperCase() === values.extra_service?.toUpperCase()
      ));
      if (!selectedExtra) return;
      const newOptions = [
        ...orderOptions.filter((option) => option?.title?.toUpperCase() !== selectedExtra?.title?.toUpperCase()),
        {
          ...selectedExtra,
          isActive: true,
        },
      ];
      onAddOptionHandler({
        options: newOptions,
      });
    }
    onCancel();
  };
  return (
    <Modal title={t('forms.estimations.select_extra_services')}
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        onFinish={submit}
      >
        <Form.Item name="extra_service">
          <Select style={{ width: 300 }}>
            {
              activeOptions
                ?.map((i, index) => (
                  <Option key={index} value={i.title}>
                    {toUpperCaseFirst(i.title)}
                  </Option>
                ))
            }
          </Select>
        </Form.Item>
        <CustomButton
          className={style.modal_button}
          htmlType="submit"
          type="primary"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};

export type ChangeExtraServicesModalProps = {
    visible: boolean,
    onCancel: () => void,
    orderOptions: ExtraServiceStructure[],
    id?: string,
    deliveryType?: string,
}

export const ChangeExtraServicesModal: React.FC<ChangeExtraServicesModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const handleSubmit = useCallback((data: PatchEstimation) => {
    if (props.id) {
      dispatch(requestUpdateEstimation({
        id: props.id, data,
      }));
    }
  }, [dispatch, props]);

  return <ChangeExtraServicesModalView {...props} onAddOptionHandler={handleSubmit}/>;
};

export const ChangeExtraServicesModalToBeApproved: React.FC<ChangeExtraServicesModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback((data: PatchEstimation) => {
    if (props.id) {
      dispatch(updateToBeApprovedShipmentAction({
        id: props.id, data,
      }));
    }
  }, [dispatch, props]);
  return <ChangeExtraServicesModalView {...props} onAddOptionHandler={handleSubmit}/>;
};
