import {
  Form,
  Input,
  Select,
  Typography,
  Row, Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import GooglePlacesComponent from 'components/GooglePlacesComponent';
import CustomButton from 'components/CustomButton/CustomButton';
import { DeliveryType, AddressFields, PaymentMethodEnum } from 'enums';
import style from './shipmentDetails.module.css';

type BillingInfoType = {
  form: FormInstance,
}
const { Option } = Select;
const BillingInfo:React.FC<BillingInfoType> = ({ form }) => {
  const { t } = useTranslation();
  return (
    <div className={style.billing}>
      <Typography.Paragraph className={style.billing_title}>
        {t('forms.shipmentDetails.billingInfo')}
      </Typography.Paragraph>
      <Form.Item
        noStyle={true}
        shouldUpdate={
          (prev, curr) => prev.shipping_typology !== curr.shipping_typology
        }
      >
        {({ getFieldValue }) => (getFieldValue('shipping_typology') === 'Private_person'
          ? <Form.Item
            label={t('forms.users.fiscal_code')}
            name="vat_number"
            rules={
              [
                { required: true, message: t('validation.fiscalCode') },
                { len: 16, message: t('validation.fiscalCode') },
              ]
            }

          >
            <Input
              className={style.vatNumber}
            />
          </Form.Item>
          : <Form.Item
            label={t('forms.shipmentDetails.vatNumber')}
            name="vat_number"
            rules={
              [
                { required: true, message: t('validation.vatNumber') },
                { len: 11, message: t('validation.vatNumber') },
                { pattern: /^\d+$/, message: t('validation.number') },
              ]
            }
          >
            <Input
              className={style.vatNumber}
              prefix={<span className={style.span}>IT</span>}
            />
          </Form.Item>
        )
        }
      </Form.Item>
      <Typography.Paragraph className="sub-title">
        {t('forms.shipmentDetails.notification')}
      </Typography.Paragraph>
      <Row>
        <Col span={15}>
          <GooglePlacesComponent
            addressField={AddressFields.REGISTRATION_ADDRESS}
            deliveryType={DeliveryType.CONSEGNA}
            hideName
            registration
          />
        </Col>
      </Row>
      <Form.Item className={style.payment}
        label={t('forms.shipmentDetails.payment')}
        name="payment_method"
      >
        <Select>
          <Option
            value={PaymentMethodEnum.CARD_PAYMENT}
          >
            {t('forms.shipmentDetails.card')}
          </Option>
          <Option
            value={PaymentMethodEnum.BANK_TRANSFER}
          >
            {t('forms.shipmentDetails.bank_transfer')}
          </Option>
          <Option
            value={PaymentMethodEnum.BANK_CHECK}
          >
            {t('forms.shipmentDetails.bank_check')}
          </Option>
        </Select>
      </Form.Item>
      <CustomButton
        className={classnames('extra-bolt-corsivo', style.send)}
        htmlType="submit"
        type="primary"
      >
        {t('forms.shipmentDetails.send')}
      </CustomButton>
      <div className={style.text_two_step}>
            * {t('forms.shipmentDetails.IVA_text')}
      </div>
    </div>
  );
};

export default BillingInfo;
