import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import lodash from 'lodash';

import { useAppSelector, useAppDispatch } from '../../../store/store';
import {
  postPriceListAction,
  requestPricelists,
  requestPrices,
} from '../../../store/redux_slice/pricelists/pricelistsSlice';
import Loader from '../../../components/Loader';
import CreatedHeader from '../CreatedHeader/CreatedHeader';
import CreatedTable from '../CreatedTable';

import style from '../UpdatePricelist/updatedPricelist.module.css';
import { DataTypeSave } from '../CreatedTable/TableColumns';
import { PriceListParams } from '../../../store/types';
import { priceKeys } from '../CreatedTable/EditableCell';
import { PathEnums } from 'enums';

const CreatedPricelist = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pricelistName, setPricelistName] = useState<string>('');
  const [isSpecial, setIsSpecial] = useState<boolean>(false);
  const [isAssociate, setIsAssociate] = useState<boolean>(false);
  const [assignedUsers, setAssignedUsers] = useState<string[]>([]);
  const [listOfChanged, setListOfChanged] = useState<{[key: string]: string | undefined}>({});
  const newExtraServices = useAppSelector((state) => state.extraServicesByPriceList.services);
  const role = useAppSelector((state) => state.auth.tokenStructure?.role);
  const pricelist = useAppSelector(
    (state) => state.priceListTemplate.priceListTemplate,
  );
  useEffect(() => {
    dispatch(requestPrices());
    dispatch(requestPricelists());
  }, [dispatch]);

  if (!pricelist) {
    return <Loader/>;
  }
  const currentPricelist = pricelist;
  const prices = pricelist.price_list_params as PriceListParams;

  const updateListOfChanged = (row: DataTypeSave) => {
    setListOfChanged((prev) => ({
      ...prev,
      [`${row.region}_${row.province}_${row.dataIndex}`]: Number.isNaN(row.amount) || !Number(row.amount)
        ? undefined : row.operation + row.amount,
    }));
  };

  const updatePrices = (percents: string) => {
    if (pricelist) {
      const d: { [key: string]: string } = {};
      Object.keys(prices).forEach((key) => {
        prices[key].forEach((o) => {
          priceKeys.forEach((dataIndex) => {
            d[`${key}_${o.province}_${dataIndex}`] = percents;
          });
        });
      });
      setListOfChanged(d);
    }
  };

  const savePriceList = async () => {
    const newPrices = lodash.cloneDeep(prices);
    const data = {
      priceList: {
        name: pricelistName || currentPricelist.name,
        isSpecial,
        extra_services: newExtraServices || [],
        price_list_params: newPrices,
      },
      log: {
        role: role?.toLowerCase() || '',
        data: {
          section: 'Listini',
          action_type: `Creato nuovo listino con nome ${pricelistName || currentPricelist.name}`,
        },
      },
      assignedUsers: assignedUsers.length ? assignedUsers : undefined,
    };
    if (Object.values(listOfChanged).some((l) => !!l)) {
      Object.keys(listOfChanged).forEach((key) => {
        const [region, province, priceKey] = key.split('_');
        if (region && province && priceKey && newPrices[region]) {
          const vRegion = newPrices[region].find((r) => r.province === province);
          if (vRegion) {
            const price = Number.parseFloat(vRegion[priceKey].replace(',', '.'));
            vRegion[priceKey] = (price + (price * Number(listOfChanged[key])) / 100).toFixed(1).replace('.', ',');
          }
        }
      });
      data.priceList.price_list_params = newPrices;
    }
    dispatch(postPriceListAction(data));
    navigate(PathEnums.PriseList);
  };

  const columns = [
    {
      title: 'Nome azienda',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Partita iva',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
    },
    {
      title: 'Associato a listino',
      dataIndex: 'associated',
      key: 'associated',
      align: 'center',
    },
  ];

  return (
    <div className={style.pricelist_container}>
      <CreatedHeader
        setPricelistName={setPricelistName}
        pricelistName={pricelistName || currentPricelist.name}
        isSpecial={isSpecial}
        setIsSpecial={setIsSpecial}
        isAssociate={isAssociate}
        setIsAssociate={setIsAssociate}
        currentPricelist={currentPricelist}
        savePriceList={savePriceList}
        updatePrices={updatePrices}
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
      />
      <CreatedTable
        currentPricelist={pricelist}
        listOfChanged={listOfChanged}
        updateListOfChanged={updateListOfChanged}
      />
    </div>
  );
};

export default CreatedPricelist;
