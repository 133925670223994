import { put, takeLatest } from 'redux-saga/effects';
import { GetContractors } from '../../../api/contractors/contractors';
import { setLoading, requestGetContractors, setContractorsSliceData } from '../../redux_slice/contractors/contractorsSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* getContractorsSaga() {
  yield put(setLoading(true));
  try {
    const { data } = yield GetContractors();
    yield put(setContractorsSliceData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* contractorsSaga() {
  yield takeLatest(requestGetContractors, getContractorsSaga);
}
