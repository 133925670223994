import { Select } from 'antd';
import classnames from 'classnames';
import './StatusSelect.css';

const StatusSelect = ({
  onChange, statusName, options, ...props
}): JSX.Element => (
  <Select onChange={onChange}
    {...props}
    defaultValue={statusName}
    options={options}
    className={(classnames('status_select', {
      blue_status: statusName === 'In attesa di data'
                    || statusName === 'In attesa di ritiro',
      black_status: statusName === 'Gestito da TP',
      green_status: statusName === 'Consegnato',
      peach_status: statusName === 'In magazzino'
                    || statusName === 'Ritirato'
                    || statusName === 'In transito'
                    || statusName === 'In consegna'
                    || statusName === 'Ritiro effettuato'
                    || statusName === 'Presso HUB',
      pink_status: statusName === 'Annullata',
    }))}
  />
);

export default StatusSelect;
