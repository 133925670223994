import { useTranslation } from 'react-i18next';
import React from 'react';
import { Modal, Collapse } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import faqTexts from './texts.json';
import style from './faq.module.css';
import './modal.css';

const FaqSection:React.FC<{ visible: boolean,
  onCancel: () => void}> = ({ visible, onCancel }) => {
    const { t } = useTranslation();
    const { Panel } = Collapse;

    return (
      <Modal
        centered
        visible={visible}
        footer={false}
        width={'60%'}
        className="help_modal"
        onCancel={onCancel}
      >
        <>
          <div className="main-title-30px">
            {t('forms.helpForm.faq')}
          </div>
          <Collapse
            bordered={false}
            defaultActiveKey={['0']}
            expandIcon={({ isActive }) => (isActive ? <MinusOutlined className={style.primary}/>
              : <PlusOutlined className={style.primary}/>)}
            className="site-collapse-custom-collapse"
          >
            { faqTexts.map((item, i) => (
              <Panel header={item.title} key={i} className="extra-bolt-corsivo">
                <div className={style.text_container}>
                  <div className={style.vl_line}/>
                  <p className={classnames(style.text_filling, 'semi-bold')}>{item.text}</p>
                </div>
              </Panel>
            ))}
          </Collapse>
        </>
      </Modal>
    );
  };

export default FaqSection;
