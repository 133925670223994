import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/store';

import style from '../createdTable.module.css';
import { makeColumns } from './TableColums';
import { ExtraServicesTablePriceList } from '../../../../components/ExtraServicesTable';
import { setServicesByPriceListData } from '../../../../store/redux_slice/extraByPriceListSlice';

const TableFooter = ({
  type, currentPricelist, className = '',
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const newExtraServices = useAppSelector((state) => state.extraServicesByPriceList.services);

  const { extra_services: extraServices, options } = currentPricelist;
  useEffect(() => {
    dispatch(setServicesByPriceListData(extraServices || options));
  }, [dispatch, extraServices, options]);
  return (
    <ExtraServicesTablePriceList
      orderOptions={newExtraServices || []}
      customColumns={makeColumns(t)}
      id={currentPricelist.id}
      tableClass={style.footerTable}
    />
  );
};

export default TableFooter;
