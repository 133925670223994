import { Select } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestGetPallexHubs } from '../../store/redux_slice/pallexHubsSlice';

const { Option } = Select;

interface Props {
  onChange: (hub: number) => void;
}

export const HubSelect: React.FC<Props> = ({ onChange }) => {
  const dispatch = useAppDispatch();
  const pallexHubs = useAppSelector((state) => state.pallexHubs?.hubs);

  useEffect(() => {
    dispatch(requestGetPallexHubs());
  }, [dispatch]);

  return (<Select onChange={onChange}>
    {pallexHubs.map((h) => (
      <Option key={h.idHub} value={h.idHub}>{h.name}</Option>
    ))}
  </Select>);
};
