import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Table, Typography } from 'antd';
import {
  EditableCellProps,
  makeExtraServicesTableColumns,
  makeExtraServicesTableColumnsWithoutOptions,
} from './TableCell';
import { ExtraServiceStructure, PatchEstimation, ShipmentStructure } from 'store/types';
import OptionsButton, { Option } from '../OptionsButton';
import { priceMapper, ServicePrice, toUpperCaseFirst } from 'functions';
import { useAppDispatch } from 'store/store';
import { requestUpdateEstimation } from 'store/redux_slice/estimationsListSlice';
import { ExtraServicesEditableCell } from './ExtraServicesEditableCell';
import { updateToBeApprovedShipmentAction } from 'store/redux_slice/shipping/shippingBeforeApproveSlice';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import DeleteModal from 'components/DeleteModal';
import style from 'containers/Pricelist/CreatedTable/createdTable.module.css';
import done from 'containers/Pricelist/images/checked.png';
import editIcon from 'containers/Pricelist/images/editFooter.png';
import deleteFooter from 'containers/Pricelist/images/deleteFooter.png';
import { setServicesByPriceListData } from 'store/redux_slice/extraByPriceListSlice';
import {
  CONTRASSEGNO, ADR, ZONE_DISAGIATE, NON_STOP, GDO, AMAZON,
} from 'pesantiConstants';
import { DeliveryType } from 'enums';

const ExtraServicesTableForm: React.FC<{
    orderOptions: ExtraServiceStructure[];
    onUpdateOptions?: (data) => void,
    afterDeleteOptions?: (data) => void,
    optionsColumn?: boolean,
    customColumns?: Partial<EditableCellProps>[],
    visibleAll?: boolean,
    actionsAsIcons?: boolean,
    tableClass?: string,
    role?: 'admin' | 'user',
    contrassegnoId?: boolean,
    orderData?: ShipmentStructure,
}> = ({
  orderOptions,
  onUpdateOptions,
  afterDeleteOptions,
  customColumns,
  optionsColumn = true,
  visibleAll = false,
  actionsAsIcons = false,
  tableClass = '',
  role = 'admin',
  contrassegnoId,
  orderData,
}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [editingKey, setEditingKey] = useState('');
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const isNonStopInDangerZone = useMemo(() => (
    orderOptions?.filter((s) => (s.title.toUpperCase() === ZONE_DISAGIATE.toUpperCase()
      || s.title.toUpperCase() === NON_STOP.toUpperCase()) && s.isActive).length === 2
  ), [orderOptions]);

  const edit = (record: ExtraServiceStructure) => {
    const price: ServicePrice = priceMapper(record?.price || '');
    form.setFieldsValue({
      priceValue: price.value || '-',
      priceUnit: price.unit || '€',
      fixedMin: record?.fixedMin?.split('€')[0] || '',
      service_name: record?.title || '',
      service_details: record?.description || '',
      apply_to: record?.category || 'TOTAL',
      requested_to: record?.triangolize || '-',
    });
    setEditingKey(record.title);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const price = row.priceValue ? row.priceValue.toString().replace(',', '.') + row.priceUnit : '-';
      setEditingKey('');
      const data = {
        options: orderOptions.map((option) => {
          if (option.title === key) {
            return {
              ...option,
              title: row.service_name || option.title,
              description: row.service_details || option.description,
              triangolize: row.requested_to || option.triangolize,
              category: row.apply_to || option.category,
              values: option.triangolize !== row.requested_to ? { ritiro: null, consegna: null } : option.values,
              price,
              fixedMin: (row.priceUnit === '%' && row.fixedMin) ? `${row.fixedMin}€` : '',
            };
          }
          return option;
        }),
      };
      if (onUpdateOptions) {
        onUpdateOptions(data);
      }

      form.resetFields();
    } catch (error) {
      dispatch(setMessage({ description: 'Errore' }));
    }
  };

  const onEditAction = (title: string) => {
    const service = orderOptions.find((s) => s?.title?.toUpperCase() === title?.toUpperCase());
    if (service) {
      edit(service);
    }
  };

  const onDeleteAction = (title: string) => {
    let extraInfo = {};
    if (title.toUpperCase() === CONTRASSEGNO.toUpperCase()) {
      extraInfo = { contrassegno: null };
      setIsDeleteModal(false);
    }
    if (title.toUpperCase() === ADR.toUpperCase()) {
      extraInfo = { merce_adr: null };
    }
    if (title.toUpperCase() === GDO.toUpperCase() || title.toUpperCase() === AMAZON) {
      extraInfo = {
        shipping_address: { ...orderData?.shipping_address, gdo_name: null, gdo_id: null },
        pickup_address: { ...orderData?.shipping_address, gdo_name: null, gdo_id: null },
      };
    }
    const data = {
      options: orderOptions.map((option) => {
        if (option?.title?.toUpperCase() === title?.toUpperCase()) {
          return {
            ...option,
            isActive: false,
            values: { ritiro: null, consegna: null },
          };
        }
        return option;
      }).filter((item) => (
        !((item?.title?.toUpperCase() === title?.toUpperCase()) && !item.isActive && item.unavailable)
      )),
    };
    if (onUpdateOptions && !afterDeleteOptions) {
      onUpdateOptions({
        ...data,
        ...extraInfo,
      });
    }
    if (afterDeleteOptions) {
      afterDeleteOptions({
        options: orderOptions.filter((ex) => ex.title !== title),
      });
    }
  };

  const adminOptions: Array<Option> = [
    {
      title: t('forms.estimations.edit'),
      action: onEditAction,
    },
    {
      title: t('forms.estimations.delete'),
      action: (value: string) => {
        if (value === CONTRASSEGNO && contrassegnoId) {
          setIsDeleteModal(true);
        } else {
          onDeleteAction(value);
        }
      },
    },
  ];

  const userOptions: Array<Option> = [
    {
      title: t('forms.estimations.delete'),
      action: onDeleteAction,
    },
  ];

  const options = role === 'admin' ? adminOptions : userOptions;

  const isEditing = (record) => record?.key === editingKey;

  const columns = (optionsColumn ? makeExtraServicesTableColumns(t, orderData?.delivery_type === DeliveryType.TRIANG, (c, record) => {
    const editable = isEditing(record);

    if (actionsAsIcons) {
      return !editable ? (
        <>
          <img src={editIcon}
            alt="edit"
            className={style.gap_cursor}
            onClick={() => {
              onEditAction(record.key);
            }}
          />
          <img src={deleteFooter}
            alt="delete"
            className={style.gap_cursor}
            onClick={() => {
              onDeleteAction(record.key);
            }}
          />
        </>
      ) : (<img
        className={style.gap_cursor}
        src={done}
        alt="done"
        onClick={() => save(record.key)}/>);
    }

    return !editable ? (<OptionsButton id={record.key} options={options}/>) : (
      <Typography.Link onClick={() => save(record.key)}>{t('buttons.save')}</Typography.Link>);
  }, customColumns || []) : makeExtraServicesTableColumnsWithoutOptions(t, orderData?.delivery_type === DeliveryType.TRIANG));

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType || 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const dataSource = orderOptions.filter((i) => visibleAll || i.isActive).map((i, k) => (
    {
      key: `${i.title}`,
      notAvailable: i.unavailable && t('forms.estimations.notAvailableExtra'),
      isNonStopInDangerZone: i.title?.toUpperCase() === NON_STOP.toUpperCase() && isNonStopInDangerZone && t('forms.estimations.non_stop_in_danger_zone'),
      service_name: toUpperCaseFirst(i.title),
      service_details: i.description,
      price: i.price && !i.price.startsWith('-') ? i.price : '-',
      fixedMin: i.fixedMin || '-',
      apply_to: i.category,
      requested_to: i.triangolize === DeliveryType.BOTH ? `${DeliveryType.RITIRO}/${DeliveryType.CONSEGNA}` : i.triangolize || '-',
      options: <OptionsButton id={`${i.id}`} options={options}/>,
    }
  ));
  return (
    <>
      <DeleteModal
        title={t('messages.attention')}
        description={t('messages.delete_contrassegno')}
        visible={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        onDelete={() => onDeleteAction(CONTRASSEGNO)}/>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: ExtraServicesEditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={dataSource}
          pagination={false}
          className={tableClass}
        />
      </Form>
    </>
  );
};

export type ExtraServicesTableProps = {
    orderOptions: ExtraServiceStructure[];
    id?: string,
    isArchived?: boolean,
    customColumns?: Partial<EditableCellProps>[],
    tableClass?: string,
    orderData?: ShipmentStructure,
}

export const ExtraServicesTable: React.FC<ExtraServicesTableProps> = (props) => {
  const dispatch = useAppDispatch();
  const onRequestUpdateEstimation = useCallback((data: PatchEstimation) => {
    if (props.id) {
      dispatch(requestUpdateEstimation({
        id: props.id, data,
      }));
    }
  }, [dispatch, props]);

  return <ExtraServicesTableForm
    optionsColumn={!props.isArchived}
    {...props}
    onUpdateOptions={onRequestUpdateEstimation}
  />;
};

export const ExtraServicesTableToBeApproved: React.FC<ExtraServicesTableProps> = (props) => {
  const dispatch = useAppDispatch();
  const handleSubmit = useCallback((data: PatchEstimation) => {
    if (props.id) {
      dispatch(updateToBeApprovedShipmentAction({
        id: props.id, data,
      }));
    }
  }, [dispatch, props]);

  return <ExtraServicesTableForm
    {...props}
    onUpdateOptions={handleSubmit}
  />;
};

export const ExtraServicesTablePriceList: React.FC<ExtraServicesTableProps> = (props) => {
  const dispatch = useAppDispatch();

  return <ExtraServicesTableForm
    {...props}
    optionsColumn={true}
    visibleAll={true}
    actionsAsIcons={true}
    onUpdateOptions={(data) => {
      dispatch(setServicesByPriceListData(data.options));
    }}
    afterDeleteOptions={(data) => {
      dispatch(setServicesByPriceListData(data.options));
    }}
  />;
};

export default ExtraServicesTableForm;
