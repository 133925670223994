import { useTranslation } from 'react-i18next';
import {
  Col, Dropdown, Menu, MenuProps, Row, Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import style from './monitoring.module.css';
import CustomButton from '../../components/CustomButton/CustomButton';
import FilterMonitoringContainer from './FilterMonitoringContainer';
import TableMonitoring from './TableMonitoring';
import { FilterMonitoring } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestGetMonitoringList, requestGetMonitoringCSV } from '../../store/redux_slice/monitoringSlice';
import { requestOperatorsList } from '../../store/redux_slice/operators/operatorsListSlice';

const Monitoring = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, _setFilter] = useState<Partial<FilterMonitoring>>({});
  const setFilter = (data) => {
    _setFilter(data);
    setPage(1);
  };
  const monitoringList = useAppSelector((state) => state.monitoring.monitoringList);
  const operatorsList = useAppSelector((state) => state.operatorsList.operatorsList);

  const items: MenuProps['items'] = [
    {
      label: t('forms.monitoring.withPagination'),
      key: '1',
    },
    {
      label: t('forms.monitoring.withoutPagination'),
      key: '2',
    },
  ];
  useEffect(() => {
    dispatch(requestGetMonitoringList({
      page,
      page_size: pageSize,
      filter,
    }));
  }, [dispatch, filter, page, pageSize]);
  useEffect(() => {
    dispatch(requestOperatorsList(false));
  }, [dispatch]);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '1') {
      dispatch(requestGetMonitoringCSV({
        page,
        page_size: pageSize,
        filter,
      }));
    } else {
      dispatch(requestGetMonitoringCSV({
        filter,
      }));
    }
  };
  return (
    <div className={style.monitoring_container}>
      <div className={style.header_container}>
        <Row>
          <Col span={12}>
            <div className={style.monitoring_title}>
              {t('forms.monitoring.title')}
            </div>
          </Col>
          <Col span={4} offset={8}>
            <Dropdown overlay={<Menu items={items} onClick={handleMenuClick}/>}>
              <CustomButton
                type="primary"
                className={style.csv_button}
              >
                <Space>
                  {t('forms.monitoring.csvButton')}
                  <DownOutlined />
                </Space>
              </CustomButton>
            </Dropdown>
          </Col>
        </Row>
        <FilterMonitoringContainer
          onChangeFilter={setFilter}
          operatorsList={operatorsList}
        />
      </div>
      {
        monitoringList && <TableMonitoring
          monitoringList={monitoringList}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      }
    </div>
  );
};

export default Monitoring;
