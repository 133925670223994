import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Row, Col,
} from 'antd';
import SideMenu from './components/SideMenu';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getShippingDataFromNotificationAction } from '../../store/redux_slice/notificationsSlice';
import { requestClearPallets } from '../../store/redux_slice/palletsSlice';
import { setCleanData } from '../../store/redux_slice/shipmentSlice';
import { requestClearEstimation } from '../../store/redux_slice/estimationSlice';

const Deliveries = ({ children }) => {
  const dispatch = useAppDispatch();
  const { shippingType } = useAppSelector((state) => state.notifications);
  const { shippingRequest } = useAppSelector((state) => state.shipmentRequest);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (shippingRequest) {
      dispatch(setCleanData());
      dispatch(requestClearPallets());
      dispatch(requestClearEstimation());
    }
  }, [shippingRequest, dispatch]);

  useEffect(() => {
    if (shippingType) {
      dispatch(getShippingDataFromNotificationAction(shippingType.id));
    }
  }, [dispatch, shippingType, searchParams]);

  return (
    <Row>
      <Col span={3}>
        <SideMenu/>
      </Col>
      <Col span={21}>{ children }</Col>
    </Row>
  );
};

export default Deliveries;
