import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { AddLogPayload, LoginPayload, TokenStructure } from '../types';
import { getToken } from '../localStorage';
import { parseToken } from '../../functions';

export const requestLogin = createAction<LoginPayload>('requestLogin');
export const requestLogOut = createAction<AddLogPayload>('requestLogOut');
export const sentDefaultPass = createAction<string>('sentDefaultPass');
export const requestRecoveryPassword = createAction<string>('requestRecoveryPassword');
export const requestUpdateUserPassword = createAction<{ query: string, password: string }>('requestUpdateUserPassword');

interface AuthState {
  accessToken: string | null,
  tokenStructure: TokenStructure | null,
}

const accessToken = getToken();
const tokenStructure = parseToken(accessToken);

const initialState: AuthState = {
  accessToken,
  tokenStructure,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<AuthState>) => {
      state.accessToken = action.payload.accessToken;
      state.tokenStructure = action.payload.tokenStructure;
    },
    clearSession: (state) => {
      state.accessToken = null;
      state.tokenStructure = null;
    },
  },
});

export const { setSession, clearSession } = authSlice.actions;

export default authSlice.reducer;
