import { useTranslation } from 'react-i18next';
import {
  DatePicker, DatePickerProps, Select, Table,
} from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  ContractorType, PatchEstimation,
  ResContract,
  ShipmentContractorInterface,
  ShipmentSubContractorInterface,
} from 'store/types';
import { DeliveryType } from 'enums';
import { useAppSelector } from 'store/store';
import { renderContractorShow } from 'functions';
import styles from './cotractorTable.module.css';

interface ContractorsType {
    key: string,
    stages: string,
    handledBy: { value: number | null | undefined, name: string | undefined },
    cargoManifest: { value: boolean | undefined, name: string | undefined },
    transportDate: { value: string | null | undefined, name: string | undefined },
}

const ContractorTable: React.FC<{
    contractor: Array<ShipmentContractorInterface>,
    subContractor: Array<ShipmentSubContractorInterface> | [],
    deliveryType: string,
    onRequestUpdateEstimation: (data: PatchEstimation) => void,
    id: string,
    userId: string | number | null,
}> = ({
  contractor, subContractor, deliveryType, onRequestUpdateEstimation, id, userId,
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const contractors = useAppSelector((state) => state.contractors?.contractors);
  const [ritiroContractor, setRitiroContractor] = useState<{ data: ResContract, name: string } | null>();
  const [consegnaContractor, setConsegnaContractor] = useState<{ data: ResContract, name: string } | null>();
  const [newContractors, setNewContractors] = useState<ContractorType[] | null>(null);
  const pallexItalia = contractors.find((el) => el.name === 'Pallex Italia');
  const filterFornitoreContractor = contractors?.filter((el) => el.is_editable);
  const subContractorType = contractor[0].ResContract.type === DeliveryType.RITIRO
    ? DeliveryType.CONSEGNA : DeliveryType.RITIRO;

  useEffect(() => {
    if (contractors) {
      const newContractor = contractors.filter((el) => el.name !== pallexItalia?.name);
      setNewContractors(newContractor);
    }
  }, [contractors, pallexItalia?.name]);
  useEffect(() => {
    if (userId) {
      if (contractor.find((el) => el.ResContract.type === DeliveryType.RITIRO)) {
        setRitiroContractor({ data: contractor[0].ResContract, name: 'contractor' });
        setConsegnaContractor(subContractor[0]?.ResSubContract ? {
          data: subContractor[0]?.ResSubContract,
          name: 'sub_contractor',
        } : null);
      } else {
        setRitiroContractor(subContractor[0]?.ResSubContract ? {
          data: subContractor[0]?.ResSubContract,
          name: 'sub_contractor',
        } : null);
        setConsegnaContractor({ data: contractor[0].ResContract, name: 'contractor' });
      }
    } else if (contractor.find((el) => el.ResContract.type === DeliveryType.RITIRO)) {
      setRitiroContractor(subContractor[0]?.ResSubContract ? {
        data: subContractor[0]?.ResSubContract,
        name: 'sub_contractor',
      } : null);
      setConsegnaContractor({ data: contractor[0].ResContract, name: 'contractor' });
    } else {
      setRitiroContractor({ data: contractor[0].ResContract, name: 'contractor' });
      setConsegnaContractor(subContractor[0]?.ResSubContract ? {
        data: subContractor[0]?.ResSubContract,
        name: 'sub_contractor',
      } : null);
    }
  }, [contractor, subContractor, userId]);

  const handleChangeContractor = (value: number, name: string) => {
    const findFornitoreContractor = filterFornitoreContractor.find((el) => Number(el.id) === Number(value));
    const findContractor = contractors.find((el) => el.id === Number(value));
    let data;
    if (name === 'contractor') {
      data = {
        contractor: [
          {
            ResContract: {
              ...contractor[0].ResContract,
              contractor_id: value,
              show: !findFornitoreContractor,
            },
          },
        ],
        tracking: findContractor?.name === 'Pallex TP' ? 'Disponsible' : undefined,
      };
    } else {
      data = {
        sub_contractor: [
          {
            ResSubContract: {
              request_id: subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract.request_id : id,
              contractor_id: value,
              type: subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract.type : subContractorType,
              del_date: subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract?.del_date : null,
              show: renderContractorShow(subContractor[0]?.ResSubContract.show, deliveryType, findFornitoreContractor),
            },
          },
        ],
        tracking: findContractor?.name === 'Pallex TP' ? 'Disponsible' : undefined,
      };
    }
    onRequestUpdateEstimation({
      id,
      data,
    });
  };
  const handleChangeShow = (value: boolean, name: string) => {
    let data;
    if (name === 'contractor') {
      data = {
        contractor: [
          {
            ResContract: {
              ...contractor[0].ResContract,
              show: value,
            },
          },
        ],
      };
    } else {
      data = {
        sub_contractor: [
          {
            ResSubContract: {
              request_id: subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract.request_id : id,
              contractor_id: subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract.contractor_id : null,
              type: subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract.type : subContractorType,
              del_date: subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract?.del_date : null,
              show: value,
            },
          },
        ],
      };
    }
    onRequestUpdateEstimation({
      id,
      data,
    });
  };
  const handleChangeDate: DatePickerProps['onChange'] = (date, name: string) => {
    const findFornitoreContractor = filterFornitoreContractor.find((el) => (
      Number(el.id) === Number(subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract.request_id : id)
    ));
    let data;
    if (name === 'contractor') {
      data = {
        contractor: [
          {
            ResContract: {
              ...contractor[0].ResContract,
              del_date: moment(date).format('YYYY-MM-DD'),
            },
          },
        ],
      };
    } else {
      data = {
        sub_contractor: [
          {
            ResContract: {
              request_id: subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract.request_id : id,
              contractor_id: subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract.contractor_id : null,
              type: subContractor[0]?.ResSubContract ? subContractor[0]?.ResSubContract.type : subContractorType,
              show: renderContractorShow(subContractor[0]?.ResSubContract.show, deliveryType, findFornitoreContractor),
              del_date: moment(date).format('YYYY-MM-DD'),
            },
          },
        ],
      };
    }
    onRequestUpdateEstimation({
      id,
      data,
    });
  };
  const columns = [
    {
      title: t('contractorsTable.shippingStages'),
      dataIndex: 'stages',
      key: 'stages',
    },
    {
      title: t('contractorsTable.handledBy'),
      dataIndex: 'handledBy',
      key: 'handledBy',
      render: (value, column) => {
        const { name } = column.transportDate;
        return <>
          {
            contractor[0].ResContract.contractor_id !== pallexItalia?.id
              ? <Select
                className={styles.select_width}
                value={value.value === pallexItalia?.id ? pallexItalia?.name : value}
                onChange={(v) => handleChangeContractor(v, name)}
              >
                {
                  newContractors?.map((i, k) => (
                    <Option value={i.id} key={i.id}>{i.name}</Option>
                  ))
                }
              </Select>
              : <Select
                className={styles.select_width}
                value={value.value === pallexItalia?.id ? pallexItalia?.name : value}
                disabled={
                  name ? name !== 'sub_contractor'
                          || deliveryType === DeliveryType.TRIANG
                          || !userId
                    : false}
                onChange={(v) => handleChangeContractor(v, name)}
              >
                {
                  contractors?.map((i, k) => (
                    <Option value={i.id} key={i.id}>{i.name}</Option>
                  ))
                }
              </Select>
          }
        </>;
      }
      ,
    },
    {
      title: t('contractorsTable.cargoManifest'),
      dataIndex: 'cargoManifest',
      key: 'cargoManifest',
      render: (text, column) => {
        const { name } = column.transportDate;
        const { value } = column.handledBy;
        const findFornitoreContractor = filterFornitoreContractor.find((el) => Number(el.id) === Number(value));
        const disabledFornitore = !!findFornitoreContractor;
        return <>
          {
            contractor[0].ResContract.contractor_id !== pallexItalia?.id || !name
              ? <Select
                value={text}
                className={styles.select_width}
                disabled={deliveryType === DeliveryType.TRIANG || disabledFornitore}
                onChange={(v) => handleChangeShow(v, name)}
              >
                <Option value={true}>SI</Option>
                <Option value={false}>NO</Option>
              </Select>
              : <Select
                value={text}
                className={styles.select_width}
                disabled={name !== 'sub_contractor' || deliveryType === DeliveryType.TRIANG || disabledFornitore}
                onChange={(v) => handleChangeShow(v, name)}
              >
                <Option value={true}>SI</Option>
                <Option value={false}>NO</Option>
              </Select>
          }
        </>;
      },
    },
    {
      title: t('contractorsTable.transportDate'),
      dataIndex: 'transportDate',
      key: 'transportDate',
      render: (date, column) => {
        const { name } = column.transportDate;
        return <>
          {
            date.value
              ? <DatePicker
                disabledDate={(current) => (
                  current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                )}
                format="DD/MM/YYYY"
                value={moment(date.value)}
                onChange={(v) => handleChangeDate(v, name)}
              />
              : <DatePicker
                disabledDate={(current) => (
                  current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                )}
                format="DD/MM/YYYY"
                onChange={(v) => handleChangeDate(v, name)}
              />
          }
        </>;
      },
    },
  ];
  const dataSource: ContractorsType[] = [
    {
      key: '1',
      stages: DeliveryType.RITIRO,
      handledBy: { value: ritiroContractor?.data.contractor_id, name: ritiroContractor?.name },
      cargoManifest: { value: ritiroContractor?.data.show, name: ritiroContractor?.name },
      transportDate: { value: ritiroContractor?.data.del_date, name: ritiroContractor?.name },
    },
    {
      key: '2',
      stages: DeliveryType.CONSEGNA,
      handledBy: { value: consegnaContractor?.data.contractor_id, name: consegnaContractor?.name },
      cargoManifest: { value: consegnaContractor?.data.show, name: consegnaContractor?.name },
      transportDate: { value: consegnaContractor?.data.del_date, name: consegnaContractor?.name },
    },
  ];
  return (
    <>
      {
        <Table columns={columns} dataSource={dataSource} pagination={false}/>
      }
    </>
  );
};

export default ContractorTable;
