import { Typography, Space } from 'antd';
import ArrowRightOutlined from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import CustomButton from '../CustomButton/CustomButton';
import styled from './notFound.module.css';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Space className={styled.notFound} direction="vertical">
      <Typography className={styled.code}>404</Typography>
      <Typography className={styled.title}>
        <span className={styled.oops}>{t('oops')}</span>{t('notFound')}
      </Typography>
      <CustomButton className={styled.button} href="/" type="primary" icon={<span/>}>
        {t('returnHome')} <span className={styled.back}>&#10230;</span>
        <ArrowRightOutlined />
      </CustomButton>
    </Space>
  );
};

export default NotFound;
