import styled from './Banner.module.css';
import { BannerProps } from './Banner.props';

export const Banner = ({ title, ...props } : BannerProps): JSX.Element => (
  <div
    className={styled.banner}
    {...props}
  >
    <h1>{title}</h1>
    <hr className={styled.underline}/>
  </div>
);

export default Banner;
