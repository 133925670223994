import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { setEstimationsList } from '../../store/redux_slice/estimationsListSlice';
import {
  EstimationStructure,
  FilterEstimation,
} from '../../store/types';
import EstimationsInfo from './EstimationsInfo';
import { requestGetShippingServices } from '../../store/redux_slice/shippingServicesSlice';
import { requestGetPallexHubs } from '../../store/redux_slice/pallexHubsSlice';
import { requestGetPriceListTemplates } from '../../store/redux_slice/priceListTemplatesSlice';
import { getShippingDataFromNotificationAction } from '../../store/redux_slice/notificationsSlice';
import { requestClearPallets } from '../../store/redux_slice/palletsSlice';
import { setCleanData } from '../../store/redux_slice/shipmentSlice';
import { requestClearEstimation } from '../../store/redux_slice/estimationSlice';
import { useSocket } from '../../hooks/useSocket';
import { requestGetContractors } from '../../store/redux_slice/contractors/contractorsSlice';

const Estimations = () => {
  const dispatch = useAppDispatch();
  const socket = useSocket();
  const [searchParams] = useSearchParams();
  const estimationsList = useAppSelector(
    (state) => state.estimationsList.estimationsList,
  ) as EstimationStructure;
  const { shippingRequest } = useAppSelector((state) => state.shipmentRequest);
  const { shippingType } = useAppSelector((state) => state.notifications);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [filter, _setFilter] = useState<Partial<FilterEstimation>>({});
  const setFilter = (data) => {
    _setFilter(data);
    setPage(1);
  };

  useEffect(() => {
    if (shippingRequest) {
      dispatch(setCleanData());
      dispatch(requestClearPallets());
      dispatch(requestClearEstimation());
    }
  }, [shippingRequest, dispatch]);

  useEffect(() => {
    socket.emit('PRIVENTIVI', {
      ...filter,
      page,
      page_size: pageSize,
      isArchived,
    });
  }, [socket, dispatch, page, pageSize, isArchived, filter]);
  useEffect(() => {
    socket.connect();
    socket.on('PRIVENTIVI', (data) => {
      dispatch(setEstimationsList(data?.message));
    });
    return () => {
      socket.off('PRIVENTIVI');
      dispatch(setEstimationsList(null));
    };
  }, [socket, dispatch]);

  useEffect(() => {
    if (shippingType) {
      dispatch(getShippingDataFromNotificationAction(shippingType.id));
    }
  }, [dispatch, shippingType, searchParams]);

  useEffect(() => {
    dispatch(requestGetShippingServices());
    dispatch(requestGetPallexHubs());
    dispatch(requestGetPriceListTemplates());
    dispatch(requestGetContractors());
  }, [dispatch]);

  return (
    <>
      <EstimationsInfo
        isArchived={isArchived}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        estimationsList={estimationsList}
        setIsArchived={setIsArchived}
        onChangeFilter={setFilter}
      />
    </>
  );
};

export default Estimations;
