import { put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  DeletePicelist, GetAssignUserToPricelist,
  GetPricelists,
  GetPrices,
  PatchPricelist,
  PostPricelist,
} from '../../../api';
import { GetPriceListById } from 'api/price';
import {
  setPricelistsData,
  setPrices,
  setLoading,
  requestPricelists,
  postPriceListAction,
  requestPrices,
  setNewPricelist,
  deletePricelist,
  deletePricelistAction,
  setSuccessPriceListPatch,
  updatePricelistAction,
  patchPriceSlice,
  updatePricesAction,
  setLastCreated,
  getPriceListByIdAction,
  setPriceListById,
} from '../../redux_slice/pricelists/pricelistsSlice';
import { PricelistStructure, PatchPricelistStructure, AddLogPayload } from '../../types';
import { setMessage } from '../../redux_slice/messageErrorSlice';
import { PostMonitoringLog } from '../../../api/monitoring';

function* getPricelistsSaga() {
  try {
    const { data } = yield GetPricelists();
    yield put(setPricelistsData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* getPricesSaga() {
  try {
    const { data } = yield GetPrices();
    yield put(setPrices(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* getPriceListById(action: {
  payload: number
}) {
  try {
    const { data } = yield GetPriceListById(action.payload);
    yield put(setPriceListById(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* postPricelistFunc(action: {
  payload: {priceList: PricelistStructure, log: AddLogPayload, assignedUsers?: string[]}
}) {
  yield put(setLoading(true));
  yield put(setLastCreated(0));
  try {
    const { data } = yield PostPricelist(action.payload.priceList);
    if (action.payload.log.role === 'operator' || action.payload.log.role === 'admin') {
      yield PostMonitoringLog(action.payload.log);
    }
    yield put(setLastCreated(+data.id));
    yield put(setNewPricelist(data));
    if (action.payload.assignedUsers?.length) {
      Promise.all(action.payload.assignedUsers.map((userId) => (
        GetAssignUserToPricelist({ pricelistId: +data.id.toFixed(), userId })
      )));
    }
  } catch (e) {
    yield put(setMessage({ description: i18n.t('errors.creating_price_list_error') }));
  }
  yield put(setLoading(false));
}

function* deletePricelistFunc(action: { payload: number }) {
  yield put(setLoading(true));
  try {
    const { data } = yield DeletePicelist(action.payload);
    yield put(deletePricelist(data));
    yield put(setLastCreated(0));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* patchPricelistFunc(action: {
  payload: PatchPricelistStructure
}) {
  yield put(setLoading(true));
  try {
    yield PatchPricelist(action.payload.id, action.payload.data);
    const { data: priceListData } = yield GetPricelists();
    yield put(setPricelistsData(priceListData));
    yield put(setSuccessPriceListPatch(true));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* patchPriceFunc(action: {
  payload: PatchPricelistStructure
}) {
  try {
    const { data } = (yield PatchPricelist(action.payload.id, action.payload.data));
    yield put(patchPriceSlice(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

export function* pricelistSaga() {
  yield takeLatest(requestPricelists, getPricelistsSaga);
  yield takeLatest(requestPrices, getPricesSaga);
  yield takeLatest(getPriceListByIdAction, getPriceListById);
  yield takeLatest(postPriceListAction, postPricelistFunc);
  yield takeLatest(deletePricelistAction, deletePricelistFunc);
  yield takeLatest(updatePricelistAction, patchPricelistFunc);
  yield takeLatest(updatePricesAction, patchPriceFunc);
}
