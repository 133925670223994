import { Upload } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './drag.module.css';
import file from './images/file.png';
import uploadOuter from './images/uploadOuter.png';
import uploadInner from './images/uploadInner.png';

const { Dragger } = Upload;

interface Props {
  width?: number;
  customRequest?: (options) => void;
  onRemove?: () => void;
  disabled?: boolean;
  xlsx?: boolean
}

const Drag = (props: Props) => {
  const { t } = useTranslation();

  const {
    width = 900, customRequest, onRemove, disabled = false,
  } = props;

  const p = {
    name: 'file',
    multiple: true,
    customRequest,
    accept: '.xlsx',
    disabled,
    onRemove,
    locale: {
      uploading: t('upload.uploading'),
      removeFile: t('upload.removeFile'),
      uploadError: t('upload.uploadError'),
      previewFile: t('upload.previewFile'),
      downloadFile: t('upload.downloadFile'),
    },
  };
  return (
    <div className={classNames(style.dragger, disabled ? style.draggerDisabled : '')}>
      <Dragger {...p}>
        <p style={{ padding: `30px ${width / 2 - 50}px` }}>
          <img src={uploadOuter} alt="upload"/>
          <img
            className={style.uploadArrow}
            style={{ right: width / 2 - 15 }}
            src={uploadInner}
            alt="upload"
          />
        </p>
        <p className={classNames('ant-upload-text', style.paragraph)}>
          <img src={file} alt="file"/>
          <span className={style.bold}>{t('uploadFile.uploadXLSX')}</span>{t('uploadFile.drag')}
        </p>
      </Dragger>
    </div>
  );
};

export default Drag;
