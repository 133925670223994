import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AdminPalletsTable } from 'components/EditableTable/Tables';
import { useAppDispatch, useAppSelector } from 'store/store';
import { requestEstimation } from 'store/redux_slice/estimationSlice';
import { Pallet } from 'store/types';
import style from './shipmentFooterContainer.module.css';

const ShipmentInformation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const estimation = useAppSelector((state) => state.estimation.estimation);
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [weight, setWeight] = useState(0);

  useEffect(() => {
    if (estimation?.pallets.length) {
      setTotalPrice(+estimation.pallets.reduce((s, pallet) => +s + +pallet.price, 0).toFixed(2));
      setQuantity(estimation.pallets.reduce((s, pallet) => +s + +pallet.item_quantity, 0));
      setWeight(estimation.pallets.reduce((s, pallet) => +s + (+pallet.item_quantity * +pallet.weight), 0));
    }
  }, [estimation?.pallets]);

  const updateOptionHandler = (pallet: { admin_pallets: Pallet[] }) => {
    if (estimation) {
      dispatch(requestEstimation({
        ...estimation,
        pallets: pallet.admin_pallets,
      }));
    }
  };

  return (
    <>
      <div className={style.shipping_information_container}>
        <div className={style.cost_container}>
          <p className={style.cost_text}>
            {t('forms.newEstimation.total_cost')}
            <span className={style.price_text}>
              {` ${totalPrice}€`}
            </span>
          </p>
          <div className={style.options_container}>
            <p className={style.options_text}>
              {t('forms.newEstimation.number_pallet')}:
              <span className={style.options_bolt_text}>
                {` ${quantity}`}
              </span>
            </p>
            <p className={style.options_text}>
              {t('forms.newEstimation.weight')}:
              <span className={style.options_bolt_text}>
                {` ${weight}kg`}
              </span>
            </p>
          </div>
        </div>
        { estimation && (
          <AdminPalletsTable
            dataArray={estimation.pallets || []}
            updateOptionHandler={updateOptionHandler}
            palletEstimate={{
              price_list_id: estimation?.price_list_id,
              pickup_address: estimation?.pickup_address,
              shipping_address: estimation?.shipping_address,
            }}
            deliveryType={estimation?.delivery_type || ''}
            options={estimation?.options}
          />
        )}
      </div>
    </>
  );
};

export default ShipmentInformation;
