import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import React from 'react';
import style from '../../../Pricelist/CreatedTable/createdTable.module.css';
import { ExtraServiceStructure } from '../../../../store/types';
import { toUpperCaseFirst } from '../../../../functions';

const ExtraApproved: React.FC<{
    orderOptions: ExtraServiceStructure[];
    pallexOrder: boolean,
}> = ({ orderOptions, pallexOrder }) => {
  const { t } = useTranslation();
  const tableColumns = [
    {
      title: t('pricelist.service'),
      dataIndex: 'name',
      key: 'name',
      className: style.serviceName,
      render: (_, record) => (
        <div className={style.extra_name}>
          <span>{record.name}</span>
          {
            !pallexOrder && <span className={style.not_available}>{record.notAvailable}</span>
          }
        </div>
      ),
    },
    {
      title: t('pricelist.accessoryDescription'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('pricelist.price'),
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: t('pricelist.apply'),
      dataIndex: 'apply',
      key: 'apply',
    },
  ];
  const validExtra = orderOptions.filter((el) => el.isActive);
  const data = validExtra.map((e, k) => ({
    key: e.title,
    notAvailable: e.unavailable && t('forms.estimations.notAvailableExtra'),
    name: toUpperCaseFirst(e.title),
    description: e.description,
    price: e.price,
    apply: e.category,
  }));

  return (
    <Table
      columns={tableColumns}
      dataSource={data}
      pagination={false}
    />
  );
};

export default ExtraApproved;
