import { put, takeLatest } from 'redux-saga/effects';
import { GetShippingTrackingStatus } from '../../api/shipping';
import {
  requestShippingTrackingStatueAction, setTrackingLoading, setTrackingStatus,
} from '../redux_slice/shipmentTrackingStatusSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';

function* requestShippingTrackingStatueSaga(action: {
  payload: string
}) {
  yield put(setTrackingLoading(true));
  try {
    const { data } = yield GetShippingTrackingStatus(action.payload);
    yield put(setTrackingStatus(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setTrackingLoading(false));
}

export function* shipmentTrackingStatusSaga() {
  yield takeLatest(requestShippingTrackingStatueAction, requestShippingTrackingStatueSaga);
}
