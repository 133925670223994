import { Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import style from '../transports.module.css';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { useAppSelector } from '../../../store/store';

const TransportTypesForm:React.FC<{
    visible: boolean,
    onCancel: () => void,
    onFinish: (values) => void
}> = ({ visible, onCancel, onFinish }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const transportType = useAppSelector((state) => state.transportType?.transportType);
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: transportType?.type,
      });
    }
  }, [form, transportType, visible]);
  return (
    <Modal
      centered
      visible={visible}
      onCancel={onCancel}
      footer={false}
      width={'40%'}
    >
      <Form
        form={form}
        className={style.form_modal}
        name="transports_form"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label= {t('forms.transports.transport_type_name')}
          name="name"
        >
          <Input
          />
        </Form.Item>
        <CustomButton
          className={classnames('extra-bolt-corsivo', style.add_transport_button)}
          htmlType="submit"
          type="primary"
        >
          {transportType ? t('forms.transports.update') : t('forms.transports.add_transport_type')}
        </CustomButton>
      </Form>
    </Modal>
  );
};

export default TransportTypesForm;
