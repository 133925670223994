import { useTranslation } from 'react-i18next';
import {
  Button,
  Form, Input, Modal, Row,
} from 'antd';
import classnames from 'classnames';
import './modal.css';
import React, { useEffect } from 'react';
import style from './operatorsList.module.css';
import RadioButton from '../../components/RadioButton';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useAppSelector } from '../../store/store';
import { RoleEnums } from '../../enums';
import Loader from '../../components/Loader';
import { generatePassword } from '../../functions';

const AddOperatorModal: React.FC<{
  openModal: boolean,
  onFinish: (values) => void,
  onCancel: () => void,
}> = ({
  openModal, onCancel, onFinish,
}) => {
  const { t } = useTranslation();
  const operator = useAppSelector((state) => state.operator?.operator);
  const loading = useAppSelector((state) => state.operator?.loading);
  const [form] = Form.useForm();
  const userRole = Form.useWatch('role', form);

  useEffect(() => {
    if (openModal && operator) {
      form.setFieldsValue({
        name: operator?.name,
        email: operator?.email,
        phone: operator?.phone,
        password: undefined,
        login_code: operator?.login_code,
        role: operator?.role,
      });
    }
    return () => {
      form.resetFields();
    };
  }, [form, openModal, operator]);
  const onUpdatePassword = () => {
    form.setFieldsValue({
      password: generatePassword(),
    });
  };
  const buttonGenerateAfter = (
    <Button
      type="link"
      className={style.auto_generation_button}
      onClick={onUpdatePassword}
    >
      {t('operatorsList.auto_generation')}
    </Button>
  );
  return (
    <Modal
      centered
      visible={openModal}
      onCancel={onCancel}
      footer={false}
      width={'40%'}
      className="operators_modal"
    >
      { loading && operator && <Loader/>}
      <>
        <div className={classnames('main-title-30px', style.modal_title) }>
          {operator ? t('operatorsList.update_operator') : t('operatorsList.add_new_operator')}
        </div>
        <Form
          key={`${operator?.id}-${openModal}`}
          form={form}
          className={style.modal_form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={t('operatorsList.name')}
            name="name"
            rules={[
              { required: true, message: t('validation.empty') },
              { whitespace: true, message: t('validation.empty') },
            ]}
          >
            <Input
            />
          </Form.Item>
          <Form.Item
            label={t('operatorsList.email')}
            name="email"
            rules={[
              { required: true, message: t('validation.email') },
              { type: 'email', message: t('validation.email') },
            ]}
          >
            <Input
            />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: t('validation.phone') },
              {
                pattern: /\(?(\d{3})\)?([ .-]?)(\d{3})\2(\d{4})/,
                message: t('validation.phone'),
              }]
            }
            label={t('operatorsList.phone')}
            name="phone"
          >
            <Input
            />
          </Form.Item>
          { userRole !== RoleEnums.DRIVER && (
            <Form.Item
              label={t('operatorsList.password')}
              name="password"
              rules={[
                { required: !operator, message: t('validation.empty') },
                { whitespace: true, message: t('validation.empty') },
                { min: 8, message: t('validation.password') },
                () => ({
                  validator(rules, value) {
                    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
                    const hasUppercase = /[A-Z]/.test(value);
                    if (hasSpecialChar && hasUppercase) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('validation.easy_password')));
                  },
                }),
              ]}
            >
              <Input
                suffix={buttonGenerateAfter}
              />
            </Form.Item>
          )}

          { (operator && userRole === RoleEnums.DRIVER) && (
            <Form.Item
              label={t('operatorsList.login_code')}
              name="login_code"
              rules={[
                { required: true, message: t('validation.empty') },
                { whitespace: true, message: t('validation.empty') },
              ]}
            >
              <Input/>
            </Form.Item>
          )}
          <Form.Item
            name="role"
            label={t('operatorsList.role')}
            rules={[
              { required: true, message: t('validation.empty') },
            ]}
          >
            <Row className={style.role_radio_select}>
              <RadioButton
                title={t('roles.administrator')}
                checked={operator?.role === RoleEnums.ADMIN}
                value={RoleEnums.ADMIN}
                id="1"
                name="radio_group"
              />
              <RadioButton
                title={t('roles.operator')}
                checked={operator?.role === RoleEnums.OPERATOR}
                value={RoleEnums.OPERATOR}
                id="2"
                name="radio_group"
              />
              <RadioButton
                title={t('roles.driver')}
                checked={operator?.role === RoleEnums.DRIVER}
                value={RoleEnums.DRIVER}
                id="3"
                name="radio_group"
              />
              <RadioButton
                title={t('roles.billing_operator')}
                checked={operator?.role === RoleEnums.BILLING_OPERATOR}
                value={RoleEnums.BILLING_OPERATOR}
                id="4"
                name="radio_group"
              />
              <RadioButton
                title={t('roles.estimating_operator')}
                checked={operator?.role === RoleEnums.ESTIMATING_OPERATOR}
                value={RoleEnums.ESTIMATING_OPERATOR}
                id="5"
                name="radio_group"
              />
            </Row>
          </Form.Item>
          <CustomButton
            type="primary"
            htmlType="submit"
            className={style.modalBtn}
          >
            {operator ? t('forms.users.update') : t('operatorsList.invite_operator')}
          </CustomButton>
        </Form>
      </>
    </Modal>
  );
};

export default AddOperatorModal;
