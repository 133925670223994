import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Table, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { makeColumnsInvoices } from './TableColumns';
import DeleteModal from 'components/DeleteModal';
import { FinanceSectionType } from 'store/types';
import SuccessIconSvg from 'assests/svg/SuccessIconSvg';
import RedArrowSvg from 'assests/svg/RedArrowSvg';
import { TPStatuses } from 'enums';
import styles from './invoiceTable.module.css';
import invoicesStyles from '../invoices.module.css';

export type inputValuesTypes = {
  value: number,
  shipment: FinanceSectionType,
  name: string,
  type?: string | null,
}

type InvoicesTablePropsType = {
  invoicesData: FinanceSectionType[],
  approve: (id:number) => void,
  disapprove: (id:number) => void,
  deleteFinance: (id: number) => void,
  patchData: (arg: inputValuesTypes) => void,
  openModal: (data: FinanceSectionType) => void,
}

interface EditableRowProps {
  index: number,
  rowData: {
    n_ordine: { shipment: FinanceSectionType, value: number | null },
    codice_cliente: { shipment: FinanceSectionType, value: number | null },
    numero_ddt: { shipment: FinanceSectionType, value: number | null },
    fluet: { shipment: FinanceSectionType, value: number | null },
    extra: {
      shipment: FinanceSectionType,
      value: number | null,
      type: string,
    }
    scontistica: { shipment: FinanceSectionType, value: number | null},
  };
}
const EditableRow = ({
  index, rowData, ...props
}: EditableRowProps) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (rowData) {
      form.setFieldsValue({
        n_ordine: rowData.n_ordine.value || null,
        codice_cliente: rowData.codice_cliente.value || null,
        numero_ddt: rowData.numero_ddt.value || null,
        fluet: rowData.fluet.value || null,
        scontistica: rowData.scontistica.value || null,
        extra: rowData.extra.value || null,
        extraSuffix: rowData.extra.type,
      });
    }
  // eslint-disable-next-line max-len, react-hooks/exhaustive-deps
  }, [form, rowData?.n_ordine?.value, rowData?.codice_cliente?.value, rowData?.numero_ddt?.value, rowData?.fluet?.value, rowData?.scontistica?.value, rowData?.extra?.value, rowData?.extra?.type]);
  return (
    <Form form={form} component={false}>
      <tr {...props} />
    </Form>
  );
};

const InvoicesTable = ({
  invoicesData, approve, disapprove, patchData, openModal, deleteFinance,
}: InvoicesTablePropsType) => {
  const { t } = useTranslation();
  const [isDeleteModal, setIsDeleteModal] = useState<number | null>(null);
  const approveOrDisapproveRender = useCallback((isApproved: boolean, id: number) => {
    if (isApproved) {
      return <button className={invoicesStyles.disapprove_btn} onClick={() => disapprove(id)}></button>;
    }
    return <button className={invoicesStyles.finance_table_btn} onClick={() => approve(id)}><SuccessIconSvg/></button>;
  }, [approve, disapprove]);

  const newTableData = useMemo(() => (
    invoicesData?.map((item) => ({
      key: item.id,
      isApproved: item.isApproved,
      orderId: {
        id: item.request.spedizione_id || item.orderId,
        annullata: item.request.status === TPStatuses.Annullata || item.request.req_status === TPStatuses.Rifiutato,
      },
      pallexId: item.request.tracking_code || '-',
      userName: item.request.user.name || '-',
      approvato_il: item.approvato_il ? format(new Date(item.approvato_il), 'dd-MM-yyy') : '-',
      data_ritiro: item.data_ritiro ? format(new Date(item.data_ritiro), 'dd-MM-yyy') : '-',
      type: item.type,
      pickup_address: `${item.request.pickup_address.name_and_surname_reference || item.request.pickup_address.company_name} - ${item.pickup_address}`,
      shipping_address: `${item.request.shipping_address.name_and_surname_reference || item.request.shipping_address.company_name} - ${item.shipping_address}`,
      n_ordine: { shipment: item, value: item.n_ordine },
      codice_cliente: { shipment: item, value: item.codice_cliente },
      numero_ddt: { shipment: item, value: item.numero_ddt },
      fluet: { shipment: item, value: item.fluet },
      extra: { shipment: item, value: item.extra.value, type: item.extra.type },
      scontistica: { shipment: item, value: item.scontistica },
      price: item.price ? +item.price.toFixed(2) : '-',
      approveOrDisapprove: (
        <div className={invoicesStyles.finance_btn_container}>
          {approveOrDisapproveRender(item.isApproved, item.id)}
        </div>
      ),
      orderDetails: (
        <div className={invoicesStyles.finance_btn_container}>
          <button onClick={() => openModal(item)} className={invoicesStyles.finance_table_btn}>
            <RedArrowSvg/>
          </button>
        </div>
      ),
      deleteFinance: (
        <div className={invoicesStyles.finance_btn_container}>
          <button onClick={() => setIsDeleteModal(item.id)} className={invoicesStyles.delete_btn}>
          </button>
        </div>
      ),
    }))
  ), [invoicesData, approveOrDisapproveRender, openModal]);

  const columns = makeColumnsInvoices(t, patchData);

  return (
    <div className={styles.table_container}>
      <DeleteModal
        title={t('invoices.table.delete_invoice_title')}
        description={t('invoices.table.delete_invoice_description')}
        visible={!!isDeleteModal}
        onCancel={() => setIsDeleteModal(null)}
        onDelete={() => isDeleteModal && deleteFinance(isDeleteModal)}
      />
      <Table
        components={{
          body: {
            row: EditableRow,
          },
        }}
        rowClassName={(record, index) => (record.isApproved ? 'row_approved' : '')}
        columns={columns}
        dataSource={newTableData}
        onRow={(rowData, index) => ({
          index,
          rowData,
        }) as React.HTMLAttributes<unknown>}
        pagination={false}
      />
    </div>
  );
};

export default InvoicesTable;
