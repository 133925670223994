import { useTranslation } from 'react-i18next';
import React, { ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table } from 'antd';
import { makeUsersTableColumns } from './TableColumnts';
import OptionsButton from '../../components/OptionsButton';
import { UserItem } from '../../store/types';
import { requestGetUser } from '../../store/redux_slice/users/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestChangeUserPriceList } from '../../store/redux_slice/users/usersListSlice';

interface PaginationInterface {
    current: number,
    pageSize: number,
    pageSizeOptions: number[],
    showSizeChanger: boolean,
    total: number,
    onChange: React.Dispatch<React.SetStateAction<number>>,
    onShowSizeChange: (current: number, size: number) => void,
}
const UsersTable: React.FC<{
    usersList: UserItem[],
    onOpenProfile: () => void,
    setDeleteId: (id: string) => void,
    setIsDeleteModal: (bool: boolean) => void,
    setIsUserForm: (bool: boolean) => void,
    pagination: PaginationInterface,
    typology: string,
    setUserId: (id: string) => void,
    setIsInviteModal: (bool: boolean) => void,
}> = ({
  usersList, onOpenProfile, setIsDeleteModal, setDeleteId, setIsUserForm, pagination, typology,
  setUserId, setIsInviteModal,
}) => {
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleChange = (event: ChangeEvent<HTMLSelectElement>, userId: number) => {
    dispatch(requestChangeUserPriceList({ id: +event, userId }));
  };
  const priceListTemplates = useAppSelector((state) => state.priceListTemplates?.priceListTemplates);
  const priceListSort = priceListTemplates?.slice().sort((a, b) => a.name.localeCompare(b.name));
  const openUserProfile = (id: string) => {
    setSearchParams({ utente_id: String(id) });
    onOpenProfile();
  };
  const openDeleteModal = (id: string) => {
    setIsDeleteModal(true);
    setDeleteId(id);
  };
  const openUpdateUserForm = (id: string) => {
    setIsUserForm(true);
    dispatch(requestGetUser(id));
  };
  const sentInviteToUsersArea = (id: string) => {
    setUserId(id);
    setIsInviteModal(true);
  };
  const options = [
    {
      title: t('forms.users.invite_private'),
      action: (id: string) => sentInviteToUsersArea(id),
    },
    {
      title: t('forms.users.see_profile'),
      action: (id: string) => openUserProfile(id),
    },
    {
      title: t('forms.users.update'),
      action: (id: string) => openUpdateUserForm(id),
    },
    {
      title: t('forms.users.delete'),
      action: (id: string) => openDeleteModal(id),
    },
  ];
  const dataSource = usersList?.map((i) => (
    {
      key: i.id,
      creation_date: i.createdAt,
      company_name: i.name,
      vat_number: i.vat_number,
      shipment_count: i.approvedRequestsCount,
      price_list: { id: i.price_list?.id, userId: i.id },
      status: i.approvedRequestsCount > 0 ? t('shipmentList.customer') : t('shipmentList.lead'),
      access_privacy: i.role,
      options: <OptionsButton id={i.id.toString()} options={options}/>,
    }
  ));

  const columns = makeUsersTableColumns(t, priceListSort, handleChange, typology);
  return (
    <Table columns={columns} dataSource={dataSource} pagination={pagination}/>
  );
};

export default UsersTable;
