import { put, takeLatest } from 'redux-saga/effects';
import { GetContrassegno } from '../../../api/pallex';
import { setLoading, requestGetContrassegno, setContrassegnoSliceData } from '../../redux_slice/pallex/contrassegnoSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* getContrassegnoSaga() {
  yield put(setLoading(true));
  try {
    const { data } = yield GetContrassegno();
    yield put(setContrassegnoSliceData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* contrassegnoSaga() {
  yield takeLatest(requestGetContrassegno, getContrassegnoSaga);
}
