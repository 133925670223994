import cn from 'classnames';
import styled from './RadioButton.module.css';
import { RadioButtonProps } from './RadioButton';

const RadioButton = ({
  title, value, id, name, onChange, checked, isChecked,
}: RadioButtonProps): JSX.Element => (
  <div className={styled.round}>
    <input
      className={styled.radio_input}
      type="radio"
      id={id}
      defaultChecked={checked}
      value={value}
      onChange={onChange}
      name={name}
    />
    <label
      htmlFor={id}
      className={cn(styled.label, {
        [styled.isChacked]: isChecked,
      })}>
      {title}
    </label>
  </div>
);

export default RadioButton;
