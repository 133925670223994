import React, { useEffect, useState } from 'react';
import {
  Form, FormInstance, Input, Modal, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Loader from '../../../components/Loader';
import style from '../estimations.module.css';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { requestGetContrassegno } from '../../../store/redux_slice/pallex/contrassegnoSlice';

const { Option } = Select;

export interface IContrassegnoValue {
  id: number,
  value: number,
}

export const ChangeContrassegnoModal: React.FC<{
  visible: boolean,
  onCancel: () => void,
  onChange: (v: IContrassegnoValue) => void,
  initialValue: IContrassegnoValue,
  form?: FormInstance,
}> = ({
  visible,
  onChange,
  onCancel,
  initialValue,
  form,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<IContrassegnoValue>(initialValue);
  const { loading, contrassegnoList } = useAppSelector((state) => state.contrassegnoList);

  useEffect(() => {
    if (visible) {
      setValue(initialValue);
    }
  }, [visible, form, initialValue]);

  useEffect(() => {
    form?.setFieldsValue({
      value: value.value,
      id: value.id || '-',
    });
  }, [form, value.id, value.value]);

  useEffect(() => {
    dispatch(requestGetContrassegno());
  }, [dispatch]);

  const onChangeTypeHandler = (v: number) => {
    setValue((prev) => ({
      ...prev,
      id: v,
      value: 0,
    }));
  };
  const onChangeAmountHandler = (e) => {
    setValue((prev) => ({
      ...prev,
      value: e.target.value,
    }));
  };
  const submit = () => {
    onChange(value);
    onCancel();
  };

  const validator = (c, v) => ((v && !Number.isNaN(v) && v > 0 && /^[+-]?\d+(\.\d{1,2})?$/.test(v))
    ? Promise.resolve()
    : Promise.reject()
  );

  return (
    <Modal title={t('forms.estimations.change_contrassegno')}
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form form={form}
        name="contrassegno"
        onFinish={submit}
        initialValues={{
          value: value.value,
          id: value.id || '-',
        }}
      >
        {loading ? <Loader/> : (
          <>
            <Form.Item name="id">
              <Select
                onChange={onChangeTypeHandler}
                className={cn(style.mb1, style.select_input_modal)}
              >
                { contrassegnoList.map((c) => (<Option key={c.id} value={c.id}>{c.description}</Option>)) }
              </Select>
            </Form.Item>
            <Form.Item
              name="value"
              rules={[
                { validator, message: t('forms.estimations.contrassegno_value_validation') },
                () => ({
                  validator(rules, v) {
                    const contrassegnoType = contrassegnoList.find((item) => item.id === value.id)?.description;
                    if (contrassegnoType?.toLocaleUpperCase() === 'CONTANTI' && (!Number.isNaN(v) && v > 1999)) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                  message: t('validation.number_not_bigger_1999'),
                }),
              ]}
            >
              <Input type="number" onChange={onChangeAmountHandler} addonAfter="€" />
            </Form.Item>
          </>
        )}
        <CustomButton
          className={style.modal_button}
          htmlType="submit"
          type="primary"
          disabled={!value.value || !value.id}
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};
