import { useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import DetailsHeadInfo from 'containers/PrivateArea/components/DetailsHeadInfo';
import { PrivateAreaPalletsTable, PrivateAreaOptionsTable, MerceAdrTable } from 'components/EditableTable/Tables';
import Loader from 'components/Loader';
import { NoteContainer, AdditionalTextContainer } from 'components/EditableTextContainer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { setShippingPayloadData } from 'store/redux_slice/privateAreaSlice';
import { PathEnums } from 'enums';
import { ADR } from 'pesantiConstants';
import style from './Details.module.css';

const CreateShipmentDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { shippingPayloadData, creationLoading, redirectFromDetails } = useAppSelector((state) => state.privateArea);

  const hasMerceAdr = useMemo(() => (
    shippingPayloadData?.options?.some((o) => (
      (o.isActive && o.title?.toUpperCase() === ADR.toUpperCase())
    ))
  ), [shippingPayloadData?.options]);

  useEffect(() => {
    if (!shippingPayloadData) {
      navigate(-1);
    }
  }, [navigate, shippingPayloadData]);

  const visibleOptions = shippingPayloadData?.options?.filter((service) => !service.isAdmin);
  const updatePalletsHandler = (pallets) => {
    dispatch(setShippingPayloadData({
      ...shippingPayloadData, pallets: pallets.private_area_pallets,
    }));
  };

  const updateMerceAdrHandler = (merceAdr) => {
    dispatch(setShippingPayloadData({
      ...shippingPayloadData, merce_adr: merceAdr.merce_adr,
    }));
  };

  const updateOptionHandler = (data) => {
    const options = data.options || data.private_area_options;
    const hasMerce = options?.some((o) => (
      (o.isActive && o.title?.toUpperCase() === ADR.toUpperCase())
    ));
    const merceAdr = hasMerce ? shippingPayloadData?.merce_adr : null;
    dispatch(setShippingPayloadData({
      ...shippingPayloadData,
      options: [...options, ...shippingPayloadData?.options?.filter((service) => service.isAdmin) || []],
      merce_adr: merceAdr,
    }));
  };

  const addNoteHandeler = (data: { note_ritiro: string | null, note_consegna: string | null }) => {
    dispatch(setShippingPayloadData({
      ...shippingPayloadData, ...data,
    }));
  };

  const addAdditionalTextHandeler = (data: { informazioni_aggiuntive: string | null }) => {
    dispatch(setShippingPayloadData({
      ...shippingPayloadData, ...data,
    }));
  };

  if (creationLoading) {
    return <Loader/>;
  }

  if (redirectFromDetails) {
    return <Navigate to={PathEnums.ShipmentList}/>;
  }

  if (!shippingPayloadData) {
    return null;
  }

  return (
    <div className={style.details_container}>
      <DetailsHeadInfo shippingInfo={shippingPayloadData}/>
      <PrivateAreaPalletsTable
        dataArray={shippingPayloadData?.pallets || []}
        updateOptionHandler={updatePalletsHandler}
        options={shippingPayloadData?.options}
      />
      { hasMerceAdr && (
        <MerceAdrTable
          dataArray={shippingPayloadData.merce_adr || null}
          updateOptionHandler={updateMerceAdrHandler}
        />
      )}
      <PrivateAreaOptionsTable
        dataArray={visibleOptions || []}
        updateOptionHandler={updateOptionHandler}
      />
      <AdditionalTextContainer
        additionalText={shippingPayloadData.informazioni_aggiuntive || ''}
        onChangeText={addAdditionalTextHandeler}
      />
      <NoteContainer
        noteRitiro={shippingPayloadData.note_ritiro || ''}
        noteConsegna={shippingPayloadData.note_consegna || ''}
        ritiroTime={shippingPayloadData.start_shipment_time || ''}
        onChangeNote={addNoteHandeler}
      />
    </div>
  );
};

export default CreateShipmentDetails;
