import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Facchinaggio } from '../../types';

export const requestGetFacchinaggio = createAction('requestGetFacchinaggio');

type State = {
  facchinaggioList: Facchinaggio[],
  loading: boolean,
}

const initialState: State = {
  facchinaggioList: [],
  loading: false,
};

const facchinaggioSlice = createSlice({
  name: 'facchinaggio',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFacchinaggioSliceData: (state, action: PayloadAction<Facchinaggio[]>) => {
      state.facchinaggioList = action.payload;
    },
  },
});

export const { setFacchinaggioSliceData, setLoading } = facchinaggioSlice.actions;

export default facchinaggioSlice.reducer;
