import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import classnames from 'classnames';

import { Navigate, useParams } from 'react-router-dom';
import { Form } from 'antd';
import moment from 'moment';
import { format } from 'date-fns';
import Banner from 'components/Banner/Banner';
import Loader from 'components/Loader';
import ShipmentDetailsContainer from './ShipmentDetailsContainer';
import ShipmentDetailsTwoStepForm from './ShipmentDetailsTwoStepForm';
import BillingInfo from './BillingInfo';

import { useAppDispatch, useAppSelector } from 'store/store';
import {
  requestShippingGetShippingRequest,
  requestShippingUpdateShippingRequest,
  setCleanData,
} from 'store/redux_slice/shipmentSlice';
import { requestGetShippingServices } from 'store/redux_slice/shippingServicesSlice';
import { requestGetServicesByPriceList } from 'store/redux_slice/extraByPriceListSlice';
import { ExtraServiceStructure } from 'store/types';
import { TPStatuses, PathEnums, PaymentMethodEnum } from 'enums';
import style from './shipmentDetails.module.css';

const ShipmentDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [newOptions, setNewOptions] = useState<ExtraServiceStructure[]>([]);
  const [twoStep, setTwoStep] = useState(false);
  const [thirdStep, setThirdStep] = useState(false);
  const shipmentRequest = useAppSelector((state) => state.shipmentRequest?.shippingRequest);
  const refused = useAppSelector((state) => state.shipmentRequest?.refused);
  const loadingShipmentRequest = useAppSelector((state) => state.shipmentRequest?.loading);
  const paymentLink = useAppSelector((state) => state.paymentLink?.link);
  const loadingUserPriceList = useAppSelector((state) => state.userPriceListById?.loading);
  const { id } = useParams();
  const [form] = Form.useForm();
  useEffect(() => {
    if (id) {
      dispatch(requestShippingGetShippingRequest(id));
    }
  }, [dispatch, id]);
  useEffect(() => {
    dispatch(requestGetShippingServices());
  }, [dispatch]);

  useEffect(() => {
    if (shipmentRequest) {
      setNewOptions(shipmentRequest.options);
    }
  }, [dispatch, shipmentRequest]);
  useEffect(() => {
    if (paymentLink) {
      window.open(paymentLink, '_self');
    }
  }, [paymentLink]);
  useEffect(() => {
    if (shipmentRequest?.price_list_id) {
      dispatch(requestGetServicesByPriceList(shipmentRequest?.price_list_id));
    }
  }, [dispatch, shipmentRequest?.price_list_id]);

  useEffect(() => {
    if (shipmentRequest?.status === TPStatuses.Annullata) {
      dispatch(setCleanData());
    }
  }, [shipmentRequest, dispatch]);

  const onFinish = async (values) => {
    if (shipmentRequest) {
      const data = {
        pickup_address: {
          typology: values?.pickup_typology ?? '',
          company_name: values?.pickup_company_name ?? '',
          name_and_surname_reference: values?.pickup_name_and_surname_reference ?? '',
          location: {
            ...shipmentRequest?.pickup_address?.location,
            full_address: values?.pickup_address ?? '',
            post_code: values.pickup_address_post_code ?? '',
            fiscal_number: values?.pickup_fiscal_number ?? '',
          },
        },
        shipping_address: {
          typology: values?.shipping_typology ?? '',
          company_name: values?.shipping_company_name ?? '',
          name_and_surname_reference: values?.shipping_name_and_surname_reference ?? '',
          location: {
            ...shipmentRequest?.shipping_address?.location,
            full_address: values?.shipping_address ?? '',
            post_code: values.shipping_address_post_code ?? '',
            fiscal_number: values?.shipping_fiscal_number ?? '',
          },
        },
        vat_number: values?.vat_number ?? '',
        registration_address: {
          ...values.registration_address.location,
          post_code: values.registration_address_post_code,
          full_address: [
            values.registration_address.location.street,
            values.registration_address.location.house_number,
            values.registration_address.location.city,
            values.registration_address.location.region,
          ].join(', '),
        },
        payment_method: values?.payment_method,
        status: values?.payment_method === PaymentMethodEnum.CARD_PAYMENT
          ? TPStatuses.In_attesa_di_approvazione : TPStatuses.In_attesa_di_pagamento,
        note_ritiro: values?.note_ritiro ?? null,
        note_consegna: values?.note_consegna ?? null,
        non_stop_date_ritiro: values?.non_stop_date_ritiro ? format(new Date(values.non_stop_date_ritiro), 'yyyy-MM-dd') : null,
        non_stop_date_consegna: values?.non_stop_date_consegna ? format(new Date(values.non_stop_date_consegna), 'yyyy-MM-dd') : null,
        options: newOptions.map((item) => (!item.isActive ? ({
          ...item,
          triangolize: null,
          values: { ritiro: null, consegna: null },
        }) : { ...item })),
      };
      dispatch(requestShippingUpdateShippingRequest(
        {
          id: shipmentRequest?.id,
          data,
          redirectToPayment: values?.payment_method === PaymentMethodEnum.CARD_PAYMENT,
        },
      ));
    }
  };

  if (shipmentRequest?.status === TPStatuses.Annullata) {
    return <Navigate to={PathEnums.Create_estimation}/>;
  }

  return (
    <>
      {
        refused && (
          <Navigate to={PathEnums.Create_estimation} replace={true} />
        )
      }
      { shipmentRequest?.payment_method && (
        <Navigate to={`${PathEnums.Bank_confirm}/${shipmentRequest.id}`} replace={true} />
      )}
      {
        loadingShipmentRequest && loadingUserPriceList ? <Loader/> : null
      } <>
        <Banner
          title={`${t('menu.shippingEstimates')} #${shipmentRequest?.preventivi_id || shipmentRequest?.id}`}
        />
        {shipmentRequest && <Form
          form={form}
          layout="vertical"
          className={classnames('customForm', style.details_container)}
          onFinish={onFinish}
          initialValues={{
            pickup_typology: 'Company',
            shipping_typology: 'Company',
            pickup_address: shipmentRequest?.pickup_address?.location,
            pickup_address_street: `${shipmentRequest.pickup_address.location.street}, ${shipmentRequest.pickup_address.location.house_number}`,
            pickup_address_post_code: shipmentRequest?.pickup_address?.location?.post_code,
            pickup_company_name: shipmentRequest?.pickup_address?.company_name,
            pickup_name_and_surname_reference: shipmentRequest?.pickup_address?.name_and_surname_reference,
            shipping_address: shipmentRequest?.shipping_address?.location?.street,
            shipping_address_street: `${shipmentRequest.shipping_address.location.street}, ${shipmentRequest.shipping_address.location.house_number}`,
            shipping_address_post_code: shipmentRequest?.shipping_address?.location?.post_code,
            shipping_company_name: shipmentRequest?.shipping_address?.company_name,
            shipping_name_and_surname_reference: shipmentRequest?.shipping_address?.name_and_surname_reference,
            note_ritiro: shipmentRequest?.note_ritiro,
            note_consegna: shipmentRequest?.note_consegna,
            non_stop_date_ritiro: shipmentRequest?.non_stop_date_ritiro ? moment(shipmentRequest?.non_stop_date_ritiro) : '',
            non_stop_date_consegna: shipmentRequest?.non_stop_date_consegna ? moment(shipmentRequest?.non_stop_date_consegna) : '',
            payment_method: PaymentMethodEnum.CARD_PAYMENT,
          }}
        >
          {
            shipmentRequest
                  && <ShipmentDetailsContainer
                    shipmentRequest={shipmentRequest}
                    twoStep={twoStep}
                    setTwoStep={setTwoStep}
                    setNewOptions={setNewOptions}
                  />
          }
          {twoStep && shipmentRequest
                  && <ShipmentDetailsTwoStepForm
                    shipmentRequest={shipmentRequest}
                    thirdStep={thirdStep}
                    setThirdStep={setThirdStep}
                    form={form}
                  />}
          { thirdStep && <BillingInfo form={form} /> }
        </Form>}
      </>
    </>
  );
};

export default ShipmentDetails;
