import { instance } from './index';
import { AddLogPayload, MonitoringListPayload } from '../store/types';

const fileDownload = require('js-file-download');

export const GetMonitoringList = (data: MonitoringListPayload) => instance.get(
  '/sys-logging',
  {
    params: {
      page: data.page,
      page_size: data.page_size,
      ...data.filter,
    },
  },
);

export const PostMonitoringLog = (data: AddLogPayload) => instance.post('/sys-logging', data.data);

export const GetMonitoringCSV = (data: MonitoringListPayload) => instance.get('/sys-logging/csv', {
  params: {
    page: data.page,
    page_size: data.page_size,
    ...data.filter,
  },
  responseType: 'blob',
}).then((response) => {
  fileDownload(response.data, 'sys-logging.csv');
});
