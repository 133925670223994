import { put, takeLatest } from 'redux-saga/effects';
import {
  requestResetFilter, requestSetFilter, resetFilter, setFilter,
} from '../../redux_slice/pricelists/filterSlice';
import { FilterPriceLists } from '../../types';

function* setFilterPriceLists(action: {
  payload: Partial<FilterPriceLists>
}) {
  yield put(setFilter(action.payload));
}

function* resetFilterPriceLists() {
  yield put(resetFilter());
}

export function* setFilterPriceListsSaga() {
  yield takeLatest(requestSetFilter, setFilterPriceLists);
}

export function* resetFilterPriceListsSaga() {
  yield takeLatest(requestResetFilter, resetFilterPriceLists);
}
