import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types';

export const requestSearchUsers = createAction<string>('requestSearchUsers');

type State = {
  users: User[],
  loading: boolean,
}

const initialState: State = {
  users: [],
  loading: false,
};

const usersSearchSlice = createSlice({
  name: 'searchUsers',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUsersSearchData: (state, action: PayloadAction<Array<User>>) => {
      state.users = action.payload;
    },
  },
});

export const { setUsersSearchData, setLoading } = usersSearchSlice.actions;

export default usersSearchSlice.reducer;
