import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransportTypeStructure } from '../../types';

export const requestTransportTypes = createAction('requestTransportTypes');

type State = {
    transportTypes: TransportTypeStructure[] | null,
    loading: boolean,
}

const initialState: State = {
  transportTypes: null,
  loading: false,
};

const transportTypesSlice = createSlice({
  name: 'transportType',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTransportTypeData: (state, action: PayloadAction<TransportTypeStructure[]>) => {
      state.transportTypes = action.payload;
    },
  },
});

export const { setTransportTypeData, setLoading } = transportTypesSlice.actions;

export default transportTypesSlice.reducer;
