import { Link } from 'react-router-dom';
import { Button } from 'antd';
import classnames from 'classnames';

import styled from './CustomButton.module.css';
import { CustomButtonProps } from './CustomButton.props';

const CustomButton = ({
  children,
  className,
  onClick,
  href,
  ...props
}: CustomButtonProps): JSX.Element => {
  if (href) {
    return (
      <Link to={href} className={styled.link}>
        <Button
          className={classnames(styled.button, className)}
          {...props}
        >
          { children }
        </Button>
      </Link>
    );
  }

  return (
    <Button
      className={classnames(styled.button, className)}
      onClick={onClick}
      {...props}
    >
      { children }
    </Button>
  );
};

export default CustomButton;
