import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import BackButton from 'components/BackButton';
import NewShipmentForm from './NewShipmentForm';
import { setCleanData } from 'store/redux_slice/shipmentSlice';
import { clearPallets, requestClearPallets } from 'store/redux_slice/palletsSlice';
import { requestClearEstimation } from 'store/redux_slice/estimationSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { PathEnums } from 'enums';
import style from './newShipmentDeliveries.module.css';

const NewShipmentDeliveries = () => {
  const { t } = useTranslation();
  const [isLoaderContainer, setIsLoaderContainer] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const shippingRequest = useAppSelector((state) => state.shipmentRequest?.shippingRequest);
  const [form] = Form.useForm();

  const onBack = () => {
    dispatch(setCleanData());
    dispatch(requestClearPallets());
    dispatch(requestClearEstimation());
    navigate(-1);
  };

  useEffect(() => () => {
    dispatch(clearPallets());
  }, [dispatch]);

  return (
    <>
      {
        shippingRequest && (
          <Navigate to={PathEnums.Deliveries_to_be_approved} replace={true} />
        )
      }
      <div className={classnames(style.new_shipment_container, style.title_container)}>
        <BackButton onClick={() => navigate(-1)} />
        <div className={classnames(style.title, 'main-title-30px')}>
          {t('forms.newShipment.new_shipment')}
        </div>
      </div>
      <NewShipmentForm
        isLoaderContainer={isLoaderContainer}
        setIsLoaderContainer={setIsLoaderContainer}
        onCancel={onBack}
        form={form}
      />
    </>
  );
};

export default NewShipmentDeliveries;
