import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  makeUserMenu, makeOperatorMenu, makeAdminMenu, makeSuperAdminMenu,
} from './LinksConfig';
import { requestLogOut } from '../../../store/redux_slice/authSlice';
import { useAppDispatch } from '../../../store/store';
import { RoleEnums } from '../../../enums';
import styles from './styles.module.css';

type Props = {
  type: RoleEnums
}

const MenuSettings = ({ type } : Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userLogOut = () => {
    navigate('/', { replace: true });
    dispatch(requestLogOut({
      role: type,
      data: {
        section: 'Generic',
        action_type: 'Ha effettuato il logout al sistema',
      },
    }));
  };

  const renderMenu = () => {
    if (type === RoleEnums.USER) {
      const userMenu = makeUserMenu(t, userLogOut);
      return userMenu.map((item) => (
        <li key={item.key} className={styles.menu_item}>{item.label}</li>
      ));
    }
    if (type === RoleEnums.OPERATOR || type === RoleEnums.BILLING_OPERATOR || type === RoleEnums.ESTIMATING_OPERATOR) {
      const operatorMenu = makeOperatorMenu(t, userLogOut);
      return operatorMenu.map((item) => (
        <li key={item.key} className={styles.menu_item}>{item.label}</li>
      ));
    }
    if (type === RoleEnums.ADMIN) {
      const adminMenu = makeAdminMenu(t, userLogOut);
      return adminMenu.map((item) => (
        <li key={item.key} className={styles.menu_item}>{item.label}</li>
      ));
    }
    if (type === RoleEnums.SUPER_ADMIN) {
      const superAdminMenu = makeSuperAdminMenu(t, userLogOut);
      return superAdminMenu.map((item) => (
        <li key={item.key} className={styles.menu_item}>{item.label}</li>
      ));
    }
    return null;
  };

  return (
    <ul className={styles.menu_container}>
      {renderMenu()}
    </ul>
  );
};

export default MenuSettings;
