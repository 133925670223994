import {
  put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import i18n from 'i18next';
import {
  PatchEstimation, ShippingEstimateCreatePayload,
  UpdateShippingEstimateRequest,
  UpdateUserPriceListRequest, UserStructure,
  ShipmentStructure,
} from '../types';
import {
  CreateRequestEstimate,
  GetRequestEstimate,
  UpdateRequestEstimate,
  UpdateShippingRequest,
  GetPalletEstimation,
} from '../../api';
import { UpdateUserPriceList } from '../../api/price';
import {
  requestUpdateEstimation,
  patchListElement,
  setLoading,
  requestGetShippingEstimate,
  requestCreateShippingEstimate,
  requestUpdateShippingEstimate, requestUpdatePriceList, patchUserPriceList, requestCreatePriceShippingEstimate,
} from '../redux_slice/estimationsListSlice';
import { setShipmentDataFromNotification, setShipmentEstimateFromNotification } from '../redux_slice/notificationsSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';
import { GetShippingRequest, PostShippingRequestEstimateCreate } from '../../api/shipping';
import { DeliveryType } from 'enums';

function* updateEstimationSaga(action: {
    payload: PatchEstimation
}) {
  yield put(setLoading(true));
  try {
    yield UpdateShippingRequest(action.payload);
    const { data } = (yield GetShippingRequest(action.payload.id));

    if (action.payload.recalculate) {
      const priceData = yield CreateRequestEstimate(data.id);
      yield put(patchListElement({
        id: data.id,
        data: {
          ...data,
          shipping_estimate: priceData.data,
        },
      }));
      yield put(setShipmentDataFromNotification({
        ...data,
        shipping_estimate: priceData.data,
      }));
    } else {
      yield put(patchListElement({
        id: data.id,
        data,
      }));
      yield put(setShipmentDataFromNotification(data));
    }
  } catch
  (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getShippingEstimateSaga(action: {
    payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetRequestEstimate(action.payload);
    yield put(patchListElement({
      id: action.payload,
      data: {
        shipping_estimate: data,
      },
    }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* createShippingEstimateSaga(action: {
  payload: ShipmentStructure
}) {
  yield put(setLoading(true));
  try {
    const { data: palletData } = yield GetPalletEstimation({
      price_list_id: action.payload?.price_list_id,
      province: action.payload.delivery_type === DeliveryType.RITIRO
        ? action.payload?.pickup_address.location.province
        : action.payload?.shipping_address.location.province,
      region: action.payload.delivery_type === DeliveryType.RITIRO
        ? action.payload?.pickup_address.location.region
        : action.payload?.shipping_address.location.region,
      items: action.payload.pallets,
      options: action.payload.options,
    });
    yield UpdateShippingRequest({
      id: action.payload.id,
      data: { pallets: palletData },
    });

    const { data } = yield CreateRequestEstimate(action.payload.id);
    yield put(patchListElement({
      id: action.payload.id,
      data: {
        shipping_estimate: data,
        pallets: palletData,
      },
    }));
    yield put(setShipmentEstimateFromNotification({
      shipping_estimate: data,
      pallets: palletData,
    }));
  } catch (e) {
    if (e.response.data.message === 'Epal too much!') {
      yield put(setMessage({ description: i18n.t('messages.epal_error'), title: i18n.t('messages.attention') }));
    }
  }
  yield put(setLoading(false));
}

function* updateShippingEstimateSaga(action: {
    payload: UpdateShippingEstimateRequest
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield UpdateRequestEstimate(action.payload);
    yield put(patchListElement({
      id: action.payload.id,
      data: {
        shipping_estimate: data,
      },
    }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* createPriceShipmentEstimateSaga(action: {
    payload: ShippingEstimateCreatePayload
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield PostShippingRequestEstimateCreate(action.payload);
    if (action.payload.shipping_id) {
      yield put(patchListElement({
        id: action.payload.shipping_id,
        data: {
          shipping_estimate: data,
        },
      }));
    }
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* updateUserPriceListSaga(action: {
    payload: UpdateUserPriceListRequest
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield UpdateUserPriceList(action.payload.id, action.payload.userId);
    yield put(patchUserPriceList({
      estimationId: action.payload.estimationId,
      price_list_id: (data as UserStructure).price_list_id,
    }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* estimationsListSaga() {
  yield takeEvery(requestGetShippingEstimate, getShippingEstimateSaga);
  yield takeEvery(requestUpdateEstimation, updateEstimationSaga);
  yield takeLatest(requestCreateShippingEstimate, createShippingEstimateSaga);
  yield takeLatest(requestUpdateShippingEstimate, updateShippingEstimateSaga);
  yield takeLatest(requestUpdatePriceList, updateUserPriceListSaga);
  yield takeLatest(requestCreatePriceShippingEstimate, createPriceShipmentEstimateSaga);
}
