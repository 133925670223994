import { useState } from 'react';

export enum EstimationFieldModal {
    openPalletForm,
    openPickupAddressModal,
    openPickupDateModal,
    openShippingAddressModal,
    openShippingDateModal,
    openNonStopDateRitiroModal,
    openNonStopDateConsegnaModal,
    openPriceModal,
    openHubModal,
    openExtraServices,
    openContractors,
    openContractor,
    openEcoPallet,
    openPrenotazioneTelefonicaModal,
    openConsegnaConGiornoEOraPrestabilitaModal,
    openGdoModal,
    openFacchinaggioModal,
    openContrassegnoModal,
    openFasciModal,
    openNonStopModal,
    openRequestSentSuccessfullyModal,
    openExtraFinanceModal,
    openFinanceOrderDetailsModal,
    openGdoOrAmazonModal,
    openTelefonica,
    openTimeModal,
    openDDTNumberModal,
    openShippintClientCodeModal,
}

export const useModalState = () => {
  const [state, setState] = useState<EstimationFieldModal|null>(null);
  const closeModal = () => setState(null);
  const isOpen = (modal:EstimationFieldModal) => state === modal;
  return {
    openModal: setState,
    closeModal,
    isOpen,
  };
};
