import { useEffect } from 'react';
import {
  Button,
  Form,
  Space,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate } from 'react-router-dom';
import cn from 'classnames';

import BackButton from 'components/BackButton';
import CustomButton from 'components/CustomButton/CustomButton';
import Drag from 'components/Drag';
import { useParceAddress } from 'hooks/useParceAddress';
import { PostParceShipmentXlsx } from 'api';
import { useAppDispatch, useAppSelector } from 'store/store';
import { requestUserData } from 'store/redux_slice/settingsSlice';
import { setShippingPayloadData, clearShippingPayloadData } from 'store/redux_slice/privateAreaSlice';
import { requestGetShippingServicesByPriceList, setServicesData } from 'store/redux_slice/shippingServicesSlice';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import {
  OrderType, PathEnums, PaymentMethodEnum, DeliveryType,
} from 'enums';
import { triangolizeConditionHandler } from 'functions';
import { triangServices } from 'pesantiConstants';
import style from './createShipment.module.css';

const { Title } = Typography;

const CreateShipment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [parceAddressHandler] = useParceAddress();
  const userData = useAppSelector((state) => state.settings.userData);
  const extraServices = useAppSelector((state) => state.extraServices?.shippingServices);
  const { shippingPayloadData, orderType } = useAppSelector((state) => state.privateArea);

  useEffect(() => {
    dispatch(clearShippingPayloadData());
    dispatch(setServicesData(null));
  }, [dispatch]);

  useEffect(() => {
    if (!userData) {
      dispatch(requestUserData());
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (userData?.price_list_id && !extraServices) {
      dispatch(requestGetShippingServicesByPriceList(userData.price_list_id));
      dispatch(setShippingPayloadData({
        price_list_id: userData.price_list_id,
        email: userData.email,
        phone: userData.phone,
      }));
    }
  }, [dispatch, userData, extraServices]);

  const parceShipmentCsv = async (info) => {
    const payment = orderType === OrderType.SHIPPING ? { payment_method: PaymentMethodEnum.BANK_TRANSFER } : {};

    const {
      onSuccess, onError, file,
    } = info;

    const fmData = new FormData();
    fmData.append('file', file);

    try {
      const response = await PostParceShipmentXlsx(fmData);
      const mappedServices = response.data.options.map((item) => (
        triangServices.includes(item.title.toUpperCase()) ? {
          ...item,
          triangolize: triangolizeConditionHandler(!!item?.values?.ritiro, !!item?.values?.consegna),
        } : item
      ));

      const servicesDeliveryType = mappedServices.map((item) => item.triangolize).filter((item) => item);
      const isRitiro = servicesDeliveryType.includes(DeliveryType.RITIRO);
      const isConsegna = servicesDeliveryType.includes(DeliveryType.CONSEGNA);
      const isBoth = servicesDeliveryType.includes(DeliveryType.BOTH);
      const serviceDelivery = triangolizeConditionHandler(isRitiro, isConsegna, isBoth);
      const orderDelivery = (serviceDelivery === DeliveryType.BOTH)
        ? DeliveryType.TRIANG : serviceDelivery as string;

      dispatch(setShippingPayloadData({
        ...shippingPayloadData,
        ...payment,
        ...response.data,
        delivery_type: orderDelivery || DeliveryType.CONSEGNA,
        options: mappedServices.map((item) => ({
          ...item, triangolize: !item.triangolize ? (serviceDelivery || DeliveryType.CONSEGNA) : item.triangolize,
        })),
      }));
      onSuccess(file);
    } catch (e) {
      onError({ event: e });
      if (e.response.data.message) {
        dispatch(setMessage({ description: e.response.data.message }));
      }
    }
  };

  const submitFirstStep = async () => {
    if (shippingPayloadData?.pallets) {
      const fullAddressPickup = [
        shippingPayloadData.pickup_address?.location.street,
        shippingPayloadData.pickup_address?.location.house_number,
        shippingPayloadData.pickup_address?.location.city,
      ].join(', ');
      const fullAddressShipping = [
        shippingPayloadData.shipping_address?.location.street,
        shippingPayloadData.shipping_address?.location.house_number,
        shippingPayloadData.shipping_address?.location.city,
      ].join(', ');
      const { address: pickUpLocation } = await parceAddressHandler(fullAddressPickup);
      const { address: shippingLocation } = await parceAddressHandler(fullAddressShipping);

      dispatch(setShippingPayloadData({
        ...shippingPayloadData,
        pickup_address: {
          ...shippingPayloadData.pickup_address,
          location: pickUpLocation,
        },
        shipping_address: {
          ...shippingPayloadData.shipping_address,
          location: shippingLocation,
        },
      }));
      navigate(PathEnums.Create_shipment_details);
    }
  };

  if (!orderType) return <Navigate to="/"/>;

  return (
    <div className={style.form_container}>
      <BackButton className={style.back} onClick={() => navigate(PathEnums.ShipmentList)}/>
      <div className={style.form_content}>
        <Space className={style.title_container}>
          <Title level={1}>
            { orderType === OrderType.SHIPPING ? t('shipmentList.newShipment') : t('shipmentList.newEstimation')}
          </Title>
          <Space>
            <Button
              className={style.csv_btn}
              type="default"
              href="/files/user_pallets_and_services_example.xlsx"
              target="_blank"
              download
            >
              {t('shipmentList.xlsxPalletTemplate')}
            </Button>
          </Space>
        </Space>
        <Form className={style.form} layout="vertical">
          <p className={style.paragraph}>{t('shipmentList.xlsxInstructions')}</p>
          <Space>
            <Space direction="vertical">
              <div className="title-20px">{t('uploadFile.uploadPalletsList')}</div>
              <Drag
                width={450}
                customRequest={parceShipmentCsv}
              />
            </Space>
          </Space>
        </Form>
        <div className={style.btn_container}>
          <CustomButton
            onClick={submitFirstStep}
            className={cn(style.proceed, 'defaultBtn')}
          >
            {t('forms.newEstimation.proceed')}
          </CustomButton>
          <h3 className={style.or_title}>{t('uploadFile.or')}</h3>
          <CustomButton
            className={style.proceed}
            type="primary"
            href={PathEnums.Create_shipment_manual}
          >
            {t('shipmentList.manual')}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default CreateShipment;
