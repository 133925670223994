import React, { useEffect } from 'react';
import OperatorForm from './OperatorForm';
import PasswordForm from './PasswordForm';
import UserForm from './UserForm';
import CancelAccount from './CancelAccount';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestUserData } from '../../store/redux_slice/settingsSlice';
import { RoleEnums } from '../../enums';
import SuperAdminForm from './SuperAdminForm';
import { requestGetSuperAdminParams } from '../../store/redux_slice/superAdminSlice';

const Profile:React.FC<{role: string}> = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.settings.userData);
  const role = useAppSelector((state) => state.auth.tokenStructure?.role);
  const superAdminParams = useAppSelector((state) => state.superAdmin.superAdminParams);

  useEffect(() => {
    dispatch(requestUserData());
  }, [dispatch]);

  useEffect(() => {
    if (role === RoleEnums.SUPER_ADMIN) {
      dispatch(requestGetSuperAdminParams());
    }
  }, [dispatch, role]);

  return (
    <>
      { (role === RoleEnums.OPERATOR || role === RoleEnums.ADMIN || role === RoleEnums.SUPER_ADMIN) && (
        <OperatorForm userData={userData}/>
      )}
      {
        role === RoleEnums.USER && <UserForm userData={userData} />
      }
      <PasswordForm userData={userData} />
      {
        role === RoleEnums.SUPER_ADMIN && <SuperAdminForm superAdminParams={superAdminParams}/>
      }
      {
        role === RoleEnums.USER && <CancelAccount/>
      }
    </>
  );
};

export default Profile;
