import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Loader from '../../components/Loader';
import ConfirmModal from '../../components/ConfirmModal';
import InvoicesHeadBlock from './HeadBlock';
import InvoicesTable, { inputValuesTypes } from './InvoicesTable';
import OrderDetailsModal from './components/OrderDetailsModal';
import ExtraServiceInfo from './ExtraServiceInfo';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  postFinanceApproveAction, postFinanceDisapproveAction,
  patchFinanceOrderByIdAction, postFinanceDefaultUserAction, postSendInvoiceAction,
  setFinanceDetails, getFinanceWithExtraListAction, deleteFinanceAction,
  getFinanceLastPushAction, clearFinanceSection, getFinanceUsersAction,
  postCalculateFinanceAction,
} from 'store/redux_slice/financeSlice';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import { FinanceSectionType, ConfirmDefaultUserType, FinanceListPayloadType } from 'store/types';
import style from './invoices.module.css';

const InvoicesContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    financeList, lastPushData, financeListLoading, financeDetails,
  } = useAppSelector((state) => state.financeSection);

  const [filterDate, setFilterDate] = useState<FinanceListPayloadType>({
    date_from: null,
    date_to: null,
    user_id: null,
  });
  const [confirmModalContent, setConfirmModalContent] = useState<{
    title: string,
    text: string,
    type: 'send_user' | 'send_invoice'
  } | null>(null);
  const [confirmDefaultUser, serConfirmDefaultUser] = useState<ConfirmDefaultUserType | null>(null);

  const isApprovedExistence = useMemo(() => (
    financeList?.filter((item) => item.isApproved).length
  ), [financeList]);

  useEffect(() => {
    dispatch(getFinanceLastPushAction());
  }, [dispatch]);

  useEffect(() => {
    if (filterDate.date_from && filterDate.date_to && filterDate.user_id !== null) {
      dispatch(getFinanceWithExtraListAction(filterDate));
    }
  }, [dispatch, filterDate]);

  useEffect(() => {
    if (filterDate.date_from && filterDate.date_to) {
      dispatch(getFinanceUsersAction({
        date_from: filterDate.date_from,
        date_to: filterDate.date_to,
      }));
    }
  }, [dispatch, filterDate.date_from, filterDate.date_to]);

  useEffect(() => () => {
    dispatch(clearFinanceSection());
  }, [dispatch]);

  const onSendInvoiceHandler = () => {
    if (isApprovedExistence) {
      setConfirmModalContent({
        type: 'send_invoice',
        title: t('messages.attention'),
        text: t('messages.accept_invoice'),
      });
    } else {
      dispatch(setMessage({ description: t('messages.accept_invoice_error') }));
    }
  };

  const openUserModal = (arg: inputValuesTypes) => {
    setConfirmModalContent({
      type: 'send_user',
      title: t('messages.fuel_message'),
      text: `${t('messages.save_to_user_1')} ${arg.value}% ${t('messages.save_to_user_2')} ${arg.shipment.request?.user?.name || '-'}?`,
    });
    serConfirmDefaultUser({
      fluet: arg.value,
      user_id: arg.shipment.request.user_id,
      date_from: filterDate.date_from,
      date_to: filterDate.date_to,
      filter_user_id: filterDate.user_id,
    });
  };

  const patchCellValueHandler = (arg: inputValuesTypes) => {
    const value = arg?.name === 'extra' ? {
      type: arg.type,
      value: arg.value,
    } : arg.value;
    dispatch(patchFinanceOrderByIdAction({
      id: arg.shipment.id,
      data: { [arg.name]: value || null },
      ...filterDate,
    }));
    if (arg?.name === 'fluet') {
      openUserModal(arg);
    }
  };

  const onConfirmModalHandler = () => {
    if (confirmModalContent?.type === 'send_user' && confirmDefaultUser) {
      dispatch(postFinanceDefaultUserAction(confirmDefaultUser));
    }
    if (confirmModalContent?.type === 'send_invoice' && filterDate?.date_from) {
      dispatch(postSendInvoiceAction({
        month: format(new Date(filterDate?.date_from), 'yyyy-MM-dd'),
        ...filterDate,
      }));
    }
    setConfirmModalContent(null);
  };

  const onUpdateOrderDetailsModal = () => {
    if (financeDetails) {
      dispatch(patchFinanceOrderByIdAction({
        id: financeDetails.id,
        data: financeDetails,
        updateTable: true,
        ...filterDate,
      }));
    }
  };

  const onCanselDetailsModal = () => {
    dispatch(setFinanceDetails(null));
  };

  if (financeListLoading) return <Loader/>;

  return (
    <section className={style.finance_container}>
      { !!confirmModalContent && (
        <ConfirmModal
          visible={!!confirmModalContent}
          content={confirmModalContent}
          onCancel={() => setConfirmModalContent(null)}
          onConfirm={onConfirmModalHandler}
        />
      )}
      <OrderDetailsModal
        visible={!!financeDetails}
        onCancel={onCanselDetailsModal}
        orderDetails={financeDetails}
        onCalculateOrder={(orderDetails: FinanceSectionType) => dispatch(postCalculateFinanceAction(orderDetails))}
        onEditOrderDetails={(data: FinanceSectionType) => dispatch(setFinanceDetails(data))}
        onUpdateOrderDetailsModal={onUpdateOrderDetailsModal}
        openUserSendModal={(arg: inputValuesTypes) => openUserModal(arg)}
      />
      <InvoicesHeadBlock
        onSendInvoice={onSendInvoiceHandler}
        lastMonth={lastPushData}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
      />
      <InvoicesTable
        invoicesData={financeList || []}
        approve={(id: number) => dispatch(postFinanceApproveAction(id))}
        disapprove={(id: number) => dispatch(postFinanceDisapproveAction(id))}
        deleteFinance={(id: number) => dispatch(deleteFinanceAction({ id, ...filterDate })) }
        openModal={(data: FinanceSectionType) => dispatch(setFinanceDetails(data))}
        patchData={patchCellValueHandler}
      />
      <ExtraServiceInfo />
    </section>
  );
};

export default InvoicesContainer;
