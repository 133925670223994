import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/CustomButton/CustomButton';
import style from '../estimations.module.css';

export const RequestSentSuccessfullyModal:React.FC<{
  visible: boolean,
  onCancel: () => void,
}> = ({
  visible,
  onCancel,
}) => {
  const { t } = useTranslation();

  const cancel = () => {
    onCancel();
  };
  return (
    <Modal title={t('estimation.title.request_sent_successfully_modal')}
      visible={visible}
      onOk={cancel}
      onCancel={onCancel}
      footer={false}
    >
      <>{t('estimation.text.request_sent_successfully')}</>
      <CustomButton
        className={style.modal_button}
        type="primary"
        onClick={cancel}
      >
        {t('buttons.ok')}
      </CustomButton>
    </Modal>
  );
};
