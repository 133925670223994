import { put, takeLatest } from 'redux-saga/effects';
import { GetPriceList, GetShippingServices } from '../../api';
import {
  setLoading,
  setServicesData,
  requestGetShippingServices,
  requestGetShippingServicesByPriceList,
} from '../redux_slice/shippingServicesSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';

function* getShippingServicesSaga(action) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetShippingServices();
    yield put(setServicesData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getShippingServicesByPriceListSaga(action) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetPriceList(action.payload);
    yield put(setServicesData(data.extra_services));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* shippingServicesSaga() {
  yield takeLatest(requestGetShippingServices, getShippingServicesSaga);
  yield takeLatest(requestGetShippingServicesByPriceList, getShippingServicesByPriceListSaga);
}
