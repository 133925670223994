import React, { useEffect } from 'react';
import {
  Form, Modal, Select, Col, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import CustomButton from 'components/CustomButton/CustomButton';
import { useAppDispatch, useAppSelector } from 'store/store';
import { requestGetFacchinaggio } from 'store/redux_slice/pallex/facchinaggioSlice';
import { ExtraServiceStructure } from 'store/types';
import { DeliveryType } from 'enums';
import style from '../estimations.module.css';

const { Option } = Select;

export const ChangeFacchinaggioModal: React.FC<{
    visible: boolean,
    service: ExtraServiceStructure,
    onCancel: () => void,
    onUpdateService: (data: ExtraServiceStructure) => void,
}> = ({
  visible,
  onCancel,
  onUpdateService,
  service,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { facchinaggioList, loading } = useAppSelector((state) => state.facchinaggioList);

  useEffect(() => {
    dispatch(requestGetFacchinaggio());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      facchinaggio_ritiro: service?.values?.ritiro || '',
      facchinaggio_consegna: service?.values?.consegna || '',
    });
  }, [form, service?.values]);

  const submit = (values) => {
    onUpdateService({
      ...service,
      values: {
        ritiro: values.facchinaggio_ritiro || null,
        consegna: values.facchinaggio_consegna || null,
      },
    });
    onCancel();
  };

  return (
    <Modal title={t('forms.estimations.change_facchinaggio')}
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      { loading ? <Loader/> : (
        <Form
          form={form}
          onFinish={submit}
        >
          { !service?.triangolize ? (
            <>{t('forms.shipmentRequest.select_delivery_for_service')}</>
          ) : (
            <Row style={{ gap: '15px' }}>
              { (service?.triangolize === DeliveryType.RITIRO
              || service?.triangolize === DeliveryType.BOTH) && (
                <Col span={11}>
                  <h5 className={style.modal_label}>{t('forms.shipmentRequest.facchinaggio_ritiro')}</h5>
                  <Form.Item
                    name="facchinaggio_ritiro"
                    rules={[
                      { required: true, message: t('validation.empty') },
                    ]}
                  >
                    <Select>
                      <Option key={0} value={0}>-</Option>
                      { facchinaggioList.map((c) => (
                        <Option key={c.id} value={c.id}>{c.description}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              { (service?.triangolize === DeliveryType.CONSEGNA
              || service?.triangolize === DeliveryType.BOTH) && (
                <Col span={11}>
                  <h5 className={style.modal_label}>{t('forms.shipmentRequest.facchinaggio_consegna')}</h5>
                  <Form.Item
                    name="facchinaggio_consegna"
                    rules={[
                      { required: true, message: t('validation.empty') },
                    ]}
                  >
                    <Select>
                      <Option key={0} value={0}>-</Option>
                      { facchinaggioList.map((c) => (
                        <Option key={c.id} value={c.id}>{c.description}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          )}
          <CustomButton
            className={style.modal_button}
            htmlType="submit"
            type="primary"
          >
            {t('forms.estimations.update')}
          </CustomButton>
        </Form>
      )}
    </Modal>
  );
};
