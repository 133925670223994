import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './status.module.css';
import { EstimationStatuses } from '../../enums';

interface StatusProps {
  status: EstimationStatuses,
  className?: string | undefined
}

const ReqStatus = ({ status, className } : StatusProps) => {
  const { t } = useTranslation();
  return (
    <div className={classnames(style.status, {
      [style.blueStatus]: status === EstimationStatuses.DA_ELABORARE,
      [style.pinkStatus]: status === EstimationStatuses.RIFIUTATO
        || status === EstimationStatuses.SCADUTO,
      [style.greenStatus]: status === EstimationStatuses.INVIATO
        || status === EstimationStatuses.CONFERMATO,
      [style.peachStatus]: status === EstimationStatuses.INVIATO_FOLLOW_UP,
    }, className)}>{t(`req_statuses.${status}`)}</div>
  );
};

export default ReqStatus;
