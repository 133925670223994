import React, { useState } from 'react';
import {
  DatePicker, DatePickerProps, Form, Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import CustomButton from '../../../components/CustomButton/CustomButton';
import style from '../estimations.module.css';
import { PatchEstimation } from '../../../store/types';

type Field = 'start_shipment_date' | 'withdrawal_date' | 'non_stop_date_ritiro' | 'non_stop_date_consegna'

export const ChangeDateModal: React.FC<{
  id?: string,
  field?: Field,
  visible: boolean,
  onCancel: () => void,
  setDate?: (date: string) => void,
  onRequestUpdateEstimation?: (data: PatchEstimation) => void,
  status?: string,
  title?: string
  initialValue: string | null,
  ritiroDate?: string | null,
  consegnaDate?: string | null,
}> = ({
  id,
  field = '',
  visible,
  onCancel,
  setDate,
  onRequestUpdateEstimation,
  status,
  title = '',
  initialValue,
  ritiroDate,
  consegnaDate,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');
  const onChange: DatePickerProps['onChange'] = (date) => {
    setValue(date ? moment(date).format('YYYY-MM-DD') : '');
  };
  const submit = () => {
    const data = {
      [field]: value !== '' ? value : null,
      status,
    };
    if (id && onRequestUpdateEstimation) {
      onRequestUpdateEstimation({
        id,
        data,
      });
    }
    onCancel();
    if (setDate) {
      setDate(value);
    }
  };

  const canselModal = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal title={title || t('forms.estimations.update_date')}
      visible={visible}
      onOk={submit}
      onCancel={canselModal}
      footer={false}
    >
      <Form
        form={form}
        onFinish={submit}
        initialValues={{
          [field]: initialValue ? moment(initialValue) : '',
        }}
      >
        <Form.Item
          name={field}
          rules={
            [
              () => ({
                // eslint-disable-next-line @typescript-eslint/no-shadow
                validator(_, value) {
                  if (ritiroDate && value && moment(ritiroDate) > value) {
                    return Promise.reject(new Error(t('validation.withdrawal_date')));
                  }
                  if (consegnaDate && value && moment(consegnaDate) < value) {
                    return Promise.reject(new Error(t('validation.start_shipment_date')));
                  }
                  if (!value) {
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              })]}
        >
          <DatePicker
            onChange={onChange}
            disabledDate={(current) => (
              current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
            )}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <CustomButton
          className={style.modal_button}
          type="primary"
          htmlType="submit"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};
