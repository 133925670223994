import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { UpdateUserPayload, UserPayload, UserStructure } from '../../types';

export const requestCreateUser = createAction<{user: UserPayload, typology: string}>('requestCreateUser');
export const requestGetUser = createAction<string>('requestGetUser');
export const requestDeleteUser = createAction<{ id: string, typology: string}>('requestDeleteUser');
export const requestUpdateUser = createAction<{ user: UpdateUserPayload, typology: string }>('requestUpdateUser');
export const requestGetAccessPrivate = createAction<{ email: string, typology: string }>('requestGetAccessPrivate');

type State = {
    user: UserStructure | null
    loading: boolean,
}
const initialState : State = {
  user: null,
  loading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUser: (state, action: PayloadAction<UserStructure>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, setLoading, clearUser } = userSlice.actions;

export default userSlice.reducer;
