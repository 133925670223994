export const PALLEX = 'Pallex';
export const GESTIONE_EPAL = 'GESTIONE EPAL';
export const CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA = 'CONSEGNA CON GIORNO E ORARIO PRESTABILITO';
export const GDO = 'Consegna gdo (esempio: coop, conad, brico)';
export const AMAZON = 'Consegna a piattaforma logistica amazon';
export const FACCHINAGGIO = 'FACCHINAGGIO';
export const CONTRASSEGNO = 'CONTRASSEGNO';
export const ADR = 'MERCE ADR';
export const FASCI = 'MERCE IN FASCI';
export const NON_STOP = 'SERVIZIO NON-STOP/ESPRESSO';
export const ZONE_DISAGIATE = 'ZONE DISAGIATE';
export const TELEFONICA = 'Prenotazione telefonica';
export const SPONDA_IDRAULICA = 'Sponda idraulica';

export const triangServices = [
  CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA.toLocaleUpperCase(),
  GDO.toLocaleUpperCase(),
  AMAZON.toLocaleUpperCase(),
  TELEFONICA.toLocaleUpperCase(),
  SPONDA_IDRAULICA.toLocaleUpperCase(),
  FACCHINAGGIO.toLocaleUpperCase(),
];
