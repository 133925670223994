import React, { useState } from 'react';
import {
  Form, FormInstance, InputNumber, Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/CustomButton/CustomButton';
import style from '../estimations.module.css';

export const ChangeEcoPalletModal: React.FC<{
    visible: boolean,
    onCancel: () => void,
    onChange: (v: number) => void,
    initialValue: number
    form?: FormInstance,
}> = ({
  visible,
  onChange,
  onCancel,
  initialValue,
  form,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number | null>(initialValue);

  const onChangeHandler = (v: number) => {
    setValue(v);
  };
  const submit = () => {
    if (value) {
      onChange(value);
      onCancel();
    }
  };

  const onCancelHandler = () => {
    onCancel();
    form?.setFieldsValue({
      value: '',
    });
    setValue(null);
  };

  return (
    <Modal title={t('forms.estimations.change_ecological_pallet')}
      visible={visible}
      onOk={submit}
      onCancel={onCancelHandler}
      footer={false}
    >
      <Form form={form}
        initialValues={{
          value: initialValue,
        }}
      >
        <Form.Item
          name="value"
        >
          <InputNumber
            min={0}
            onChange={onChangeHandler}
            className={style.input_modal}
          />
        </Form.Item>
      </Form>
      <CustomButton
        className={style.modal_button}
        type="primary"
        onClick={submit}
      >
        {t('forms.estimations.update')}
      </CustomButton>
    </Modal>
  );
};
