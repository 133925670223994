import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

export const requestGetPaymentLink = createAction<string>('requestGetPaymentLink');

type State = {
    link: string | null
    loading: boolean,
}
const initialState : State = {
  link: null,
  loading: false,
};

const estimationSlice = createSlice({
  name: 'paymentLink',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPaymentLink: (state, action: PayloadAction<string>) => {
      state.link = action.payload;
    },
  },
});

export const { setPaymentLink, setLoading } = estimationSlice.actions;

export default estimationSlice.reducer;
