import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PathEnums, RoleEnums } from '../../enums';
import PrivateHeader from './PrivateHeader';

const ProtectedHeader = ({ type }: {
  type: RoleEnums
}) => {
  const { t } = useTranslation();
  const navigationLinksArray = useMemo(() => {
    if (type === RoleEnums.USER) {
      return [
        {
          path: PathEnums.ShipmentList,
          name: t('shipmentList.shipmentsList'),
        },
        {
          path: PathEnums.Support,
          name: t('shipmentList.help'),
        },
      ];
    }
    if (type === RoleEnums.OPERATOR) {
      return [
        {
          path: PathEnums.Deliveries,
          name: t('privateMenu.deliveries'),
        },
        {
          path: PathEnums.Transports,
          name: t('privateMenu.machines'),
        },
        {
          path: PathEnums.Manifests,
          name: t('privateMenu.manifest'),
        },
      ];
    }
    if (type === RoleEnums.BILLING_OPERATOR) {
      return [
        {
          path: PathEnums.Invoices,
          name: t('privateMenu.invoices'),
        },
        {
          path: PathEnums.Deliveries,
          name: t('privateMenu.deliveries'),
        },
        {
          path: PathEnums.Users,
          name: t('privateMenu.personalData'),
        },
        {
          path: PathEnums.Transports,
          name: t('privateMenu.machines'),
        },
        {
          path: PathEnums.Manifests,
          name: t('privateMenu.manifest'),
        },
      ];
    }
    if (type === RoleEnums.ESTIMATING_OPERATOR) {
      return [
        {
          path: PathEnums.Deliveries,
          name: t('privateMenu.deliveries'),
        },
        {
          path: PathEnums.Estimations,
          name: t('privateMenu.estimates'),
        },
        {
          path: PathEnums.Transports,
          name: t('privateMenu.machines'),
        },
        {
          path: PathEnums.Manifests,
          name: t('privateMenu.manifest'),
        },
      ];
    }
    if (type === RoleEnums.SUPER_ADMIN || type === RoleEnums.ADMIN) {
      return [
        {
          path: PathEnums.Dashboard,
          name: t('privateMenu.dashboard'),
        },
        {
          path: PathEnums.Invoices,
          name: t('privateMenu.invoices'),
        },
        {
          path: PathEnums.Deliveries,
          name: t('privateMenu.deliveries'),
        },
        {
          path: PathEnums.Estimations,
          name: t('privateMenu.estimates'),
        },
        {
          path: PathEnums.PriseList,
          name: t('privateMenu.priceList'),
        },
        {
          path: PathEnums.Users,
          name: t('privateMenu.personalData'),
        },
        {
          path: PathEnums.Transports,
          name: t('privateMenu.machines'),
        },
        {
          path: PathEnums.Manifests,
          name: t('privateMenu.manifest'),
        },
      ];
    }
    return [];
  }, [t, type]);

  return (
    <PrivateHeader
      type={type}
      navigationLinks={navigationLinksArray}
    />
  );
};

export default ProtectedHeader;
