import { put, takeLatest } from 'redux-saga/effects';
import { GetExtraServiceGDO } from 'api/pallex';
import { getExtraServeceGDOAction, setLoading, setGDOSliceData } from 'store/redux_slice/pallex/gdoSlice';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import { GdoPayloadType } from 'store/types';

function* getGDOSaga(action: {
  payload: GdoPayloadType
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetExtraServiceGDO(action.payload);
    yield put(setGDOSliceData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* gdoSaga() {
  yield takeLatest(getExtraServeceGDOAction, getGDOSaga);
}
