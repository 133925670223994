import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractorType } from '../../types';

export const requestGetContractors = createAction('requestGetContractors');

type State = {
  contractors: ContractorType[],
  loading: boolean,
}

const initialState: State = {
  contractors: [],
  loading: false,
};

const contractorsSlice = createSlice({
  name: 'contractors',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setContractorsSliceData: (state, action: PayloadAction<ContractorType[]>) => {
      state.contractors = action.payload;
    },
  },
});

export const { setContractorsSliceData, setLoading } = contractorsSlice.actions;

export default contractorsSlice.reducer;
