import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { SuperAdminParams } from '../types';

export const requestGetSuperAdminParams = createAction('requestGetSuperAdminParams');
export const requestUpdateSuperAdminParams = createAction<SuperAdminParams>('requestUpdateSuperAdminParams');

type State = {
    superAdminParams: SuperAdminParams | null
    loading: boolean,
}
const initialState : State = {
  superAdminParams: null,
  loading: false,
};

const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSuperAdminParams: (state, action: PayloadAction<SuperAdminParams>) => {
      state.superAdminParams = action.payload;
    },
  },
});

export const { setSuperAdminParams, setLoading } = superAdminSlice.actions;

export default superAdminSlice.reducer;
