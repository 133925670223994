import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import { useAppSelector } from '../../store/store';

const ChartNumberOfPalletsDuringPeriod = () => {
  const { t } = useTranslation();
  const { palletsLoding, palletsData } = useAppSelector((state) => state.dashboards);
  if (palletsLoding) return <Loader/>;

  if (!palletsData?.data.length) return <NoData/>;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={palletsData?.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="qty" barSize={75} name={t('dashboard.quantity')} fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartNumberOfPalletsDuringPeriod;
