import { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Button, Col, Input, Row, Form,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, DeleteFilled } from '@ant-design/icons/lib/icons';
import OptionsButton, { Option } from '../OptionsButton';
import style from './noteContainer.module.css';

type OnChangeParams = {
  informazioni_aggiuntive: string | null,
}

type AdditionalTextPropsTypes = {
  additionalText: string | null,
  onChangeText?: (data: OnChangeParams) => void,
  isEdit?: boolean,
}
const AdditionalTextContainer = ({
  additionalText, onChangeText, isEdit = true,
}: AdditionalTextPropsTypes) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      informazioni_aggiuntive: additionalText,
    });
  }, [additionalText, form]);

  const onEditAction = () => {
    setIsEditable(true);
  };
  const onCloseEditableField = () => {
    setIsEditable(false);
    form.resetFields();
  };
  const onDeleteAction = () => {
    if (onChangeText) {
      onChangeText({
        informazioni_aggiuntive: null,
      });
      form.resetFields();
    }
  };

  const onFinish = (values) => {
    if (onChangeText) {
      onChangeText({
        informazioni_aggiuntive: values.informazioni_aggiuntive,
      });
      onCloseEditableField();
      form.resetFields();
    }
  };

  const options: Array<Option> = [
    {
      title: t('forms.estimations.edit'),
      action: onEditAction,
    },
    {
      title: t('forms.estimations.delete'),
      action: onDeleteAction,
    },
  ];

  const renderOptionButtons = () => {
    if (isEditable) {
      return (
        <>
          <Button
            htmlType="submit"
            type="primary"
            shape="round"
            icon={<CheckOutlined/>}
            className={style.iconBtn}
          />
          <Button
            type="primary"
            shape="round"
            icon={<DeleteFilled/>}
            className={classnames(style.iconBtn, style.deleteBtn)}
            onClick={onCloseEditableField}
          />
        </>
      );
    }
    return (
      <Col span={1} offset={2} className={style.options_padding}>
        <OptionsButton id={'1'} options={options}/>
      </Col>
    );
  };

  return (
    <div className={style.container}>
      <Row className={style.note_header}>
        <Col offset={1} className={style.note_title}>{t('forms.shipmentRequest.informazioni_aggiuntive')}</Col>
      </Row>
      <div className={style.content_container}>
        <Form
          form={form}
          initialValues={{
            informazioni_aggiuntive: additionalText,
          }}
          onFinish={onFinish}
        >
          <Row className={style.options_padding}>
            <Col span={19} offset={1} >
              { isEditable
                ? <Form.Item name="informazioni_aggiuntive" style={{ margin: 0 }}>
                  <Input.TextArea />
                </Form.Item>
                : <span>{additionalText}</span>
              }
            </Col>
            <Col span={3} offset={1} className={style.btn_container}>{ isEdit && renderOptionButtons() }</Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AdditionalTextContainer;
