import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { makeColumnsRejected } from '../components/TableColumns';
import OptionsButton, { Option } from 'components/OptionsButton';
import { ShipmentStructure } from 'store/types';
import { TPStatuses } from 'enums';

const RejectedTable = ({
  rejectedShipments,
  options,
  page,
  count,
  total,
  setPage,
  setCount,
  onStatusChange,
} : {
  rejectedShipments: ShipmentStructure[],
  options: Option[],
  page: number,
  count: number,
  total: number,
  setPage: (number) => void,
  setCount: (number) => void,
  onStatusChange: (status, shipment) => void,
}) => {
  const { t } = useTranslation();

  const onChangePageSize = (current, size) => {
    setCount(size);
  };
  const pagination = {
    current: page,
    pageSize: count,
    pageSizeOptions: [10, 15, 20],
    showSizeChanger: true,
    total,
    onChange: setPage,
    onShowSizeChange: onChangePageSize,
  };
  const data = rejectedShipments
    .map((shipment) => {
      const {
        id,
        pallets,
        user,
        user_id: userId,
        status_logs: statusLogs,
      } = shipment;
      const isNonStop = shipment.options.filter((el) => el.title?.toUpperCase() === 'SERVIZIO NON-STOP/ESPRESSO' && el.isActive).length > 0;
      return {
        key: id,
        id: shipment.spedizione_id || shipment.id,
        recipient_name: user.name,
        number_pallet: pallets.map((item) => `${item.item_quantity} - ${item.family}`),
        measures: pallets.map((pallet) => (
          `${pallet.width}cm x ${pallet.depth}cm x ${pallet.height}cm | ${pallet.weight}kg`
        )),
        rejected_date: statusLogs?.find((item) => item.status === 'Rifiutato')?.date || '',
        status: TPStatuses.Annullata,
        price: shipment.shipping_estimate ? `€ ${shipment.shipping_estimate.price.toFixed(2)}` : '-',
        settings: id,
        options: (
          <OptionsButton
            id={id.toString()}
            userId={userId?.toString()}
            options={options}
          />
        ),
        isNonStop,
      };
    });
  const columns = makeColumnsRejected(t, onStatusChange);

  return (
    <Table
      rowClassName={(record, index) => (record.isNonStop ? 'non_stop_background' : '')}
      columns={columns}
      pagination={pagination}
      dataSource={data}
    />
  );
};

export default RejectedTable;
