import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contractor, ContractorType, NewContractor } from '../../types';

export const requestSetContractor = createAction<ContractorType>('requestSetContractor');
export const requestGetContractor = createAction<string>('requestGetContractor');
export const requestCreateContractor = createAction<NewContractor>('requestCreateContractor');
export const requestUpdateContractor = createAction<Contractor>('requestUpdateContractor');
export const requestDeleteContractor = createAction<Contractor>('requestDeleteContractor');
export const requestClearContractor = createAction('requestClearContractor');

type State = {
  contractor: ContractorType | null,
  loading: boolean,
}

const initialState: State = {
  contractor: null,
  loading: false,
};

const contractorSlice = createSlice({
  name: 'contractor',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setContractorSliceData: (state, action: PayloadAction<ContractorType>) => {
      state.contractor = action.payload;
    },
    deleteContractorSliceData: (state) => {
      state.contractor = null;
    },
  },
});

export const { setContractorSliceData, deleteContractorSliceData, setLoading } = contractorSlice.actions;

export default contractorSlice.reducer;
