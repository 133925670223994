import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import {
  FinanceListPayloadType, FinanceSectionType, FinanceOrderByIdPayloadType, FinanceLastPushType,
  ExtraFinanceType, ExtraServicePatchPayload, SelectsIdsType, ConfirmDefaultUserType, ConfirmCreationInvoiceType,
  FinanceUsersType, FinanceUsersResponseType, FinanceUsersPayloadType, DeleteFinanceType,
} from '../types';

export const getFinanceWithExtraListAction = createAction<FinanceListPayloadType>('getFinanceWithExtraListAction');
export const getFinanceLastPushAction = createAction('getFinanceLastPushAction');
export const getFinanceListAction = createAction<FinanceListPayloadType>('getFinanceListAction');
export const postFinanceApproveAction = createAction<number>('postFinanceApproveAction');
export const postFinanceDisapproveAction = createAction<number>('postFinanceDisapproveAction');
export const patchFinanceOrderByIdAction = createAction<FinanceOrderByIdPayloadType>('patchFinanceOrderByIdAction');
export const postExtraFinanceAction = createAction<ExtraFinanceType>('postExtraFinanceAction');
export const patchExtraFinanceAction = createAction<ExtraServicePatchPayload>('patchExtraFinanceAction');
export const deleteExtraFinanceAction = createAction<number>('deleteExtraFinanceAction');
export const postExtraFinanceApproveAction = createAction<number>('postExtraFinanceApproveAction');
export const postExtraFinanceDisapproveAction = createAction<number>('postExtraFinanceDisapproveAction');
export const postFinanceDefaultUserAction = createAction<ConfirmDefaultUserType>('postFinanceDefaultUserAction');
export const postSendInvoiceAction = createAction<ConfirmCreationInvoiceType>('postSendInvoiceAction');
export const getFinanceUsersAction = createAction<FinanceUsersPayloadType>('getFinanceUsersAction');
export const deleteFinanceAction = createAction<DeleteFinanceType>('deleteFinanceAction');
export const postCalculateFinanceAction = createAction<FinanceSectionType>('postCalculateFinanceAction');

type State = {
  financeListLoading: boolean,
  financeList: FinanceSectionType[] | null,
  lastPushData: FinanceLastPushType | null,
  financeDetails: FinanceSectionType | null,
  extraFinanceList: ExtraFinanceType[] | null,
  selectIds: SelectsIdsType[] | null,
  extraFinanceModalLoading: boolean,
  successExtraFinanceModal: boolean,
  updateDetailsLoading: boolean,
  financeUsers: FinanceUsersType[] | null,
}

const initialState: State = {
  financeListLoading: false,
  financeList: null,
  lastPushData: null,
  financeDetails: null,
  extraFinanceList: null,
  selectIds: null,
  extraFinanceModalLoading: false,
  successExtraFinanceModal: false,
  updateDetailsLoading: false,
  financeUsers: null,
};

const financeSlice = createSlice({
  name: 'financeSection',
  initialState,
  reducers: {
    setFinanceListLoading: (state, action: PayloadAction<boolean>) => {
      state.financeListLoading = action.payload;
    },
    setUpdateDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.updateDetailsLoading = action.payload;
    },
    setExtraFinanceModalLoading: (state, action: PayloadAction<boolean>) => {
      state.extraFinanceModalLoading = action.payload;
    },
    setFinanceList: (state, action: PayloadAction<FinanceSectionType[] | null>) => {
      if (action.payload) {
        const selectIds = action.payload.map(({ approvato_il: approvatoIl, orderId, request }) => ({
          approvato_il: approvatoIl, orderId, shippingId: request.spedizione_id || orderId,
        }));
        state.financeList = action.payload;
        state.selectIds = selectIds;
      } else {
        state.financeList = null;
        state.selectIds = null;
      }
    },
    setLastPushData: (state, action: PayloadAction<FinanceLastPushType>) => {
      state.lastPushData = action.payload;
    },
    setFinanceDetails: (state, action: PayloadAction<FinanceSectionType | null>) => {
      if (!state.financeList) return;
      if (action.payload) {
        state.financeDetails = {
          ...state.financeList.find((item) => item.id === action?.payload?.id),
          ...action.payload,
        };
      }
      state.financeDetails = action.payload ? {
        ...state.financeDetails,
        ...action.payload,
      } : action.payload;
    },
    updateFinanceList: (state, action: PayloadAction<FinanceSectionType>) => {
      if (!state.financeList) return;
      state.financeList = state.financeList.map((item) => (
        item.id === action.payload.id ? {
          ...item, ...action.payload,
        } : item
      ));
    },
    setExtraFinanceList: (state, action: PayloadAction<ExtraFinanceType[] | null>) => {
      state.extraFinanceList = action.payload;
    },
    addNewExtraFinance: (state, action: PayloadAction<ExtraFinanceType>) => {
      if (!state.extraFinanceList) return;
      state.extraFinanceList = [...state.extraFinanceList, action.payload];
      state.successExtraFinanceModal = true;
    },
    editExtraFinance: (state, action: PayloadAction<ExtraFinanceType>) => {
      if (!state.extraFinanceList) return;
      const newExtraFinanceList = state.extraFinanceList.map((item) => (
        item.id === action.payload.id ? action.payload : item
      ));
      state.extraFinanceList = newExtraFinanceList;
      state.successExtraFinanceModal = true;
    },
    deleteExtraFinance: (state, action: PayloadAction<number>) => {
      if (!state.extraFinanceList) return;
      const newExtraFinanceList = state.extraFinanceList.filter((item) => (
        item.id !== action.payload
      ));
      state.extraFinanceList = newExtraFinanceList;
    },
    clearExtraFinaceModalInfo: (state) => {
      state.successExtraFinanceModal = false;
    },
    setOrderAfterApproveOrDisapprove: (state, action: PayloadAction<FinanceSectionType>) => {
      if (!state.financeList) return;
      const newFinanceList = state.financeList.map((item) => (
        item.id === action.payload.id ? { ...item, isApproved: action.payload.isApproved } : item
      ));
      state.financeList = newFinanceList;
    },
    setExtraFinanceAfterApproveOrDisapprove: (state, action: PayloadAction<ExtraFinanceType>) => {
      if (!state.extraFinanceList?.length) return;
      const newFinanceList = state.extraFinanceList.map((item) => (
        item.id === action.payload.id ? { ...item, isApproved: action.payload.isApproved } : item
      ));
      state.extraFinanceList = newFinanceList;
    },
    setFinanceUsers: (state, action: PayloadAction<FinanceUsersResponseType[]>) => {
      const mappedUsers = action.payload.map(({ id, name, email }) => ({
        value: id,
        label: name || email,
      }));
      state.financeUsers = mappedUsers;
    },
    clearFinanceSection: (state) => {
      state.financeList = null;
      state.extraFinanceList = null;
      state.financeUsers = null;
    },
  },
});

export const {
  setFinanceListLoading, setFinanceList, setLastPushData, setOrderAfterApproveOrDisapprove,
  setExtraFinanceList, addNewExtraFinance, editExtraFinance, clearExtraFinaceModalInfo, deleteExtraFinance,
  setExtraFinanceAfterApproveOrDisapprove, setExtraFinanceModalLoading, setUpdateDetailsLoading, updateFinanceList,
  setFinanceDetails, setFinanceUsers, clearFinanceSection,
} = financeSlice.actions;

export default financeSlice.reducer;
