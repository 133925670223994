import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import { useAppSelector } from '../../store/store';
import style from './dashboard.module.css';

const CustomTooltip = (props) => {
  const { active, payload } = props;
  if (active) {
    return (
      <div className={style.custom_tooltip}>
        {payload.map((item) => (
          <p
            key={item.dataKey}
            style={{ color: item.color }}
            className={style.tooltip_text}
          >
            {item.dataKey === 'deliveries' ? item.payload.name : item.name} : {item.value}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const ChartNumberOfDeliveryOrCollectionDelays = () => {
  const { t } = useTranslation();
  const { collctedLoading, collctedData } = useAppSelector((state) => state.dashboards);
  if (collctedLoading) return <Loader/>;

  if (!collctedData?.data.length) return <NoData/>;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={collctedData?.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="deliveries" name={t('dashboard.withdrawals')} fill="#8884d8" />
        <Bar dataKey="delays" name={t('dashboard.delays')} fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartNumberOfDeliveryOrCollectionDelays;
