import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorMessage } from '../types';

type State = {
   message: ErrorMessage | null
}

const initialState: State = {
  message: null,
};

const messageErrorSlice = createSlice({
  name: 'MessageError',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<ErrorMessage>) => {
      state.message = action.payload;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const { setMessage, clearMessage } = messageErrorSlice.actions;

export default messageErrorSlice.reducer;
