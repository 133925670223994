import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { CreateTransportPayload, TransportStructure, UpdateTransportPayload } from '../../types';

export const requestCreateNewTransport = createAction<CreateTransportPayload>('requestCreateNewTransport');
export const requestGetTransportById = createAction<string>('requestGetTransportById');
export const requestDeleteTransportById = createAction<string>('requestDeleteTransportById');
export const requestUpdateTransportById = createAction<UpdateTransportPayload>('requestUpdateTransportById');
export const requestGetTransportByShippingId = createAction<string>('requestGetTransportByShippingId');

type State = {
    transportById: TransportStructure | null
    loading: boolean,
}
const initialState: State = {
  transportById: null,
  loading: false,
};

const transportByIdSlice = createSlice({
  name: 'transportById',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTransportById: (state, action: PayloadAction<TransportStructure>) => {
      state.transportById = action.payload;
    },
    clearTransportById: (state) => {
      state.transportById = null;
    },
  },
});

export const { setTransportById, setLoading, clearTransportById } = transportByIdSlice.actions;

export default transportByIdSlice.reducer;
