import { put, takeLatest } from 'redux-saga/effects';
import { setLoading, setTransportData, requestTransport } from '../../redux_slice/transport/transportSlice';
import { GetTransportList } from '../../../api/transtort';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* getTransportSaga() {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetTransportList());
    yield put(setTransportData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* transportSaga() {
  yield takeLatest(requestTransport, getTransportSaga);
}
