import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { EstimationStructure } from '../../store/types';
import { makeEstimationTableColumns } from './TableColumns';

interface EstimationTableInterface {
  estimationsList: EstimationStructure,
  page: number,
  pageSize: number,
  setPage: (number) => void,
  setPageSize: (number) => void,
  onSelectedEstimations: (number) => void
  isArchived: boolean
}
const EstimationsTable: React.FC<EstimationTableInterface> = ({
  estimationsList, page, pageSize,
  setPage, setPageSize,
  onSelectedEstimations,
  isArchived,
}) => {
  const { t } = useTranslation();
  if (!estimationsList?.items) {
    return null;
  }
  const onChangePageSize = (current, size) => {
    setPageSize(size);
  };
  const pagination = {
    current: page,
    pageSize,
    pageSizeOptions: [10, 15, 20],
    showSizeChanger: true,
    total: isArchived ? estimationsList?.countArchived : estimationsList?.countNotArchived,
    onChange: setPage,
    onShowSizeChange: onChangePageSize,
  };

  const dataSource = estimationsList?.items?.map((i) => (
    {
      key: i.id,
      date: i.start_shipment_date || '',
      id: i.preventivi_id || i.id,
      email: i.user.isDeleted ? t('forms.estimations.deleted_user') : i.user.email,
      number_pallet: [
        i?.pallets?.map((item, k) => (
          <div key={`${i.id}${item}${k}`}>
            {item.item_quantity}
          </div>
        )),
      ],
      measures: [
        i?.pallets?.map((measure, k) => (
          <div key={`${i.id}${k}`}>
            {`${measure.width}cm x ${measure.depth}cm x ${measure.height}cm | ${measure.weight}kg`}
          </div>

        )),
      ],
      from_to: `${i.pickup_address?.location?.city || i.pickup_address?.location?.region} > 
      ${i.shipping_address?.location?.city || i.shipping_address?.location?.region}`,
      status: i.req_status,
      settings: i.id,
    }
  ));
  const columns = makeEstimationTableColumns(t, onSelectedEstimations);
  return (
    <>
      <Table
        columns={columns}
        pagination={pagination}
        dataSource={dataSource}
      />
    </>
  );
};

export default EstimationsTable;
