import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Banner from '../../components/Banner/Banner';
import EnterNumber from './EnterNumber';
import TrackingContent from '../../components/TrackingContent';
import { useAppDispatch, useAppSelector } from '../../store/store';

import style from './tracking.module.css';
import { requestShippingGetShippingRequest } from '../../store/redux_slice/shipmentSlice';

const Tracking = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [secondStep, setSecondStep] = useState(false);
  const { trackingStatus } = useAppSelector((state) => state.shipmentTrackingStatus);
  const { shippingRequest } = useAppSelector((state) => state.shipmentRequest);

  useEffect(() => {
    if (trackingStatus?.shipping_id) {
      dispatch(requestShippingGetShippingRequest(trackingStatus.shipping_id.toString()));
    }
  }, [dispatch, trackingStatus?.shipping_id]);

  return (
    <>
      <Banner title={!secondStep
        ? t('banner.trackingSearch')
        : `${t('banner.trackingNumber')} #${shippingRequest?.spedizione_id || shippingRequest?.id || '--'}`}
      />
      { !secondStep && <EnterNumber setSecondStep={setSecondStep} /> }
      { secondStep
        && <div className={classnames('customForm', style.trackingProgress)}>
          {
            shippingRequest?.tracking && shippingRequest.tracking === 'NonDisponsible'
              ? <div className="not_tracking_text">
                {t('shipmentList.notTrackingText')} {' '}
                <a href="mailto:commercialeplt@pesantisrl.it">commercialeplt@pesantisrl.it</a>
              </div>
              : <TrackingContent trackingStatus={trackingStatus} />
          }
        </div>
      }
    </>
  );
};

export default Tracking;
