import {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import {
  Typography, Row, Col,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { format } from 'date-fns';
import BackButton from 'components/BackButton';
import CustomButton from 'components/CustomButton/CustomButton';
import { OpenModalButton } from 'containers/Estimations/OpenModalButton';
import { ChangeAddressModal } from 'containers/Estimations/FieldModals/ChangeAddressModal';
import { ChangeEcoPalletModal } from 'containers/Estimations/FieldModals/ChangeEcoPalletModal';
import { ChangeFasciModal } from 'containers/Estimations/FieldModals/ChangeFasciModal';
import { ChangeContrassegnoModal } from 'containers/Estimations/FieldModals/ChangeContrassegnoModal';
import { ChangeFacchinaggioModal } from 'containers/Estimations/FieldModals/ChangeFacchinaggioModal';
import { ChangeOreNoteModal } from 'containers/Estimations/FieldModals/ChangeOreNoteModal';
import { ChangeDateModal } from 'containers/Estimations/FieldModals/ChangeDateModal';
import { ChangeTelefonicaModal } from 'containers/Estimations/FieldModals/ChangeTelefonicaModal';
import { ChangeGdoOrAmazonModal } from 'containers/Estimations/FieldModals/ChangeGdoOrAmazonModal';
import { ChangeTimeModal } from 'containers/Estimations/FieldModals/ChangeTimeModal';
import { ChangeShippintClientCode } from 'containers/Estimations/FieldModals/ChangeShippingClientCodeModal';
import Status from 'components/Status';
import { EstimationFieldModal, useModalState } from 'containers/Estimations/hooks';
import { useAppDispatch, useAppSelector } from 'store/store';
import { ShipmentStructure, ExtraServiceStructure } from 'store/types';
import {
  setShippingPayloadData, postCreateShippingAction, requestPrintLabelAction,
  requestUpdateShippingAction, requestRefusedPrivateAreaShippingRequest, requestApprovePrivateAreaShipmentRequestAction,
} from 'store/redux_slice/privateAreaSlice';
import {
  CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA, NON_STOP,
  CONTRASSEGNO, FACCHINAGGIO, FASCI, GESTIONE_EPAL, TELEFONICA,
  GDO, AMAZON,
} from 'pesantiConstants';
import {
  TPStatuses, OrderType, PathEnums, AddressFields, DeliveryType, EstimationStatuses,
} from 'enums';
import { checkShipmentStatus } from 'functions';
import style from './DetailsHeadInfo.module.css';

const { Title } = Typography;

const DetailsHeadInfo:React.FC<{
  shippingInfo: Partial<ShipmentStructure>,
}> = ({ shippingInfo }) => {
  const { t } = useTranslation();
  const {
    openModal,
    closeModal,
    isOpen,
  } = useModalState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orderType } = useAppSelector((state) => state.privateArea);
  const contrassegnoList = useAppSelector((state) => state.contrassegnoList?.contrassegnoList);
  const facchinaggioList = useAppSelector((state) => state.facchinaggioList?.facchinaggioList);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [ecoPallets, setEcoPallets] = useState<boolean>(false);
  const [fasciState, setFasci] = useState<boolean>(false);
  const [contrassegnoState, setContrassegno] = useState<boolean>(false);
  const [oreNoteState, setOreNote] = useState<boolean>(false);
  const [optionsError, setOptionsError] = useState<boolean>(false);

  const IS_EDITABLE = !!orderType;
  const IS_EDITABLE_DATE = IS_EDITABLE
    || shippingInfo.status === TPStatuses.In_attesa_di_approvazione
    || shippingInfo.status === TPStatuses.In_attesa_di_data
    || shippingInfo.status === TPStatuses.In_attesa_di_ritiro;
  const CRETE_ORDER_TITLE = orderType === OrderType.SHIPPING ? t('shipmentList.newShipment') : t('shipmentList.newEstimation');
  const IS_ESTIMATION = !shippingInfo.spedizione_id;
  const DETAILS_ORDER_TITLE = IS_ESTIMATION ? t('privateAreaShipmentDetails.estimation') : t('privateAreaShipmentDetails.shipment');
  const CONTRASSEGNO_EDIT = ((!shippingInfo.status && shippingInfo.req_status === EstimationStatuses.DA_ELABORARE)
    || shippingInfo.status === TPStatuses.In_attesa_di_approvazione
    || shippingInfo.status === TPStatuses.In_attesa_di_pagamento);

  const gdoOrAmazonLabel = useMemo(() => {
    const hasGdo = shippingInfo?.options?.some((o) => o.isActive && o.title?.toUpperCase() === GDO.toUpperCase());
    const hasAmazon = shippingInfo?.options?.some((o) => o.isActive && o.title?.toUpperCase() === AMAZON.toUpperCase());
    if (hasGdo && hasAmazon) {
      return 'GDO/AMAZON';
    }
    if (hasGdo) {
      return 'GDO';
    }
    if (hasAmazon) {
      return 'AMAZON';
    }
    return '';
  }, [shippingInfo?.options]);

  const serviceTelefonica = useMemo(() => (
    shippingInfo?.options?.find((item) => item.title.toUpperCase() === TELEFONICA.toUpperCase() && item.isActive)
  ), [shippingInfo?.options]);

  const serviceFacchinaggio = useMemo(() => (
    shippingInfo?.options?.find((item) => item.title.toUpperCase() === FACCHINAGGIO.toUpperCase() && item.isActive)
  ), [shippingInfo?.options]);

  const facchinaggioRitiroDescription = useMemo(() => (
    facchinaggioList?.find((s) => s.id === serviceFacchinaggio?.values?.ritiro)?.description
  ), [facchinaggioList, serviceFacchinaggio?.values?.ritiro]);

  const facchinaggioConsegnaDescription = useMemo(() => (
    facchinaggioList?.find((s) => s.id === serviceFacchinaggio?.values?.consegna)?.description
  ), [facchinaggioList, serviceFacchinaggio?.values?.consegna]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const gdoOrAmazonCompanyName = useMemo(() => (
    `${shippingInfo?.pickup_address?.gdo_name ? shippingInfo?.pickup_address.gdo_name : '-'}/
      ${shippingInfo?.shipping_address?.gdo_name ? shippingInfo?.shipping_address.gdo_name : '-'}`
  ), [shippingInfo]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasNonStop = useMemo(() => (
    shippingInfo?.options && shippingInfo?.options.some((o) => (
      (o.isActive && o.title?.toUpperCase() === NON_STOP.toUpperCase())
    ))
  ), [shippingInfo?.options]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasGDO = useMemo(() => (
    shippingInfo?.options && shippingInfo?.options.some((o) => (
      (o.isActive && o.title?.toUpperCase() === GDO.toUpperCase())
       || (o.isActive && o.title?.toUpperCase() === AMAZON.toUpperCase())
    ))
  ), [shippingInfo?.options]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const palletsNumber = useMemo(() => {
    if (shippingInfo) {
      return shippingInfo.pallets?.reduce((summ, cur) => summ + +cur.item_quantity, 0);
    }
    return null;
  }, [shippingInfo]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
  const palletsWeigth = useMemo(() => {
    if (shippingInfo) {
      return shippingInfo.pallets?.reduce((summ, cur) => summ + +cur.weight * cur.item_quantity, 0);
    }
    return null;
  }, [shippingInfo]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const {
      epal, fasci, contrassegno, ore_note: oreNote,
    } = shippingInfo;
    if (
      (ecoPallets && !epal)
       || (fasciState && !fasci)
       || (contrassegnoState && !contrassegno)
       || (oreNoteState && !oreNote)
    ) {
      setOptionsError(true);
    } else {
      setOptionsError(false);
    }
  }, [ecoPallets, fasciState, contrassegnoState, oreNoteState, shippingInfo]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!shippingInfo?.pickup_address?.location.province
      || !shippingInfo?.shipping_address?.location.province || !shippingInfo.start_shipment_date
      || !shippingInfo.start_shipment_time
    ) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  }, [shippingInfo, orderType]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const updateServiceHandler = useCallback((type:string, cb: (value: boolean) => void) => {
    const p = shippingInfo?.options?.find((s) => s?.title?.toUpperCase() === type);
    if (p && p.isActive) {
      cb(true);
    } else {
      cb(false);
    }
  }, [shippingInfo?.options]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (shippingInfo?.options?.length) {
      updateServiceHandler(GESTIONE_EPAL, setEcoPallets);
      updateServiceHandler(FASCI, setFasci);
      updateServiceHandler(CONTRASSEGNO, setContrassegno);
      updateServiceHandler(CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA, setOreNote);
    }
  }, [shippingInfo?.options?.length, updateServiceHandler]);

  const onChangeAddressHandler = (address: Partial<ShipmentStructure>) => {
    dispatch(setShippingPayloadData({
      ...shippingInfo,
      ...address,
    }));
  };

  const onChangeDateHandler = (field:string, date:string) => {
    if (!IS_EDITABLE && shippingInfo.id) {
      const checkStatus = shippingInfo.status !== TPStatuses.In_attesa_di_approvazione;
      let payloadStatus:string | undefined;

      if (field === 'start_shipment_date' && checkStatus && !date) {
        payloadStatus = TPStatuses.In_attesa_di_data;
      } else if (field === 'start_shipment_date' && checkStatus) {
        payloadStatus = TPStatuses.In_attesa_di_ritiro;
      } else {
        payloadStatus = undefined;
      }
      const data = {
        id: shippingInfo.id,
        data: {
          [field]: date || null,
          status: payloadStatus,
        },
      };
      dispatch(requestUpdateShippingAction(data));
    } else {
      dispatch(setShippingPayloadData({
        ...shippingInfo,
        [field]: date,
      }));
    }
  };

  const onSetServiceDetails = (field, value) => {
    if (!IS_EDITABLE && shippingInfo.id && CONTRASSEGNO_EDIT) {
      dispatch(requestUpdateShippingAction({
        id: shippingInfo.id,
        data: {
          [field]: value,
        },
      }));
    } else {
      dispatch(setShippingPayloadData({
        ...shippingInfo,
        [field]: value,
      }));
    }
  };

  const onSubmitOrderRequest = () => {
    const status = orderType === OrderType.SHIPPING ? {
      status: TPStatuses.In_attesa_di_approvazione,
    } : {};

    if (shippingInfo.id) {
      const data = {
        id: shippingInfo.id || '',
        sendEmail: true,
        data: { ...shippingInfo },
      };
      dispatch(requestUpdateShippingAction(data));
    } else {
      dispatch(postCreateShippingAction({
        ...shippingInfo,
        ...status,
      }));
    }
  };

  const onGdoChange = (gdoInfo) => {
    dispatch(setShippingPayloadData({
      ...shippingInfo,
      ...gdoInfo,
    }));
  };

  const onUpdateTime = (time) => {
    if (!IS_EDITABLE && shippingInfo.id) {
      const data = {
        id: shippingInfo.id,
        data: {
          start_shipment_time: time,
        },
      };
      dispatch(requestUpdateShippingAction(data));
    } else {
      dispatch(setShippingPayloadData({
        ...shippingInfo,
        start_shipment_time: time,
      }));
    }
  };

  const onChangeCodeHandler = (shippingCode) => {
    dispatch(setShippingPayloadData({
      ...shippingInfo,
      codice_cliente_spedizione: shippingCode,
    }));
  };

  const renderEstimationCost = () => {
    if (shippingInfo?.shipping_estimate?.price) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(shippingInfo?.shipping_estimate?.price);
    }
    return '--€';
  };

  const printLabelHandler = () => {
    if (shippingInfo.id) {
      dispatch(requestPrintLabelAction(shippingInfo.id));
    }
  };

  const onSetEditableService = (servicePeyload: ExtraServiceStructure, serviceTitle: string) => {
    if (shippingInfo.options) {
      dispatch(setShippingPayloadData({
        ...shippingInfo,
        options: shippingInfo?.options.map((item) => (
          item.title.toUpperCase() === serviceTitle.toUpperCase() ? servicePeyload : item
        )),
      }));
    }
  };

  const appreveorRejectHandler = (type: 'approve' | 'reject') => {
    if (shippingInfo?.id) {
      const contractor = shippingInfo?.contractor;
      if (type === 'approve' && contractor && contractor[0] && contractor[0].name) {
        dispatch(requestApprovePrivateAreaShipmentRequestAction({
          id: shippingInfo.id,
          data: {
            status: TPStatuses.In_attesa_di_approvazione,
            contractor: contractor[0].name,
            payment_method: 'bank_transfer',
          },
        }));
      } else if (type === 'reject') {
        dispatch(requestRefusedPrivateAreaShippingRequest({
          id: shippingInfo.id,
        }));
      }
    }
  };

  const renderButtons = () => {
    if (IS_EDITABLE) {
      return (
        <div className={style.support_btn_contaier}>
          <CustomButton
            className={style.confirm_btn}
            onClick={onSubmitOrderRequest}
            type="primary"
            disabled={errorMessage
              || optionsError
              || !shippingInfo.pallets?.length
            }
          >
            { orderType === OrderType.SHIPPING ? t('banner.shipmentConfirm') : t('banner.estimationConfirm')}
          </CustomButton>
        </div>
      );
    }
    if (!shippingInfo.status && shippingInfo.req_status === EstimationStatuses.DA_ELABORARE) {
      return (
        <p className={style.paragraph}>
          {t('privateAreaShipmentDetails.estimation_in_pending')}
        </p>
      );
    }
    if (shippingInfo.status === TPStatuses.In_attesa_di_approvazione && shippingInfo.payment_method) {
      return (
        <p className={style.paragraph}>
          {t('privateAreaShipmentDetails.order_in_pending')}
        </p>
      );
    }
    if (shippingInfo.status === TPStatuses.In_attesa_di_approvazione
      && (shippingInfo.req_status === EstimationStatuses.INVIATO
        || shippingInfo.req_status === EstimationStatuses.INVIATO_FOLLOW_UP)) {
      return (
        <>
          <p className={style.paragraph}>
            {t('privateAreaShipmentDetails.confirm_or_not')}
          </p>
          <div className={style.buttons_container}>
            <CustomButton
              type="primary"
              className={style.confirm_btn}
              onClick={() => appreveorRejectHandler('approve')}>
              {t('buttons.confirm')}
            </CustomButton>
            <CustomButton
              className={cn(style.confirm_btn, 'defaultBtn')}
              onClick={() => appreveorRejectHandler('reject')}
            >
              {t('buttons.reject')}
            </CustomButton>
          </div>
        </>
      );
    }
    return (
      <div className={style.buttons_column_container}>
        <CustomButton
          className={style.confirm_btn}
          type="primary"
          onClick={() => navigate(PathEnums.Support)}
        >
          {t('shipmentList.requestSupport')}
        </CustomButton>
        { shippingInfo.tracking_code && shippingInfo.user?.printer_params && (
          <CustomButton
            className={cn(style.confirm_btn, 'defaultBtn')}
            onClick={printLabelHandler}
          >
            {t('buttons.print_label')}
          </CustomButton>
        )}
      </div>
    );
  };

  return (
    <div className={style.head_info}>
      <ChangeAddressModal
        orderData={shippingInfo}
        field={AddressFields.PICKUP_ADDRESS}
        deliveryType={DeliveryType.RITIRO}
        visible={isOpen(EstimationFieldModal.openPickupAddressModal)}
        onCancel={closeModal}
        onRequestUpdateOrder={onChangeAddressHandler}
      />
      <ChangeAddressModal
        orderData={shippingInfo}
        field={AddressFields.SHIPPING_ADDRESS}
        deliveryType={DeliveryType.CONSEGNA}
        visible={isOpen(EstimationFieldModal.openShippingAddressModal)}
        onCancel={closeModal}
        onRequestUpdateOrder={onChangeAddressHandler}
      />
      <ChangeDateModal
        visible={isOpen(EstimationFieldModal.openPickupDateModal)}
        onCancel={closeModal}
        field="start_shipment_date"
        title={t('privateAreaShipmentDetails.deliveryDate')}
        setDate={(value) => onChangeDateHandler('start_shipment_date', value)}
        initialValue={shippingInfo.start_shipment_date ? shippingInfo.start_shipment_date : null}
        consegnaDate={shippingInfo.withdrawal_date}
      />
      <ChangeDateModal
        visible={isOpen(EstimationFieldModal.openShippingDateModal)}
        onCancel={closeModal}
        field="withdrawal_date"
        title={t('privateAreaShipmentDetails.withdrawalDate')}
        setDate={(value) => onChangeDateHandler('withdrawal_date', value)}
        initialValue={shippingInfo.withdrawal_date ? shippingInfo.withdrawal_date : null}
        ritiroDate={shippingInfo.start_shipment_date}
      />
      <ChangeDateModal
        field="non_stop_date_ritiro"
        visible={isOpen(EstimationFieldModal.openNonStopDateRitiroModal)}
        onCancel={closeModal}
        title={t('forms.shipmentRequest.non_stop_date_ritiro_2')}
        setDate={(value) => onChangeDateHandler('non_stop_date_ritiro', value)}
        initialValue={shippingInfo.non_stop_date_ritiro ? shippingInfo.non_stop_date_ritiro : null}
        consegnaDate={shippingInfo.non_stop_date_consegna}
      />
      <ChangeDateModal
        field="non_stop_date_consegna"
        visible={isOpen(EstimationFieldModal.openNonStopDateConsegnaModal)}
        onCancel={closeModal}
        title={t('forms.shipmentRequest.non_stop_date_consegna')}
        setDate={(value) => onChangeDateHandler('non_stop_date_consegna', value)}
        initialValue={shippingInfo.non_stop_date_consegna ? shippingInfo.non_stop_date_consegna : null}
        ritiroDate={shippingInfo.non_stop_date_ritiro}
      />
      <ChangeEcoPalletModal
        visible={isOpen(EstimationFieldModal.openEcoPallet)}
        onChange={(value) => onSetServiceDetails('epal', value)}
        onCancel={() => closeModal()}
        initialValue={shippingInfo?.epal || 0}
      />
      <ChangeFasciModal
        visible={isOpen(EstimationFieldModal.openFasciModal)}
        onChange={(value) => onSetServiceDetails('fasci', value)}
        onCancel={() => closeModal()}
        initialValue={shippingInfo?.fasci || 0}
        maxValue={palletsNumber ?? 0}
      />
      <ChangeContrassegnoModal
        visible={isOpen(EstimationFieldModal.openContrassegnoModal)}
        onChange={(value) => onSetServiceDetails('contrassegno', value)}
        onCancel={() => closeModal()}
        initialValue={({ value: shippingInfo?.contrassegno?.value || 0, id: shippingInfo?.contrassegno?.id || 0 })}
      />
      <ChangeOreNoteModal
        visible={isOpen(EstimationFieldModal.openConsegnaConGiornoEOraPrestabilitaModal)}
        onChange={(value) => onSetServiceDetails('ore_note', value)}
        onCancel={() => closeModal()}
        initialValue={shippingInfo?.ore_note || ''}
        id={shippingInfo?.id}
      />
      <ChangeTimeModal
        visible={isOpen(EstimationFieldModal.openTimeModal)}
        onCancel={closeModal}
        initialValue={shippingInfo?.start_shipment_time || ''}
        onUpdateTime={onUpdateTime}
      />
      <ChangeShippintClientCode
        visible={isOpen(EstimationFieldModal.openShippintClientCodeModal)}
        onCancel={closeModal}
        initialValue={shippingInfo?.codice_cliente_spedizione || ''}
        onChangeCode={onChangeCodeHandler}
      />
      { serviceFacchinaggio && (
        <ChangeFacchinaggioModal
          visible={isOpen(EstimationFieldModal.openFacchinaggioModal)}
          service={serviceFacchinaggio}
          onCancel={closeModal}
          onUpdateService={(data) => onSetEditableService(data, FACCHINAGGIO)}
        />
      )}
      { serviceTelefonica && (
        <ChangeTelefonicaModal
          visible={isOpen(EstimationFieldModal.openTelefonica)}
          service={serviceTelefonica}
          onCancel={closeModal}
          onUpdateService={(data) => onSetEditableService(data, TELEFONICA)}
        />
      )}
      { hasGDO && (
        <ChangeGdoOrAmazonModal
          visible={isOpen(EstimationFieldModal.openGdoOrAmazonModal)}
          orderData={shippingInfo}
          onCancel={closeModal}
          onGdoChange={onGdoChange}
          privateArea
        />
      )}
      <Row className={style.back_link_container}>
        <BackButton className={style.back} onClick={() => navigate(-1)}/>
      </Row>
      <Row className={style.height_container}>
        <Col span={12}>
          <div className={style.title_container}>
            <Title level={1} className={style.title}>
              { IS_EDITABLE
                ? CRETE_ORDER_TITLE
                : `${DETAILS_ORDER_TITLE} #${shippingInfo.spedizione_id || shippingInfo.preventivi_id || shippingInfo.id}`
              }
            </Title>
            { (shippingInfo.preventivi_id || shippingInfo.spedizione_id) && !IS_EDITABLE && (
              <Status
                status={checkShipmentStatus(shippingInfo?.status, shippingInfo?.req_status)}
                className={style.status}
              />
            )}
          </div>
          { IS_ESTIMATION && (
            <div className={cn(style.total_cost_container, style.text_margin_bottom)}>
              {t('privateAreaShipmentDetails.total_cost')}
              <span className={style.total_cost}>
                { renderEstimationCost() } + IVA
              </span>
            </div>
          )}
          { IS_ESTIMATION && shippingInfo?.shipping_estimate?.price && (
            <div className={style.shipment_description_row}>
              {t('privateAreaShipmentDetails.fuel_info')}
            </div>
          )}
          {shippingInfo?.ddt_number && (
            <div className={style.shipment_description_row}>
              <span>{t('forms.shipmentRequest.ddt_number')}:</span>
              <span className={style.bold}>{shippingInfo.ddt_number}</span>
            </div>
          )}
          {shippingInfo?.codice_cliente_spedizione && (
            <div className={style.shipment_description_row}>
              <span>{t('forms.shipmentRequest.shipping_client_code')}:</span>
              <span className={style.bold}>{shippingInfo.codice_cliente_spedizione}</span>
              { IS_EDITABLE
                && <OpenModalButton
                  modal={EstimationFieldModal.openShippintClientCodeModal}
                  openModal={openModal}
                /> }
            </div>
          )}
        </Col>
        <Col span={7} offset={5}>
          {renderButtons()}
        </Col>
      </Row>
      <Row className={style.height_container}>
        <Col span={5}>
          <div className={style.shipment_description}>
            <div className={style.col_container}>
              <span>{t('privateAreaShipmentDetails.sender')}</span>
              {shippingInfo.pickup_address?.gdo_id && (
                <span className={style.gdo_label}>GDO</span>
              )}
            </div>
            <div className={cn(style.bold, style.address)}>
              <span>{shippingInfo.pickup_address?.company_name}</span>
              <span>{shippingInfo.pickup_address?.name_and_surname_reference}</span>
              <span>
                {shippingInfo.pickup_address?.location.street
                  ? `${shippingInfo.pickup_address?.location.street} ${shippingInfo.pickup_address?.location.house_number || ''}, ` : ''
                }
                {shippingInfo.pickup_address?.location.post_code
                  ? `${shippingInfo.pickup_address?.location.post_code},` : ''
                }
                {shippingInfo.pickup_address?.location.city
                  ? `${shippingInfo.pickup_address?.location.city},` : ''
                }
                {shippingInfo.pickup_address?.location.province}
              </span>
              { !shippingInfo?.pickup_address?.location.province
                && <span className={style.error}>{t('forms.newShipment.street_and_city')}</span> }
            </div>
            { IS_EDITABLE
            && <OpenModalButton
              modal={EstimationFieldModal.openPickupAddressModal}
              openModal={openModal}
            /> }
          </div>
        </Col>
        <Col span={5} offset={1}>
          <div className={style.shipment_description}>
            <div className={style.col_container}>
              <span>{t('privateAreaShipmentDetails.recipient')}</span>
              {shippingInfo.shipping_address?.gdo_id && (
                <span className={style.gdo_label}>GDO</span>
              )}
            </div>
            <div className={cn(style.bold, style.address)}>
              <span>{shippingInfo.shipping_address?.company_name}</span>
              <span>{shippingInfo.shipping_address?.name_and_surname_reference}</span>
              <span>
                {shippingInfo.shipping_address?.location.street
                  ? `${shippingInfo.shipping_address?.location.street} ${shippingInfo.shipping_address?.location.house_number || ''}, ` : ''
                }
                {shippingInfo.shipping_address?.location.post_code
                  ? `${shippingInfo.shipping_address?.location.post_code},` : ''
                }
                {shippingInfo.shipping_address?.location.city
                  ? `${shippingInfo.shipping_address?.location.city},` : ''
                }
                {shippingInfo.shipping_address?.location.province}
              </span>
              { !shippingInfo?.shipping_address?.location.province
                && <span className={style.error}>{t('forms.newShipment.street_and_city')}</span> }
            </div>
            { IS_EDITABLE
            && <OpenModalButton
              modal={EstimationFieldModal.openShippingAddressModal}
              openModal={openModal}
            /> }
          </div>
        </Col>
        <Col span={4} offset={1} className={style.col_container}>
          <p className={style.shipment_description}>
            <span>{t('privateAreaShipmentDetails.number_pallet')}</span>
            <span className={style.bold}>{palletsNumber || '-'}</span>
          </p>
          <p className={style.shipment_description}>
            <span>{t('privateAreaShipmentDetails.weight')}</span>
            <span className={style.bold}>{palletsWeigth || '-'} Kg</span>
          </p>
        </Col>
        <Col span={8} className={style.col_container}>
          <p className={style.shipment_description}>
            <span>{t('privateAreaShipmentDetails.deliveryDate')}:</span>
            <span className={style.bold}>
              { shippingInfo?.start_shipment_date ? format(new Date(shippingInfo?.start_shipment_date), 'dd-MM-yyyy') : '-'}
            </span>
            { IS_EDITABLE_DATE
            && <OpenModalButton
              modal={EstimationFieldModal.openPickupDateModal}
              openModal={openModal}
              className={style.edit_btn}
            /> }
            { !shippingInfo?.start_shipment_date && (
              <span className={style.error}>{t('privateAreaShipmentDetails.start_date_validation')}</span>
            )}
          </p>

          <p className={style.shipment_description}>
            <span>{t('privateAreaShipmentDetails.deliveryTime')}:</span>
            <span className={style.bold}>
              { shippingInfo?.start_shipment_time ? shippingInfo?.start_shipment_time : '-'}
            </span>
            { IS_EDITABLE_DATE
            && <OpenModalButton
              modal={EstimationFieldModal.openTimeModal}
              openModal={openModal}
              className={style.edit_btn}
            /> }
            { !shippingInfo?.start_shipment_time && (
              <span className={style.error}>{t('privateAreaShipmentDetails.start_time_validation')}</span>
            )}
          </p>

          <p className={style.shipment_description}>
            <span>{t('privateAreaShipmentDetails.withdrawalDate')}:</span>
            <span className={style.bold}>
              { shippingInfo?.withdrawal_date ? format(new Date(shippingInfo?.withdrawal_date), 'dd-MM-yyyy') : '-' }
            </span>
            { IS_EDITABLE_DATE
            && <OpenModalButton
              modal={EstimationFieldModal.openShippingDateModal}
              openModal={openModal}
              className={style.edit_btn}
            /> }
          </p>
          { hasNonStop && (
            <>
              <p className={style.shipment_description}>
                <span>{t('forms.shipmentRequest.non_stop_date_ritiro_2')}:</span>
                <span className={style.bold}>
                  { shippingInfo?.non_stop_date_ritiro ? format(new Date(shippingInfo?.non_stop_date_ritiro), 'dd-MM-yyyy') : '-' }
                </span>
                { IS_EDITABLE_DATE
                && <OpenModalButton
                  modal={EstimationFieldModal.openNonStopDateRitiroModal}
                  openModal={openModal}
                  className={style.edit_btn}
                /> }
              </p>
              <p className={style.shipment_description}>
                <span>{t('forms.shipmentRequest.non_stop_date_consegna_2')}:</span>
                <span className={style.bold}>
                  { shippingInfo?.non_stop_date_consegna ? format(new Date(shippingInfo?.non_stop_date_consegna), 'dd-MM-yyyy') : '-' }
                </span>
                { IS_EDITABLE_DATE
                && <OpenModalButton
                  modal={EstimationFieldModal.openNonStopDateConsegnaModal}
                  openModal={openModal}
                  className={style.edit_btn}
                /> }
              </p>
            </>
          )}
        </Col>
      </Row>
      <Row className={cn(style.height_container, style.p_top_none)}>
        { ecoPallets
          && <p className={style.options_text}>
            {t('forms.estimations.ecological_pallet')}:
            <span className={style.options_bolt_text}>
              {shippingInfo?.epal || 0}
            </span>
            { IS_EDITABLE && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openEcoPallet)}/> }
          </p>
        }
        { fasciState
          && <p className={style.options_text}>
            {t('forms.estimations.fasci')}:
            <span className={style.options_bolt_text}>
              {shippingInfo?.fasci || 0}
            </span>
            { IS_EDITABLE
              && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openFasciModal)}/> }
          </p>
        }
        { contrassegnoState
          && <p className={style.options_text}>
            {t('forms.estimations.contrassegno')}:
            <span className={style.options_bolt_text}>
              { (shippingInfo?.contrassegno
                  && `${contrassegnoList.find((s) => s.id === shippingInfo?.contrassegno?.id)?.description || ''}
                  ${shippingInfo?.contrassegno.id ? '-' : ''}
                  ${parseFloat(`${shippingInfo?.contrassegno?.value}`).toFixed(2)}€`) || '-'}
            </span>
            { (IS_EDITABLE || CONTRASSEGNO_EDIT)
              && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openContrassegnoModal)}/>}
          </p>
        }
        {serviceFacchinaggio
          && <p className={style.options_text}>
            {t('forms.estimations.facchinaggio')}:
            <span className={style.options_bolt_text}>
              { facchinaggioRitiroDescription || '-'}{' '}/{' '}
              { facchinaggioConsegnaDescription || '-'}
            </span>
            { IS_EDITABLE
            && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openFacchinaggioModal)}/> }
          </p>
        }
        { oreNoteState
          && <p className={style.options_text}>
            {t('forms.estimations.ore_note')}:
            <span className={style.options_bolt_text}>
              {shippingInfo?.ore_note || '-'}
            </span>
            { IS_EDITABLE
             && <SettingOutlined onClick={
               () => openModal(EstimationFieldModal.openConsegnaConGiornoEOraPrestabilitaModal)
             }/>}
          </p>
        }
        {hasGDO
            && <p className={style.options_text}>
              {gdoOrAmazonLabel}:
              <span className={style.options_bolt_text}>
                {gdoOrAmazonCompanyName}
              </span>
              {IS_EDITABLE
                && <SettingOutlined onClick={
                  () => openModal(EstimationFieldModal.openGdoOrAmazonModal)
                }/>
              }
            </p>
        }
        {serviceTelefonica
          && <p className={style.options_text}>
            {t('forms.shipmentDetails.telephonica')}:
            <span className={style.options_bolt_text}>
              {serviceTelefonica?.values?.ritiro || '-'}{' '}/{' '}
              {serviceTelefonica?.values?.consegna || '-'}
            </span>
            {IS_EDITABLE
            && <SettingOutlined onClick={
              () => openModal(EstimationFieldModal.openTelefonica)
            }/>
            }
          </p>
        }
      </Row>
    </div>
  );
};

export default DetailsHeadInfo;
