import createSagaMiddleware from 'redux-saga';

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import mySaga from './sagas';
import authReducer from './redux_slice/authSlice';
import estimationsListReducer from './redux_slice/estimationsListSlice';
import shipmentReducer from './redux_slice/shipmentSlice';
import shippingServicesSlice from './redux_slice/shippingServicesSlice';
import pricelistsSlice from './redux_slice/pricelists/pricelistsSlice';
import filterPriceListSlice from './redux_slice/pricelists/filterSlice';
import usersSlice from './redux_slice/users/usersSlice';
import priceListTemplatesSlice from './redux_slice/priceListTemplatesSlice';
import userPriceListSlice from './redux_slice/userPriceListSlice';
import palletsSlice from './redux_slice/palletsSlice';
import estimationSlice from './redux_slice/estimationSlice';
import contractorsSlice from './redux_slice/contractors/contractorsSlice';
import pallexHubsSlice from './redux_slice/pallexHubsSlice';
import extraByPriceListSlice from './redux_slice/extraByPriceListSlice';
import contractorSlice from './redux_slice/contractors/contractorSlice';
import paymentLinkSlice from './redux_slice/paymentLinkSlice';
import userPriceListByIdSlice from './redux_slice/pricelists/userPriceListByIdSlice';
import usersListSlice from './redux_slice/users/usersListSlice';
import UserSlice from './redux_slice/users/userSlice';
import usersSearchSlice from './redux_slice/users/usersSearchSlice';
import settingsSlice from './redux_slice/settingsSlice';
import operatorsListSlice from './redux_slice/operators/operatorsListSlice';
import operatorSlice from './redux_slice/operators/operatorSlice';
import numeroOnuSlice from './redux_slice/pallex/numeroOnuSlice';
import contrassegnoSlice from './redux_slice/pallex/contrassegnoSlice';
import facchinaggioSlice from './redux_slice/pallex/facchinaggioSlice';
import nonStopSlice from './redux_slice/pallex/nonStopSlice';
import transportSlice from './redux_slice/transport/transportSlice';
import transportTypesSlice from './redux_slice/transport/transportTypesSlice';
import transportByIdSlice from './redux_slice/transport/transportByIdSlice';
import transportTypeSlice from './redux_slice/transport/transportTypeSlice';
import messageErrorSlice from './redux_slice/messageErrorSlice';
import shippingBeforeApproveSlice from './redux_slice/shipping/shippingBeforeApproveSlice';
import shippingApproveSlice from './redux_slice/shipping/shippingApproveSlice';
import shippingRejcetedSlice from './redux_slice/shipping/shippingRejectedSlice';
import privateAreaSlice from './redux_slice/privateAreaSlice';
import notificationsSlice from './redux_slice/notificationsSlice';
import shipmentTrackingStatusSlice from './redux_slice/shipmentTrackingStatusSlice';
import tripsSlice from './redux_slice/tripsSlice';
import dashboardSlice from './redux_slice/dashboardSlice';
import transportByDriverIdSlice from './redux_slice/transport/transportByDriverIdSlice';
import superAdminSlice from './redux_slice/superAdminSlice';
import financeSlice from './redux_slice/financeSlice';
import MonitoringSlice from './redux_slice/monitoringSlice';
import usersCompanyListSlice from './redux_slice/users/usersCompanyListSlice';
import priceListTemplateSlice from './redux_slice/pricelists/priceListTemplateSlice';
import gdoSlice from './redux_slice/pallex/gdoSlice';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    auth: authReducer,
    estimationsList: estimationsListReducer,
    shipmentRequest: shipmentReducer,
    extraServices: shippingServicesSlice,
    pricelists: pricelistsSlice,
    users: usersSlice,
    usersSearch: usersSearchSlice,
    priceListTemplates: priceListTemplatesSlice,
    userPriceList: userPriceListSlice,
    pallets: palletsSlice,
    estimation: estimationSlice,
    contractors: contractorsSlice,
    pallexHubs: pallexHubsSlice,
    extraServicesByPriceList: extraByPriceListSlice,
    contractor: contractorSlice,
    paymentLink: paymentLinkSlice,
    userPriceListById: userPriceListByIdSlice,
    transport: transportSlice,
    settings: settingsSlice,
    usersList: usersListSlice,
    user: UserSlice,
    operatorsList: operatorsListSlice,
    operator: operatorSlice,
    transportTypes: transportTypesSlice,
    transportById: transportByIdSlice,
    transportType: transportTypeSlice,
    contrassegnoList: contrassegnoSlice,
    facchinaggioList: facchinaggioSlice,
    nonStopList: nonStopSlice,
    messageError: messageErrorSlice,
    shippingBeforeApprove: shippingBeforeApproveSlice,
    shippingApprove: shippingApproveSlice,
    shippingRejceted: shippingRejcetedSlice,
    privateArea: privateAreaSlice,
    notifications: notificationsSlice,
    shipmentTrackingStatus: shipmentTrackingStatusSlice,
    filterPriceList: filterPriceListSlice,
    unloadingTrips: tripsSlice,
    dashboards: dashboardSlice,
    transportByDriverId: transportByDriverIdSlice,
    superAdmin: superAdminSlice,
    financeSection: financeSlice,
    monitoring: MonitoringSlice,
    usersCompanyList: usersCompanyListSlice,
    priceListTemplate: priceListTemplateSlice,
    gdo: gdoSlice,
    numeroOnu: numeroOnuSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// then run the saga
sagaMiddleware.run(mySaga);
