import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NonStop } from '../../types';

export const requestGetNonStop = createAction('requestGetNonStop');

type State = {
  nonStopList: NonStop[],
  loading: boolean,
}

const initialState: State = {
  nonStopList: [],
  loading: false,
};

const nonStopSlice = createSlice({
  name: 'nonStop',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNonStopSliceData: (state, action: PayloadAction<NonStop[]>) => {
      state.nonStopList = action.payload;
    },
  },
});

export const { setNonStopSliceData, setLoading } = nonStopSlice.actions;

export default nonStopSlice.reducer;
