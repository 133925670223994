import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import NewEstimationForm from './NewEstimationForm';
import BackButton from '../../components/BackButton';

import style from './newEstimation.module.css';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { setCleanData } from '../../store/redux_slice/shipmentSlice';
import { clearPallets, requestClearPallets } from '../../store/redux_slice/palletsSlice';
import { requestClearEstimation } from '../../store/redux_slice/estimationSlice';
import { PathEnums } from 'enums';

const NewEstimation = () => {
  const { t } = useTranslation();
  const [isLoaderContainer, setIsLoaderContainer] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const shippingRequest = useAppSelector((state) => state.shipmentRequest?.shippingRequest);
  const [form] = Form.useForm();

  const onBack = () => {
    dispatch(setCleanData());
    dispatch(requestClearPallets());
    dispatch(requestClearEstimation());
    navigate(PathEnums.Estimations, { replace: true });
  };

  useEffect(() => () => {
    dispatch(clearPallets());
  }, [dispatch]);

  return (
    <>
      {
        shippingRequest && (
          <Navigate to={PathEnums.Estimations} replace={true} />
        )
      }
      <div className={classnames(style.new_estimate_container, style.title_container)}>
        <BackButton onClick={() => navigate(-1)} />
        <div className={classnames(style.title, 'main-title-30px')}>
          {t('forms.newEstimation.new_quote')}
        </div>
      </div>
      <NewEstimationForm
        isLoaderContainer={isLoaderContainer}
        setIsLoaderContainer={setIsLoaderContainer}
        onCancel={onBack}
        form={form}
      />
    </>
  );
};

export default NewEstimation;
