import { put, takeLatest } from 'redux-saga/effects';
import { DeleteShippingRequest } from '../../../api/shipping';
import { setLoading, requestDeleteRejectedShipmentAction } from '../../redux_slice/shipping/shippingRejectedSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* deleteShipmenRejectedSaga(action: {
  payload: string
}) {
  yield put(setLoading(true));
  try {
    yield DeleteShippingRequest(action.payload);
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* shippingRejectedSaga() {
  yield takeLatest(requestDeleteRejectedShipmentAction, deleteShipmenRejectedSaga);
}
