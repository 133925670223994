import { Button, Modal } from 'antd';
import React from 'react';
import styles from './users.module.css';

interface InviteModalInterface {
    title: string,
    description: string,
    submitText: string,
    cancelText: string,
    visible: boolean,
    onCancel: () => void,
    onInvite: () => void,
}

const InviteModal:React.FC<InviteModalInterface> = ({
  title, description, visible, onCancel, onInvite, submitText, cancelText,
}) => (
  <Modal title={title}
    visible={visible}
    onOk={onInvite}
    onCancel={onCancel}
    footer={[
      <Button key="back" onClick={onCancel} className={styles.primary_blue}>
        {cancelText}
      </Button>,
      <Button key="submit" type="primary" onClick={onInvite}>
        {submitText}
      </Button>,
    ]}
  >
    {description}
  </Modal>
);

export default InviteModal;
