import { put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  CreateRequestEstimate,
  GetRequestEstimate, GetPalletEstimation,
  UpdateRequestEstimate, UpdateShippingRequest,
} from 'api';
import { GetPriceListById } from 'api/price';
import {
  updateToBeApprovedShipmentAction,
  requestUpdateToBeApprovedShippingEstimate,
  requestGetToBeApprovedShippingEstimate,
  requestCreateToBeApprovedShippingEstimate,
  patchToBeApprovedSlice, approveToBeApprovedShipmentAction,
  requestCreatePriceToBeApprovedShippingEstimate,
} from 'store/redux_slice/shipping/shippingBeforeApproveSlice';
import {
  PatchEstimation, ShippingEstimateCreatePayload,
  UpdateShippingEstimateRequest, ShipmentStructure, ExtraServiceStructure,
} from 'store/types';
import { setLoading } from 'store/redux_slice/estimationsListSlice';
import {
  GetShippingRequest,
  PostShippingRequestEstimateCreate,
  PostShippingRequestTrackingCode,
} from 'api/shipping';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import { setShipmentDataFromNotification, setShipmentEstimateFromNotification } from 'store/redux_slice/notificationsSlice';
import { ContractorTypes, DeliveryType } from 'enums';

function mergeArrays(arr1: ExtraServiceStructure[], arr2:ExtraServiceStructure[]) {
  const resultMap = new Map();
  arr2.forEach((item) => {
    resultMap.set(item.title, item);
  });
  arr1.forEach((item) => {
    if (resultMap.has(item.title)) {
      resultMap.set(item.title, {
        ...item,
        isActive: !resultMap.has(item.title) ? resultMap.get(item.title).isActive : item.isActive,
        unavailable: false,
      });
    } else {
      resultMap.set(item.title, {
        ...item,
        isActive: resultMap.has(item.title) ? resultMap.get(item.title).isActive : item.isActive,
        unavailable: true,
      });
    }
  });
  return Array.from(resultMap.values());
}

function* patchToBeApprovedShipmentSaga(action: {
    payload: PatchEstimation
}) {
  yield put(setLoading(true));
  try {
    if (action.payload.data.price_list_id && action.payload.patchListElement?.options) {
      const { data: serviceData } = yield GetPriceListById(action.payload.data.price_list_id);
      const filterSelectedServices = action.payload.patchListElement.options.filter((item) => item.isActive);
      const options = mergeArrays(filterSelectedServices, serviceData.extra_services);
      yield UpdateShippingRequest({
        ...action.payload,
        data: {
          ...action.payload.data,
          options,
        },
      });
    } else {
      yield UpdateShippingRequest(action.payload);
    }

    const { data } = (yield GetShippingRequest(action.payload.id));
    if (action.payload.recalculate) {
      const priceData = yield CreateRequestEstimate(data.id);
      yield put(patchToBeApprovedSlice({
        id: data.id,
        data: {
          ...data,
          shipping_estimate: priceData.data,
        },
      }));
      yield put(setShipmentDataFromNotification({
        ...data,
        shipping_estimate: priceData.data,
      }));
    } else {
      yield put(patchToBeApprovedSlice({
        id: data.id,
        data,
      }));
      yield put(setShipmentDataFromNotification(data));
    }
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* updateToBeApprovedShipmentEstimateSaga(action: {
    payload: UpdateShippingEstimateRequest
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield UpdateRequestEstimate(action.payload);
    yield put(patchToBeApprovedSlice({
      id: action.payload.id,
      data: {
        shipping_estimate: data,
      },
    }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* createPriceToBeApprovedShipmentEstimateSaga(action: {
  payload: ShippingEstimateCreatePayload
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield PostShippingRequestEstimateCreate(action.payload);
    if (action.payload.shipping_id) {
      yield put(patchToBeApprovedSlice({
        id: action.payload.shipping_id,
        data: {
          shipping_estimate: data,
        },
      }));
    }
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getToBeApprovedShipmentEstimateSaga(action: {
    payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetRequestEstimate(action.payload);
    yield put(patchToBeApprovedSlice({
      id: action.payload,
      data: {
        shipping_estimate: data,
      },
    }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* createToBeApprovedShipmentEstimateSaga(action: {
    payload: ShipmentStructure
}) {
  yield put(setLoading(true));
  try {
    const { data: palletData } = yield GetPalletEstimation({
      price_list_id: action.payload?.price_list_id,
      province: action.payload.delivery_type === DeliveryType.RITIRO
        ? action.payload?.pickup_address.location.province
        : action.payload?.shipping_address.location.province,
      region: action.payload.delivery_type === DeliveryType.RITIRO
        ? action.payload?.pickup_address.location.region
        : action.payload?.shipping_address.location.region,
      items: action.payload.pallets,
      options: action.payload.options,
    });
    yield UpdateShippingRequest({
      id: action.payload.id,
      data: { pallets: palletData },
    });

    const { data } = yield CreateRequestEstimate(action.payload.id);
    yield put(patchToBeApprovedSlice({
      id: action.payload.id,
      data: {
        shipping_estimate: data,
        pallets: palletData,
      },
    }));
    yield put(setShipmentEstimateFromNotification({
      shipping_estimate: data,
      pallets: palletData,
    }));
  } catch (e) {
    if (e.response.data.message === 'Epal too much!') {
      yield put(setMessage({ description: i18n.t('messages.epal_error'), title: i18n.t('messages.attention') }));
    }
  }
  yield put(setLoading(false));
}

function* approveToBeApprovedShipmentEstimateSaga(action: {
  payload: PatchEstimation
}) {
  yield put(setLoading(true));
  try {
    const { data: { contractor } } = action.payload;
    yield UpdateShippingRequest(action.payload);
    if (contractor && contractor[0].name !== ContractorTypes.Pallex) {
      yield PostShippingRequestTrackingCode(action.payload.id);
    }
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}
export function* shippingBeforeApproveSaga() {
  yield takeLatest(updateToBeApprovedShipmentAction, patchToBeApprovedShipmentSaga);
  yield takeLatest(requestUpdateToBeApprovedShippingEstimate, updateToBeApprovedShipmentEstimateSaga);
  yield takeLatest(requestGetToBeApprovedShippingEstimate, getToBeApprovedShipmentEstimateSaga);
  yield takeLatest(requestCreateToBeApprovedShippingEstimate, createToBeApprovedShipmentEstimateSaga);
  yield takeLatest(approveToBeApprovedShipmentAction, approveToBeApprovedShipmentEstimateSaga);
  yield takeLatest(requestCreatePriceToBeApprovedShippingEstimate, createPriceToBeApprovedShipmentEstimateSaga);
}
