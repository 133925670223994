import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/store';
import { requestGetContractors } from '../store/redux_slice/contractors/contractorsSlice';
import { ContractorType } from '../store/types';

export const useFilterContractors = () => {
  const contractors = useAppSelector((state) => state.contractors?.contractors);
  const [mainContractors, setMainContractors] = useState<ContractorType [] | []>([]);
  const [notMainContractors, setNotMainContractors] = useState<ContractorType[] | []>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(requestGetContractors());
  }, [dispatch]);

  useEffect(() => {
    if (contractors) {
      const filterMainContractors = contractors.filter((el) => !el.is_editable);
      const filterNotMainContractors = contractors.filter((el) => el.is_editable);
      setMainContractors(filterMainContractors);
      setNotMainContractors(filterNotMainContractors);
    }
  }, [contractors]);

  return [mainContractors, notMainContractors];
};
