import { geocodeByAddress } from 'react-places-autocomplete';
import { getAddressInfo } from 'functions';
import { Location } from 'store/types';

type FullAddressType = {
  fullAddress: string,
  placeId: string,
} | null

type ParcedAddressType = {
  address: Location,
  googleAddress: FullAddressType,
}

export const useParceAddress = (): [(value: string) => Promise<ParcedAddressType>] => {
  const parceAddressHandler = async (address: string): Promise<ParcedAddressType> => {
    const infoByCity = await geocodeByAddress(address);
    const addressInfo = await getAddressInfo({
      fullAddress: address,
      placeId: infoByCity[0].place_id,
    });
    return {
      address: addressInfo,
      googleAddress: {
        fullAddress: address,
        placeId: infoByCity[0].place_id,
      },
    };
  };

  return [parceAddressHandler];
};
