import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserPriceListById } from '../../types';

export const requestUserPriceListById = createAction<number>('requestUserPriceList');

type State = {
    priceList: UserPriceListById | null
    loading: boolean,
}

const initialState: State = {
  priceList: null,
  loading: false,
};

const userPriceListByIdSlice = createSlice({
  name: 'priceListById',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPriceListById: (state, action: PayloadAction<UserPriceListById>) => {
      state.priceList = action.payload;
    },
  },
});

export const { setPriceListById, setLoading } = userPriceListByIdSlice.actions;

export default userPriceListByIdSlice.reducer;
