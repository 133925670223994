import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { PathEnums } from 'enums';
import style from './styles.module.css';

const SideMenu = () => {
  const { t } = useTranslation();
  const navigationLinksArray = [
    {
      path: `${PathEnums.Deliveries_approved}`,
      name: t('forms.deliveries.approved'),
    },
    {
      path: `${PathEnums.Deliveries_to_be_approved}`,
      name: t('forms.deliveries.to_be_approved'),
    },
    {
      path: `${PathEnums.Deliveries_canceled}`,
      name: t('forms.deliveries.not_approved'),
    },
  ];

  return (
    <div className={style.side_menu_container}>
      { navigationLinksArray.map((item) => (
        <NavLink
          key={item.path}
          to={item.path}
          className={ ({ isActive }) => cn(style.side_menu_tab, isActive && style.side_menu_tab_active)}
        >
          <span>{item.name}</span>
        </NavLink>
      ))}
    </div>
  );
};

export default SideMenu;
