import {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import EditableTable from '../index';
import { GetPalletEstimation } from 'api';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import { useAppDispatch } from 'store/store';
import { Pallet, ExtraServiceStructure, Address } from 'store/types';
import { EditableDetailsTableTypes, DeliveryType } from 'enums';
import { ADR } from 'pesantiConstants';

type PalletsTableProps = {
  dataArray: Pallet[],
  updateOptionHandler: (data) => void,
  palletEstimate?: {
    price_list_id: number,
    pickup_address: Address,
    shipping_address: Address,
  } | null,
  editable?: boolean,
  deliveryType?: string,
  options: ExtraServiceStructure[],
}

const AdminPalletsTable = ({
  dataArray, updateOptionHandler, palletEstimate, editable = true, deliveryType, options,
}: PalletsTableProps) => {
  const dispatch = useAppDispatch();
  const [tableDataSource, setTableDataSource] = useState<Pallet[]>([]);

  const mapDataSource = useCallback((pallets: Pallet[]) => (
    pallets.map((sourceItem, i) => ({
      key: `${EditableDetailsTableTypes.admin_pallets}_${i}`,
      item_quantity: sourceItem.item_quantity,
      depth: sourceItem.depth,
      width: sourceItem.width,
      height: sourceItem.height,
      weight: sourceItem.weight,
      base_price: sourceItem.base_price || '-',
      coef: sourceItem.coef || 0,
      price: sourceItem.price || 0,
    }))
  ), []);

  useEffect(() => {
    setTableDataSource(mapDataSource(dataArray));
  }, [dataArray, mapDataSource]);

  const calculatePallets = useCallback(async (pallets: Pallet[]) => {
    let returnPallets = pallets;
    if (palletEstimate && palletEstimate?.pickup_address && palletEstimate?.shipping_address
      && palletEstimate?.price_list_id && deliveryType) {
      try {
        const { data } = await GetPalletEstimation({
          price_list_id: palletEstimate?.price_list_id,
          province: (deliveryType === DeliveryType.CONSEGNA || deliveryType === DeliveryType.TRIANG)
            ? palletEstimate?.shipping_address.location.province
            : palletEstimate?.pickup_address.location.province,
          region: (deliveryType === DeliveryType.CONSEGNA || deliveryType === DeliveryType.TRIANG)
            ? palletEstimate?.shipping_address.location.region
            : palletEstimate?.pickup_address.location.region,
          items: pallets,
          options,
        });
        returnPallets = data;
        if (updateOptionHandler) {
          updateOptionHandler({ [EditableDetailsTableTypes.admin_pallets]: data });
        }
      } catch (e) {
        dispatch(setMessage({ description: e.response.data.message }));
      }
    }
    return returnPallets;
  }, [deliveryType, dispatch, palletEstimate, updateOptionHandler, options]);

  useEffect(() => {
    const checkPrice = dataArray.some((item) => !item.price);
    if (dataArray.length && checkPrice) {
      calculatePallets(dataArray);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    palletEstimate?.shipping_address?.location.region,
    palletEstimate?.shipping_address?.location.province,
    palletEstimate?.pickup_address?.location.region,
    palletEstimate?.pickup_address?.location.province,
    deliveryType, palletEstimate?.price_list_id,
  ]);

  const parceSavedData = async (row, key: string) => {
    let initPallets:Pallet = {
      item_quantity: Math.trunc(row.item_quantity),
      depth: +row.depth,
      width: +row.width,
      height: +row.height,
      weight: +row.weight,
      price: row.price ? row.price : 0,
      adr: row.adr,
    };
    if (palletEstimate) {
      const { data } = await GetPalletEstimation({
        price_list_id: palletEstimate?.price_list_id,
        province: deliveryType === DeliveryType.RITIRO
          ? palletEstimate?.pickup_address.location.province
          : palletEstimate?.shipping_address.location.province,
        region: deliveryType === DeliveryType.RITIRO
          ? palletEstimate?.pickup_address.location.region
          : palletEstimate?.shipping_address.location.region,
        items: [initPallets],
        options,
      });
      initPallets = data[0] as Pallet;
    }
    let newDataArr:Pallet[] = [];
    const rowIsAvailable = !!dataArray.find((item, i) => `${EditableDetailsTableTypes.admin_pallets}_${i}` === key);
    if (rowIsAvailable) {
      newDataArr = dataArray.map((item, i) => (
        (`${EditableDetailsTableTypes.admin_pallets}_${i}` === key) ? {
          ...initPallets,
        } : item));
    } else {
      newDataArr = [...dataArray, initPallets];
    }
    if (updateOptionHandler) {
      updateOptionHandler({ [EditableDetailsTableTypes.admin_pallets]: newDataArr });
    }
    return newDataArr;
  };

  const deleteHandler = (key:string) => {
    if (updateOptionHandler) {
      const data = {
        [EditableDetailsTableTypes.admin_pallets]: dataArray.filter((item, i) => `${EditableDetailsTableTypes.admin_pallets}_${i}` !== key),
      };
      updateOptionHandler(data);
    }
  };

  return (
    <EditableTable
      tableType={EditableDetailsTableTypes.admin_pallets}
      dataSource={tableDataSource}
      parceSavedData={parceSavedData}
      deleteHandler={deleteHandler}
      initialFields={{
        key: `${EditableDetailsTableTypes.admin_pallets}_${tableDataSource.length}`,
      }}
      editable={editable}
      deliveryType={deliveryType}
    />
  );
};

export default AdminPalletsTable;
