import { put, takeLatest } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import {
  setLoading, requestGetTransportTrucksAction, setTrucksData,
  requestPostAssignTripToTruckAction, requestPatchTripAction,
  requestGetTripByIdAction, setTripById, setTripLoading,
  requestDeleteTripByIdAction, requestGetTripCsvAction,
  setTruckFilter, requestGetTripPdfAction, requestSplitTripAction,
} from '../redux_slice/tripsSlice';
import {
  PostAssignTripToTruck, PatchTrip, GetTripById,
  DeleteTripById, GetTripCsv, GetTrucksScarico, GetTripPdf,
  PostSplittedTrips,
} from '../../api/trips';
import { setMessage } from '../redux_slice/messageErrorSlice';
import {
  PostAssignTripPayload, PatchTripPayload, DeleteTripPayload, SplittetPalletsPayloadActionType,
  GetFileDownloadPayload, GetTrucksScaricoPayloadType,
} from '../types';

function* getTrucksSaga(action: {
  payload: GetTrucksScaricoPayloadType
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetTrucksScarico(action.payload);
    yield put(setTrucksData(data));
    yield put(setTruckFilter());
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* PostAssignTripToTruckSaga(action: {
  payload: PostAssignTripPayload
}) {
  yield put(setLoading(true));
  try {
    const {
      delivery_type: deliveryType, truck_id: truckId, filter_truck_id: filterTruckId,
      tracking_code: trackingCode, order_id: orderID, search_bar: searchBar, ...rest
    } = action.payload;
    yield PostAssignTripToTruck({
      ...rest,
      delivery_type: deliveryType,
      truck_id: truckId,
      pallets: action.payload.pallets.map((item) => ({ ...item, [`truck_id_${deliveryType?.toLowerCase()}`]: truckId })),
    });

    yield put(requestGetTransportTrucksAction({
      date_start: action.payload.date_start,
      truck_id: filterTruckId,
      tracking_code: trackingCode,
      order_id: orderID,
      search_bar: searchBar,
    }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* PatchTripSaga(action: {
  payload: PatchTripPayload
}) {
  yield put(setLoading(true));
  try {
    const {
      delivery_type: deliveryType, old_truck_id: oldTruckId, truck_id: truckId,
      tracking_code: trackingCode, order_id: orderID, search_bar: searchBar,
    } = action.payload;
    const truckIdDelivery = `truck_id_${deliveryType?.toLowerCase()}`;
    const isDeliveredPallets = `is_delivered_${deliveryType?.toLowerCase()}`;
    yield PatchTrip(action.payload.trip_id, {
      truck_id: action.payload.truck_id,
      pallets: action.payload.pallets.map((item) => (
        item[truckIdDelivery] === oldTruckId && !item[isDeliveredPallets]
          ? { ...item, [truckIdDelivery]: truckId } : item
      )),
    });
    yield put(requestGetTransportTrucksAction({
      date_start: action.payload.date_start,
      truck_id: action.payload.filter_truck_id,
      tracking_code: trackingCode,
      order_id: orderID,
      search_bar: searchBar,
    }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* GetTripByIdSaga(action: {
  payload: number
}) {
  yield put(setTripLoading(true));
  try {
    const { data } = yield GetTripById(action.payload);
    yield put(setTripById(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setTripLoading(false));
}

function* DeleteTripByIdSaga(action: {
  payload: DeleteTripPayload
}) {
  yield put(setLoading(true));
  try {
    yield DeleteTripById(action.payload.trip_id);
    yield put(requestGetTransportTrucksAction({
      date_start: action.payload.date_start,
      truck_id: action.payload.filter_truck_id,
    }));
    yield put(setTruckFilter());
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* SplitTripSaga(action: {
  payload: SplittetPalletsPayloadActionType
}) {
  yield put(setLoading(true));
  try {
    const mappedPayload = action.payload.data.map((item) => ({
      truck_id: item.truck_id,
      shipping_id: item.shipping_id,
      date_start: action.payload.date_start,
      delivery_type: item.delivery_type,
    }));
    const mappedPalletsArray = action.payload.data.map((item) => item.pallets).flat();
    yield PostSplittedTrips({
      new_trip: mappedPayload,
      pallets: [...mappedPalletsArray, ...action.payload.copyExtraPallets],
      main_trip_id: action.payload.main_trip_id,
    });
    yield put(requestGetTransportTrucksAction({
      date_start: action.payload.date_start,
      truck_id: action.payload.filter_truck_id,
      tracking_code: action.payload.tracking_code,
      order_id: action.payload.order_id,
      search_bar: action.payload.search_bar,
    }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

export function* requestGetTripCsvSaga(action: {
  payload: GetFileDownloadPayload
}) {
  try {
    const { data } = yield GetTripCsv(action.payload);
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'trips.csv');
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

export function* requestGetTripPdfSaga(action: {
  payload: GetFileDownloadPayload
}) {
  try {
    const { data } = yield GetTripPdf({
      truck_id: action.payload.truck_id,
      date_start: action.payload.date_start,
    });
    const blob = new Blob([data], { type: 'application/pdf' });
    saveAs(blob, action.payload.truck_name ? `${action.payload.truck_name}.pdf` : 'all_truks.pdf');
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

export function* tripsSaga() {
  yield takeLatest(requestGetTransportTrucksAction, getTrucksSaga);
  yield takeLatest(requestPostAssignTripToTruckAction, PostAssignTripToTruckSaga);
  yield takeLatest(requestPatchTripAction, PatchTripSaga);
  yield takeLatest(requestGetTripByIdAction, GetTripByIdSaga);
  yield takeLatest(requestSplitTripAction, SplitTripSaga);
  yield takeLatest(requestDeleteTripByIdAction, DeleteTripByIdSaga);
  yield takeLatest(requestGetTripCsvAction, requestGetTripCsvSaga);
  yield takeLatest(requestGetTripPdfAction, requestGetTripPdfSaga);
}
