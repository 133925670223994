import Profile from '../Profile';
import { RoleEnums } from '../../enums';

const UserProfile = () => (
  <Profile
    role={RoleEnums.USER}
  />
);

export default UserProfile;
