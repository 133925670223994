import { ConfigProvider } from 'antd';
import 'moment/locale/it';
import locale from 'antd/es/locale/it_IT';
import { ProtectedHeader, DefaultHeader } from 'components/Headers';
import InformationModal from 'components/InformationModal';
import CustomRoute from 'routes/Route';
import { useAppSelector } from 'store/store';

const App = () => {
  const role = useAppSelector((state) => state.auth.tokenStructure?.role);

  const renderHeader = () => {
    if (role) {
      return <ProtectedHeader type={role}/>;
    }
    return <DefaultHeader/>;
  };

  return (
    <>
      <InformationModal/>
      {renderHeader()}
      <ConfigProvider locale={locale}>
        <CustomRoute/>
      </ConfigProvider>
    </>
  );
};

export default App;
