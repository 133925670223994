import { put, select, takeLatest } from 'redux-saga/effects';
import {
  ShippingRequestListPayload, ApproveShipmentPayload,
  ShipmentStructure, PatchEstimation, ShippingRequestRefusePayload,
} from '../types';
import { UpdateShippingRequest } from 'api';
import {
  GetPrivateAreaShippingList, DeleteShippingRequest, GetShippingRequest,
  CreateShippingRequest, GetShippingRequestSendEmail,
  GetEstimationSendEmail, PrintLabel, RefusedShippingRequest,
} from 'api/shipping';
import {
  setListLoading,
  setShippingInfoLoading,
  getPrivateAreaShippingRequestListAction,
  setShipmentListData,
  deleteShippingRequestAction,
  getPrivatAreaShippingDetailsAction,
  setPrivateAreaShippingInfo,
  setCreationLoading,
  postCreateShippingAction,
  setRedirectFromDetails,
  requestUpdateShippingAction,
  setShippingPayloadData,
  requestPrintLabelAction,
  requestApprovePrivateAreaShipmentRequestAction,
  requestRefusedPrivateAreaShippingRequest,
} from 'store/redux_slice/privateAreaSlice';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import { OrderType } from 'enums';

function* getShippingRequestListSaga(action: {
  payload: ShippingRequestListPayload
}) {
  yield put(setListLoading(true));
  try {
    const { data } = yield GetPrivateAreaShippingList(action.payload);
    yield put(setShipmentListData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setListLoading(false));
}

function* deleteShippingRequestSaga(action: { payload: string }) {
  yield put(setListLoading(true));
  try {
    yield DeleteShippingRequest(action.payload);
    const { data } = yield GetPrivateAreaShippingList(action.payload);
    yield put(setShipmentListData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setListLoading(false));
}

function* getPrivatAreaShippingDetailsSaga(action: {
  payload: string
}) {
  yield put(setShippingInfoLoading(true));
  try {
    const { data } = yield GetShippingRequest(action.payload);
    yield put(setPrivateAreaShippingInfo(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setShippingInfoLoading(false));
}

function* createShippingRequestSaga(action: {
  payload: Partial<ShipmentStructure>
}) {
  yield put(setCreationLoading(true));
  try {
    const privateAreaSlice = yield select((store) => store.privateArea);
    const { data } = yield CreateShippingRequest(action.payload);
    yield put(setShippingPayloadData(data));

    if (privateAreaSlice.orderType === OrderType.ESTIMATION) {
      yield GetEstimationSendEmail(data.id);
    }
    if (privateAreaSlice.orderType === OrderType.SHIPPING) {
      yield GetShippingRequestSendEmail(data.id);
    }
    yield put(setRedirectFromDetails(true));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setCreationLoading(false));
}

function* updateShippingRequestSaga(action: {
  payload: PatchEstimation
}) {
  try {
    yield UpdateShippingRequest(action.payload);

    if (action.payload.sendEmail) {
      const privateAreaSlice = yield select((store) => store.privateArea);
      if (privateAreaSlice.orderType === OrderType.ESTIMATION) {
        yield GetEstimationSendEmail(action.payload.id);
      }
      if (privateAreaSlice.orderType === OrderType.SHIPPING) {
        yield GetShippingRequestSendEmail(action.payload.id);
      }
      yield put(setRedirectFromDetails(true));
    } else {
      const { data } = yield GetShippingRequest(action.payload.id);
      yield put(setPrivateAreaShippingInfo(data));
    }
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* refusedShippingRequestSaga(action: {
  payload: ShippingRequestRefusePayload
}) {
  yield put(setCreationLoading(true));
  try {
    yield RefusedShippingRequest({
      id: action.payload.id,
      is_by_operator: false,
    });
    yield put(setRedirectFromDetails(true));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setCreationLoading(false));
}

function* approveShipmentRequestSaga(action: {
  payload: ApproveShipmentPayload
}) {
  yield put(setCreationLoading(true));
  try {
    const { id, data: { contractor, ...rest } } = action.payload;
    yield UpdateShippingRequest({ id, data: rest });
    yield put(setRedirectFromDetails(true));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setCreationLoading(false));
}

function* printLabelSaga(action: {
  payload: string
}) {
  try {
    yield PrintLabel(action.payload);
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

export function* privateAreaSaga() {
  yield takeLatest(getPrivateAreaShippingRequestListAction, getShippingRequestListSaga);
  yield takeLatest(deleteShippingRequestAction, deleteShippingRequestSaga);
  yield takeLatest(getPrivatAreaShippingDetailsAction, getPrivatAreaShippingDetailsSaga);
  yield takeLatest(postCreateShippingAction, createShippingRequestSaga);
  yield takeLatest(requestUpdateShippingAction, updateShippingRequestSaga);
  yield takeLatest(requestPrintLabelAction, printLabelSaga);
  yield takeLatest(requestRefusedPrivateAreaShippingRequest, refusedShippingRequestSaga);
  yield takeLatest(requestApprovePrivateAreaShipmentRequestAction, approveShipmentRequestSaga);
}
