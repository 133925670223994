import {
  Pallet, PostAssignTripPayload, SplittedPalletsPayloadType, GetTrucksScaricoPayloadType, GetFileDownloadPayload,
} from '../store/types';
import { instance } from './index';

export const GetTrucksScarico = (data: GetTrucksScaricoPayloadType) => instance.get(
  '/trip/manifesto_united',
  { params: { ...data } },
);

export const PostAssignTripToTruck = (data: PostAssignTripPayload) => instance.post('/trip', data);

export const PatchTrip = (id: number, data: {
  truck_id: number,
  pallets: Pallet[],
}) => instance.patch(`/trip/${id}`, data);

export const GetTripById = (id:number) => instance.get(`/trip/${id}`);

export const DeleteTripById = (id:number) => instance.post(`/trip/del/${id}`);

export const PostSplittedTrips = (data: SplittedPalletsPayloadType) => instance.post('/trip/split', data);

export const GetTripCsv = (data: GetFileDownloadPayload) => instance.get('/trip/csv', {
  params: data,
});

export const GetTripPdf = (data: GetFileDownloadPayload) => instance.get('trip/pdf', {
  responseType: 'blob',
  params: data,
});
