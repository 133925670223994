import classnames from 'classnames';
import styles from './privacy.module.css';

const PrivacyPolicy = () => (
  <div className={styles.container}>
    <div className={classnames(styles.color_red, 'main-title-25px', styles.margin_bottom)}>
                Informativa sul trattamento dei dati personali degli Utenti del sito web
    </div>
    <p className={styles.text_left}>
          Ai sensi degli artt. 13 e 14 del Regolamento 2016/679/UE (nel seguito “GDPR”), TRASPORTI PESANTI S.R.L., con sede in
          Piadena (CR), Via XX Settembre, 6 – 26034, nella sua veste di “Titolare del trattamento” è impegnata nel rispetto e
          nella protezione della privacy dell’Utente e desidera garantire la sicurezza del trattamento dei dati forniti sia durante
          la navigazione sia tramite la registrazione all’area riservata.
    </p>
    <p className={styles.text_left}>
          La seguente informativa fornisce dettagli sul trattamento dei dati personali relativi agli Utenti che visitano o
          consultano il sito web accessibile per via telematica a partire dall’indirizzo <a target="_blank" href={'https://pallex.pesantisrl.it/'} className={styles.color_red} rel="noreferrer"> https://pallex.pesantisrl.it/</a> (il “Sito”).
          L`informativa è resa solo per il sito web di TRASPORTI PESANTI S.R.L. e non anche per altri siti eventualmente
          accessibili e consultati dall`Utente tramite link (per i quali si rinvia alle rispettive informative/policies in tema privacy).
          Relativamente ai contenuti offerti ed alle informazioni fornite, TRASPORTI PESANTI S.R.L. farà in modo di mantenere i
          contenuti del Sito ragionevolmente aggiornati e rivisti, senza offrire alcuna garanzia sull`adeguatezza, esattezza o
          completezza delle informazioni fornite declinando esplicitamente ogni responsabilità per eventuali errori d`omissione
          nelle informazioni fornite nel Sito.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Origine - Dati di navigazione
    </div>
    <p className={styles.text_left}>
          I dati personali forniti ed acquisiti contestualmente alla richiesta d’informazioni e/o contatto, registrazione al sito ed
          utilizzo dei servizi mediante smartphone o qualunque altro dispositivo, nonché i dati necessari all`erogazione di tali
          servizi, compresi i dati di navigazione e i dati utilizzati per l’eventuale acquisto dei prodotti e servizi offerti, saranno
          trattati nel rispetto della normativa applicabile.
    </p>
    <p className={styles.text_left}>
          I sistemi informatici e le procedure software utilizzate per il funzionamento del Sito Web acquisiscono, nel corso del
          loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell`uso della rete Internet. Si tratta di
          informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura
          potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti
      {/* eslint-disable-next-line react/no-unescaped-entities */}
          navigatori. In questa categoria di dati rientrano gli "indirizzi IP" o i nomi a dominio dei computer utilizzati dagli Utenti
          che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l`orario della
          richiesta, il metodo utilizzato nel sottoporre la richiesta al server web, la dimensione del file ottenuto in risposta, il
          codice numerico indicante lo stato della risposta data dal server web (buon fine, errore, ecc.) ed altri parametri relativi
          al sistema operativo ed all`ambiente informatico dell`utente. Questi dati vengono utilizzati al solo fine di ricavare
          informazioni statistiche anonime sull`uso del sito e per controllare il corretto funzionamento del sito web di
          TRASPORTI PESANTI S.R.L..
    </p>
    <p className={styles.text_left}>
          Tali dati potrebbero essere utilizzati per l`accertamento di responsabilità in caso di reati informatici ai danni del sito di
          TRASPORTI PESANTI S.R.L. o ad altri siti ad esso connessi o collegati: salva questa eventualità, allo stato i dati sui
          contatti web non persistono per più di pochi giorni.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Origine - Dati forniti dall’Utente
    </div>
    <p className={styles.text_left}>
          TRASPORTI PESANTI S.R.L. tratta i dati personali dell’Utente allo scopo di fornire i prodotti e servizi offerti sul Sito
          ovvero per obblighi di legge. Relativamente ad alcuni specifici Servizi, Prodotti, Promozioni, ecc. TRASPORTI PESANTI
          S.R.L. potrà trattare i dati anche per scopi commerciali e di marketing. In tali casi, verrà richiesto un consenso
          specifico, separato, facoltativo e sempre revocabile con le modalità e ai recapiti nel seguito indicati.

    </p>
    <p className={styles.text_left}>
          L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati nell`apposita sezione del Sito Web, la
          compilazione di questionari (es. form), la comunicazione tramite chat, push notification via APP, social network, call
          center, etc., comporta l’acquisizione di alcuni dati personali, inclusi quelli raccolti attraverso l’utilizzo di App e servizi
          correlati necessari per rispondere alle richieste. Qualora alcuni contenuti e servizi digitali siano offerti da Società
          partner di TRASPORTI PESANTI S.R.L. potrà essere necessario trasferire i dati dell’Utente.
    </p>
    <p className={styles.text_left}>
          All’interno del Sito Web l’Utente potrebbe altresì accedere ad aree dove può essere abilitato a pubblicare
          informazioni utilizzando blog o bacheche, comunicare con altri, ad esempio con Facebook®, LinkedIn®, YouTube®, ed
          altri siti di social network, rivedere prodotti e offerte e pubblicare commenti o contenuti. Prima di interagire con tali
          aree si invita a leggere attentamente le Condizioni Generali d’Utilizzo tenendo in considerazione che, in talune
          circostanze, le informazioni pubblicate possono essere visionate da chiunque abbia accesso ad Internet e tutte le
          informazioni che includi nelle tue pubblicazioni possono essere lette, raccolte ed usate da terzi.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Finalità del trattamento e base giuridica
    </div>
    <p className={styles.text_left}>
          I dati sono trattati per le seguenti finalità:
    </p>
    <p className={styles.text_left}>
          1) Quelle strettamente connesse e necessarie alla registrazione al sito <a target="_blank" href={'https://pallex.pesantisrl.it/'} className={styles.color_red} rel="noreferrer"> https://pallex.pesantisrl.it/</a>, ai servizi e/o alle
          App sviluppate o rese disponibili da TRASPORTI PESANTI S.R.L., alla fruizione dei relativi servizi informativi, alla
          gestione delle richieste di contatto o di informazioni, per l’effettuazione di acquisti di prodotti e servizi offerti;
    </p>
    <p className={styles.text_left}>
          2) Per le attività connesse alla gestione delle richieste dell’Utente/Cliente e l’invio del riscontro che può prevedere la
          trasmissione di materiale promozionale; per il perfezionamento dell’ordine di acquisto dei prodotti e servizi offerti,
          incluso gli aspetti relativi al pagamento con carta di credito, la gestione delle spedizioni, dell’eventuale esercizio
          del diritto di ripensamento previsto per gli acquisti a distanza, l’aggiornamento sulla disponibilità di prodotti e
          servizi temporaneamente non disponibili;
    </p>
    <p className={styles.text_left}>
          3) Quelle correlate all`adempimento di obblighi previsti da normative comunitarie e nazionali, alla tutela dell`ordine
          pubblico, all`accertamento e repressione dei reati;
    </p>
    <p className={styles.text_left}>
          4) Marketing diretto, ossia invio di materiale pubblicitario, vendita diretta, compimento di ricerche di mercato o
          comunicazione commerciale di prodotti e/o servizi offerti; tale attività potrà riguardare anche prodotti e servizi di
          Società del Gruppo cui TRASPORTI PESANTI S.R.L. fa parte ed essere eseguita mediante l’invio di materiale
          pubblicitario/informativo/promozionale e/o di inviti di partecipazione ad iniziative, eventi ed offerte volte a
          premiare gli Utenti/Clienti, effettuato con modalità “tradizionali” (ad esempio posta cartacea e/o chiamate da
          operatore), mediante sistemi “automatizzati” di contatto (ad esempio SMS e/o MMS, chiamate telefoniche senza
          l’intervento dell’operatore, e-mail, fax, applicazioni interattive) (art. 130 c. 1 e 2 del D. lgs. 196/03 e s.m.i.).
          Il conferimento dei dati per le finalità di cui ai punti 1), 2) e 3), connesso ad una fase pre-contrattuale e/o contrattuale
          ovvero funzionale ad una richiesta dell’Utente o prevista da una specifica previsione normativa, è obbligatorio e, in
          difetto, non sarà possibile ricevere le informazioni ed accedere ai servizi eventualmente richiesti; relativamente al
          punto 4) il consenso al trattamento dei dati da parte dell’Utente è invece libero e facoltativo e sempre revocabile
          senza conseguenze sulla utilizzabilità dei prodotti e servizi salvo l’impossibilità per TRASPORTI PESANTI S.R.L. di tenere
          aggiornati sulle nuove iniziative o su particolari promozioni o vantaggi eventualmente disponibili.
    </p>
    <p className={styles.text_left}>
          TRASPORTI PESANTI S.R.L. potrà inviare comunicazioni commerciali relative a prodotti e/o servizi analoghi a quelli già
          forniti, ai sensi della Direttiva 2002/58/UE, utilizzando le coordinate di posta elettronica o cartacee indicate, alle quali
          l’Utente potrà opporsi con le modalità e ai recapiti nel seguito.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Modalità, logiche del trattamento, tempi di conservazione e misure di sicurezza
    </div>
    <p className={styles.text_left}>
          Il trattamento è effettuato anche con l’ausilio di mezzi elettronici o automatizzati ed è svolto da TRASPORTI PESANTI
          S.R.L. e/o da Soggetti terzi di cui TRASPORTI PESANTI S.R.L. può avvalersi, per memorizzare, gestire e trasmettere i dati
          stessi. Il trattamento sarà effettuato con logiche di organizzazione ed elaborazione, anche in riferimento ai log
          originati dall’accesso e all’utilizzo dei servizi resi disponibili via web, dei prodotti e servizi fruiti, per le finalità indicate e
          in modo da garantire la sicurezza e la riservatezza dei dati. I dati personali trattati verranno conservati per i tempi
          previsti dalla normativa nel tempo applicabile.
    </p>
    <p className={styles.text_left}>
          Sempre relativamente alla sicurezza dei dati, nelle sezioni del Sito Web predisposte per particolari servizi, dove
          vengono richiesti dati personali all`Utente, questi vengono crittografati tramite una tecnologia di sicurezza chiamata
          Secure Sockets Layer, abbreviata in SSL. La tecnologia SSL codifica le informazioni prima che queste vengano
          scambiate via Internet tra l`elaboratore dell`Utente ed i sistemi centrali di TRASPORTI PESANTI S.R.L., rendendole
          incomprensibili ai non autorizzati e garantendo in questo modo la riservatezza; inoltre le transazioni effettuate
          utilizzando strumenti di pagamento elettronici sono realizzate utilizzando direttamente la piattaforma del Fornitore
          dei servizi di pagamento (PSP) e TRASPORTI PESANTI S.R.L. conserva solo il set minimo di informazioni necessario a
          gestire eventuali contestazioni. Proprio in riferimento agli aspetti di protezione dei dati personali l’Utente/Cliente è
          invitato, ai sensi dell’art. 33 del GDPR, a segnalare a TRASPORTI PESANTI S.R.L. eventuali circostanze dalle quali possa
          derivare una potenziale violazione dei dati personali (data breach), al fine di consentire una immediata valutazione e
          l’adozione di azioni volte a contrastare tale evento, inviando una comunicazione a : Mariano Abramo
          (info@pesantisrl.it) o contattando il Servizio Clienti. Nonostante le misure adottate da TRASPORTI PESANTI S.R.L.
          l’Utente dovrà prestare la necessaria attenzione all’utilizzo, ove richiesto, di password/PIN di complessità adeguata, da
          aggiornare periodicamente, soprattutto nell’ipotesi di una violazione, e da custodire con attenzione e rendere
          inaccessibili a terzi, al fine di evitarne usi impropri e non autorizzati.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Cookies
    </div>
    <p className={styles.text_left}>
          Un cookie è una breve stringa di testo che viene inviata al browser e, eventualmente, installata sul dispositivo
          dell’Utente, generalmente ogni volta che visita un sito web. TRASPORTI PESANTI S.R.L. utilizza i cookie per diverse
          finalità, allo scopo di offrire un’esperienza digitale rapida e sicura, ad esempio, permettendoti di mantenere attiva la
          connessione all’area protetta durante la navigazione attraverso le pagine del sito.
    </p>
    <p className={styles.text_left}>I cookie memorizzati sul terminale non possono accedere a nessun dato sull’hard disk, non possono trasmettere virus
          informatici o identificare ed utilizzare l’indirizzo e-mail. Ogni cookie è unico in relazione al browser e dispositivo
          utilizzati per accedere al Sito Web o per utilizzare la App di TRASPORTI PESANTI S.R.L.. La finalità dei cookie è quella di
          migliorare il funzionamento del sito web e l`esperienza dell`Utente nell`utilizzo, anche se possono essere presenti dei
          cookie con ulteriori finalità. Per maggiori informazioni su che cosa sono i cookie e come funzionano, è consultabile il
          sito web “All about cookies” <a target="_blank" href={'http://www.allaboutcookies.org'} className={styles.color_red} rel="noreferrer">http://www.allaboutcookies.org</a>.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Ambiti di comunicazione e trasferimento dei dati.
    </div>
    <p className={styles.text_left}>
          Per il perseguimento delle finalità sopra indicate, TRASPORTI PESANTI S.R.L. potrà comunicare e far trattare i dati
          personali dell’Utente a Soggetti terzi che forniscono servizi o che intrattengono con il Titolare rapporti di lavoro. A
          questi saranno comunicate solo le informazioni necessarie a effettuare i servizi richiesti e saranno adottate tutte le
          misure necessarie alla tutela dei dati personali. Questi possono essere, ad esempio, società di marketing, società di
          profilazione dati, aziende che forniscono servizi o prodotti offerti sul sito. I dati potranno essere trasferiti al di fuori
          dello Spazio Economico Europeo qualora ciò risulti necessario per la gestione del rapporto contrattuale e ai destinatari
          saranno imposti obblighi di protezione e sicurezza equivalenti a quelli garantiti dal Titolare. Nel caso di utilizzo di
          servizi offerti direttamente da Partners saranno comunicati solo i dati strettamente necessari per la loro effettuazione
          e saranno garantite le protezioni applicabili ai trasferimenti di dati verso paesi terzi. Possono essere resi noti i dati ai
          fornitori di servizi commerciali per ragioni di marketing, a tal fine nominati Responsabili esterni del trattamento.
          I dati personali potranno essere comunicati all’Autorità competente per esigenze di adempimento ad obblighi
          normativi o per l`accertamento di responsabilità in caso di reati informatici ai danni del Sito e comunicati a Soggetti
          terzi (in qualità di responsabili o, ove si tratti di fornitori di servizi di comunicazione elettronica, di autonomi titolari),
          che prestano servizi informatici e telematici (es.: servizi di hosting, di gestione e sviluppo di siti web) e di cui
          TRASPORTI PESANTI S.R.L. si avvale per lo svolgimento di compiti ed attività di natura anche tecnica ed organizzativa
          strumentali al funzionamento del Sito web. I soggetti appartenenti alle categorie sopra riportate operano come
          distinti Titolari del trattamento o in qualità di Responsabili all`uopo nominati TRASPORTI PESANTI S.R.L..
          I dati personali potranno essere conosciuti dai dipendenti e dai consulenti di TRASPORTI PESANTI S.R.L. debitamente
          istruiti e nominati Incaricati del trattamento.
    </p>
    <p className={styles.text_left}>
          Le categorie dei destinatari ai quali i dati possono essere comunicati sono disponibili rivolgendosi a TRASPORTI
          PESANTI S.R.L. ai recapiti sotto indicati.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Diritti degli Utenti
    </div>
    <p className={styles.text_left}>
          Ogni Utente può esercitare i seguenti diritti riconosciuti per legge:
    </p>
    <p className={styles.text_left}>
          a) accesso ai propri dati personali, ottenendo evidenza delle finalità perseguite da parte del Titolare, delle
          categorie di dati coinvolti, dei destinatari a cui gli stessi possono essere comunicati, del periodo di
          conservazione applicabile, dell’esistenza di processi decisionali automatizzati;
    </p>
    <p className={styles.text_left}>
          b) rettifica dei dati personali inesatti;
    </p>
    <p className={styles.text_left}>
          c) cancellazione dei dati;
    </p>
    <p className={styles.text_left}>
          d) limitazione del trattamento o opposizione allo stesso, quando possibile;
    </p>
    <p className={styles.text_left}>
          e) portabilità dei dati forniti a TRASPORTI PESANTI S.R.L., ossia ricezione in un formato strutturato, di uso
          comune e leggibile da dispositivo automatico;
    </p>
    <p className={styles.text_left}>
          f) reclamo all’Autorità Garante per la Protezione dei Dati Personali ai sensi dell’art. 77 del GDPR.
    </p>
    <p className={styles.text_left}>
          Per i trattamenti di cui alla finalità al punto 4) l’Utente potrà sempre revocare il consenso ed esercitare il diritto di
          opposizione al marketing diretto (in forma “tradizionale” e “automatizzata”). L’opposizione, in assenza di indicazione
          contraria, verrà riferita tanto alle comunicazioni tradizionali quanto a quelle automatizzate.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Titolare del Trattamento
    </div>
    <p className={styles.text_left}>
          Titolare del trattamento, ai sensi dell’art. 4 del GDPR, è TRASPORTI PESANTI S.R.L., Via XX Settembre, 6 – 26034
          Piadena (CR) P.IVA: 01509820195 - CF: 01509820195
    </p>
    <p className={styles.text_left}>
          I diritti sopra indicati possono essere esercitati su richiesta dell’Interessato con le modalità rese note dal Servizio
          Clienti o sul Sito Web ovvero utilizzando i seguenti riferimenti: Mariano Abramo (info@pesantisrl.it).
          L`uso del Sito Web da parte dell’Utente implica la presa visione del contenuto e delle eventuali indicazioni incluse in
          questa versione di informativa. La presente informativa può essere modificata senza alcun preavviso e quindi ne
          consiglia una lettura periodica.
    </p>
    <p className={styles.text_left}>
          Il Titolare del trattamento
    </p>
    <p className={styles.text_left}>
          TRASPORTI PESANTI S.R.L.
    </p>
    <p className={styles.text_left}>
          La presente informativa privacy è stata aggiornata il 10/10/2022
    </p>
    <p className={classnames(styles.text_left, styles.margin_top)}>
          TRASPORTI PESANTI S.R.L.
    </p>
    <p className={styles.text_left}>
          Via XX Settembre, 6
    </p>
    <p className={styles.text_left}>
          26034 Piadena (CR)
    </p>
    <p className={styles.text_left}>
          Email info@pesantisrl.it | PEC
    </p>
    <p className={styles.text_left}>
          trasportipesanti@lamiapec.it | Tel. 0375
    </p>
    <p className={styles.text_left}>
          758900
    </p>
    <p className={styles.text_left}>
          P. IVA 01509820195
    </p>
    <div className={classnames(styles.color_red, 'main-title-25px', styles.margin_bottom, styles.main_top)}>
        Informativa sul trattamento dei dati personali dei Clienti
    </div>
    <p className={styles.text_left}>
          Ai sensi degli artt. 13 e 14 del Regolamento 2016/679/UE (nel seguito “GDPR”) TRASPORTI PESANTI S.R.L. con
          sede in Piadena (CR), Via XX Settembre, 6 – 26034, nella sua veste di “Titolare del trattamento”, informa che i
          dati personali del Cliente, raccolti ai fini della conclusione del contratto e/o nell’ambito dell’esecuzione e/o
          della stipula dello stesso, saranno trattati nel rispetto delle normativa, al fine di garantire i diritti, le libertà
          fondamentali, la dignità, con particolare riferimento alla riservatezza e all`identità personale.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Origine, finalità, base giuridica e natura dei dati trattati
    </div>
    <p className={styles.text_left}>
          Il trattamento dei dati personali del Cliente, direttamente forniti ovvero provenienti da terzi autorizzati o
          delegati, è effettuato da TRASPORTI PESANTI S.R.L. ai fini della conclusione del contratto e/o nell’ambito
          dell’esecuzione e/o della stipula dello stesso.
    </p>
    <p className={styles.text_left}>
          Inoltre, è possibile il verificarsi di un trattamento di dati personali di terzi soggetti comunicati dal Cliente a
          TRASPORTI PESANTI S.R.L. . Rispetto a tale ipotesi, il Cliente si pone come autonomo titolare del trattamento e
          si assume i conseguenti obblighi e responsabilità legali, assicurando di aver adempiuto a quanto previsto dalla
          normativa ai fini di rendere legittimo il loro trattamento da parte di TRASPORTI PESANTI S.R.L., manlevandola
          rispetto a ogni contestazione, pretesa e/o richiesta di risarcimento del danno che dovesse pervenire a
          TRASPORTI PESANTI S.R.L. da terzi interessati.
    </p>
    <p className={styles.text_left}>
          Nel rispetto della normativa vigente in materia di protezione dei dati personali, i dati saranno archiviati,
          raccolti e trattati da TRASPORTI PESANTI S.R.L. per i seguenti fini:
    </p>
    <p className={styles.text_left}>
          a) adempimento di obblighi contrattuali, esecuzione e/o stipulazione del contratto e/o gestione di
          eventuali misure precontrattuali;
    </p>
    <p className={styles.text_left}>
          b) assolvimento di eventuali obblighi normativi, di disposizioni fiscali e tributarie derivanti dallo
          svolgimento dell’attività d’impresa e di obblighi connessi ad attività amministrativo-contabili;
    </p>
    <p className={styles.text_left}>
          Le basi giuridiche del trattamento per le finalità a) e b) sopra indicate sono gli artt. 6.1.b) e 6.1.c) del GDPR,
          ossia, rispettivamente, l’esecuzione del contratto e l’adempimento di un obbligo di legge.
    </p>
    <p className={styles.text_left}>
          Il conferimento dei dati per i suddetti fini è necessario per consentire a TRASPORTI PESANTI S.R.L. di eseguire
          e/o stipulare il contratto ed erogare le prestazioni richieste dal Cliente.
    </p>
    <p className={styles.text_left}>
          Per i trattamenti effettuati ai fini di invio diretto di proprio materiale pubblicitario o di vendita diretta o per il
          compimento di proprie ricerche di mercato o di comunicazioni commerciali in relazione a prodotti o servizi
          analoghi a quelli già utilizzati dal Cliente, TRASPORTI PESANTI S.R.L. può utilizzare gli indirizzi di posta
          elettronica o anagrafici ai sensi e nei limiti consentiti dall’art. 130, comma 4 del Codice e dal provvedimento
          dell’Autorità Garante per la protezione dei dati personali del 19 giugno 2008, anche in assenza di consenso
          esplicito (comunicazioni inerenti a beni e/o servizi acquistati di recente) . La base giuridica del trattamento dei
          dati per tale finalità è l’art. 6, comma 1, lett. f) del GDPR, ferma restando la possibilità di opporsi a tale
          trattamento in ogni momento, seguendo le indicazioni presenti alla sezione dei “Diritti del Cliente” della
          presente Informativa.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Comunicazioni
    </div>
    <p className={styles.text_left}>
          I dati potranno essere comunicati a Soggetti terzi nominati Responsabili del trattamento ai sensi dell’art. 28 del
          GDPR, quali fornitori di servizi strettamente necessari allo svolgimento dell’attività d’impresa ovvero a
          consulenti dell’azienda, ove ciò si riveli necessario per ragioni fiscali, amministrative, contrattuali o per
          esigenze tutelate dalle vigenti normative. Questi possono essere, ad esempio, il gestore del sito web, il
          commercialista, la società di gestione contabile.
    </p>
    <p className={styles.text_left}>
          I dati personali del Cliente ovvero i dati personali di terzi potranno altresì essere comunicati a società esterne,
          individuate di volta in volta, quali banche, assicurazioni, società di gestione marketing, società di trasporti, cui
          TRASPORTI PESANTI S.R.L. affidi l`esecuzione di obblighi derivanti dall’incarico ricevuto, alle quali saranno
          trasmessi i soli dati necessari alle attività loro richieste e che opereranno come Titolari autonomi del
          trattamento o come Responsabili esterni debitamente nominati.
    </p>
    <p className={styles.text_left}>
          Tutti i dipendenti, consulenti, interinali e/o ogni altra “persona fisica” che, autorizzati al trattamento, svolgono
          la propria attività sulla base delle istruzioni ricevute da TRASPORTI PESANTI S.R.L., ai sensi dell’art. 29 del
          GDPR, sono nominati “Autorizzati al trattamento”. Agli Autorizzati o ai Responsabili, eventualmente designati,
          TRASPORTI PESANTI S.R.L. impartisce adeguate istruzioni operative, con particolare riferimento all’adozione ed
          al rispetto delle misure di sicurezza, al fine di poter garantire la riservatezza e la sicurezza dei dati. Proprio in
          riferimento agli aspetti di protezione dei dati personali il Cliente è invitato, ai sensi dell’art. 33 del GDPR a
          segnalare a TRASPORTI PESANTI S.R.L. eventuali circostanze dalle quali possa discendere una potenziale
          violazione dei dati personali (data breach) al fine di consentire una immediata valutazione e l’adozione di
          azioni volte a contrastare tale evento inviando una comunicazione a TRASPORTI PESANTI S.R.L. ai recapiti nel
          seguito indicati.
    </p>
    <p className={styles.text_left}>
          I dati non saranno diffusi.
    </p>
    <p className={styles.text_left}>
          Resta fermo l`obbligo di TRASPORTI PESANTI S.R.L. di comunicare i dati alle Autorità competenti su specifica
          richiesta delle stesse.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Trasferimento all’estero
    </div>
    <p className={styles.text_left}>
          Il trasferimento all`estero dei dati personali del Cliente può avvenire qualora risulti necessario per la gestione
          dell’incarico ricevuto. Per il trattamento delle informazioni e dei dati che saranno eventualmente comunicati
          saranno richiesti gli equivalenti livelli di protezione adottati per il trattamento dei dati personali dei propri
          dipendenti. In ogni caso saranno comunicati i soli dati necessari al perseguimento degli scopi previsti e saranno
          applicati gli strumenti normativi previsti dal Capo V del GDPR.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Modalità del trattamento e tempi di conservazione
    </div>
    <p className={styles.text_left}>
          I dati sono trattati in modo lecito e secondo correttezza per le finalità sopra indicate nel rispetto dei principi
          fondamentali stabiliti dalla normativa.
    </p>
    <p className={styles.text_left}>
          Il trattamento dei dati personali può avvenire mediante dispositivi informatici, telematici ovvero in modalità
          cartacea, adottando le necessarie misure tecniche e organizzative idonee a garantire la sicurezza, la
          riservatezza e la riduzione dei rischi di distruzione o perdita, anche accidentale, di accesso non autorizzato o di
          trattamento illecito.
    </p>
    <p className={styles.text_left}>
          I dati verranno trattati da TRASPORTI PESANTI S.R.L. per tutta la durata del rapporto e successivamente per il
          tempo necessario a far valere i propri diritti ovvero per finalità amministrative e/o per dare esecuzione ad
          obblighi di legge.
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Diritti del Cliente
    </div>
    <p className={styles.text_left}>
          Al Cliente vengono riconosciuti i seguenti diritti previsti dal GDPR:
    </p>
    <p className={styles.text_left}>
          a) accesso ai dati personali e a qualsiasi informazione inerente al loro trattamento;
    </p>
    <p className={styles.text_left}>
          b) rettifica dei dati personali inesatti e notifica della rettifica ai terzi cui siano stati trasmessi;
    </p>
    <p className={styles.text_left}>
          c) cancellazione dei dati e notifica della cancellazione ai terzi cui siano stati trasmessi;
    </p>
    <p className={styles.text_left}>
          d) limitazione del trattamento e opposizione (quando possibile);
    </p>
    <p className={styles.text_left}>
          e) portabilità dei dati da TRASPORTI PESANTI S.R.L. ad altro Titolare del trattamento o ricezione in un
          formato strutturato, di uso comune e leggibile da dispositivo automatico;
    </p>
    <p className={styles.text_left}>
          f) reclamo all’Autorità Garante per la Protezione dei Dati Personali.
    </p>
    <p className={styles.text_left}>
          Per ottenere maggiori informazioni sul trattamento dei propri dati personali o per esercitare i propri diritti, è
          possibile contattare Mariano Abramo (<a href="mailto:info@pesantisrl.it" className={styles.color_red}>info@pesantisrl.it</a>).
    </p>
    <div className={classnames(styles.margin_top, 'title-20px')}>
          Titolare del Trattamento
    </div>
    <p className={styles.text_left}>
          Titolare del trattamento, ai sensi dell’art. 4 del GDPR, è TRASPORTI PESANTI S.R.L., Via XX Settembre, 6 –
          26034 Piadena (CR)
    </p>
    <p className={styles.text_left}>
          P.IVA: 01509820195 - CF: 01509820195
    </p>
    <p className={styles.text_left}>
          Il Titolare del trattamento
    </p>
    <p className={styles.text_left}>
          TRASPORTI PESANTI S.R.L.
    </p>
    <p className={styles.text_left}>
          10/10/2022
    </p>
  </div>
);

export default PrivacyPolicy;
