import { put, takeLatest } from 'redux-saga/effects';
import { CreateOperatorPayload, UpdateUserPayload } from '../../types';
import {
  setLoading, setOperator, requestCreateOperator, requestUpdateOperator, requestDeleteOperator, requestGetOperator,
} from '../../redux_slice/operators/operatorSlice';
import {
  CreateNewUser, GetAllUsers, DeleteUserById, UpdateUser, GetUserById,
} from '../../../api/users';
import { setOperatorsList } from '../../redux_slice/operators/operatorsListSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* createOperatorSaga(action: {
    payload: {
        user: CreateOperatorPayload,
        isGetAllUsers: boolean,
    }
}) {
  yield put(setLoading(true));
  try {
    (yield CreateNewUser(action.payload.user));
    const { data } = (yield GetAllUsers(action.payload.isGetAllUsers));
    yield put(setOperatorsList(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getOperatorSaga(action: {
    payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetUserById(action.payload));
    yield put(setOperator(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* deleteOperatorSaga(action: {
    payload: {
        id: string,
        isGetAllUsers: boolean,
    }
}) {
  yield put(setLoading(true));
  try {
    (yield DeleteUserById(action.payload.id));
    const { data } = (yield GetAllUsers(action.payload.isGetAllUsers));
    yield put(setOperatorsList(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* updateOperatorSaga(action: {
    payload: {
        user: UpdateUserPayload,
        isGetAllUsers: boolean,
    }
}) {
  yield put(setLoading(true));
  try {
    (yield UpdateUser(action.payload.user));
    const { data } = (yield GetAllUsers(action.payload.isGetAllUsers));
    yield put(setOperatorsList(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* operatorSaga() {
  yield takeLatest(requestCreateOperator, createOperatorSaga);
  yield takeLatest(requestGetOperator, getOperatorSaga);
  yield takeLatest(requestDeleteOperator, deleteOperatorSaga);
  yield takeLatest(requestUpdateOperator, updateOperatorSaga);
}
