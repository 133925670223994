import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, InputNumber, Modal, Row, Select, DatePicker,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import style from './transports.module.css';
import './modal.css';
import RadioButton from '../../components/RadioButton';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { RoleEnums } from '../../enums';
import TransportTypesSelect from './transportTypes/TransportTypesSelect';
import {
  clearTransportByDriverId,
  requestGetTransportByDriverId,
} from '../../store/redux_slice/transport/transportByDriverIdSlice';
import ConfirmDriverModal from './ConfirmDriverModal';

const NewTransportForm:React.FC<{
  visible: number | null,
  onCancel: () => void,
  onFinish: (values) => void,
  truckTypeId: string,
  setTruckTypeId: (value: string) => void,
}> = ({
  visible, onCancel, onFinish, truckTypeId, setTruckTypeId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const operatorsList = useAppSelector((state) => state.operatorsList.operatorsList);
  const transportById = useAppSelector((state) => state.transportById.transportById);
  const transportByDriverId = useAppSelector((state) => state.transportByDriverId.transportByDriverId);
  const [busyTransport, setBusyTransport] = useState<boolean>(false);
  const contractors = useAppSelector((state) => state.contractors.contractors);
  const drivers = operatorsList?.filter((el) => el.role === RoleEnums.DRIVER);
  const [form] = Form.useForm();

  useEffect(() => {
    if (transportByDriverId) {
      setBusyTransport(true);
    }
  }, [transportByDriverId]);
  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue({
        license_plate: transportById?.plate_number.toUpperCase(),
        type: transportById?.truck_type?.id,
        description: transportById?.note,
        drivers_name: transportById?.driver?.id ?? null,
        contractor: transportById?.contractor,
        number_pallets_max: transportById?.pallet_capacity,
        available: transportById?.busy ? 'No' : 'Yes',
        available_days: transportById?.busy && transportById?.booking_date ? moment(transportById?.booking_date) : '',
      });
    }
  }, [form, transportById, visible]);

  useEffect(() => {
    if (truckTypeId) {
      form.setFieldsValue({
        type: truckTypeId,
      });
    }
  }, [form, truckTypeId]);
  const onCheckFormChange = (e) => {
    const driverNameChange = e[0].name.find((name) => name === 'drivers_name');
    const checkDriverName = form.getFieldValue('drivers_name');
    if (driverNameChange && checkDriverName !== transportById?.driver?.id) {
      dispatch(requestGetTransportByDriverId(checkDriverName));
    }
  };
  const onOkModal = () => {
    setBusyTransport(false);
  };

  const onNoModal = () => {
    setBusyTransport(false);
    dispatch(clearTransportByDriverId());
    form.setFieldsValue({
      drivers_name: transportById?.driver?.id || null,
    });
  };

  return (
    <Modal
      centered
      visible={!!visible}
      onCancel={onCancel}
      footer={false}
      width={'40%'}
      className="transports_modal"
    >
      <ConfirmDriverModal
        visible={busyTransport}
        title={t('messages.attention')}
        description={t('messages.assign_driver')}
        onOk={onOkModal}
        onCancel={onOkModal}
        onNo={onNoModal}
      />
      <div className={style.transports_modal}>
        <div className="main-title-30px">
          {transportById ? t('forms.transports.update_transport') : t('forms.transports.add_new_medium')}
        </div>
        <Form
          key={`${transportById?.id}-${visible}`}
          form={form}
          className={style.form_modal}
          name="transports_form"
          layout="vertical"
          onFinish={onFinish}
          onFieldsChange={(e) => onCheckFormChange(e)}
        >
          <Form.Item
            label= {t('forms.transports.license_plate')}
            name="license_plate"
            rules={
              [
                { required: true, message: t('validation.empty') },
                { whitespace: true, message: t('validation.empty') },
              ]
            }
          >
            <Input
            />
          </Form.Item>
          <Form.Item
            label={t('forms.transports.type')}
            name="type"
            rules={
              [
                { required: true, message: t('validation.empty') },
              ]
            }
          >
            <TransportTypesSelect
              truckTypeId={truckTypeId}
              setTruckTypeId={setTruckTypeId}
            />
          </Form.Item>
          <Form.Item
            label={t('forms.transports.transport_description')}
            name="description"
            rules={
              [
                { required: true, message: t('validation.empty') },
                { whitespace: true, message: t('validation.empty') },
              ]
            }
          >
            <Input
            />
          </Form.Item>
          <Form.Item
            label={t('forms.transports.drivers_name')}
            name="drivers_name"
          >
            <Select>
              {
                drivers?.map((i) => (
                  <Option value={i.id} key={i.id}>{i.name}</Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            label={t('forms.shipmentDetails.agency')}
            name="contractor"
            rules={
              [
                { required: true, message: t('validation.empty') },
              ]
            }
          >
            <Select
            >
              {
                contractors?.map((i) => (
                  <Option value={i.name} key={i.id}>{i.name}</Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            label={t('forms.transports.max_transport_number')}
            name="number_pallets_max"
            rules={
              [
                { required: true, message: t('validation.empty') },
              ]
            }
          >
            <InputNumber
              min={1}
              className={style.max_width}
            />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                name="available"
                label={t('forms.transports.available')}
              >
                <Row>
                  <Col span={6}>
                    <RadioButton
                      checked={!transportById?.busy}
                      title={t('forms.transports.yes')}
                      value="Yes"
                      id="1"
                      name="busy"
                    />
                  </Col>
                  <Col span={6} offset={1}>
                    <RadioButton
                      checked={transportById?.busy}
                      title={t('forms.transports.no')}
                      value="No"
                      id="2"
                      name="busy"
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                noStyle={true}
                shouldUpdate={
                  (prev, curr) => prev.available !== curr.available
                }
              >
                {({ getFieldValue }) => getFieldValue('available') === 'No' && <Form.Item
                  name="available_days"
                  label={t('forms.transports.available_in')}
                >
                  <DatePicker placeholder={t('forms.transports.number_days')}
                    disabledDate={(current) => (
                      current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                    )}
                    className={style.calendar_height}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>}
              </Form.Item>
            </Col>
          </Row>
          <CustomButton
            className={classnames('extra-bolt-corsivo', style.add_transport_button)}
            htmlType="submit"
            type="primary"
          >
            {transportById ? t('forms.transports.update') : t('forms.transports.add_transport_type')}
          </CustomButton>
        </Form>
      </div>
    </Modal>
  );
};

export default NewTransportForm;
