import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { UsersListPayload, UsersListStructure } from '../../types';

export const requestUsersCompanyList = createAction<UsersListPayload>('requestUsersCompanyList');

type State = {
    usersCompanyList: UsersListStructure | null
    loading: boolean,
}
const initialState : State = {
  usersCompanyList: null,
  loading: false,
};

const usersCompanyListSlice = createSlice({
  name: 'usersCompanyList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCompanyUsersList: (state, action: PayloadAction<UsersListStructure>) => {
      state.usersCompanyList = action.payload;
    },
  },
});

export const { setCompanyUsersList, setLoading } = usersCompanyListSlice.actions;

export default usersCompanyListSlice.reducer;
