import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import React from 'react';
import OptionsButton from 'components/OptionsButton';
import { operatorListTableColumns } from './TableColumns';
import { User, UserStructure } from 'store/types';
import { useAppDispatch } from 'store/store';
import { requestGetOperator } from 'store/redux_slice/operators/operatorSlice';
import { RoleEnums } from 'enums';
import lockIcon from 'assests/images/lockIcon.svg';

const OperatorsListTable:React.FC<{
  operatorsList: UserStructure[],
  setDeleteId: (id: string) => void,
  setIsDeleteModal: (bool: boolean) => void,
  setOpenModal: (bool: boolean) => void,
  userData: User | null
}> = ({
  operatorsList, setDeleteId, setIsDeleteModal, setOpenModal, userData,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const openDeleteModal = (id: string) => {
    setIsDeleteModal(true);
    setDeleteId(id);
  };
  const openUpdateOperatorForm = (id: string) => {
    setOpenModal(true);
    dispatch(requestGetOperator(id));
  };
  const options = [
    {
      title: t('forms.users.update'),
      action: (id: string) => openUpdateOperatorForm(id),
    },
    {
      title: t('forms.users.delete'),
      action: (id: string) => openDeleteModal(id),
    },
  ];
  const renderStatus = (role) => {
    if (role === RoleEnums.ADMIN) {
      return t('roles.administrator');
    }
    if (role === RoleEnums.OPERATOR) {
      return t('roles.operator');
    }
    if (role === RoleEnums.BILLING_OPERATOR) {
      return t('roles.billing_operator');
    }
    if (role === RoleEnums.ESTIMATING_OPERATOR) {
      return t('roles.estimating_operator');
    }
    if (role === RoleEnums.DRIVER) {
      return t('roles.driver');
    }
    return '';
  };
  const renderOptions = (id) => {
    if (Number(id) === userData?.id) {
      return <img src={lockIcon} alt={lockIcon}/>;
    }
    return <OptionsButton id={id} options={options}/>;
  };

  const dataSource = operatorsList?.map((i) => (
    {
      key: i.id,
      creation_date: i.createdAt,
      email: i.email,
      phone: i.phone,
      name: i.name,
      status: renderStatus(i.role),
      options: renderOptions(i.id.toString()),
    }
  ));
  const columns = operatorListTableColumns(t);
  return (
    <Table columns={columns} dataSource={dataSource} pagination={false}/>
  );
};
export default OperatorsListTable;
