import { useState } from 'react';

export enum DrawerType {
  openNotifications,
}

export const useDrawerState = () => {
  const [state, setState] = useState<DrawerType|null>(null);
  const closeDrawer = () => setState(null);
  const isOpen = (modal:DrawerType) => state === modal;
  return {
    openDrawer: setState,
    closeDrawer,
    isOpen,
  };
};
