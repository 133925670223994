import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExtraServiceStructure } from '../types';

export const requestGetServicesByPriceList = createAction<number>('requestGetServicesByPriceList');

type State = {
    services: ExtraServiceStructure[] | null
    loading: boolean,
}

const initialState: State = {
  services: null,
  loading: false,
};

const shipmentSlice = createSlice({
  name: 'ExtraServicesByPriceList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setServicesByPriceListData: (state, action: PayloadAction<ExtraServiceStructure[] | null>) => {
      state.services = action?.payload || null;
    },
  },
});

export const { setServicesByPriceListData, setLoading } = shipmentSlice.actions;

export default shipmentSlice.reducer;
