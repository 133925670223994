import { useEffect, useMemo, useState } from 'react';
import {
  Col,
  Form,
  InputNumber,
  Modal, Row, Select,
  Table,
} from 'antd';
import * as _ from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import CustomButton from 'components/CustomButton/CustomButton';
import { EditableCell, EditableRow } from './EditableCell';
import TableFooter from './Footer/TableFooter';
import {
  columnsPrices, ColumnTypes, DataType,
} from './TableColumns';
import { useAppDispatch, useAppSelector } from 'store/store';
import { requestPricelists, requestPrices } from 'store/redux_slice/pricelists/pricelistsSlice';
import { requestGetShippingServices } from 'store/redux_slice/shippingServicesSlice';
import { setServicesByPriceListData } from 'store/redux_slice/extraByPriceListSlice';
import style from './createdTable.module.css';

const CreatedTable = ({
  currentPricelist,
  listOfChanged,
  updateListOfChanged,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const [addItem, setAddItem] = useState<boolean>(false);
  const [variantPrice, setVariantPrice] = useState<string>('€');
  const [form] = Form.useForm();
  const newExtraServices = useAppSelector((state) => state.extraServicesByPriceList.services);
  const extra = useAppSelector((state) => state.extraServices?.shippingServices);
  const pricelist = useAppSelector(
    (state) => state.pricelists,
  );

  const optionsToSelect = useMemo(() => (
    extra?.filter((extreItem) => !newExtraServices?.some((listItem) => (
      extreItem.title.toLocaleUpperCase() === listItem.title.toLocaleUpperCase()
    )))
  ), [newExtraServices, extra]);

  const tableData = useMemo(() => {
    const arr:DataType[] = [];
    // eslint-disable-next-line guard-for-in
    for (const key in currentPricelist.price_list_params) {
      currentPricelist.price_list_params[key].forEach((item: DataType, index: number) => {
        arr.push({
          ...item,
          key: `${key}-${index}`,
          region: key,
          rowSpan: index ? 0 : (currentPricelist.price_list_params[key].length || 1),
        });
      });
    }
    return arr;
  }, [currentPricelist?.price_list_params]);

  useEffect(() => {
    dispatch(requestPrices());
    dispatch(requestPricelists());
    dispatch(requestGetShippingServices());
  }, [dispatch]);

  if (!pricelist
    || !currentPricelist
    || !pricelist.pricelists
    || !pricelist.prices
    || pricelist.loading
  ) {
    return <Loader/>;
  }

  const selectAfter = (
    <Select
      defaultValue={variantPrice}
      value={variantPrice}
      className="select-after"
      onChange={(value: string) => setVariantPrice(value)}
    >
      <Option value="€">€</Option>
      <Option value="%">%</Option>
    </Select>
  );
  const onFinish = (values) => {
    setAddItem(false);
    form.resetFields();
    const newService = {
      price: values.price ? `${values.price} ${variantPrice}` : '-',
      title: values.service_name,
      description: values.service_details,
      category: values.apply_to,
      fixedMin: (variantPrice === '%' && values.fixedMin) ? `${values.fixedMin}€` : '',
      isActive: false,
    };
    const extraServices = [
      ...newExtraServices || [],
      {
        id: newExtraServices && newExtraServices.length > 0
          ? Math.max(...newExtraServices.map((item) => item.id)) + 1
          : 1,
        ...newService,
      },
    ];
    dispatch(setServicesByPriceListData(extraServices));
  };

  const onSelectOption = (serviceTitle: string) => {
    const findOption = optionsToSelect?.find((item) => (
      item.title.toLocaleUpperCase() === serviceTitle.toLocaleUpperCase()
    ));
    if (findOption) {
      const findPercent = findOption.price.includes('%') ? findOption.price.split('%')[0] : '';
      const findEuro = findOption.price.includes('€') ? findOption.price.split('€')[0] : '';
      setVariantPrice(findPercent ? '%' : '€');
      form.setFieldsValue({
        service_details: findOption.description,
        price: findPercent || findEuro,
        apply_to: findOption.category,
      });
    }
  };

  const onSearchOption = _.debounce((value) => {
    if (value) {
      form.setFieldsValue({
        service_name: value,
      });
    }
  }, 500);

  const onCancelModal = () => {
    setAddItem(false);
    form.resetFields();
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = columnsPrices.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: updateListOfChanged,
        listOfChanged,
      }),
    };
  });

  return (
    <>
      <Table
        className={style.priceListTable}
        dataSource={tableData}
        pagination={false}
        columns={columns as ColumnTypes}
        components={components}
        footer={() => <TableFooter
          type={'pricelist'}
          currentPricelist={currentPricelist}
        />}
      />
      <CustomButton
        className={style.modalBtn}
        type="primary"
        onClick={() => setAddItem(true)}
      >
        {t('buttons.add')}
      </CustomButton>
      <Modal
        className={style.modal}
        visible={addItem}
        mask={false}
        onOk={() => setAddItem(false)}
        onCancel={onCancelModal}
        footer={[]}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label={t('pricelist.service')}
            name="service_name"
            rules={
              [
                { required: true, message: t('validation.empty') },
                { whitespace: true, message: t('validation.empty') },
              ]
            }
          >
            <Select
              showSearch
              className="select-after"
              onChange={onSelectOption}
              onSearch={onSearchOption}
            >
              { optionsToSelect?.map((item) => (
                <Option key={item.id} value={item.title}>{item.title}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('forms.newEstimation.service_details')}
            name="service_details"
            rules={
              [
                { required: true, message: t('validation.empty') },
                { whitespace: true, message: t('validation.empty') },
              ]
            }
          >
            <TextArea
              aria-multiline
            />
          </Form.Item>
          <Row>
            <Col span={7}>
              <Form.Item
                label={t('forms.newEstimation.price')}
                name="price"
              >
                <InputNumber
                  style={{ width: 150 }}
                  addonAfter={selectAfter}
                  controls={false}
                />
              </Form.Item>
            </Col>

            <Col span={7} offset={1}>
              <Form.Item
                label={t('forms.newEstimation.fixed_minimum')}
                name="fixedMin"
              >
                <InputNumber
                  style={{ width: 150 }}
                  addonAfter={<span>€</span>}
                  disabled={variantPrice === '€'}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                name="apply_to"
                label={t('forms.newEstimation.apply_to')}
                rules={
                  [
                    { required: true, message: t('validation.empty') },
                  ]
                }
              >
                <Select
                  aria-label={t('pricelist.apply')}
                >
                  <Option value="TOTAL">{t('pricelist.total')}</Option>
                  <Option value="PALLET">{t('pricelist.pallet')}</Option>
                  <Option value="ESTERNAMENTE">{t('pricelist.esternamente')}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <CustomButton
            type="primary"
            htmlType="submit"
          >
            {t('pricelist.confirmUpdate')}
          </CustomButton>
        </Form>
      </Modal>
    </>
  );
};

export default CreatedTable;
