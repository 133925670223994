import { useTranslation } from 'react-i18next';
import Banner from '../../components/Banner/Banner';
import LoginForm from './LoginForm';

const LoginContainer = () => {
  const { t } = useTranslation();
  return (
    <>
      <Banner title={t('banner.logIn')}></Banner>
      <LoginForm/>
    </>
  );
};
export default LoginContainer;
