import { useEffect } from 'react';
import {
  Row, Col, Form, Input, Modal, Select, DatePicker,
} from 'antd';
import moment from 'moment';
import { formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import Loader from '../../../../components/Loader';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { postExtraFinanceAction, patchExtraFinanceAction } from '../../../../store/redux_slice/financeSlice';
import { ExtraFinanceType } from '../../../../store/types';
import styles from './extraFinanceModal.module.css';

type ExtraFinanceModalType = {
  visible: boolean,
  onCancel: () => void,
  title: string,
  success: boolean,
  extraFinanceEdit: ExtraFinanceType | null,
}

const { Option } = Select;
const { TextArea } = Input;

const ExtraFinanceModal = ({
  visible, onCancel, title, success, extraFinanceEdit,
}: ExtraFinanceModalType) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { selectIds, extraFinanceModalLoading } = useAppSelector((state) => state.financeSection);

  const isNumberValidator = (c, v) => (v && !Number.isNaN(v) && v > 0 ? Promise.resolve() : Promise.reject());

  useEffect(() => {
    if (extraFinanceEdit) {
      form.setFieldsValue({
        orderId: extraFinanceEdit?.orderId,
        approvato_il: moment(extraFinanceEdit?.approvato_il),
        quantity: extraFinanceEdit?.quantity,
        price: extraFinanceEdit?.price,
        description: extraFinanceEdit?.description,
      });
    }
  }, [extraFinanceEdit, form]);

  useEffect(() => {
    if (success) {
      form.resetFields();
    }
  }, [success, form]);

  const onFinish = (data) => {
    const newData = {
      ...data,
      price: +data.price,
      quantity: Math.trunc(data.quantity),
      approvato_il: formatISO(new Date(data.approvato_il)),
    };
    if (extraFinanceEdit) {
      dispatch(patchExtraFinanceAction({
        id: extraFinanceEdit.id,
        data: newData,
      }));
    } else {
      dispatch(postExtraFinanceAction(newData));
    }
  };

  const onSelectOrderId = (selectedOrderId) => {
    form.setFieldsValue({
      approvato_il: moment(selectIds?.find((item) => item.orderId === selectedOrderId)?.approvato_il),
    });
  };

  const onCancelModalHandler = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancelModalHandler}
      footer={false}
    >
      { extraFinanceModalLoading ? <Loader/> : (
        <>
          <h3 className={cn(styles.title, 'main-title-30px')}>
            {title}
          </h3>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Row>
              <Col span={11}>
                <Form.Item
                  label={t('invoices.table.orderId')}
                  name="orderId"
                  rules={
                    [{ required: true, message: t('invoices.field_is_required') }]
                  }
                >
                  <Select
                    className={styles.select_form_item}
                    onChange={onSelectOrderId}
                    disabled={extraFinanceEdit?.isApproved}
                  >
                    { selectIds?.map((item) => (
                      <Option key={item.orderId} value={item.orderId}>{item.shippingId}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  label={t('invoices.table.approvato_il')}
                  name="approvato_il"
                  rules={
                    [{ required: true, message: t('invoices.field_is_required') }]
                  }
                >
                  <DatePicker
                    className={cn(styles.date_input, styles.form_item)}
                    format="DD/MM/YYYY"
                    disabledDate={(current) => (
                      current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                    )}
                    disabled={extraFinanceEdit?.isApproved}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  label={t('invoices.table.quantity')}
                  name="quantity"
                  rules={[
                    { required: true, message: t('invoices.field_is_required') },
                    { validator: isNumberValidator, message: t('invoices.table.isNumberValidation') },
                  ]}
                >
                  <Input
                    className={styles.form_item}
                    disabled={extraFinanceEdit?.isApproved}
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  label={t('invoices.table.unit_cost')}
                  name="price"
                  rules={[
                    { required: true, message: t('invoices.field_is_required') },
                    { validator: isNumberValidator, message: t('invoices.table.isNumberValidation') },
                  ]}
                >
                  <Input
                    className={styles.form_item}
                    disabled={extraFinanceEdit?.isApproved}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t('invoices.table.description')}
              name="description"
              rules={
                [{ required: true, whitespace: true, message: t('invoices.field_is_required') }]
              }
            >
              <TextArea
                rows={4}
                className={styles.form_item}
                disabled={extraFinanceEdit?.isApproved}
              />
            </Form.Item>
            { !extraFinanceEdit?.isApproved && (
              <CustomButton
                type="primary"
                htmlType="submit"
                className={styles.submit_btn}
              >
                {t('invoices.add_extra')}
              </CustomButton>
            )}
          </Form>
        </>
      )}
    </Modal>
  );
};

export default ExtraFinanceModal;
