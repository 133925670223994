import { put, takeLatest } from 'redux-saga/effects';
import { GetFacchinaggio } from '../../../api/pallex';
import { setLoading, requestGetFacchinaggio, setFacchinaggioSliceData } from '../../redux_slice/pallex/facchinaggioSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* getFacchinaggioSaga() {
  yield put(setLoading(true));
  try {
    const { data } = yield GetFacchinaggio();
    yield put(setFacchinaggioSliceData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* facchinaggioSaga() {
  yield takeLatest(requestGetFacchinaggio, getFacchinaggioSaga);
}
