import React from 'react';
import { Col, Row } from 'antd';
import CustomButton from '../CustomButton/CustomButton';
import editIcon from '../../assests/images/edit_icon.svg';
import deleteIcon from '../../assests/images/delete_icon.svg';
import style from '../../containers/Estimations/estimations.module.css';

export interface EditableOptionElement {
  label: string,
  value: string,
  isEditable: boolean,
  onCheck: (value: string) => void;
  onEdit: (value: string) => void;
  onDelete: (value: string) => void;
}

export const EditableOption: React.FC<EditableOptionElement> = ({
  label, value, isEditable, onCheck, onEdit, onDelete,
}) => (
  <Row>
    <Col span={20} onClick={() => { onCheck(value); }}>
      <div>{label}</div>
    </Col>
    { isEditable && <Col span={4}>
      <Row justify="end">
        <Col span={10}>
          <CustomButton
            type="text"
            icon={<img src={editIcon} alt="edit"/>}
            onClick={() => onEdit(value)}>
          </CustomButton>
        </Col>
        <Col span={10} offset={2}>
          <CustomButton
            type="text"
            icon={<img src={deleteIcon} alt="delete" className={style.button_img}/>}
            onClick={() => onDelete(value)}>
          </CustomButton>
        </Col>
      </Row>
    </Col>}
  </Row>
);
