import React, { useEffect, useState } from 'react';
import {
  Col, Modal, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import CustomButton from '../../../components/CustomButton/CustomButton';
import style from '../estimations.module.css';
import { ContractorsSelect } from '../../NewEstimation/ContractorsSelect';
import { ContractorType, PatchEstimation, ShipmentStructure } from '../../../store/types';
import { DeliveryType } from '../../../enums';
import { renderContractorShow, selectContractorType } from '../../../functions';

interface Props {
  id: string;
  visible: boolean;
  initialValue: string | number;
  onCancel: () => void;
  onEdit: () => void;
  onRequestUpdateEstimation: (data: PatchEstimation) => void;
  deliveryType: string,
  estimation: ShipmentStructure,
}

export const ChangeContractorModal: React.FC<Props> = ({
  id, visible, initialValue, onCancel, onRequestUpdateEstimation, estimation,
  deliveryType,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const contractors = useAppSelector((state) => state.contractors?.contractors);
  const [value, setValue] = useState(initialValue);
  const [newContractors, setNewContractors] = useState<ContractorType[] | null>(null);
  const pallexItalia = contractors.find((el) => el.name === 'Pallex Italia');
  const defaultSelect = contractors.find((el) => el.id === initialValue);
  const initialSelect = defaultSelect?.name !== pallexItalia?.name ? defaultSelect?.name : '';

  useEffect(() => {
    if (contractors) {
      const newContractor = contractors.filter((el) => el.name !== pallexItalia?.name);
      setNewContractors(newContractor);
    }
  }, [contractors, dispatch, pallexItalia?.name]);

  const submit = () => {
    const selectedTypes = selectContractorType(Number(value), deliveryType);
    const contractor = contractors.find((el) => el.id === Number(value));
    if (contractor) {
      const data = {
        contractor: [
          {
            ResContract: {
              request_id: estimation?.contractor[0]?.ResContract
                ? estimation.contractor[0].ResContract.request_id : estimation.id,
              contractor_id: Number(value),
              type: selectedTypes[0],
              show: renderContractorShow(estimation?.contractor[0]?.ResContract.show, deliveryType, contractor.is_editable),
              del_date: estimation?.contractor[0]?.ResContract ? estimation.contractor[0].ResContract.del_date : null,
            },
          },
        ],
        sub_contractor: estimation.delivery_type === DeliveryType.TRIANG
          ? [
            {
              ResSubContract: {
                request_id: estimation?.contractor[0]?.ResContract
                  ? estimation.contractor[0].ResContract.request_id : estimation.id,
                contractor_id: Number(value),
                type: selectedTypes[1],
                show: renderContractorShow(estimation.contractor[0]?.ResContract.show, deliveryType, contractor.is_editable) ?? false,
                del_date: estimation?.contractor[0]?.ResContract ? estimation.contractor[0]?.ResContract.del_date : null,
              },
            },
          ]
          : undefined,
        tracking: contractor.name === 'Pallex TP' ? 'Disponsible' : undefined,
      };
      if (!estimation?.contractor[0]?.ResContract.contractor_id
          || estimation.contractor[0].ResContract.contractor_id !== Number(value)) {
        onRequestUpdateEstimation({
          id,
          data,
        });
      }
      onCancel();
    }
  };

  const onCheckHandler = (contractorId: string | number) => {
    const contractor = contractors.find((c) => c.id === contractorId);
    if (contractor) {
      setValue(contractor.id);
    }
  };

  return (
    <Modal title={t('forms.estimations.update_contractor')}
      visible={visible}
      onOk={submit}
      onCancel={onCancel}
      footer={false}
    >
      <Row>
        <Col span={24}>
          <ContractorsSelect
            initialValue={initialSelect || ''}
            onChange={onCheckHandler}
            contractors={newContractors || []}
          />
        </Col>
      </Row>
      <CustomButton
        className={style.modal_button}
        type="primary"
        onClick={submit}
      >
        {t('forms.estimations.update')}
      </CustomButton>
    </Modal>
  );
};
