import { useEffect, useState } from 'react';
import cn from 'classnames';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, Select, DatePicker,
} from 'antd';
import ChartTotalNumberOfQuotes from './ChartTotalNumberOfQuotes';
import ChartNumberOfPalletsDuringPeriod from './ChartNumberOfPalletsDuringPeriod';
import ChartNumberOfDeliveryOrCollectionDelays from './ChartNumberOfDeliveryOrCollectionDelays';
import ChartTimeOrderRemainsInTheWarehouse from './ChartTimeOrderRemainsInTheWarehouse';
import {
  getDashboardArchivedAction, getDashboardPalletsAction,
  getDashboardCollectedAction, getDashboardWarehouseAction,
} from '../../store/redux_slice/dashboardSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import style from './dashboard.module.css';

type Props = {
  type: 'archived' | 'pallet' | 'collected' | 'warehouse'
}

type SelectPalletsType = {
  name: string,
  qty: number
};

type DatePickerValuesType = {
  start: Moment,
  end: Moment,
  type?: string
};

const ChartsWithFiltersContainer = ({ type }:Props) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const dispatch = useAppDispatch();
  const {
    archivedData, palletsData, collctedData, warehouseData,
  } = useAppSelector((state) => state.dashboards);
  const [selectPallets, setSelectPallets] = useState<SelectPalletsType[]>([]);
  const defaultDateRange = {
    start: moment(new Date(new Date().setDate(new Date().getDate() - 30)), 'yyyy-MM-DD'),
    end: moment(new Date(), 'yyyy-MM-DD'),
  };
  const [filterArchived, setFilterArchived] = useState<DatePickerValuesType>(defaultDateRange);
  const [filterPallets, setFilterPallets] = useState<DatePickerValuesType>(defaultDateRange);
  const [filterCollected, setFilterCollected] = useState<DatePickerValuesType>(defaultDateRange);
  const [filterWarehouse, setFilterWarehouse] = useState<DatePickerValuesType>(defaultDateRange);

  useEffect(() => {
    if (palletsData && !selectPallets.length) {
      setSelectPallets(palletsData?.data);
    }
  }, [palletsData, selectPallets]);

  const returnPayload = (start, end) => ({
    start: moment(new Date(start)).format('yyyy-MM-DD'),
    end: moment(new Date(end)).format('yyyy-MM-DD'),
  });

  const filterArchivedChartHandler = (values) => {
    if (values) {
      setFilterArchived({ start: values[0], end: values[1] });
      dispatch(getDashboardArchivedAction(returnPayload(values[0], values[1])));
    } else {
      setFilterArchived(defaultDateRange);
      dispatch(getDashboardArchivedAction(returnPayload(defaultDateRange.start, defaultDateRange.end)));
    }
  };
  const filterPalletsChartHandler = (valueType: 'type' | 'date_range', value) => {
    let filter;
    if (valueType === 'type') {
      filter = { ...filterPallets, type: value };
      setFilterPallets(filter);
    }
    if (valueType === 'date_range') {
      const dateRange = value ? {
        start: value[0],
        end: value[1],
      } : defaultDateRange;

      filter = {
        ...filterPallets,
        ...dateRange,
      };
      setFilterPallets(filter);
    }
    dispatch(getDashboardPalletsAction({
      type: filter.type,
      ...returnPayload(filter.start, filter.end),
    }));
  };

  const filterCollectedChartHandler = (values) => {
    if (values) {
      setFilterCollected({ start: values[0], end: values[1] });
      dispatch(getDashboardCollectedAction(returnPayload(values[0], values[1])));
    } else {
      setFilterCollected(defaultDateRange);
      dispatch(getDashboardCollectedAction(returnPayload(defaultDateRange.start, defaultDateRange.end)));
    }
  };

  const filterWarehouseChartHandler = (values) => {
    if (values) {
      setFilterCollected({ start: values[0], end: values[1] });
      dispatch(getDashboardWarehouseAction(returnPayload(values[0], values[1])));
    } else {
      setFilterWarehouse(defaultDateRange);
      dispatch(getDashboardWarehouseAction(returnPayload(defaultDateRange.start, defaultDateRange.end)));
    }
  };
  if (type === 'archived') {
    return (
      <Row>
        <Col span={18}>
          <ChartTotalNumberOfQuotes/>
        </Col>
        <Col span={5} offset={1} className={style.gap_container}>
          <RangePicker
            className={cn(style.select, style.range_picker)}
            value={[filterArchived.start, filterArchived.end]}
            onChange={filterArchivedChartHandler}
            disabled={!archivedData?.data.length}
            format="DD-MM-YYYY"
          />
        </Col>
      </Row>
    );
  }

  if (type === 'pallet') {
    return (
      <Row>
        <Col span={18}>
          <ChartNumberOfPalletsDuringPeriod/>
        </Col>
        <Col span={5} offset={1} className={style.gap_container}>
          <Select
            className={style.select}
            placeholder={t('dashboard.filterPallets')}
            onChange={(val) => filterPalletsChartHandler('type', val)}
            disabled={!palletsData?.data.length}
          >
            <Option value="">{t('dashboard.filterPallets')}</Option>
            {selectPallets.map((pallet, i) => (
              <Option key={`select-${i}`} value={pallet.name}>
                { pallet.name }
              </Option>
            ))}
          </Select>
          <RangePicker
            className={cn(style.select, style.range_picker)}
            value={[filterPallets.start, filterPallets.end]}
            onChange={(val) => filterPalletsChartHandler('date_range', val)}
            disabled={!palletsData?.data.length}
            format="DD-MM-YYYY"
          />
        </Col>
      </Row>
    );
  }

  if (type === 'collected') {
    return (
      <Row>
        <Col span={18}>
          <ChartNumberOfDeliveryOrCollectionDelays/>
        </Col>
        <Col span={5} offset={1} className={style.gap_container}>
          <RangePicker
            className={cn(style.select, style.range_picker)}
            value={[filterCollected.start, filterCollected.end]}
            onChange={filterCollectedChartHandler}
            disabled={!collctedData?.data.length}
            format="DD-MM-YYYY"
          />
        </Col>
      </Row>
    );
  }

  if (type === 'warehouse') {
    return (
      <Row>
        <Col span={18}>
          <ChartTimeOrderRemainsInTheWarehouse/>
        </Col>
        <Col span={5} offset={1} className={style.gap_container}>
          <RangePicker
            className={cn(style.select, style.range_picker)}
            value={[filterWarehouse.start, filterWarehouse.end]}
            onChange={filterWarehouseChartHandler}
            disabled={!warehouseData?.data.length}
            format="DD-MM-YYYY"
          />
        </Col>
      </Row>
    );
  }

  return null;
};

export default ChartsWithFiltersContainer;
