import {
  Button,
  Checkbox, Form,
  InputNumber, Select,
  Space,
  Typography,
} from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/CustomButton/CustomButton';
import bin from '../images/bin.png';
import BackButton from '../../../components/BackButton';

import style from '../UpdatePricelist/updatedPricelist.module.css';
import AssociateModal from '../AssociateModal/AssociateModal';
import { useAppDispatch } from '../../../store/store';
import { deletePricelistAction } from '../../../store/redux_slice/pricelists/pricelistsSlice';
import { PathEnums } from 'enums';

const { Option } = Select;

const CreatedHeader = ({
  setPricelistName,
  pricelistName,
  isSpecial,
  setIsSpecial,
  isAssociate,
  setIsAssociate,
  currentPricelist,
  savePriceList,
  updatePrices,
  assignedUsers,
  setAssignedUsers,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    setIsSpecial(currentPricelist.isSpecial);
  }, [currentPricelist.id]);

  const updatePricesHandler = async () => {
    const values = await form.validateFields();
    const amount = values.operation === '-' && values.amount > 100 ? 100 : values.amount;
    if (amount) {
      updatePrices(values.operation + amount.toString());
    } else {
      updatePrices(undefined);
    }
  };

  const onBack = () => {
    navigate(PathEnums.PriseList, { replace: true });
  };
  const deletePriceList = () => {
    if (currentPricelist.id) {
      dispatch(deletePricelistAction(currentPricelist.id));
    }
    navigate(PathEnums.PriseList);
  };

  return (
    <>
      <Space direction="vertical" className={style.height_container}>
        <BackButton
          onClick={onBack}
        />
        <Space className={style.justify}>
          <Space direction="vertical">
            <Typography.Paragraph
              className={style.priceNumber}
              editable={{ onChange: setPricelistName }}
            >
              {pricelistName}
            </Typography.Paragraph>
            <div className={style.actions}>
              <p>{t('pricelist.data')} <span className={style.membership}>
                {currentPricelist.users ? currentPricelist.users.length + assignedUsers.length : assignedUsers.length}
              </span></p>
              <CustomButton
                className={style.associate}
                type="primary"
                onClick={() => setIsAssociate(true)}
              >
                {t('pricelist.associate')}
              </CustomButton>
              <Checkbox
                checked={isSpecial}
                onChange={() => setIsSpecial(!isSpecial)}
              >
                {t('pricelist.correlation')}
              </Checkbox>
            </div>
          </Space>
          <Space direction="vertical">
            <Space className={style.mainActions}>
              <CustomButton
                type="primary"
                className={style.newQuoteBtn}
                onClick={savePriceList}
              >
                {t('pricelist.confirm')}
              </CustomButton>
              <Button
                className={cn('defaultBtn', style.delete)}
                shape="circle"
                onClick={deletePriceList}
              >
                <img src={bin} alt="delete" />
              </Button>
            </Space>
            <Space className={style.percantage}>
              <Form
                form={form}
                initialValues={{
                  operation: '+',
                  amount: 0,
                }}
              >
                <Form.Item className={style.formItem} name="operation">
                  <Select className={style.percentSelect}>
                    <Option value="+">+</Option>
                    <Option value="-">-</Option>
                  </Select>
                </Form.Item>
                <Form.Item className={style.formItem} name="amount">
                  <InputNumber
                    className={style.percentInput}
                    min={0}
                    addonAfter={'%'}
                    controls={false}
                  />
                </Form.Item>
              </Form>
              <Button
                className={style.percent}
                type="primary"
                onClick={updatePricesHandler}
              >
                ✓
              </Button>
            </Space>
          </Space>
        </Space>
      </Space>
      <AssociateModal
        setIsAssociate={setIsAssociate}
        isAssociate={isAssociate}
        pricelistId={currentPricelist.id}
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
        isSendRequest={false}
      />
    </>
  );
};

export default CreatedHeader;
