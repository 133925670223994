import { useTranslation } from 'react-i18next';
import {
  Row, Col,
} from 'antd';
import Drag from 'components/Drag';
import CustomButton from 'components/CustomButton/CustomButton';
import { GetPalletEstimationCSV } from 'api';
import { useAppDispatch, useAppSelector } from 'store/store';
import { requestAddPallet } from 'store/redux_slice/palletsSlice';
import { DeliveryType } from 'enums';
import style from './shipmentFooterContainer.module.css';

const ShipmentUploadContainer = ({ setIsDownloadFinish }: { setIsDownloadFinish: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const estimation = useAppSelector((state) => state.estimation.estimation);

  const customRequest = (options) => {
    const {
      onSuccess, onError, file,
    } = options;

    if (!estimation) {
      onError({ event: new Error('Insufficient data to import price list') });
      return;
    }

    const fmData = new FormData();
    fmData.append('file', file);
    fmData.append('price_list_id', estimation.price_list_id.toString());
    if (estimation.delivery_type === DeliveryType.RITIRO) {
      fmData.append('region', estimation.pickup_address.location.region);
      fmData.append('province', estimation.pickup_address.location.province);
    } else if (estimation.delivery_type === DeliveryType.CONSEGNA) {
      fmData.append('region', estimation.shipping_address.location.region);
      fmData.append('province', estimation.shipping_address.location.province);
    }

    GetPalletEstimationCSV(fmData)
      .then((res) => {
        const { data } = res;
        dispatch(requestAddPallet(data));
        setIsDownloadFinish();
        onSuccess(file);
      })
      .catch((error) => {
        onError({ event: error });
      });
  };

  return (
    <div className={style.loader_container}
    >
      <Row>
        <Col span={11}>
          <Drag
            width={600}
            customRequest={customRequest}
          />
        </Col>
        <Col span={2} offset={2} className={style.top_loader}>
          <div className="semi-bold">{t('forms.newEstimation.or')}</div>
        </Col>
        <Col span={4} offset={2} className={style.top_loader}>
          <CustomButton
            type="primary"
            className={style.uploadBtn}
            onClick={setIsDownloadFinish}
          >
            {t('forms.newEstimation.manual_loading')}
          </CustomButton>
        </Col>
      </Row>
    </div>
  );
};

export default ShipmentUploadContainer;
