import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PallexNumeroOnuType, NumeroOnuPayloadType } from 'store/types';

export const requestGetNumeroOnuAction = createAction<NumeroOnuPayloadType>('requestGetNumeroOnuAction');

type State = {
  numeroOnuList: PallexNumeroOnuType[] | null,
  loading: boolean,
}

const initialState: State = {
  numeroOnuList: [],
  loading: false,
};

const numeroOnuSlice = createSlice({
  name: 'numeroOnu',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNumeroOnuData: (state, action: PayloadAction<PallexNumeroOnuType[]>) => {
      state.numeroOnuList = action.payload;
    },
  },
});

export const { setNumeroOnuData, setLoading } = numeroOnuSlice.actions;

export default numeroOnuSlice.reducer;
