import {
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Tooltip, Select,
  TimePicker, InputNumber,
} from 'antd';
import { useTranslation } from 'react-i18next';

import React, { useEffect } from 'react';
import style from './ShipmentRequestStepTwo.module.css';
import BackButton from '../../components/BackButton';
import {
  ContrassegnoDto, ExtraServiceStructure, Facchinaggio, Pallet,
} from '../../store/types';
import infoIcon from '../../assests/images/infoIcon.svg';
import { toUpperCaseFirst } from '../../functions';
import RadioButton from '../../components/RadioButton';

const FormItemRender = <T, >({ name, children }: { name: string, children: (v: T) => React.ReactNode }) => <Form.Item
  noStyle={true}
  shouldUpdate={
    (prev, curr) => prev[name] !== curr[name]
  }
>
  {({ getFieldValue }) => children(getFieldValue(name) ?? [])}
</Form.Item>;

const ShipmentRequestStepTwo: React.FC<{
    validate: boolean, setFirstStep: () => void,
    extraServices: ExtraServiceStructure[], facchinaggioList: Facchinaggio[],
    contrassegnoList: ContrassegnoDto[], pallets: Pallet[]
}> = ({
  validate,
  setFirstStep,
  extraServices,
  facchinaggioList,
  contrassegnoList,
  pallets,
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const palletsCount = pallets?.reduce((sum, elem) => sum + (elem?.item_quantity ?? 0), 0);
  return (
    <>
      <div className={style.extra_title}>
        {t('forms.shipmentDetails.extraServices')}
        <Tooltip title={t('messages.info_extra_services')}>
          <img src={infoIcon} alt="info"/>
        </Tooltip>
      </div>
      <Form.Item name="checkboxGroup"
      >
        <Checkbox.Group>
          <Row>
            <FormItemRender name={'checkboxGroup'}>{(selectedIds: number[]) => extraServices
              ?.map((i, k) => (
                <React.Fragment key={k}>
                  <Col span={12} key={i.id}>
                    <div className={style.flex}>
                      <Checkbox value={i.id}>
                        {toUpperCaseFirst(i.title)}
                      </Checkbox>
                      {
                        selectedIds.includes(i.id) && i.title === 'GESTIONE EPAL'
                                                && <Form.Item
                                                  className={style.list_elements}
                                                  name="epal_count"
                                                  dependencies={['pallet']}
                                                  rules={
                                                    [
                                                      { required: validate, message: t('validation.number') },
                                                      {
                                                        pattern: /^-?[0-9]*$/,
                                                        message: t('validation.number'),
                                                      },
                                                      ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                          if (value && validate) {
                                                            if (value <= palletsCount) {
                                                              return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error(t('validation.epal_error')));
                                                          }
                                                          return Promise.resolve();
                                                        },
                                                      }),
                                                    ]
                                                  }
                                                >
                                                  <InputNumber
                                                    className="quantity_height"
                                                    min={1}
                                                    step={1}
                                                    placeholder="Quantità"
                                                  />
                                                </Form.Item>
                      }
                      {
                        selectedIds.includes(i.id) && i.title === 'CONTRASSEGNO' && <>
                          <Form.Item name="contrassegno_id"
                            className={style.list_elements}
                          >
                            <Select
                            >
                              {
                                contrassegnoList.map((c) => (
                                  <Option key={c.id} value={c.id}>{c.description}</Option>
                                ))
                              }
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name="contrassegno_amount"
                            rules={
                              [
                                () => ({
                                  validator(rules, v) {
                                    if (!Number.isNaN(v) && v > 1999) {
                                      return Promise.reject();
                                    }
                                    return Promise.resolve();
                                  },
                                  message: t('validation.number_not_bigger_1999'),
                                }),
                              ]
                            }
                          >
                            <InputNumber
                              className={style.list_elements}
                              min={1}
                              step={1}
                              placeholder="Ammontare di denaro"/>
                          </Form.Item>
                        </>
                      }
                      {
                        selectedIds.includes(i.id) && i.title === 'FACCHINAGGIO' && <Form.Item
                          className={style.facchinaggio_width}
                          name="facchinaggio"
                        >
                          <Select>
                            {
                              facchinaggioList.map((c) => (
                                <Option key={c.id} value={c.id}>{c.description}
                                </Option>))
                            }
                          </Select>
                        </Form.Item>
                      }
                      {
                        selectedIds.includes(i.id) && i.title === 'CONSEGNA CON GIORNO E ORARIO PRESTABILITO'
                                                && <Form.Item
                                                  className={style.list_elements}
                                                  name="e_orario_time"
                                                >
                                                  <TimePicker.RangePicker style={{ height: 30 }} format={'HH:mm'}/>
                                                </Form.Item>
                      }
                    </div>
                  </Col>
                </React.Fragment>
              ))
            }
            </FormItemRender>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <div className="extra-bolt">{t('messages.info')}</div>
      <Form.Item
        name="user_type"
      >
        <Row className={style.radio_group}>
          <Col span={2}>
            <RadioButton
              checked={true}
              title={t('forms.shipmentDetails.agency')}
              value="Company"
              id="1"
              name="group_1"
            />
          </Col>
          <Col span={2} offset={1}>
            <RadioButton
              title={t('forms.shipmentDetails.private')}
              value="Private_person"
              id="2"
              name="group_1"
            />
          </Col>
        </Row>
      </Form.Item>
      <div className={style.contacts}>
        <Form.Item label={t('forms.shipmentRequest.contactEmail')}
          name="email"
          rules={
            [
              { required: validate, message: t('validation.email') },
              { type: 'email', message: t('validation.email') },
            ]
          }
        >
          <Input
            className={style.contactEmail}
          />
        </Form.Item>
        <Form.Item label={t('forms.shipmentRequest.phoneNumber')}
          name="phone"
          rules={
            [
              { required: validate, message: t('validation.phone') },
              {
                pattern: /\(?(\d{3})\)?([ .-]?)(\d{3})\2(\d{4})/,
                message: t('validation.phone'),
              },
            ]
          }
        >
          <Input
            className={style.contactPhone}
          />
        </Form.Item>
      </div>
      <div className={style.contacts}>
        <Form.Item label={t('forms.shipmentRequest.companyName')}
          name="company_name"
          rules={
            [
              { required: validate, message: t('validation.companyName') },
              { whitespace: true, message: t('validation.empty') },
              { min: 3, message: t('validation.companyName') },
            ]
          }
        >
          <Input
            className={style.contactEmail}
          />
        </Form.Item>
        <Form.Item label={t('forms.shipmentRequest.nameAndSurname')}
          name="name_and_surname_reference"
          rules={
            [
              { whitespace: true, message: t('validation.empty') },
            ]
          }
        >
          <Input
            className={style.contactEmail}
          />
        </Form.Item>
      </div>
      <BackButton
        onClick={setFirstStep}
      />
    </>
  );
};

export default ShipmentRequestStepTwo;
