import { put, takeLatest } from 'redux-saga/effects';
import { Estimation, ApproveEstimaptionPayload } from '../types';
import {
  requestEstimation,
  requestClearEstimation,
  setEstimation,
  clearEstimation,
  requestApproveEstimation, setSentShippingRequest, requestClearSentShippingRequest,
} from '../redux_slice/estimationSlice';
import { ApproveShippingRequest, UpdateShippingRequest } from '../../api';
import { setMessage } from '../redux_slice/messageErrorSlice';
import { TPStatuses } from 'enums';

function* setEstimationSaga(action: {
  payload: Estimation
}) {
  yield put(setEstimation(action.payload));
}

function* clearEstimationSaga() {
  yield put(clearEstimation());
}

function* approveEstimationSaga(action: {
  payload: ApproveEstimaptionPayload
}) {
  try {
    yield ApproveShippingRequest(action.payload.id);
    if (action.payload.isUser) {
      yield UpdateShippingRequest({
        id: action.payload.id,
        data: { status: TPStatuses.In_attesa_di_approvazione },
      });
    }
    yield put(setSentShippingRequest(true));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* clearSentShippingRequest() {
  try {
    yield put(setSentShippingRequest(false));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

export function* estimationSaga() {
  yield takeLatest(requestEstimation, setEstimationSaga);
  yield takeLatest(requestClearEstimation, clearEstimationSaga);
  yield takeLatest(requestApproveEstimation, approveEstimationSaga);
  yield takeLatest(requestClearSentShippingRequest, clearSentShippingRequest);
}
