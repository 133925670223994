import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GDOType, GdoPayloadType } from 'store/types';

export const getExtraServeceGDOAction = createAction<GdoPayloadType>('getExtraServeceGDOAction');

type State = {
  gdoList: GDOType[] | null,
  loading: boolean,
}

const initialState: State = {
  gdoList: [],
  loading: false,
};

const gdoSlice = createSlice({
  name: 'gdo_slice',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setGDOSliceData: (state, action: PayloadAction<GDOType[]>) => {
      state.gdoList = action.payload;
    },
    clearGDOList: (state) => {
      state.gdoList = null;
    },
  },
});

export const { setGDOSliceData, setLoading, clearGDOList } = gdoSlice.actions;

export default gdoSlice.reducer;
