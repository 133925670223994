import {
  useEffect, useMemo, useState,
} from 'react';
import * as _ from 'lodash';
import { TFunction, useTranslation } from 'react-i18next';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  Row, Col, Form, Input, Checkbox, Select,
} from 'antd';
import { useParceAddress } from 'hooks/useParceAddress';
import { AddressFields, DeliveryType } from 'enums';
import { useAppSelector, useAppDispatch } from 'store/store';
import { ExtraServiceStructure } from 'store/types';
import { getExtraServeceGDOAction, clearGDOList } from 'store/redux_slice/pallex/gdoSlice';
import {
  GDO, AMAZON,
} from 'pesantiConstants';
import styles from './googlePlacesComponent.module.css';

export type GooglePlacesItem = {
  fullAddress: string;
  placeId?: string;
}

export type GooglePlacesComponentProps = {
  addressField: AddressFields,
  deliveryType: DeliveryType,
  orderData?: {
    options?: ExtraServiceStructure[],
    delivery_type?: string,
    city?: string
  },
  privateArea?: boolean,
  hideName?: boolean,
  registration?: boolean,
}

const { Option } = Select;

const addressInfoFunc = (t:TFunction) => ({
  [AddressFields.PICKUP_ADDRESS]: {
    company_name: {
      label: t('forms.newEstimation.from'),
    },
    disable_private_area_checkbox: AddressFields.SHIPPING_ADDRESS,
    checkbox_text_address: t('forms.newEstimation.checkbox_title'),
  },
  [AddressFields.SHIPPING_ADDRESS]: {
    company_name: {
      label: t('forms.newEstimation.to'),
    },
    disable_private_area_checkbox: AddressFields.PICKUP_ADDRESS,
    checkbox_text_address: t('forms.newEstimation.checkbox_title'),
  },
  [AddressFields.REGISTRATION_ADDRESS]: {
    company_name: {
      label: '',
    },
    disable_private_area_checkbox: '',
    checkbox_text_address: t('forms.shipmentDetails.sameAdress'),
  },
});

const GooglePlacesComponent:React.FC<GooglePlacesComponentProps> = ({
  addressField,
  deliveryType,
  orderData,
  privateArea = false,
  hideName = false,
  registration = false,
}) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user?.user);
  const { gdoList } = useAppSelector((state) => state.gdo);
  const [parceAddressHandler] = useParceAddress();
  const [cityState, setCityState] = useState<string>('');
  const { [addressField]: addressFieldValues } = addressInfoFunc(t);
  Form.useWatch('pickup_address_name_checked', form);
  Form.useWatch('shipping_address_name_checked', form);
  Form.useWatch('pickup_address_checked', form);
  Form.useWatch('shipping_address_checked', form);

  const serviceGDO = useMemo(() => (
    orderData?.options?.find((item) => (item.title?.toUpperCase() === GDO.toUpperCase() && item.isActive)
    || (item.title?.toUpperCase() === AMAZON.toUpperCase() && item.isActive))
  ), [orderData?.options]);

  const disabled = useMemo(() => (
    serviceGDO && (serviceGDO.triangolize === deliveryType
      || serviceGDO.triangolize === DeliveryType.BOTH)
  ), [deliveryType, serviceGDO]);

  useEffect(() => {
    if (orderData?.city) {
      setCityState(orderData.city);
    }
  }, [orderData?.city]);

  useEffect(() => () => {
    dispatch(clearGDOList());
  }, [dispatch]);

  const onSelectGoogleApiHandler = async (fullAddress) => {
    setCityState(fullAddress);
    form.setFieldsValue({
      [`${addressField}_street`]: '',
      [`${addressField}_post_code`]: '',
    });
  };

  const onStreetNameChange = _.debounce(async (e) => {
    const inputValue = e.target.value.trim();
    const streetWithHouse = inputValue.split(',');
    const street = streetWithHouse[0];
    const house = streetWithHouse[1];
    const city = cityState.split(',')[0];
    if (cityState && streetWithHouse.length === 2 && street && house) {
      const cityWithStreet = `${inputValue}, ${cityState.trim()}`;
      const { address: cityAddress } = await parceAddressHandler(cityState);
      const { address: fullAddress } = await parceAddressHandler(cityWithStreet);
      const isStreeAndHouseInFullAddress = !!(fullAddress.street && fullAddress.house_number);
      form.setFieldsValue({
        [addressField]: {
          location: {
            ...cityAddress,
            city,
            street: street.trim(),
            house_number: house.trim(),
            lat: isStreeAndHouseInFullAddress ? fullAddress.lat : cityAddress.lat,
            lng: isStreeAndHouseInFullAddress ? fullAddress.lng : cityAddress.lng,
          },
        },
      });
    }
  }, 500);

  const onCheckboxChange = async (e) => {
    const { checked } = e.target;
    let addressInformation;
    let address: { country?: string, region?: string } = {};
    if (checked) {
      if (registration) {
        addressInformation = form.getFieldValue('pickup_address');
        address = (await parceAddressHandler(addressInformation.full_address)).address;
      }
      if (user?.address.full_address) {
        address = (await parceAddressHandler(user?.address.full_address)).address;
        addressInformation = user?.address;
      }

      form.setFieldsValue({
        [addressField]: {
          location: {
            ...addressInformation,
            country: address?.country || 'Italia',
            region: address.region || '',
          },
        },
        [`${addressField}_street`]: `${addressInformation.street}, ${addressInformation.house_number || ''}`,
        [`${addressField}_post_code`]: `${addressInformation.post_code}`,
        [`${addressField}_name`]: `${user?.name}`,
      });
      setCityState(`${addressInformation.city}, ${addressInformation.province}, ${address?.country}`);
    }
  };
  const onGdoSearchHandler = _.debounce((e) => {
    if (e) {
      dispatch(getExtraServeceGDOAction({ gdoName: e }));
    }
  }, 500);

  const onChangeGdo = async (gdoDescription: string) => {
    const gdoInfo = gdoList?.find((item) => item.description === gdoDescription);
    if (gdoInfo?.address?.location?.full_address) {
      const { address } = await parceAddressHandler(gdoInfo.address.location.full_address);
      form.setFieldsValue({
        [addressField]: {
          location: { ...address },
          company_name: gdoInfo.address.company_name,
          gdo_id: gdoInfo.id,
          gdo_name: gdoInfo.description,
        },
        [`${addressField}_name`]: gdoInfo.address.company_name,
        [`${addressField}_gdo`]: gdoInfo.description,
        [`${addressField}_street`]: `${address.street}, ${address.house_number}`,
        [`${addressField}_post_code`]: `${address.post_code || gdoInfo?.address.location.post_code}`,
      });
      setCityState(`${address.city}, ${address.province}, ${address.country}`);
    }
  };

  return (
    <>
      { !hideName && (
        <Form.Item
          name={`${addressField}_name`}
          label={addressFieldValues.company_name.label}
          rules={[
            { required: !serviceGDO || (serviceGDO && orderData?.delivery_type !== deliveryType), message: t('validation.empty') },
            { min: 3, message: t('forms.newShipment.name_min_length') },
          ]}
        >
          <Input
            placeholder={t('forms.newShipment.name')}
            disabled={disabled}
          />
        </Form.Item>
      )}
      <Form.Item
        name={addressField}
        rules={[
          { required: !serviceGDO || (serviceGDO && orderData?.delivery_type !== deliveryType), message: t('validation.address') },
        ]}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev[addressField] !== curr[addressField]}
        >
          <PlacesAutocomplete
            value={cityState}
            onChange={(fullAddress) => {
              setCityState(fullAddress);
            }}
            onSelect={onSelectGoogleApiHandler}
            searchOptions={{
              componentRestrictions: {
                country: ['it'],
              },
              types: ['locality', 'administrative_area_level_3', 'country'],
            }}
          >
            {({
              getInputProps, suggestions, getSuggestionItemProps, loading,
            }) => (
              <>
                <Input
                  {...getInputProps({
                    placeholder: t('placeholder.enter_city'),
                    className: styles.google_input,
                  })}
                  disabled={disabled}
                />
                <div className={styles.google_result_list}>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, k) => (
                    <div
                      {...getSuggestionItemProps(suggestion)}
                      className={styles.google_item}
                      key={k}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </PlacesAutocomplete>
        </Form.Item>
      </Form.Item>
      <Row>
        <Col span={20}>
          <Form.Item
            name={`${addressField}_street`}
            rules={[
              { required: !serviceGDO || (serviceGDO && orderData?.delivery_type !== deliveryType), message: t('validation.empty') },
              () => ({
                validator(rules, value) {
                  if (value) {
                    const streetAndHouse = value.split(',');
                    if (!serviceGDO && streetAndHouse.length < 2) {
                      return Promise.reject(new Error(t('validation.street_and_house_number')));
                    }

                    if (streetAndHouse.length > 2) {
                      return Promise.reject(new Error(t('validation.street_and_house_number_invalid')));
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              onChange={onStreetNameChange}
              placeholder={t('placeholder.enter_street')}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        <Col span={3} offset={1}>
          <Form.Item
            name={`${addressField}_post_code`}
            rules={[
              { required: !serviceGDO || (serviceGDO && orderData?.delivery_type !== deliveryType), message: t('validation.empty') },
            ]}
          >
            <Input
              placeholder={t('forms.shipmentDetails.n')}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      { (registration
          || (privateArea && !serviceGDO)
          || (!serviceGDO && orderData?.delivery_type !== deliveryType && !!user?.address)) && (
        <Row>
          <Form.Item
            name={`${addressField}_checked`}
            valuePropName="checked"
          >
            <Checkbox
              onChange={onCheckboxChange}
              disabled={privateArea && !!form.getFieldValue(`${addressFieldValues.disable_private_area_checkbox}_checked`)}
            >
              {addressFieldValues.checkbox_text_address}
            </Checkbox>
          </Form.Item>
        </Row>
      )}
      { serviceGDO && (!hideName
        && (
          orderData?.delivery_type === deliveryType
          || serviceGDO.triangolize === deliveryType
          || serviceGDO.triangolize === DeliveryType.BOTH
        )) && (
        <Row>
          <Col span={24}>
            <Form.Item
              name={`${addressField}_gdo`}
              rules={
                [() => ({
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  validator(_, value) {
                    if (orderData?.delivery_type !== DeliveryType.TRIANG && !value) {
                      return Promise.reject(new Error(t('validation.empty')));
                    }
                    return Promise.resolve();
                  },
                })]
              }
            >
              <Select
                showSearch
                filterOption={false}
                placeholder={t('forms.shipmentDetails.select_gdo')}
                onChange={(e) => onChangeGdo(e)}
                onSearch={(e) => onGdoSearchHandler(e)}
              >
                {gdoList?.map((gdo) => (
                  <Option key={gdo.id} value={gdo.description}>
                    <p className={styles.gdo_select_name}>{gdo.description}</p>
                    <p className={styles.gdo_select_address}>{gdo.address.location.full_address}</p>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

export default GooglePlacesComponent;
