import {
  useState, useEffect, useMemo,
} from 'react';
import * as _ from 'lodash';
import {
  Col,
  Form, Modal, Row, FormInstance,
} from 'antd';
import { useTranslation } from 'react-i18next';
import GooglePlacesComponent from 'components/GooglePlacesComponent';
import CustomButton from 'components/CustomButton/CustomButton';
import { ShipmentStructure } from 'store/types';
import { AddressFields, DeliveryType } from 'enums';
import style from '../estimations.module.css';

const SubmitButton = ({ form, field }: { form: FormInstance, field: AddressFields, }) => {
  const { t } = useTranslation();
  const [submittable, setSubmittable] = useState(false);
  const valuesWatcher = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields().then(
      (e) => {
        if (`${e[field].location.street}, ${e[field].location.house_number}` === e[`${field}_street`]) {
          setSubmittable(true);
        } else {
          setSubmittable(false);
        }
      },
      (e) => {
        setSubmittable(false);
      },
    );
  }, [field, form, valuesWatcher]);

  return (
    <CustomButton
      className={style.modal_button}
      htmlType="submit"
      disabled={!submittable}
      type="primary"
    >
      {t('forms.estimations.update')}
    </CustomButton>
  );
};

export const ChangeAddressModal: React.FC<{
  field: AddressFields,
  deliveryType: DeliveryType
  visible: boolean,
  orderData: Partial<ShipmentStructure>,
  onCancel: () => void,
  onRequestUpdateOrder: (data: Partial<ShipmentStructure>) => void,
  hideName?:boolean,
}> = ({
  field,
  deliveryType,
  visible,
  orderData,
  onCancel,
  onRequestUpdateOrder,
  hideName,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const optionsMap = useMemo(() => (
    orderData.options?.filter((item) => item.isActive)
  ), [orderData.options]);

  useEffect(() => () => {
    form.resetFields();
  });

  const submit = (values) => {
    const data = {
      [field]: {
        ...orderData[field],
        location: {
          ...values[field].location,
          post_code: values[`${field}_post_code`],
        },
        company_name: values[`${field}_name`] || orderData[field]?.company_name,
      },
    };
    onRequestUpdateOrder(data);
    onCancel();
  };
  return (
    <Modal title={t('forms.estimations.update_address')}
      visible={visible}
      onOk={submit}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={submit}
        initialValues={{
          [field]: orderData[field],
          [`${field}_name`]: orderData[field]?.company_name || orderData[field]?.name_and_surname_reference || '',
          [`${field}_street`]: orderData[field]?.location.street
            ? `${orderData[field]?.location.street}, ${orderData[field]?.location.house_number || ''}`
            : '',
          [`${field}_post_code`]: orderData[field]?.location.post_code || '',
        }}
      >
        <Row>
          <Col span={24}>
            <GooglePlacesComponent
              addressField={field}
              deliveryType={deliveryType}
              orderData={{
                options: optionsMap || [],
                delivery_type: orderData?.delivery_type || '',
                city: orderData[field]?.location.city
                  ? `${orderData[field]?.location.city}, ${orderData[field]?.location.province}, ${orderData[field]?.location.country || 'Italia'}`
                  : '',
              }}
              hideName={hideName}
            />
          </Col>
        </Row>
        <SubmitButton form={form} field={field}/>
      </Form>
    </Modal>
  );
};
