import {
  useCallback, useContext, useEffect, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { SettingOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { AdminPalletsTable, MerceAdrTable } from 'components/EditableTable/Tables';
import { CurrentSlideContext } from 'components/ModalWithCarousel';
import { NoteContainer, AdditionalTextContainer } from 'components/EditableTextContainer';
import ContractorTable from 'components/ContractorTable';
import Loader from 'components/Loader';
import { ExtraServicesTableProps, ExtraServicesTableToBeApproved, ExtraServicesTable } from 'components/ExtraServicesTable';
import { EstimationFieldModal, useModalState } from './hooks';
import { OpenModalButton } from './OpenModalButton';
import ModalInfoContainer, { ModalInfoContainerProps, ModalInfoContainerToBeApproved } from './ModalInfoContainer';
import {
  ChangeExtraServicesModal,
  ChangeExtraServicesModalProps,
  ChangeExtraServicesModalToBeApproved,
} from './FieldModals/ChangeExtraServicesModal';
import { ChangeHabModal, ChangeHabModalProps, ChangeHabModalToBeApproved } from './FieldModals/ChangeHabModal';
import { ChangeEcoPalletModal } from './FieldModals/ChangeEcoPalletModal';
import { ChangeFasciModal } from './FieldModals/ChangeFasciModal';
import {
  ChangeContrassegnoModal,
} from './FieldModals/ChangeContrassegnoModal';
import { ChangeFacchinaggioModal } from './FieldModals/ChangeFacchinaggioModal';
import { ChangeOreNoteModal } from './FieldModals/ChangeOreNoteModal';
import { ChangeGdoOrAmazonModal } from './FieldModals/ChangeGdoOrAmazonModal';
import { ChangeTelefonicaModal } from './FieldModals/ChangeTelefonicaModal';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  requestGetToBeApprovedShippingEstimate,
  updateToBeApprovedShipmentAction,
} from 'store/redux_slice/shipping/shippingBeforeApproveSlice';
import { requestGetServicesByPriceList } from 'store/redux_slice/extraByPriceListSlice';
import { requestGetShippingEstimate, requestUpdateEstimation } from 'store/redux_slice/estimationsListSlice';
import {
  ExtraServiceStructure,
  PatchEstimation, ShipmentStructure,
} from 'store/types';
import { isTrackingAvailable } from 'functions';
import {
  CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA, GDO, AMAZON,
  CONTRASSEGNO, FACCHINAGGIO, FASCI, GESTIONE_EPAL,
  TELEFONICA, ADR,
} from 'pesantiConstants';
import { DeliveryType } from 'enums';
import style from './estimations.module.css';

export const isEditable = (isEstimation?: boolean, isArchived?: boolean) => (!isEstimation || !isArchived);

const ModalInfoView: React.FC<{
  estimation: ShipmentStructure,
  isEstimation: boolean,
  onRequestUpdateEstimation: (data:PatchEstimation) => void
  onRequestGetShippingEstimate: (id: string) => void;
  ChangeExtraServicesModalComponent: React.FC<ChangeExtraServicesModalProps>;
  ChangeHabModalComponent: React.FC<ChangeHabModalProps>;
  ExtraServicesTableComponent: React.FC<ExtraServicesTableProps>;
  ModalInfoContainerComponent: React.FC<ModalInfoContainerProps>;
  setVisible?: () => void,
}> = ({
  estimation,
  isEstimation,
  onRequestUpdateEstimation,
  onRequestGetShippingEstimate,
  ChangeExtraServicesModalComponent,
  ChangeHabModalComponent,
  ExtraServicesTableComponent,
  ModalInfoContainerComponent,
  setVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [hubName, setHubName] = useState<string | undefined>('');
  const [ecoPallets, setEcoPallets] = useState<boolean>(false);
  const { isCurrent } = useContext(CurrentSlideContext);
  const [hubForm] = Form.useForm();
  const [ecoPalletForm] = Form.useForm();
  const [fasciForm] = Form.useForm();
  const [contrassegnoForm] = Form.useForm();
  const [oreNoteForm] = Form.useForm();
  const [fasci, setFasci] = useState<boolean>(false);
  const [contrassegno, setContrassegno] = useState<boolean>(false);
  const [facchinaggio, setFacchinaggio] = useState<boolean>(false);
  const [oreNote, setOreNote] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean>(false);
  const [quantity, setQuantity] = useState(0);
  const extraServices = useAppSelector((state) => state.extraServicesByPriceList.services);
  const contractors = useAppSelector((state) => state.contractors?.contractors);
  const pallexHubs = useAppSelector((state) => state.pallexHubs?.hubs);
  const contrassegnoList = useAppSelector((state) => state.contrassegnoList?.contrassegnoList);
  const facchinaggioList = useAppSelector((state) => state.facchinaggioList?.facchinaggioList);
  const pallexItalia = contractors?.find((el) => el.name === 'Pallex Italia');

  const hasGDO = useMemo(() => (
    estimation?.options.some((option) => (
      (option.isActive && option.title?.toUpperCase() === GDO.toUpperCase())
       || (option.isActive && option.title?.toUpperCase() === AMAZON.toUpperCase())
    ))
  ), [estimation?.options]);

  const hasMerceAdr = useMemo(() => (
    estimation?.options.some((o) => (
      (o.isActive && o.title?.toUpperCase() === ADR.toUpperCase())
    ))
  ), [estimation?.options]);

  const gdoOrAmazonCompanyName = useMemo(() => {
    if (estimation.delivery_type === DeliveryType.RITIRO && estimation.pickup_address.gdo_name) {
      return estimation.pickup_address.gdo_name;
    }
    if (estimation.delivery_type === DeliveryType.CONSEGNA && estimation.shipping_address.gdo_name) {
      return estimation.shipping_address.gdo_name;
    }
    if (estimation.delivery_type === DeliveryType.TRIANG) {
      return `${estimation.pickup_address.gdo_name ? estimation.pickup_address.gdo_name : '-'}/
      ${estimation.shipping_address.gdo_name ? estimation.shipping_address.gdo_name : '-'}`;
    }
    return '-';
  }, [estimation]);

  const gdoOrAmazonLabel = useMemo(() => {
    const hasGdo = estimation?.options.some((o) => o.isActive && o.title?.toUpperCase() === GDO.toUpperCase());
    const hasAmazon = estimation?.options.some((o) => o.isActive && o.title?.toUpperCase() === AMAZON.toUpperCase());
    if (hasGdo && hasAmazon) {
      return 'GDO/AMAZON';
    }
    if (hasGdo) {
      return 'GDO';
    }
    if (hasAmazon) {
      return 'AMAZON';
    }
    return '';
  }, [estimation?.options]);

  const serviceTelefonica = useMemo(() => (
    estimation?.options?.find((item) => item.title.toUpperCase() === TELEFONICA.toUpperCase() && item.isActive)
  ), [estimation?.options]);

  const serviceFacchinaggio = useMemo(() => (
    estimation?.options?.find((item) => item.title.toUpperCase() === FACCHINAGGIO.toUpperCase() && item.isActive)
  ), [estimation?.options]);

  const facchinaggioRitiroDescription = useMemo(() => (
    facchinaggioList?.find((s) => s.id === serviceFacchinaggio?.values?.ritiro)?.description
  ), [facchinaggioList, serviceFacchinaggio?.values?.ritiro]);

  const facchinaggioConsegnaDescription = useMemo(() => (
    facchinaggioList?.find((s) => s.id === serviceFacchinaggio?.values?.consegna)?.description
  ), [facchinaggioList, serviceFacchinaggio?.values?.consegna]);

  const {
    openModal,
    isOpen,
    closeModal,
  } = useModalState();
  const hasHubs = useCallback(
    () => estimation?.contractor?.[0]?.ResContract.contractor_id === pallexItalia?.id
      && estimation?.delivery_type !== DeliveryType.TRIANG,
    [estimation?.contractor, estimation?.delivery_type, pallexItalia?.id],
  );
  useEffect(() => {
    let hasError = false;
    if (hasHubs()) {
      if (!estimation?.idHub) {
        hasError = true;
      }
    }
    if (ecoPallets) {
      if (!(estimation?.epal && estimation?.epal > 0)) {
        hasError = true;
      }
    }
    if (fasci) {
      if (!(estimation?.fasci && estimation?.fasci > 0)) {
        hasError = true;
      }
    }
    if (contrassegno) {
      if (!(estimation?.contrassegno && estimation?.contrassegno.id > 0
          && estimation?.contrassegno.value > 0)) {
        hasError = true;
      }
    }
    if (facchinaggio) {
      if (!(estimation?.facchinaggio && estimation?.facchinaggio > 0)) {
        hasError = true;
      }
    }
    if (oreNote) {
      if (!(estimation?.ore_note && estimation?.ore_note !== '')) {
        hasError = true;
      }
    }
    if (!estimation.price_list_id) {
      hasError = true;
    }
    setAvailable(!hasError);
  }, [
    contrassegno, ecoPallets, estimation?.contrassegno, estimation?.epal,
    estimation?.facchinaggio, estimation?.fasci, estimation?.idHub, estimation?.ore_note,
    facchinaggio, fasci, hasHubs, oreNote, estimation.price_list_id,
  ]);
  useEffect(() => {
    if (estimation?.price_list_id) {
      if (!isCurrent) return;
      dispatch(requestGetServicesByPriceList(estimation?.price_list_id));
    }
  }, [dispatch, estimation?.price_list_id, isCurrent]);

  useEffect(() => {
    if (estimation?.price_list_id && !!searchParams.get('dettaglio')) {
      dispatch(requestGetServicesByPriceList(estimation?.price_list_id));
    }
    if (!estimation?.shipping_estimate && !!searchParams.get('dettaglio')) {
      onRequestGetShippingEstimate(estimation?.id);
    }
  }, [searchParams, dispatch, estimation, onRequestGetShippingEstimate]);

  useEffect(() => {
    if (estimation.contractor[0]?.ResContract || estimation.sub_contractor[0]?.ResSubContract) {
      const findContractor = contractors.find((el) => el.id === estimation.contractor[0]?.ResContract?.contractor_id);
      const findSubContractor = contractors.find((el) => el.id === estimation.sub_contractor[0]?.ResSubContract?.contractor_id);
      const isAvailable = isTrackingAvailable(estimation.user_id, findContractor?.name, findSubContractor?.name, estimation.tracking);
      if (estimation?.tracking !== isAvailable) {
        onRequestUpdateEstimation({
          id: estimation?.id,
          data: {
            tracking: isAvailable,
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    setQuantity(estimation?.pallets?.reduce((s, pallet) => +s + +pallet.item_quantity, 0));
  }, [estimation?.pallets]);

  useEffect(() => {
    if (!isCurrent) return;
    onRequestGetShippingEstimate(estimation?.id);
  }, [onRequestGetShippingEstimate, estimation?.id, isCurrent]);

  useEffect(() => {
    if (estimation?.idHub) {
      const estimationHub = pallexHubs.find((x) => x.idHub === estimation?.idHub);
      setHubName(estimationHub?.name);
    } else {
      setHubName('');
    }
  }, [estimation?.idHub, pallexHubs]);

  const updateServiceHandler = useCallback((type:string, cb: (value: boolean) => void) => {
    const p = estimation?.options?.find((s) => s?.title?.toUpperCase() === type);
    if (p && p.isActive) {
      cb(true);
    } else {
      cb(false);
    }
  }, [estimation?.options]);

  useEffect(() => {
    if (estimation?.options?.length) {
      updateServiceHandler(GESTIONE_EPAL, setEcoPallets);
      updateServiceHandler(FASCI, setFasci);
      updateServiceHandler(CONTRASSEGNO, setContrassegno);
      updateServiceHandler(FACCHINAGGIO, setFacchinaggio);
      updateServiceHandler(CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA, setOreNote);
    }
  }, [estimation?.options?.length, updateServiceHandler]);

  const updatePalletsHandler = (pallets) => {
    onRequestUpdateEstimation({
      id: estimation?.id,
      data: {
        pallets: pallets.admin_pallets,
      },
    });
  };

  const updateMerceAdrHandler = (merceAdr) => {
    onRequestUpdateEstimation({
      id: estimation?.id,
      data: {
        merce_adr: merceAdr.merce_adr,
      },
    });
  };

  const onSetServiceDetails = (field, value) => {
    onRequestUpdateEstimation({
      id: estimation?.id,
      data: {
        [field]: value,
      },
    });
  };

  const onNoteHandler = (data: { note_ritiro: string | null, note_consegna: string | null }) => {
    onRequestUpdateEstimation({
      id: estimation?.id,
      data,
    });
  };

  const onSetEditableService = (servicePeyload: ExtraServiceStructure, serviceTitle: string) => {
    onRequestUpdateEstimation({
      id: estimation?.id,
      data: {
        options: estimation.options.map((item) => (
          item.title.toUpperCase() === serviceTitle.toUpperCase() ? servicePeyload : item
        )),
      },
    });
  };

  const onGdoChange = (gdoPayload) => {
    onRequestUpdateEstimation({
      id: estimation?.id,
      data: { ...gdoPayload },
    });
  };

  const addAdditionalTextHandeler = (data: { informazioni_aggiuntive: string | null }) => {
    onRequestUpdateEstimation({
      id: estimation?.id,
      data,
    });
  };

  if (!estimation?.contractor) {
    return <Loader/>;
  }

  return (
    <div className={style.detail_modal_container}>
      <ChangeHabModalComponent
        form={hubForm}
        id={estimation?.id ?? 0}
        visible={isOpen(EstimationFieldModal.openHubModal)}
        onCancel={() => closeModal()}
        initialValue={estimation?.idHub || null}
        options={pallexHubs}
      />
      <ChangeEcoPalletModal
        form={ecoPalletForm}
        visible={isOpen(EstimationFieldModal.openEcoPallet)}
        onChange={(value) => onSetServiceDetails('epal', value)}
        onCancel={() => closeModal()}
        initialValue={estimation?.epal || 0}
      />
      <ChangeFasciModal
        form={fasciForm}
        visible={isOpen(EstimationFieldModal.openFasciModal)}
        onChange={(value) => onSetServiceDetails('fasci', value)}
        onCancel={() => closeModal()}
        initialValue={estimation?.fasci || 0}
        maxValue={quantity}
      />
      <ChangeContrassegnoModal
        form={contrassegnoForm}
        visible={isOpen(EstimationFieldModal.openContrassegnoModal)}
        onChange={(value) => onSetServiceDetails('contrassegno', value)}
        onCancel={() => closeModal()}
        initialValue={({ value: estimation?.contrassegno?.value || 0, id: estimation?.contrassegno?.id || 0 })}
      />
      <ChangeOreNoteModal
        form={oreNoteForm}
        visible={isOpen(EstimationFieldModal.openConsegnaConGiornoEOraPrestabilitaModal)}
        onChange={(value) => onSetServiceDetails('ore_note', value)}
        onCancel={() => closeModal()}
        initialValue={estimation?.ore_note || ''}
      />
      <ChangeGdoOrAmazonModal
        visible={isOpen(EstimationFieldModal.openGdoOrAmazonModal)}
        orderData={estimation}
        onCancel={closeModal}
        onGdoChange={onGdoChange}
      />
      { serviceFacchinaggio && (
        <ChangeFacchinaggioModal
          visible={isOpen(EstimationFieldModal.openFacchinaggioModal)}
          service={serviceFacchinaggio}
          onCancel={closeModal}
          onUpdateService={(data) => onSetEditableService(data, FACCHINAGGIO)}
        />
      )}
      { serviceTelefonica && (
        <ChangeTelefonicaModal
          visible={isOpen(EstimationFieldModal.openTelefonica)}
          service={serviceTelefonica}
          onCancel={closeModal}
          onUpdateService={(data) => onSetEditableService(data, TELEFONICA)}
        />
      )}
      {/* <ChangeNonStopModal* /}
      {/* id={estimation?.id ?? 0} */}
      {/* visible={isOpen(EstimationFieldModal.openNonStopModal)} */}
      {/* onChange={onSetFacchinaggio} */}
      {/* onCancel={() => closeModal()} */}
      {/* initialValue={estimation?.nonStop || 0} */}
      {/* /> */}
      {
        !!estimation?.options?.length && extraServices
        && <ChangeExtraServicesModalComponent
          id={estimation?.id ?? 0}
          visible={isOpen(EstimationFieldModal.openExtraServices)}
          onCancel={() => closeModal()}
          orderOptions={estimation?.options}
          deliveryType={estimation?.delivery_type || ''}
        />
      }
      <div className={style.modal_background}>
        <ModalInfoContainerComponent
          available={available}
          setVisible={setVisible}
          isEstimation={isEstimation}
          estimation={estimation}
        />
        <div className={style.options_container}>
          <p className={style.options_text}>
            {t('forms.newEstimation.number_pallet')}:
            <span className={style.options_bolt_text}>
              {quantity}
            </span>
          </p>
          <p className={style.options_text}>
            {t('forms.newEstimation.weight')}:
            <span className={style.options_bolt_text}>
              {estimation?.pallets?.reduce((s, pallet) => +s + pallet.weight * pallet.item_quantity, 0)} Kg
            </span>
          </p>
          {
            hasHubs()
            && <p className={style.options_text}>
              {t('forms.estimations.hub')}:
              <span className={style.options_bolt_text}>
                {hubName}
              </span>
              {
                isEditable(isEstimation, estimation?.isArchived)
                  && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openHubModal)}/>
              }
            </p>
          }
          {
            ecoPallets
            && <p className={style.options_text}>
              {t('forms.estimations.ecological_pallet')}:
              <span className={style.options_bolt_text}>
                {estimation?.epal || 0}
              </span>
              {
                isEditable(isEstimation, estimation?.isArchived)
                && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openEcoPallet)}/>
              }
            </p>
          }
          {
            fasci
            && <p className={style.options_text}>
              {t('forms.estimations.fasci')}:
              <span className={style.options_bolt_text}>
                {estimation?.fasci || 0}
              </span>
              {
                isEditable(isEstimation, estimation?.isArchived)
                && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openFasciModal)}/>
              }
            </p>
          }
          {
            contrassegno
            && <p className={style.options_text}>
              {t('forms.estimations.contrassegno')}:
              <span className={style.options_bolt_text}>
                { (estimation?.contrassegno
                  && `${contrassegnoList.find((s) => s.id === estimation?.contrassegno?.id)?.description || ''}
                  ${estimation?.contrassegno.id ? '-' : ''}
                  ${parseFloat(`${estimation?.contrassegno?.value}`).toFixed(2)}€`) || '-'}
              </span>
              {
                isEditable(isEstimation, estimation?.isArchived)
                && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openContrassegnoModal)}/>
              }
            </p>
          }
          {
            serviceFacchinaggio
            && <p className={style.options_text}>
              {t('forms.estimations.facchinaggio')}:
              <span className={style.options_bolt_text}>
                { facchinaggioRitiroDescription || '-'}{' '}/{' '}
                { facchinaggioConsegnaDescription || '-'}
              </span>
              {
                isEditable(isEstimation, estimation?.isArchived)
                && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openFacchinaggioModal)}/>
              }
            </p>
          }
          {
            oreNote
            && <p className={style.options_text}>
              {t('forms.estimations.ore_note')}:
              <span className={style.options_bolt_text}>
                {estimation?.ore_note || '-'}
              </span>
              {
                isEditable(isEstimation, estimation?.isArchived)
                && <SettingOutlined onClick={
                  () => openModal(EstimationFieldModal.openConsegnaConGiornoEOraPrestabilitaModal)
                }/>
              }
            </p>
          }
          {hasGDO
            && <p className={style.options_text}>
              {gdoOrAmazonLabel}:
              <span className={style.options_bolt_text}>
                {gdoOrAmazonCompanyName}
              </span>
              {isEditable(isEstimation, estimation?.isArchived)
                && <SettingOutlined onClick={
                  () => openModal(EstimationFieldModal.openGdoOrAmazonModal)
                }/>
              }
            </p>
          }

          {serviceTelefonica
            && <p className={style.options_text}>
              {t('forms.shipmentDetails.telephonica')}:
              <span className={style.options_bolt_text}>
                {serviceTelefonica?.values?.ritiro || '-'}{' '}/{' '}
                {serviceTelefonica?.values?.consegna || '-'}
              </span>
              {isEditable(isEstimation, estimation?.isArchived)
                && <SettingOutlined onClick={
                  () => openModal(EstimationFieldModal.openTelefonica)
                }/>
              }
            </p>
          }
        </div>
      </div>
      { !(isEstimation && estimation?.isArchived) && estimation?.contractor?.length ? (
        <ContractorTable
          contractor={estimation?.contractor}
          subContractor={estimation?.sub_contractor || []}
          deliveryType={estimation?.delivery_type}
          onRequestUpdateEstimation={onRequestUpdateEstimation}
          id={estimation?.id}
          userId={estimation?.user_id}
        />
      ) : null}
      <AdminPalletsTable
        dataArray={estimation?.pallets || []}
        updateOptionHandler={updatePalletsHandler}
        palletEstimate={{
          price_list_id: estimation?.price_list_id,
          pickup_address: estimation?.pickup_address,
          shipping_address: estimation?.shipping_address,
        }}
        deliveryType={estimation?.delivery_type || ''}
        options={estimation?.options}
      />
      { hasMerceAdr && (
        <MerceAdrTable
          dataArray={estimation.merce_adr}
          updateOptionHandler={updateMerceAdrHandler}
        />
      )}
      <ExtraServicesTableComponent
        isArchived={!isEditable(isEstimation, estimation?.isArchived)}
        id={estimation?.id ?? 0}
        orderOptions={estimation?.options || []}
        orderData={estimation}
      />
      <div className={style.footer_modal}>
        {
          isEditable(isEstimation, estimation?.isArchived)
          && <OpenModalButton
            modal={EstimationFieldModal.openExtraServices}
            openModal={openModal}
            icon={<PlusOutlined/>}
            className={style.addPalletsBtn}
          >
            {t('forms.estimations.add_extra')}
          </OpenModalButton>
        }
      </div>
      <AdditionalTextContainer
        additionalText={estimation?.informazioni_aggiuntive || ''}
        onChangeText={addAdditionalTextHandeler}
      />
      <NoteContainer
        noteRitiro={estimation.note_ritiro || estimation.note}
        noteConsegna={estimation.note_consegna || estimation.note}
        ritiroTime={estimation.start_shipment_time || ''}
        deliveryType={estimation.delivery_type}
        onChangeNote={onNoteHandler}
        isEdit={!estimation.isArchived}
      />
    </div>
  );
};

const ModalInfo: React.FC<{
  estimation: ShipmentStructure,
  isEstimation: boolean,
  setVisible: () => void,
  isArchived?: boolean,
}> = (props) => {
  const dispatch = useAppDispatch();

  const onRequestUpdateEstimation = useCallback(
    (data: PatchEstimation) => dispatch(requestUpdateEstimation(data)),
    [dispatch],
  );
  const onRequestGetShippingEstimate = useCallback(
    (shippingId: string) => dispatch(requestGetShippingEstimate(shippingId)),
    [dispatch],
  );
  return <ModalInfoView
    {...props}
    onRequestUpdateEstimation={onRequestUpdateEstimation}
    onRequestGetShippingEstimate={onRequestGetShippingEstimate}
    ChangeExtraServicesModalComponent={ChangeExtraServicesModal}
    ChangeHabModalComponent={ChangeHabModal}
    ExtraServicesTableComponent={ExtraServicesTable}
    ModalInfoContainerComponent={ModalInfoContainer}
  />;
};

export const ModalInfoToBeApproved: React.FC<{
  estimation: ShipmentStructure,
  isEstimation: boolean,
  setVisible: () => void
}> = (props) => {
  const dispatch = useAppDispatch();

  const onRequestUpdateEstimation = useCallback(
    (data: PatchEstimation) => dispatch(updateToBeApprovedShipmentAction(data)),
    [dispatch],
  );
  const onRequestGetShippingEstimate = useCallback(
    (shippingId: string) => dispatch(requestGetToBeApprovedShippingEstimate(shippingId)),
    [dispatch],
  );
  return <ModalInfoView
    {...props}
    onRequestUpdateEstimation={onRequestUpdateEstimation}
    onRequestGetShippingEstimate={onRequestGetShippingEstimate}
    ChangeExtraServicesModalComponent={ChangeExtraServicesModalToBeApproved}
    ChangeHabModalComponent={ChangeHabModalToBeApproved}
    ExtraServicesTableComponent={ExtraServicesTableToBeApproved}
    ModalInfoContainerComponent={ModalInfoContainerToBeApproved}
    setVisible={props.setVisible}
  />;
};

export default ModalInfo;
