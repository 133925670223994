import React from 'react';
import {
  Input, Form, Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/CustomButton/CustomButton';
import style from '../estimations.module.css';
import { PatchEstimation } from 'store/types';

export const ChangeDDTNumberModal: React.FC<{
  id: string,
  visible: boolean,
  onCancel: () => void,
  onRequestUpdateEstimation: (data: PatchEstimation) => void,
  ddtNumberValue: string | null,
}> = ({
  id,
  visible,
  onCancel,
  onRequestUpdateEstimation,
  ddtNumberValue,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const submit = (values) => {
    onRequestUpdateEstimation({
      id,
      data: { ...values },
    });
    onCancel();
  };

  const canselModal = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal title={t('forms.shipmentRequest.ddt_number')}
      visible={visible}
      onOk={submit}
      onCancel={canselModal}
      footer={false}
    >
      <Form
        form={form}
        onFinish={submit}
        initialValues={{
          ddt_number: ddtNumberValue || '',
        }}
      >
        <Form.Item name="ddt_number">
          <Input/>
        </Form.Item>
        <CustomButton
          className={style.modal_button}
          type="primary"
          htmlType="submit"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};
