import { useNavigate } from 'react-router-dom';
import {
  Row, Col, DatePicker, Form, Input,
} from 'antd';
import * as _ from 'lodash';
import cn from 'classnames';
import { PlusOutlined, FilterFilled, ReloadOutlined } from '@ant-design/icons/lib/icons';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import CustomSelect from 'components/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import ShipmentsTable from './ShipmentsTable';
import TrackingModal from './TrackingModal';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  getPrivateAreaShippingRequestListAction, setRedirectFromDetails, setOrderType,
} from 'store/redux_slice/privateAreaSlice';
import { FilterShippingList } from 'store/types';
import { OrderType, PathEnums } from 'enums';
import style from './shipmentsList.module.css';
import 'assests/styles/common.css';

const statuses = [
  { value: 'In attesa di data', title: 'In attesa di data' },
  { value: 'In attesa di ritiro', title: 'In attesa di ritiro' },
  { value: 'Ritiro effettuato', title: 'Ritiro effettuato' },
  { value: 'Presso HUB', title: 'Presso HUB' },
  { value: 'In consegna', title: 'In consegna' },
  { value: 'Ritirato', title: 'Ritirato' },
  { value: 'In magazzino', title: 'In magazzino' },
  { value: 'Consegnato', title: 'Consegnato' },
  { value: 'Annullata', title: 'Annullata' },
];

const PrivateAreaShippingList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFilteredForm] = Form.useForm();
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, _setFilter] = useState<Partial<FilterShippingList>>({});
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState<boolean>(false);
  const [statusFilter, setStatuFilter] = useState<string>('');
  const { shippingRequestList, listLoading, redirectFromDetails } = useAppSelector((state) => state.privateArea);

  const dispatch = useAppDispatch();
  const setFilter = (data) => {
    _setFilter(data);
    setPage(1);
  };

  useEffect(() => {
    if (redirectFromDetails) {
      dispatch(setRedirectFromDetails(false));
    }
  }, [redirectFromDetails, dispatch]);

  useEffect(() => {
    dispatch(getPrivateAreaShippingRequestListAction({
      page,
      page_size: pageSize,
      filter,
      status: statusFilter || undefined,
    }));
    dispatch(setOrderType(null));
  }, [page, pageSize, filter, dispatch, statusFilter]);

  const onChangeFilterHandler = _.debounce((param: keyof FilterShippingList, value) => {
    setFilter((prevState) => ({ ...prevState, ...{ [param]: value } }));
  }, 500);

  const onFilterShipmentsByStatus = (status) => {
    setStatuFilter(status);
    setPage(1);
  };

  const orResetFilterHandler = () => {
    setFilter({});
    setStatuFilter('');
    isFilteredForm.resetFields();
  };

  const estimationRedirect = () => {
    dispatch(setOrderType(OrderType.ESTIMATION));
    navigate(PathEnums.Create_shipment);
  };

  const shippingRedirect = () => {
    dispatch(setOrderType(OrderType.SHIPPING));
    navigate(PathEnums.Create_shipment);
  };

  return (
    <div className={style.shipments_container}>
      <Row className={style.height_container}>
        <Col span={12}>
          <Row>
            <CustomSelect
              placeholder={t('shipmentList.select_placeholder')}
              value={statusFilter}
              options={statuses}
              onChange={onFilterShipmentsByStatus}
            />
            <CustomButton
              className={cn(style.searchBtn, style.btn)}
              icon={<FilterFilled/>}
              onClick={() => setIsFiltered(!isFiltered)}
            >
              {t('pricelist.advancedSearch')}
            </CustomButton>
            <CustomButton
              className={cn(style.resetBtn, style.btn)}
              icon={<ReloadOutlined/>}
              onClick={orResetFilterHandler}
            >
              {t('pricelist.reset')}
            </CustomButton>
          </Row>
        </Col>
        <Col className={style.btn_container}>
          <CustomButton
            type="primary"
            icon={<PlusOutlined/>}
            className={cn('defaultBtn', style.new_order_btn)}
            onClick={() => estimationRedirect()}
          >
            {t('shipmentList.newEstimation')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<PlusOutlined/>}
            className={style.new_order_btn}
            onClick={() => shippingRedirect()}
          >
            {t('shipmentList.newShipment')}
          </CustomButton>
        </Col>
      </Row>
      {isFiltered && (
        <Form form={isFilteredForm}>
          <Row className={style.filter_container}>
            <Col>
              <p className={style.labelSearch}>{t('shipmentList.recipientNameSearch')}</p>
              <Form.Item name="recipientName">
                <Input onChange={(v) => { onChangeFilterHandler('recipientName', v.target.value || undefined); }} />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <p className={style.labelSearch}>{t('forms.shipmentRequest.shipping_client_code')}</p>
              <Form.Item name="codice_cliente_spedizione">
                <Input onChange={(v) => { onChangeFilterHandler('codice_cliente_spedizione', v.target.value || undefined); }} />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <p className={style.labelSearch}>{t('shipmentList.deliveryDateSearch')}</p>
              <Form.Item name="start_shipment_date">
                <DatePicker className="date_input" onChange={(v) => { onChangeFilterHandler('start_shipment_date', v ? v.utc().startOf('day').toISOString() : undefined); }} format="DD/MM/YYYY"/>
              </Form.Item>
            </Col>
            <Col offset={1}>
              <p className={style.labelSearch}>{t('shipmentList.collectionDateSearch')}</p>
              <Form.Item name="withdrawal_date">
                <DatePicker className="date_input" onChange={(v) => { onChangeFilterHandler('withdrawal_date', v ? v.utc().startOf('day').toISOString() : undefined); }} format="DD/MM/YYYY"/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      <TrackingModal
        visible={isTrackingModalOpen}
        setVisibleTrackingModal={setIsTrackingModalOpen}
      />
      { listLoading ? <Loader/>
        : (
          <ShipmentsTable
            tableData={shippingRequestList}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            total={shippingRequestList?.count || 0}
            setVisibleTrackingModal={setIsTrackingModalOpen}
          />
        )}
    </div>
  );
};

export default PrivateAreaShippingList;
