import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

import './antdStyles.css';
import React from 'react';
import { ShipmentStructure } from '../../store/types';
import { makeShipmentDetailsTableColumns } from './TableColumns';

const ShipmentDetailsTable:React.FC<{shipmentRequest: ShipmentStructure}> = ({ shipmentRequest }) => {
  const { t } = useTranslation();
  const dataSource = shipmentRequest?.pallets?.map((i, k) => (
    {
      key: `${k}${i.item_quantity}`,
      quantity: i.item_quantity,
      width: i.width,
      depth: i.depth,
      height: i.height,
      weight: i.weight,
    }
  ));
  const columns = makeShipmentDetailsTableColumns(t);
  return (
    <div className="table_container">
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </div>
  );
};

export default ShipmentDetailsTable;
