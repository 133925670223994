import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Banner from '../../components/Banner/Banner';
import ConfirmContainer from '../../components/ConfirmContainer';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { setCleanData } from '../../store/redux_slice/shipmentSlice';

const ShipmentRequestContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const confirmEmail = useAppSelector((state) => state.shipmentRequest.confirmationEmail);
  useEffect(() => {
    dispatch(setCleanData());
  }, [dispatch]);
  return (
    <>
      <Banner title={t('banner.shipmentRequestStep3')} />
      <ConfirmContainer
        title={t('forms.shipmentRequest.quoteSent')}
        confirmEmail={confirmEmail ?? ''}
      />
    </>
  );
};

export default ShipmentRequestContainer;
