import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { format } from 'date-fns';
import style from './transports.module.css';
import successIcon from '../../assests/images/successIcon.svg';
import errorIcon from '../../assests/images/errorIcon.svg';

interface TransportsTableInterface {
    license_plate: string,
    type: string,
    description: string,
    drivers_name: string,
    contractor: string,
    number_pallets_max: number,
    availability: {busy: boolean, busy_days: string},
    options: JSX.Element,
}

export const makeTransportsTableColumns = (t: TFunction)
    : ColumnsType<TransportsTableInterface> => [
  {
    title: t('forms.transports.license_plate'),
    dataIndex: 'license_plate',
    key: 'license_plate',
    render: (text) => <span className={style.min_bold}>
      {text}
    </span>,
    align: 'center',
  },
  {
    title: t('forms.transports.type'),
    dataIndex: 'type',
    sorter: (a, b) => {
      if (a && a.type && a.type.length && b && b.type && b.type.length) {
        return a.type.length - b.type.length;
      } if (a && a.type && a.type.length) {
        return -1;
      } if (b && b.type && b.type.length) {
        return 1;
      }
      return 0;
    },
    key: 'type',
    align: 'center',
  },
  {
    title: t('forms.transports.description'),
    dataIndex: 'description',
    key: 'description',
    align: 'center',
  },
  {
    title: t('forms.transports.drivers_name'),
    dataIndex: 'drivers_name',
    key: 'drivers_name',
    align: 'center',
  },
  {
    title: t('forms.shipmentDetails.agency'),
    dataIndex: 'contractor',
    key: 'contractor',
    align: 'center',
  },
  {
    title: t('forms.transports.max_pallet_number'),
    dataIndex: 'number_pallets_max',
    key: 'number_pallets_max',
    render: (text) => <span className={style.min_bold}>
      {text}
    </span>,
    align: 'center',
  },
  {
    title: t('forms.transports.availability'),
    dataIndex: 'availability',
    key: 'availability',
    render: (status) => <>
      <img src={status.busy ? errorIcon : successIcon}/>
      {
        status.busy && status.busy_days && <>
          <div>{t('forms.transports.available_in')}</div>
          <div className={style.min_bold}>
            {Math.ceil((+new Date(status.busy_days) - +new Date())
                / (24 * 60 * 60 * 1000))} {t('forms.transports.days')}
          </div>
        </>
      }
    </>,
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    align: 'center',
  },
];
