import {
  Button,
  Modal,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { TableRowSelection } from 'antd/es/table/interface';

import CustomButton from '../../../components/CustomButton/CustomButton';
import style from './modal.module.css';
import { CreatedDataType } from './data';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import Loader from '../../../components/Loader';
import { requestSearchUsers } from '../../../store/redux_slice/users/usersSearchSlice';
import successIcon from '../../../assests/images/successIcon.svg';
import { requestAssignUser } from '../../../store/redux_slice/users/usersSlice';

const AssociateModal = ({
  isAssociate,
  setIsAssociate,
  pricelistId,
  assignedUsers,
  setAssignedUsers,
  isSendRequest,
}) => {
  const dispatch = useAppDispatch();
  const usersSearch = useAppSelector((state) => state.usersSearch.users);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    dispatch(requestSearchUsers(''));
  }, [dispatch]);

  if (!usersSearch) {
    <Loader/>;
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSelectConfirm = async () => {
    if (isSendRequest && selectedRowKeys.length) {
      await Promise.all(selectedRowKeys.map((userId) => dispatch(
        requestAssignUser({ pricelistId, userId: userId.toString() }),
      )));
    }
    setIsAssociate(false);
    setAssignedUsers(selectedRowKeys);
    setSelectedRowKeys([]);
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record?.disabled,
    }),
  };

  const columns = [
    {
      title: 'Nome azienda',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Partita iva',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Associato a listino',
      dataIndex: 'associated',
      key: 'associated',
    },
  ];
  const data: CreatedDataType = usersSearch?.map((user) => ({
    key: user.id,
    name:
      <Space>
        <Tooltip title={user.name || user.email}>
          <p style={{
            width: 150,
            fontSize: 15,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>{user.name || user.email}</p>
        </Tooltip>
      </Space>,
    number: <Tooltip title={user.vat_number || '-'}>
      <p style={{
        width: 150,
        fontSize: 15,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>{user.vat_number || '-'}</p>
    </Tooltip>,
    associated: user.price_list_id && +user.price_list_id === +pricelistId ? <img src={successIcon} alt="+"/> : '-',
    disabled: user.price_list_id && +user.price_list_id === +pricelistId,
  }));
  return (
    <Modal
      bodyStyle={{ padding: '24px 2px' }}
      className={style.asociaModal}
      visible={isAssociate}
      onCancel={() => {
        setIsAssociate(false);
        setSelectedRowKeys([]);
      }}
      footer={
        <div className={style.modalFooter}>
          <Button
            key="confirm"
            className={cn(style.confirm, style.btn)}
            type="primary"
            onClick={onSelectConfirm}
          >
            <span className={style.btnTitle}>Conferma</span>
          </Button>
          <CustomButton
            key="cancel"
            className={cn('defaultBtn', style.btn)}
            onClick={() => {
              setIsAssociate(false);
              setSelectedRowKeys([]);
              setAssignedUsers([]);
            }}
          >
            <span className={style.btnTitle}>Annula</span>
          </CustomButton>
        </div>
      }
    >
      <h2 className={style.modalTitle}>associa anagrafiche a listino</h2>
      <Table
        className={style.table}
        rowClassName={style.row}
        dataSource={data}
        columns={columns}
        rowSelection={rowSelection}
      />
    </Modal>
  );
};

export default AssociateModal;
