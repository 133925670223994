import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PricelistStructure } from '../../types';

type State = {
    priceListTemplate: PricelistStructure | null
    loading: boolean,
}

const initialState: State = {
  priceListTemplate: null,
  loading: false,
};

const priceListTemplateSlice = createSlice({
  name: 'priceListTemplate',
  initialState,
  reducers: {
    setTemplatePriceList: (state, action: PayloadAction<PricelistStructure>) => {
      state.priceListTemplate = action.payload;
    },
  },
});

export const { setTemplatePriceList } = priceListTemplateSlice.actions;

export default priceListTemplateSlice.reducer;
