import { useEffect } from 'react';
import {
  Form, Modal, Input, Row, Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/CustomButton/CustomButton';
import { ExtraServiceStructure } from 'store/types';
import { DeliveryType } from 'enums';
import style from '../estimations.module.css';

export const ChangeTelefonicaModal: React.FC<{
  visible: boolean,
  service: ExtraServiceStructure,
  onCancel: () => void,
  onUpdateService: (data: ExtraServiceStructure) => void,
}> = ({
  visible,
  service,
  onCancel,
  onUpdateService,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      telefonica_ritiro: service?.values?.ritiro || '',
      telefonica_consegna: service?.values?.consegna || '',
    });
  }, [form, service?.values]);

  const submit = (values) => {
    onUpdateService({
      ...service,
      values: {
        ritiro: values.telefonica_ritiro || null,
        consegna: values.telefonica_consegna || null,
      },
    });
    onCancel();
  };
  return (
    <Modal
      title={t('forms.shipmentDetails.add_telephone')}
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        onFinish={submit}
      >
        { !service?.triangolize ? (
          <>{t('forms.shipmentRequest.select_delivery_for_service')}</>
        ) : (
          <Row style={{ gap: '15px' }}>
            {(service?.triangolize === DeliveryType.RITIRO
            || service?.triangolize === DeliveryType.BOTH) && (
              <Col span={11}>
                <h5 className={style.modal_label}>{t('forms.shipmentRequest.telefonica_ritiro')}</h5>
                <Form.Item
                  name="telefonica_ritiro"
                  rules={[
                    { required: true, message: t('validation.empty') },
                  ]}
                >
                  <Input/>
                </Form.Item>
              </Col>
            )}
            { (service?.triangolize === DeliveryType.CONSEGNA
            || service?.triangolize === DeliveryType.BOTH) && (
              <Col span={11}>
                <h5 className={style.modal_label}>{t('forms.shipmentRequest.telefonica_consegna')}</h5>
                <Form.Item
                  name="telefonica_consegna"
                  rules={[
                    { required: true, message: t('validation.empty') },
                  ]}
                >
                  <Input/>
                </Form.Item>
              </Col>
            )}
          </Row>
        )}
        <CustomButton
          className={style.modal_button}
          htmlType="submit"
          type="primary"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};
