import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dropdown, Col,
} from 'antd';
import { BellOutlined, SettingOutlined } from '@ant-design/icons';
import cn from 'classnames';
import {
  useNavigate, useSearchParams, NavLink,
} from 'react-router-dom';
import { useSocket } from '../../hooks/useSocket';
import logo from '../../assests/images/logo.png';
import NotificationItem from '../NotificationItem';
import Drawer from '../Drawer';
import MenuSettings from './MenuSettings';
import { useDrawerState, DrawerType } from '../Drawer/hooks';
import { useAppDispatch, useAppSelector } from '../../store/store';
import {
  setNotifications, setNewNotifications, readNotification, clearNotifications,
  getShippingTableTypeAction, setCheckNotificationsAfterLogin,
} from '../../store/redux_slice/notificationsSlice';
import { getPrivateAreaShippingRequestListAction } from 'store/redux_slice/privateAreaSlice';
import { requestDownloadQrCodesAction } from '../../store/sagas/downloadQrCodesSaga';
import { RoleEnums, PathEnums } from '../../enums';
import style from './styles/privateHeader.module.css';

type Props = {
  navigationLinks: {
    path: string,
    name: string,
  }[]
  type: RoleEnums,
};

const OperatorHeader = ({ navigationLinks, type }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    openDrawer,
    isOpen,
    closeDrawer,
  } = useDrawerState();
  const socket = useSocket();
  const [searchParams] = useSearchParams();

  const {
    notificationsList, shippingType, tableTypeLoading, checkNotificationAfterLogin,
  } = useAppSelector((state) => state.notifications);

  const [clickedNotification, setClickedNotification] = useState(false);
  const [notReadNotifications, setNotReadNotifications] = useState<boolean | string | null>(null);

  useEffect(() => {
    if (localStorage.getItem('NEW_NOTIFICATIONS')) {
      setNotReadNotifications(localStorage.getItem('NEW_NOTIFICATIONS'));
    }
  }, []);

  useEffect(() => {
    if (checkNotificationAfterLogin && notificationsList.length) {
      dispatch(setCheckNotificationsAfterLogin(false));
      const notificationsInPending = notificationsList.filter((item) => item.type === 'In pending').length;
      if (notificationsInPending) {
        setNotReadNotifications(true);
        localStorage.setItem('NEW_NOTIFICATIONS', String(true));
      }
    }
  }, [notificationsList, checkNotificationAfterLogin, dispatch]);

  useEffect(() => {
    socket.connect();
    socket.on('notification', (data) => {
      dispatch(setNotifications(data.message.reverse()));
    });
    return () => {
      socket.off('notification');
      dispatch(clearNotifications());
    };
  }, [socket, dispatch]);

  useEffect(() => {
    socket.on('new_notification', (data) => {
      if (!notReadNotifications) {
        setNotReadNotifications(true);
        localStorage.setItem('NEW_NOTIFICATIONS', String(true));
      }
      dispatch(setNewNotifications(data.message));
      if (type === RoleEnums.USER) {
        dispatch(getPrivateAreaShippingRequestListAction({
          page: 1,
          page_size: 10,
        }));
      }
    });
    return () => {
      socket.off('new_notification');
    };
  }, [socket, notReadNotifications, dispatch, type]);

  useEffect(() => {
    socket.on('success', (data) => {
      dispatch(readNotification(data.message[0]));
    });
    return () => {
      socket.off('success');
    };
  });

  useEffect(() => {
    const shippingId = searchParams.get('dettaglio');
    if (!shippingType && shippingId) {
      dispatch(getShippingTableTypeAction(shippingId));
    }
  }, [dispatch, searchParams, shippingType]);

  useEffect(() => {
    if (shippingType && !tableTypeLoading && clickedNotification) {
      if (shippingType.type === 'preventivi' || shippingType.type === 'preventivi-archivate') {
        navigate(`${PathEnums.Estimations}?dettaglio=${shippingType.id}`);
      } else if (shippingType?.type === 'spedizione-aprovate' || shippingType?.type === 'spedizione-aprovate-archivate') {
        navigate(`${PathEnums.Deliveries_approved}?dettaglio=${shippingType.id}`);
      } else if (shippingType?.type === 'spedizione-da-approvare') {
        navigate(`${PathEnums.Deliveries_to_be_approved}?dettaglio=${shippingType.id}`);
      } else if (shippingType?.type === 'rifiutato') {
        navigate(`${PathEnums.Deliveries_canceled}?dettaglio=${shippingType?.id}`);
      }
      setClickedNotification(false);
    }
  }, [shippingType, navigate, tableTypeLoading, clickedNotification]);

  const emitNotificationHandler = (id) => {
    socket.emit('acknowledgement', {
      notification_id: id,
    });
  };

  const onOpenDrawerHandler = () => {
    openDrawer(DrawerType.openNotifications);
    localStorage.removeItem('NEW_NOTIFICATIONS');
    setNotReadNotifications(false);
  };

  const notificationOnclickHandler = (shippingId: string, isQrCode: boolean) => {
    if (isQrCode) {
      dispatch(requestDownloadQrCodesAction(shippingId));
      closeDrawer();
      return;
    }
    if (type === RoleEnums.USER) {
      navigate(`private_area_shipment_details/${shippingId}`);
      return;
    }
    setClickedNotification(true);
    dispatch(getShippingTableTypeAction(shippingId));
    closeDrawer();
  };

  return (
    <>
      <header className={style.private_header}>
        <Col span={3} className={style.logo_col}>
          <img src={logo} alt="logo" />
        </Col>
        <Col span={21} className={style.menu_col}>
          <div className={style.menu}>
            {navigationLinks.map((item, i) => (
              <NavLink
                key={item.path}
                to={item.path}
                className={({ isActive }) => cn(style.menu_item, isActive && style.menu_item_active)}
              >
                <span>{item.name}</span>
              </NavLink>
            ))}
          </div>
          <div className={style.icons_container}>
            <div className={cn(style.badge_container, {
              [style.badge]: notReadNotifications,
            })}>
              <Button
                type="text"
                icon={<BellOutlined />}
                onClick={onOpenDrawerHandler}
                className={style.icon}
              />
            </div>
            <Dropdown
              key="more"
              overlay={<MenuSettings type={type} />}
              placement="bottomRight"
            >
              <Button type="text" icon={<SettingOutlined />} className={style.icon} />
            </Dropdown>
          </div>
        </Col>
      </header>
      <Drawer
        isOpen={isOpen(DrawerType.openNotifications)}
        onClose={closeDrawer}
        title={t('privateMenu.notifications')}
      >
        {notificationsList.map((item) => (
          <NotificationItem
            key={item.id}
            notification={item}
            onClick={notificationOnclickHandler}
            emitNotificationHandler={emitNotificationHandler}
          />
        ))}
      </Drawer>
    </>
  );
};

export default OperatorHeader;
