import { Navigate } from 'react-router-dom';
import { RoleEnums } from 'enums';
import LoginForm from '../containers/LoginContainer';
import ShipmentRequestStepOne from '../containers/ShipmentRequestStepOne';
import ShipmentRequestStepThee from '../containers/ShipmentRequestStepThee';
import NotFound from '../components/NotFound';
import ShipmentDetails from '../containers/ShipmentDetails';
import Tracking from '../containers/Tracking';
import Estimations from '../containers/Estimations';
import Pricelist from '../containers/Pricelist';
import UploadTab from '../containers/Pricelist/UploadTab';
import CreatedPricelist from '../containers/Pricelist/CreatedPriceList';
import NewEstimation from '../containers/NewEstimation';
import Deliveries from '../containers/Deliveries';
import ShippingApprovedContainer from '../containers/Deliveries/ShippingApprovedContainer';
import ShippingToBeApprovedContainer from '../containers/Deliveries/ShippingToBeApprovedContainer';
import RejectedContainer from '../containers/Deliveries/RejectedContainer';
import PrivateAreaShippingList from '../containers/PrivateArea/ShippingList';
import PrivateAreaShipmentDetails from '../containers/PrivateArea/ShippingDetails';
import CreateShipment from '../containers/PrivateArea/CreateShipping';
import CreateShipmentManual from '../containers/PrivateArea/CreateShipping/Manual';
import CreateShipmentDetails from '../containers/PrivateArea/CreateShipping/Details';
import NewShipmentDeliveries from '../containers/NewShipmentDeliveries';
import OperatorProfile from '../containers/OperatorProfile';
import UserProfile from '../containers/UserProfile';
import HelpContainer from '../containers/HelpContainer';
import OperatorsList from '../containers/OperatorsList';
import UsersContainer from '../containers/UsersContainer';
import BankConfirm from '../containers/BankConfirm';
import TransportsContainer from '../containers/TransportsContainer';
import Dashboard from '../containers/Dashboard';
import InvoicesContainer from '../containers/Invioces';
import Manifests from '../containers/Manifests';
import RestorePassword from '../containers/RestorePassword';
import NewPass from '../containers/NewPass';
import Monitoring from '../containers/Monitoring';
import PrivacyPolicy from '../containers/PrivacyPolicy';
import UpdatedPricelist from '../containers/Pricelist/UpdatePricelist';

export const routes = [
  {
    path: '/consegne',
    exact: true,
    role: [
      RoleEnums.OPERATOR, RoleEnums.BILLING_OPERATOR,
      RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <Navigate to="/consegne/approvate" />,
  },
  {
    path: '/consegne/approvate',
    exact: true,
    role: [
      RoleEnums.OPERATOR, RoleEnums.BILLING_OPERATOR,
      RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: (
      <Deliveries>
        <ShippingApprovedContainer/>
      </Deliveries>
    ),
  },
  {
    path: '/consegne/da-approvare',
    exact: true,
    role: [
      RoleEnums.OPERATOR, RoleEnums.BILLING_OPERATOR,
      RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: (
      <Deliveries>
        <ShippingToBeApprovedContainer/>
      </Deliveries>
    ),
  },
  {
    path: '/consegne/rifiutate',
    exact: true,
    role: [
      RoleEnums.OPERATOR, RoleEnums.BILLING_OPERATOR,
      RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: (
      <Deliveries>
        <RejectedContainer/>
      </Deliveries>
    ),
  },
  {
    path: '/nuova-spedizione',
    exact: true,
    role: [
      RoleEnums.OPERATOR, RoleEnums.BILLING_OPERATOR,
      RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <NewShipmentDeliveries/>,
  },
  {
    path: '/flotta',
    exact: true,
    role: [
      RoleEnums.OPERATOR, RoleEnums.BILLING_OPERATOR,
      RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <TransportsContainer/>,
  },
  {
    path: '/manifesto-di-carico',
    role: [
      RoleEnums.OPERATOR, RoleEnums.BILLING_OPERATOR,
      RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <Manifests/>,
  },
  {
    path: '/operatori',
    exact: true,
    role: [
      RoleEnums.OPERATOR, RoleEnums.BILLING_OPERATOR,
      RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <OperatorProfile/>,
  },
  {
    path: '/preventivi',
    exact: true,
    role: [RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <Estimations/>,
  },
  {
    path: '/nuovo-preventivo',
    exact: true,
    role: [RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <NewEstimation/>,
  },
  {
    path: '/listino-prezzi',
    exact: true,
    role: [RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <Pricelist/>,
  },
  {
    path: '/nuovo-listino-prezzi',
    exact: true,
    role: [RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <UploadTab/>,
  },
  {
    path: '/listino-prezzi-creato',
    exact: true,
    role: [RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <CreatedPricelist/>,
  },
  {
    path: '/listino-prezzi-dettaglio/:id',
    exact: true,
    role: [RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <UpdatedPricelist/>,
  },
  {
    path: '/utenti',
    exact: true,
    role: [RoleEnums.BILLING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <UsersContainer/>,
  },
  {
    path: '/lista-operatori',
    exact: true,
    role: [RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <OperatorsList/>,
  },
  {
    path: '/dashboard',
    exact: true,
    role: [RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <Dashboard/>,
  },
  {
    path: '/fatture-da-inviare',
    exact: true,
    role: [RoleEnums.BILLING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN],
    element: <InvoicesContainer/>,
  },
  {
    path: '/monitoring',
    exact: true,
    role: [RoleEnums.SUPER_ADMIN],
    element: <Monitoring/>,
  },
  {
    path: '/lista-spedizioni',
    exact: true,
    role: [RoleEnums.USER],
    element: <PrivateAreaShippingList/>,
  },
  {
    path: '/dettaglio-spedizione-area-privata/:id',
    exact: true,
    role: [RoleEnums.USER],
    element: <PrivateAreaShipmentDetails/>,
  },
  {
    path: '/crea-spedizione',
    exact: true,
    role: [RoleEnums.USER],
    element: <CreateShipment/>,
  },
  {
    path: '/crea-spedizione/manuale',
    exact: true,
    role: [RoleEnums.USER],
    element: <CreateShipmentManual/>,
  },
  {
    path: '/crea-spedizione/dettaglio',
    exact: true,
    role: [RoleEnums.USER],
    element: <CreateShipmentDetails/>,
  },
  {
    path: '/profilo-personale',
    exact: true,
    role: [RoleEnums.USER],
    element: <UserProfile/>,
  },
  {
    path: '/supporto',
    exact: true,
    role: [RoleEnums.USER],
    element: <HelpContainer/>,
  },
  {
    path: '/recupera-password',
    exact: true,
    role: [],
    element: <RestorePassword/>,
  },
  {
    path: '/aggiorna-password',
    exact: true,
    role: [],
    element: <NewPass/>,
  },
  {
    path: '/crea-richiesta-di-preventivo',
    exact: true,
    role: [RoleEnums.GUEST],
    element: <ShipmentRequestStepOne/>,
  },
  {
    path: '/crea-richiesta-di-preventivo/conferma',
    exact: true,
    role: [RoleEnums.GUEST],
    element: <ShipmentRequestStepThee/>,
  },
  {
    path: '/dettaglio-preventivo/:id',
    exact: true,
    role: [RoleEnums.GUEST],
    element: <ShipmentDetails/>,
  },
  {
    path: '/tracking',
    exact: true,
    role: [RoleEnums.GUEST],
    element: <Tracking/>,
  },
  {
    path: '/conferma-pagamento/:id',
    exact: true,
    role: [RoleEnums.GUEST],
    element: <BankConfirm/>,
  },
  {
    path: '/privacy-policy',
    exact: true,
    role: [RoleEnums.GUEST],
    element: <PrivacyPolicy/>,
  },
  {
    path: '/',
    exact: true,
    role: [],
    element: <LoginForm/>,
  },
  {
    path: '*',
    exact: false,
    role: [RoleEnums.GUEST],
    element: <NotFound/>,
  },
];
