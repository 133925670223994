import { createSlice, createAction } from '@reduxjs/toolkit';
import { User, ChangePasswordPayload, PatchUser } from '../types';

export const requestUserData = createAction('requestUserData');
export const requestChangePass = createAction<ChangePasswordPayload>('requestChangePass');
export const updateUserData = createAction<PatchUser>('patchUserData');

interface AuthState {
  userData: User | null,
  changePassSuccess: boolean,
}

const initialState: AuthState = {
  userData: null,
  changePassSuccess: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setSuccessPasswordChange: (state, action) => {
      state.changePassSuccess = action.payload;
    },
  },
});

export const { setUserData, setSuccessPasswordChange } = settingsSlice.actions;

export default settingsSlice.reducer;
