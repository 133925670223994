import { useContext } from 'react';
import cn from 'classnames';
import { useDrag } from 'react-dnd';
import { SplitPalletsContext } from '../index';
import { ScaricoPalletsToSplitType } from '../../../../store/types';
import arrow from '../../../../assests/images/arrow.png';
import styles from './palletsTable.module.css';

export const ItemTypes = {
  TABLE_LINE: 'TABLE_LINE',
};

const RowItem = ({ pallet, palletIndex, tableIndex }: ScaricoPalletsToSplitType) => {
  const { splitPallets, splittedPalletInfo } = useContext(SplitPalletsContext);

  const [, drag] = useDrag(
    () => ({
      type: ItemTypes.TABLE_LINE,
      canDrag: splitPallets,
      item: { pallet, palletIndex, tableIndex },
    }),
    [splitPallets, splittedPalletInfo],
  );

  return (
    <div ref={drag} className={cn(styles.tr, {
      [styles.is_draggable]: splitPallets,
    })}>
      <>
        <div className={styles.td}>{ pallet.item_quantity }</div>
        <div className={styles.td}>{ pallet.depth }</div>
        <div className={styles.td}>{ pallet.height }</div>
        <div className={styles.td}>{ pallet.weight }</div>
        <div className={styles.td}>{ pallet.width }</div>
      </>
      { splitPallets && (
        <>
          <img className={cn(styles.arrow, styles.arrow_rotate)} src={arrow} alt="arrow" />
          <img className={styles.arrow} src={arrow} alt="arrow" />
        </>
      )}
    </div>
  );
};

export default RowItem;
