import { put, takeLatest } from 'redux-saga/effects';
import { GetPaymentLink } from '../../api/shipping';
import { setLoading, setPaymentLink, requestGetPaymentLink } from '../redux_slice/paymentLinkSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';

function* getPaymentLinkSaga(action: {
    payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetPaymentLink(action.payload);
    yield put(setPaymentLink(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* paymentLinkSaga() {
  yield takeLatest(requestGetPaymentLink, getPaymentLinkSaga);
}
