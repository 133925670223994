import { useMemo } from 'react';
import EditableTable from '../index';
import { MerceAdrType } from 'store/types';
import { EditableDetailsTableTypes } from 'enums';

type MerceAdrTableProps = {
  dataArray: MerceAdrType[] | null,
  updateOptionHandler?: (data) => void,
  editable?: boolean,
}

const MerceAdrTable = ({
  dataArray, updateOptionHandler, editable = true,
}: MerceAdrTableProps) => {
  const newTableData = useMemo(() => {
    if (dataArray?.length) {
      return dataArray.map((sourceItem, i) => ({
        key: `${sourceItem.idNumeroOnuMerceAdr}`,
        limited_qty: 'No',
        numeroOnu: sourceItem.numeroOnu,
        quantita: sourceItem.quantita,
        numeroColli: sourceItem.numeroColli,
        imballaggioMerceAdr: sourceItem.imballaggioMerceAdr,
        descrizioneNas: sourceItem.descrizioneNas,
      }));
    }
    if (dataArray?.length === 0) {
      return [{
        key: `${EditableDetailsTableTypes.merce_adr}_0`,
        limited_qty: 'Si',
        numeroOnu: '',
        quantita: '',
        numeroColli: '',
        imballaggioMerceAdr: '',
        descrizioneNas: '',
      }];
    }
    return [];
  }, [dataArray]);
  const parseString = (str: string) => {
    try {
      return JSON.parse(str);
    } catch (error) {
      return str;
    }
  };

  const parceSavedData = async (row, key: string) => {
    const parcedNumber = parseString(row.numeroOnu);
    const initPallets:MerceAdrType = {
      idNumeroOnuMerceAdr: parcedNumber?.idNumeroOnuMerceAdr || +key,
      numeroOnu: parcedNumber?.numeroOnu || parcedNumber,
      quantita: +row.quantita,
      numeroColli: +row.numeroColli,
      imballaggioMerceAdr: row.imballaggioMerceAdr,
      descrizioneNas: row.descrizioneNas || null,
    };

    let newDataArr:MerceAdrType[] = [];
    if (row.limited_qty === 'Si') {
      newDataArr = [];
    }
    if (row.limited_qty !== 'Si' && dataArray) {
      const rowIsAvailable = !!dataArray.find((item, i) => `${item.idNumeroOnuMerceAdr}` === key);
      if (rowIsAvailable) {
        newDataArr = dataArray.map((item, i) => (
          (`${item.idNumeroOnuMerceAdr}` === key) ? {
            ...initPallets,
          } : item));
      } else {
        newDataArr = [...dataArray, initPallets];
      }
    }
    if (row.limited_qty !== 'Si' && !dataArray) {
      newDataArr = [initPallets];
    }

    if (updateOptionHandler) {
      updateOptionHandler({ [EditableDetailsTableTypes.merce_adr]: newDataArr });
    }
    return newDataArr;
  };

  const deleteHandler = (key:string) => {
    if (updateOptionHandler) {
      const data = {
        [EditableDetailsTableTypes.merce_adr]: (dataArray?.length && dataArray?.length !== 1) ? dataArray?.filter((item, i) => `${EditableDetailsTableTypes.merce_adr}_${i}` !== key) : null,
      };
      updateOptionHandler(data);
    }
  };

  return (
    <EditableTable
      tableType={EditableDetailsTableTypes.merce_adr}
      dataSource={newTableData}
      parceSavedData={parceSavedData}
      deleteHandler={deleteHandler}
      initialFields={{
        key: `${EditableDetailsTableTypes.merce_adr}_${newTableData.length}`,
      }}
      editable={editable}
    />
  );
};

export default MerceAdrTable;
