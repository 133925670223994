import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import React from 'react';
import classnames from 'classnames';
import { Select } from 'antd';
import style from './users.module.css';
import Status from '../../components/Status';
import successIcon from '../../assests/images/successIcon.svg';
import { UserPriceListPayload } from '../../store/types';
import { RoleEnums } from '../../enums';

const { Option } = Select;

interface UsersTableInterface {
    creation_date: string,
    company_name: string,
    vat_number: string,
    shipment_count: number,
    price_list: UserPriceListPayload,
    status: string,
    access_privacy: string,
    options: JSX.Element,
}

export const makeUsersTableColumns = (t:TFunction, priceListTemplates, handleChange, typology)
    : ColumnsType<UsersTableInterface> => [
  {
    title: t('forms.users.creation_date'),
    dataIndex: 'creation_date',
    key: 'creation_date',
    render: (value) => format(new Date(value), 'dd-MM-yyyy'),
    sorter: (a, b) => new Date(a.creation_date).getTime() - new Date(b.creation_date).getTime(),
    align: 'center',
  },
  {
    title: t('forms.users.company_name'),
    dataIndex: 'company_name',
    key: 'company_name',
    render: (text) => <span className={classnames(style.bold, style.primary_color)}>
      {text}
    </span>,
    align: 'center',
  },
  {
    title: typology === 'Company' ? t('forms.users.vat_number') : t('forms.users.fiscal_code'),
    dataIndex: 'vat_number',
    key: 'vat_number',
    align: 'center',
  },
  {
    title: t('forms.users.shipment_generated'),
    dataIndex: 'shipment_count',
    key: 'shipment_count',
    render: (text) => <span className={style.bold}>
      {text}
    </span>,
    align: 'center',
  },
  {
    title: t('forms.users.associated_price_list'),
    dataIndex: 'price_list',
    key: 'price_list',
    render: (itemId) => <Select
      className={classnames('status_select', 'blue_status')}
      defaultValue={itemId.id}
      onSelect={(value) => handleChange(value, itemId.userId)}
    >
      {
        priceListTemplates.map((i) => (
          <Option key={i.id} value={i.id}>
            {i.name}
          </Option>
        ))
      }
    </Select>,
    align: 'center',
  },
  {
    title: t('forms.users.status'),
    dataIndex: 'status',
    key: 'status',
    render: (status) => <Status
      status={status}
    />,
    align: 'center',
  },
  {
    title: t('forms.users.access_private'),
    dataIndex: 'access_privacy',
    key: 'access_privacy',
    render: (status) => (status === RoleEnums.USER ? <img src={successIcon} alt="succes_img"/> : '-'),
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    align: 'center',
  },
];
