import { useTranslation } from 'react-i18next';
import {
  Button, Form, InputNumber,
} from 'antd';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { DeleteFilled } from '@ant-design/icons/lib/icons';
import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NamePath } from 'rc-field-form/es/interface';
import style from './ShipmentRequestStepOne.module.css';
import { Pallet } from '../../store/types';

interface ErrorShowInterface {
  isError: boolean,
  error: JSX.Element | null,
}

export const useDebounce = (fn: ()=>void, delay = 1000) => {
  const refFn = useRef(fn);
  refFn.current = fn;
  const refTimer = useRef<number>();
  const debounced = useCallback(() => {
    clearTimeout(refTimer.current);
    refTimer.current = (setTimeout(refFn.current, delay)) as unknown as number;
  }, [delay]);
  const cancel = useCallback(() => clearTimeout(refTimer.current), []);
  return useMemo(() => ({ debounced, cancel }), [debounced, cancel]);
};

export const PalletsWarningCondition: React.FC<{
  width: number,
  depth: number,
  widthName: NamePath,
  depthName: NamePath,
  onError?: () => void
}> = ({
  onError,
  width,
  depth,
  widthName,
  depthName,
}) => {
  const form = Form.useFormInstance();
  const [show, setShow] = useState<ErrorShowInterface>({ isError: false, error: null });
  const showMinError = () => {
    if (width < 60 || depth < 60) {
      setShow({
        isError: true,
        error: <div className={classNames(style.error_value, style.error_style)}>
          Misure minime consentite 60x60 per spedizioni pallet. Per quotazioni cartoni sfusi inviare mail a {' '}
          <a href="mailto:info@pesantisrl.it" className={style.error_style}>commercialeplt@pesantisrl.it</a>
        </div>,
      });
    }
  };

  const updateMaxError = useCallback(() => {
    setShow({ isError: false, error: null });
    if ((depth > 160 && width > 160)
      || (depth > 120 && width > 240)
      || (depth > 240 && width > 120)
      || depth > 300
      || width > 300
      || depth < 60
      || width < 60
    ) {
      setShow({
        isError: true,
        error: <div className={classNames(style.error_value, style.error_style)}>
          Misure massime consentite 300x120 o 240x160. Inviare mail a {' '}
          <a href="mailto:info@pesantisrl.it" className={style.error_style}>commercialeplt@pesantisrl.it</a>
          {' '} per fattibilità e prezzo.
        </div>,
      });
    }
  }, [depth, width]);
  const widthDebounce = useDebounce(() => {
    form.setFields([{
      name: widthName,
      value: 60,
    }]);
    showMinError();
  });
  const depthDebounce = useDebounce(() => {
    form.setFields([{
      name: depthName,
      value: 60,
    }]);
    showMinError();
  });

  useEffect(() => {
    if (width !== null && width < 60) {
      widthDebounce.debounced();
    } else {
      widthDebounce.cancel();
      if (width > 60) {
        updateMaxError();
      }
    }
    if (depth !== null && depth < 60) {
      depthDebounce.debounced();
    } else {
      depthDebounce.cancel();
      if (depth > 60) {
        updateMaxError();
      }
    }
  }, [width, depth, updateMaxError, widthDebounce, depthDebounce]);
  useEffect(() => {
    if (show.isError && onError) {
      onError();
    }
  }, [show.isError]);
  if (show.isError) {
    return show.error;
  }
  return null;
};

const PalletsWarning:React.FC<{index: number}> = ({ index }) => <Form.Item
  noStyle
  shouldUpdate={() => true}
>
  {({ getFieldValue, setFields }) => {
    const pallet: (Pallet | null) = getFieldValue(['pallets', index]);
    if (!pallet) return null;
    return <PalletsWarningCondition
      {...pallet}
      widthName={['pallets', index, 'width']}
      depthName={['pallets', index, 'depth']}
    />;
  } }
</Form.Item>;

const PalletDetails:
    React.FC<{ index: number, isDeletePallet: boolean, setCountArray: (index) => void, arrayLength: number }> = ({
      index,
      isDeletePallet,
      setCountArray,
      arrayLength,
    }) => {
      const { t } = useTranslation();
      return (
        <div key={index}>
          <div className={style.palleteDetails}>
            <Form.Item
              label={t('forms.shipmentRequest.quantity')}
              name={['pallets', index, 'item_quantity']}
              className={style.select}
              rules={
                [
                  { required: true, message: t('validation.empty') },
                ]
              }
            >
              <InputNumber
                className={style.input}
                min={1}
                max={100}
              />
            </Form.Item>
            <Form.Item
              label={t('forms.shipmentRequest.shortSide')}
              name={['pallets', index, 'depth']}
              className={style.detailsItem}
              rules={
                [
                  { required: true, message: t('validation.empty') },
                ]
              }
            >
              <InputNumber
                className={style.input}
                addonAfter="Cm"
                min={0}
              />
            </Form.Item>
            <Form.Item
              label={t('forms.shipmentRequest.longSide')}
              name={['pallets', index, 'width']}
              className={style.detailsItem}
              rules={
                [{ required: true, message: t('validation.empty') }]
              }
            >
              <InputNumber
                className={style.input}
                addonAfter="Cm"
                min={0}
              />
            </Form.Item>
            <Form.Item
              label={t('forms.shipmentRequest.height')}
              name={['pallets', index, 'height']}
              className={style.detailsItem}
              rules={
                [{ required: true, message: t('validation.empty') }]
              }
            >
              <InputNumber
                className={style.input}
                addonAfter="Cm"
                min={5}
                max={240}
              />
            </Form.Item>
            <Form.Item
              label={t('forms.shipmentRequest.weight')}
              name={['pallets', index, 'weight']}
              className={style.detailsItem}
              rules={
                [{ required: true, message: t('validation.empty') }]
              }
            >
              <InputNumber
                className={style.input}
                addonAfter="Kg"
                min={1}
                max={1800}
              />
            </Form.Item>
            {
              isDeletePallet
                    && arrayLength > 1
                    && <Form.Item>
                      <Button
                        key={index}
                        type="link"
                        icon={<DeleteFilled/>}
                        className={classNames(style.deleteBtn)}
                        onClick={() => setCountArray(index)}
                      />
                    </Form.Item>
            }
          </div>
          <PalletsWarning index={index}/>
        </div>
      );
    };

export default PalletDetails;
