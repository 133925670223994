import { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Button, Col, Input, Row, Form,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, DeleteFilled } from '@ant-design/icons/lib/icons';
import OptionsButton, { Option } from '../OptionsButton';
import { DeliveryType } from 'enums';
import style from './noteContainer.module.css';

type OnChangeParams = {
  note_ritiro: string | null,
  note_consegna: string | null,
}

type NoteProps = {
  noteRitiro: string | null,
  noteConsegna: string | null
  ritiroTime: string,
  deliveryType?: string,
  onChangeNote?: (data: OnChangeParams) => void,
  isEdit?: boolean,
}
const NoteContainer = ({
  noteRitiro, noteConsegna, ritiroTime, deliveryType, onChangeNote, isEdit = true,
}: NoteProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      note_ritiro: noteRitiro,
      note_consegna: noteConsegna,
    });
  }, [noteRitiro, noteConsegna, form]);

  const onEditAction = () => {
    setIsEditable(true);
  };
  const onCloseEditableField = () => {
    setIsEditable(false);
    form.resetFields();
  };
  const onDeleteAction = () => {
    if (onChangeNote) {
      onChangeNote({
        note_ritiro: null,
        note_consegna: null,
      });
      form.resetFields();
    }
  };

  const onFinish = (values) => {
    if (onChangeNote) {
      const ririroNote = (deliveryType === DeliveryType.RITIRO && !values.note_ritiro.includes(t('forms.shipmentRequest.start_shipment_time_note')))
        ? `${t('forms.shipmentRequest.start_shipment_time_note')}: ${ritiroTime}, ${values.note_ritiro}` : values.note_ritiro;
      onChangeNote({
        note_ritiro: ririroNote,
        note_consegna: values.note_consegna,
      });
      onCloseEditableField();
      form.resetFields();
    }
  };

  const options: Array<Option> = [
    {
      title: t('forms.estimations.edit'),
      action: onEditAction,
    },
    {
      title: t('forms.estimations.delete'),
      action: onDeleteAction,
    },
  ];

  const renderOptionButtons = () => {
    if (isEditable) {
      return (
        <>
          <Button
            htmlType="submit"
            type="primary"
            shape="round"
            icon={<CheckOutlined/>}
            className={style.iconBtn}
          />
          <Button
            type="primary"
            shape="round"
            icon={<DeleteFilled/>}
            className={classnames(style.iconBtn, style.deleteBtn)}
            onClick={onCloseEditableField}
          />
        </>
      );
    }
    return (
      <Col span={1} offset={2} className={style.options_padding}>
        <OptionsButton id={'1'} options={options}/>
      </Col>
    );
  };

  return (
    <div className={style.container}>
      <Row className={style.note_header}>
        <Col offset={1} className={style.note_title}>Note</Col>
      </Row>
      <div className={style.content_container}>
        <Row>
          <Col span={9} offset={1} className={style.note_delivery_type}>Ritiro</Col>
          <Col span={9} offset={1} className={style.note_delivery_type}>Consegna</Col>
        </Row>

        <Form
          form={form}
          initialValues={{
            note_ritiro: noteRitiro,
            note_consegna: noteConsegna,
          }}
          onFinish={onFinish}
        >
          <Row className={style.options_padding}>
            <Col span={9} offset={1}>
              { isEditable
                ? <Form.Item name="note_ritiro" style={{ margin: 0 }}>
                  <Input.TextArea placeholder={t('placeholder.note')}/>
                </Form.Item>
                : <span>{noteRitiro}</span>
              }
            </Col>
            <Col span={9} offset={1}>
              { isEditable
                ? <Form.Item name="note_consegna" style={{ margin: 0 }}>
                  <Input.TextArea placeholder={t('placeholder.note')}/>
                </Form.Item>
                : <span>{noteConsegna}</span>
              }
            </Col>
            <Col span={3} offset={1} className={style.btn_container}>{ isEdit && renderOptionButtons() }</Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default NoteContainer;
