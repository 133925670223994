import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { EditableSelectStateless } from '../../../components/EditableSelect/EditableSelect';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { Option } from '../../../store/types';
import {
  clearTransportType,
  requestCreateTransportType, requestDeleteTransportType,
  requestGetTransportType, requestUpdateTransportType,
} from '../../../store/redux_slice/transport/transportTypeSlice';
import TransportTypesForm from './TransportTypesForm';
import DeleteModal from '../../../components/DeleteModal';

const useIncreasedEffect = (count: number | null, callback:()=>void) => {
  const prevArray = useRef(count);
  useEffect(() => {
    if (prevArray.current !== null && count !== null && prevArray.current < count) {
      callback();
    }
    prevArray.current = count;
  }, [count, callback]);
};

const TransportTypesSelect:React.FC<{
  truckTypeId: string,
  setTruckTypeId: (value: string) => void,
}> = ({ truckTypeId, setTruckTypeId }) => {
  const dispatch = useAppDispatch();
  const transportTypes = useAppSelector((state) => state.transportTypes?.transportTypes) || [];
  const transportById = useAppSelector((state) => state.transportById.transportById);
  const transportType = transportTypes?.find((e) => e.id === Number(truckTypeId));
  const [isTransportTypesForm, setIsTransportTypesForm] = useState<boolean>(false);
  const [isDeleteTransportType, setIsDeleteTransportType] = useState<boolean>(false);
  const options: Option[] = transportTypes.map((c) => ({ id: c.id, name: c.type, isEditable: true }));
  const { t } = useTranslation();
  const onTransportDidAdd = useCallback(() => {
    if (!transportTypes) return;
    const last = transportTypes[transportTypes.length - 1];
    if (!last) return;
    setTruckTypeId(String(last.id));
  }, [setTruckTypeId, transportTypes]);
  useIncreasedEffect(transportTypes?.length ?? null, onTransportDidAdd);
  useEffect(() => {
    if (transportById?.truck_type?.id) {
      setTruckTypeId(transportById?.truck_type?.id.toString());
    }
  }, [transportTypes]);
  const onCheckHandler = (v: string) => {
    setTruckTypeId(v);
  };
  const onAddHandler = () => {
    setIsTransportTypesForm(true);
  };

  const onEditHandler = (v: string) => {
    setIsTransportTypesForm(true);
    dispatch(requestGetTransportType(v));
    setTruckTypeId(v);
  };

  const onDeleteHandler = (v: string) => {
    setIsDeleteTransportType(true);
    setTruckTypeId(v);
  };
  const onCloseModal = () => {
    dispatch(clearTransportType());
    setIsTransportTypesForm(false);
  };
  const onFinish = (values) => {
    const data = {
      id: truckTypeId,
      data: {
        type: values.name,
      },
    };
    if (transportType) {
      dispatch(requestUpdateTransportType(data));
    } else {
      dispatch(requestCreateTransportType({ type: values.name }));
    }
    setIsTransportTypesForm(false);
  };
  const onDeleteTransportType = () => {
    dispatch(requestDeleteTransportType(truckTypeId));
    setIsDeleteTransportType(false);
  };
  return (
    <>
      <DeleteModal
        title={t('forms.transports.delete_transport_type')}
        description={t('forms.transports.delete_transport_type_description')}
        visible={isDeleteTransportType}
        onCancel={() => setIsDeleteTransportType(false)}
        onDelete={onDeleteTransportType}/>
      <TransportTypesForm
        visible={isTransportTypesForm}
        onCancel={onCloseModal}
        onFinish={onFinish}
      />
      <EditableSelectStateless
        name={options.find((e) => e.id?.toString() === truckTypeId)?.name ?? ''}
        options={options}
        onCheck={onCheckHandler}
        onAdd={onAddHandler}
        onEdit={onEditHandler}
        onDelete={onDeleteHandler}
      />
    </>
  );
};

export default TransportTypesSelect;
