import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Form, Input, Button } from 'antd';
import { useEffect, useState } from 'react';
import style from './help.module.css';
import CustomButton from '../../components/CustomButton/CustomButton';
import FaqSection from '../../components/FaqModal';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestUserSupport, setUserSupportSuccess } from '../../store/redux_slice/users/usersSlice';
import { setMessage } from '../../store/redux_slice/messageErrorSlice';
import { UserSupport } from '../../store/types';

const HelpContainer = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const supportSuccess = useAppSelector((state) => state.users.supportSuccess);

  const [visible, setVisible] = useState<boolean>(false);

  const onFinish = ({ subject, body }: UserSupport) => {
    dispatch(requestUserSupport({ subject: subject.trim(), body: body.trim() }));
  };

  useEffect(() => {
    if (supportSuccess) {
      form.resetFields();
      dispatch(setMessage({ description: t('forms.helpForm.confirmEmail') }));
    }

    return () => {
      dispatch(setUserSupportSuccess(false));
    };
  }, [form, dispatch, supportSuccess, t]);

  const validator = (c, v) => (v?.trim() ? Promise.resolve() : Promise.reject());

  return (
    <>
      <FaqSection
        visible={visible}
        onCancel={() => setVisible(false)}
      />
      <div className={classnames(style.help_container, 'customForm')}>
        <div className={style.faq_container}>
          <div className={style.faq_text}>{t('forms.helpForm.faqText1')}
            <br/>
            {t('forms.helpForm.faqText2')}
            <br/>
            {t('forms.helpForm.faqText3')}</div>
          <CustomButton
            type="primary"
            className={style.faqBtn}
            onClick={() => setVisible(true)}
          >
            {t('forms.helpForm.faqButton')}
          </CustomButton>
          <Button
            className={style.faqBtn}
            type="primary"
            href="/files/Documentazione_Area_Privata_cliente_Trasporti_Pesanti.pdf"
            target="_blank"
            download
          >
            {t('forms.helpForm.download_guide')}
          </Button>
        </div>
        <div className="main-title-30px">
          {t('forms.helpForm.submitSupportRequest')}
        </div>
        <Form
          form={form}
          layout="vertical"
          className={style.help_form}
          onFinish={onFinish}
        >
          <Form.Item
            label={t('forms.helpForm.requestSubject')}
            name="subject"
            className={style.help_form_input}
            rules={[{
              validator,
              required: true,
              message: t('forms.helpForm.requiredSubject'),
            }]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            name="body"
            rules={[{
              validator,
              required: true,
              message: t('forms.helpForm.requiredBody'),
            }]}
          >
            <Input.TextArea
              className={style.help_form_textarea}
              placeholder={t('forms.helpForm.placeholder')}
            />
          </Form.Item>
          <CustomButton
            htmlType="submit"
            type="primary"
            className={style.helpBtn}
          >
            {t('forms.helpForm.submitButton')}
          </CustomButton>
        </Form>
      </div>
    </>
  );
};

export default HelpContainer;
