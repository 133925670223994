import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PatchEstimation, PatchShipmentStructure, ShipmentsListStructure, ShippingEstimateCreatePayload,
  UpdateShippingEstimateRequest, ShipmentStructure,
} from '../../types';

export const updateToBeApprovedShipmentAction = createAction<PatchEstimation>('updateToBeApprovedShipmentAction');
export const requestUpdateToBeApprovedShippingEstimate = createAction<UpdateShippingEstimateRequest>('requestUpdateToBeApprovedShippingEstimate');
export const requestGetToBeApprovedShippingEstimate = createAction<string>('requestGetToBeApprovedShippingEstimate');
export const requestCreateToBeApprovedShippingEstimate = createAction<ShipmentStructure>('requestCreateToBeApprovedShippingEstimate');
export const approveToBeApprovedShipmentAction = createAction<PatchEstimation>('approveToBeApprovedShipmentAction');
export const requestCreatePriceToBeApprovedShippingEstimate = createAction<ShippingEstimateCreatePayload>('requestCreatePriceToBeApprovedShippingEstimate');

type State = {
    toBeApproved: ShipmentsListStructure | null,
}

const initialState: State = {
  toBeApproved: null,
};

const shipmentsBeforeApproveSlice = createSlice({
  name: 'shippingBeforeApprove',
  initialState,
  reducers: {
    setToBeApprovedShipmentsData: (state, action: PayloadAction<ShipmentsListStructure | null>) => {
      state.toBeApproved = action.payload;
    },
    patchToBeApprovedSlice: (state, action: PayloadAction<PatchShipmentStructure>) => {
      if (!state.toBeApproved?.items) return;
      const index = state.toBeApproved.items.findIndex((e) => e.id === action.payload.id);
      if (index === -1) return;
      const el = state.toBeApproved.items[index];
      state.toBeApproved.items[index] = {
        ...el,
        ...action.payload.data,
      };
    },
  },
});

export const {
  setToBeApprovedShipmentsData,
  patchToBeApprovedSlice,
} = shipmentsBeforeApproveSlice.actions;

export default shipmentsBeforeApproveSlice.reducer;
