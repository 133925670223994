import { put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  requestUserData, requestChangePass, setUserData, updateUserData, setSuccessPasswordChange,
} from '../redux_slice/settingsSlice';
import { GetUser, PostNewPassword, UpdateUserSettings } from '../../api';
import { ChangePasswordPayload, PatchUser } from '../types';
import { setMessage } from '../redux_slice/messageErrorSlice';
import { clearSession } from '../redux_slice/authSlice';

function* patchUserDataSaga(action: {
  payload: PatchUser
}) {
  try {
    yield UpdateUserSettings(action.payload);
    if (action.payload.data.email) {
      yield put(setMessage({ description: i18n.t('messages.change_email') }));
      yield put(clearSession());
      localStorage.clear();
    }
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* getUserDataSaga() {
  try {
    const { data } = yield GetUser();
    yield put(setUserData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* changePasswordSaga(action: {
  payload: ChangePasswordPayload
}) {
  try {
    yield PostNewPassword(action.payload);
    yield put(setSuccessPasswordChange(true));
    yield put(setMessage({ description: i18n.t('messages.change_password') }));
  } catch (e) {
    yield put(setMessage({ description: i18n.t('messages.login_error') }));
  }
}

export function* settingsSaga() {
  yield takeLatest(requestUserData, getUserDataSaga);
  yield takeLatest(requestChangePass, changePasswordSaga);
  yield takeLatest(updateUserData, patchUserDataSaga);
}
