import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { EstimationFieldModal, useModalState } from '../../Estimations/hooks';
import { OpenModalButton } from '../../Estimations/OpenModalButton';
import ExtraFinanceModal from '../components/ExtraFinanceModal';
import FinanceExtraTable from '../components/FinanceExtraTable';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
  clearExtraFinaceModalInfo, postExtraFinanceApproveAction, postExtraFinanceDisapproveAction,
  deleteExtraFinanceAction,
} from '../../../store/redux_slice/financeSlice';
import { ExtraFinanceType } from '../../../store/types';
import style from '../invoices.module.css';

const ExtraServiceInfo = () => {
  const { t } = useTranslation();
  const {
    openModal,
    isOpen,
    closeModal,
  } = useModalState();
  const dispatch = useAppDispatch();
  const { extraFinanceList, successExtraFinanceModal } = useAppSelector((state) => state.financeSection);
  const [extraFinanceEdit, setExtraFinanceEdit] = useState<ExtraFinanceType | null>(null);

  useEffect(() => {
    if (successExtraFinanceModal) {
      closeModal();
      dispatch(clearExtraFinaceModalInfo());
    }
  }, [dispatch, closeModal, successExtraFinanceModal]);

  const onOpenExtraFinanceModal = (id: number) => {
    const serviceBiId = extraFinanceList?.find((item) => (
      item.id === id
    ));

    if (serviceBiId) {
      openModal(EstimationFieldModal.openExtraFinanceModal);
      setExtraFinanceEdit(serviceBiId);
    }
  };

  const onCancelExtraFinanceModal = () => {
    closeModal();
    if (extraFinanceEdit) {
      setExtraFinanceEdit(null);
    }
  };

  return (
    <>
      <ExtraFinanceModal
        title={t('invoices.add_extra')}
        visible={isOpen(EstimationFieldModal.openExtraFinanceModal)}
        onCancel={onCancelExtraFinanceModal}
        extraFinanceEdit={extraFinanceEdit}
        success={successExtraFinanceModal}
      />
      <FinanceExtraTable
        extraData={extraFinanceList || []}
        approve={(id: number) => dispatch(postExtraFinanceApproveAction(id))}
        disapprove={(id: number) => dispatch(postExtraFinanceDisapproveAction(id))}
        deleteExtra={(id:number) => dispatch(deleteExtraFinanceAction(id))}
        editModal={onOpenExtraFinanceModal}
      />
      <OpenModalButton
        modal={EstimationFieldModal.openExtraFinanceModal}
        openModal={openModal}
        icon={<PlusOutlined/>}
        className={style.add_extra_finance}
      >
        {t('invoices.add_extra')}
      </OpenModalButton>
    </>
  );
};

export default ExtraServiceInfo;
