import { useState } from 'react';
import ShipmentInformation from './ShipmentInformation';
import ShipmentUploadContainer from './ShipmentUploadContainer';

const ShipmentFooterContainer = () => {
  const [isDownloadFinish, setIsDownloadFinish] = useState(false);
  return (
    <>
      {
        !isDownloadFinish
          ? <ShipmentUploadContainer
            setIsDownloadFinish={() => setIsDownloadFinish(true)}
          />
          : <ShipmentInformation/>
      }
    </>
  );
};

export default ShipmentFooterContainer;
