import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserForPricelist } from '../types';

export const requestGetUserPriceList = createAction<string>('requestGetUserPriceList');

type State = {
  userPriceList: UserForPricelist | null,
  loading: boolean,
}

const initialState: State = {
  userPriceList: null,
  loading: false,
};

const userPriceListSlice = createSlice({
  name: 'priceListTemplates',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUserPriceListSliceData: (state, action: PayloadAction<UserForPricelist | null>) => {
      state.userPriceList = action.payload;
    },
    clearUserPriceListSliceData: (state) => {
      state.userPriceList = null;
    },
  },
});

export const { setUserPriceListSliceData, setLoading, clearUserPriceListSliceData } = userPriceListSlice.actions;

export default userPriceListSlice.reducer;
