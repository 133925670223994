import { Table } from 'antd';

import { useTranslation } from 'react-i18next';
import OptionsButton, { Option } from '../../../components/OptionsButton';
import { ContractorType, ShipmentStructure, UserStructure } from '../../../store/types';
import { makeColumnsApprove } from '../components/TableColumns';
import PallexErrorSvg from '../../../assests/svg/PallexErrorSvg';
import styles from '../deliveries.module.css';

const ShippingApprovedTable = ({
  options,
  approvedShipments,
  page,
  count,
  setPage,
  setCount,
  total,
  operatorsList,
  pallexOptions,
  onChange,
  pallexItaly,
  pallexItalyTP,
  contractors,
} : {
  options: Array<Option>,
  approvedShipments: ShipmentStructure[],
  page: number,
  count: number,
  setPage: (number) => void,
  setCount: (number) => void,
  total: number,
  operatorsList: UserStructure[]
  pallexOptions: Array<Option>,
  onChange: (status, shipment) => void,
  pallexItaly: ContractorType,
  pallexItalyTP: ContractorType,
  contractors: ContractorType[]
}) => {
  const onChangePageSize = (current, size) => {
    setCount(size);
  };
  const { t } = useTranslation();
  const pagination = {
    current: page,
    pageSize: count,
    pageSizeOptions: [10, 15, 20],
    showSizeChanger: true,
    total,
    onChange: setPage,
    onShowSizeChange: onChangePageSize,
  };
  const data = approvedShipments
    .map((shipment) => {
      const {
        id,
        pallets,
        delivery_type: type,
        withdrawal_date: withdrawal,
        start_shipment_date: startDate,
        trips,
        user_id: userId,
        user,
        pallex_error: pallexError,
      } = shipment;
      const drivers = trips?.length ? trips.map((driver) => operatorsList.filter((userItem) => userItem.id === driver.truck?.driver_id)[0]?.name) : '-';
      const isNonStop = shipment.options.filter((el) => el.title?.toUpperCase() === 'SERVIZIO NON-STOP/ESPRESSO' && el.isActive).length > 0;
      const selectedContractor = contractors?.find((el) => (
        el.id === shipment.contractor[0]?.ResContract?.contractor_id
      ));
      const contractorIsPallex = selectedContractor?.id === pallexItaly?.id
          || selectedContractor?.id === pallexItalyTP?.id || selectedContractor?.is_editable;
      const contractorIsTPPallex = shipment.contractor[0]?.ResContract?.contractor_id === pallexItalyTP?.id;
      return {
        key: id,
        pallex_id: shipment?.tracking_code?.includes('IT') ? shipment.tracking_code : '',
        id: shipment.spedizione_id || shipment.id,
        number_pallet: pallets.map((item) => `${item.item_quantity} - ${item.family}`),
        pickupName: shipment.pickup_address?.company_name || shipment.pickup_address?.name_and_surname_reference || '-',
        shippingName: shipment.shipping_address?.company_name || shipment.shipping_address?.name_and_surname_reference || '-',
        approved_date: shipment.approvato_il,
        recipient_name: user?.name || shipment.pallex_client_name || '-',
        start_date: startDate || '',
        withdrawal_date: withdrawal || '',
        type: { type, applicant: shipment.user_id ? t('forms.deliveries.customer') : 'Pallex' },
        applicant: { nonStop: isNonStop, applicant: shipment.user_id ? t('forms.deliveries.customer') : 'Pallex' },
        status: {
          shipment, applicant: shipment.user_id ? t('forms.deliveries.customer') : 'Pallex', contractorIsPallex, contractorIsTPPallex,
        },
        drivers,
        pallex_error: !pallexError ? '' : <PallexErrorSvg className={styles.pallex_error_image_table}/>,
        options: (
          <OptionsButton
            id={id.toString()}
            userId={userId?.toString()}
            options={shipment.user_id ? options : pallexOptions}
          />
        ),
      };
    });
  const columns = makeColumnsApprove(t, onChange);
  return (
    <Table
      rowClassName={(record, index) => (record.applicant.nonStop ? 'non_stop_background' : '')}
      columns={columns}
      pagination={pagination}
      dataSource={data}/>
  );
};

export default ShippingApprovedTable;
