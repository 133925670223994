import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { CreateOperatorPayload, UpdateUserPayload, UserStructure } from '../../types';

export const requestCreateOperator = createAction<{user: CreateOperatorPayload, isGetAllUsers: boolean}>('requestCreateOperator');
export const requestGetOperator = createAction<string>('requestGetOperator');
export const requestDeleteOperator = createAction<{ id: string, isGetAllUsers: boolean}>('requestDeleteOperator');
export const requestUpdateOperator = createAction<{ user: UpdateUserPayload, isGetAllUsers: boolean}>('requestUpdateOperator');

type State = {
    operator: UserStructure | null
    loading: boolean,
}
const initialState : State = {
  operator: null,
  loading: false,
};

const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOperator: (state, action: PayloadAction<UserStructure>) => {
      state.operator = action.payload;
    },
    clearOperator: (state) => {
      state.operator = null;
    },
  },
});

export const { setOperator, setLoading, clearOperator } = operatorSlice.actions;

export default operatorSlice.reducer;
