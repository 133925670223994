import { format } from 'date-fns';
import { Pallet, ShippingRequestPayload } from 'store/types';

const convertDate = (value) => {
  let defaultTimeValue;
  if (value !== 'undefined') {
    const timeStartStr = value.split(',')[0].trim();
    const timeEndStr = value.split(',')[1].trim();
    const timeStart = format(new Date(timeStartStr), 'HH:mm');
    const timeEnd = format(new Date(timeEndStr), 'HH:mm');
    if (timeStart && timeEnd) {
      defaultTimeValue = `${timeStart} - ${timeEnd}`;
    }
  }
  return defaultTimeValue;
};

export const formToShippingRequestPayload = (
  values: Record<string, unknown>,
): Omit<ShippingRequestPayload, 'pickup_address'| 'shipping_address' | 'options' > => ({
  email: String(values.email),
  pallets: (values.pallets as Pallet[])
    ?.filter(Boolean)
    ?.map((e) => ({
      item_quantity: Number(e.item_quantity) || 0,
      depth: Number(e.depth) || 0,
      height: Number(e.height) || 0,
      width: Number(e.width) || 0,
      weight: Number(e.weight) || 0,
      price: Number(e.price) || 0,
    })),
  phone: String(values.phone),
  start_shipment_date: values.start_shipment_date ? format(new Date(values.start_shipment_date as Date), 'yyyy-MM-dd') : null,
  withdrawal_date: values.withdrawal_date ? format(new Date(values.withdrawal_date as Date), 'yyyy-MM-dd') : null,
  user_type: String(values.user_type),
  epal: Number(values.epal_count) || undefined,
  facchinaggio: Number(values.facchinaggio) || undefined,
  ore_note: convertDate(String(values.e_orario_time)) || undefined,
  contrassegno: {
    value: Number(values.contrassegno_amount),
    id: Number(values.contrassegno_id),
  } || undefined,
});
