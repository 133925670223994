import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { clearMessage } from '../../store/redux_slice/messageErrorSlice';
import styles from './errorMessage.module.css';

const InformationModal = () => {
  const { t } = useTranslation();
  const message = useAppSelector((state) => state.messageError.message);
  const dispatch = useAppDispatch();
  const onCloseMessage = () => {
    dispatch(clearMessage());
  };
  if (!message) return null;

  return (
    <Modal title={message?.title || t('messages.info')}
      visible={!!message}
      onCancel={onCloseMessage}
      footer={null}
      className={styles.error_style}
    >
      {message?.description}
    </Modal>
  );
};

export default InformationModal;
