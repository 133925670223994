import { Space } from 'antd';
import style from './loader.module.css';

const Loader = ({ title }: { title?: string}) => (
  <Space className={style.loaderContainer} direction="vertical">
    <div className={style.loader}></div>
    {title && <p>{title}</p>}
  </Space>
);

export default Loader;
