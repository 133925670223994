import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './transports.module.css';

interface ConfirmDriverModalInterface {
    title: string,
    description: string,
    visible: boolean,
    onCancel: () => void,
    onOk: () => void,
    onNo: () => void,
}

const ConfirmDriverModal:React.FC<ConfirmDriverModalInterface> = ({
  title, description, visible, onCancel, onOk, onNo,
}) => {
  const { t } = useTranslation();
  return (
    <Modal title={title}
      visible={visible}
      onOk={onOk}
      footer={[
        <Button key="no" onClick={onNo}>
          {t('forms.transports.no')}
        </Button>,
        <Button key="yes" type="primary" onClick={onOk}>
          {t('forms.transports.yes')}
        </Button>,
      ]}
      closeIcon={<div className={styles.hide_modal_close_icon} />}
    >
      {description}
    </Modal>
  );
};

export default ConfirmDriverModal;
