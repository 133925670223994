import { TripStructure, Pallet } from '../../store/types';
import { PallexStatuses, TPStatuses } from '../../enums';

export const checkIsSplit = (trip: TripStructure | null) => {
  if (!trip) return true;
  const { status } = trip;
  const tripStatus = status?.toUpperCase();

  if (tripStatus === PallexStatuses.Presso_HUB.toUpperCase()
    || tripStatus === PallexStatuses.In_attesa_di_ritiro.toUpperCase()
    || tripStatus === TPStatuses.In_magazzino.toUpperCase()
  ) return true;

  return false;
};

export const inPendingDelivery = (trip: TripStructure | null) => {
  if (!trip) return false;
  const { status } = trip;
  const tripStatus = status?.toUpperCase();

  if (tripStatus === TPStatuses.In_consegna.toUpperCase()) return true;

  return false;
};

export const getPalletsSumm = (pallets: Pallet[], trip: TripStructure | null) => (
  trip ? pallets.filter((item) => {
    const truckIdDeliveryType = `truck_id_${trip.delivery_type?.toLowerCase()}`;
    const isDeliveredDeliveryType = `is_delivered_${trip.delivery_type?.toLowerCase()}`;
    if (trip.date_end) {
      return item[isDeliveredDeliveryType];
    }
    return item[truckIdDeliveryType] === trip.truck_id && !item[isDeliveredDeliveryType];
  }).reduce((summ, cur) => summ + +cur.item_quantity, 0)
    : pallets.reduce((summ, cur) => summ + +cur.item_quantity, 0)
);
