import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import React from 'react';
import { Form, Input } from 'antd';
import style from './profile.module.css';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestUpdateSuperAdminParams } from '../../store/redux_slice/superAdminSlice';
import { SuperAdminParams } from '../../store/types';
import Loader from '../../components/Loader';

const SuperAdminForm:React.FC<{
    superAdminParams: SuperAdminParams | null
}> = ({ superAdminParams }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const superAdminParamsLoading = useAppSelector((state) => state.superAdmin.loading);

  if (superAdminParamsLoading) {
    return <Loader/>;
  }
  const onFinish = (values: SuperAdminParams) => {
    dispatch(requestUpdateSuperAdminParams(values));
  };
  return (
    <div className={style.super_admin_container}>
      <div className={classnames('main-title-30px', style.blue)}>
        {t('operatorSettings.support_data')}
      </div>
      <Form
        className={style.super_admin_form}
        name="superAdminForm"
        layout="vertical"
        initialValues={{
          email: superAdminParams?.email,
          phone: superAdminParams?.phone,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label={t('operatorSettings.email')}
          name="email"
          className={classnames(style.text_style, style.blue)}
          rules={
            [
              { required: true, message: t('validation.email') },
              { type: 'email', message: t('validation.email') },
              { whitespace: true, message: t('validation.email') },
            ]
          }
        >
          <Input
            className={style.input}
          />
        </Form.Item>
        <Form.Item
          label={t('operatorSettings.phone')}
          name="phone"
          className={classnames(style.text_style, style.blue)}
          rules={
            [
              { required: true, message: t('validation.phone') },
            ]
          }
        >
          <Input
            className={style.input}
          />
        </Form.Item>
        <CustomButton
          type="primary"
          htmlType="submit"
          className={style.confirmBtn}
        >
          {t('operatorSettings.contact_update')}
        </CustomButton>
      </Form>
    </div>
  );
};

export default SuperAdminForm;
