import { put, takeLatest } from 'redux-saga/effects';
import { setLoading, setOperatorsList, requestOperatorsList } from '../../redux_slice/operators/operatorsListSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';
import { GetAllUsers } from '../../../api/users';

function* getOperatorsListSaga(action: {
  payload: boolean
}) {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetAllUsers(action.payload));
    yield put(setOperatorsList(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* operatorsListSaga() {
  yield takeLatest(requestOperatorsList, getOperatorsListSaga);
}
