import { Drawer as AntdDrawer } from 'antd';
import './drawer.css';

const Drawer = ({
  isOpen, onClose, title, children,
}) => (
  <AntdDrawer
    title={title}
    placement="right"
    onClose={onClose}
    visible={isOpen}
  >
    {children}
  </AntdDrawer>
);

export default Drawer;
