import React, { useEffect } from 'react';
import { Form, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/CustomButton/CustomButton';
import style from '../estimations.module.css';

type ChangeShippingClientCodeType = {
  visible: boolean,
  onCancel: () => void,
  onChangeCode: (text: string) => void,
  initialValue: string | null,
}

export const ChangeShippintClientCode: React.FC<ChangeShippingClientCodeType> = ({
  visible, onCancel, onChangeCode, initialValue,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      codice_cliente_spedizione: initialValue || '',
    });
  }, [form, initialValue]);

  const submit = (values) => {
    onChangeCode(values.codice_cliente_spedizione);
    onCancel();
  };

  return (
    <Modal title={t('forms.shipmentRequest.shipping_client_code')}
      visible={visible}
      onOk={submit}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        onFinish={submit}
      >
        <Form.Item name="codice_cliente_spedizione">
          <Input placeholder={t('forms.shipmentRequest.optional')}/>
        </Form.Item>
        <CustomButton
          className={style.modal_button}
          type="primary"
          htmlType="submit"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};
