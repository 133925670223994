import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { MonitoringListPayload, MonitoringStructure } from '../types';

export const requestGetMonitoringList = createAction<MonitoringListPayload>('requestGetMonitoringList');
export const requestGetMonitoringCSV = createAction<MonitoringListPayload>('requestGetMonitoringCSV');

type State = {
    monitoringList: MonitoringStructure | null,
    loading: boolean,
}

const initialState : State = {
  monitoringList: null,
  loading: false,
};

const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setMonitoringList: (state, action: PayloadAction<MonitoringStructure | null>) => {
      state.monitoringList = action.payload;
    },
  },
});

export const { setLoading, setMonitoringList } = monitoringSlice.actions;

export default monitoringSlice.reducer;
