import React from 'react';
import { EditFilled } from '@ant-design/icons';
import CustomButton from '../../components/CustomButton/CustomButton';
import { EstimationFieldModal } from './hooks';

export const OpenModalCustomButton: React.FC<{
    modal:EstimationFieldModal;
    openModal: (modal:EstimationFieldModal) => void;
    icon?: React.ReactNode;
    className?: string;
}> = ({
  modal,
  openModal,
  icon = <EditFilled />,
  className,
  children,
}) => <CustomButton
  className={className}
  type="link"
  icon={icon}
  onClick={() => openModal(modal)}
>{children}</CustomButton>;
