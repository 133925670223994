import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Input } from 'antd';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { SplitPalletsContext } from '../index';
import { ScaricoPalletsToSplitType } from '../../../../store/types';
import styles from './splitModal.module.css';

const SplitModal = ({ visible, setVisible, palletsToSplit }: {
  visible: boolean,
  setVisible: (boolean) => void,
  palletsToSplit: ScaricoPalletsToSplitType | null,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { setPalletsData } = useContext(SplitPalletsContext);

  const onFinish = ({ quantity }) => {
    const oldTableIndex = palletsToSplit?.tableIndex || 0;
    const newTableIndex = palletsToSplit?.newTableIndex || 0;
    const checkQuantityEquality = +quantity === palletsToSplit?.pallet.item_quantity;

    setPalletsData((prevState) => {
      const splittedPallets = checkQuantityEquality
        ? [...prevState[newTableIndex].pallets, palletsToSplit?.pallet]
        : [...prevState[newTableIndex].pallets, { ...palletsToSplit?.pallet, item_quantity: +quantity }];

      const palletsAfterSplitted = checkQuantityEquality
        ? prevState[oldTableIndex].pallets.filter((item, i) => i !== palletsToSplit?.palletIndex)
        : prevState[oldTableIndex].pallets.map((item, i) => (
          i === palletsToSplit?.palletIndex
            ? { ...item, item_quantity: item.item_quantity - quantity } : item
        ));
      const returnedState = prevState.map((allPalletsItem, i) => {
        if (i === oldTableIndex) {
          return { ...allPalletsItem, pallets: palletsAfterSplitted };
        }
        if (i === newTableIndex) {
          return { ...allPalletsItem, pallets: splittedPallets };
        }
        return allPalletsItem;
      });
      setVisible(false);
      return returnedState;
    });
  };

  const isNumberValidator = (c, v) => (v && !Number.isNaN(v) && v > 0 ? Promise.resolve() : Promise.reject());

  const isNotBiggerValueThenInOrderValidator = (c, v) => (
    v && palletsToSplit && v <= palletsToSplit?.pallet?.item_quantity ? Promise.resolve() : Promise.reject()
  );

  useEffect(() => {
    form.setFieldsValue({
      quantity: palletsToSplit?.pallet?.item_quantity,
    });
  }, [form, palletsToSplit]);

  return (
    <Modal
      title="Scegli la quantità da spostare"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={false}
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name="quantity"
          className={styles.form_input}
          rules={[
            { required: true, validator: isNumberValidator, message: t('manifest.required_split_error') },
            { validator: isNotBiggerValueThenInOrderValidator, message: t('manifest.is_not_bigger_value_split_error') },
          ]}
        >
          <Input/>
        </Form.Item>
        <CustomButton
          className={styles.submit_btn}
          htmlType="submit"
          type="primary"
        >
          Conferma
        </CustomButton>
      </Form>
    </Modal>
  );
};

export default SplitModal;
