import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import style from './transports.module.css';
import CustomButton from '../../components/CustomButton/CustomButton';
import TransportsTable from './TransportsTable';
import NewTransportForm from './NewTransportForm';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestTransport, setTransportData } from '../../store/redux_slice/transport/transportSlice';
import Loader from '../../components/Loader';
import { requestTransportTypes } from '../../store/redux_slice/transport/transportTypesSlice';
import { requestGetContractors } from '../../store/redux_slice/contractors/contractorsSlice';
import { requestOperatorsList } from '../../store/redux_slice/operators/operatorsListSlice';
import {
  requestCreateNewTransport, requestDeleteTransportById,
  requestUpdateTransportById, clearTransportById,
} from '../../store/redux_slice/transport/transportByIdSlice';
import DeleteModal from '../../components/DeleteModal';
import UnavailableModal from './UnavailableModal';
import FilterContainer from './FilterContainer';

const TransportsContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const transport = useAppSelector((state) => state.transport.transport);
  const transportById = useAppSelector((state) => state.transportById.transportById);
  const transportByDriverId = useAppSelector((state) => state.transportByDriverId.transportByDriverId);
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [isUnavailableModal, setIsUnavailableModal] = useState<boolean>(false);
  const [selectId, setSelectId] = useState<string| null>(null);
  const [isTransportForm, setIsTransportForm] = useState<number| null>(null);
  const [truckTypeId, setTruckTypeId] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [contractorsFilter, setContractorsFilter] = useState<string>('');
  const [numberPalletFilter, setNumberPalletFilter] = useState<string>('');
  const [unavailableFilter, setUnavailableFilter] = useState<boolean | null>(null);

  useEffect(() => {
    dispatch(requestTransport());
    dispatch(requestTransportTypes());
    dispatch(requestGetContractors());
    dispatch(requestOperatorsList(false));
    return () => {
      dispatch(setTransportData(null));
    };
  }, [dispatch]);
  const onFinish = (values) => {
    const data = {
      plate_number: values?.license_plate.toUpperCase(),
      note: values?.description,
      contractor: values?.contractor,
      truck_type_id: values?.type,
      driver_id: values?.drivers_name,
      pallet_capacity: values?.number_pallets_max,
      busy: values?.available === 'No',
      booking_date: null,
    };
    const busyData = {
      ...data,
      booking_date: values?.available_days ? values?.available_days.toJSON() : '',
    };
    if (transportById) {
      const updateData = {
        id: transportById?.id.toString(),
        data: values?.available_days ? busyData : data,
      };
      dispatch(requestUpdateTransportById(updateData));
    } else {
      dispatch(requestCreateNewTransport({
        data: values?.available_days ? busyData : data,
        truckTypeId: transportByDriverId?.id,
        busyTransport: !!transportByDriverId,
      }));
    }
    setIsTransportForm(null);
    dispatch(clearTransportById());
    setTruckTypeId('');
  };
  const onDeleteTransport = () => {
    setIsDeleteModal(false);
    if (selectId) {
      dispatch(requestDeleteTransportById(selectId));
    }
  };
  const closeTransportForm = () => {
    setIsTransportForm(null);
    dispatch(clearTransportById());
    setTruckTypeId('');
  };
  if (!transport) {
    return <Loader/>;
  }
  return (
    <>
      {
        selectId && <UnavailableModal
          visible={isUnavailableModal}
          onCancel={() => setIsUnavailableModal(false)}
          selectId={selectId}
        />
      }
      <DeleteModal
        title={t('forms.transports.delete_transport')}
        description={t('forms.transports.delete_transport_description')}
        visible={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        onDelete={onDeleteTransport}/>
      <NewTransportForm
        truckTypeId={truckTypeId}
        setTruckTypeId={setTruckTypeId}
        visible={isTransportForm}
        onCancel={closeTransportForm}
        onFinish={onFinish}
      />
      <div className={style.transports_container}>
        <Row className={style.margin_container}>
          <Col span={12}>
            <h1 className={style.bold}>
              {t('forms.transports.fleet_management')} ({transport?.length})
            </h1>
            <FilterContainer
              search={search}
              contractorsFilter={contractorsFilter}
              numberPalletFilter={numberPalletFilter}
              unavailableFilter={unavailableFilter}
              setSearch={setSearch}
              setContractorsFilter={setContractorsFilter}
              setNumberPalletFilter={setNumberPalletFilter}
              setUnavailableFilter={setUnavailableFilter}
            />
          </Col>
          <Col span={4} offset={8}>
            <CustomButton
              type="primary"
              icon={<PlusOutlined/>}
              className={style.newTransportBtn}
              onClick={() => setIsTransportForm(Date.now())}
            >
              {t('forms.transports.new_medium')}
            </CustomButton>
          </Col>
        </Row>
        <TransportsTable
          transport={transport}
          contractorsFilter={contractorsFilter}
          numberPalletFilter={numberPalletFilter}
          unavailableFilter={unavailableFilter}
          search={search}
          setIsTransportForm={setIsTransportForm}
          setIsDeleteModal={setIsDeleteModal}
          setSelectId={setSelectId}
          setIsUnavailableModal={setIsUnavailableModal}
        />
      </div>
    </>
  );
};

export default TransportsContainer;
