import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Form, Input } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import Status from '../../components/Status';
import style from './profile.module.css';
import { User } from '../../store/types';
import { RoleEnums } from '../../enums';
import { useAppDispatch } from '../../store/store';
import { updateUserData } from '../../store/redux_slice/settingsSlice';

const OperatorForm:React.FC<{userData: User | null}> = ({ userData }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const renderStatus = () => {
    if (userData) {
      if (userData.role === RoleEnums.OPERATOR) {
        return t('roles.operator');
      }
      if (userData.role === RoleEnums.ADMIN) {
        return t('roles.administrator');
      }
      if (userData.role === RoleEnums.SUPER_ADMIN) {
        return t('roles.super_administrator');
      }
    }
    return '';
  };
  const onChangeEmail = () => {
    const email = form.getFieldValue('email');
    if (userData) {
      dispatch(updateUserData({
        id: userData?.id,
        data: {
          email,
        },
      }));
    }
  };
  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        email: userData?.email,
      });
    }
  }, [form, userData]);
  return (
    <div className={style.profile_container}>
      <div className={classnames('main-title-30px', style.blue)}>
        {t('operatorSettings.my_data')}
      </div>
      {
        userData?.name
          && <div className={classnames(style.name_top, 'title-20px', style.blue)}>
            {userData?.name}
          </div>
      }
      {
        userData
            && <div className={style.status}>
              <Status status={renderStatus()}/>
            </div>
      }
      <Form
        name="operatorForm"
        form={form}
        layout="vertical"
      >
        <Form.Item
          label={t('operatorSettings.email')}
          name="email"
          className={classnames(style.text_style, style.blue)}
        >
          <Input
            className={style.input}
            suffix={<span className="suffix">
              <SettingOutlined onClick={onChangeEmail}/>
            </span>}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default OperatorForm;
