import { useMemo } from 'react';

export const useFixedOrder = <T extends {id: string}>(
  visible: boolean,
  items: T[],
  currentId: string | undefined,
  close: () => void,
) => {
  const memoItems = useMemo(() => items ?? [], [visible]);
  const ordered = memoItems.reduce((prev, curr, idx) => {
    const updatedItem = items.find((e) => e.id === curr.id);
    if (updatedItem) {
      prev[idx] = updatedItem;
    } else if (currentId === curr.id) {
      close();
    }
    return prev;
  }, [...memoItems]);
  return ordered;
};
