import { ShipmentStructure, RefusePayload } from '../store/types';
import { instance } from './index';

export const CreateShippingRequest = (data) => instance.post('shipping/request/create', data);

export const GetPrivateAreaShippingList = (data) => instance.get(
  '/shipping/request',
  {
    params: {
      page: data.page,
      page_size: data.page_size,
      status: data.status,
      ...data.filter,
    },
  },
);

export const GetShippingRequest = (id: string) => instance.get(`shipping/request/${id}/request`);

export const PatchShippingRequest = (id: string, data: Partial<ShipmentStructure>) => instance.patch(`shipping/request/${id}/request`, data);

export const GetPaymentLink = (id: string) => instance.get(`shipping/request/${id}/payment_link`);

export const RefusedShippingRequest = (data: RefusePayload) => instance.post(`shipping/request/${data.id}/refused`, {
  ...data,
});

export const PostShippingRequestEstimateCreate = (data) => instance.post('shipping/request/estimate/create', data);

export const PostDownloadQrCodes = (id: string) => instance.post(`shipping/request/${id}/qrcodes`);

export const GetShippingTrackingStatus = (code: string) => instance.get(`tracking/${code}`);

export const GetSippingRequestTruck = (shippingId: string) => instance.get(`shipping/request/${shippingId}/truck`);

export const DeleteShippingRequest = (id: string) => instance.delete(`/shipping/request/${id}/soft`);

export const PostShippingRequestTrackingCode = (id: string) => instance.post(`/shipping/request/${id}/tracking`);

export const GetShippingRequestSendEmail = (id: string) => instance.get(`/shipping/request/${id}/send-new-shipment-mail`);

export const GetEstimationSendEmail = (id: string) => instance.get(`/shipping/request/${id}/send-new-estimation-mail`);

export const GetShippingTableType = (id: string) => instance.get(`/shipping/request/${id}/table-type`);

export const GetPallexShipping = (id: string) => instance.get(`/shipping/request/${id}/pallex`);

export const PatchPallexShipping = (id: string) => instance.patch(`/shipping/request/${id}/pallex`);

export const PrintLabel = (id: string) => instance.get(`/shipping/request/${id}/ascii-print`);
