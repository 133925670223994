import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PatchEstimation, PatchShipmentStructure,
  EstimationStructure, PallexErrorModalPayload,
} from '../../types';

export const updateApprovedShipmentAction = createAction<PatchEstimation>('updateApprovedShipmentAction');
export const deleteArchivedShipmentAction = createAction<{id: string, isArchived?: boolean}>('deleteArchivedShipmentAction');
export const getApprovedPallexShippingRequestAction = createAction<PallexErrorModalPayload>('getApprovedPallexShippingRequestAction');

type State = {
    approved: EstimationStructure | null,
    pallexErrorSuccess: boolean,
    pallexErrorLoading: boolean,
}

const initialState: State = {
  approved: null,
  pallexErrorSuccess: false,
  pallexErrorLoading: false,
};

const shipmentApproveSlice = createSlice({
  name: 'shippingApprove',
  initialState,
  reducers: {
    setApprovedShipmentsData: (state, action: PayloadAction<EstimationStructure | null>) => {
      state.approved = action.payload;
    },
    patchApprovedSlice: (state, action: PayloadAction<PatchShipmentStructure>) => {
      if (!state.approved?.items) return;
      const index = state.approved.items.findIndex((e) => e.id === action.payload.id);
      if (index === -1) return;
      const el = state.approved.items[index];
      state.approved.items[index] = {
        ...el,
        ...action.payload.data,
      };
    },
    setPallexErrorLoading: (state, action: PayloadAction<boolean>) => {
      state.pallexErrorLoading = action.payload;
    },
  },
});

export const {
  setApprovedShipmentsData, setPallexErrorLoading,
  patchApprovedSlice,
} = shipmentApproveSlice.actions;

export default shipmentApproveSlice.reducer;
