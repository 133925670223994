import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './EditableSelect.module.css';
import CustomButton from '../CustomButton/CustomButton';

interface Props {
  onClick: () => void;
}

export const AddButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (<Row justify="end">
    <Col span={8} className={style.pushRightChild}>
      <CustomButton type="primary" onClick={onClick}>
        {t('buttons.add')}
      </CustomButton>
    </Col>
  </Row>);
};
