export enum PathEnums {
    Deliveries = '/consegne',
    Deliveries_approved = '/consegne/approvate',
    Deliveries_to_be_approved = '/consegne/da-approvare',
    Deliveries_canceled = '/consegne/rifiutate',
    Estimations = '/preventivi',
    PriseList ='/listino-prezzi',
    ShipmentList = '/lista-spedizioni',
    Support = '/supporto',
    Transports = '/flotta',
    Users = '/utenti',
    Create_estimation = '/crea-richiesta-di-preventivo',
    Create_estimation_confirm = '/crea-richiesta-di-preventivo/conferma',
    Dashboard = '/dashboard',
    Manifests = '/manifesto-di-carico',
    Tracking = '/tracking',
    Invoices = '/fatture-da-inviare',
    PrivacyPolicy = '/privacy-policy',
    New_shipment_deliveries = '/nuova-spedizione',
    Operator_profile = '/operatori',
    User_profile = '/profilo-personale',
    Operator_List = '/lista-operatori',
    Monitoring = '/monitoring',
    New_estimation = '/nuovo-preventivo',
    New_pricelist = '/nuovo-listino-prezzi',
    Created_pricelist = '/listino-prezzi-creato',
    Details_pricelist = '/listino-prezzi-dettaglio',
    Private_area_shipment_details = '/dettaglio-spedizione-area-privata',
    Create_shipment = '/crea-spedizione',
    Create_shipment_manual = '/crea-spedizione/manuale',
    Create_shipment_details = '/crea-spedizione/dettaglio',
    Restore_password = '/recupera-password',
    Bank_confirm = '/conferma-pagamento',
}

export enum RoleEnums {
    USER = 'USER',
    OPERATOR = 'OPERATOR',
    ADMIN = 'ADMIN',
    GUEST = 'GUEST',
    DRIVER = 'DRIVER',
    SUPER_ADMIN = 'SUPERADMIN',
    BILLING_OPERATOR = 'OPERATORE_FATTURAZIONE',
    ESTIMATING_OPERATOR = 'OPERATORE_PREVENTIVAZIONE'
}

export enum DeliveryType {
    CONSEGNA = 'Consegna',
    RITIRO = 'Ritiro',
    TRIANG = 'Triangolazione',
    BOTH = 'Both'
}

export enum EstimationStatuses {
    DA_ELABORARE = 'Da_Elaborare',
    INVIATO = 'Inviato',
    INVIATO_FOLLOW_UP = 'Inviato_follow_up',
    CONFERMATO = 'Confermato',
    RIFIUTATO = 'Rifiutato',
    SCADUTO = 'Scaduto',
    ATESA_DI_DATA = 'In attesa di data',
    IN_ELABORAZIONE = 'In elaborazione',
    PREVENTIVO_CONFERMATO = 'Preventivo confermato',
    CONFERMA_PREVENTIVO = 'Conferma preventivo'
}

export enum UserTypology {
    PrivatePerson = 'Private_person',
    Company = 'Company',
}

export enum PallexStatuses {
    In_attesa_di_data= 'In attesa di data',
    In_attesa_di_ritiro = 'In attesa di ritiro',
    Ritiro_effettuato = 'Ritiro effettuato',
    Presso_HUB = 'Presso HUB',
    In_transito = 'In transito',
    In_consegna = 'In consegna',
    Consegnato = 'Consegnato',
}

export enum TPStatuses {
    In_attesa_di_data= 'In attesa di data',
    In_attesa_di_ritiro = 'In attesa di ritiro',
    In_attesa_di_approvazione = 'In attesa di approvazione',
    In_attesa_del_cliente = 'In attesa del cliente',
    In_attesa_di_pagamento = 'In attesa di pagamento',
    Ritirato = 'Ritirato',
    In_magazzino = 'In magazzino',
    In_consegna = 'In consegna',
    Consegnato = 'Consegnato',
    Annullata = 'Annullata',
    Gestito_da_TP = 'Gestito da TP',
    Rifiutato = 'Rifiutato',
    Da_confermare = 'Da confermare'
}

export enum ContractorTypes {
    Pallex = 'Pallex Italia',
    Pallex_TP = 'Pallex TP',
    TP_Stradale = 'TP Stradale',
}

export enum CanDeleteShipmentsWithStatuses {
    In_attesa_di_approvazione = 'In attesa di approvazione',
    In_attesa_del_cliente = 'In attesa del cliente',
    Consegnato = 'Consegnato',
    Annullata = 'Annullata',
}

export enum EditableDetailsTableTypes {
    options = 'options',
    invoice_pallets = 'invoice_pallets',
    addebitos = 'addebitos',
    admin_pallets = 'admin_pallets',
    private_area_pallets = 'private_area_pallets',
    private_area_options = 'private_area_options',
    merce_adr = 'merce_adr'
}

export enum OptionsCategory {
    TOTAL = 'TOTAL',
    PALLET = 'PALLET',
    ESTERNAMENTE = 'ESTERNAMENTE'
}

export enum AddressFields {
    PICKUP_ADDRESS = 'pickup_address',
    SHIPPING_ADDRESS = 'shipping_address',
    REGISTRATION_ADDRESS = 'registration_address',
}

export enum OrderType {
    ESTIMATION = 'ESTIMATION',
    SHIPPING = 'SHIPPING',
}

export enum ImballaggioMerceAdrEnum {
    FUSTP = 'Fusto',
    TANICA = 'Tanica',
    CASSA = 'Cassa',
    SACCO = 'Sacco',
    IBC = 'IBC'
}

export enum PaymentMethodEnum {
    BANK_TRANSFER = 'bank_transfer',
    CARD_PAYMENT = 'card_payment',
    BANK_CHECK = 'bank_check'
}
