import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import classnames from 'classnames';
import { Navigate } from 'react-router-dom';
import Banner from 'components/Banner/Banner';
import Loader from 'components/Loader';
import ShipmentRequestForm from './ShipmentRequestForm';
import { useAppDispatch, useAppSelector } from 'store/store';
import { requestGetShippingServices } from 'store/redux_slice/shippingServicesSlice';
import { requestShippingCreate, setCleanData } from 'store/redux_slice/shipmentSlice';
import { requestGetFacchinaggio } from 'store/redux_slice/pallex/facchinaggioSlice';
import { requestGetContrassegno } from 'store/redux_slice/pallex/contrassegnoSlice';
import { Pallet } from 'store/types';
import { formToShippingRequestPayload } from './functions';
import { PathEnums } from 'enums';
import style from './ShipmentRequestStepOne.module.css';

const ShipmentRequestStepOne = () => {
  const { t } = useTranslation();
  const [firstStep, setFirstStep] = useState<boolean>(true);
  const [countArray, setCountArray] = useState<number[]>([1]);
  const [isDeletePallet, setIsDeletePallet] = useState(false);
  const [pallets, setPallets] = useState<Pallet[]>([]);
  const dispatch = useAppDispatch();
  const loadingServices = useAppSelector((state) => state.extraServices.loading);
  const extra = useAppSelector((state) => state.extraServices?.shippingServices);
  const facchinaggioList = useAppSelector((state) => state.facchinaggioList?.facchinaggioList);
  const contrassegnoList = useAppSelector((state) => state.contrassegnoList?.contrassegnoList);
  const shippingRequest = useAppSelector((state) => state.shipmentRequest?.shippingRequest);
  const [form] = Form.useForm();
  const extraServices = extra?.filter((el) => !el.isAdmin);
  useEffect(() => {
    dispatch(requestGetShippingServices());
    dispatch(requestGetFacchinaggio());
    dispatch(requestGetContrassegno());
  }, [dispatch]);
  const onFinish = (values) => {
    const formResult = formToShippingRequestPayload(values);
    const optionsSelected = form.getFieldValue('checkboxGroup') ? form.getFieldValue('checkboxGroup').map((id) => +id) : '';

    const options = extraServices?.map((service) => ({
      ...service,
      isActive: optionsSelected.includes(service.id),
    })) || [];

    const data = {
      ...formResult,
      pickup_address: {
        location: {
          ...values.pickup_address.location,
          post_code: values.pickup_address_post_code,
        },
      },
      shipping_address: {
        company_name: values.company_name || '',
        name_and_surname_reference: values.name_and_surname_reference || '',
        location: {
          ...values.shipping_address.location,
          post_code: values.shipping_address_post_code,
        },
      },
      start_shipment_time: values.start_shipment_time ? format(new Date(values.start_shipment_time), 'HH:mm') : '',
      options,
    };
    dispatch(requestShippingCreate({
      estimation_email: true,
      data,
    }));
    dispatch(setCleanData());
  };
  const onAddPallet = () => {
    setCountArray((prev) => [...prev, Math.max(-1, ...prev) + 1]);
  };
  const onDeletePallet = (index) => {
    setCountArray((prev) => prev.filter((e) => e !== index));
  };
  const onChangeStep = async () => {
    const stage2RequiredFields = ['email', 'name', 'checkbox-group', 'epal_count'];
    form.setFields(stage2RequiredFields.map((name) => ({
      name,
      validating: !firstStep,
      errors: firstStep ? [] : undefined,
    })));
    const palletsValue = form.getFieldValue('pallets');
    setPallets(palletsValue);
    try {
      await form.validateFields();
      setFirstStep(!firstStep);
    } catch (e) {
      if (!firstStep) {
        setFirstStep(true);
      }
    }
  };
  return (
    <>
      {
        shippingRequest && (
          <Navigate to={PathEnums.Create_estimation_confirm} replace={true} />
        )
      }
      { loadingServices ? <Loader/>
        : <>
          <Banner title={t('menu.shippingEstimates')}/>
          <div className={classnames(style.shipmentOne, 'customForm')}>
            {
              extraServices && facchinaggioList && contrassegnoList
                && <ShipmentRequestForm
                  firstStep={firstStep}
                  onFinish={onFinish}
                  countArray={countArray}
                  isDeletePallet={isDeletePallet}
                  onDeletePallet={onDeletePallet}
                  setIsDeletePallet={setIsDeletePallet}
                  onChangeStep={onChangeStep}
                  onAddPallet={onAddPallet}
                  extraServices={extraServices}
                  facchinaggioList={facchinaggioList}
                  contrassegnoList={contrassegnoList}
                  form={form}
                  pallets={pallets}
                />
            }
          </div>
        </>
      }
    </>
  );
};

export default ShipmentRequestStepOne;
