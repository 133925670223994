import { createAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { UpdateShippingRequest } from '../../../api';
import { PatchShipmentStatusPayload } from '../../types';
import { setLoading } from '../../redux_slice/estimationsListSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';

export const updateStatusAction = createAction<PatchShipmentStatusPayload>('updateStatusAction');

function* patchStatusShipmentSaga(action: {
  payload: PatchShipmentStatusPayload
}) {
  yield put(setLoading(true));
  try {
    yield UpdateShippingRequest(action.payload);
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* shippingStatusSaga() {
  yield takeLatest(updateStatusAction, patchStatusShipmentSaga);
}
