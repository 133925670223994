import {
  Button,
  Form, FormInstance, InputNumber, Select, Space,
} from 'antd';
import React, {
  useContext, useState,
} from 'react';
import cn from 'classnames';
import { useAppDispatch } from '../../../store/store';
import { setMessage } from '../../../store/redux_slice/messageErrorSlice';
import style from './createdTable.module.css';
import pen from '../../../assests/images/pen_svg.svg';

const { Option } = Select;

/* eslint-disable  @typescript-eslint/no-explicit-any */
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string,
  rowSpan: number,
  region?: string,
  province: string,
  mini: string,
  quarter: string,
  half: string,
  medium: string,
  light: string,
  large: string,
  full: string,
  big: string,
  mega: string,
}

export const priceKeys = [
  'mini',
  'quarter',
  'half',
  'medium',
  'light',
  'large',
  'full',
  'big',
  'mega',
];

interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
  listOfChanged: {[key: string]: string};
}

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  listOfChanged,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const form = useContext(EditableContext)!;
  const dispatch = useAppDispatch();

  const prepareCell = (v: React.ReactNode, key: keyof Item, percent: string | undefined) => {
    if (!v || !percent || Number.isNaN(percent)) {
      return v;
    }
    if (!priceKeys.includes(key)) {
      return v;
    }
    const price = Number.parseFloat(v[1].replace(',', '.'));
    return [
      v[0],
      (price + (price * Number(percent)) / 100)
        .toFixed(1)
        .replace('.', ','),
    ];
  };

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: 0 });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      const amount = values.operation === '-' && values.amount > 100 ? 100 : values.amount;
      toggleEdit();
      handleSave({ ...record, ...{ ...values, amount }, dataIndex });
    } catch (errInfo) {
      dispatch(setMessage({ description: 'Errore' }));
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Space>
        <div className={style.counter}>
          <Form
            form={form}
            initialValues={{
              operation: '+',
              amount: 0,
            }}
          >
            <Form.Item className={style.formItem} name="operation">
              <Select className={style.percentSelect}>
                <Option value="+">+</Option>
                <Option value="-">-</Option>
              </Select>
            </Form.Item>
            <Form.Item className={cn(style.formItem, style.formItemInput)} name="amount">
              <InputNumber
                className={style.percentInput}
                min={0}
                addonAfter={'%'}
              />
            </Form.Item>
            <Button
              className={style.btnOk}
              type="primary"
              onClick={save}
            >
              ✓
            </Button>
          </Form>
        </div>
      </Space>
    ) : (
      <>
        <div className={style.editableCell} onClick={toggleEdit}>
          <span>{prepareCell(children, dataIndex, listOfChanged[`${record.region}_${record.province}_${dataIndex}`])}</span>
          <img className={style.editableCellPencil} src={pen} alt=""/>
        </div>
        {
          !!listOfChanged[`${record.region}_${record.province}_${dataIndex}`] && <span className={cn(
            style.editableCellPercent,
            { [style.redPercent]: listOfChanged[`${record.region}_${record.province}_${dataIndex}`].startsWith('-') },
            { [style.greenPercent]: listOfChanged[`${record.region}_${record.province}_${dataIndex}`].startsWith('+') },
          )}>
            ({listOfChanged[`${record.region}_${record.province}_${dataIndex}`]}%)
          </span>
        }
      </>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
