import jwtDecode from 'jwt-decode';
import {
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import { Form } from 'antd';
import { useEffect } from 'react';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { Location, TokenStructure } from 'store/types';
import { GooglePlacesItem } from 'components/GooglePlacesComponent';
import {
  DeliveryType, PaymentMethodEnum, EstimationStatuses, TPStatuses,
} from 'enums';

export const parseToken = (accessToken) => (
  accessToken ? jwtDecode(accessToken) as TokenStructure : null
);

export const getAddressInfo = async (address?: GooglePlacesItem): Promise<Location> => {
  if (!address?.placeId) {
    return {
      province: '',
      region: '',
      city: '',
      street: '',
      house_number: '',
      post_code: '',
      lat: 0,
      lng: 0,
    };
  }
  const infoByPlaceId = await geocodeByPlaceId(address.placeId);
  const region = infoByPlaceId?.[0].address_components.find((c) => c.types.includes('administrative_area_level_1'))?.long_name ?? '';
  const province = infoByPlaceId?.[0].address_components.find((c) => c.types.includes('administrative_area_level_2'))?.short_name ?? '';
  const city = infoByPlaceId?.[0].address_components.find((c) => c.types.includes('locality'))?.long_name
    ?? infoByPlaceId?.[0].address_components.find((c) => c.types.includes('administrative_area_level_3'))?.long_name ?? '';
  const postCode = infoByPlaceId?.[0].address_components.find((c) => c.types.includes('postal_code'))?.long_name ?? '';
  const street = infoByPlaceId?.[0].address_components.find((c) => c.types.includes('route'))?.long_name ?? '';
  const streetNumber = infoByPlaceId?.[0].address_components.find((c) => c.types.includes('street_number'))?.long_name ?? '';
  const country = infoByPlaceId?.[0].address_components.find((c) => c.types.includes('country'))?.long_name ?? '';
  const { lat, lng } = infoByPlaceId?.[0]?.geometry?.location?.toJSON() ?? { lat: 0, lng: 0 };
  return {
    province,
    region,
    city,
    street,
    house_number: streetNumber,
    post_code: postCode,
    country,
    lat,
    lng,
  };
};

export const isEmail = (email: string): boolean => {
  const re = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
  return re.test(email);
};

export const buildQueryString = (params: Record<string, string | number> | URLSearchParams | undefined): string => {
  const query = new URLSearchParams();
  if (params) {
    Object.keys(params).forEach((key) => {
      if (typeof params[key] !== 'boolean' && params[key] !== '') {
        query.append(key, params[key].toString());
      }
    });
  }
  return query.toString() && `?${query.toString()}`;
};

export function findInArrayByField<Type, Key extends keyof Type, U extends Type[Key]>(source: Array<Type>, target: Array<U>, field: Key): Array<Type> {
  if (!Array.isArray(source)) {
    return [];
  }
  return source.filter((s) => target.includes(s[field] as U));
}

export function toUpperCaseFirst(s = ''): string {
  return s && s[0].toLocaleUpperCase() + s.slice(1).toLocaleLowerCase();
}

type ServicePriceUnit = '€' | '%';

export interface ServicePrice {
  value: number | string,
  unit: ServicePriceUnit,
}

export function priceMapper(price: string): ServicePrice {
  const units: ServicePriceUnit[] = ['€', '%'];
  const res: ServicePrice = {
    value: '-',
    unit: units[0],
  };

  units.forEach((u) => {
    if (price.includes(u)) {
      res.value = parseFloat(price.replace(',', '.'));
      res.unit = u;
    }
  });

  return res;
}

export const generatePassword = () => {
  const specialChars = '!@#$%^&*()_+{}[]<>?/';
  let password = '';
  let hasUpperCase = false;
  let hasSpecialChar = false;

  while (password.length < 8 || (!hasUpperCase || !hasSpecialChar) || password.length > 8) {
    const char = String.fromCharCode(Math.floor(Math.random() * 94) + 33);
    password += char;
    if (!hasUpperCase && /[A-Z]/.test(char)) {
      hasUpperCase = true;
    }
    if (!hasSpecialChar && specialChars.includes(char)) {
      hasSpecialChar = true;
    }
  }

  return password;
};

export const modifyPaymentMethodName = (name, t) => {
  if (name === PaymentMethodEnum.BANK_TRANSFER) {
    return t('forms.shipmentDetails.bank_transfer');
  }
  if (name === PaymentMethodEnum.BANK_CHECK) {
    return t('forms.shipmentDetails.bank_check');
  }
  return t('forms.shipmentDetails.card');
};

export const useGetHouseNumbers = (
  form: FormInstance,
  setPickupStreet: (street: string) => void,
  setShippingStreet?: (street: string) => void,
) => {
  const checkPickupAddress = Form.useWatch('pickupAddress', form);
  const checkShippingAddress = Form.useWatch('shippingAddress', form);
  useEffect(() => {
    if (checkPickupAddress?.placeId) {
      getAddressInfo(checkPickupAddress)
        .then((r) => {
          setPickupStreet(r.street);
        });
    } else {
      form.setFieldsValue({
        pickup_post_code: checkPickupAddress?.post_code,
      });
    }
  }, [checkPickupAddress, form]);
  useEffect(() => {
    getAddressInfo(checkShippingAddress)
      .then((r) => {
        if (setShippingStreet) {
          setShippingStreet(r.street);
        }
      });
  }, [checkShippingAddress, form]);
};

export const selectContractorType = (selectedContractor, type) => {
  let contractorType = '';
  let subContractorType = '';
  if (type === DeliveryType.CONSEGNA) {
    contractorType = DeliveryType.CONSEGNA;
    subContractorType = DeliveryType.RITIRO;
  } else {
    contractorType = DeliveryType.RITIRO;
    subContractorType = DeliveryType.CONSEGNA;
  }
  return [contractorType, subContractorType];
};

export const renderContractorShow = (contractorShow, deliveryType, isFornitore) => {
  if (!isFornitore && contractorShow) {
    return contractorShow;
  }
  if (isFornitore) {
    return false;
  }
  return !(!contractorShow && deliveryType === DeliveryType.TRIANG);
};

export const checkShipmentStatus = (status: string | undefined, reqStatus: string | undefined) => {
  if (!status && reqStatus === EstimationStatuses.DA_ELABORARE) {
    return EstimationStatuses.IN_ELABORAZIONE;
  }
  if (status === TPStatuses.In_attesa_di_approvazione && (
    reqStatus === EstimationStatuses.INVIATO || reqStatus === EstimationStatuses.INVIATO_FOLLOW_UP
  )) {
    return EstimationStatuses.CONFERMA_PREVENTIVO;
  }
  if (status === TPStatuses.In_attesa_di_approvazione && reqStatus === EstimationStatuses.CONFERMATO) {
    return EstimationStatuses.PREVENTIVO_CONFERMATO;
  }
  return status || '';
};

export const renderShowInManifesto = (deliveryType, FornitoreId, pallexItaliaId) => {
  if (deliveryType === DeliveryType.TRIANG) {
    return false;
  } if (FornitoreId) {
    return false;
  } return !pallexItaliaId;
};

export const isTrackingAvailable = (userId, contractor, subcontractor, tracking) => {
  if (!userId) {
    return 'NonDisponsible';
  }
  if (contractor === 'Pallex TP' || subcontractor === 'Pallex TP') {
    return 'Disponsible';
  }
  return tracking;
};

export const triangolizeConditionHandler = (isRitiro: boolean, isConsegna: boolean, both?: boolean) => {
  if (isRitiro && !isConsegna) return DeliveryType.RITIRO;
  if (!isRitiro && isConsegna) return DeliveryType.CONSEGNA;
  if ((isRitiro && isConsegna) || both) return DeliveryType.BOTH;
  if (!isRitiro && !isConsegna && !both) return null;
  return null;
};
