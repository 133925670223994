import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import {
  Form, Input, Select, InputNumber,
} from 'antd';
import { OptionsCategory, DeliveryType } from 'enums';
import { requestGetNumeroOnuAction } from 'store/redux_slice/pallex/numeroOnuSlice';
import { useAppSelector, useAppDispatch } from 'store/store';
import { EditableTableSourceType } from 'store/types';
import { triangServices } from 'pesantiConstants';
import styles from '../editableTable.module.css';

const { Option } = Select;

export enum InputTypes {
  text = 'text',
  select_price = 'select_price',
  select_negative_price = 'select_negative_price',
  select_category = 'select_category',
  select_show = 'select_show',
  quantyty_number = 'quantyty_number',
  depth_number = 'depth_number',
  width_number = 'width_number',
  height_number = 'height_number',
  weight_number = 'weight_number',
  pallet_price = 'pallet_price',
  simple_number = 'simple_number',
  select_numero_onu = 'select_numero_onu',
  select_imballaggio = 'select_imballaggio',
  descrizione_text = 'descrizione_text',
  select_adr_limit = 'select_adr_limit',
  fixedMinPrice = 'fixedMinPrice',
  requested_to = 'requested_to',
}

interface EditableCellProps {
  editing: boolean,
  record: Partial<EditableTableSourceType>,
  dataIndex: string,
  title: string,
  inputType: InputTypes,
  deliveryType: string,
  richiedeNasSelected: boolean,
  onDescrizioneNasChange: (value: boolean) => void,
  children: React.ReactNode,
  onAdrLimitChange: (value: boolean) => void,
}
const EditableCell = ({
  editing,
  record,
  dataIndex,
  title,
  inputType,
  deliveryType,
  children,
  richiedeNasSelected,
  onDescrizioneNasChange,
  onAdrLimitChange,
  ...restProps
}: EditableCellProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { numeroOnuList } = useAppSelector((state) => state.numeroOnu);
  const isNumberValidator = (c, v) => (v && !Number.isNaN(v) && v > 0 ? Promise.resolve() : Promise.reject());

  const renderNumberInputs = () => {
    switch (inputType) {
    case InputTypes.quantyty_number:
      return <InputNumber min={1} max={100} addonAfter="N°"/>;
    case InputTypes.depth_number:
    case InputTypes.width_number:
      return <InputNumber min={60} addonAfter="Cm"/>;
    case InputTypes.height_number:
      return <InputNumber min={5} max={240} addonAfter="Cm"/>;
    case InputTypes.weight_number:
      return <InputNumber min={1} max={1800} addonAfter="Kg"/>;
    default:
      return <InputNumber addonAfter="N°"/>;
    }
  };

  const onNumeroOnuSearchHandler = _.debounce((e) => {
    if (e) {
      dispatch(requestGetNumeroOnuAction({ query_name: e }));
    }
  }, 500);

  const renderInputs = () => {
    if (inputType === InputTypes.select_numero_onu) {
      return (
        <Form.Item
          name={dataIndex}
          className={styles.editable_cell_form_item_adr}
          rules={[{
            required: true,
            message: `${t('forms.pallet.validationError')} ${title.toLowerCase()}`,
          }]}
        >
          <Select
            showSearch
            filterOption={false}
            onSearch={(e) => onNumeroOnuSearchHandler(e)}
            onChange={(value) => {
              onDescrizioneNasChange(JSON.parse(value).richiedeNas);
            }}
          >
            { numeroOnuList?.map((item, i) => (
              <Option
                key={`${item.numeroOnu}_${i}`}
                value={JSON.stringify(item)}
                className={styles.merce_adr_option}
              >
                <p className={styles.merce_adr_select_row}>
                  <span className={styles.merce_adr_title}>Numero ONU e Classe Pericolo:</span>
                  {' '}{item.numeroOnu}, {item.classePericolo}
                </p>
                <p className={styles.merce_adr_select_row}>
                  <span className={styles.merce_adr_title}>Nome Materia:</span>
                  {' '}{item.nomeMateria}
                </p>
                <p className={styles.merce_adr_select_row}>
                  <span className={styles.merce_adr_title}>Gruppo Imballaggio:</span>
                  {' '}{item.gruppoImballaggio}
                </p>
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    }
    if (inputType === InputTypes.select_imballaggio) {
      return (
        <Form.Item
          name={dataIndex}
          rules={[{
            required: true,
            message: `${t('forms.pallet.validationError')} ${title.toLowerCase()}`,
          }]}
        >
          <Select>
            <Option value="Fusto">Fusto</Option>
            <Option value="Tanica">Tanica</Option>
            <Option value="Cassa">Cassa</Option>
            <Option value="Sacco">Sacco</Option>
            <Option value="IBC">IBC</Option>
          </Select>
        </Form.Item>
      );
    }
    if (inputType === InputTypes.select_show || inputType === InputTypes.select_adr_limit) {
      return (
        <Form.Item
          name={dataIndex}
          className={styles.editable_cell_form_item}
          rules={[{
            required: true,
            message: t('invoices.select_list_error'),
          }]}
        >
          <Select
            onChange={(value) => onAdrLimitChange(value !== 'Si')}
          >
            <Option key="Si" value="Si">Si</Option>
            <Option key="No" value="No">No</Option>
          </Select>
        </Form.Item>
      );
    }
    if (inputType === InputTypes.select_category) {
      return (
        <Form.Item
          name={dataIndex}
          className={styles.editable_cell_form_item}
          rules={[{
            required: true,
            message: t('invoices.select_list_error'),
          }]}
        >
          <Select>
            { Object.keys(OptionsCategory).map((item) => (
              <Option key={item} value={item}>{item}</Option>
            ))}
          </Select>
        </Form.Item>
      );
    }
    if (inputType === InputTypes.select_price || inputType === InputTypes.select_negative_price) {
      return (
        <span className={styles.select_price_container}>
          <Form.Item
            className={styles.editable_cell_form_item}
            name="priceValue"
            rules={[
              () => ({
                validator(rules, v) {
                  const n = Number.parseFloat(v?.toString().replace(',', '.'));
                  if ((!Number.isNaN(n) && inputType === InputTypes.select_price && n >= 0) || v === '-') {
                    return Promise.resolve();
                  }
                  if ((!Number.isNaN(n) && inputType === InputTypes.select_negative_price) || v === '-') {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
                message: t('forms.estimations.extra_service_error'),
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="priceUnit" style={{ margin: 0 }} className={styles.select_price}>
            <Select>
              <Option key="€" value="€">€</Option>
              <Option key="%" value="%">%</Option>
            </Select>
          </Form.Item>
        </span>
      );
    }
    if (inputType === InputTypes.descrizione_text) {
      return (
        <Form.Item
          className={styles.editable_cell_form_item}
          name={dataIndex}
          rules={[() => ({
            validator(rule, value) {
              if (!(!richiedeNasSelected && !record[dataIndex]) && !value) {
                return Promise.reject(new Error(t('invoices.input_error')));
              }
              return Promise.resolve();
            },
          })]}
        >
          <Input disabled={!richiedeNasSelected && !record[dataIndex]}/>
        </Form.Item>
      );
    }
    if (inputType === InputTypes.text) {
      return (
        <Form.Item
          className={styles.editable_cell_form_item}
          name={dataIndex}
          rules={[{
            required: true,
            whitespace: true,
            message: t('invoices.input_error'),
          }]}
        >
          <Input/>
        </Form.Item>
      );
    }
    if (inputType === InputTypes.pallet_price) {
      return (
        <Form.Item
          name={dataIndex}
          className={styles.editable_cell_form_item}
          initialValue={0}
          rules={[
            () => ({
              validator(rules, v) {
                if (!v || v === 0 || (v && !Number.isNaN(v) && v > 0)) {
                  return Promise.resolve();
                }
                return Promise.reject();
              },
              message: `${t('forms.pallet.validationError')} ${title.toLowerCase()}`,
            }),
          ]}
        >
          <InputNumber addonAfter="€"/>
        </Form.Item>
      );
    }
    if (inputType === InputTypes.fixedMinPrice) {
      return (
        <Form.Item
          shouldUpdate={
            (prev, curr) => prev.priceUnit !== curr.priceUnit
          }
          noStyle
        >
          {({ getFieldValue }) => (
            <Form.Item
              name={dataIndex}
              rules={[
                () => ({
                  validator(rules, v) {
                    if (!v || v === 0 || (v && !Number.isNaN(v) && v > 0)) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                  message: t('forms.estimations.extra_service_error'),
                }),
              ]}
            >
              <InputNumber
                style={{ width: 150 }}
                addonAfter={<span>€</span>}
                disabled={getFieldValue('priceUnit') === '€'}
              />
            </Form.Item>
          )}
        </Form.Item>
      );
    }
    if (inputType === InputTypes.requested_to) {
      return (
        <Form.Item
          shouldUpdate={
            (prev, curr) => prev.service_name !== curr.service_name
          }
          noStyle
        >
          {({ getFieldValue }) => (
            <Form.Item name={dataIndex} noStyle>
              <Select style={{ width: 150 }} disabled={!triangServices.includes(getFieldValue('title')?.toLocaleUpperCase())}>
                <Option key={DeliveryType.RITIRO} value={DeliveryType.RITIRO}>Ritiro</Option>
                <Option key={DeliveryType.CONSEGNA} value={DeliveryType.CONSEGNA}>Consegna</Option>
                <Option key={DeliveryType.BOTH} value={DeliveryType.BOTH}>Ritiro/Consegna</Option>
              </Select>
            </Form.Item>
          )}
        </Form.Item>
      );
    }
    return (
      <Form.Item
        name={dataIndex}
        className={styles.editable_cell_form_item}
        rules={[{
          validator: isNumberValidator,
          message: `${t('forms.pallet.validationError')} ${title.toLowerCase()}`,
        }]}
      >
        {renderNumberInputs()}
      </Form.Item>
    );
  };

  return (
    <td {...restProps} className={styles.td_input}>
      {editing ? (
        renderInputs()
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
