import { Modal } from 'antd';
import React from 'react';

interface DeleteModalInterface {
  title: string,
  description: string,
  visible: boolean,
  onCancel: () => void,
  onDelete: () => void,
}

const DeleteModal:React.FC<DeleteModalInterface> = ({
  title, description, visible, onCancel, onDelete,
}) => (
  <Modal title={title}
    visible={visible}
    onOk={onDelete}
    onCancel={onCancel}
  >
    {description}
  </Modal>
);

export default DeleteModal;
