import { useTranslation } from 'react-i18next';
import {
  useState, useEffect, useCallback,
} from 'react';
import { Row, Col, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import style from '../deliveries.module.css';
import CustomTabs from '../../../components/CustomTabs';
import CustomButton from '../../../components/CustomButton/CustomButton';
import ShippingApprovedTable from './ShippingApprovedTable';
import ShippingModal from '../components/ShippingModal';
import ModalWithCarousel from '../../../components/ModalWithCarousel';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import Loader from '../../../components/Loader';
import { FilterApproveShippingRequest, PatchEstimation } from '../../../store/types';
import {
  updateApprovedShipmentAction,
  deleteArchivedShipmentAction, setApprovedShipmentsData,
} from '../../../store/redux_slice/shipping/shippingApproveSlice';
import { updateStatusAction } from '../../../store/sagas/shipping/shippingStatusSaga';
import { requestOperatorsList } from '../../../store/redux_slice/operators/operatorsListSlice';
import ArchivedTable from './ArchivedTable';
import ShippingApproveFilter from './ShippingApproveFilter';
import DeleteModal from '../../../components/DeleteModal';
import { requestDownloadQrCodesAction } from '../../../store/sagas/downloadQrCodesSaga';
import { ContractorTypes, RoleEnums, PathEnums } from '../../../enums';
import ChangeStatusModal from '../components/ChangeStatusModal';
import { requestGetContrassegno } from '../../../store/redux_slice/pallex/contrassegnoSlice';
import { requestGetFacchinaggio } from '../../../store/redux_slice/pallex/facchinaggioSlice';
import { requestGetPallexHubs } from '../../../store/redux_slice/pallexHubsSlice';
import { useSocket } from '../../../hooks/useSocket';
import { setCleanShipmentDataFromNotification } from '../../../store/redux_slice/notificationsSlice';
import { requestGetContractors } from '../../../store/redux_slice/contractors/contractorsSlice';
import { requestGetPriceListTemplates } from '../../../store/redux_slice/priceListTemplatesSlice';
import { useFixedOrder } from '../../../hooks/useFixedOrder';

const ShippingApprovedContainer = () => {
  const dispatch = useAppDispatch();
  const socket = useSocket();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('tab1');
  const [filter, _setFilter] = useState<Partial<FilterApproveShippingRequest>>({});
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(10);
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [selectId, setSelectId] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState<boolean>(false);
  const contractors = useAppSelector((state) => state.contractors?.contractors);
  const pallexItaly = contractors.find((el) => el.name === ContractorTypes.Pallex);
  const pallexItalyTP = contractors?.find((el) => el.name === 'Pallex TP');
  const setFilter = (data) => {
    _setFilter(data);
    setPage(1);
  };
  const operatorsList = useAppSelector((state) => state.operatorsList.operatorsList);
  const role = useAppSelector((state) => state.auth.tokenStructure?.role);
  const {
    shippingType, tableTypeLoading, shimpentDataFromNotification, notificationShipmentLoading,
  } = useAppSelector((state) => state.notifications);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(requestOperatorsList(false));
  }, [dispatch]);

  useEffect(() => {
    if (shippingType?.type === 'spedizione-aprovate') {
      setActiveTab('tab1');
      setIsArchived(false);
    }

    if (shippingType?.type === 'spedizione-aprovate-archivate') {
      setActiveTab('tab2');
      setIsArchived(true);
    }
  }, [shippingType]);

  const approvedShipments = useAppSelector(
    (state) => state?.shippingApprove.approved,
  );
  const onRequestUpdateEstimation = useCallback(
    (data: PatchEstimation) => dispatch(updateApprovedShipmentAction(data)),
    [dispatch],
  );

  const initialSlide = approvedShipments?.items?.findIndex((e) => String(e.id) === String(selectedId)) ?? -1;
  const itemsWithFixedOrder = useFixedOrder(
    visible,
    approvedShipments?.items ?? [],
    selectedId ? String(selectedId) : undefined,
    () => setVisible(false),
  );

  useEffect(() => {
    dispatch(requestGetContrassegno());
    dispatch(requestGetFacchinaggio());
    dispatch(requestGetPallexHubs());
    dispatch(requestGetContractors());
    dispatch(requestGetPriceListTemplates());
  }, [dispatch]);

  useEffect(() => {
    socket.emit('APPROVATE', {
      ...filter,
      isArchived,
      page,
      page_size: count,
    });
  }, [socket, dispatch, isArchived, page, count, filter]);
  useEffect(() => {
    socket.connect();
    socket.on('APPROVATE', (data) => {
      dispatch(setApprovedShipmentsData(data?.message));
    });
    return () => {
      socket.off('APPROVATE');
      dispatch(setApprovedShipmentsData(null));
    };
  }, [socket, dispatch]);

  if (!approvedShipments || !operatorsList) {
    return <Loader/>;
  }
  const openDeleteModal = (id: string) => {
    setIsDeleteModal(true);
    setDeleteId(id);
  };
  const onDeleteArchiveShipment = () => {
    dispatch(deleteArchivedShipmentAction({ id: deleteId }));
    setIsDeleteModal(false);
  };

  const adminArrayOptions = [
    {
      title: t('forms.deliveries.customer_data'),
      type: 'user_data',
      action: (id: string) => {
        navigate(`${PathEnums.Users}?utente_id=${id}`);
      },
    },
  ];

  const generalApprovedOptions = [
    {
      title: t('forms.deliveries.details'),
      action: (id: string) => {
        setSelectedId(id);
        setVisible(true);
      },
    },
    {
      title: t('forms.deliveries.labelDownload'),
      action: (id: string) => {
        dispatch(requestDownloadQrCodesAction(id));
      },
    },
    {
      title: t('forms.deliveries.archive'),
      action: (id: string) => {
        setOpenChangeStatusModal(true);
        setSelectId(id);
      },
    },
  ];
  const generalArchivedOptions = [
    {
      title: t('forms.deliveries.details'),
      action: (id: string) => {
        setSelectedId(id);
        setVisible(true);
      },
    },
    {
      title: t('shipmentList.delete'),
      action: (id: string) => openDeleteModal(id),
    },
  ];

  const approvedOptions = role === RoleEnums.OPERATOR
    ? generalApprovedOptions : [...adminArrayOptions, ...generalApprovedOptions];

  const archivedOptions = role === RoleEnums.OPERATOR
    ? generalArchivedOptions : [...adminArrayOptions, ...generalArchivedOptions];

  const onChange = (status, shipment) => {
    if (status !== 'Annullata') {
      dispatch(updateStatusAction({
        id: shipment.id,
        data: { status },
      }));
    } else {
      setOpenChangeStatusModal(true);
      setSelectedStatus(status);
      setSelectId(shipment.id);
    }
  };
  const pallexOptions = [
    {
      title: t('forms.deliveries.details'),
      action: (id: string) => {
        setSelectedId(id);
        setVisible(true);
      },
    },
  ];

  return (
    <div className={style.max_width}>
      <Modal
        centered
        visible={!!searchParams.get('dettaglio') && !!shimpentDataFromNotification}
        footer={false}
        width={'80%'}
        className="custom_modal"
        onCancel={() => {
          searchParams.delete('dettaglio');
          setSearchParams(searchParams);
          dispatch(setCleanShipmentDataFromNotification());
        }}
      >
        {notificationShipmentLoading || tableTypeLoading ? <Loader/> : (
          shimpentDataFromNotification && <ShippingModal
            onRequestUpdateEstimation={onRequestUpdateEstimation}
            shipment={shimpentDataFromNotification}
          />
        )}
      </Modal>
      <ChangeStatusModal
        id={selectId}
        visible={openChangeStatusModal}
        onCancel={() => setOpenChangeStatusModal(false)}
        selectedStatus={selectedStatus}
      />
      <DeleteModal
        title={t('forms.deliveries.delete_shipping')}
        description={t('forms.deliveries.delete_shipping_description')}
        visible={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        onDelete={onDeleteArchiveShipment}/>
      <Row className={style.shipping_approved_container}>
        <ModalWithCarousel
          key={selectedId}
          initialSlide={initialSlide ?? -1}
          visible={visible}
          setVisible={() => {
            setVisible(false);
            setSelectedId(undefined);
          }}
          content={
            itemsWithFixedOrder?.map((shipment) => (
              <ShippingModal
                key={shipment.id}
                shipment={shipment}
                onRequestUpdateEstimation={onRequestUpdateEstimation}
              />
            ))
          }
        />
        <Col span={12}>
          <CustomTabs
            activeTab={activeTab}
            setActiveTab={(tab) => {
              setActiveTab(tab);
              dispatch(setApprovedShipmentsData(null));
            }}
            setIsChange={setIsArchived}
            leftText={t('forms.deliveries.shipments_approved')}
            leftNumber={approvedShipments.countNotArchived}
            rightText={t('forms.deliveries.archived')}
            rightNumber={approvedShipments.countArchived}
            setPage={setPage}
          />
          <ShippingApproveFilter
            filterStatus={filter.tracking_status}
            activeTab={activeTab}
            setFilter={setFilter}
          />
        </Col>
        <Col span={4} offset={8}>
          <CustomButton
            type="primary"
            icon={<PlusOutlined/>}
            className={style.newShipmentBtn}
            href={PathEnums.New_shipment_deliveries}
          >
            {t('forms.deliveries.new_shipment')}
          </CustomButton>
        </Col>
      </Row>
      {
        activeTab === 'tab1' && pallexItaly && pallexItalyTP
                && <ShippingApprovedTable
                  options={approvedOptions}
                  operatorsList={operatorsList}
                  approvedShipments={approvedShipments.items}
                  page={page}
                  count={count}
                  setPage={setPage}
                  setCount={setCount}
                  total={approvedShipments.countNotArchived}
                  pallexOptions={pallexOptions}
                  onChange={onChange}
                  pallexItaly={pallexItaly}
                  pallexItalyTP={pallexItalyTP}
                  contractors={contractors}
                />
      }
      {
        activeTab === 'tab2' && pallexItaly
                && <ArchivedTable
                  options={archivedOptions}
                  archivedShipments={approvedShipments.items}
                  page={page}
                  count={count}
                  setPage={setPage}
                  setCount={setCount}
                  total={approvedShipments.countArchived}
                  pallexOptions={pallexOptions}
                  onChange={onChange}
                  pallexItaly={pallexItaly}
                />
      }
    </div>
  );
};

export default ShippingApprovedContainer;
