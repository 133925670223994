import { useTranslation } from 'react-i18next';
import {
  Checkbox, Col, Form, FormInstance, Input, Modal, Row, Select,
} from 'antd';
import './modal.css';
import { useEffect } from 'react';
import classnames from 'classnames';
import CustomButton from 'components/CustomButton/CustomButton';
import RadioButton from 'components/RadioButton';
import GooglePlacesComponent from 'components/GooglePlacesComponent';
import Loader from 'components/Loader';
import { useAppSelector } from 'store/store';
import { UserItem, UserStructure } from 'store/types';
import { UserTypology, AddressFields, DeliveryType } from 'enums';
import style from './users.module.css';

const NewUserForm: React.FC<{
    visible: boolean,
    onFinish: (values) => void,
    onCancel: () => void,
    user: UserStructure | null,
    form: FormInstance,
    usersCompanyList: UserItem[],
}> = ({
  visible, onFinish, onCancel, user, form, usersCompanyList,
}) => {
  const priceListTemplates = useAppSelector((state) => state.priceListTemplates?.priceListTemplates);
  const loading = useAppSelector((state) => state.user?.loading);

  const { t } = useTranslation();
  const { Option } = Select;

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue({
        pickup_typology: user?.typology,
        business_name: user?.name,
        codica_cliente: user?.codica_cliente,
        email: user?.email,
        phone: user?.phone,
        vat_number: user?.vat_number,
        note: user?.note,
        pickup_address: { location: user?.address || '' },
        pickup_address_street: user?.address ? `${user?.address.street}, ${user?.address.house_number || ''}` : '',
        pickup_address_post_code: `${user?.address.post_code || ''}`,
        price_list: user?.price_list_id || priceListTemplates[0]?.id,
        private_area: user?.role === 'USER',
        pallex_name: user?.pallex_company_id,
      });
    }
  }, [form, priceListTemplates, user, visible]);

  return (
    <Modal
      centered
      visible={visible}
      onCancel={onCancel}
      footer={false}
      width={'42%'}
      className="users_modal"
    >
      <div className={style.user_modal}>
        <div className="main-title-30px">
          {user ? t('forms.users.update_client') : t('forms.users.create_new_client')}
        </div>
        { loading && user && <Loader/>}
        <Form
          key={`${user?.id}-${visible}`}
          form={form}
          className={style.form_modal}
          name="users_form"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="pickup_typology"
            rules={
              [
                { required: true, message: t('validation.empty') },
              ]
            }
          >
            <Row className={style.radio_group}>
              <Col span={6}>
                <RadioButton
                  checked={user?.typology === UserTypology.Company}
                  title={t('forms.shipmentDetails.agency')}
                  value={UserTypology.Company}
                  id="1"
                  name="group_1"
                />
              </Col>
              <Col span={6} offset={1}>
                <RadioButton
                  checked={user?.typology === UserTypology.PrivatePerson}
                  title={t('forms.shipmentDetails.private')}
                  value={UserTypology.PrivatePerson}
                  id="2"
                  name="group_1"
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            noStyle={true}
            shouldUpdate={
              (prev, curr) => prev.pickup_typology !== curr.pickup_typology
            }
          >
            {({ getFieldValue }) => (getFieldValue('pickup_typology') === 'Private_person'
              ? <Form.Item
                label={t('forms.users.name')}
                name="business_name"
                rules={
                  [
                    { required: true, message: t('validation.companyName') },
                    { whitespace: true, message: t('validation.companyName') },
                    { pattern: /^((?!\s\s+).)*$/gm, message: t('validation.companyName') },
                  ]
                }
              >
                <Input />
              </Form.Item>
              : <Form.Item
                label={t('forms.users.business_name')}
                name="business_name"
                rules={
                  [
                    { required: true, message: t('validation.companyName') },
                    { whitespace: true, message: t('validation.companyName') },
                    { pattern: /^((?!\s\s+).)*$/gm, message: t('validation.companyName') },
                  ]
                }
              >
                <Input />
              </Form.Item>)
            }
          </Form.Item>
          <Form.Item
            label={t('forms.users.codica_cliente')}
            name="codica_cliente"
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label={t('forms.users.email')}
            name="email"
            rules={
              [
                { required: true, message: t('validation.email') },
                { type: 'email', message: t('validation.email') },
              ]
            }
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label={t('forms.shipmentRequest.phoneNumber')}
            name="phone"
          >
            <Input/>
          </Form.Item>
          <Form.Item
            noStyle={true}
            shouldUpdate={
              (prev, curr) => prev.pickup_typology !== curr.pickup_typology
            }
          >
            {({ getFieldValue }) => (getFieldValue('pickup_typology') === 'Private_person'
              ? <Form.Item
                label={t('forms.users.fiscal_code')}
                name="vat_number"
                rules={
                  [
                    { required: true, message: t('validation.fiscalCode') },
                    { len: 16, message: t('validation.fiscalCode') },
                  ]
                }

              >
                <Input/>
              </Form.Item>
              : <Form.Item
                label={t('forms.users.vat_number')}
                name="vat_number"
                rules={
                  [
                    { required: true, message: t('validation.vatNumber') },
                    { len: 11, message: t('validation.vatNumber') },
                    { pattern: /^\d+$/, message: t('validation.number') },
                  ]
                }
              >
                <Input />
              </Form.Item>)
            }
          </Form.Item>
          <Form.Item
            label={t('forms.users.notes')}
            name="note"
            rules={
              [
                { whitespace: true, message: t('validation.address') },
                { pattern: /^((?!\s\s+).)*$/gm, message: t('validation.address') },
              ]
            }
          >
            <Input.TextArea
            />
          </Form.Item>
          <Row className={style.details_part}>
            <Col span={24}>
              <GooglePlacesComponent
                addressField={AddressFields.PICKUP_ADDRESS}
                deliveryType={DeliveryType.RITIRO}
                orderData={{
                  city: user ? `${user?.address.city}, ${user?.address?.province}, ${user?.address.country || 'Italia'}` : '',
                }}
                hideName
              />
            </Col>
          </Row>
          <Form.Item
            name="price_list"
            label={t('forms.users.associated_price_list')}
          >
            <Select
              className={classnames('status_select', 'blue_status')}
            >
              {
                priceListTemplates
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((i) => (
                    <Option key={i.id} value={i.id}>
                      {i.name}
                    </Option>
                  ))
              }
            </Select>
          </Form.Item>
          {
            !user && <Form.Item
              name="private_area"
              valuePropName="checked"
            >
              <Checkbox className={style.addressesSame}>
                {t('forms.users.access_private_area')}
              </Checkbox>
            </Form.Item>
          }
          <div className={style.vat_number_title}>
            {t('forms.users.billing_title')}
          </div>
          <div className={style.vat_number_description}>
            {t('forms.users.billing_description')}
          </div>
          <Form.Item
            label={t('forms.users.business_name')}
            name="pallex_name"
          >
            <Select>
              <Option value="">{t('forms.users.no_company')}</Option>
              {usersCompanyList.map((u) => (
                <Option key={u.id} value={u.id}>{u.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <CustomButton
            className={classnames('extra-bolt-corsivo', style.add_user_button)}
            htmlType="submit"
            type="primary"
          >
            { user ? t('forms.users.update') : t('forms.users.create')}
          </CustomButton>
        </Form>
      </div>
    </Modal>
  );
};

export default NewUserForm;
