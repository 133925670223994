import { put, takeLatest } from 'redux-saga/effects';
import {
  CreateContractor,
  DeleteContractor, GetContractorByID,
  GetContractors,
  UpdateContractor,
} from '../../../api/contractors/contractors';
import {
  setLoading,
  setContractorSliceData,
  deleteContractorSliceData,
  requestSetContractor,
  requestCreateContractor,
  requestUpdateContractor,
  requestDeleteContractor, requestClearContractor, requestGetContractor,
} from '../../redux_slice/contractors/contractorSlice';
import { Contractor, ContractorType, NewContractor } from '../../types';
import { setContractorsSliceData } from '../../redux_slice/contractors/contractorsSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* setContractorSaga(action: {
  payload: ContractorType
}) {
  yield put(setLoading(true));
  yield put(setContractorSliceData(action.payload));
  yield put(setLoading(false));
}

function* getContractorSaga(action: {
  payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetContractorByID(action.payload);
    yield put(setContractorSliceData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* updateContractorSaga(action: {
  payload: Contractor
}) {
  yield put(setLoading(true));
  try {
    let res = yield UpdateContractor(action.payload);
    yield put(setContractorSliceData(res.data));
    res = yield GetContractors();
    yield put(setContractorsSliceData(res.data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* createContractorSaga(action: {
  payload: NewContractor
}) {
  yield put(setLoading(true));
  try {
    let res = yield CreateContractor(action.payload);
    yield put(setContractorSliceData(res.data));
    res = yield GetContractors();
    yield put(setContractorsSliceData(res.data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* deleteContractorSaga(action: {
  payload: Contractor
}) {
  yield put(setLoading(true));
  try {
    yield DeleteContractor(action.payload.id.toString());
    yield put(deleteContractorSliceData());
    const { data } = yield GetContractors();
    yield put(setContractorsSliceData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* clearContractorSaga() {
  yield put(deleteContractorSliceData());
}

export function* contractorSaga() {
  yield takeLatest(requestSetContractor, setContractorSaga);
  yield takeLatest(requestGetContractor, getContractorSaga);
  yield takeLatest(requestCreateContractor, createContractorSaga);
  yield takeLatest(requestUpdateContractor, updateContractorSaga);
  yield takeLatest(requestDeleteContractor, deleteContractorSaga);
  yield takeLatest(requestClearContractor, clearContractorSaga);
}
