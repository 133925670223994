import { instance } from './index';
import { CreateOperatorPayload, UserPayload, UsersListPayload } from '../store/types';

export const GetUsersList = (data: UsersListPayload) => instance.get('/users', {
  params: {
    page: data.page,
    page_size: data.page_size,
    typology: data.typology,
    ...data.filter,
  },
});

export const CreateNewUser = (data: UserPayload | CreateOperatorPayload) => instance.post('/users', data);

export const GetUserById = (id: string) => instance.get(`/users/${id}`);

export const DeleteUserById = (id: string) => instance.delete(`/users/${id}/soft`);

export const UpdateUser = ({ id, ...data }) => instance.patch(`/users/${id}`, data.data);

export const GetAllUsers = (isGetAllUsers: boolean) => instance.get('users/operators', {
  params: {
    isGetAllUsers,
  },
});
