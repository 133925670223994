import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserS,
  Users,
  AssignUserPayload,
  UserSupport,
} from '../../types';

export const requestUsers = createAction('requestUsers');
export const requestAssignUser = createAction<AssignUserPayload>('requestAssignUser');
export const requestAssignUserAndUpdateList = createAction<AssignUserPayload>('requestAssignUserAndUpdateList');
export const requestCurrentUserAction = createAction<number>('requestCurrentUser');
export const requestUserSupport = createAction<UserSupport>('requestUserSupport');

type State = {
  users: Users | null,
  currentUser: UserS | null,
  supportSuccess: boolean,
}

const initialState: State = {
  users: null,
  currentUser: null,
  supportSuccess: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersData: (state, action: PayloadAction<Users>) => {
      state.users = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<UserS>) => {
      state.currentUser = action.payload;
    },
    setUserSupportSuccess: (state, action: PayloadAction<boolean>) => {
      state.supportSuccess = action.payload;
    },
  },
});

export const {
  setUsersData,
  setCurrentUser,
  setUserSupportSuccess,
} = usersSlice.actions;

export default usersSlice.reducer;
