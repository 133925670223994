import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import style from './pricelist.module.css';
import checked from './images/checked.png';
import DeleteModal from 'components/DeleteModal';
import { dataType } from './types';
import { useAppDispatch, useAppSelector } from '../../store/store';
import OptionsButton from '../../components/OptionsButton';
import { deletePricelistAction, postPriceListAction, requestPrices } from '../../store/redux_slice/pricelists/pricelistsSlice';
import Loader from '../../components/Loader';
import { PathEnums } from 'enums';

const PriceTable = ({
  pricelists,
  setIsAssociate,
  setPricelistId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDeleteModalId, setIsDeleteModalId] = useState<number | null>(null);
  const prices = useAppSelector((state) => state.pricelists.prices);
  const filterPrices = useAppSelector((state) => state.filterPriceList.filter);
  const role = useAppSelector((state) => state.auth.tokenStructure?.role);

  useEffect(() => {
    dispatch(requestPrices());
  }, [dispatch]);

  if (!prices) {
    return <Loader/>;
  }
  const options = [
    {
      title: t('pricelist.edit'),
      action: (id: string) => navigate(`${PathEnums.Details_pricelist}/${id}`),
    },
    {
      title: t('pricelist.duplicate'),
      action: (id: string) => {
        const pricelistToDuplicate = pricelists.items
          .filter((list) => list.id === +id)[0];
        const fullPriceList = prices.items
          .find((item) => item.id === pricelistToDuplicate.id);
        if (pricelistToDuplicate) {
          const newPricelist = {
            name: pricelistToDuplicate.name,
            price_list_params: prices.items
              .filter((item) => item.id === pricelistToDuplicate.id)
              .map((price) => price.price_list_params)[0],
            extra_services: fullPriceList?.extra_services || [],
            isSpecial: pricelistToDuplicate?.isSpecial,
            users_count: 0,
            shipments_count: 0,
          };
          dispatch(postPriceListAction({
            priceList: newPricelist,
            log: {
              role: role?.toLowerCase() || '',
              data: {
                section: 'Listini',
                action_type: `Duplicato il listino con nome ${pricelistToDuplicate.name || ''}`,
              },
            },
          }));
        }
      },
    },
    {
      title: t('pricelist.personalData'),
      action: (id: string) => {
        setIsAssociate(true);
        setPricelistId(id);
      },
    },
    {
      title: t('pricelist.delete'),
      action: (id: string) => {
        setIsDeleteModalId(+id);
      },
    },
  ];

  const onDeleteArchiveShipment = () => {
    if (isDeleteModalId) {
      dispatch(deletePricelistAction(isDeleteModalId));
      setIsDeleteModalId(null);
    }
  };

  const columns: ColumnsType<dataType> = [
    {
      title: t('pricelist.creationDate'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
    },
    {
      title: t('pricelist.listName'),
      dataIndex: 'name',
      key: 'name',
      className: style.semiBold,
      align: 'center',
    },
    {
      title: t('pricelist.data'),
      dataIndex: 'data',
      key: 'data',
      className: style.red,
      align: 'center',
    },
    {
      title: t('pricelist.shipmentsGenerated'),
      dataIndex: 'shipments',
      key: 'shipments',
      align: 'center',
    },
    {
      title: t('pricelist.related'),
      dataIndex: 'related',
      key: 'related',
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'options',
      key: 'options',
      align: 'center',
    },
  ];

  const data = pricelists.items
    .filter((pricelist) => {
      const { name } = pricelist;
      let isValid = true;
      if (!name.toLowerCase().includes(filterPrices.search.toLowerCase())) {
        return false;
      } if (filterPrices.date !== '') {
        isValid = isValid && format(new Date(pricelist.createdAt), 'dd-MM-yyyy') === filterPrices.date;
      } if (filterPrices.users_count !== '') {
        isValid = isValid && pricelist.users_count === filterPrices.users_count;
      } if (filterPrices.shipments_count !== '') {
        isValid = isValid && pricelist.shipments_count === filterPrices.shipments_count;
      } return isValid;
    })
    .map((pricelist) => {
      const {
        id,
        createdAt,
        name,
        isSpecial,
      } = pricelist;
      const dateFormatted = format(new Date(createdAt), 'dd-MM-yyyy');
      return {
        key: id,
        date: dateFormatted,
        name,
        data: pricelist.users_count || '0',
        shipments: pricelist.shipments_count || '0',
        related: isSpecial ? <img src={checked} alt="checked" /> : '-',
        options: <OptionsButton options={options} id={id}/>,
      };
    });

  return (
    <>
      <DeleteModal
        title={t('pricelist.delete_modal_title')}
        description={t('pricelist.delete_modal_description')}
        visible={!!isDeleteModalId}
        onCancel={() => setIsDeleteModalId(null)}
        onDelete={onDeleteArchiveShipment}
      />
      <Table columns={columns} dataSource={data} pagination={false}/>
    </>
  );
};

export default PriceTable;
