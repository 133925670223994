import { Table } from 'antd';
import style from './createdTable.module.css';

export interface DataType {
  key: string,
  rowSpan: number,
  region?: string,
  province: string,
  mini: string,
  quarter: string,
  half: string,
  medium: string,
  light: string,
  large: string,
  full: string,
  big: string,
  mega: string,
}

export interface DataTypeSave extends DataType{
  operation: string,
  amount: string,
  dataIndex: string,
}

type EditableTableProps = Parameters<typeof Table>[0];

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const columnsPrices: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
  {
    title: 'Regione',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
    className: style.bold,
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    onCell: (data: any) => ({
      rowSpan: data.rowSpan,
    }),
  },
  {
    title: 'Province',
    dataIndex: 'province',
    key: 'province',
    align: 'center',
  },
  {
    title: 'Mini',
    dataIndex: 'mini',
    key: 'mini',
    align: 'center',
    editable: true,
    width: '10%',
  },
  {
    title: 'Quarter',
    dataIndex: 'quarter',
    key: 'quarter',
    align: 'center',
    editable: true,
    width: '10%',
  },
  {
    title: 'Half',
    dataIndex: 'half',
    key: 'half',
    align: 'center',
    editable: true,
    width: '10%',
  },
  {
    title: 'Medium',
    dataIndex: 'medium',
    key: 'medium',
    align: 'center',
    editable: true,
    width: '8%',
  },
  {
    title: 'Light',
    dataIndex: 'light',
    key: 'light',
    align: 'center',
    editable: true,
    width: '10%',
  },
  {
    title: 'Large',
    dataIndex: 'large',
    key: 'large',
    align: 'center',
    editable: true,
    width: '10%',
  },
  {
    title: 'Full',
    dataIndex: 'full',
    key: 'full',
    align: 'center',
    editable: true,
    width: '10%',
  },
  {
    title: 'Big',
    dataIndex: 'big',
    key: 'big',
    align: 'center',
    editable: true,
    width: '10%',
  },
  {
    title: 'Mega',
    dataIndex: 'mega',
    key: 'mega',
    align: 'center',
    editable: true,
    width: '10%',
  },
];
