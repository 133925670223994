import axios, { AxiosResponse } from 'axios';
import {
  getToken, getRefreshToken, setToken, setRefreshToken,
} from '../store/localStorage';
import { store } from '../store/store';
import { setSession } from '../store/redux_slice/authSlice';
import { buildQueryString, parseToken } from '../functions';
import {
  AxiosConfig,
  ListResponse,
  PalletEstimate,
  PalletEstimateRequest,
  PatchEstimation,
  PriceListTemplate, User,
  Users, PatchUser,
} from '../store/types';
import { AppConfig } from '../config';

export const instance = axios.create({
  baseURL: `${AppConfig.API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const RefreshTokenRequest = (refreshToken: string) => instance.post<unknown,
  AxiosResponse<{ accessToken: string, refreshToken: string }>>('/auth/refresh', {
    refresh_token: refreshToken,
  });

const refreshSessionFlow = async (refreshToken) => {
  const { data: newSession } = await RefreshTokenRequest(refreshToken);
  setToken(newSession.accessToken);
  setRefreshToken(newSession.refreshToken);
  store.dispatch(setSession({
    accessToken: newSession.accessToken,
    tokenStructure: parseToken(newSession.accessToken),
  }));
  instance.defaults.headers.common['x-access-token'] = newSession.accessToken;
  return newSession.accessToken;
};

const getFreshAccessToken = () => {
  const token = getToken();
  if (!token) return null;
  const tokenStructure = parseToken(token);
  if (!tokenStructure) return token;
  const timestampNow = Date.now() / 1000;
  const refreshBeforeSec = 5;
  const secondsLeft = tokenStructure.exp - refreshBeforeSec - timestampNow;
  if (secondsLeft > 0) return token;
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return token;
  }
  return refreshSessionFlow(refreshToken);
};

instance.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      const token = config.url === '/auth/refresh'
        ? getToken()
        : await getFreshAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config as AxiosConfig;
    try {
      const refreshToken = getRefreshToken();
      if (!(err.response.status === 401 && !originalConfig.retry && refreshToken)) {
        if (err.response.status === 401 && originalConfig.retry) {
          window.location.replace('/');
          localStorage.clear();
          return Promise.reject(err);
        }
        return Promise.reject(err);
      }
      originalConfig.retry = true;
      return [
        instance(originalConfig),
        await refreshSessionFlow(refreshToken),
      ];
    } catch (e) {
      window.location.replace('/');
      localStorage.clear();
      return Promise.reject(err);
    }
  },
);

export const SignIn = (data) => instance.post('/auth/login', data);

export const SendDefaultPass = (email: string) => instance.post('/auth/set-default-pass', { email });

export const GetAccessPrivate = (email: string) => instance.post('/auth/access-private', { email });

export const RecoveryPassword = (email: string) => instance.post('/auth/recovery', { email });

export const UpdatePassword = (password: string, query: string) => instance.post(
  `/auth/pass-update?query=${query}`,
  { newPassword: password },
);

export const GetEstimationsList = (data) => instance.get(
  '/shipping/request/preventivi-all',
  {
    params: {
      page: data.page,
      page_size: data.page_size,
      isArchived: data.isArchived,
      ...data.filter,
    },
  },
);

export const GetUser = () => instance.get('/users/me');

export const PostNewPassword = (data) => instance.post('/auth/change-pass', data);

export const UpdateUserSettings = ({ id, data }: PatchUser) => instance.patch(`users/${id}`, data);

export const UpdateShippingRequest = ({ id, ...data }:PatchEstimation) => instance.patch(`shipping/request/${id}/request`, data.data);

export const GetShippingServices = () => instance.get('shipping/request/service');

export const GetPricelists = () => instance.get('/price-list/count-users');

export const GetPrices = () => instance.get('/price-list');

export const PostParsePriceslist = (data) => instance.post('/price-list/parse/list-xlsx', data);

export const PostPricelist = (data) => instance.post('/price-list', data);

export const DeletePicelist = (id) => instance.delete(`/price-list/${id}`);

export const PatchPricelist = (id, data) => instance.patch(`/price-list/${id}`, data);

export const GetUsers = () => instance.get('/users/erp/unverified');

export const GetUserById = (id) => instance.get(`/users/${id}`);

export const SearchUsers = (query: Record<string, string | number> | URLSearchParams | undefined): Promise<User[]> => instance.get(`users/search${buildQueryString(query)}`);

export const PostSupport = (data) => instance.post('/users/support', data);
export const GetAssignUserToPricelist = (data) => instance.get(`/price-list/${data.pricelistId}/user/${data.userId}`);

export const GetPriceListTemplates = (): Promise<ListResponse<PriceListTemplate>> => instance.get('price-list/templates');

export const GetUserPriceList = (email: string): Promise<Users | null> => instance.get(`users/${email}/price-list`);

export const GetPriceList = async (id: number) => instance.get(`price-list/${id}`);

export const GetPallexHubs = () => instance.get('pallex/hub');

export const GetPalletEstimation = (data: PalletEstimateRequest): Promise<AxiosResponse<PalletEstimate[]>> => (
  instance.post('shipping/request/pallet-estimate', data)
);

export const GetFinancePalletEstimation = (data: PalletEstimateRequest): Promise<AxiosResponse<PalletEstimate[]>> => (
  instance.post('shipping/request/pallet-estimate/finance', data)
);

export const GetPalletEstimationCSV = (data): Promise<AxiosResponse<PalletEstimate[]>> => instance.post('shipping/request/parse/pallets', data, {
  headers: { 'content-type': 'multipart/form-data' },
});

export const GetRequestEstimate = (id: string) => instance.get(`shipping/request/${id}/estimate`);

export const CreateRequestEstimate = (id: string) => instance.post(`shipping/request/${id}/estimate`);

export const UpdateRequestEstimate = ({ id, ...data }) => instance.patch(
  `shipping/request/${id}/estimate`,
  data,
);

export const PostParseServices = (data) => instance.post('/price-list/parse/services-xlsx', data);

export const PostParceShipmentXlsx = (data) => instance.post('/shipping/request/parse/united-xlsx', data);

export const ApproveShippingRequest = (id: string) => instance.get(`/shipping/request/${id}/send-details-mail`);

export const GetShipmentsList = (isApproved: boolean) => instance.get('/shipping/request', { params: { isApproved } });
