import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PricelistsStructure,
  Prices,
  PricelistStructure,
  PatchPricelistStructure,
  PricelistItem, AddLogPayload,
} from '../../types';

export const requestPricelists = createAction('requestPricelists');
export const requestPrices = createAction('requestPrices');
export const postPriceListAction = createAction<
    {priceList: PricelistStructure, log: AddLogPayload, assignedUsers?: string[]}>('postPricelist');
export const deletePricelistAction = createAction<number>('deletePricelist');
export const updatePricelistAction = createAction<PatchPricelistStructure>('patchPricelist');
export const updatePricesAction = createAction<PatchPricelistStructure>('patchPrices');
export const getPriceListByIdAction = createAction<number>('getPriceListByIdAction');

type State = {
  pricelists: PricelistsStructure | null,
  prices: Prices | null,
  priceListByID: PricelistItem | null,
  pricelistFile: string | null | ArrayBuffer,
  extraServices: string | null,
  lastCreated: number,
  loading: boolean,
  successPatch: boolean,
}

const initialState: State = {
  pricelists: null,
  prices: null,
  priceListByID: null,
  pricelistFile: '',
  extraServices: null,
  lastCreated: 0,
  loading: false,
  successPatch: false,
};

const pricelistsSlice = createSlice({
  name: 'pricelists',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLastCreated: (state, action: PayloadAction<number>) => {
      state.lastCreated = action.payload;
    },
    setPriceListById: (state, action: PayloadAction<PricelistItem>) => {
      state.priceListByID = action.payload;
    },
    setPricelistsData: (state, action: PayloadAction<PricelistsStructure | null>) => {
      state.pricelists = action.payload;
    },
    deletePricelist: (state, action: PayloadAction<number>) => {
      if (!state.pricelists?.items || !state.prices?.items) return;
      const pricelistsItems = state.pricelists.items;
      const prices = state.prices.items;
      state.pricelists = {
        ...state.pricelists,
        items: [...pricelistsItems.filter((item) => item.id !== action.payload)],
      };
      state.prices = {
        ...state.prices,
        items: [...prices.filter((item) => item.id !== action.payload)],
      };
    },
    setNewPricelist: (state, action: PayloadAction<PricelistItem>) => {
      if (!state.pricelists?.items) return;
      const pricelistsItems = state.pricelists.items;
      state.pricelists = {
        ...state.pricelists,
        items: [...pricelistsItems, action.payload],
      };
    },
    setPrices: (state, action) => {
      state.prices = { ...state.prices, ...action.payload };
    },
    parsePricelist: (state, action: PayloadAction<string | null | ArrayBuffer>) => {
      state.pricelistFile = action.payload;
    },
    setSuccessPriceListPatch: (state, action: PayloadAction<boolean>) => {
      state.successPatch = action.payload;
    },
    patchPriceSlice: (state, action: PayloadAction<PatchPricelistStructure>) => {
      if (!state.prices?.items) return;
      const index = state.prices.items.findIndex((item) => item.id === action.payload.id);
      if (index === -1) return;
      const el = state.prices.items[index];
      state.prices.items[index] = {
        ...el,
        ...action.payload,
      };
    },
  },
});

export const {
  setPricelistsData,
  setLoading,
  setLastCreated,
  parsePricelist,
  setPrices,
  setNewPricelist,
  deletePricelist,
  setSuccessPriceListPatch,
  patchPriceSlice,
  setPriceListById,
} = pricelistsSlice.actions;

export default pricelistsSlice.reducer;
