import {
  Button, Form, Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ReloadOutlined } from '@ant-design/icons/lib/icons';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Drag from '../../../components/Drag';

import style from './upload.module.css';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import CustomSelect from '../../../components/CustomSelect';
import {
  requestPricelists,
  requestPrices,
} from '../../../store/redux_slice/pricelists/pricelistsSlice';
import Loader from '../../../components/Loader';
import { PostParsePriceslist, PostParseServices } from '../../../api';
import { ExtraServiceStructure, Prices, PricesItem } from '../../../store/types';
import { setTemplatePriceList } from '../../../store/redux_slice/pricelists/priceListTemplateSlice';
import { PathEnums } from 'enums';

interface NewPricelist {
  name: string,
  price_list_params: Prices,
  isSpecial: boolean,
  extra_services: string[],
}

const UploadTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [filePriceList, setPriceListFile] = useState<boolean | null | ArrayBuffer>(null);
  const [fileExtra, setFileExtra] = useState<boolean | null | ArrayBuffer>(null);
  const [selectedPricelist, setSelectedPricelist] = useState<PricesItem | null>(null);
  const [priceListToBeCreated, setPriceListToBeCreated] = useState<NewPricelist | null>(null);
  const [priceListServicesToBeCreated, setPriceListServicesToBeCreated] = useState<ExtraServiceStructure[] | null>(null);
  const pricelist = useAppSelector(
    (state) => state.pricelists,
  );

  useEffect(() => {
    dispatch(requestPrices());
    dispatch(requestPricelists());
  }, [dispatch]);

  if (!pricelist || !pricelist.prices) {
    return <Loader/>;
  }

  const pricesRequest = (options) => {
    const {
      onSuccess, onError, file,
    } = options;
    const priceName = file.name.replace(/.csv/, '');

    const fmData = new FormData();
    fmData.append('file', file);

    PostParsePriceslist(fmData)
      .then((res) => {
        const { data } = res;
        setPriceListFile(true);
        setPriceListToBeCreated({
          name: priceName,
          price_list_params: data,
          extra_services: [],
          isSpecial: false,
        });
        onSuccess(file);
      })
      .catch((error) => {
        setPriceListFile(false);
        onError({ event: error });
      });
  };

  const extraServicesRequest = (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    fmData.append('file', file);

    PostParseServices(fmData)
      .then((res) => {
        setFileExtra(true);
        const { data } = res;
        setPriceListServicesToBeCreated(data);
        onSuccess(file);
      })
      .catch((error) => {
        setFileExtra(false);
        onError({ event: error });
      });
  };

  const pricesTemplates = pricelist.prices.items
    .filter((pricelists) => pricelists.price_list_params && Object.keys(pricelists.price_list_params).length > 0);

  const onReset = () => {
    setSelectedPricelist(null);
    form.resetFields();
  };

  const createNewPriceListTemplate = () => {
    dispatch(setTemplatePriceList(
      priceListToBeCreated ? {
        ...priceListToBeCreated,
        extra_services: priceListServicesToBeCreated || [],
      } : {
        name: 'New pricelist',
        price_list_params: selectedPricelist?.price_list_params || {},
        extra_services: selectedPricelist?.extra_services || [],
        isSpecial: false,
      },
    ));
    navigate(PathEnums.Created_pricelist);
  };
  return (
    <div className={style.form_container}>
      <Space className={style.title_container}>
        <Button
          className={style.csv_btn}
          type="default"
          href="/files/admin_price_lists_example.xlsx"
          target="_blank"
          download
        >
          {t('shipmentList.xlsxPalletTemplate')}
        </Button>
        <Button
          className={style.csv_btn}
          type="default"
          href="/files/admin_services_example.xlsx"
          target="_blank"
          download
        >
          {t('shipmentList.xlsxOptionsTemplate')}
        </Button>
      </Space>
      <Form className={style.form} layout="vertical" form={form}>
        <Space>
          <Space direction="vertical">
            <div className="title-20px">{t('uploadFile.uploadPriceList')}</div>
            <Form.Item>
              <Drag
                width={700}
                customRequest={pricesRequest}
                onRemove={() => {
                  setPriceListToBeCreated(null);
                  setPriceListFile(false);
                }}
                disabled={Boolean(selectedPricelist)}
              />
            </Form.Item>
          </Space>
          <Space direction="vertical">
            <div className="title-20px">{t('uploadFile.uploadExtraServices')}</div>
            <Form.Item>
              <Drag
                width={700}
                customRequest={extraServicesRequest}
                onRemove={() => {
                  setPriceListServicesToBeCreated(null);
                  setFileExtra(false);
                }}
                disabled={Boolean(selectedPricelist)}
              />
            </Form.Item>
          </Space>
        </Space>
        <h3>{t('uploadFile.or')}</h3>
        <p className={style.label}>{t('uploadFile.createNew')}</p>
        <Form.Item name="price_list" className={cn(style.select, style.selectWrapper)}>
          <CustomSelect
            onChange={(v) => setSelectedPricelist(JSON.parse(v))}
            options={pricesTemplates ? pricesTemplates.map((price, index) => ({
              key: index,
              value: JSON.stringify(price),
              title: price.name,
            })) : [{ value: 'empty', title: 'empty' }]}
            disabled={Boolean(filePriceList && fileExtra)}
            withEmpty={false}
          />
          { Boolean(selectedPricelist) && <CustomButton
            icon={<ReloadOutlined/>}
            className={style.resetBtn}
            onClick={() => onReset()}
          >{t('buttons.reset')}</CustomButton> }
        </Form.Item>
        <CustomButton
          className={style.proceed}
          type="primary"
          disabled={!(selectedPricelist || (filePriceList && fileExtra))}
          onClick={createNewPriceListTemplate}
        >
          {t('uploadFile.proceed')}
        </CustomButton>
      </Form>
    </div>
  );
};

export default UploadTab;
