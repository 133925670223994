import { put, takeLatest } from 'redux-saga/effects';
import { UserPriceListPayload, UsersListPayload } from '../../types';
import {
  setLoading, setUsersList, requestUsersList, requestChangeUserPriceList,
} from '../../redux_slice/users/usersListSlice';
import { GetUsersList } from '../../../api/users';
import { UpdateUserPriceList } from '../../../api/price';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* getUsersListSaga(action: {
    payload: UsersListPayload
}) {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetUsersList(action.payload));
    yield put(setUsersList(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* changeUserPriceListSaga(action: {
  payload: UserPriceListPayload
}) {
  try {
    (yield UpdateUserPriceList(action.payload.id, action.payload.userId));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

export function* usersListSaga() {
  yield takeLatest(requestUsersList, getUsersListSaga);
  yield takeLatest(requestChangeUserPriceList, changeUserPriceListSaga);
}
