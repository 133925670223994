import i18n from 'i18next';
import { put, takeLatest } from 'redux-saga/effects';
import { GetShippingTableType, GetShippingRequest } from '../../api/shipping';
import {
  getShippingTableTypeAction, setShippingType, setTableTypeLoading,
  setShipmentDataFromNotification, getShippingDataFromNotificationAction,
  setNotificationShipmentLoading,
} from '../redux_slice/notificationsSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';

function* getShippingTableType(action: {
  payload: string
}) {
  yield put(setTableTypeLoading(true));
  try {
    const { data } = yield GetShippingTableType(action.payload);
    if (data !== 'undefined') {
      yield put(setShippingType({
        id: action.payload,
        type: data,
      }));
    } else {
      yield put(setMessage({ description: i18n.t('notifications.shippingNotFound') }));
    }
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setTableTypeLoading(false));
}

function* getShippingRequestFromNotificationSaga(action: {
  payload: string
}) {
  yield put(setNotificationShipmentLoading(true));
  try {
    const { data } = yield GetShippingRequest(action.payload);
    yield put(setShipmentDataFromNotification(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setNotificationShipmentLoading(false));
}

export function* notificationsSaga() {
  yield takeLatest(getShippingTableTypeAction, getShippingTableType);
  yield takeLatest(getShippingDataFromNotificationAction, getShippingRequestFromNotificationSaga);
}
