import classnames from 'classnames';
import { CustomTabsProps } from './CustomTabs';
import style from './CustomTabs.module.css';
import { UserTypology } from '../../enums';

const CustomTabs = ({
  activeTab, setActiveTab, leftText, leftNumber, rightText, rightNumber, setIsChange, setChange, setPage, ...props
}: CustomTabsProps) : JSX.Element => (
  <div className={style.tabs_container}
    {...props}
  >
    <p
      className={classnames(style.tab, activeTab !== 'tab1' && style.tab_disabled)}
      onClick={
        () => {
          if (activeTab !== 'tab1') {
            setActiveTab('tab1');
            if (setIsChange) {
              setIsChange(false);
              setPage(1);
            }
            if (setChange) {
              setChange(UserTypology.Company);
              setPage(1);
            }
          }
        }
      }
    >
      {leftText}
      <span className={style.tab_number}>
          ({leftNumber})
      </span>
    </p>
    <div className={style.vl}/>
    <p
      className={classnames(style.tab, activeTab !== 'tab2' && style.tab_disabled)}
      onClick={() => {
        if (activeTab !== 'tab2') {
          setActiveTab('tab2');
          if (setIsChange) {
            setIsChange(true);
            setPage(1);
          }
          if (setChange) {
            setChange(UserTypology.PrivatePerson);
            setPage(1);
          }
        }
      }}
    >
      {rightText}
      <span className={style.tab_number}>
        ({rightNumber})
      </span>
    </p>
  </div>
);

export default CustomTabs;
