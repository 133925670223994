import React, { useCallback, useState } from 'react';
import {
  Form, FormInstance, Modal, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PallexHub, PatchEstimation } from '../../../store/types';
import CustomButton from '../../../components/CustomButton/CustomButton';
import style from '../estimations.module.css';
import { requestUpdateEstimation } from '../../../store/redux_slice/estimationsListSlice';
import { useAppDispatch } from '../../../store/store';
import { updateToBeApprovedShipmentAction } from '../../../store/redux_slice/shipping/shippingBeforeApproveSlice';

const { Option } = Select;

export const ChangeHabModalForm: React.FC<{
    id: string,
    visible: boolean,
    onCancel: () => void,
    options: PallexHub[],
    onRequestUpdateEstimation: (data: PatchEstimation) => void,
    form?: FormInstance,
    initialValue: number | null,
}> = ({
  id,
  visible,
  onCancel,
  options,
  onRequestUpdateEstimation,
  form,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>();
  const onChange = (v: string) => {
    setValue(parseFloat(v));
  };
  const submit = () => {
    const data = {
      idHub: value,
    };
    onRequestUpdateEstimation({
      id,
      data,
    });
    onCancel();
  };
  return (
    <Modal title={t('forms.estimations.select_hub')}
      visible={visible}
      onOk={submit}
      onCancel={onCancel}
      footer={false}
    >
      <Form form={form}
        initialValues={{
          hab_name: initialValue,
        }
        }
        name="hab"
        onFinish={submit}
      >
        <Form.Item
          name="hab_name"
        >
          <Select style={{ width: 300 }} onChange={onChange}>
            {
              options?.map((i) => (
                <Option key={i.idHub} value={i.idHub}>
                  {i.name}
                </Option>
              ))
            }
          </Select>
        </Form.Item>
        <CustomButton
          className={style.modal_button}
          type="primary"
          htmlType="submit"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};

export type ChangeHabModalProps = {
    id: string,
    visible: boolean,
    onCancel: () => void,
    options: PallexHub[],
    form?: FormInstance,
    initialValue: number | null,
}

export const ChangeHabModal: React.FC<ChangeHabModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const onRequestUpdateEstimation = useCallback(
    (data: PatchEstimation) => dispatch(requestUpdateEstimation(data)),
    [dispatch],
  );
  return <ChangeHabModalForm {...props} onRequestUpdateEstimation={onRequestUpdateEstimation}/>;
};

export const ChangeHabModalToBeApproved: React.FC<ChangeHabModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const onRequestUpdateEstimation = useCallback(
    (data: PatchEstimation) => dispatch(updateToBeApprovedShipmentAction(data)),
    [dispatch],
  );
  return <ChangeHabModalForm {...props} onRequestUpdateEstimation={onRequestUpdateEstimation}/>;
};
