import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { useEffect, useState } from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import Loader from 'components/Loader';
import DeleteModal from 'components/DeleteModal';
import OperatorsListTable from './OperatorsListTable';
import AddOperatorModal from './AddOperatorModal';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  clearOperator,
  requestCreateOperator,
  requestDeleteOperator, requestUpdateOperator,
} from 'store/redux_slice/operators/operatorSlice';
import { requestOperatorsList } from 'store/redux_slice/operators/operatorsListSlice';
import { requestUserData } from 'store/redux_slice/settingsSlice';
import style from './operatorsList.module.css';

const OperatorsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string| null>(null);
  const operatorsList = useAppSelector((state) => state.operatorsList.operatorsList);
  const operator = useAppSelector((state) => state.operator?.operator);
  const userData = useAppSelector((state) => state.settings.userData);

  useEffect(() => {
    dispatch(requestOperatorsList(false));
    dispatch(requestUserData());
  }, [dispatch]);
  if (!operatorsList) {
    return <Loader/>;
  }
  const createNewOperator = (values) => {
    if (operator) {
      const updateDate = {
        id: operator?.id,
        data: values,
      };
      dispatch(requestUpdateOperator({ user: updateDate, isGetAllUsers: false }));
    } else {
      dispatch(requestCreateOperator({ user: values, isGetAllUsers: false }));
    }
    setOpenModal(false);
    dispatch(clearOperator());
  };
  const onDeleteOperator = () => {
    setIsDeleteModal(false);
    dispatch(requestDeleteOperator({ id: deleteId ?? '', isGetAllUsers: false }));
  };
  const onCancelNewOperatorForm = () => {
    setOpenModal(false);
    dispatch(clearOperator());
  };
  return (
    <>
      <DeleteModal
        title={t('forms.users.delete_client')}
        description={t('forms.users.delete_client_description')}
        visible={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        onDelete={onDeleteOperator}
      />
      <AddOperatorModal
        openModal={openModal}
        onCancel={onCancelNewOperatorForm}
        onFinish={createNewOperator}
      />
      <div className={style.operators_list_container}>
        <Row>
          <Col span={12}>
            <p className={style.operators}>{t('operatorsList.operators')}
              <span className={style.operators_count}>({operatorsList?.length})</span>
            </p>
          </Col>
          <Col span={4} offset={8}>
            <CustomButton
              type="primary"
              icon={<PlusOutlined/>}
              className={style.newUserBtn}
              onClick={() => setOpenModal(true)}
            >
              {t('operatorsList.new_operator')}
            </CustomButton>
          </Col>
        </Row>
      </div>
      <OperatorsListTable
        setDeleteId={setDeleteId}
        setIsDeleteModal={setIsDeleteModal}
        setOpenModal={setOpenModal}
        operatorsList={operatorsList}
        userData={userData}
      />
    </>
  );
};

export default OperatorsList;
