import { put, takeLatest } from 'redux-saga/effects';
import { GetUserPriceList } from '../../api';
import { setLoading, setUserPriceListSliceData, requestGetUserPriceList } from '../redux_slice/userPriceListSlice';

function* getUserPriceListSaga(action: {
  payload: string
}) {
  yield put(setLoading(true));
  try {
    if (!action.payload) {
      throw new Error('Empty email');
    }
    const { data } = yield GetUserPriceList(action.payload);
    yield put(setUserPriceListSliceData(data));
  } catch (e) {
    yield put(setUserPriceListSliceData(null));
  }
  yield put(setLoading(false));
}

export function* userPriceListSaga() {
  yield takeLatest(requestGetUserPriceList, getUserPriceListSaga);
}
