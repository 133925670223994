import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import RowItem, { ItemTypes } from './RowItem';
import SplitModal from '../SplitModal';
import { ScaricoPalletsToSplitType, SplittedPalletsDataType } from '../../../../store/types';
import styles from './palletsTable.module.css';

const PalletsTable = ({ palletsInfo, tableIndex } : {
  palletsInfo: SplittedPalletsDataType,
  tableIndex: number
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [palletsToSplit, setPalletsToSplit] = useState<ScaricoPalletsToSplitType | null>(null);

  const tableColumns = [
    {
      title: t('manifest.quantity'),
      key: 'item_quantity',
    },
    {
      title: t('manifest.shortSide'),
      key: 'depth',
    },
    {
      title: t('manifest.longSide'),
      key: 'height',
    },
    {
      title: t('manifest.height'),
      key: 'weight',
    },
    {
      title: t('manifest.weight'),
      key: 'width',
    },
  ];

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.TABLE_LINE,
      drop(draggedPallet: ScaricoPalletsToSplitType) {
        if (draggedPallet.tableIndex !== tableIndex) {
          setVisible(true);
          setPalletsToSplit({
            ...draggedPallet,
            newTableIndex: tableIndex,
          });
        }
      },
    }),
    [],
  );

  return (
    <>
      <div className={styles.table_container}>
        <div className={styles.tr}>
          { tableColumns.map(({ title, key }) => (
            <div key={key} className={styles.th}>{ title }</div>
          ))}
        </div>

        <div ref={drop}>
          { palletsInfo.pallets.length ? (
            palletsInfo.pallets.map((pallet, index) => (
              <RowItem
                pallet={pallet}
                palletIndex={index}
                tableIndex={tableIndex}
                key={`pallet-${index}`}
              />
            ))) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('forms.shipmentDetails.no_data')}
            />
          )}
        </div>
      </div>
      <SplitModal
        visible={visible}
        setVisible={setVisible}
        palletsToSplit={palletsToSplit}
      />
    </>
  );
};

export default PalletsTable;
