import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import {
  EstimationStructure,
  PatchEstimation, PatchEstimationUser, ShippingEstimateCreatePayload,
  UpdateShippingEstimateRequest, UpdateUserPriceListRequest, ShipmentStructure,
} from '../types';

export const requestUpdateEstimation = createAction<PatchEstimation>('requestUpdateEstimation');
export const requestGetShippingEstimate = createAction<string>('requestGetShippingEstimate');
export const requestCreateShippingEstimate = createAction<ShipmentStructure>('requestCreateShippingEstimate');
export const requestCreatePriceShippingEstimate = createAction<ShippingEstimateCreatePayload>('requestCreatePriceShippingEstimate');
export const requestUpdateShippingEstimate = createAction<UpdateShippingEstimateRequest>('requestUpdateShippingEstimate');
export const requestUpdatePriceList = createAction<UpdateUserPriceListRequest>('requestUpdatePriceList');

type State = {
  estimationsList: EstimationStructure | null
  loading: boolean,
}
const initialState : State = {
  estimationsList: null,
  loading: false,
};

const estimationsListSlice = createSlice({
  name: 'estimationsList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEstimationsList: (state, action: PayloadAction<EstimationStructure | null>) => {
      state.estimationsList = action.payload;
    },
    patchListElement: (state, action: PayloadAction<PatchEstimation>) => {
      if (!state.estimationsList?.items) return;
      const index = state.estimationsList.items.findIndex((e) => e.id === action.payload.id);
      if (index === -1) return;
      const el = state.estimationsList.items[index];
      state.estimationsList.items[index] = {
        ...el,
        ...action.payload.data,
      };
    },
    patchUserPriceList: (state, action: PayloadAction<PatchEstimationUser>) => {
      if (!state.estimationsList?.items) return;
      const index = state.estimationsList.items.findIndex((e) => e.id === action.payload.estimationId.toString());
      if (index === -1) return;
      state.estimationsList.items[index].user.price_list_id = action.payload.price_list_id;
    },
  },
});

export const {
  setEstimationsList, patchListElement, setLoading, patchUserPriceList,
} = estimationsListSlice.actions;

export default estimationsListSlice.reducer;
