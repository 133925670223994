import { put, takeLatest } from 'redux-saga/effects';
import { GetSuperAdminParams, PatchSuperAdminParams } from '../../api/superAdmin';
import { setMessage } from '../redux_slice/messageErrorSlice';
import {
  requestGetSuperAdminParams, requestUpdateSuperAdminParams, setLoading, setSuperAdminParams,
} from '../redux_slice/superAdminSlice';
import { SuperAdminParams } from '../types';

function* getSuperAdminParamsSaga() {
  yield put(setLoading(true));
  try {
    const { data } = yield GetSuperAdminParams();
    yield put(setSuperAdminParams(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* patchSuperAdminParamsSaga(action: {
    payload: SuperAdminParams
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield PatchSuperAdminParams(action.payload);
    yield put(setSuperAdminParams(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* superAdminSaga() {
  yield takeLatest(requestGetSuperAdminParams, getSuperAdminParamsSaga);
  yield takeLatest(requestUpdateSuperAdminParams, patchSuperAdminParamsSaga);
}
