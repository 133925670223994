import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import lodash from 'lodash';

import { useAppSelector, useAppDispatch } from '../../../store/store';
import {
  setLastCreated,
  updatePricelistAction,
  setSuccessPriceListPatch,
  getPriceListByIdAction,
} from '../../../store/redux_slice/pricelists/pricelistsSlice';
import Loader from '../../../components/Loader';
import CreatedHeader from '../CreatedHeader/CreatedHeader';
import CreatedTable from '../CreatedTable';

import style from './updatedPricelist.module.css';
import { DataTypeSave } from '../CreatedTable/TableColumns';
import { PricesItem } from '../../../store/types';
import { priceKeys } from '../CreatedTable/EditableCell';
import { requestAssignUserAndUpdateList } from '../../../store/redux_slice/users/usersSlice';
import { PathEnums } from 'enums';

const UpdatedPricelist = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pricelistName, setPricelistName] = useState<string>('');
  const [isSpecial, setIsSpecial] = useState<boolean>(false);
  const [isAssociate, setIsAssociate] = useState<boolean>(false);
  const [assignedUsers, setAssignedUsers] = useState<string[]>([]);
  const [listOfChanged, setListOfChanged] = useState<{[key: string]: string | undefined}>({});
  const newExtraServices = useAppSelector((state) => state.extraServicesByPriceList.services);
  const { id } = useParams();
  const { priceListByID, successPatch } = useAppSelector(
    (state) => state.pricelists,
  );

  useEffect(() => {
    if (id) {
      dispatch(getPriceListByIdAction(+id));
      dispatch(setLastCreated(0));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (successPatch) {
      navigate(PathEnums.PriseList);
      dispatch(setSuccessPriceListPatch(false));
    }
  }, [successPatch, navigate, dispatch]);

  if (!priceListByID || !id) {
    return <Loader/>;
  }

  const updateListOfChanged = (row: DataTypeSave) => {
    setListOfChanged((prev) => ({
      ...prev,
      [`${row.region}_${row.province}_${row.dataIndex}`]: Number.isNaN(row.amount) || !Number(row.amount)
        ? undefined : row.operation + row.amount,
    }));
  };

  const updatePrices = (percents: string | undefined) => {
    if (priceListByID) {
      const d: { [key: string]: string | undefined } = {};
      Object.keys(priceListByID?.price_list_params).forEach((key) => {
        priceListByID.price_list_params[key].forEach((o) => {
          priceKeys.forEach((dataIndex) => {
            d[`${key}_${o.province}_${dataIndex}`] = percents;
          });
        });
      });
      setListOfChanged(d);
    }
  };

  const savePriceList = async () => {
    const data: {
      id: number,
      data: Partial<PricesItem>
    } = {
      id: priceListByID.id,
      data: {
        name: pricelistName || priceListByID.name,
        isSpecial,
        extra_services: newExtraServices || [],
      },
    };
    if (Object.values(listOfChanged).some((l) => !!l) && priceListByID) {
      const newPrices = lodash.cloneDeep(priceListByID.price_list_params);
      Object.keys(listOfChanged).forEach((key) => {
        const [region, province, priceKey] = key.split('_');
        if (region && province && priceKey && newPrices[region]) {
          const vRegion = newPrices[region].find((r) => r.province === province);
          if (vRegion) {
            const price = Number.parseFloat(vRegion[priceKey].replace(',', '.'));
            vRegion[priceKey] = (price + (price * Number(listOfChanged[key])) / 100).toFixed(1).replace('.', ',');
          }
        }
      });
      data.data.price_list_params = newPrices;
    }
    dispatch(updatePricelistAction(data));
    if (assignedUsers.length) {
      await Promise.all(assignedUsers.map((userId) => dispatch(
        requestAssignUserAndUpdateList({ pricelistId: priceListByID.id.toFixed(), userId }),
      )));
    }
  };

  const columns = [
    {
      title: 'Nome azienda',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Partita iva',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
    },
    {
      title: 'Associato a listino',
      dataIndex: 'associated',
      key: 'associated',
      align: 'center',
    },
  ];

  return (
    <div className={style.pricelist_container}>
      <CreatedHeader
        setPricelistName={setPricelistName}
        pricelistName={pricelistName || priceListByID.name}
        isSpecial={isSpecial}
        setIsSpecial={setIsSpecial}
        isAssociate={isAssociate}
        setIsAssociate={setIsAssociate}
        currentPricelist={priceListByID}
        savePriceList={savePriceList}
        updatePrices={updatePrices}
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
      />
      <CreatedTable
        currentPricelist={priceListByID}
        listOfChanged={listOfChanged}
        updateListOfChanged={updateListOfChanged}
      />
    </div>
  );
};

export default UpdatedPricelist;
