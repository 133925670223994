import { useEffect, useMemo } from 'react';
import { Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import { useAppDispatch, useAppSelector } from 'store/store';
import { requestGetShippingServicesByPriceList } from 'store/redux_slice/shippingServicesSlice';
import { requestUserData } from 'store/redux_slice/settingsSlice';
import { toUpperCaseFirst } from 'functions';
import {
  CONTRASSEGNO, triangServices,
} from 'pesantiConstants';
import { DeliveryType } from 'enums';
import style from './extraServices.module.css';
import './styles.css';

interface Props {
  isUser?: boolean;
  deliveryType?: DeliveryType;
}

const ExtraServices = ({ isUser = false, deliveryType }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const loadingServices = useAppSelector((state) => state.extraServices.loading);
  const extraServices = useAppSelector((state) => state.extraServices?.shippingServices);
  const userData = useAppSelector((state) => state.settings.userData);

  const mappedExtraServices = useMemo(() => (
    extraServices?.filter((service) => !service.isAdmin)
  ), [extraServices]);

  useEffect(() => {
    dispatch(requestUserData());
  }, [dispatch]);

  useEffect(() => {
    if (isUser && userData && userData.price_list_id) {
      dispatch(requestGetShippingServicesByPriceList(userData.price_list_id));
    }
  }, [dispatch, userData, isUser]);

  if (loadingServices) return <Loader/>;
  if (!extraServices?.length) return null;

  return (
    <>
      <p className={style.subtitle}>
        { deliveryType === DeliveryType.TRIANG ? t('forms.newEstimation.extra_service_title_with_triang') : (
          t('forms.newEstimation.extra_service_title')
        )}
      </p>
      <Form.Item name="extra_services">
        <Checkbox.Group className={style.extra}>
          {mappedExtraServices?.map((s) => (
            <div key={s.title}>
              <Checkbox
                value={s.title}
                disabled={
                  s.title.toLocaleUpperCase() === CONTRASSEGNO.toLocaleUpperCase()
                  && deliveryType === DeliveryType.RITIRO
                }
              >
                {toUpperCaseFirst(s.title)}
              </Checkbox>
              {deliveryType === DeliveryType.TRIANG && triangServices.includes(s.title.toLocaleUpperCase()) && (
                <div>
                  <Checkbox value={`${s.title}&${DeliveryType.RITIRO}`}>
                    R
                  </Checkbox>
                  <Checkbox value={`${s.title}&${DeliveryType.CONSEGNA}`}>
                    C
                  </Checkbox>
                </div>
              )}
            </div>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </>
  );
};

export default ExtraServices;
