import { put, takeLatest } from 'redux-saga/effects';
import { GetNonStop } from '../../../api/pallex';
import { setLoading, requestGetNonStop, setNonStopSliceData } from '../../redux_slice/pallex/nonStopSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* getNonStopSaga() {
  yield put(setLoading(true));
  try {
    const { data } = yield GetNonStop();
    yield put(setNonStopSliceData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* nonStopSaga() {
  yield takeLatest(requestGetNonStop, getNonStopSaga);
}
