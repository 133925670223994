import { Button, Modal } from 'antd';
import Slider, { Settings } from 'react-slick';
import './modalWithCarousel.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons/lib/icons';

//-------------------
import React, { useState } from 'react';

export const CurrentSlideContext = React.createContext<{
    currentSlide?: number,
    isCurrent?: boolean,
    mySlide?: number
}>({ });
//-------------------

interface ModalWithCarouselInterface {
   visible: boolean,
   initialSlide?: number,
   setVisible: () => void,
   content: JSX.Element[]
}
const CustomRightArrow = ({ onClick }: { onClick?: () => void }) => (
  <Button icon={<RightOutlined width={'26px'}/>} shape="circle"
    className="carousel_button_right"
    onClick={onClick}
  />
);
const CustomLeftArrow = ({ onClick }: { onClick?: () => void }) => (
  <Button icon={<LeftOutlined/>} shape="circle"
    className="carousel_button_left"
    onClick={onClick}
  />
);
const ModalWithCarousel = ({
  visible, setVisible, content,
  initialSlide,
}: ModalWithCarouselInterface) => {
  const [currentSlide, setCurrentSlide] = useState(initialSlide ?? 0);
  const settings: Settings = {
    initialSlide,
    infinite: false,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    nextArrow: <CustomRightArrow/>,
    prevArrow: <CustomLeftArrow/>,
    onInit() {
      setCurrentSlide(initialSlide ?? 0);
    },
    beforeChange: (prev, nextSlide) => {
      setCurrentSlide(nextSlide);
    },
  };
  return (
    <>
      <Modal
        centered
        visible={visible}
        footer={false}
        width={'80%'}
        className="custom_modal"
        onCancel={setVisible}
      >
        <Slider
          {...settings} className={'slider'}>
          {content?.map(
            (e, mySlide) => <CurrentSlideContext.Provider
              key={mySlide}
              value={{ currentSlide, mySlide, isCurrent: currentSlide === mySlide }}
            >
              {e}
            </CurrentSlideContext.Provider>,
          )}
        </Slider>
      </Modal>
    </>
  );
};

export default ModalWithCarousel;
