import { useMemo } from 'react';
import { format } from 'date-fns';
import { Slider } from 'antd';
import cn from 'classnames';
import { ShipmentTrackingStatuses } from '../../store/types';
import style from './tracking.module.css';
import './tracking.css';

type Props = {
  trackingStatus: ShipmentTrackingStatuses | null,
}

const TrackingContent = ({ trackingStatus }: Props) => {
  const marks = useMemo(() => {
    const increaseCount = 100 / ((trackingStatus?.tracking.length ?? 0) - 1);
    let returnObj = {};
    let count = 0;
    trackingStatus?.tracking.forEach((item) => {
      returnObj = {
        ...returnObj,
        [Math.floor(count)]: item.name,
      };
      count += increaseCount;
    });
    return returnObj;
  }, [trackingStatus]);

  const getNewStepPosition = (tracking) => {
    const lastStatusIndex = tracking.map((e) => e.value).findLastIndex((elem) => elem);
    return lastStatusIndex >= 0 ? +Object.keys(marks)[lastStatusIndex] : 0;
  };

  if (!trackingStatus) {
    return null;
  }

  const { logs, withdrawal_date: withdrawalDate } = trackingStatus;

  return (
    <>
      <p className={style.title}>
        Data di consegna prevista: {''}
        <span className={style.bold}>
          { withdrawalDate ? format(new Date(withdrawalDate), 'dd-MM-yyyy') : '--' }
        </span>
      </p>
      <div className={cn(style.progressBar, 'progress_bar_container')}>
        <Slider
          marks={marks}
          defaultValue={getNewStepPosition(trackingStatus?.tracking)}
          handleStyle={{ display: 'none' }}
          disabled
        />
      </div>
      { logs.map((item) => (
        <div key={item.id} className={style.stage}>
          <div className={style.date_time}>
            {format(new Date(item.date), 'dd-MM-yyyy')} | {' '}
            {format(new Date(item.date), 'HH:mm')}
          </div>
          <div>{ item.status }</div>
        </div>
      ))}
    </>
  );
};

export default TrackingContent;
