import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import {
  DashboardsPayloadType, DashboardChartsType, DashboardPalletsResponseType,
  DashboardArchivedResponseType, DashboardsCollectedResponseType, DashboardsCollectedChartType,
  DashboardsWarehouseResponseType, DashboardsWarehouseChartType,
} from '../types';

export const getDashboardArchivedAction = createAction<DashboardsPayloadType>('getDashboardArchivedAction');
export const getDashboardPalletsAction = createAction<DashboardsPayloadType>('getDashboardPalletsAction');
export const getDashboardCollectedAction = createAction<DashboardsPayloadType>('getDashboardCollectedAction');
export const getDashboardWarehouseAction = createAction<DashboardsPayloadType>('getDashboardWarehouseAction');

type State = {
  archivedLoding: boolean,
  archivedData: DashboardChartsType | null,
  palletsLoding: boolean
  palletsData: DashboardChartsType | null,
  collctedLoading: boolean,
  collctedData: DashboardsCollectedChartType | null,
  warehouseLoading: boolean,
  warehouseData: DashboardsWarehouseChartType | null,
}

const initialState: State = {
  archivedLoding: false,
  archivedData: null,
  palletsLoding: false,
  palletsData: null,
  collctedLoading: false,
  collctedData: null,
  warehouseLoading: false,
  warehouseData: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setArchivedLoading: (state, action: PayloadAction<boolean>) => {
      state.archivedLoding = action.payload;
    },
    setPalletsLoading: (state, action: PayloadAction<boolean>) => {
      state.palletsLoding = action.payload;
    },
    setCollctedLoading: (state, action: PayloadAction<boolean>) => {
      state.collctedLoading = action.payload;
    },
    setWarehouseLoading: (state, action: PayloadAction<boolean>) => {
      state.warehouseLoading = action.payload;
    },
    setArchivedData: (state, action: PayloadAction<DashboardArchivedResponseType>) => {
      const parsedArr = Object.keys(action.payload).map((item) => ({
        name: item[0].toUpperCase() + item.slice(1),
        qty: action.payload[item],
      }));
      state.archivedData = {
        total: action.payload.inviati + action.payload.archiviati,
        data: parsedArr,
      };
    },
    setPalletsData: (state, action: PayloadAction<DashboardPalletsResponseType>) => {
      const parsedArr = Object.keys(action.payload.pallets).map((item) => ({
        name: item,
        qty: action.payload.pallets[item],
      }));
      state.palletsData = {
        ...action.payload,
        data: parsedArr,
      };
    },
    setCollectedData: (state, action: PayloadAction<DashboardsCollectedResponseType>) => {
      const parsedArr = Object.keys(action.payload).map((item) => ({
        name: item[0].toUpperCase() + item.slice(1),
        deliveries: action.payload[item].count,
        delays: action.payload[item].delays,
      }));
      const total = parsedArr.reduce((prev, cur) => prev + cur.delays, 0);
      state.collctedData = {
        total,
        data: parsedArr,
      };
    },
    setWarehouseData: (state, action: PayloadAction<DashboardsWarehouseResponseType[]>) => {
      const parsedArr = action.payload.map((item) => {
        const warehouseTime = new Date(item.warehouse).getTime();
        const startDeliveryTime = new Date(item.startDelivery).getTime();
        let delay:string | null;
        const { days, hours } = item.delay;
        if (days && hours) {
          delay = `${days} giorni, ${hours} ora`;
        } else if (days && !hours) {
          delay = `${days} giorni`;
        } else if (!days && hours) {
          delay = `${hours} ora`;
        } else {
          delay = null;
        }

        return {
          delay,
          id: item.id,
          warehouse: warehouseTime,
          startDelivery: startDeliveryTime,
        };
      });
      state.warehouseData = {
        total: action.payload.length,
        data: parsedArr,
      };
    },
  },
});

export const {
  setArchivedLoading, setArchivedData, setPalletsLoading, setPalletsData,
  setCollctedLoading, setCollectedData, setWarehouseLoading, setWarehouseData,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
