import { useTranslation } from 'react-i18next';
import Banner from '../../components/Banner/Banner';
import NewPassForm from './NewPassForm';

const RestorePassword = () => {
  const { t } = useTranslation();
  return (
    <>
      <Banner title={t('banner.restorePassword')}></Banner>
      <NewPassForm/>
    </>
  );
};

export default RestorePassword;
