import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import { useAppSelector } from '../../store/store';
import style from './dashboard.module.css';

const CustomTooltip = (props) => {
  const { t } = useTranslation();
  const { active, payload } = props;
  if (active) {
    return (
      <div className={style.custom_tooltip}>
        <p className={style.tooltip_title}>Id: {props.payload[0].payload.id}</p>
        {props.payload[0].payload.delay && (
          <p className={style.tooltip_title}>{t('dashboard.time_in_stock')}: {props.payload[0].payload.delay}</p>
        )}
        {payload.map((item) => (
          <p
            key={item.dataKey}
            style={{ color: item.color }}
            className={style.tooltip_text}
          >
            {item.name}: {' '} {format(new Date(item.value), 'dd-MM-yyy, HH:mm')}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const ChartTimeOrderRemainsInTheWarehouse = () => {
  const { t } = useTranslation();
  const { warehouseLoading, warehouseData } = useAppSelector((state) => state.dashboards);

  if (warehouseLoading) return <Loader/>;

  if (!warehouseData?.data.length) return <NoData/>;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={warehouseData?.data}
        margin={{
          right: 35,
          left: 35,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="id" />
        <YAxis
          orientation="right"
          domain={['auto', 'auto']}
          yAxisId="startDelivery"
          tickFormatter={(unixTime) => format(new Date(unixTime), 'dd-MM-yyyy')}
        />
        <YAxis
          domain={['auto', 'auto']}
          yAxisId="warehouse"
          tickFormatter={(unixTime) => format(new Date(unixTime), 'dd-MM-yyyy')}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          yAxisId="warehouse"
          type="monotone"
          dataKey="warehouse"
          name={t('dashboard.in_stock')}
          stroke="#8884d8"
        />
        <Line
          yAxisId="startDelivery"
          type="monotone"
          dataKey="startDelivery"
          name={t('dashboard.delivering')}
          stroke="#82ca9d"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartTimeOrderRemainsInTheWarehouse;
