import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import CustomInput from '../components/TableInputs/CustomInput';
import { inputValuesTypes } from './index';
import { FinanceSectionType } from 'store/types';
import styles from './invoiceTable.module.css';

type InvoicesColumnsInterface = {
  isApproved: boolean,
  orderId: {
    id: string,
    annullata: boolean
  },
  pallexId: string | null,
  userName: string | null,
  approvato_il: string,
  data_ritiro: string,
  type: string,
  shipping_address: string,
  pickup_address: string,
  n_ordine: { shipment: FinanceSectionType, value: number | null },
  codice_cliente: { shipment: FinanceSectionType, value: number | null },
  numero_ddt: { shipment: FinanceSectionType, value: number | null },
  fluet: { shipment: FinanceSectionType, value: number | null },
  extra: {
    shipment: FinanceSectionType,
    value: number | null,
    type: string,
  }
  scontistica: { shipment: FinanceSectionType, value: number | null},
  price: number | string,
  approveOrDisapprove: JSX.Element,
  orderDetails: JSX.Element,
}

export const makeColumnsInvoices = (t:TFunction, onChange: (arg: inputValuesTypes) => void)
    : ColumnsType<InvoicesColumnsInterface> => [
  {
    title: t('invoices.table.orderId'),
    dataIndex: 'orderId',
    key: 'orderId',
    render: (value) => (
      <div className={styles.id_container}>
        <span>{value.id}</span>
        { value.annullata && (
          <span className={styles.annullata}>{t('shipmentList.canceled')}</span>
        )}
      </div>
    ),
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.pallexId'),
    dataIndex: 'pallexId',
    key: 'pallexId',
    className: styles.cell_styles,
  },
  {
    title: t('forms.deliveries.client_name'),
    dataIndex: 'userName',
    key: 'userName',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.approvato_il'),
    dataIndex: 'approvato_il',
    key: 'approvato_il',
    width: '8%',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.data_ritiro'),
    dataIndex: 'data_ritiro',
    key: 'data_ritiro',
    width: '8%',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.type'),
    dataIndex: 'type',
    key: 'type',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.pickup_address'),
    dataIndex: 'pickup_address',
    key: 'pickup_address',
    width: '10%',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.shipping_address'),
    dataIndex: 'shipping_address',
    key: 'shipping_address',
    width: '10%',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.n_ordine'),
    dataIndex: 'n_ordine',
    key: 'n_ordine',
    render: (value) => <CustomInput defaultValue={value} name="n_ordine" onChange={onChange}/>,
    width: '7%',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.codice_cliente'),
    dataIndex: 'codice_cliente',
    key: 'codice_cliente',
    render: (value) => <CustomInput defaultValue={value} name="codice_cliente" onChange={onChange}/>,
    width: '7%',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.numero_ddt'),
    dataIndex: 'numero_ddt',
    key: 'numero_ddt',
    render: (value) => <CustomInput defaultValue={value} name="numero_ddt" onChange={onChange}/>,
    width: '7%',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.fluet'),
    dataIndex: 'fluet',
    key: 'fluet',
    render: (value) => <CustomInput
      defaultValue={value}
      name="fluet"
      onChange={onChange}
      suffix
    />,
    width: '7%',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.extra'),
    dataIndex: 'extra',
    key: 'extra',
    render: (extra) => <CustomInput
      defaultValue={extra}
      name="extra"
      onChange={onChange}
      addonAfter
    />,
    width: '7%',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.scontistica'),
    dataIndex: 'scontistica',
    key: 'scontistica',
    render: (value) => <CustomInput
      defaultValue={value}
      name="scontistica"
      onChange={onChange}
      suffix
    />,
    width: '7%',
    className: styles.cell_styles,
  },
  {
    title: t('invoices.table.total_price'),
    dataIndex: 'price',
    key: 'price',
    className: styles.cell_styles,
  },
  {
    title: '',
    dataIndex: 'approveOrDisapprove',
    key: 'approveOrDisapprove',
    className: styles.cell_styles,
  },
  {
    title: '',
    dataIndex: 'orderDetails',
    key: 'orderDetails',
    className: styles.cell_styles,
  },
  {
    title: '',
    dataIndex: 'deleteFinance',
    key: 'deleteFinance',
    width: '3%',
    className: styles.cell_styles,
  },
];
