import { put, takeLatest, select } from 'redux-saga/effects';
import { PatchEstimation, ShippingRequestCreatePayload, ShippingRequestRefusePayload } from '../types';
import {
  UpdateShippingRequest, GetRequestEstimate, CreateRequestEstimate,
} from '../../api';
import {
  GetShippingRequest, RefusedShippingRequest, GetShippingRequestSendEmail,
  GetEstimationSendEmail, CreateShippingRequest,
} from '../../api/shipping';
import {
  requestShippingCreate, setShipmentData, setLoading, setConfirmationEmail,
  requestShippingGetShippingRequest, requestShippingUpdateShippingRequest,
  setRefused, requestRefusedShippingRequest, setShippingCost,
} from '../redux_slice/shipmentSlice';
import { requestGetPaymentLink } from '../redux_slice/paymentLinkSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';
import { ShippingEstimate } from 'store/types';
import { TPStatuses, RoleEnums } from 'enums';

function* createShippingRequestSaga(action: {
  payload: ShippingRequestCreatePayload
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield CreateShippingRequest(action.payload.data);
    if (action.payload.shipment_email) {
      GetShippingRequestSendEmail(data.id);
    }
    if (action.payload.estimation_email) {
      GetEstimationSendEmail(data.id);
    }
    yield put(setShipmentData(data));
    yield put(setConfirmationEmail(action.payload.data.email));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getShippingRequestSaga(action: {
  payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetShippingRequest(action.payload);
    const { data: estimationData } = yield GetRequestEstimate(data.id);
    yield put(setShipmentData(data));
    yield put(setShippingCost(estimationData as ShippingEstimate));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* updateShippingRequestSaga(action: {
  payload: PatchEstimation
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield UpdateShippingRequest(action.payload);
    if (action.payload.recalculate) {
      const { data: estimationData } = yield CreateRequestEstimate(action.payload.id);
      yield put(setShippingCost(estimationData as ShippingEstimate));
    } else {
      if (!action.payload.redirectToPayment) {
        yield GetShippingRequestSendEmail(action.payload.id);
      }
      yield put(setShipmentData(data));
      if (action.payload.redirectToPayment) {
        yield put(requestGetPaymentLink(action.payload.id));
      }
    }
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* refusedShippingRequestSaga(action: {
  payload: ShippingRequestRefusePayload
}) {
  yield put(setLoading(true));
  try {
    const { prevenivi, da_approvare: daApprovare, ...rest } = action.payload;
    const store = yield select();
    yield RefusedShippingRequest({
      id: rest.id,
      reason_annulation: rest.reason_annulation,
      is_by_operator: !!(store.auth.tokenStructure && store.auth.tokenStructure.role !== RoleEnums.USER),
    });
    if (daApprovare) {
      yield UpdateShippingRequest({
        id: action.payload.id,
        data: {
          req_status: TPStatuses.Rifiutato,
        },
      });
    }
    if (prevenivi) {
      yield UpdateShippingRequest({
        id: action.payload.id,
        data: {
          isArchived: true,
        },
      });
    }
    yield put(setRefused(true));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* shippingRequestSaga() {
  yield takeLatest(requestShippingCreate, createShippingRequestSaga);
  yield takeLatest(requestShippingGetShippingRequest, getShippingRequestSaga);
  yield takeLatest(requestShippingUpdateShippingRequest, updateShippingRequestSaga);
  yield takeLatest(requestRefusedShippingRequest, refusedShippingRequestSaga);
}
