import { useEffect } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import style from './tracking.module.css';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestShippingTrackingStatueAction } from '../../store/redux_slice/shipmentTrackingStatusSlice';

const Tracking = ({ setSecondStep }: {
    setSecondStep: (value: boolean) => void,
  }) => {
  const { t } = useTranslation();
  const { trackingStatus } = useAppSelector((state) => state.shipmentTrackingStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (trackingStatus) {
      setSecondStep(true);
    }
  }, [trackingStatus, setSecondStep]);

  const onFinish = (values) => {
    dispatch(requestShippingTrackingStatueAction(values.tracking_status));
  };

  return (
    <div className={classnames('customForm', style.tracking)}>
      <Form onFinish={onFinish}>
        <p className={style.label}>{t('forms.tracking.enterTrackingNumber')}</p>
        <Form.Item
          name="tracking_status"
          rules={
            [{ required: true, message: t('forms.tracking.required') }]
          }
        >
          <Input className={style.input} />
        </Form.Item>
        <CustomButton
          htmlType="submit"
          className={style.button}
          type="primary"
        >{t('forms.tracking.send')}</CustomButton>
      </Form>
    </div>
  );
};

export default Tracking;
