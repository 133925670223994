import { put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  clearSession, requestLogin, requestLogOut, requestRecoveryPassword,
  requestUpdateUserPassword, sentDefaultPass, setSession,
} from '../redux_slice/authSlice';
import { AddLogPayload, LoginPayload } from '../types';
import {
  RecoveryPassword, SendDefaultPass, SignIn, UpdatePassword,
} from '../../api';
import { setRefreshToken, setToken } from '../localStorage';
import { parseToken } from '../../functions';
import { setMessage } from '../redux_slice/messageErrorSlice';
import { setCheckNotificationsAfterLogin } from '../redux_slice/notificationsSlice';
import { PostMonitoringLog } from '../../api/monitoring';

function* loginSaga(action: {
 payload: LoginPayload
}) {
  try {
    const { data } = yield SignIn(action.payload);
    const accessToken = data?.accessToken;
    setToken(data.accessToken);
    setRefreshToken(data.refreshToken);
    yield put(setSession({ accessToken, tokenStructure: parseToken(accessToken) }));
    yield put(setCheckNotificationsAfterLogin(true));
  } catch (e) {
    yield put(setMessage({ description: i18n.t('messages.login_error') }));
  }
}

function* sendPassSaga(action: {
  payload: string
}) {
  try {
    yield SendDefaultPass(action.payload);
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* logOutSaga(action: {
  payload: AddLogPayload
}) {
  if (action.payload.role === 'operator' || action.payload.role === 'admin') {
    yield PostMonitoringLog(action.payload);
  }
  yield put(clearSession());
  localStorage.clear();
}

function* recoveryPassSaga(action: {
  payload: string
}) {
  try {
    yield RecoveryPassword(action.payload);
    yield put(setMessage({ description: i18n.t('messages.recovery_password'), title: i18n.t('messages.recovery_password_title') }));
  } catch (e) {
    yield put(setMessage({ description: i18n.t('messages.login_error') }));
  }
}

function* updatePassSaga(action: {
  payload:
      { query: string, password: string }
}) {
  try {
    yield UpdatePassword(action.payload.password, action.payload.query);
    yield put(setMessage({ description: i18n.t('messages.change_password') }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}
export function* authSaga() {
  yield takeLatest(requestLogin, loginSaga);
  yield takeLatest(requestLogOut, logOutSaga);
  yield takeLatest(sentDefaultPass, sendPassSaga);
  yield takeLatest(requestRecoveryPassword, recoveryPassSaga);
  yield takeLatest(requestUpdateUserPassword, updatePassSaga);
}
