import {
  useEffect, useMemo, useState, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './Card';
import { requestGetTripPdfAction } from '../../../store/redux_slice/tripsSlice';
import { useAppDispatch } from '../../../store/store';
import { TrucksStructure, ShipmentStructure, TrucksFilterType } from '../../../store/types';
import styles from './cardContainer.module.css';

type Props = {
  title?: string,
  truck?: TrucksStructure,
  moveCardToColumn:
    (shippingRequest: ShipmentStructure, tripId: number | null, truckId: number, oldTruckId: number | null) => void,
  dateStart?: string,
  trucksFilter?: TrucksFilterType | null,
  children: React.ReactNode,
};

type DropType = {
  shippingRequest: ShipmentStructure,
  tripId: number | null,
  oldTruckId: number | null,
}

const CardContainer = ({
  title,
  truck,
  moveCardToColumn,
  dateStart,
  trucksFilter,
  children,
}: Props) => {
  const { t } = useTranslation();
  const [isOptions, setIsOptions] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const palletsNumber = useMemo(() => (
    trucksFilter?.truckInfo.find((item) => item.id === truck?.id)?.palletQty
  ), [truck?.id, trucksFilter?.truckInfo]);

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      drop({
        shippingRequest, tripId, oldTruckId,
      }: DropType) {
        if (truck?.id && oldTruckId !== truck?.id) {
          moveCardToColumn(shippingRequest, tripId, truck?.id, oldTruckId);
        }
      },
    }),
    [moveCardToColumn],
  );

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOptions && ref.current && !ref.current.contains(e.target)) {
        setIsOptions(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOptions]);

  const downloadTruckInfoHandler = () => {
    if (dateStart) {
      dispatch(requestGetTripPdfAction({
        truck_id: truck?.id,
        truck_name: truck?.plate_number,
        date_start: dateStart,
      }));
    }
  };

  return (
    <div className={styles.card_container}>
      <div className={cn(styles.header, {
        [styles.padding_left]: truck?.plate_number,
      })}>
        { truck?.plate_number && (
          <div className={styles.options_container} ref={ref}>
            <button
              className={styles.options_btn}
              onClick={() => setIsOptions(!isOptions)}
            >
              <span className={styles.dots}/>
            </button>
            {isOptions && <div className={styles.options}>
              <p
                className={styles.option_item}
                onClick={downloadTruckInfoHandler}
              >{t('manifest.downloadTruckPdf')}</p>
            </div>}
          </div>
        )}
        <h3 className={styles.title}>
          { title || truck?.plate_number || '-' }
          { truck?.plate_number && <span className={styles.name}>{ truck?.driver?.name || '-' }</span> }
        </h3>
        { truck?.plate_number && <div className={styles.number_of}>
          { palletsNumber }/{ truck?.pallet_capacity }
        </div>}
      </div>
      <div ref={drop} className={styles.body}>
        {children}
      </div>
    </div>
  );
};

export default CardContainer;
