import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import classnames from 'classnames';

import React from 'react';
import { format } from 'date-fns';
import ShipmentDetailsTable from './ShipmentDetailsTable';
import CustomButton from '../../components/CustomButton/CustomButton';
import ExtraServicesListContainer from './ExtraServicesListContainer';

import style from './shipmentDetails.module.css';
import { ExtraServiceStructure, ShipmentStructure } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestRefusedShippingRequest, setCleanData } from '../../store/redux_slice/shipmentSlice';

const ShipmentDetailsContainer:React.FC<{
    twoStep: boolean,
    setTwoStep: ((value: boolean) => void),
    shipmentRequest: ShipmentStructure,
    setNewOptions: (values: ExtraServiceStructure[]) => void,
}> = ({
  twoStep, setTwoStep, shipmentRequest, setNewOptions,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const shippingCost = useAppSelector((state) => state.shipmentRequest?.shippingCost);

  const refusedShipmentRequest = () => {
    dispatch(requestRefusedShippingRequest({ id: shipmentRequest?.id, prevenivi: true }));
    dispatch(setCleanData());
  };
  return (
    <div className={style.first_step_container}>
      <div className={style.price_container}>
        <p className={style.price}>
          {
            shippingCost?.price
              ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(shippingCost?.price)
              : '--€'
          }
        </p>
        <p className={style.price_iva}>+ {t('forms.shipmentDetails.VAT')}</p>
      </div>
      <div className={style.price_container_triangle}/>
      {shipmentRequest && <ExtraServicesListContainer
        shipmentRequest={shipmentRequest}
        setNewOptions={setNewOptions}
      />}
      <div className={classnames(style.title, style.left)}>
        {t('forms.shipmentDetails.title')}
      </div>
      <div className={style.params_container}>
        <Row>
          <Col span={8}>
            <p className={style.params_title}>{t('forms.shipmentDetails.pickupAddress')}: {' '}
              <span className={style.params_detail}>
                {`${shipmentRequest?.pickup_address?.location?.street}, ${shipmentRequest?.pickup_address?.location?.house_number}`}
              </span>
              <span className={style.params_detail}>
                {shipmentRequest?.pickup_address?.location?.post_code}{' '}
                {shipmentRequest?.pickup_address?.location?.city},{' '}
                {shipmentRequest?.pickup_address?.location?.province}
              </span>
            </p>
          </Col>
          <Col span={7} offset={1}>
            <p className={style.params_title}>{t('forms.shipmentDetails.shippingAddress')}: {' '}
              <span className={style.params_detail}>
                {`${shipmentRequest?.shipping_address?.location?.street}, ${shipmentRequest?.shipping_address?.location?.house_number}`}
              </span>
              <span className={style.params_detail}>
                {shipmentRequest?.shipping_address?.location?.post_code},{' '}
                {shipmentRequest?.shipping_address?.location?.city},{' '}
                {shipmentRequest?.shipping_address?.location?.province}
              </span>
            </p>
          </Col>
        </Row>
        <Row className={style.date_top}>
          <Col span={8}>
            <p className={style.params_title}>{t('forms.shipmentDetails.retirementDate')}: {' '}
              <span className={style.params_detail}>
                {
                  shipmentRequest?.start_shipment_date
                    ? format(new Date(shipmentRequest?.start_shipment_date), 'dd-MM-yyyy')
                    : ''
                }
              </span>
            </p>
          </Col>
          <Col span={12}>
            <p className={classnames(style.params_title, style.params_margin)}>{t('forms.shipmentDetails.delivery')}: {' '}
              <span className={style.params_detail}>
                {
                  shipmentRequest?.withdrawal_date
                    ? format(new Date(shipmentRequest?.withdrawal_date), 'dd-MM-yyyy')
                    : ''
                }
              </span>
            </p>
          </Col>
        </Row>
      </div>
      <ShipmentDetailsTable
        shipmentRequest={shipmentRequest}
      />
      {
        twoStep
          ? null
          : <>
            <Row>
              <CustomButton
                htmlType="submit"
                type="primary"
                icon={<span/>}
                className={classnames('extra-bolt-corsivo', style.send, style.left)}
                onClick={() => setTwoStep(true)}
              >
                {t('forms.shipmentDetails.confirmEstimation')}
              </CustomButton>
              <CustomButton
                type="link"
                className={classnames(style.extra_list_price, 'extra-bolt-corsivo', style.send)}
                onClick={refusedShipmentRequest}
              >
                {t('forms.shipmentDetails.cancel_quote')}
              </CustomButton>
            </Row>
            <div className={style.text_first_step}>
                * {t('forms.shipmentDetails.IVA_text')}
            </div>
          </>
      }
    </div>
  );
};

export default ShipmentDetailsContainer;
