import {
  Form, Input, InputNumber, Select,
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryType } from 'enums';
import { triangServices } from 'pesantiConstants';
import style from './ExtraServicesTable.module.css';

const { Option } = Select;

const PriceForm: React.FC = () => {
  const { t } = useTranslation();
  return (
    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Form.Item noStyle
        name="priceValue"
        rules={[
          {
            required: true,
            message: '',
          },
          () => ({
            validator(_, v) {
              const n = Number.parseFloat(v?.toString().replace(',', '.'));
              if ((!Number.isNaN(n) && n >= 0) || v === '-') {
                return Promise.resolve();
              }
              return Promise.reject();
            },
            message: t('forms.estimations.extra_service_error'),
          }),
        ]}
      >
        <InputNumber step={0.1} />
      </Form.Item>
      <Form.Item name="priceUnit" noStyle>
        <Select
          className={style.margin_left}
        >
          <Option key="€" value="€">€</Option>
          <Option key="%" value="%">%</Option>
        </Select>
      </Form.Item>
    </span>
  );
};

export const ExtraServicesEditableCell = (
  {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  },
) => {
  const { t } = useTranslation();
  let inputNode;
  switch (inputType) {
  case 'requested_to':
    inputNode = (
      <Form.Item
        shouldUpdate={
          (prev, curr) => prev.service_name !== curr.service_name
        }
        noStyle
      >
        {({ getFieldValue }) => (
          <Form.Item name={dataIndex} noStyle>
            <Select style={{ width: 150 }} disabled={!triangServices.includes(getFieldValue('service_name')?.toLocaleUpperCase())}>
              <Option key={DeliveryType.RITIRO} value={DeliveryType.RITIRO}>Ritiro</Option>
              <Option key={DeliveryType.CONSEGNA} value={DeliveryType.CONSEGNA}>Consegna</Option>
              <Option key={DeliveryType.BOTH} value={DeliveryType.BOTH}>Ritiro/Consegna</Option>
            </Select>
          </Form.Item>
        )}
      </Form.Item>
    );
    break;
  case 'fixedMinPrice':
    inputNode = (
      <Form.Item
        shouldUpdate={
          (prev, curr) => prev.priceUnit !== curr.priceUnit
        }
        noStyle
      >
        {({ getFieldValue }) => (
          <Form.Item name={dataIndex} noStyle>
            <InputNumber
              style={{ width: 150 }}
              addonAfter={<span>€</span>}
              disabled={getFieldValue('priceUnit') === '€'}
            />
          </Form.Item>
        )}
      </Form.Item>
    );
    break;
  case 'price':
    inputNode = <PriceForm/>;
    break;
  case 'select_category':
    inputNode = <Form.Item name={dataIndex} noStyle>
      <Select>
        <Option key="TOTAL" value="TOTAL">{t('pricelist.total')}</Option>
        <Option key="PALLET" value="PALLET">{t('pricelist.pallet')}</Option>
        <Option key="ESTERNAMENTE" value="ESTERNAMENTE">{t('pricelist.esternamente')}</Option>
      </Select>
    </Form.Item>;
    break;
  case 'number':
    inputNode = <Form.Item noStyle name={dataIndex}><InputNumber/></Form.Item>;
    break;
  default:
    inputNode = <Form.Item noStyle
      className={style.withOutError}
      name={dataIndex}
      rules={[
        () => ({
          validator(_, v) {
            if (v.trim()) {
              return Promise.resolve();
            }
            return Promise.reject();
          },
        }),
      ]}><Input/></Form.Item>;
    break;
  }
  return (
    <td {...restProps} className={editing ? style.table_form_style : ''}>
      {editing ? (
        inputNode
      ) : (
        children
      )}
    </td>
  );
};
