import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form, Row, Col, Input, DatePicker, Select, Radio, TimePicker,
} from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import CustomButton from 'components/CustomButton/CustomButton';
import ExtraServices from 'components/ExtraServices';
import { MerceAdrTable } from 'components/EditableTable/Tables';
import {
  AddressFields, ContractorTypes, DeliveryType, TPStatuses, PaymentMethodEnum,
} from 'enums';
import {
  Estimation, ExtraServiceStructure, MerceAdrType,
} from 'store/types';
import { renderShowInManifesto, selectContractorType, triangolizeConditionHandler } from 'functions';
import { ContractorsSelect } from 'components/ContractorsSelect/ContractorsSelect';
import { HubSelect } from 'components/ContractorsSelect/HubSelect';
import { requestGetUserPriceList } from 'store/redux_slice/userPriceListSlice';
import GooglePlacesComponent from 'components/GooglePlacesComponent';
import RadioButton from 'components/RadioButton';
import { useFilterContractors } from 'hooks/useFilterContractors';
import ShipmentFooterContainer from '../ShipmentFooterContainer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { requestGetUser } from 'store/redux_slice/users/userSlice';
import { requestEstimation, clearEstimation } from 'store/redux_slice/estimationSlice';
import { requestSearchUsers } from 'store/redux_slice/users/usersSearchSlice';
import { requestShippingCreate } from 'store/redux_slice/shipmentSlice';
import { requestGetShippingServicesByPriceList } from 'store/redux_slice/shippingServicesSlice';
import { requestGetPriceListTemplates } from 'store/redux_slice/priceListTemplatesSlice';
import { requestGetContractors } from 'store/redux_slice/contractors/contractorsSlice';
import { requestGetPallexHubs } from 'store/redux_slice/pallexHubsSlice';
import { requestGetFacchinaggio } from 'store/redux_slice/pallex/facchinaggioSlice';
import {
  NON_STOP, ADR, CONTRASSEGNO, FACCHINAGGIO, TELEFONICA,
  CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA, GDO, AMAZON,
  SPONDA_IDRAULICA,
} from 'pesantiConstants';
import style from './newShipmentDeliveries.module.css';

const { Option } = Select;

type NewShipmentFormType = {
  isLoaderContainer: boolean,
  setIsLoaderContainer: (val: boolean) => void,
  onCancel: () => void,
  form: FormInstance
}

const NewShipmentForm = ({
  isLoaderContainer, setIsLoaderContainer, onCancel, form,
} : NewShipmentFormType) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { facchinaggioList } = useAppSelector((state) => state.facchinaggioList);
  const orderData = useAppSelector((state) => state.estimation.estimation);
  const usersSearch = useAppSelector((state) => state.usersSearch.users);
  const loadingPriceListTemplates = useAppSelector((state) => state.priceListTemplates.loading);
  const priceListTemplates = useAppSelector((state) => state.priceListTemplates?.priceListTemplates);
  const priceLists = priceListTemplates.filter((el) => el.isSpecial);
  const userPriceList = useAppSelector((state) => state.userPriceList.userPriceList);
  const extraServices = useAppSelector((state) => state.extraServices?.shippingServices);
  const contractors = useFilterContractors();
  const [contractor, setContractor] = useState<number | null>(null);
  const [merceAdrState, setMerceAdrState] = useState<MerceAdrType[] | null>(null);
  const allContractors = useAppSelector((state) => state.contractors?.contractors);
  const findPallexItalia = allContractors?.find((el) => el.name === ContractorTypes.Pallex);
  const filterFornitoreContractor = allContractors?.filter((el) => el.is_editable);
  const deliveryTypeWatch:DeliveryType = Form.useWatch('delivery_type', form);
  const optionsWatch = Form.useWatch('extra_services', form);
  const contractorTypeWatch = Form.useWatch('contractor_type', form);
  const startShipmentTimeWatch = Form.useWatch('start_shipment_time', form);

  const hasNonStop = useMemo(() => (
    optionsWatch?.some((title) => title.toUpperCase() === NON_STOP.toUpperCase())
  ), [optionsWatch]);

  const hasMerceAdr = useMemo(() => (
    optionsWatch?.some((title) => title.toUpperCase() === ADR.toUpperCase())
  ), [optionsWatch]);

  const filteredOptions: ExtraServiceStructure[] = useMemo(() => (
    extraServices ? extraServices.map((service) => {
      const isContrassegno = optionsWatch?.includes(CONTRASSEGNO)
        && deliveryTypeWatch !== DeliveryType.RITIRO;
      const isActive = optionsWatch?.includes(service.title);
      const isRitiroTriang = optionsWatch?.includes(`${service.title}&${DeliveryType.RITIRO}`);
      const isConsegnaTriang = optionsWatch?.includes(`${service.title}&${DeliveryType.CONSEGNA}`);
      return {
        ...service,
        triangolize: triangolizeConditionHandler(isRitiroTriang, isConsegnaTriang) || deliveryTypeWatch,
        isActive: service.title === CONTRASSEGNO ? isContrassegno : isActive,
      };
    }) : []
  ), [deliveryTypeWatch, extraServices, optionsWatch]);

  const serviceTelefonica = useMemo(() => (
    filteredOptions?.find((item) => item.title.toUpperCase() === TELEFONICA.toUpperCase() && item.isActive)
  ), [filteredOptions]);

  const serviceFacchinaggio = useMemo(() => (
    filteredOptions?.find((item) => item.title.toUpperCase() === FACCHINAGGIO.toUpperCase() && item.isActive)
  ), [filteredOptions]);

  useEffect(() => {
    if (optionsWatch) {
      const reducedOptionsWatch = optionsWatch.reduce((result, item) => {
        if (item.includes('&')) {
          const substr = item.split('&')[0];
          if (!result.includes(substr)) {
            result.push(substr);
          }
        }
        if (!result.includes(item)) {
          result.push(item);
        }
        return result;
      }, []);
      form.setFieldsValue({ extra_services: reducedOptionsWatch });
    }
  }, [optionsWatch, form]);

  useEffect(() => {
    dispatch(requestGetPriceListTemplates());
    dispatch(requestGetContractors());
    dispatch(requestGetPallexHubs());
    dispatch(requestSearchUsers(''));
    dispatch(requestGetFacchinaggio());
  }, [dispatch]);

  useEffect(() => {
    if (userPriceList && userPriceList.price_list) {
      form.setFieldsValue({ price_list: userPriceList.price_list.id });
      dispatch(requestGetShippingServicesByPriceList(+userPriceList.price_list.id));
    }
  }, [dispatch, form, userPriceList]);

  useEffect(() => {
    if (userPriceList && userPriceList.id) {
      dispatch(requestGetUser(userPriceList.id));
    }
  }, [dispatch, userPriceList]);

  useEffect(() => () => {
    dispatch(clearEstimation());
  }, [dispatch]);

  const onFinishForm = () => {
    const values = form.getFieldsValue();
    const selectedContractor = values.contractor_type === 'Fornitore' ? values.contractor_type_select || contractor : values.contractor_type;
    const selectedTypes = selectContractorType(selectedContractor, values?.delivery_type);
    const findFornitoreContractor = filterFornitoreContractor.find((el) => (
      Number(el.id) === Number(selectedContractor)
    ));

    const mappedServices = filteredOptions.map((item) => {
      switch (item.title.toUpperCase()) {
      case TELEFONICA.toUpperCase():
        return {
          ...item,
          values: { ritiro: values.telefonica_ritiro || null, consegna: values.telefonica_consegna || null },
        };
      case FACCHINAGGIO.toUpperCase():
        return {
          ...item,
          values: { ritiro: values.facchinaggio_ritiro || null, consegna: values.facchinaggio_consegna || null },
        };
      case CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA.toUpperCase():
      case GDO.toUpperCase():
      case AMAZON.toUpperCase():
      case SPONDA_IDRAULICA.toUpperCase():
        return {
          ...item,
          values: {
            ritiro: values?.extra_services?.includes(`${item.title}&${DeliveryType.RITIRO}`) || null,
            consegna: values?.extra_services?.includes(`${item.title}&${DeliveryType.CONSEGNA}`) || null,
          },
        };
      default:
        return item;
      }
    });

    const data: Estimation = {
      price_list_id: values.price_list,
      pickup_address: {
        location: {
          ...values?.pickup_address?.location,
          post_code: values.pickup_address_post_code,
        },
        typology: 'Company',
        company_name: values.pickup_address_name,
        gdo_id: values.pickup_address?.gdo_id || undefined,
        gdo_name: values.pickup_address?.gdo_name || undefined,
      },
      shipping_address: {
        location: {
          ...values?.shipping_address?.location,
          post_code: values.shipping_address_post_code,
        },
        typology: 'Company',
        company_name: values.shipping_address_name,
        gdo_id: values.shipping_address?.gdo_id || undefined,
        gdo_name: values.shipping_address?.gdo_name || undefined,
      },
      email: values.email,
      phone: values.phone,
      contractor: {
        contractor_id: Number(selectedContractor),
        type: selectedTypes[0],
        show: renderShowInManifesto(
          values.delivery_type,
          findFornitoreContractor?.id,
          Number(selectedContractor) === Number(findPallexItalia?.id),
        ),
      },
      sub_contractor: values.delivery_type === DeliveryType.TRIANG
        ? {
          contractor_id: Number(selectedContractor),
          type: selectedTypes[1],
          show: false,
        }
        : undefined,
      delivery_type: values.delivery_type,
      ddt_number: values.ddt_number,
      idHub: values.idHub,
      start_shipment_date: values.start_shipment_date ? format(new Date(values.start_shipment_date), 'yyyy-MM-dd') : null,
      start_shipment_time: values.start_shipment_time ? format(new Date(values.start_shipment_time), 'HH:mm') : null,
      payment_method: PaymentMethodEnum.BANK_TRANSFER,
      options: mappedServices,
      merce_adr: merceAdrState,
      pallets: orderData?.pallets || [],
      note_ritiro: (values.delivery_type === DeliveryType.RITIRO && values.note_ritiro && !values.note_ritiro.includes(t('forms.shipmentRequest.start_shipment_time_note')))
        ? `${t('forms.shipmentRequest.start_shipment_time_note')}: ${values.start_shipment_time ? format(new Date(values.start_shipment_time), 'HH:mm') : ''}, ${values.note_ritiro}`
        : values.note_ritiro,
      note_consegna: values.note_consegna,
      non_stop_date_ritiro: values.non_stop_date_ritiro ? format(new Date(values.non_stop_date_ritiro), 'yyyy-MM-dd') : null,
      non_stop_date_consegna: values.non_stop_date_consegna ? format(new Date(values.non_stop_date_consegna), 'yyyy-MM-dd') : null,
      telefonica: values.telefonica || null,
      withdrawal_date: values.withdrawal_date ? format(new Date(values.withdrawal_date), 'yyyy-MM-dd') : null,
      informazioni_aggiuntive: values.informazioni_aggiuntive || null,
      codice_cliente_spedizione: values.codice_cliente_spedizione || null,
    };

    if (isLoaderContainer && !!data.pallets.length) {
      dispatch(requestShippingCreate({
        shipment_email: true,
        data: {
          status: TPStatuses.In_attesa_di_approvazione,
          ...data,
        },
      }));
    } else if (!isLoaderContainer) {
      setIsLoaderContainer(true);
      dispatch(requestEstimation(data));
    }
  };

  const fieldsChangedHandler = _.debounce((changedFields) => {
    if (changedFields?.price_list) {
      dispatch(requestGetShippingServicesByPriceList(+changedFields.price_list));
    }

    if (isLoaderContainer && orderData) {
      setIsLoaderContainer(false);
      dispatch(requestEstimation({
        ...orderData,
        pallets: [],
      }));
    }
  }, 500);

  const onDeliveryTypeChange = () => {
    if (startShipmentTimeWatch) {
      form.setFieldsValue({
        note_ritiro: `${t('forms.shipmentRequest.start_shipment_time_note')}: ${format(new Date(startShipmentTimeWatch), 'HH:mm')}`,
      });
    }
  };

  const timeOnChange = (time) => {
    if (deliveryTypeWatch === DeliveryType.RITIRO) {
      form.setFieldsValue({
        note_ritiro: `${t('forms.shipmentRequest.start_shipment_time_note')}: ${format(new Date(time), 'HH:mm')}`,
      });
    }
  };

  const onChangeClient = _.debounce((email: string) => {
    dispatch(requestGetUserPriceList(email));
  }, 500);

  const updateMerceAdrHandler = (adr: { merce_adr: MerceAdrType[] }) => {
    setMerceAdrState(adr.merce_adr);
  };

  return (
    <Form
      layout="vertical"
      className={style.new_shipment_form}
      form={form}
      onValuesChange={fieldsChangedHandler}
      onFinish={onFinishForm}
    >
      <div className={style.new_shipment_container}>
        <Form.Item
          label={t('forms.newShipment.customer')}
          name="email"
          rules={
            [{ required: true, message: t('validation.empty') }]
          }
        >
          <Select
            showSearch
            placeholder={t('forms.newShipment.select_user')}
            optionFilterProp="children"
            onChange={onChangeClient}
          >
            {usersSearch?.map((u) => (
              <Option key={u.email} value={u.email}>{u.name || u.email}</Option>
            ))}
          </Select>
        </Form.Item>
        <div className={classnames('title-20px', style.type_title)}>
          {t('forms.newEstimation.shipping_type')}
        </div>
        <Form.Item
          name="delivery_type"
          rules={
            [
              { required: true, message: t('validation.delivery_type') },
            ]
          }
        >
          <Row>
            <Col span={4}>
              <RadioButton
                title={t('forms.newEstimation.withdrawal')}
                value={DeliveryType.RITIRO}
                onChange={onDeliveryTypeChange}
                id="1"
                name="type_group"
              />
            </Col>
            <Col span={4} offset={1}>
              <RadioButton
                title={t('forms.newEstimation.delivery')}
                value={DeliveryType.CONSEGNA}
                id="2"
                name="type_group"
              />
            </Col>
            <Col span={4} offset={1}>
              <RadioButton
                title={t('forms.newEstimation.triangulation')}
                value={DeliveryType.TRIANG}
                id="3"
                name="type_group"
              />
            </Col>
          </Row>
        </Form.Item>
        <div className={classnames(style.new_shipment_form, 'title-20px')}>
          {t('forms.newShipment.details')}
        </div>
        <Row className={style.details_part}>
          <Col span={11}>
            <GooglePlacesComponent
              addressField={AddressFields.PICKUP_ADDRESS}
              deliveryType={DeliveryType.RITIRO}
              orderData={{
                options: filteredOptions,
                delivery_type: deliveryTypeWatch || '',
              }}
            />
          </Col>
          <Col span={11} offset={2}>
            <GooglePlacesComponent
              addressField={AddressFields.SHIPPING_ADDRESS}
              deliveryType={DeliveryType.CONSEGNA}
              orderData={{
                options: filteredOptions,
                delivery_type: deliveryTypeWatch || '',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label={t('forms.newEstimation.price_list')}
              name="price_list"
              rules={
                [{ required: true, message: t('validation.empty') }]
              }
            >
              <Select disabled={loadingPriceListTemplates}>
                { priceLists
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((p) => (
                    <Option key={p.id} value={p.id}>{p.name}</Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5} className={style.input_container}>
            <Form.Item
              label={t('forms.shipmentRequest.start_shipment_date')}
              name="start_shipment_date"
              rules={[
                { required: true, message: t('validation.date_pronto_merce') },
                ({ getFieldValue }) => ({
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  validator(_, value) {
                    if (getFieldValue('withdrawal_date') && value && moment(getFieldValue('withdrawal_date')) < value) {
                      return Promise.reject(new Error(t('validation.start_shipment_date')));
                    }
                    if (!value) {
                      return Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                className="date_input"
                placeholder=""
                disabledDate={(current) => (
                  current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                )}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
          { (deliveryTypeWatch === DeliveryType.RITIRO || deliveryTypeWatch === DeliveryType.TRIANG)
            && hasNonStop && (
            <Col span={5} offset={1} className={style.input_container}>
              <Form.Item
                label={t('forms.shipmentRequest.non_stop_date_ritiro')}
                name="non_stop_date_ritiro"
                rules={
                  [
                    ({ getFieldValue }) => ({
                      validator(rules, value) {
                        if (getFieldValue('non_stop_date_consegna') && value && moment(getFieldValue('non_stop_date_consegna')) < value) {
                          return Promise.reject(new Error(t('validation.non_stop_date_ritiro')));
                        }
                        if (!value) {
                          return Promise.resolve();
                        }
                        return Promise.resolve();
                      },
                    })]}
              >
                <DatePicker
                  className="date_input"
                  placeholder=""
                  disabledDate={(current) => (
                    current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                  )}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={5} offset={1} className={style.input_container}>
            <Form.Item
              label={t('forms.shipmentRequest.withdrawal_date')}
              name="withdrawal_date"
              rules={
                [
                  ({ getFieldValue }) => ({
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    validator(_, value) {
                      if (getFieldValue('start_shipment_date') && value && moment(getFieldValue('start_shipment_date')) > value) {
                        return Promise.reject(new Error(t('validation.withdrawal_date')));
                      }
                      if (!value) {
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  })]}
            >
              <DatePicker
                className="date_input"
                placeholder=""
                disabledDate={(current) => (
                  current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                )}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
          { (deliveryTypeWatch === DeliveryType.CONSEGNA || deliveryTypeWatch === DeliveryType.TRIANG)
            && hasNonStop && (
            <Col span={5} offset={1} className={style.input_container}>
              <Form.Item
                label={t('forms.shipmentRequest.non_stop_date_consegna')}
                name="non_stop_date_consegna"
                rules={
                  [
                    ({ getFieldValue }) => ({
                      validator(rules, value) {
                        if (getFieldValue('non_stop_date_ritiro') && value && moment(getFieldValue('non_stop_date_ritiro')) > value) {
                          return Promise.reject(new Error(t('validation.non_stop_date_consegna')));
                        }
                        if (!value) {
                          return Promise.resolve();
                        }
                        return Promise.resolve();
                      },
                    })]}
              >
                <DatePicker
                  className="date_input"
                  placeholder=""
                  disabledDate={(current) => (
                    current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                  )}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={5} className={style.input_container}>
            <Form.Item
              name="start_shipment_time"
              label={t('forms.shipmentRequest.start_shipment_time')}
              rules={[
                { required: true, message: t('validation.empty') },
              ]}
            >
              <TimePicker
                onChange={timeOnChange}
                placeholder=""
                format={'HH:mm'}
              />
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item
              label={t('forms.shipmentRequest.shipping_client_code')}
              name="codice_cliente_spedizione"
            >
              <Input placeholder={t('forms.shipmentRequest.optional')}/>
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item
              label={t('forms.shipmentRequest.ddt_number')}
              name="ddt_number"
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.options_gap_container}>
          { (serviceTelefonica?.triangolize === DeliveryType.RITIRO
          || serviceTelefonica?.triangolize === DeliveryType.BOTH) && (
            <Col span={5}>
              <Form.Item
                label={t('forms.shipmentRequest.telefonica_ritiro')}
                name="telefonica_ritiro"
                rules={[
                  { required: true, message: t('validation.empty') },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>
          )}
          { (serviceTelefonica?.triangolize === DeliveryType.CONSEGNA
          || serviceTelefonica?.triangolize === DeliveryType.BOTH) && (
            <Col span={5}>
              <Form.Item
                label={t('forms.shipmentRequest.telefonica_consegna')}
                name="telefonica_consegna"
                rules={[
                  { required: true, message: t('validation.empty') },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>
          )}

          { (serviceFacchinaggio?.triangolize === DeliveryType.RITIRO
          || serviceFacchinaggio?.triangolize === DeliveryType.BOTH) && (
            <Col span={5}>
              <Form.Item
                label={t('forms.shipmentRequest.facchinaggio_ritiro')}
                name="facchinaggio_ritiro"
                rules={[
                  { required: true, message: t('validation.empty') },
                ]}
              >
                <Select>
                  <Option key={0} value={0}>-</Option>
                  { facchinaggioList.map((c) => (
                    <Option key={c.id} value={c.id}>{c.description}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          { (serviceFacchinaggio?.triangolize === DeliveryType.CONSEGNA
          || serviceFacchinaggio?.triangolize === DeliveryType.BOTH) && (
            <Col span={5}>
              <Form.Item
                label={t('forms.shipmentRequest.facchinaggio_consegna')}
                name="facchinaggio_consegna"
                rules={[
                  { required: true, message: t('validation.empty') },
                ]}
              >
                <Select>
                  <Option key={0} value={0}>-</Option>
                  { facchinaggioList.map((c) => (
                    <Option key={c.id} value={c.id}>{c.description}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Form.Item
          name="contractor_type"
          rules={
            [
              { required: true, message: t('validation.contractor_type') },
            ]
          }
        >
          <Radio.Group name="radiogroup"
          >
            <Row>
              {
                contractors[0]?.map((i) => (
                  <Col key={i.id}>
                    <Radio value={i.id} className={style.radio_gap}>{i.name}</Radio>
                  </Col>
                ))
              }
              <Col>
                <Radio value={'Fornitore'} className={style.radio_gap}>Fornitore</Radio>
                <Form.Item
                  noStyle={true}
                  shouldUpdate={
                    (prev, curr) => prev.available !== curr.available
                  }
                >
                  {({ getFieldValue }) => (getFieldValue('contractor_type') === 'Fornitore'
                    ? <Form.Item
                      className={style.contractor_select}
                      name="contractor_type_select"
                    >
                      <ContractorsSelect
                        initialValue={''}
                        onChange={() => setContractor}
                        contractors={contractors[1]}
                      />
                    </Form.Item>
                    : <Form.Item
                      className={style.contractor_select}
                    >
                      <Select
                        disabled={true}
                      >
                      </Select>
                    </Form.Item>
                  )
                  }
                </Form.Item>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
        { contractorTypeWatch === findPallexItalia?.id && deliveryTypeWatch !== DeliveryType.TRIANG
          && (
            <Row>
              <Col span={5}>
                <Form.Item
                  label={'Hub'}
                  name="idHub"
                >
                  <HubSelect onChange={(idHub: number) => {
                    form.setFieldsValue({
                      idHub: +idHub,
                    });
                  }}/>
                </Form.Item>
              </Col>
            </Row>
          )
        }
        <ExtraServices deliveryType={deliveryTypeWatch} />
        <Row>
          <Col span={24}>
            <Form.Item
              label={<div className={style.note_style}>
                {t('forms.shipmentRequest.informazioni_aggiuntive')}
              </div>}
              name="informazioni_aggiuntive"
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item
              label={<div className={style.note_style}>
                {t('forms.shipmentRequest.note_ritiro')}
              </div>}
              name="note_ritiro"
            >
              <Input.TextArea placeholder={t('placeholder.note')} />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label={<div className={style.note_style}>
                {t('forms.shipmentRequest.note_consegna')}
              </div>}
              name="note_consegna"
            >
              <Input.TextArea placeholder={t('placeholder.note')} />
            </Form.Item>
          </Col>
        </Row>
      </div>
      { hasMerceAdr && (
        <MerceAdrTable
          dataArray={merceAdrState}
          updateOptionHandler={updateMerceAdrHandler}
        />
      )}
      { isLoaderContainer && <ShipmentFooterContainer/>}

      <div className={style.buttons_container}>
        <CustomButton
          type="primary"
          className={style.proceedBtn}
          htmlType="submit"
        >
          { isLoaderContainer ? t('forms.newEstimation.confirmation') : t('forms.newEstimation.proceed') }
        </CustomButton>
        <CustomButton
          className={classnames('defaultBtn', style.cancelBtn)}
          onClick={onCancel}
        >
          {t('forms.newEstimation.cancel')}
        </CustomButton>
      </div>
    </Form>
  );
};

export default NewShipmentForm;
