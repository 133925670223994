import Profile from '../Profile';
import { RoleEnums } from '../../enums';

const OperatorProfile = () => (
  <Profile
    role={RoleEnums.OPERATOR}
  />
);

export default OperatorProfile;
