import { useMemo } from 'react';
import EditableTable from '../index';
import { Pallet, ExtraServiceStructure } from 'store/types';
import { EditableDetailsTableTypes } from 'enums';

type PrivateAreaPalletsTableProps = {
  dataArray: Pallet[],
  updateOptionHandler?: (data) => void,
  editable?: boolean,
  options?: ExtraServiceStructure[],
}

const PrivateAreaPalletsTable = ({
  dataArray, updateOptionHandler, editable = true, options,
}: PrivateAreaPalletsTableProps) => {
  const newTableData = useMemo(() => (
    dataArray.map((sourceItem, i) => ({
      key: `${EditableDetailsTableTypes.private_area_pallets}_${i}`,
      item_quantity: sourceItem.item_quantity,
      depth: sourceItem.depth,
      width: sourceItem.width,
      height: sourceItem.height,
      weight: sourceItem.weight,
    }))
  ), [dataArray]);

  const parceSavedData = async (row, key: string) => {
    const initPallets:Pallet = {
      item_quantity: Math.trunc(row.item_quantity),
      depth: +row.depth,
      width: +row.width,
      height: +row.height,
      weight: +row.weight,
      adr: row.adr,
      price: row.price ? row.price : 0,
    };
    let newDataArr:Pallet[] = [];
    const rowIsAvailable = !!dataArray.find((item, i) => `${EditableDetailsTableTypes.private_area_pallets}_${i}` === key);
    if (rowIsAvailable) {
      newDataArr = dataArray.map((item, i) => (
        (`${EditableDetailsTableTypes.private_area_pallets}_${i}` === key) ? {
          ...initPallets,
        } : item));
    } else {
      newDataArr = [...dataArray, initPallets];
    }
    if (updateOptionHandler) {
      updateOptionHandler({ [EditableDetailsTableTypes.private_area_pallets]: newDataArr });
    }
    return newDataArr;
  };

  const deleteHandler = (key:string) => {
    if (updateOptionHandler) {
      const data = {
        [EditableDetailsTableTypes.private_area_pallets]: dataArray.filter((item, i) => `${EditableDetailsTableTypes.private_area_pallets}_${i}` !== key),
      };
      updateOptionHandler(data);
    }
  };

  return (
    <EditableTable
      tableType={EditableDetailsTableTypes.private_area_pallets}
      dataSource={newTableData}
      parceSavedData={parceSavedData}
      deleteHandler={deleteHandler}
      initialFields={{
        key: `${EditableDetailsTableTypes.private_area_pallets}_${newTableData.length}`,
      }}
      editable={editable}
    />
  );
};

export default PrivateAreaPalletsTable;
