import {
  put, takeEvery,
} from 'redux-saga/effects';
import { MonitoringListPayload } from '../types';
import { setMessage } from '../redux_slice/messageErrorSlice';
import { GetMonitoringCSV, GetMonitoringList } from '../../api/monitoring';
import {
  requestGetMonitoringList, setMonitoringList, setLoading, requestGetMonitoringCSV,
} from '../redux_slice/monitoringSlice';

function* getMonitoringListSaga(action: {
    payload: MonitoringListPayload
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetMonitoringList(action.payload);
    yield put(setMonitoringList(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getMonitoringCSVSaga(action: {
  payload: MonitoringListPayload
}) {
  try {
    yield GetMonitoringCSV(action.payload);
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

export function* monitoringSaga() {
  yield takeEvery(requestGetMonitoringList, getMonitoringListSaga);
  yield takeEvery(requestGetMonitoringCSV, getMonitoringCSVSaga);
}
