import {
  Col, DatePicker, Form, Input, Row, Select,
} from 'antd';
import { FilterFilled, ReloadOutlined } from '@ant-design/icons/lib/icons';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import CustomButton from '../../components/CustomButton/CustomButton';
import style from './monitoring.module.css';
import { FilterApproveShippingRequest, UserStructure } from '../../store/types';
import { RoleEnums } from '../../enums';

const FilterMonitoringContainer:React.FC<{
  onChangeFilter: (obj) => void,
  operatorsList: UserStructure[] | null,
}> = ({ onChangeFilter, operatorsList }) => {
  const { t } = useTranslation();
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const [searchForm] = Form.useForm();
  const [advancedForm] = Form.useForm();
  const { Option } = Select;
  const operators = operatorsList?.filter((el) => el.role === RoleEnums.OPERATOR || el.role === RoleEnums.ADMIN);

  const onChangeFilterHandler = useCallback(_.debounce((param: keyof FilterApproveShippingRequest, value) => {
    onChangeFilter((prevState) => ({ ...prevState, ...{ [param]: value } }));
  }, 500), []);

  const orResetFilterHandler = () => {
    onChangeFilter({});
    searchForm.resetFields();
    advancedForm.resetFields();
  };
  return (
    <>
      <div className={style.filter_container}>
        <Form form={searchForm}>
          <Form.Item name="ip_address" className={style.search_input}>
            <Input
              placeholder={t('forms.monitoring.searchInputPlaceholder')}
              onChange={(v) => { onChangeFilterHandler('ip_address', v.target.value || undefined); }}
            />
          </Form.Item>
        </Form>
        <CustomButton
          className={style.searchBtn}
          icon={<FilterFilled/>}
          onClick={() => setIsAdvanced(!isAdvanced)}
        >
          {t('forms.estimations.filter_button')}
        </CustomButton>
        <CustomButton
          className={style.resetBtn}
          icon={<ReloadOutlined/>}
          onClick={orResetFilterHandler}
        >
          {t('forms.estimations.reset_button')}
        </CustomButton>
      </div>
      {isAdvanced && (
        <Form form={advancedForm}>
          <Row>
            <Col>
              <p className={style.labelSearch}>{t('forms.monitoring.operator')}</p>
              <Form.Item name="operator_name" className={style.operator_select}>
                <Select
                  onChange={(v) => onChangeFilterHandler('operator_name', v || undefined)}
                >
                  {
                    operators?.map((i) => (
                      <Option key={i.id} value={i.email}>
                        {i.name || i.email}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col offset={1}>
              <p className={style.labelSearch}>{t('forms.monitoring.from')}</p>
              <Form.Item name="date_from">
                <DatePicker className={style.date_input}
                  onChange={(v) => { onChangeFilterHandler('date_from', v ? v.utc().startOf('day').toISOString() : undefined); }}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <p className={style.labelSearch}>{t('forms.monitoring.to')}</p>
              <Form.Item name="date_to">
                <DatePicker className={style.date_input}
                  onChange={(v) => { onChangeFilterHandler('date_to', v ? v.utc().startOf('day').toISOString() : undefined); }}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default FilterMonitoringContainer;
