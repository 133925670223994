import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { PathEnums } from 'enums';
import style from './profile.module.css';

const CancelAccount = () => {
  const { t } = useTranslation();
  return (
    <div className={style.cancel_form}>
      <div className="main-title-30px">
        {t('operatorSettings.cancel_account')}
      </div>
      <p className={classnames(style.user_left, style.user_title, style.text_style, style.primary_dark)}>
        {t('operatorSettings.send_request')}
        <Link to={PathEnums.Support} className={classnames(style.primary, style.bolt_text)}>
          {t('operatorSettings.support')}
        </Link>
      </p>
    </div>
  );
};

export default CancelAccount;
