import React, { useEffect } from 'react';
import {
  Form, Modal, TimePicker,
} from 'antd';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomButton from 'components/CustomButton/CustomButton';
import style from '../estimations.module.css';

type ChangeTimeModalType = {
  visible: boolean,
  onCancel: () => void,
  onUpdateTime: (time: string) => void,
  initialValue: string | null,
}

export const ChangeTimeModal: React.FC<ChangeTimeModalType> = ({
  visible, onCancel, onUpdateTime, initialValue,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      start_shipment_time: initialValue ? moment(initialValue, 'HH:mm') : '',
    });
  }, [form, initialValue]);

  const submit = (values) => {
    onUpdateTime(format(new Date(values.start_shipment_time), 'HH:mm'));
    onCancel();
  };

  return (
    <Modal title={t('forms.estimations.update_time')}
      visible={visible}
      onOk={submit}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        onFinish={submit}
      >
        <Form.Item name="start_shipment_time">
          <TimePicker format={'HH:mm'} />
        </Form.Item>
        <CustomButton
          className={style.modal_button}
          type="primary"
          htmlType="submit"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};
