import { Button } from 'antd';
import { useState, useEffect, useRef } from 'react';

import style from './optionsButton.module.css';

export interface Option {
  title: string,
  type?: string,
  action: (id: string, status?: string) => void
}

interface OptionsButtonProps {
  id: string,
  userId?: string,
  status?: string,
  options: Option[],
}

const OptionsButton = ({
  id, userId, options, status,
}: OptionsButtonProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOptions, setIsOptions] = useState<boolean>(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOptions && ref.current && !ref.current.contains(e.target)) {
        setIsOptions(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOptions]);

  return (
    <div ref={ref}>
      <Button
        className={style.optionsBtn}
        icon={<span>. . .</span>}
        onClick={() => setIsOptions(!isOptions)}
      />
      {(isOptions) && <div className={style.options}>
        {options.map((option) => (
          <button
            className={style.optionLink}
            key={option.title}
            onClick={() => {
              if (option.type && userId) {
                option.action(userId);
              } else {
                option.action(id, status);
              }
              setIsOptions(false);
            }}>
            {option.title}
          </button>
        ))}
      </div>}
    </div>
  );
};

export default OptionsButton;
