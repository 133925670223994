import { TFunction } from 'react-i18next';
import { EditableDetailsTableTypes } from 'enums';

export const makeColumnsEditableTable = (t:TFunction, tableType: EditableDetailsTableTypes, editable: boolean) => {
  const palletsProportionsArray = [
    {
      title: t('forms.newEstimation.quantity'),
      dataIndex: 'item_quantity',
      inputType: 'quantyty_number',
      editable: true,
      width: '10%',
      align: 'center',
    },
    {
      title: t('forms.newEstimation.shortSide'),
      dataIndex: 'depth',
      inputType: 'depth_number',
      editable: true,
      width: '10%',
      align: 'center',
    },
    {
      title: t('forms.newEstimation.longSide'),
      dataIndex: 'width',
      inputType: 'width_number',
      editable: true,
      width: '10%',
      align: 'center',
    },
    {
      title: t('forms.newEstimation.height'),
      dataIndex: 'height',
      inputType: 'height_number',
      editable: true,
      width: '10%',
      align: 'center',
    },
    {
      title: t('forms.newEstimation.weight'),
      dataIndex: 'weight',
      inputType: 'weight_number',
      editable: true,
      width: '10%',
      align: 'center',
    },
  ];
  const palletsPriceArray = [
    {
      title: t('forms.newEstimation.base_price'),
      dataIndex: 'base_price',
      editable: false,
      width: '10%',
      align: 'center',
    },
    {
      title: '% ripr.',
      dataIndex: 'coef',
      editable: false,
      align: 'center',
    },
  ];
  switch (tableType) {
  case EditableDetailsTableTypes.invoice_pallets:
    return [
      ...palletsProportionsArray,
      {
        title: t('invoices.table.repriced'),
        dataIndex: 'show',
        inputType: 'select_show',
        editable: true,
        width: '10%',
        align: 'center',
      },
      ...palletsPriceArray,
      {
        title: t('invoices.table.price'),
        dataIndex: 'price',
        inputType: 'pallet_price',
        editable: true,
        width: '10%',
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'options',
        width: '12%',
      },
    ];
  case EditableDetailsTableTypes.admin_pallets:
    return [
      ...palletsProportionsArray,
      ...palletsPriceArray,
      {
        title: t('invoices.table.price'),
        dataIndex: 'price',
        inputType: 'pallet_price',
        editable: false,
        width: '10%',
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'options',
        width: '12%',
      },
    ];
  case EditableDetailsTableTypes.private_area_pallets:
    return [
      ...palletsProportionsArray,
      {
        title: '',
        dataIndex: 'options',
        width: '12%',
      },
    ];
  case EditableDetailsTableTypes.options:
    return [
      {
        title: t('invoices.table.quantity'),
        dataIndex: 'quantity',
        inputType: 'simple_number',
        editable: true,
        width: '13%',
      },
      {
        title: t('invoices.table.options_title'),
        dataIndex: 'title',
        editable: false,
      },
      {
        title: t('forms.newEstimation.service_details'),
        dataIndex: 'description',
        inputType: 'text',
        editable: true,
      },
      {
        title: t('invoices.table.unit_cost'),
        dataIndex: 'price',
        inputType: 'select_price',
        editable: true,
        width: '15%',
      },
      {
        title: t('forms.newEstimation.apply_to'),
        dataIndex: 'category',
        inputType: 'select_category',
        editable: true,
        width: '15%',
      },
      {
        title: '',
        dataIndex: 'options',
        width: '15%',
      },
    ];
  case EditableDetailsTableTypes.private_area_options:
    return [
      {
        title: t('forms.newEstimation.service_name'),
        dataIndex: 'title',
        inputType: 'text',
        width: '45%',
        editable: true,
      },
      {
        title: t('forms.newEstimation.service_details'),
        dataIndex: 'description',
        inputType: 'text',
        width: '45%',
        editable: true,
      },
      {
        title: '',
        dataIndex: 'options',
        width: '15%',
      },
    ];
  case EditableDetailsTableTypes.addebitos:
    return [
      {
        title: t('invoices.table.quantity'),
        dataIndex: 'quantity',
        inputType: 'quantyty_number',
        editable: true,
        width: '13%',
      },
      {
        title: t('invoices.table.charge_description'),
        dataIndex: 'description',
        inputType: 'text',
        editable: true,
      },
      {
        title: t('invoices.table.unit_cost'),
        dataIndex: 'price',
        inputType: 'select_price',
        editable: true,
        width: '15%',
      },
      {
        title: '',
        dataIndex: 'options',
        width: '15%',
      },
    ];
  case EditableDetailsTableTypes.merce_adr:
    return [
      {
        title: t('forms.newEstimation.merce_adr_limited_qty'),
        dataIndex: 'limited_qty',
        inputType: 'select_adr_limit',
        editable: true,
        width: '13%',
      },
      {
        title: t('forms.newEstimation.merce_adr_number_onu'),
        dataIndex: 'numeroOnu',
        inputType: 'select_numero_onu',
        editable,
        width: '20%',
      },
      {
        title: t('forms.newEstimation.merce_adr_qty'),
        dataIndex: 'quantita',
        inputType: 'number',
        editable,
        width: '13%',
      },
      {
        title: t('forms.newEstimation.merce_adr_packages'),
        dataIndex: 'numeroColli',
        inputType: 'number',
        editable,
        width: '13%',
      },
      {
        title: t('forms.newEstimation.merce_adr_packaging_description'),
        dataIndex: 'imballaggioMerceAdr',
        inputType: 'select_imballaggio',
        editable,
        width: '13%',
      },
      {
        title: t('forms.newEstimation.merce_adr_description_nos'),
        dataIndex: 'descrizioneNas',
        inputType: 'descrizione_text',
        editable,
        width: '13%',
      },
      {
        title: '',
        dataIndex: 'options',
        width: '13%',
      },
    ];
  default:
    return [];
  }
};
