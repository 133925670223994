import { useState, useEffect, useMemo } from 'react';
import { Checkbox } from 'antd';
import cn from 'classnames';
import axios from 'axios';
import ReactMapGL, { Source, Layer, Marker } from 'react-map-gl';
import SuccessIconSvg from 'assests/svg/SuccessIconSvg';
import { TrucksStructure } from 'store/types';
import { DeliveryType } from 'enums';
import style from './map.module.css';
import 'mapbox-gl/dist/mapbox-gl.css';

type RoadsTypes = {
  color: string,
  routes: number[][] | [],
  locationStr: string,
  visible: boolean,
  truckNumber: string,
  driverName: string,
  markers: {
    date_end: boolean,
    lng: number,
    lat: number,
  }[],
}[]

const transportiPesantiLocation = {
  lat: 45.12016,
  lng: 10.37516,
};

const MapboxContainer = ({ trucks }: {
  trucks: TrucksStructure[];
}) => {
  const [visible, setVisible] = useState(false);
  const [roads, setRoads] = useState<RoadsTypes>([]);

  const mapboxMarkers = useMemo(() => (
    trucks?.map((truck) => {
      const locationStr = truck.trips.map((trip) => (
        trip.delivery_type === DeliveryType.RITIRO ? (
          `${trip.shipping_request.pickup_address.location.lng}%2C${
            trip.shipping_request.pickup_address.location.lat}%3B${
            transportiPesantiLocation.lng}%2C${transportiPesantiLocation.lat}`
        ) : (
          `${trip.shipping_request.shipping_address.location.lng}%2C${
            trip.shipping_request.shipping_address.location.lat}%3B${
            transportiPesantiLocation.lng}%2C${transportiPesantiLocation.lat}`
        )
      )).join('%3B');
      const markers = truck.trips.map((trip) => ({
        date_end: !!trip.date_end,
        lng: trip.delivery_type === DeliveryType.RITIRO
          ? trip.shipping_request.pickup_address.location.lng
          : trip.shipping_request.shipping_address.location.lng,
        lat: trip.delivery_type === DeliveryType.RITIRO
          ? trip.shipping_request.pickup_address.location.lat
          : trip.shipping_request.shipping_address.location.lat,
      }));
      markers.push({
        date_end: false,
        ...transportiPesantiLocation,
      });
      return {
        color: truck.color,
        locationStr,
        visible: true,
        truckNumber: truck.plate_number,
        driverName: truck?.driver?.name || '-',
        markers,
      };
    })
  ), [trucks]);

  const toogleDrawer = () => {
    if (visible) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    if (mapboxMarkers && mapboxMarkers?.length > roads.length) {
      mapboxMarkers?.forEach(async (item, i) => {
        let roadsArr: number[][] = [];
        if (item.markers.length > 1) {
          const { data } = await axios.get(`https://api.mapbox.com/directions/v5/mapbox/driving/${item.locationStr}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=pk.eyJ1IjoidHJhc3BvcnRpcGVzYW50aSIsImEiOiJjbDdham5jOG4wdWFmM25vbHFvYmNhNmQxIn0.cFyhqBBQqeF-XZNT7tes_g`);
          if (data.code === 'Ok' && data.routes.length) {
            const [route] = data.routes;
            roadsArr = route.geometry.coordinates;
          }
        }
        setRoads((prevState) => [...prevState, {
          routes: roadsArr,
          ...item,
        }]);
      });
    }
  }, [mapboxMarkers]);

  const checkboxOnChange = (e) => {
    setRoads((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.truckNumber === e.target.value) {
          return { ...obj, visible: !obj.visible };
        }
        return obj;
      });

      return newState;
    });
  };

  if (!mapboxMarkers) return null;

  return (
    <>
      <div className={cn({ [style.drawer_is_open_mask]: visible })}></div>
      <div className={cn(style.drawer_map_container, {
        [style.drawer_is_open]: visible,
      })}>
        <div className={style.btn_container}>
          <button className={style.drawer_btn} onClick={toogleDrawer}>
            { visible ? 'Nascondi mappa percorsi' : 'Vedi mappa percorsi' }
          </button>
        </div>
        {roads.length ? (
          <div className={style.routes_board}>
            <div className={style.routes_board_conteiner}>
              { roads.map((m, i) => (
                <Checkbox
                  key={i}
                  className={style.checkbox_item}
                  value={m.truckNumber}
                  checked={m.visible}
                  onChange={checkboxOnChange}
                >
                  <div className={style.item_content}>
                    <span className={style.round_color} style={{ backgroundColor: m.color }}></span>
                    <div className={style.board_info}>
                      <span className={style.truck_number}>{m.truckNumber}</span>
                      <span>{m.driverName}</span>
                    </div>
                  </div>
                </Checkbox>
              ))}
            </div>
          </div>
        ) : null}
        <div className={cn(style.map_container, { [style.blur]: !visible })}>
          <ReactMapGL
            initialViewState={{
              latitude: 42.50,
              longitude: 12.50,
              zoom: 7,
            }}
            mapStyle="mapbox://styles/trasportipesanti/cl7zwu5pw00av14mpss83p70n"
            mapboxAccessToken="pk.eyJ1IjoidHJhc3BvcnRpcGVzYW50aSIsImEiOiJjbDdhamh3cWgwdWJxM29vbGU5aDZ2cWQ4In0.pXBYUfhcWtSLVqSmkapLHQ"
          >
            { roads.map((item, i) => item.visible && (
              <Source key={i} type="geojson" data={{
                type: 'FeatureCollection',
                features: [{
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'LineString',
                    coordinates: item.routes,
                  },
                }],
              }}>
                <Layer
                  type="line"
                  layout={{ 'line-join': 'round' }}
                  paint={{
                    'line-color': item.color,
                    'line-width': 5,
                  }}
                />
              </Source>
            ))}
            { roads.map((road) => road.visible && (
              road.markers.map((trip, i) => (
                <Marker key={i} longitude={trip.lng} latitude={trip.lat}>
                  <div style={{ backgroundColor: road.color }} className={style.markers}>
                    { trip.date_end && <SuccessIconSvg color="#fff" /> }
                  </div>
                </Marker>
              ))
            ))}
          </ReactMapGL>
        </div>
      </div>
    </>
  );
};

export default MapboxContainer;
