import { Link } from 'react-router-dom';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import CustomButton from '../../components/CustomButton/CustomButton';
import style from './login.module.css';
import { useAppDispatch } from '../../store/store';
import { requestLogin } from '../../store/redux_slice/authSlice';
import { LoginPayload } from '../../store/types';
import { PathEnums } from 'enums';

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onFinish = (values: LoginPayload) => {
    dispatch(requestLogin(values));
  };

  return (<div className={classnames(style.login, 'customForm')}>
    <Form layout="vertical"
      name="logIn"
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        label={t('forms.login.emailAdress')}
        rules={
          [
            { required: true, message: t('validation.email') },
            { type: 'email', message: t('validation.email') },
          ]
        }
      >
        <Input className={style.input} type="email"/>
      </Form.Item>
      <Form.Item
        name="password"
        label={t('forms.login.password')}
        className={style.password__field}
        rules={
          [
            { required: true, message: t('validation.password') },
            { min: 8, message: t('validation.password') },
            { whitespace: true, message: t('validation.password') },
          ]
        }
      >
        <Input.Password className={style.input}/>
      </Form.Item>
      <Link to={PathEnums.Restore_password} className={style.password__forgot}>
        {t('forms.login.forgotPassword')}
      </Link>
      <Form.Item>
        <CustomButton className={style.login__button}
          htmlType="submit"
          type="primary"
        >
          {t('forms.login.signIn')}
        </CustomButton>
      </Form.Item>
    </Form>
  </div>);
};

export default LoginForm;
