import { instance } from './index';
import { GdoPayloadType, NumeroOnuPayloadType } from 'store/types';

export const GetContrassegno = () => instance.get('/pallex/contrassegno');

export const GetFacchinaggio = () => instance.get('/pallex/facchinaggio');

export const GetNonStop = () => instance.get('/pallex/non-stop');

export const GetExtraServiceGDO = (data: GdoPayloadType) => instance.get('/pallex/gdo-search', {
  params: { ...data },
});

export const GetNumeroOnu = (data: NumeroOnuPayloadType) => instance.get('/pallex/numeri-onu', {
  params: { ...data },
});
