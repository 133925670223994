import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { SettingOutlined } from '@ant-design/icons';
import { maceColumnsToBeApproved } from '../components/TableColumns';
import { ShipmentsListStructure } from 'store/types';

const ShippingToBeApprovedTable = ({
  setVisible,
  toBeApprovedShipments,
  setSelectedId,
  // setInitialSlide,
  page,
  count,
  setPage,
  setCount,
} : {
  setVisible: () => void,
  toBeApprovedShipments: ShipmentsListStructure,
  setSelectedId: (number) => void,
  // setInitialSlide: (number) => void,
  page: number,
  count: number,
  setPage: (number) => void,
  setCount: (number) => void,
}) => {
  const { t } = useTranslation();
  const onChangePageSize = (current, size) => {
    setCount(size);
  };
  const pagination = {
    current: page,
    pageSize: count,
    pageSizeOptions: [10, 15, 20],
    showSizeChanger: true,
    total: toBeApprovedShipments?.count,
    onChange: setPage,
    onShowSizeChange: onChangePageSize,
  };
  const data = toBeApprovedShipments.items
    .map((item) => {
      const {
        id,
        start_shipment_date: startDate,
        pallets,
        pickup_address: fromAddress,
        shipping_address: toAddress,
        user,
      } = item;
      const isNonStop = item.options.filter((el) => el.title?.toUpperCase() === 'SERVIZIO NON-STOP/ESPRESSO' && el.isActive).length > 0;
      return {
        key: id,
        start_shipment_date: startDate || '',
        id: item.spedizione_id || item.id,
        pickupName: item.pickup_address?.company_name || item.pickup_address?.name_and_surname_reference || '-',
        shippingName: item.shipping_address?.company_name || item.shipping_address?.name_and_surname_reference || '-',
        number_pallet: pallets.map((aplletItem) => (
          aplletItem.family ? `${aplletItem.item_quantity} - ${aplletItem.family}` : `${aplletItem.item_quantity}`
        )),
        recipient_name: user.name,
        measures: pallets.map((pallet) => (
          `${pallet.width}cm x ${pallet.depth}cm x ${pallet.height}cm | ${pallet.weight}kg`
        )),
        status: { shipment: item },
        from_to: `${fromAddress.location.city
          || fromAddress.location.region} > ${toAddress.location.city
          || toAddress.location.region}`,
        options: (
          <SettingOutlined onClick={() => {
            setSelectedId(id);
            setVisible();
          }}/>
        ),
        isNonStop,
      };
    });
  const columns = maceColumnsToBeApproved(t);

  return (
    <Table
      rowClassName={(record, index) => (record.isNonStop ? 'non_stop_background' : '')}
      columns={columns}
      pagination={pagination} dataSource={data}
    />
  );
};

export default ShippingToBeApprovedTable;
