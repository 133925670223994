import React from 'react';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './assests/styles/index.less';
import './assests/styles/common.css';
import './assests/styles/antdCustomStyles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import App from './containers/App/App';
import { store } from './store/store';
import './translations/i18n';

render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <Provider store={store}>
          <App/>
        </Provider>
      </BrowserRouter>
    </DndProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
