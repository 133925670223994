import { FilterFilled, ReloadOutlined } from '@ant-design/icons/lib/icons';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Col, DatePicker, Form, Input, Row, Select,
} from 'antd';
import { format } from 'date-fns';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { FilterApproveShippingRequest } from '../../../store/types';
import style from '../deliveries.module.css';
import CustomSelect from '../../../components/CustomSelect';
import { useAppSelector } from '../../../store/store';
import { PallexStatuses, RoleEnums, TPStatuses } from '../../../enums';

const ShippingApproveFilter: React.FC<{
    setFilter: (obj) => void,
    activeTab: string,
    filterStatus: string | undefined,
}> = ({ setFilter, activeTab, filterStatus }) => {
  const { t } = useTranslation();
  const operatorsList = useAppSelector((state) => state.operatorsList.operatorsList);
  const [advancedForm] = Form.useForm();
  const [statusForm] = Form.useForm();
  const { Option } = Select;
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const onChangeFilterHandler = useCallback(_.debounce((param: keyof FilterApproveShippingRequest, value) => {
    setFilter((prevState) => ({ ...prevState, ...{ [param]: value } }));
  }, 500), []);
  const orResetFilterHandler = () => {
    setFilter({});
    advancedForm.resetFields();
    statusForm.resetFields();
  };
  const drivers = operatorsList?.filter((el) => el.role === RoleEnums.DRIVER);
  return (
    <>
      <div className={style.filter_container}>
        <Form form={statusForm}>
          <Form.Item name="tracking_status" className={style.status_select}>
            <CustomSelect
              defaultValue={filterStatus}
              placeholder={t('forms.estimations.select_placeholder')}
              onChange={(v) => onChangeFilterHandler('tracking_status', v || undefined)}
              options={(activeTab === 'tab1'
                ? [
                  { title: PallexStatuses.In_attesa_di_data, value: PallexStatuses.In_attesa_di_data },
                  { title: PallexStatuses.In_attesa_di_ritiro, value: PallexStatuses.In_attesa_di_ritiro },
                  { title: PallexStatuses.Ritiro_effettuato, value: PallexStatuses.Ritiro_effettuato },
                  { title: PallexStatuses.Presso_HUB, value: PallexStatuses.Presso_HUB },
                  { title: PallexStatuses.In_consegna, value: PallexStatuses.In_consegna },
                ]
                : [
                  { title: PallexStatuses.Consegnato, value: PallexStatuses.Consegnato },
                  { title: TPStatuses.Annullata, value: TPStatuses.Annullata },
                ])}
            />
          </Form.Item>
          <Form.Item name="search_bar" className={style.status_select}>
            <Input
              onChange={(v) => onChangeFilterHandler('search_bar', v.target.value || undefined)}
              className={style.select_width}
              placeholder={t('forms.users.search')}
            />
          </Form.Item>
        </Form>
        <CustomButton
          className={style.searchBtn}
          onClick={() => setIsAdvanced(!isAdvanced)}
          icon={<FilterFilled/>}
        >
          {t('forms.estimations.filter_button')}
        </CustomButton>
        <CustomButton
          className={style.resetBtn}
          icon={<ReloadOutlined/>}
          onClick={orResetFilterHandler}
        >
          {t('forms.estimations.reset_button')}
        </CustomButton>
      </div>
      {isAdvanced && (
        <Form form={advancedForm} className={style.filter_form_container}>
          <Row>
            <Col span={4}>
              <p className={style.labelSearch}>{t('forms.deliveries.type')}</p>
              <Form.Item
                name="delivery_type"
              >
                <Select
                  className={style.input_number}
                  onChange={(v) => onChangeFilterHandler('delivery_type', v || undefined)}
                >
                  <Option key={'Consegna'} value={'Consegna'}>
                    {'Consegna'}
                  </Option>
                  <Option key={'Ritiro'} value={'Ritiro'}>
                    {'Ritiro'}
                  </Option>
                  <Option key={'Triangolazione'} value={'Triangolazione'}>
                    {'Triangolazione'}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col offset={1} span={4}>
              <p className={style.labelSearch}>{t('forms.deliveries.driver')}</p>
              <Form.Item
                name="driver_id"
              >
                <Select
                  className={style.input_number}
                  onChange={(v) => onChangeFilterHandler('driver_id', v || undefined)}
                >
                  {
                    drivers?.map((i) => (
                      <Option key={i.id} value={i.id}>
                        {i.name}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col offset={1} span={5}>
              <p className={style.labelSearch}>{t('forms.deliveries.client_name')}</p>
              <Form.Item name="userName">
                <Input onChange={(v) => { onChangeFilterHandler('userName', v.target.value || undefined); }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <p className={style.labelSearch}>{t('shipmentList.collectionDateSearch')}</p>
              <Form.Item name="withdrawal_date">
                <DatePicker className="date_input" onChange={(v) => { onChangeFilterHandler('withdrawal_date', v ? v.utc().startOf('day').toISOString() : undefined); }} format="DD/MM/YYYY"/>
              </Form.Item>
            </Col>
            <Col offset={1} span={6}>
              <p className={style.labelSearch}>{t('shipmentList.deliveryDateSearch')}</p>
              <Form.Item name="start_shipment_date">
                <DatePicker className="date_input" onChange={(v) => { onChangeFilterHandler('start_shipment_date', v ? v.utc().startOf('day').toISOString() : undefined); }} format="DD/MM/YYYY"/>
              </Form.Item>
            </Col>
            <Col span={3} offset={1}>
              <p className={style.labelSearch}>{t('forms.deliveries.approved_date')}</p>
              <Form.Item name="approvato_il">
                <DatePicker className="date_input" onChange={(v) => { onChangeFilterHandler('approvato_il', v ? format(v.toDate(), 'yyyy-MM-dd') : undefined); }} format="DD/MM/YYYY"/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ShippingApproveFilter;
