import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PallexHub } from '../types';

export const requestGetPallexHubs = createAction('requestGetPallexHubs');

type State = {
  hubs: Array<PallexHub>,
  loading: boolean,
}

const initialState: State = {
  hubs: [],
  loading: false,
};

const pallexHubsSlice = createSlice({
  name: 'pallexHubs',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPallexHubs: (state, action: PayloadAction<Array<PallexHub>>) => {
      state.hubs = action.payload;
    },
  },
});

export const { setPallexHubs, setLoading } = pallexHubsSlice.actions;

export default pallexHubsSlice.reducer;
