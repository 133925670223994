import { TFunction } from 'react-i18next';
import classnames from 'classnames';
import { SettingOutlined } from '@ant-design/icons';
import React from 'react';
import type { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import style from './estimations.module.css';
import ReqStatus from '../../components/ReqStatus';
import { EstimationStatuses } from '../../enums';

interface EstimationTableColumnsInterface {
  date: string,
  id: string,
  email: string,
  number_pallet: JSX.Element[][],
  measures: JSX.Element[][],
  from_to: string,
  status: string,
  settings: string
}

export const makeEstimationTableColumns = (t:TFunction, onClick:(id:string)=>void)
    : ColumnsType<EstimationTableColumnsInterface> => [
  {
    title: t('forms.estimations.date'),
    dataIndex: 'date',
    key: 'date',
    render: (value) => (value ? format(new Date(value), 'dd-MM-yyyy') : ''),
    sorter: (a, b, sortOrder) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    align: 'center',
  },
  {
    title: t('forms.estimations.id'),
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  },
  {
    title: t('forms.estimations.email'),
    dataIndex: 'email',
    key: 'email',
    render: (text) => <span className={classnames(style.primary_color, style.email_column)}>
      {text}
    </span>,
    align: 'center',
  },
  {
    title: t('forms.estimations.number_pallet'),
    dataIndex: 'number_pallet',
    key: 'number_pallet',
    render: (numbers) => (
      <>
        {
          numbers.map((number, k) => (
            <div className={classnames(style.primary_color, 'bolt-14px')} key={k}>
              {number}
            </div>
          ))
        }
      </>
    ),
    align: 'center',
  },
  {
    title: t('forms.estimations.measures'),
    dataIndex: 'measures',
    key: 'measures',
    render: (measures) => (
      <>
        {measures.map((measure, k) => (
          <div key={k}>{measure}</div>
        ))}
      </>
    ),
    align: 'center',
  },
  {
    title: t('forms.estimations.from_to'),
    dataIndex: 'from_to',
    key: 'from_to',
    render: (text) => <span className="bolt-14px">{text}</span>,
    align: 'center',
  },
  {
    title: t('forms.estimations.status'),
    dataIndex: 'status',
    key: 'status',
    render: (status: EstimationStatuses) => <ReqStatus status={status}/>,
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'settings',
    key: 'settings',
    render: (id) => <SettingOutlined onClick={() => onClick(id)}/>,
    align: 'center',
  },
];
