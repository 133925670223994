import { useRef, useEffect } from 'react';
import io from 'socket.io-client';
import { useAppSelector } from '../store/store';
import { AppConfig } from '../config';

export const useSocket = () => {
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const { current: socket } = useRef(io(`${AppConfig.API_URL}/api/sockets`, {
    extraHeaders: {
      Authorization: accessToken ?? '',
    },
    autoConnect: false,
  }));
  useEffect(() => () => {
    socket.close();
  }, [socket]);

  return socket;
};
