import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Banner from 'components/Banner/Banner';
import ConfirmContainer from 'components/ConfirmContainer';
import Loader from 'components/Loader';
import { useAppDispatch, useAppSelector } from 'store/store';
import { requestShippingGetShippingRequest } from 'store/redux_slice/shipmentSlice';

const BankConfirm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const shipmentRequest = useAppSelector((state) => state.shipmentRequest?.shippingRequest);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(requestShippingGetShippingRequest(id));
    }
  }, [dispatch, id]);

  if (!shipmentRequest) {
    return <Loader/>;
  }

  return (
    <>
      <Banner title={t('banner.shipmentConfirm')} />
      <ConfirmContainer
        title={t('forms.shipmentDetails.confirmation_title')}
        confirmEmail={shipmentRequest?.user.email}
      />
    </>
  );
};

export default BankConfirm;
