import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import InfoContainer from './InfoContainer';
import FiltersWithChartsContainer from './FiltersWithChartsContainer';
import {
  getDashboardPalletsAction, getDashboardArchivedAction,
  getDashboardCollectedAction, getDashboardWarehouseAction,
} from '../../store/redux_slice/dashboardSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import style from './dashboard.module.css';

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    palletsData, archivedData, collctedData, warehouseData,
  } = useAppSelector((state) => state.dashboards);
  const dashboardsPayload = useMemo(() => ({
    start: format(new Date().setDate(new Date().getDate() - 30), 'yyyy-MM-dd'),
    end: format(new Date(), 'yyyy-MM-dd'),
  }), []);

  useEffect(() => {
    dispatch(getDashboardArchivedAction(dashboardsPayload));
    dispatch(getDashboardPalletsAction(dashboardsPayload));
    dispatch(getDashboardCollectedAction(dashboardsPayload));
    dispatch(getDashboardWarehouseAction(dashboardsPayload));
  }, [dispatch, dashboardsPayload]);

  return (
    <div className={style.charts_container}>
      <InfoContainer
        title={t('dashboard.estimatesTitle')}
        count={archivedData?.total || 0}
        chart={<FiltersWithChartsContainer type="archived" />}
      />
      <InfoContainer
        title={t('dashboard.palletTitle')}
        count={palletsData?.total || 0}
        chart={<FiltersWithChartsContainer type="pallet" />}
      />
      <InfoContainer
        title={t('dashboard.deliveriesTitle')}
        count={collctedData?.total || 0}
        chart={<FiltersWithChartsContainer type="collected" />}
      />
      <InfoContainer
        title={t('dashboard.warehouseTitle')}
        count={warehouseData?.total || 0}
        chart={<FiltersWithChartsContainer type="warehouse" />}
      />
    </div>
  );
};

export default Dashboard;
