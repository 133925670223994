import { useTranslation } from 'react-i18next';
import {
  Form, Input, Modal, Select,
} from 'antd';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../store/store';
import { TPStatuses } from '../../../../enums';
import style from '../../../Estimations/estimations.module.css';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { updateStatusAction } from '../../../../store/sagas/shipping/shippingStatusSaga';
import { requestRefusedShippingRequest } from '../../../../store/redux_slice/shipmentSlice';

const ChangeStatusModal: React.FC<{
    visible: boolean,
    onCancel: () => void,
    id: string,
  selectedStatus: string | null,
}> = ({
  visible, onCancel, id, selectedStatus,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue({
        status: TPStatuses.Consegnato,
      });
    }
  }, [form, visible]);
  const onFinish = (values) => {
    if (values.status === TPStatuses.Consegnato || selectedStatus === TPStatuses.Consegnato) {
      dispatch(updateStatusAction({
        id,
        data: {
          status: values.status || selectedStatus,
        },
      }));
    } else {
      dispatch(requestRefusedShippingRequest({ id, reason_annulation: values.description }));
    }
    onCancel();
  };
  return (
    <Modal
      footer={false}
      visible={visible}
      onCancel={onCancel}
      title={t('forms.deliveries.change_status_for_archive')}
    >
      <Form
        key={`${id}-${visible}`}
        form={form}
        onFinish={onFinish}
      > {
          !selectedStatus && <Form.Item name="status">
            <Select>
              <Option key={0} value={TPStatuses.Consegnato}>{TPStatuses.Consegnato}</Option>
              <Option key={1} value={TPStatuses.Annullata}>{TPStatuses.Annullata}</Option>
            </Select>
          </Form.Item>
        }
        <Form.Item
          noStyle={true}
          shouldUpdate={
            (prev, curr) => prev.status !== curr.status
          }
        >
          {({ getFieldValue }) => (getFieldValue('status') === TPStatuses.Annullata || selectedStatus === TPStatuses.Annullata
            ? <Form.Item
              name="description"
              rules={
                [
                  { required: true, message: t('validation.empty') },
                ]
              }
            >
              <Input.TextArea
                placeholder={t('placeholder.reasonAnnulation')}
              />
            </Form.Item>
            : null)
          }
        </Form.Item>
        <CustomButton
          className={style.modal_button}
          type="primary"
          htmlType="submit"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};

export default ChangeStatusModal;
