import { TFunction } from 'react-i18next';
import type { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

interface MonitoringTableColumnsInterface {
    name: string,
    description: string,
    section: string,
    ip: string,
    date: string,
}

export const makeMonitoringTableColumns = (t:TFunction)
    : ColumnsType<MonitoringTableColumnsInterface> => [
  {
    title: t('forms.monitoring.operatorName'),
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: t('forms.monitoring.details'),
    dataIndex: 'description',
    key: 'description',
    align: 'center',
  },
  {
    title: t('forms.monitoring.section'),
    dataIndex: 'section',
    key: 'section',
    align: 'center',
  },
  {
    title: t('forms.monitoring.ip'),
    dataIndex: 'ip',
    key: 'ip',
    align: 'center',
  },
  {
    title: t('forms.monitoring.dateAndTime'),
    dataIndex: 'date',
    key: 'date',
    render: (value) => (value ? formatInTimeZone(new Date(value), 'UTC', 'yyyy-MM-dd HH:mm') : ''),
    align: 'center',
  },
];
