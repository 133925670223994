import classnames from 'classnames';
import { format } from 'date-fns';
import { NotificationStructure } from '../../store/types';
import style from './NotificationItem.module.css';

const NotificationItem = ({
  notification,
  emitNotificationHandler,
  onClick,
}: {
  notification: NotificationStructure,
  emitNotificationHandler: (id: number) => void,
  onClick: (shippingId: string, isQrCode:boolean) => void,
}) => {
  const {
    id, type, date, title, body, shipment_id: shipmentId,
  } = notification;

  const onClickHandler = () => {
    if (type === 'In pending') {
      emitNotificationHandler(id);
    }
    onClick(shipmentId, title.includes('Le etichette'));
  };
  return (
    <div
      onClick={onClickHandler}
      className={classnames(style.notificationItemContainer, {
        [style.activeNotification]: type === 'In pending',
      })}
    >
      <p className={style.timeDate}>
        <span>{ format(new Date(date), 'dd-MM-yyy') }</span> | {' '}
        <span>{ format(new Date(date), 'HH:mm') }</span>
      </p>
      <p className={style.text}>{ title.includes('Le etichette') ? title : body }</p>
    </div>
  );
};

export default NotificationItem;
