import React, { useEffect, useState } from 'react';
import {
  Form, FormInstance, InputNumber, Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/CustomButton/CustomButton';
import style from '../estimations.module.css';

export const ChangeFasciModal: React.FC<{
    visible: boolean,
    onCancel: () => void,
    onChange: (v: number) => void,
    initialValue: number
    maxValue: number,
    form?: FormInstance,
}> = ({
  visible,
  onChange,
  onCancel,
  initialValue,
  maxValue,
  form,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number | null>(initialValue);

  useEffect(() => {
    if (visible) {
      setValue(initialValue);
      form?.setFieldsValue({
        value: initialValue,
      });
    }
  }, [form, initialValue, visible]);

  const onChangeHandler = (v: number) => {
    if (v > maxValue) {
      setValue(null);
    } else {
      setValue(+v);
    }
  };

  const submit = () => {
    if (value) {
      onChange(value);
      onCancel();
    }
  };

  const onCancelHandler = () => {
    onCancel();
    form?.setFieldsValue({
      value: '',
    });
    setValue(null);
  };

  return (
    <Modal title={t('forms.estimations.change_fasci')}
      visible={visible}
      onOk={submit}
      onCancel={onCancelHandler}
      footer={false}
    >
      <Form form={form}
        name="fasci"
      >
        <Form.Item
          name="value"
          rules={[
            () => ({
              validator(rule, formValue) {
                if (formValue > maxValue) {
                  return Promise.reject(new Error(t('forms.estimations.fasci_validation')));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            min={0}
            onChange={onChangeHandler}
            className={style.input_modal}
          />
        </Form.Item>
      </Form>
      <p className={style.fasci_text_styles}>{t('forms.estimations.text_fasci')}</p>
      <CustomButton
        className={style.modal_button}
        type="primary"
        onClick={submit}
      >
        {t('forms.estimations.update')}
      </CustomButton>
    </Modal>
  );
};
