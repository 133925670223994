import {
  Routes, Route, matchRoutes, useLocation, Navigate,
} from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { RoleEnums, PathEnums } from 'enums';
import { routes } from '.';

const ALL_ADMIN_ROLES = [
  RoleEnums.OPERATOR, RoleEnums.BILLING_OPERATOR,
  RoleEnums.ESTIMATING_OPERATOR, RoleEnums.ADMIN, RoleEnums.SUPER_ADMIN];

const CustomRoute = () => {
  const role = useAppSelector((state) => state.auth.tokenStructure?.role);
  const location = useLocation();
  const match = matchRoutes(routes, location);
  const route = match && match[0].route;

  if ((!role && route?.role.length && !route.role.includes(RoleEnums.GUEST))
    || (role && route?.role.length && !route.role.includes(role) && !route.role.includes(RoleEnums.GUEST))) {
    return <Navigate to="/"/>;
  }

  if (!route?.role.length && role && ALL_ADMIN_ROLES.includes(role)) {
    return <Navigate to={PathEnums.Deliveries}/>;
  }

  if (!route?.role.length && role === RoleEnums.USER) {
    return <Navigate to={PathEnums.ShipmentList}/>;
  }

  if (route?.element) {
    return (
      <Routes>
        <Route path={route.path} element={route?.element} />
      </Routes>
    );
  }
  return null;
};

export default CustomRoute;
