import { put, takeLatest } from 'redux-saga/effects';
import { setLoading, setPriceListById, requestUserPriceListById } from '../../redux_slice/pricelists/userPriceListByIdSlice';
import { GetUserPriceListById } from '../../../api/price';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* getUserPriceListByIdSaga(action: {
    payload: number
}) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetUserPriceListById(action.payload);
    yield put(setPriceListById(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* userPriceListByIdSaga() {
  yield takeLatest(requestUserPriceListById, getUserPriceListByIdSaga);
}
