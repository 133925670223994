import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import OptionsButton, { Option } from '../../../components/OptionsButton';
import { ContractorType, ShipmentStructure } from '../../../store/types';
import { makeColumnsApproveArchive } from '../components/TableColumns';

const ArchivedTable = ({
  options,
  archivedShipments,
  page,
  count,
  setPage,
  setCount,
  total,
  pallexOptions,
  onChange,
  pallexItaly,
}
  : {
    options: Array<Option>,
    archivedShipments: ShipmentStructure[],
    page: number,
    count: number,
    setPage: (number) => void,
    setCount: (number) => void,
    total: number,
    pallexOptions: Array<Option>,
    onChange: (status, shipment) => void,
    pallexItaly: ContractorType,
  }) => {
  const { t } = useTranslation();
  const onChangePageSize = (current, size) => {
    setCount(size);
  };
  const pagination = {
    current: page,
    pageSize: count,
    pageSizeOptions: [10, 15, 20],
    showSizeChanger: true,
    total,
    onChange: setPage,
    onShowSizeChange: onChangePageSize,
  };
  const data = archivedShipments
    .map((shipment) => {
      const {
        id,
        pallets,
        withdrawal_date: withdrawalDate,
        start_shipment_date: startDate,
        contractor,
        user,
        user_id: userId,
      } = shipment;
      const contractorIsPallex = contractor[0]?.ResContract.contractor_id === pallexItaly?.id;
      return {
        key: id,
        id: shipment.spedizione_id || shipment.id,
        number_pallet: pallets.map((item) => item.item_quantity),
        pickupName: shipment.pickup_address?.company_name || shipment.pickup_address?.name_and_surname_reference || '-',
        shippingName: shipment.shipping_address?.company_name || shipment.shipping_address?.name_and_surname_reference || '-',
        approved_date: shipment.approvato_il,
        recipient_name: user?.name || shipment.pallex_client_name || '-',
        withdrawal_date: withdrawalDate || '',
        start_date: startDate || '',
        status: { shipment, applicant: shipment.user_id ? t('forms.deliveries.customer') : 'Pallex', contractorIsPallex },
        price: shipment.shipping_estimate ? `€ ${shipment.shipping_estimate.price.toFixed(2)}` : '-',
        options: (
          <OptionsButton
            id={id.toString()}
            userId={userId?.toString()}
            options={shipment.user_id ? options : pallexOptions}/>
        ),
      };
    });
  const columns = makeColumnsApproveArchive(t, onChange);
  return (
    <Table columns={columns} pagination={pagination} dataSource={data}/>
  );
};

export default ArchivedTable;
