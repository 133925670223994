import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { TransportStructure } from '../../types';

export const requestGetTransportByDriverId = createAction<string>('requestGetTransportByDriverId');

type State = {
    transportByDriverId: TransportStructure | null
    loading: boolean,
}
const initialState: State = {
  transportByDriverId: null,
  loading: false,
};

const transportByDriverIdSlice = createSlice({
  name: 'transportByDriverId',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTransportByDriverId: (state, action: PayloadAction<TransportStructure>) => {
      state.transportByDriverId = action.payload;
    },
    clearTransportByDriverId: (state) => {
      state.transportByDriverId = null;
    },
  },
});

export const { setTransportByDriverId, setLoading, clearTransportByDriverId } = transportByDriverIdSlice.actions;

export default transportByDriverIdSlice.reducer;
