import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Menu, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import phoneIcon from '../../assests/images/phoneIcon.png';
import emailIcon from '../../assests/images/emailIcon.png';
import userIcon from '../../assests/images/userIcon.png';
import { PathEnums } from '../../enums';

import style from './styles/defaultHeader.module.css';
import logo from '../../assests/images/logo.png';

const DefaultHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [, setCurrent] = useState(`${PathEnums.Create_estimation}`);
  const onClick: MenuProps['onClick'] = (e) => setCurrent(e.key);
  return (<header className={style.header}>
    <div className={style.info}>
      <div className={style.contacts}>
        <div className={style.phone}>
          <img src={phoneIcon} alt="phone" />
          <div className={style.phone_number}>
            <a href="tel:+390375758900">+39 0375 758900</a>
          </div>
        </div>
        <div className={style.email}>
          <img src={emailIcon} alt="email" />
          <div className={style.email_address}>
            <a href="mailto:info@pesantisrl.it">info@pesantisrl.it</a>
          </div>
        </div>
      </div>
      <div className={style.subinfo}>
        <Link to="/" className={style.account}>
          <img src={userIcon} alt="user" />
          <div>{t('menu.signIn')}</div>
        </Link>
        <div className={style.language}>ITA</div>
      </div>
    </div>
    <div className={style.menu}>
      <a href={'https://www.pesantisrl.it/'}>
        <img src={logo} alt="logo" className={style.logo}/>
      </a>
      <Menu mode="horizontal"
        onClick={onClick}
        selectedKeys={[location.pathname]}
        className={style.menu}
      >
        <Menu.Item key={PathEnums.Create_estimation} className={style.menu_item}>
          <Link to={PathEnums.Create_estimation}>
            {t('menu.shippingEstimates')}
          </Link>
        </Menu.Item>
        <Menu.Item key={PathEnums.Tracking} className={style.menu_item}>
          <Link to={PathEnums.Tracking}>
            {t('menu.trackingShipments')}
          </Link>
        </Menu.Item>
        <Menu.Item key={PathEnums.PrivacyPolicy} className={style.menu_item}>
          <Link to={PathEnums.PrivacyPolicy}>
            {t('menu.privacyPolicy')}
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  </header>
  );
};

export default DefaultHeader;
