import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { Estimation, ApproveEstimaptionPayload } from '../types';

export const requestEstimation = createAction<Estimation>('requestEstimation');
export const requestClearEstimation = createAction('requestClearEstimation');
export const requestApproveEstimation = createAction<ApproveEstimaptionPayload>('requestApproveEstimation');
export const requestClearSentShippingRequest = createAction('requestClearSentShippingRequest');

type State = {
  estimation: Estimation | null
  loading: boolean,
  sent: boolean,
}
const initialState : State = {
  estimation: null,
  loading: false,
  sent: false,
};

const estimationSlice = createSlice({
  name: 'estimation',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    clearEstimation: (state) => {
      state.estimation = null;
    },
    setEstimation: (state, action: PayloadAction<Estimation>) => {
      state.estimation = action.payload;
    },
    setSentShippingRequest: (state, action: PayloadAction<boolean>) => {
      state.sent = action.payload;
    },
  },
});

export const {
  setEstimation, clearEstimation, setLoading, setSentShippingRequest,
} = estimationSlice.actions;

export default estimationSlice.reducer;
