export const getToken = () => localStorage.getItem('token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const setToken = (token?: string) => {
  if (token) {
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
  }
};

export const setRefreshToken = (token) => {
  localStorage.setItem('refresh_token', token);
};
