import { put, takeLatest } from 'redux-saga/effects';
import { GetPallexHubs } from '../../api';
import { setLoading, setPallexHubs, requestGetPallexHubs } from '../redux_slice/pallexHubsSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';

function* getPallexHubsSaga() {
  yield put(setLoading(true));
  try {
    const { data } = yield GetPallexHubs();
    yield put(setPallexHubs(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* pallexHubsSaga() {
  yield takeLatest(requestGetPallexHubs, getPallexHubsSaga);
}
