import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransportStructure } from '../../types';

export const requestTransport = createAction('requestTransport');

type State = {
  transport: TransportStructure[] | null,
  loading: boolean,
}

const initialState: State = {
  transport: null,
  loading: false,
};

const transportSlice = createSlice({
  name: 'transport',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTransportData: (state, action: PayloadAction<TransportStructure[] | null>) => {
      state.transport = action.payload;
    },
  },
});

export const { setTransportData, setLoading } = transportSlice.actions;

export default transportSlice.reducer;
