import { useEffect } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import TrackingContent from '../../../../components/TrackingContent';
import Loader from '../../../../components/Loader';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import './trackingModal.css';
import { requestShippingGetShippingRequest } from '../../../../store/redux_slice/shipmentSlice';

type Props = {
  visible: boolean,
  setVisibleTrackingModal: (bool: boolean) => void,
}

const TrackingModal = ({ visible, setVisibleTrackingModal }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackingLoading, trackingStatus } = useAppSelector((state) => state.shipmentTrackingStatus);
  const { shippingRequest, loading } = useAppSelector((state) => state.shipmentRequest);

  useEffect(() => {
    if (trackingStatus?.shipping_id) {
      dispatch(requestShippingGetShippingRequest(trackingStatus.shipping_id.toString()));
    }
  }, [dispatch, trackingStatus?.shipping_id]);

  return (
    <Modal
      className="tracking_modal_container"
      width={'70%'}
      visible={visible}
      onCancel={() => setVisibleTrackingModal(false)}
      footer={false}
    >
      { trackingLoading && loading ? <Loader/>
        : (<>
          <h2 className="modal_trackint_title">
            {`${t('shipmentList.modalTracking')} #${trackingStatus?.shipping_id ?? '--'}`}
          </h2>
          {
            shippingRequest?.tracking && shippingRequest.tracking === 'NonDisponsible'
              ? <div className="not_tracking_text">
                {t('shipmentList.notTrackingText')} {' '}
                <a href="mailto:commercialeplt@pesantisrl.it">commercialeplt@pesantisrl.it</a>
              </div>
              : <TrackingContent trackingStatus={trackingStatus} />
          }
        </>
        )}
    </Modal>
  );
};

export default TrackingModal;
