import { useTranslation } from 'react-i18next';
import {
  DatePicker, Form, Input, Modal,
} from 'antd';
import React, { useEffect } from 'react';
import style from '../Estimations/estimations.module.css';
import CustomButton from '../../components/CustomButton/CustomButton';
import { requestUpdateTransportById } from '../../store/redux_slice/transport/transportByIdSlice';
import { useAppDispatch } from '../../store/store';

const UnavailableModal:React.FC<{
  visible: boolean,
  onCancel: () => void,
  selectId: string,
}> = ({ visible, onCancel, selectId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const onMakeUnavailable = (values) => {
    const updateData = {
      id: selectId,
      data: {
        busy: true,
        booking_date: values?.available_days,
      },
    };
    onCancel();
    dispatch(requestUpdateTransportById(updateData));
  };
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        available_days: '',
      });
    }
  }, [form, visible]);
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        form={form}
        name="available_form"
        layout="vertical"
        onFinish={onMakeUnavailable}
      >
        <Form.Item
          name="available_days"
          label={t('forms.transports.available_in')}
        >
          <DatePicker placeholder={t('forms.transports.number_days')}
            disabledDate={(current) => (
              current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
            )}
            className={style.calendar_height}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <CustomButton
          className={style.modal_button}
          type="primary"
          htmlType="submit"
        >
          {t('forms.estimations.update')}
        </CustomButton>
      </Form>
    </Modal>
  );
};

export default UnavailableModal;
