import { put, takeEvery } from 'redux-saga/effects';
import { GetFinancePalletEstimation } from 'api';
import {
  GetFinancelist, GetFinanceLastPush, PostFinanceApprove, PostFinanceDisapprove, PatchOrderFinanceById,
  GetFinanceExtralist, PostFinanceExtra, PatchFinanceExtralist, DeleteFinanceExtra,
  PostExtraFinanceApprove, PostExtraFinanceDisapprove, PostFinanceDefaultUser,
  PostFinanceCalculate, PostSendInvoice, GetFinanceUsers, DeleteFinance,
} from 'api/finance';
import {
  getFinanceListAction, setFinanceListLoading, setFinanceList,
  postFinanceApproveAction, postFinanceDisapproveAction,
  setOrderAfterApproveOrDisapprove, patchFinanceOrderByIdAction,
  setExtraFinanceList, postExtraFinanceAction, patchExtraFinanceAction,
  postExtraFinanceApproveAction, postExtraFinanceDisapproveAction,
  setExtraFinanceAfterApproveOrDisapprove, setExtraFinanceModalLoading,
  addNewExtraFinance, editExtraFinance, postFinanceDefaultUserAction,
  updateFinanceList, setUpdateDetailsLoading, deleteExtraFinanceAction, deleteExtraFinance,
  postSendInvoiceAction, setFinanceDetails, getFinanceWithExtraListAction,
  getFinanceLastPushAction, setLastPushData, setFinanceUsers, getFinanceUsersAction,
  deleteFinanceAction, postCalculateFinanceAction,
} from '../redux_slice/financeSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';
import {
  FinanceListPayloadType, FinanceOrderByIdPayloadType, ExtraFinanceType,
  ExtraServicePatchPayload, ConfirmDefaultUserType, ConfirmCreationInvoiceType,
  FinanceUsersPayloadType, DeleteFinanceType, FinanceSectionType,
} from '../types';
import { DeliveryType } from 'enums';

function* getFinanceListSaga(action: {
  payload: FinanceListPayloadType
}) {
  yield put(setFinanceListLoading(true));
  try {
    const { data } = yield GetFinancelist(action.payload);
    yield put(setFinanceList(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setFinanceListLoading(false));
}

function* getFinanceLastPushSaga() {
  try {
    const { data } = yield GetFinanceLastPush();
    yield put(setLastPushData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* getFinanceWithExtraListSaga(action: {
  payload: FinanceListPayloadType
}) {
  yield put(setFinanceListLoading(true));
  try {
    const { data } = yield GetFinancelist(action.payload);
    const { data: extraData } = yield GetFinanceExtralist(action.payload);
    yield put(setFinanceList(data));
    yield put(setExtraFinanceList(extraData));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
    yield put(setFinanceList(null));
    yield put(setExtraFinanceList(null));
  }
  yield put(setFinanceListLoading(false));
}

function* postFinanceApproveSaga(action: {
  payload: number
}) {
  try {
    const { data } = yield PostFinanceApprove(action.payload);
    yield put(setOrderAfterApproveOrDisapprove(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* postFinanceDisapproveSaga(action: {
  payload: number
}) {
  try {
    const { data } = yield PostFinanceDisapprove(action.payload);
    yield put(setOrderAfterApproveOrDisapprove(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* postCalculateFinanceSaga(action: {
  payload: FinanceSectionType,
}) {
  yield put(setUpdateDetailsLoading(true));
  try {
    const { data: palletsData } = yield GetFinancePalletEstimation({
      price_list_id: action.payload.request.price_list_id,
      province: action.payload.type === DeliveryType.CONSEGNA || action.payload.type === DeliveryType.TRIANG
        ? action.payload.request.shipping_address.location.province
        : action.payload.request.pickup_address.location.province,
      region: action.payload.type === DeliveryType.CONSEGNA || action.payload.type === DeliveryType.TRIANG
        ? action.payload.request.shipping_address.location.region
        : action.payload.request.pickup_address.location.region,
      items: action.payload.pallets,
      options: action.payload.options,
    });
    yield PatchOrderFinanceById({
      ...action.payload,
      pallets: palletsData,
    });
    const { data } = yield PostFinanceCalculate(action.payload.id);
    yield put(setFinanceDetails({
      ...data,
      pallets: palletsData,
    }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setUpdateDetailsLoading(false));
}

function* patchFinanceOrderByIdSaga(action: {
  payload: FinanceOrderByIdPayloadType
}) {
  yield put(setUpdateDetailsLoading(true));
  try {
    const {
      date_from: dateFrom, date_to: dateTo, updateTable, ...rest
    } = action.payload;
    const { data } = yield PatchOrderFinanceById({
      id: action.payload.id,
      ...action.payload.data,
    });
    yield put(updateFinanceList(data));
    if (updateTable) {
      yield put(setFinanceDetails(null));
      yield put(getFinanceListAction({
        date_from: dateFrom,
        date_to: dateTo,
        user_id: rest.user_id,
      }));
    }
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setUpdateDetailsLoading(false));
}

function* postExtraFinanceSaga(action: {
  payload: ExtraFinanceType
}) {
  yield put(setExtraFinanceModalLoading(true));
  try {
    const { data } = yield PostFinanceExtra(action.payload);
    yield put(addNewExtraFinance(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setExtraFinanceModalLoading(false));
}

function* patchExtraFinanceSaga(action: {
  payload: ExtraServicePatchPayload
}) {
  yield put(setExtraFinanceModalLoading(true));
  try {
    const { data } = yield PatchFinanceExtralist(action.payload);
    yield put(editExtraFinance(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setExtraFinanceModalLoading(false));
}

function* deleteExtraFinanceSaga(action: {
  payload: number
}) {
  try {
    yield DeleteFinanceExtra(action.payload);
    yield put(deleteExtraFinance(action.payload));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* postExtraFinanceApproveSaga(action: {
  payload: number
}) {
  try {
    const { data } = yield PostExtraFinanceApprove(action.payload);
    yield put(setExtraFinanceAfterApproveOrDisapprove(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* postExtraFinanceDisapproveSaga(action: {
  payload: number
}) {
  try {
    const { data } = yield PostExtraFinanceDisapprove(action.payload);
    yield put(setExtraFinanceAfterApproveOrDisapprove(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* postFinanceDefaultUserSaga(action: {
  payload: ConfirmDefaultUserType
}) {
  try {
    const { date_from: dateFrom, date_to: dateTo, ...rest } = action.payload;
    yield PostFinanceDefaultUser(rest);
    yield put(getFinanceListAction({
      date_from: dateFrom,
      date_to: dateTo,
      user_id: rest.filter_user_id,
    }));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* postSendInvoiceSaga(action: {
  payload: ConfirmCreationInvoiceType
}) {
  yield put(setFinanceListLoading(true));
  try {
    const {
      date_from: dateFrom, date_to: dateTo, user_id: userId, ...rest
    } = action.payload;
    yield PostSendInvoice(rest);
    yield put(getFinanceWithExtraListAction({
      date_from: dateFrom,
      date_to: dateTo,
      user_id: userId,
    }));
    yield put(getFinanceLastPushAction());
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setFinanceListLoading(false));
}

function* getFinanceUsersSaga(action: {
  payload: FinanceUsersPayloadType
}) {
  try {
    const { data } = yield GetFinanceUsers(action.payload);
    yield put(setFinanceUsers(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
}

function* deleteFinanceSaga(action: {
  payload: DeleteFinanceType
}) {
  yield put(setFinanceListLoading(true));
  try {
    const { id, ...rest } = action.payload;
    yield DeleteFinance(id);
    yield put(getFinanceWithExtraListAction(rest));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setFinanceListLoading(false));
}

export function* financeSectionSaga() {
  yield takeEvery(getFinanceListAction, getFinanceListSaga);
  yield takeEvery(getFinanceLastPushAction, getFinanceLastPushSaga);
  yield takeEvery(getFinanceWithExtraListAction, getFinanceWithExtraListSaga);
  yield takeEvery(postFinanceApproveAction, postFinanceApproveSaga);
  yield takeEvery(postFinanceDisapproveAction, postFinanceDisapproveSaga);
  yield takeEvery(postCalculateFinanceAction, postCalculateFinanceSaga);
  yield takeEvery(patchFinanceOrderByIdAction, patchFinanceOrderByIdSaga);
  yield takeEvery(postExtraFinanceAction, postExtraFinanceSaga);
  yield takeEvery(patchExtraFinanceAction, patchExtraFinanceSaga);
  yield takeEvery(deleteExtraFinanceAction, deleteExtraFinanceSaga);
  yield takeEvery(postExtraFinanceApproveAction, postExtraFinanceApproveSaga);
  yield takeEvery(postExtraFinanceDisapproveAction, postExtraFinanceDisapproveSaga);
  yield takeEvery(postFinanceDefaultUserAction, postFinanceDefaultUserSaga);
  yield takeEvery(postSendInvoiceAction, postSendInvoiceSaga);
  yield takeEvery(getFinanceUsersAction, getFinanceUsersSaga);
  yield takeEvery(deleteFinanceAction, deleteFinanceSaga);
}
