import { Modal } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import CustomButton from '../CustomButton/CustomButton';
import styles from './confirm.module.css';

type ConfirmModalType = {
  content: {
    title: string,
    text: string,
  } | null,
  visible: boolean,
  onCancel: () => void,
  onConfirm: () => void,
}
const ConfirmModal = ({
  content, visible, onCancel, onConfirm,
}: ConfirmModalType) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={false}
      className={styles.modal_container}
    >
      <h3 className={styles.title}>{content?.title}</h3>
      <p className={styles.text}>{content?.text}</p>
      <div className={styles.btn_container}>
        <CustomButton
          type="primary"
          className={cn(styles.btn_styles, 'defaultBtn')}
          onClick={onCancel}
        >
          {t('buttons.cancel')}
        </CustomButton>
        <CustomButton
          type="primary"
          className={styles.btn_styles}
          onClick={onConfirm}
        >
          {t('buttons.confirm')}
        </CustomButton>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
