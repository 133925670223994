import { useMemo } from 'react';
import { EstimationFieldModal, useModalState } from 'containers/Estimations/hooks';
import { ChangeExtraServicesModalView } from 'containers/Estimations/FieldModals/ChangeExtraServicesModal';
import EditableTable from '../index';
import { ExtraServiceStructure } from 'store/types';
import { EditableDetailsTableTypes, DeliveryType } from 'enums';
import { triangServices } from 'pesantiConstants';

type PrivateAreaOptionsTableProps = {
  dataArray: ExtraServiceStructure[],
  updateOptionHandler?: (data) => void,
  editable?: boolean,
}

const PrivateAreaOptionsTable = ({
  dataArray, updateOptionHandler, editable = true,
}: PrivateAreaOptionsTableProps) => {
  const {
    openModal,
    isOpen,
    closeModal,
  } = useModalState();

  const newTableData = useMemo(() => (
    dataArray.filter((i) => i.isActive).map((sourceItem) => ({
      key: sourceItem?.title?.toString() || '',
      title: sourceItem.title,
      description: sourceItem.description,
      requested_to: sourceItem.triangolize === DeliveryType.BOTH ? `${DeliveryType.RITIRO}/${DeliveryType.CONSEGNA}` : sourceItem.triangolize || '-',
    }))
  ), [dataArray]);

  const parceSavedData = (row, key: string) => {
    let newDataArr:ExtraServiceStructure[] = [];
    newDataArr = dataArray.map((item) => (
      item.title?.toUpperCase() === key.toString().toUpperCase() ? ({
        ...item,
        title: row.title,
        description: row.description,
        triangolize: row.requested_to === `${DeliveryType.RITIRO}/${DeliveryType.CONSEGNA}` ? DeliveryType.BOTH : row.requested_to,
        values: triangServices.includes(item.title.toLocaleUpperCase())
          && item.triangolize !== row.requested_to ? { ritiro: null, consegna: null } : item.values,
      }) : item
    ));
    if (updateOptionHandler) {
      updateOptionHandler({ [EditableDetailsTableTypes.private_area_options]: newDataArr });
    }
    return newDataArr;
  };

  const deleteHandler = (key:string) => {
    if (updateOptionHandler) {
      const data = {
        [EditableDetailsTableTypes.private_area_options]: dataArray.map((option) => (
          option?.title?.toUpperCase() === key?.toUpperCase() ? ({
            ...option,
            isActive: false,
            values: { ritiro: null, consegna: null },
            triangolize: null,
          }) : option
        )),
      };
      updateOptionHandler(data);
    }
  };

  return (
    <>
      <EditableTable
        tableType={EditableDetailsTableTypes.private_area_options}
        dataSource={newTableData}
        parceSavedData={parceSavedData}
        openModal={() => openModal(EstimationFieldModal.openExtraServices)}
        deleteHandler={deleteHandler}
        editable={editable}
      />
      { updateOptionHandler && (
        <ChangeExtraServicesModalView
          visible={isOpen(EstimationFieldModal.openExtraServices)}
          onCancel={() => closeModal()}
          orderOptions={dataArray}
          onAddOptionHandler={updateOptionHandler}
        />
      )}
    </>
  );
};

export default PrivateAreaOptionsTable;
