import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';

type FinanceExtraInterface = {
  isApproved: boolean,
  orderId: string,
  approvato_il: string,
  description: string,
  quantity: number,
  price: number,
  approveOrDisapprove: JSX.Element,
  options: JSX.Element,
}

export const makeColumnsFinanceExtra = (t:TFunction)
    : ColumnsType<FinanceExtraInterface> => [
  {
    title: t('invoices.table.orderId'),
    dataIndex: 'orderId',
    key: 'orderId',
  },
  {
    title: t('invoices.table.approvato_il'),
    dataIndex: 'approvato_il',
    key: 'approvato_il',
  },
  {
    title: t('invoices.table.description'),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: t('invoices.table.quantity'),
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: t('invoices.table.unit_cost'),
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: '',
    dataIndex: 'approveOrDisapprove',
    key: 'approveOrDisapprove',
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
  },
];
