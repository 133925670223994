import { put, takeEvery } from 'redux-saga/effects';
import {
  requestGetServicesByPriceList,
  setLoading,
  setServicesByPriceListData,
} from '../redux_slice/extraByPriceListSlice';
import { setMessage } from '../redux_slice/messageErrorSlice';
import { GetPriceList } from '../../api';

function* getExtraServicesByPriceListSaga(action) {
  yield put(setLoading(true));
  try {
    const { data } = yield GetPriceList(action.payload);
    yield put(setServicesByPriceListData(data.extra_services));
  } catch (e) {
    yield put(setServicesByPriceListData([]));
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* extraServicesByPriceListSaga() {
  yield takeEvery(requestGetServicesByPriceList, getExtraServicesByPriceListSaga);
}
