import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TPStatuses, EstimationStatuses, PallexStatuses } from 'enums';

import style from './status.module.css';

interface StatusProps {
  status: string | null,
  className?: string | undefined
}

const Status = ({ status, className }: StatusProps) => {
  const { t } = useTranslation();
  return (
    <div className={classnames(style.status, {
      [style.blueStatus]: status === 'In attesa di data'
            || status === t('shipmentList.administrator')
            || status === TPStatuses.In_attesa_di_ritiro
            || status === t('shipmentList.role')
            || status === t('roles.operator')
            || status === t('roles.billing_operator')
            || status === t('roles.estimating_operator')
            || status === t('roles.super_administrator')
            || status === t('roles.driver')
            || status === TPStatuses.In_attesa_di_approvazione
            || status === EstimationStatuses.PREVENTIVO_CONFERMATO,
      [style.blackStatus]: status === TPStatuses.Gestito_da_TP,
      [style.greenStatus]: status === TPStatuses.Consegnato
            || status === t('shipmentList.customer'),
      [style.peachStatus]: status === TPStatuses.In_magazzino
            || status === TPStatuses.Ritirato
            || status === TPStatuses.In_consegna
            || status === PallexStatuses.In_transito
            || status === PallexStatuses.Ritiro_effettuato
            || status === t('shipmentList.lead')
            || status === PallexStatuses.Presso_HUB
            || status === TPStatuses.In_attesa_del_cliente
            || status === TPStatuses.In_attesa_di_pagamento
            || status === TPStatuses.Da_confermare
            || status === EstimationStatuses.IN_ELABORAZIONE
            || status === EstimationStatuses.CONFERMA_PREVENTIVO,
      [style.pinkStatus]: status === 'Annullata',
    }, className)}>{status}</div>
  );
};

export default Status;
