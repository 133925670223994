import { instance } from './index';
import { TransportPayload } from '../store/types';

export const GetTransportList = () => instance.get('/transport/truck');

export const GetTransportListScarico = () => instance.get('/transport/trucks-scarico');

export const GetTransportTypes = () => instance.get('/transport/truck_type');

export const CreateNewTransport = (data: TransportPayload) => instance.post('/transport/truck', data);

export const GetTransportById = (id: string) => instance.get(`/transport/${id}/truck`);

export const UpdateTransportById = ({ id, ...data }) => instance.patch(`/transport/${id}/truck`, data.data);

export const DeleteTransportById = (id: string) => instance.delete(`/transport/${id}/truck`);

export const GetTransportType = (id: string) => instance.get(`/transport/${id}/truck_type`);

export const CreateTransportType = (data) => instance.post('/transport/truck_type', data);

export const UpdateTransportType = ({ id, ...data }) => instance.patch(`/transport/${id}/truck_type`, data.data);

export const DeleteTransportType = (id: string) => instance.delete(`/transport/${id}/truck_type`);

export const GetTransportByDriverId = (id: string) => instance.get(`/transport/${id}/truck-by-driver`);
