import { useEffect, useMemo } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import DetailsHeadInfo from '../components/DetailsHeadInfo';
import Loader from 'components/Loader';
import { PrivateAreaPalletsTable, PrivateAreaOptionsTable, MerceAdrTable } from 'components/EditableTable/Tables';
import { NoteContainer, AdditionalTextContainer } from 'components/EditableTextContainer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getPrivatAreaShippingDetailsAction, clearPrivateAreaShippingInfo } from 'store/redux_slice/privateAreaSlice';
import { PathEnums } from 'enums';
import { ADR } from 'pesantiConstants';
import style from './newShipmentDetails.module.css';

const PrivateAreaShipmentDetails = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    creationLoading, shippingInfoLoading, privateAreaShippingInfo, redirectFromDetails,
  } = useAppSelector((store) => store.privateArea);
  const visibleOptions = privateAreaShippingInfo?.options?.filter((service) => !service.isAdmin);

  const hasMerceAdr = useMemo(() => (
    privateAreaShippingInfo?.options?.some((o) => (
      (o.isActive && o.title?.toUpperCase() === ADR.toUpperCase())
    ))
  ), [privateAreaShippingInfo?.options]);

  useEffect(() => {
    if (id) {
      dispatch(getPrivatAreaShippingDetailsAction(id));
    }
    return () => {
      dispatch(clearPrivateAreaShippingInfo());
    };
  }, [id, dispatch]);

  if (redirectFromDetails) {
    return <Navigate to={PathEnums.ShipmentList}/>;
  }

  if (creationLoading || shippingInfoLoading || !privateAreaShippingInfo) {
    return <Loader/>;
  }

  return (
    <div className={style.details_container}>
      <DetailsHeadInfo shippingInfo={privateAreaShippingInfo} />
      <PrivateAreaPalletsTable
        dataArray={privateAreaShippingInfo?.pallets}
        editable={false}
      />
      { hasMerceAdr && (
        <MerceAdrTable
          dataArray={privateAreaShippingInfo.merce_adr || null}
          editable={false}
        />
      )}
      <PrivateAreaOptionsTable
        dataArray={visibleOptions || []}
        editable={false}
      />
      <AdditionalTextContainer
        additionalText={privateAreaShippingInfo.informazioni_aggiuntive || ''}
        isEdit={false}
      />
      <NoteContainer
        noteRitiro={privateAreaShippingInfo.note_ritiro || privateAreaShippingInfo.note}
        noteConsegna={privateAreaShippingInfo.note_consegna || privateAreaShippingInfo.note}
        ritiroTime={privateAreaShippingInfo.start_shipment_time || ''}
        deliveryType={privateAreaShippingInfo.delivery_type}
        isEdit={false}
      />
    </div>
  );
};

export default PrivateAreaShipmentDetails;
