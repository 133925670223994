import { TFunction } from 'react-i18next';
import type { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import Status from 'components/Status';
import styles from './operatorsList.module.css';

interface OperatorListTableColumnsInterface {
    creation_date: string,
    email: string,
    phone: string,
    name: string,
    status: string,
    options: JSX.Element
}

export const operatorListTableColumns = (t: TFunction):
    ColumnsType<OperatorListTableColumnsInterface> => [
  {
    title: t('operatorsList.creation_date'),
    dataIndex: 'creation_date',
    key: 'creation_date',
    render: (value) => format(new Date(value), 'dd-MM-yyyy'),
    sorter: (a, b) => new Date(a.creation_date).getTime() - new Date(b.creation_date).getTime(),
    align: 'center',
  },
  {
    title: t('operatorsList.email'),
    dataIndex: 'email',
    key: 'email',
    align: 'center',
  },
  {
    title: t('operatorsList.phone'),
    dataIndex: 'phone',
    key: 'phone',
    align: 'center',
  },
  {
    title: t('operatorsList.name'),
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: t('operatorsList.status'),
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    render: (status) => <Status status={status} />,
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    align: 'center',
  },
];
