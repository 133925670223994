import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Col, Modal, Row, Space, Tag,
} from 'antd';
import React, { useEffect } from 'react';
import classnames from 'classnames';
import { DeleteFilled, SettingOutlined } from '@ant-design/icons';
import style from './users.module.css';
import Status from '../../components/Status';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { requestGetUser } from '../../store/redux_slice/users/userSlice';
import { UserItem } from '../../store/types';

const UserProfile:React.FC<{
  visible: boolean,
  typology: string,
  onCancel: () => void,
  setDeleteId: (id: string) => void,
  setIsDeleteModal: (bool: boolean) => void,
  setIsUserForm: (bool: boolean) => void,
  usersCompanyList: UserItem[],
}> = ({
  visible, onCancel, setIsDeleteModal, setDeleteId, setIsUserForm, typology, usersCompanyList,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const user = useAppSelector((state) => state.user?.user);
  const priceListTemplates = useAppSelector((state) => state.priceListTemplates?.priceListTemplates);
  const userPriceList = priceListTemplates.find((el) => el.id === user?.price_list_id);
  const selectedUser = usersCompanyList.find((el) => el.id === user?.pallex_company_id);

  useEffect(() => {
    const userId = searchParams.get('utente_id');
    if (userId) {
      dispatch(requestGetUser(userId));
    }
  }, [searchParams, dispatch]);

  const openDeleteModal = () => {
    setIsDeleteModal(true);
    if (user?.id) {
      setDeleteId(user.id.toString());
    }
  };
  const openUpdateUserForm = () => {
    onCancel();
    setIsUserForm(true);
    if (user) {
      dispatch(requestGetUser(user.id.toString()));
    }
  };
  return (
    <Modal
      centered
      visible={visible}
      footer={false}
      onCancel={onCancel}
      width={'40%'}
      className="users_modal">
      { user ? (
        <div className={style.user_profile_container}>
          <div className="main-title-30px">{user.name}</div>
          <Space direction="horizontal" size={[15, 0]} className={style.space_top}>
            <Status status={+user.approvedRequestsCount > 0 ? t('shipmentList.customer') : t('shipmentList.lead')}/>
            <div className="sub-title">{t('forms.users.order_number')}: {user.approvedRequestsCount}</div>
          </Space>
          <div className={style.user_profile_gap}>
            <div className={style.user_profile_title}>{t('forms.users.email')}</div>
            <div className={style.primary_color}>{user.email}</div>
          </div>
          <div className={style.user_profile_gap}>
            <div className={style.user_profile_title}>
              { typology === 'Company' ? t('forms.users.vat_number') : t('forms.users.fiscal_code')}
            </div>
            {
              user?.vat_number
                && <div className={style.bold}>
                  { typology === 'Company'
                    ? `IT${user.vat_number}`
                    : `${user.vat_number}`
                  }
                </div>
            }
          </div>
          <div className={style.user_profile_gap}>
            <div className={style.user_profile_title}>{t('forms.users.notes')}</div>
            <div>{user.note}</div>
          </div>
          <div className={style.user_profile_gap}>
            <div className={style.user_profile_title}>{t('forms.users.office_address')}</div>
            <div>{user.address?.full_address}</div>
          </div>
          {
            userPriceList && <div className={style.user_profile_gap}>
              <div className={style.user_profile_title}>{t('forms.users.associated_price_list')}</div>
              <div>
                <Tag className={style.tag_price_list}>{userPriceList?.name}</Tag>
              </div>
            </div>
          }
          {
            selectedUser && <div className={style.user_profile_gap}>
              <div className={style.user_profile_title}>
                {t('forms.users.pallex_company_name')}
              </div>
              <div className={style.bold}>
                {selectedUser.name}
              </div>
            </div>
          }
          {
            selectedUser && <div className={style.user_profile_gap}>
              <div className={style.user_profile_title}>
                {t('forms.users.pallex_vat_number')}
              </div>
              <div className={style.bold}>
                  IT{selectedUser.vat_number}
              </div>
            </div>
          }
          <Row className={style.user_profile_gap}>
            <Col>
              <CustomButton
                className={classnames('extra-bolt-corsivo', style.user_profile_button)}
                htmlType="submit"
                type="primary"
                onClick={onCancel}
              >
                {t('forms.users.close')}
              </CustomButton>
            </Col>
            <Col offset={1}>
              <Button
                className={classnames('defaultBtn', style.circle_button)}
                icon={<SettingOutlined/>}
                onClick={openUpdateUserForm}
              />
            </Col>
            <Col className={style.right}>
              <Button
                className={classnames('defaultBtn', style.circle_button)}
                onClick={openDeleteModal}
                icon={<DeleteFilled/>} />
            </Col>
          </Row>
        </div>
      ) : t('forms.users.not_exist')}
    </Modal>
  );
};

export default UserProfile;
