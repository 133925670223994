import { put, takeLatest } from 'redux-saga/effects';
import { TransportTypePayload } from '../../types';
import {
  setLoading, requestCreateTransportType, requestDeleteTransportType,
  requestGetTransportType, requestUpdateTransportType, setTransportType,
} from '../../redux_slice/transport/transportTypeSlice';
import {
  GetTransportType, CreateTransportType, UpdateTransportType, DeleteTransportType, GetTransportTypes,
} from '../../../api/transtort';
import { setTransportTypeData } from '../../redux_slice/transport/transportTypesSlice';
import { setMessage } from '../../redux_slice/messageErrorSlice';

function* createTransportTypeSaga(action: {
    payload: { type: string }
}) {
  yield put(setLoading(true));
  try {
    (yield CreateTransportType(action.payload));
    const { data } = (yield GetTransportTypes());
    yield put(setTransportTypeData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* getTransportTypeSaga(action: {
    payload: string
}) {
  yield put(setLoading(true));
  try {
    const { data } = (yield GetTransportType(action.payload));
    yield put(setTransportType(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* deleteTransportTypeSaga(action: {
    payload: string,
}) {
  yield put(setLoading(true));
  try {
    (yield DeleteTransportType(action.payload));
    const { data } = (yield GetTransportTypes());
    yield put(setTransportTypeData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

function* updateTransportTypeSaga(action: {
    payload: TransportTypePayload,
}) {
  yield put(setLoading(true));
  try {
    (yield UpdateTransportType(action.payload));
    const { data } = (yield GetTransportTypes());
    yield put(setTransportTypeData(data));
  } catch (e) {
    yield put(setMessage({ description: e.response.data.message }));
  }
  yield put(setLoading(false));
}

export function* transportTypeSaga() {
  yield takeLatest(requestCreateTransportType, createTransportTypeSaga);
  yield takeLatest(requestGetTransportType, getTransportTypeSaga);
  yield takeLatest(requestDeleteTransportType, deleteTransportTypeSaga);
  yield takeLatest(requestUpdateTransportType, updateTransportTypeSaga);
}
