import { useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cn from 'classnames';
import SuccessIconSvg from 'assests/svg/SuccessIconSvg';
import { ShipmentStructure, TripStructure, Address } from 'store/types';
import { getPalletsSumm } from '../utils';
import { DeliveryType } from 'enums';
import styles from './card.module.css';

export const ItemTypes = {
  CARD: 'card',
};

type Props = {
  trip?: TripStructure | null,
  shippingRequest: ShipmentStructure;
  showDetailsModal: () => void,
};

const Card = ({
  trip = null,
  shippingRequest,
  showDetailsModal,
}: Props) => {
  const completedTrip = useMemo(() => !!trip?.date_end, [trip]);
  const deliveryType = useMemo(() => trip?.delivery_type || shippingRequest.delivery_type, [trip, shippingRequest]);
  const mappedShippingRequest = useMemo(() => (
    trip ? { ...shippingRequest, delivery_type: trip.delivery_type } : shippingRequest
  ), [trip, shippingRequest]);

  const isNonStop = useMemo(() => (
    shippingRequest.options.filter((el) => el.title.toUpperCase() === 'SERVIZIO NON-STOP/ESPRESSO' && el.isActive).length
  ), [shippingRequest]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      canDrag: !completedTrip,
      item: {
        shippingRequest: mappedShippingRequest, tripId: trip?.id || null, oldTruckId: trip?.truck_id || null,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [shippingRequest.id],
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [shippingRequest.id],
  );

  const getAddressWithName = (address: Address) => {
    const {
      company_name: CompanyName, name_and_surname_reference: userName, location,
    } = address;
    return (
      <span className={cn(styles.thin_text, styles.hover_color)}>
        {CompanyName || userName} - {location.city}
      </span>
    );
  };

  const renderAddressInfo = () => {
    if (deliveryType === DeliveryType.RITIRO) {
      return getAddressWithName(shippingRequest.pickup_address);
    }
    if (deliveryType === DeliveryType.CONSEGNA) {
      return getAddressWithName(shippingRequest.shipping_address);
    }
    return null;
  };

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={cn(styles.card, {
        [styles.card_opacity_hidden]: isOver || isDragging,
        [styles.isNonStop]: isNonStop,
      })}
    >
      <div className={styles.card_info}>
        <h4 className={cn(styles.bold_text, styles.hover_color)}>{ shippingRequest.tracking_code }</h4>
        <button
          className={styles.number_of}
          onClick={showDetailsModal}
        >
          { completedTrip ? <SuccessIconSvg /> : getPalletsSumm(shippingRequest.pallets, trip) }
        </button>
      </div>
      <span className={cn(styles.shipping_id, styles.hover_color)}>Id:{' '}
        {shippingRequest.spedizione_id || shippingRequest.id}
      </span>
      <span className={cn(styles.thin_text, styles.hover_color)}>
        {renderAddressInfo()}
      </span>
      <span className={cn(styles.thin_text, styles.hover_color)}>
        { deliveryType } - { shippingRequest.contractor[0].name }
      </span>
    </div>
  );
};

export default Card;
