import {
  useCallback, useMemo, useState,
} from 'react';
import { Checkbox, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import DeleteModal from 'components/DeleteModal';
import FaqSection from 'components/FaqModal';
import OptionsButton from 'components/OptionsButton';
import { makeColumnsApprove, PrivateAreaColumnsInterface } from './TableColumns';
import { useAppDispatch } from 'store/store';
import { ShipmentlistStructure } from 'store/types';
import { requestShippingTrackingStatueAction } from 'store/redux_slice/shipmentTrackingStatusSlice';
import { deleteShippingRequestAction } from 'store/redux_slice/privateAreaSlice';
import { requestDownloadQrCodesAction } from 'store/sagas/downloadQrCodesSaga';
import { setMessage } from 'store/redux_slice/messageErrorSlice';
import { CanDeleteShipmentsWithStatuses, EstimationStatuses, PathEnums } from 'enums';
import { checkShipmentStatus } from 'functions';
import styles from './shipmentsTable.module.css';

type Props = {
  tableData: ShipmentlistStructure | null,
  page: number,
  pageSize: number,
  setPage: (val:number) => void,
  setPageSize: (val:number) => void,
  total: number,
  setVisibleTrackingModal: (val:boolean) => void,
}

type OptionsArray = {
  title: string,
  action: (id: string, status?: string) => void,
}

const ShipmentsTable = ({
  tableData, page, pageSize, setPage, setPageSize, total, setVisibleTrackingModal,
}: Props) => {
  const { t } = useTranslation();
  const [isDeleteModal, setIsDeleteModal] = useState<{
    isOpen: boolean,
    id: string | null,
  }>({ isOpen: false, id: null });
  const [faqVisible, setFaqVisible] = useState<boolean>(false);
  const [selectedShippingsInfo, setSelectedShippingsInfo] = useState<PrivateAreaColumnsInterface[] | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pagination = {
    current: page,
    pageSize,
    pageSizeOptions: [10, 15, 20],
    showSizeChanger: true,
    total,
    onChange: setPage,
    onShowSizeChange: (current, size) => setPageSize(size),
  };

  const checkIfPossibleToDelete = useCallback((status?: string) => {
    if (selectedShippingsInfo && selectedShippingsInfo.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < selectedShippingsInfo.length; i++) {
        const findStatus = Object.values(CanDeleteShipmentsWithStatuses)
          .find((value) => value === selectedShippingsInfo[i].status);
        if (!findStatus) return false;
      }
      return true;
    }
    return !!Object.values(CanDeleteShipmentsWithStatuses).find((value) => value === status);
  }, [selectedShippingsInfo]);

  const onDeleteHandler = useCallback((id?: string, status?: string) => {
    if (id && status && checkIfPossibleToDelete(status)) {
      setIsDeleteModal({ isOpen: true, id });
    } else if (selectedShippingsInfo && checkIfPossibleToDelete()) {
      const idsToDelete = selectedShippingsInfo?.map((item) => item.id).join(',');
      setIsDeleteModal({ isOpen: true, id: idsToDelete });
    } else {
      dispatch(setMessage({ title: 'Attenzione!', description: 'Non è possibile eliminare le spedizioni non ancora terminate.' }));
    }
  }, [checkIfPossibleToDelete, dispatch, selectedShippingsInfo]);

  const onConfirmDeleteHandler = () => {
    if (isDeleteModal?.id) {
      dispatch(deleteShippingRequestAction(isDeleteModal?.id));
      setSelectedShippingsInfo(null);
    }
  };

  const trackingOption:OptionsArray[] = useMemo(() => [
    {
      title: t('shipmentList.tracking'),
      action: (id: string) => {
        const code = tableData?.items.filter((item) => item.id === id)[0].tracking_code ?? '';
        dispatch(requestShippingTrackingStatueAction(code));
        setVisibleTrackingModal(true);
      },
    },
  ], [dispatch, setVisibleTrackingModal, t, tableData]);

  const options = useMemo(() => [
    {
      title: t('shipmentList.seeDetails'),
      action: (id: string) => navigate(`${PathEnums.Private_area_shipment_details}/${id}`),
    },
    {
      title: t('shipmentList.download'),
      action: (id: string) => dispatch(requestDownloadQrCodesAction(id)),
    },
    {
      title: t('shipmentList.requestSupport'),
      action: () => setFaqVisible(true),
    },
    {
      title: t('pricelist.delete'),
      action: (id: string, status?: string) => onDeleteHandler(id, status),
    },
  ], [dispatch, navigate, onDeleteHandler, t]);

  const newTableData = useMemo(() => (
    tableData?.items?.map((item) => {
      let tableOptions: OptionsArray[] = [];
      if (item.tracking_code) {
        tableOptions = [...options, ...trackingOption];
      } else {
        tableOptions = options;
      }
      return {
        key: item.spedizione_id || item.preventivi_id || item.id,
        id: item.spedizione_id || item.preventivi_id || item.id,
        type: item.spedizione_id ? t('privateAreaShipmentDetails.shipment') : t('privateAreaShipmentDetails.estimation'),
        palletNumber: item.pallets.reduce((prev, cur) => prev + cur.item_quantity, 0),
        pickupName: item.pickup_address?.company_name || item.pickup_address?.name_and_surname_reference || '-',
        pickupGDO: !!item.pickup_address.gdo_id,
        shippingName: item.shipping_address?.company_name || item.shipping_address?.name_and_surname_reference || '-',
        shippingGDO: !!item.shipping_address.gdo_id,
        start_shipment_date: item.start_shipment_date ? format(new Date(item.start_shipment_date), 'dd-MM-yyyy') : '-',
        withdrawal_date: item.withdrawal_date ? format(new Date(item.withdrawal_date), 'dd-MM-yyyy') : '-',
        status: checkShipmentStatus(item.status, item.req_status),
        tracking: item.tracking_code || '-',
        codice_cliente_spedizione: item.codice_cliente_spedizione || '-',
        options: <OptionsButton options={tableOptions} id={item.id} status={item.status}/>,
      };
    })
  ), [options, tableData?.items, trackingOption, t]);

  const onChangeHeadCheckboxHandler = () => {
    if (selectedShippingsInfo?.length) {
      setSelectedShippingsInfo([]);
    } else {
      setSelectedShippingsInfo(newTableData as PrivateAreaColumnsInterface[]);
    }
  };

  const columnTitle = (
    <span style={{ display: 'flex' }}>
      <Checkbox onChange={onChangeHeadCheckboxHandler} checked={!!selectedShippingsInfo?.length} />
      {selectedShippingsInfo?.length ? (
        <span className={styles.delete_icon}>
          <DeleteOutlined
            width={20}
            height={20}
            onClick={() => onDeleteHandler()}
          />
        </span>
      ) : null}
    </span>
  );
  const columns = makeColumnsApprove(t);

  return (
    <>
      <FaqSection
        visible={faqVisible}
        onCancel={() => setFaqVisible(false)}
      />
      <DeleteModal
        title={t('forms.deliveries.delete_shipping')}
        description={t('forms.deliveries.delete_shipping_description')}
        visible={isDeleteModal.isOpen}
        onCancel={() => setIsDeleteModal({ isOpen: false, id: null })}
        onDelete={onConfirmDeleteHandler}
      />
      <Table
        columns={columns}
        dataSource={newTableData}
        pagination={pagination}
        rowSelection={{
          selectedRowKeys: selectedShippingsInfo?.map((el) => el.id),
          onChange: (selectedRowKeys, rowInfo: PrivateAreaColumnsInterface[]) => {
            setSelectedShippingsInfo(rowInfo);
          },
          columnTitle,
        }}
      />
    </>
  );
};

export default ShipmentsTable;
