import {
  Col, Form, Input, InputNumber, Row, Select,
} from 'antd';
import { FilterFilled, ReloadOutlined } from '@ant-design/icons/lib/icons';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import CustomButton from '../../components/CustomButton/CustomButton';
import style from './users.module.css';
import { FilterUsers } from '../../store/types';
import { useAppSelector } from '../../store/store';

const UsersFilter: React.FC<{
    setFilter: (obj) => void,
}> = ({ setFilter }) => {
  const { t } = useTranslation();
  const [advancedForm] = Form.useForm();
  const [nameForm] = Form.useForm();
  const { Option } = Select;
  const priceListTemplates = useAppSelector((state) => state.priceListTemplates?.priceListTemplates);
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const onChangeFilterHandler = useCallback(_.debounce((param: keyof FilterUsers, value) => {
    setFilter((prevState) => ({ ...prevState, ...{ [param]: value } }));
  }, 500), []);
  const orResetFilterHandler = () => {
    setFilter({});
    advancedForm.resetFields();
    nameForm.resetFields();
  };
  return (
    <>
      <div className={style.filter_container}>
        <Form form={nameForm}>
          <Form.Item name="name">
            <Input
              onChange={(v) => onChangeFilterHandler('userNameOrVATnumber', v.target.value || undefined)}
              className={style.filter_input}
              placeholder={t('forms.users.search')}
            />
          </Form.Item>
        </Form>
        <CustomButton
          className={style.searchBtn}
          onClick={() => setIsAdvanced(!isAdvanced)}
          icon={<FilterFilled/>}
        >
          {t('forms.estimations.filter_button')}
        </CustomButton>
        <CustomButton
          className={style.resetBtn}
          icon={<ReloadOutlined/>}
          onClick={orResetFilterHandler}
        >
          {t('forms.estimations.reset_button')}
        </CustomButton>
      </div>
      {isAdvanced && (
        <Form form={advancedForm}>
          <Row className={style.height_container}>
            <Col>
              <p className={style.labelSearch}>{t('forms.users.shipment_generated')}</p>
              <Form.Item name="requestCount">
                <InputNumber onChange={(v) => {
                  onChangeFilterHandler('requestCount', v ?? undefined);
                }}
                className={style.input_number}
                />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <p className={style.labelSearch}>{t('forms.users.associated_price_list')}</p>
              <Form.Item
                name="price_list"
              >
                <Select
                  className={style.input_number}
                  onChange={(v) => onChangeFilterHandler('priceListId', v || undefined)}
                >
                  {
                    priceListTemplates
                      .slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((i) => (
                        <Option key={i.id} value={i.id}>
                          {i.name}
                        </Option>
                      ))
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default UsersFilter;
