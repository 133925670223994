import { useState } from 'react';
import {
  Col, Form, Input, Row, Select,
} from 'antd';
import { FilterFilled, ReloadOutlined } from '@ant-design/icons/lib/icons';
import { formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import CustomButton from 'components/CustomButton/CustomButton';
import { useAppSelector } from 'store/store';
import { ScaricoFilterType } from 'store/types';
import style from './manifestsFilter.module.css';

type ManofestFiltersType = {
  scaricoFilter: ScaricoFilterType,
  setScaricoFilter: (scaricoFilter:ScaricoFilterType) => void,
}

const ManofestFilters = ({ scaricoFilter, setScaricoFilter }: ManofestFiltersType) => {
  const { t } = useTranslation();
  const [advancedForm] = Form.useForm();
  const [nameForm] = Form.useForm();
  const { trucksFilter } = useAppSelector((state) => state.unloadingTrips);

  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const onChangeFilterHandler = _.debounce((param: string, value) => {
    setScaricoFilter({
      ...scaricoFilter,
      [param]: value,
    });
  }, 500);
  const orResetFilterHandler = () => {
    setScaricoFilter({
      date_start: formatISO(new Date()),
      filter_truck_id: scaricoFilter.filter_truck_id,
    });
    advancedForm.resetFields();
    nameForm.resetFields();
  };
  return (
    <>
      <div className={style.filter_container}>
        <Form form={nameForm}>
          <Form.Item name="search_bar">
            <Input
              onChange={(v) => onChangeFilterHandler('search_bar', v.target.value || undefined)}
              className={style.filter_input}
              placeholder={t('forms.users.search')}
            />
          </Form.Item>
        </Form>
        <CustomButton
          className={style.searchBtn}
          onClick={() => setIsAdvanced(!isAdvanced)}
          icon={<FilterFilled/>}
        >
          {t('forms.estimations.filter_button')}
        </CustomButton>
        <CustomButton
          className={style.resetBtn}
          icon={<ReloadOutlined/>}
          onClick={orResetFilterHandler}
        >
          {t('forms.estimations.reset_button')}
        </CustomButton>
      </div>
      {isAdvanced && (
        <Form form={advancedForm}>
          <Row>
            <Col>
              <Form.Item name="tracking_code">
                <Select
                  showSearch
                  placeholder={t('manifest.tracking_code')}
                  className={style.input_number}
                  options={trucksFilter?.trackingCodeFilter}
                  onChange={(v) => onChangeFilterHandler('tracking_code', v || undefined)}
                />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item name="order_id">
                <Select
                  showSearch
                  placeholder={t('manifest.select_id')}
                  className={style.input_number}
                  options={trucksFilter?.orderIdFilter}
                  onChange={(v) => onChangeFilterHandler('order_id', v || undefined)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ManofestFilters;
