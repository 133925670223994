import {
  useMemo, useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { EditFilled, SettingOutlined } from '@ant-design/icons';
import {
  Row, Col, Select, Form, Button,
} from 'antd';
import { CalendarFilled, PlusOutlined, ClockCircleFilled } from '@ant-design/icons/lib/icons';
import classnames from 'classnames';
import * as _ from 'lodash';
import StatusSelect from 'components/StatusSelect';
import Status from 'components/Status';
import { NoteContainer, AdditionalTextContainer } from 'components/EditableTextContainer';
import ContractorTable from 'components/ContractorTable';
import ExtraServicesTableForm from 'components/ExtraServicesTable';
import { AdminPalletsTable, MerceAdrTable } from 'components/EditableTable/Tables';
import { CurrentSlideContext } from 'components/ModalWithCarousel';
import { ChangeDateModal } from 'containers/Estimations/FieldModals/ChangeDateModal';
import { EstimationFieldModal, useModalState } from 'containers/Estimations/hooks';
import { OpenModalButton } from 'containers/Estimations/OpenModalButton';
import { ChangeExtraServicesModalView } from 'containers/Estimations/FieldModals/ChangeExtraServicesModal';
import { ChangeEcoPalletModal } from 'containers/Estimations/FieldModals/ChangeEcoPalletModal';
import { ChangeFasciModal } from 'containers/Estimations/FieldModals/ChangeFasciModal';
import { ChangeContrassegnoModal } from 'containers/Estimations/FieldModals/ChangeContrassegnoModal';
import { ChangeFacchinaggioModal } from 'containers/Estimations/FieldModals/ChangeFacchinaggioModal';
import { ChangeGdoOrAmazonModal } from 'containers/Estimations/FieldModals/ChangeGdoOrAmazonModal';
import { ChangeOreNoteModal } from 'containers/Estimations/FieldModals/ChangeOreNoteModal';
import { ChangeTelefonicaModal } from 'containers/Estimations/FieldModals/ChangeTelefonicaModal';
import { ChangeAddressModal } from 'containers/Estimations/FieldModals/ChangeAddressModal';
import { ChangeTimeModal } from 'containers/Estimations/FieldModals/ChangeTimeModal';
import { ChangeDDTNumberModal } from 'containers/Estimations/FieldModals/ChangeDDTNumberModal';
import { ChangeHabModalForm } from 'containers/Estimations/FieldModals/ChangeHabModal';
import PallexErrorModal from '../PallexErrorModal';
import TrackingModal from './TrackingModal';
import ChangeStatusModal from '../ChangeStatusModal';
import ExtraApproved from '../ExtraApproved';
import { PallexStatusesArray, TransportiStatusesArray } from '../TableColumns';
import { useAppDispatch, useAppSelector } from 'store/store';
import { updateStatusAction } from 'store/sagas/shipping/shippingStatusSaga';
import { requestCurrentUserAction } from 'store/redux_slice/users/usersSlice';
import { requestGetTransportByShippingId } from 'store/redux_slice/transport/transportByIdSlice';
import { requestGetServicesByPriceList } from 'store/redux_slice/extraByPriceListSlice';
import { clearGDOList } from 'store/redux_slice/pallex/gdoSlice';
import { ExtraServiceStructure, PatchEstimation, ShipmentStructure } from 'store/types';
import {
  TPStatuses, ContractorTypes,
  PallexStatuses, DeliveryType, AddressFields,
} from 'enums';
import {
  CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA, GDO, AMAZON, ADR,
  CONTRASSEGNO, FACCHINAGGIO, FASCI, GESTIONE_EPAL, TELEFONICA,
} from 'pesantiConstants';
import PenIcon from 'assests/images/penIcon.png';
import PallexErrorSvg from 'assests/svg/PallexErrorSvg';
import style from '../../deliveries.module.css';

const ShippingModal = ({
  toBeApproved = false, shipment, onRequestUpdateEstimation,
}: {
  toBeApproved?: boolean, shipment: ShipmentStructure,
  onRequestUpdateEstimation?: (data: PatchEstimation) => void,
}) => {
  const { isCurrent } = useContext(CurrentSlideContext);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [hubForm] = Form.useForm();
  const {
    openModal,
    closeModal,
    isOpen,
  } = useModalState();
  const [visiblePallesErrorModal, setVisiblePallesErrorModal] = useState<boolean>(false);
  const [ecoPallets, setEcoPallets] = useState<boolean>(false);
  const [fasci, setFasci] = useState<boolean>(false);
  const [contrassegno, setContrassegno] = useState<boolean>(false);
  const [oreNote, setOreNote] = useState<boolean>(false);
  const [openTrackingModal, setOpenTrackingModal] = useState<boolean>(false);
  const [selectId, setSelectId] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState<boolean>(false);
  const [oreNoteForm] = Form.useForm();
  const pallexHubs = useAppSelector((state) => state.pallexHubs?.hubs);
  const transportById = useAppSelector((state) => state.transportById?.transportById);
  const contrassegnoList = useAppSelector((state) => state.contrassegnoList?.contrassegnoList);
  const priceListTemplates = useAppSelector((state) => state.priceListTemplates?.priceListTemplates);
  const facchinaggioList = useAppSelector((state) => state.facchinaggioList?.facchinaggioList);
  const extraServices = useAppSelector((state) => state.extraServicesByPriceList.services);
  const contractors = useAppSelector((state) => state.contractors?.contractors);
  const pallexItaly = contractors.find((el) => el.name === ContractorTypes.Pallex);
  const selectedPriceList = priceListTemplates?.find((el) => el.id === shipment.price_list_id);
  const selectedContractor = contractors?.find((el) => el.id === shipment.contractor[0]?.ResContract.contractor_id);
  const inMagazinoCaseContractor = selectedContractor?.name === 'Pallex TP' || selectedContractor?.name === 'TP Stradale';
  const inMagazinoCaseDelivery = inMagazinoCaseContractor && shipment.delivery_type === 'Ritiro';
  const inMagazinoCase = inMagazinoCaseDelivery && shipment.status === 'In magazzino';
  const shippingContractorIsPallex = shipment.contractor[0]?.ResContract.contractor_id === pallexItaly?.id;
  const shippingContractorIsPallexORPallexTP = shippingContractorIsPallex || selectedContractor?.name === 'Pallex TP' || selectedContractor?.is_editable;
  const shippingIsPallexArchive = shippingContractorIsPallex && shipment.status === PallexStatuses.Ritiro_effettuato && shipment.delivery_type === 'Ritiro';
  const findContractor = contractors.find((el) => el.id === shipment.contractor[0]?.ResContract?.contractor_id);
  const findSubContractor = contractors.find((el) => (
    el.id === shipment.sub_contractor[0]?.ResSubContract?.contractor_id
  ));
  const pallexTP = findContractor?.name === 'Pallex TP' || findSubContractor?.name === 'Pallex TP';
  const IS_APROVED_SHIPMENT = shipment?.id !== null
      && shipment?.user_id !== null
      && shipment?.status !== TPStatuses.Annullata
      && shipment?.req_status !== TPStatuses.Rifiutato
      && shipment?.status !== TPStatuses.Consegnato
      && !inMagazinoCase;

  const IS_APROVED_SHIPMENT_WITHOUT_USER_ID = shipment?.status !== TPStatuses.Annullata
      && shipment?.req_status !== TPStatuses.Rifiutato
      && shipment?.status !== TPStatuses.Consegnato
      && !inMagazinoCase
      && shipment?.id !== null
      && !shippingIsPallexArchive;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const checkUpdatePallexError:boolean = useMemo(() => (
    !!(shipment?.pallex_error && (shipment.pallex_error.message.includes('È stata modificata la spedizione sul portale') || shipment.pallex_error.message.includes('Il cliente ha modificato il contrassegno. Verifica che sia corretto e invia la modifica a Teseo')))
  ), [shipment?.pallex_error]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasGDO = useMemo(() => (
    shipment?.options.some((o) => (
      (o.isActive && o.title?.toUpperCase() === GDO.toUpperCase())
       || (o.isActive && o.title?.toUpperCase() === AMAZON.toUpperCase())
    ))
  ), [shipment?.options]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasMerceAdr = useMemo(() => (
    shipment?.options.some((o) => (
      (o.isActive && o.title?.toUpperCase() === ADR.toUpperCase())
    ))
  ), [shipment?.options]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const gdoOrAmazonCompanyName = useMemo(() => {
    if (shipment.delivery_type === DeliveryType.RITIRO && shipment.pickup_address.gdo_name) {
      return shipment.pickup_address.gdo_name;
    }
    if (shipment.delivery_type === DeliveryType.CONSEGNA && shipment.shipping_address.gdo_name) {
      return shipment.shipping_address.gdo_name;
    }
    if (shipment.delivery_type === DeliveryType.TRIANG) {
      return `${shipment.pickup_address.gdo_name ? shipment.pickup_address.gdo_name : '-'}/
      ${shipment.shipping_address.gdo_name ? shipment.shipping_address.gdo_name : '-'}`;
    }
    return '-';
  }, [shipment]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const gdoOrAmazonLabel = useMemo(() => {
    const hasGdo = shipment?.options.some((o) => o.isActive && o.title?.toUpperCase() === GDO.toUpperCase());
    const hasAmazon = shipment?.options.some((o) => o.isActive && o.title?.toUpperCase() === AMAZON.toUpperCase());
    if (hasGdo && hasAmazon) {
      return 'GDO/AMAZON';
    }
    if (hasGdo) {
      return 'GDO';
    }
    if (hasAmazon) {
      return 'AMAZON';
    }
    return '';
  }, [shipment?.options]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const serviceTelefonica = useMemo(() => (
    shipment?.options?.find((item) => item.title.toUpperCase() === TELEFONICA.toUpperCase() && item.isActive)
  ), [shipment?.options]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const serviceFacchinaggio = useMemo(() => (
    shipment?.options?.find((item) => item.title.toUpperCase() === FACCHINAGGIO.toUpperCase() && item.isActive)
  ), [shipment?.options]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const facchinaggioRitiroDescription = useMemo(() => (
    facchinaggioList?.find((s) => s.id === serviceFacchinaggio?.values?.ritiro)?.description
  ), [facchinaggioList, serviceFacchinaggio?.values?.ritiro]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const facchinaggioConsegnaDescription = useMemo(() => (
    facchinaggioList?.find((s) => s.id === serviceFacchinaggio?.values?.consegna)?.description
  ), [facchinaggioList, serviceFacchinaggio?.values?.consegna]);

  const optionsHash = JSON.stringify(shipment?.options);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const quantity = useMemo(() => {
    if (shipment) {
      return shipment.pallets?.reduce((prev, cur) => prev + cur.item_quantity, 0);
    }
    return null;
  }, [shipment]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!isCurrent) return;
    if (!shipment) return;
    if (shipment.user_id) {
      dispatch(requestCurrentUserAction(shipment.user_id));
    }
  }, [dispatch, shipment, isCurrent]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (shipment) {
      if (!isCurrent) return;
      dispatch(requestGetTransportByShippingId(shipment?.id.toString()));
    }
  }, [dispatch, isCurrent, shipment, shipment?.id]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (shipment?.price_list_id) {
      if (!isCurrent) return;
      dispatch(requestGetServicesByPriceList(shipment?.price_list_id));
    }
  }, [dispatch, shipment?.price_list_id, isCurrent]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (shipment?.price_list_id && !!searchParams.get('dettaglio')) {
      dispatch(requestGetServicesByPriceList(shipment?.price_list_id));
    }
  }, [searchParams, dispatch, shipment.price_list_id]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!shipment.pallex_error) {
      setVisiblePallesErrorModal(false);
    }
  }, [shipment.pallex_error, dispatch]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // useEffect(() => {
  //   if ((shipment.contractor.length || shipment.sub_contractor.length)
  //   && (shipment.contractor[0].ResContract || shipment.sub_contractor[0].ResSubContract)) {
  //     const isAvailable = isTrackingAvailable(shipment.user_id, findContractor?.name, findSubContractor?.name, shipment.tracking);
  //     if (onRequestUpdateEstimation) {
  //       onRequestUpdateEstimation({
  //         id: shipment?.id,
  //         data: {
  //           tracking: shipment?.tracking === isAvailable ? undefined : isAvailable,
  //         },
  //       });
  //     }
  //   }
  // }, [onRequestUpdateEstimation]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => () => {
    dispatch(clearGDOList());
  }, [dispatch, IS_APROVED_SHIPMENT]);

  if (!shipment) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const updateEpalByServices = useCallback(_.debounce((services: ExtraServiceStructure[]) => {
    const p = services.find((s) => s.title?.toUpperCase() === GESTIONE_EPAL);
    if (!p || !p.isActive) {
      if (shipment?.epal && onRequestUpdateEstimation) {
        onRequestUpdateEstimation({
          id: shipment?.id ?? 0,
          data: {
            epal: 0,
          },
        });
      }
      setEcoPallets(false);
    } else if (p) {
      setEcoPallets(true);
    }
  }, 500), [shipment?.epal]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const updateFasciByServices = useCallback(_.debounce((services: ExtraServiceStructure[]) => {
    const p = services.find((s) => s.title?.toUpperCase() === FASCI);
    if (!p || !p.isActive) {
      if (shipment?.fasci && onRequestUpdateEstimation) {
        onRequestUpdateEstimation({
          id: shipment?.id ?? 0,
          data: {
            fasci: 0,
          },
        });
      }
      setFasci(false);
    } else if (p) {
      setFasci(true);
    }
  }, 500), [shipment?.fasci]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const updateContrassegnoByServices = useCallback(_.debounce((services: ExtraServiceStructure[]) => {
    const p = services.find((s) => s.title?.toUpperCase() === CONTRASSEGNO);
    if (!p || !p.isActive) {
      if (shipment?.contrassegno && onRequestUpdateEstimation) {
        onRequestUpdateEstimation({
          id: shipment?.id ?? 0,
          data: {
            contrassegno: {
              id: 0,
              value: 0,
            },
          },
        });
      }
      setContrassegno(false);
    } else if (p) {
      setContrassegno(true);
    }
  }, 500), [shipment?.contrassegno]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const updateOreNoteByServices = useCallback(_.debounce((services: ExtraServiceStructure[]) => {
    const p = services.find((s) => s.title?.toUpperCase() === CONSEGNA_CON_GIORNO_E_ORA_PRESTABILITA);
    if (!p || !p.isActive) {
      if (shipment?.ore_note && onRequestUpdateEstimation) {
        onRequestUpdateEstimation({
          id: shipment?.id ?? 0,
          data: {
            ore_note: ' - ',
          },
        });
      }
      setOreNote(false);
    } else if (p) {
      setOreNote(true);
    }
  }, 500), [shipment?.ore_note]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (shipment?.options.length) {
      updateEpalByServices(shipment?.options);
      updateFasciByServices(shipment?.options);
      updateContrassegnoByServices(shipment?.options);
      updateOreNoteByServices(shipment?.options);
    }
  }, [optionsHash]);

  const onStatusChange = (status, shipmentData) => {
    if (status !== 'Annullata') {
      dispatch(updateStatusAction({
        id: shipment.id,
        data: { status },
      }));
    } else {
      setOpenChangeStatusModal(true);
      setSelectedStatus(status);
      setSelectId(shipment.id);
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onRequestUpdateOptions = useCallback((data: PatchEstimation) => {
    if (onRequestUpdateEstimation && shipment?.id) {
      onRequestUpdateEstimation({
        recalculate: true,
        id: shipment?.id,
        data,
      });
    }
  }, [shipment?.id, onRequestUpdateEstimation]);

  const updateMerceAdrHandler = (merceAdr) => {
    if (onRequestUpdateEstimation && shipment?.id) {
      onRequestUpdateEstimation({
        id: shipment?.id,
        data: {
          merce_adr: merceAdr.merce_adr,
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const updateAddressHandler = useCallback((address) => {
    if (onRequestUpdateEstimation && shipment.id) {
      onRequestUpdateEstimation({
        id: shipment?.id,
        recalculate: true,
        data: { ...address },
      });
    }
  }, [onRequestUpdateEstimation, shipment?.id]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const updateOptionHandler = useCallback((pallets) => {
    if (onRequestUpdateEstimation && shipment?.id) {
      onRequestUpdateEstimation({
        id: shipment?.id,
        recalculate: true,
        data: {
          pallets: pallets.admin_pallets,
        },
      });
    }
  }, [onRequestUpdateEstimation, shipment?.id]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onSetServiceDetails = useCallback((field, value) => {
    if (onRequestUpdateEstimation && shipment?.id) {
      onRequestUpdateEstimation({
        id: shipment?.id,
        recalculate: true,
        data: {
          [field]: value,
        },
      });
    }
  }, [onRequestUpdateEstimation, shipment?.id]);

  const onOpenPallesError = () => {
    setVisiblePallesErrorModal(true);
  };

  const renderStatuses = () => {
    if (shipment.user_id && shipment.req_status !== TPStatuses.Rifiutato) {
      return (
        <StatusSelect
          statusName={shipment.status}
          value={shipment.status}
          onChange={(value: string) => onStatusChange(value, shipment)}
          options={!shippingContractorIsPallex
            ? TransportiStatusesArray : PallexStatusesArray}
        />
      );
    }
    return <Status status={shipment.status}/>;
  };

  const onNoteHandler = (data: { note_ritiro: string | null, note_consegna: string | null }) => {
    if (onRequestUpdateEstimation) {
      onRequestUpdateEstimation({
        id: shipment?.id,
        data,
      });
    }
  };

  const onGdoChange = (gdoPayload) => {
    if (onRequestUpdateEstimation) {
      onRequestUpdateEstimation({
        id: shipment?.id,
        data: { ...gdoPayload },
      });
    }
  };

  const onUpdateTime = (time: string) => {
    if (onRequestUpdateEstimation) {
      const noteRitiro = shipment.delivery_type === DeliveryType.RITIRO
        ? { note_ritiro: `${t('forms.shipmentRequest.start_shipment_time_note')}: ${time}` } : {};
      onRequestUpdateEstimation({
        id: shipment.id,
        data: {
          start_shipment_time: time,
          ...noteRitiro,
        },
      });
    }
  };

  const addAdditionalTextHandeler = (data: { informazioni_aggiuntive: string | null }) => {
    if (onRequestUpdateEstimation) {
      onRequestUpdateEstimation({
        id: shipment?.id,
        data,
      });
    }
  };

  const onSetEditableService = (servicePeyload: ExtraServiceStructure, serviceTitle: string) => {
    if (onRequestUpdateEstimation) {
      onRequestUpdateEstimation({
        id: shipment?.id,
        data: {
          options: shipment.options.map((item) => (
            item.title.toUpperCase() === serviceTitle.toUpperCase() ? servicePeyload : item
          )),
        },
      });
    }
  };

  const onHubChangeHandler = (data) => {
    if (onRequestUpdateEstimation && data?.data?.idHub) {
      onRequestUpdateEstimation({
        id: shipment?.id,
        data: {
          idHub: data?.data?.idHub,
        },
      });
    }
  };

  return (
    <div className={style.detail_modal_container}>
      {
        onRequestUpdateEstimation
                && <TrackingModal
                  visible={openTrackingModal}
                  onCancel={() => setOpenTrackingModal(false)}
                  tracking={shipment?.tracking}
                  onRequestUpdateEstimation={onRequestUpdateEstimation}
                  id={shipment?.id}
                />
      }
      <ChangeHabModalForm
        form={hubForm}
        id={shipment?.id ?? 0}
        visible={isOpen(EstimationFieldModal.openHubModal)}
        onCancel={() => closeModal()}
        initialValue={shipment?.idHub || null}
        options={pallexHubs}
        onRequestUpdateEstimation={onHubChangeHandler}
      />
      <ChangeAddressModal
        orderData={shipment}
        field={AddressFields.PICKUP_ADDRESS}
        deliveryType={DeliveryType.RITIRO}
        visible={isOpen(EstimationFieldModal.openPickupAddressModal)}
        onCancel={closeModal}
        onRequestUpdateOrder={updateAddressHandler}
        hideName
      />
      <ChangeAddressModal
        orderData={shipment}
        visible={isOpen(EstimationFieldModal.openShippingAddressModal)}
        field={AddressFields.SHIPPING_ADDRESS}
        deliveryType={DeliveryType.CONSEGNA}
        onCancel={closeModal}
        onRequestUpdateOrder={updateAddressHandler}
        hideName
      />
      <ChangeStatusModal
        id={selectId}
        visible={openChangeStatusModal}
        onCancel={() => setOpenChangeStatusModal(false)}
        selectedStatus={selectedStatus}
      />
      <ChangeDateModal
        id={shipment?.id}
        status={shipment?.status}
        initialValue={shipment?.start_shipment_date}
        field={'start_shipment_date'}
        visible={isOpen(EstimationFieldModal.openPickupDateModal)}
        onCancel={closeModal}
        onRequestUpdateEstimation={onRequestUpdateEstimation}
        consegnaDate={shipment?.withdrawal_date}
      />
      <ChangeDateModal
        id={shipment?.id}
        status={shipment?.status}
        field={'withdrawal_date'}
        initialValue={shipment?.withdrawal_date}
        visible={isOpen(EstimationFieldModal.openShippingDateModal)}
        onCancel={closeModal}
        onRequestUpdateEstimation={onRequestUpdateEstimation}
        ritiroDate={shipment?.start_shipment_date}
      />

      <ChangeEcoPalletModal
        visible={isOpen(EstimationFieldModal.openEcoPallet)}
        onChange={(value) => onSetServiceDetails('epal', value)}
        onCancel={() => closeModal()}
        initialValue={shipment?.epal || 0}
      />
      <ChangeFasciModal
        visible={isOpen(EstimationFieldModal.openFasciModal)}
        onChange={(value) => onSetServiceDetails('fasci', value)}
        onCancel={() => closeModal()}
        initialValue={shipment?.fasci || 0}
        maxValue={quantity || 0}
      />
      <ChangeContrassegnoModal
        visible={isOpen(EstimationFieldModal.openContrassegnoModal)}
        onChange={(value) => onSetServiceDetails('contrassegno', value)}
        onCancel={() => closeModal()}
        initialValue={({ value: shipment?.contrassegno?.value || 0, id: shipment?.contrassegno?.id || 0 })}
      />
      <ChangeOreNoteModal
        form={oreNoteForm}
        visible={isOpen(EstimationFieldModal.openConsegnaConGiornoEOraPrestabilitaModal)}
        onChange={(value) => onSetServiceDetails('ore_note', value)}
        onCancel={() => closeModal()}
        initialValue={shipment?.ore_note || ''}
      />
      <ChangeTimeModal
        visible={isOpen(EstimationFieldModal.openTimeModal)}
        onCancel={closeModal}
        initialValue={shipment?.start_shipment_time}
        onUpdateTime={onUpdateTime}
      />
      { onRequestUpdateEstimation && (
        <ChangeDDTNumberModal
          id={shipment.id}
          visible={isOpen(EstimationFieldModal.openDDTNumberModal)}
          onCancel={closeModal}
          onRequestUpdateEstimation={onRequestUpdateEstimation}
          ddtNumberValue={shipment.ddt_number}
        />
      )}
      { onRequestUpdateEstimation && (
        <ChangeGdoOrAmazonModal
          visible={isOpen(EstimationFieldModal.openGdoOrAmazonModal)}
          orderData={shipment}
          onCancel={closeModal}
          onGdoChange={onGdoChange}
        />
      )}
      { serviceFacchinaggio && (
        <ChangeFacchinaggioModal
          visible={isOpen(EstimationFieldModal.openFacchinaggioModal)}
          service={serviceFacchinaggio}
          onCancel={closeModal}
          onUpdateService={(data) => onSetEditableService(data, FACCHINAGGIO)}
        />
      )}
      { serviceTelefonica && (
        <ChangeTelefonicaModal
          visible={isOpen(EstimationFieldModal.openTelefonica)}
          service={serviceTelefonica}
          onCancel={closeModal}
          onUpdateService={(data) => onSetEditableService(data, TELEFONICA)}
        />
      )}
      <PallexErrorModal
        visiblePallesErrorModal={visiblePallesErrorModal}
        setVisiblePallesErrorModal={setVisiblePallesErrorModal}
        checkUpdatePallexError={checkUpdatePallexError}
        pallexError={shipment.pallex_error}
        shippingIds={{
          orderId: shipment.id,
          trackingId: shipment.tracking_code,
        }}
      />
      <div className={style.modal_background}>
        <Row>
          <Col span={16}>
            <div className={classnames('main-title-30px', style.modal_main_title)}>
              <div>
                {t('forms.deliveries.shipping')}{' '}
                <span className={style.shipping_status_id}>#{shipment.spedizione_id || shipment.id}</span>
              </div>
              {shipment.pallex_error && IS_APROVED_SHIPMENT && (
                <div className={style.pallex_error}>
                  <PallexErrorSvg className={style.pallex_error_image_modal}/>
                  <p className={style.pallex_error_text_container}>
                    { checkUpdatePallexError ? t('pallexError.errorTextUpdate') : t('pallexError.errorText')}
                    <br/>
                    <button
                      onClick={onOpenPallesError}
                      className={style.pallex_error_btn}
                    >
                      { checkUpdatePallexError ? t('pallexError.errorBtnTextUpdate') : t('pallexError.errorBtnText')}
                    </button>
                  </p>
                </div>
              )}
            </div>
            <p className={style.paragraph}>
              {t('forms.deliveries.customer')}
              <span className="extra-bolt-corsivo">
                : {shipment.pallex_client_name || shipment?.user?.name}
              </span>
            </p>
            <p className={style.paragraph}>
              {t('forms.deliveries.approved_date')}
              <span className="extra-bolt-corsivo">
                : {shipment?.approvato_il && format(new Date(shipment?.approvato_il), 'dd-MM-yyyy')}
              </span>
            </p>
            <div className={classnames(style.inline, style.paragraph)}>
              <p>Stato:</p>
              <span className={classnames('extra-bolt-corsivo', style.status_margin)}>
                {renderStatuses()}
              </span>
            </div>
            { !shippingContractorIsPallexORPallexTP
                && <div className={style.status_container}>
                  <Status status="Gestito da TP"/>
                </div>
            }
            <Row className={style.detail_top_container}>
              <Col span={8} className={classnames('semi-bold', style.address_column)}>
                <div className={style.col_container}>
                  <span className={style.paragraph}>{t('forms.estimations.sender')}:</span>
                  {shipment.pickup_address?.gdo_id && (
                    <span className={style.gdo_label}>GDO</span>
                  )}
                </div>
                <div>
                  <div className={style.gap_container}>
                    <span className="extra-bolt-corsivo">
                      {shipment?.pickup_address?.company_name || shipment?.pickup_address?.name_and_surname_reference}
                    </span>
                    { shipment.user_id
                      ? (
                        <>
                          <span>
                            {`${shipment?.pickup_address?.location?.street} ${shipment?.pickup_address?.location?.house_number || ''}`}
                          </span>
                          <span>
                            {shipment?.pickup_address?.location?.post_code},
                            {shipment?.pickup_address?.location?.city},
                          </span>
                          <span>
                            {shipment?.pickup_address?.location?.province}
                          </span>
                        </>
                      ) : (
                        <span>
                          {shipment?.pickup_address?.location?.full_address}
                        </span>
                      )
                    }
                    {IS_APROVED_SHIPMENT && shipment.pallex_error && !shipment.tracking_code
                      && <OpenModalButton
                        modal={EstimationFieldModal.openPickupAddressModal}
                        openModal={openModal}
                        className={classnames(style.on_edit_btn, style.on_edit_address_btn)}
                      />
                    }
                  </div>
                  <div className={classnames(style.date_margin, style.on_edit_container, style.paragraph, 'extra-bolt-corsivo')}>
                    { shipment.start_shipment_date
                      ? <>
                        <CalendarFilled className={style.primary_color}/>
                        <span>{format(new Date(shipment.start_shipment_date), 'dd-MM-yyyy')}</span>
                      </>
                      : ''
                    }
                    { IS_APROVED_SHIPMENT
                      ? <OpenModalButton
                        modal={EstimationFieldModal.openPickupDateModal}
                        openModal={openModal}
                        className={style.on_edit_btn}
                      />
                      : null
                    }
                  </div>
                  <div className={classnames(style.date_margin, style.on_edit_container, style.paragraph, 'extra-bolt-corsivo')}>
                    { shipment?.start_shipment_time
                      && <>
                        <ClockCircleFilled className={style.primary_color}/>
                        {shipment?.start_shipment_time}
                      </>
                    }
                    { IS_APROVED_SHIPMENT
                      && <OpenModalButton
                        modal={EstimationFieldModal.openTimeModal}
                        openModal={openModal}
                        className={style.on_edit_btn}
                      />
                    }
                  </div>
                  {toBeApproved && <Select placeholder={t('forms.estimations.define_medium')}/>}
                </div>
              </Col>
              <Col span={9} className={classnames('semi-bold', style.address_column)}>
                <div className={style.col_container}>
                  <span className={style.paragraph}>{t('forms.estimations.destination')}:</span>
                  {shipment.shipping_address?.gdo_id && (
                    <span className={style.gdo_label}>GDO</span>
                  )}
                </div>
                <div>
                  <div className={style.gap_container}>
                    <span className="extra-bolt-corsivo">
                      {shipment?.shipping_address?.company_name
                        || shipment?.shipping_address?.name_and_surname_reference}
                    </span>
                    {
                      shipment.user_id
                        ? <>
                          <span>
                            {`${shipment?.shipping_address?.location?.street} ${shipment?.shipping_address?.location?.house_number || ''}`}
                          </span>
                          <span>
                            {shipment?.shipping_address?.location?.post_code},
                            {shipment?.shipping_address?.location?.city},
                          </span>
                          <span>
                            {shipment?.shipping_address?.location?.province}
                          </span>
                        </>
                        : <span>
                          {shipment?.shipping_address?.location?.full_address}
                        </span>
                    }
                    { IS_APROVED_SHIPMENT && shipment.pallex_error && !shipment.tracking_code
                        && <OpenModalButton
                          modal={EstimationFieldModal.openShippingAddressModal}
                          openModal={openModal}
                          className={classnames(style.on_edit_btn, style.on_edit_address_btn)}
                        />
                    }
                  </div>
                  <div className={
                    classnames(
                      style.date_margin,
                      style.paragraph,
                      style.address,
                      style.on_edit_container,
                      'extra-bolt-corsivo',
                    )}>
                    {
                      shipment.withdrawal_date
                        ? <>
                          <CalendarFilled className={style.primary_color}/>
                          <span>{format(new Date(shipment.withdrawal_date), 'dd-MM-yyyy')}</span>
                        </>
                        : ''
                    }
                    {
                      IS_APROVED_SHIPMENT
                        ? <OpenModalButton
                          modal={EstimationFieldModal.openShippingDateModal}
                          openModal={openModal}
                          className={style.on_edit_btn}
                        />
                        : null
                    }
                  </div>
                  {toBeApproved && <Select placeholder={t('forms.estimations.define_medium')}/>}
                </div>
              </Col>
              <Col span={6}>
                <p className={classnames('semi-bold', style.address_column)}>
                  <span className={style.driver}>{t('forms.deliveries.truck')}:{' '}</span>
                  <span className="extra-bolt-corsivo"
                  >  {transportById?.plate_number}
                  </span>
                </p>
                <p className={classnames('semi-bold', style.address_column)}>
                  <span className={style.driver}>{t('forms.deliveries.driver')}:{' '}</span>
                  <span className="extra-bolt-corsivo"
                  >  {transportById?.driver?.name}
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <p className={classnames(style.cost_text, style.left_text)}>
              {t('forms.newEstimation.tot_cost')}
              <span className={style.price_text}>
                {
                  shipment?.shipping_estimate?.price
                    ? new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(shipment?.shipping_estimate?.price)
                    : '--€'
                }
                {
                  toBeApproved && <img src={PenIcon} alt="pen" className={style.pen_image}/>
                }
              </span>
            </p>
            {
              shipment.delivery_type && <>
                <p className={style.left_text}>
                  {t('forms.estimations.order_type')}:
                  <span className="extra-bolt-corsivo">
                    {' '} {shipment.delivery_type}
                  </span>
                </p>
              </>
            }
            <p className={style.left_text}>
              {t('forms.shipmentRequest.ddt_number')}:
              <span className="extra-bolt-corsivo">
                {' '} {shipment.ddt_number}
              </span>
              {IS_APROVED_SHIPMENT && (
                <OpenModalButton
                  modal={EstimationFieldModal.openDDTNumberModal}
                  openModal={openModal}
                />
              )}
            </p>
            <p className={classnames(style.price_list_top, style.left_text)}>
                            Listino associato:
              <span className="extra-bolt-corsivo">
                {' '} {selectedPriceList?.name}
              </span>
            </p>
            <p className={style.left_text}>
                            Gestito da:
              <span className="extra-bolt-corsivo">
                {' '} {selectedContractor?.name}
              </span>
            </p>
            {
              shippingContractorIsPallex
                ? <p className={style.left_text}>
                      Numero spedizione
                  <br/>
                      Pallex Italia:
                  <span className="extra-bolt-corsivo">
                    {' '} {shipment.tracking_code}
                  </span>
                </p>
                : <p className={style.left_text}>
                      Numero spedizione:
                  <span className="extra-bolt-corsivo">
                    {' '} {shipment.tracking_code}
                  </span>
                </p>
            }
            { shipment.codice_cliente_spedizione && (
              <p className={style.left_text}>
                {t('forms.shipmentRequest.shipping_client_code')}
                <span className="extra-bolt-corsivo">
                  {' '} {shipment.codice_cliente_spedizione}
                </span>
              </p>
            )}
            <p className={style.left_text}>
              {t('forms.estimations.tracking')}:
              {
                shipment.tracking
                                && <span className="extra-bolt-corsivo">
                                  {' '} {
                                    shipment.tracking === 'Disponsible'
                                      ? t('forms.estimations.disponsible')
                                      : t('forms.estimations.non_disponsible')
                                  }
                                </span>
              }
              {IS_APROVED_SHIPMENT && !pallexTP
                                && <Button
                                  type="link"
                                  icon={<EditFilled/>}
                                  onClick={() => setOpenTrackingModal(true)}
                                />
              }
            </p>
          </Col>
        </Row>
        <div className={style.options_container}>
          <p className={style.options_text}>
            {t('forms.newEstimation.number_pallet')}:
            <span className={style.options_bolt_text}>
              {shipment?.pallets?.reduce((s, pallet) => +s + +pallet.item_quantity, 0)}
            </span>
          </p>
          <p className={style.options_text}>
            {t('forms.newEstimation.weight')}:
            <span className={style.options_bolt_text}>
              {shipment?.pallets?.reduce((s, pallet) => +s + pallet.weight * pallet.item_quantity, 0)} Kg
            </span>
          </p>
          {shipment?.idHub && (
            <p className={style.options_text}>
              {t('forms.newEstimation.hub')}:
              <span className={style.options_bolt_text}>
                {pallexHubs.filter((hub) => hub.idHub === shipment.idHub)[0]?.name || shipment.idHub}
              </span>
              {shipment?.pallex_error && IS_APROVED_SHIPMENT
                && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openHubModal)}/> }
            </p>
          )}
          {/* <p className={style.options_text}> */}
          {/*  {t('forms.deliveries.type')}: */}
          {/*  <span className={style.options_bolt_text}> {shipment.delivery_type}</span> */}
          {/* </p> */}
          {ecoPallets
            && <p className={style.options_text}>
              {t('forms.estimations.ecological_pallet')}:
              <span className={style.options_bolt_text}>
                {shipment?.epal || 0}
              </span>
              {IS_APROVED_SHIPMENT
                && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openEcoPallet)}/>
              }
            </p>
          }
          {fasci
            && <p className={style.options_text}>
              {t('forms.estimations.fasci')}:
              <span className={style.options_bolt_text}>
                {shipment?.fasci || 0}
              </span>
              {IS_APROVED_SHIPMENT
                && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openFasciModal)}/>
              }
            </p>
          }
          {contrassegno
            && <p className={style.options_text}>
              {t('forms.estimations.contrassegno')}:
              <span className={style.options_bolt_text}>
                { (shipment?.contrassegno
                  && `${contrassegnoList?.find((s) => s.id === shipment?.contrassegno?.id)?.description || ''}
                  ${shipment?.contrassegno.id ? '-' : ''}
                  ${parseFloat(`${shipment?.contrassegno?.value}`).toFixed(2)}€`) || '-'}
              </span>
              {IS_APROVED_SHIPMENT
                && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openContrassegnoModal)}/>
              }
            </p>
          }
          {
            serviceFacchinaggio
            && <p className={style.options_text}>
              {t('forms.estimations.facchinaggio')}:
              <span className={style.options_bolt_text}>
                { facchinaggioRitiroDescription || '-'}{' '}/{' '}
                { facchinaggioConsegnaDescription || '-'}
              </span>
              { IS_APROVED_SHIPMENT
                && <SettingOutlined onClick={() => openModal(EstimationFieldModal.openFacchinaggioModal)}/>
              }
            </p>
          }
          {oreNote
            && <p className={style.options_text}>
              {t('forms.estimations.ore_note')}:
              <span className={style.options_bolt_text}>
                {shipment?.ore_note || '-'}
              </span>
              {
                IS_APROVED_SHIPMENT
                    && <SettingOutlined onClick={
                      () => openModal(EstimationFieldModal.openConsegnaConGiornoEOraPrestabilitaModal)
                    }/>
              }
            </p>
          }
          {hasGDO
            && <p className={style.options_text}>
              {gdoOrAmazonLabel}:
              <span className={style.options_bolt_text}>
                {gdoOrAmazonCompanyName}
              </span>
              { IS_APROVED_SHIPMENT
                && <SettingOutlined onClick={
                  () => openModal(EstimationFieldModal.openGdoOrAmazonModal)
                }/>
              }
            </p>
          }
          {serviceTelefonica
            && <p className={style.options_text}>
              {t('forms.shipmentDetails.telephonica')}:
              <span className={style.options_bolt_text}>
                {serviceTelefonica?.values?.ritiro || '-'}{' '}/{' '}
                {serviceTelefonica?.values?.consegna || '-'}
              </span>
              { IS_APROVED_SHIPMENT
                && <SettingOutlined onClick={
                  () => openModal(EstimationFieldModal.openTelefonica)
                }/>
              }
            </p>
          }
        </div>
      </div>
      { IS_APROVED_SHIPMENT_WITHOUT_USER_ID && shipment?.contractor.length > 0 && onRequestUpdateEstimation ? (
        <ContractorTable
          userId={shipment?.user_id}
          contractor={shipment?.contractor}
          subContractor={shipment?.sub_contractor || []}
          deliveryType={shipment?.delivery_type}
          onRequestUpdateEstimation={onRequestUpdateEstimation}
          id={shipment?.id}
        />
      ) : null }
      <AdminPalletsTable
        dataArray={shipment?.pallets || []}
        updateOptionHandler={updateOptionHandler}
        palletEstimate={{
          price_list_id: shipment?.price_list_id,
          pickup_address: shipment?.pickup_address,
          shipping_address: shipment?.shipping_address,
        }}
        deliveryType={shipment?.delivery_type || ''}
        options={shipment?.options}
        editable={IS_APROVED_SHIPMENT}
      />
      { hasMerceAdr && (
        <MerceAdrTable
          dataArray={shipment.merce_adr}
          updateOptionHandler={updateMerceAdrHandler}
          editable={IS_APROVED_SHIPMENT}
        />
      )}
      {
        IS_APROVED_SHIPMENT
          ? <ExtraServicesTableForm
            onUpdateOptions={onRequestUpdateOptions}
            orderOptions={shipment?.options}
            contrassegnoId={!!shipment?.contrassegno_id}
            orderData={shipment}
          />
          : <ExtraApproved
            pallexOrder={!shipment.user_id}
            orderOptions={shipment?.options}
          />
      }
      {IS_APROVED_SHIPMENT && (
        <div className={style.footer_modal}>
          {extraServices && <ChangeExtraServicesModalView
            visible={isOpen(EstimationFieldModal.openExtraServices)}
            onCancel={() => closeModal()}
            orderOptions={shipment.options}
            onAddOptionHandler={onRequestUpdateOptions}
            contrassegnoId={!!shipment?.contrassegno_id}
            deliveryType={shipment.delivery_type}
          />}
          <OpenModalButton
            modal={EstimationFieldModal.openExtraServices}
            openModal={openModal}
            icon={<PlusOutlined/>}
            className={style.addPalletsBtn}
          >
            {t('forms.estimations.add_extra')}
          </OpenModalButton>
        </div>
      )}
      <AdditionalTextContainer
        additionalText={shipment?.informazioni_aggiuntive || ''}
        onChangeText={addAdditionalTextHandeler}
        isEdit={IS_APROVED_SHIPMENT}
      />
      <NoteContainer
        noteRitiro={shipment.note_ritiro || shipment.note}
        noteConsegna={shipment.note_consegna || shipment.note}
        ritiroTime={shipment.start_shipment_time || ''}
        deliveryType={shipment.delivery_type}
        onChangeNote={onNoteHandler}
        isEdit={IS_APROVED_SHIPMENT}
      />
    </div>
  );
};

export default ShippingModal;
