import {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Row, Col, Form, Input, DatePicker, FormInstance,
} from 'antd';
import classnames from 'classnames';
import CustomButton from 'components/CustomButton/CustomButton';
import RadioButton from 'components/RadioButton';
import GooglePlacesComponent from 'components/GooglePlacesComponent';
import { useParceAddress } from 'hooks/useParceAddress';
import { ShipmentStructure } from 'store/types';
import { DeliveryType, AddressFields } from 'enums';
import style from './shipmentDetails.module.css';

type ShipmentDetailsTwoStepFormProps = {
  shipmentRequest: ShipmentStructure
  thirdStep: boolean,
  setThirdStep: (value: boolean) => void,
  form: FormInstance,
}

const ShipmentDetailsTwoStepForm:React.FC<ShipmentDetailsTwoStepFormProps> = ({
  thirdStep, setThirdStep, shipmentRequest, form,
}) => {
  const { t } = useTranslation();
  const nonStopExtra = shipmentRequest?.options.find((el) => el.title?.toUpperCase() === 'SERVIZIO NON-STOP/ESPRESSO');
  const filteredOptions = useMemo(() => (
    shipmentRequest.options?.filter((item) => item.isActive)
  ), [shipmentRequest.options]);

  return (
    <div className={style.two_step_container}>
      <div className={style.two_step_form}>
        <Row>
          <Col span={11}>
            <div className={style.title}>
              {t('forms.shipmentDetails.pickUpAddress')}
            </div>
            <Form.Item
              name="pickup_typology"
            >
              <Row className={style.radio_group}>
                <Col span={6}>
                  <RadioButton
                    checked={true}
                    title={t('forms.shipmentDetails.agency')}
                    value="Company"
                    id="1"
                    name="group_1"
                  />
                </Col>
                <Col span={6} offset={1}>
                  <RadioButton
                    title={t('forms.shipmentDetails.private')}
                    value="Private_person"
                    id="2"
                    name="group_1"
                  />
                </Col>
              </Row>
            </Form.Item>
            <Row>
              <Col span={24}>
                <GooglePlacesComponent
                  addressField={AddressFields.PICKUP_ADDRESS}
                  deliveryType={DeliveryType.RITIRO}
                  orderData={{
                    options: filteredOptions || [],
                    delivery_type: shipmentRequest?.delivery_type || '',
                    city: `${shipmentRequest.pickup_address.location.city}, ${shipmentRequest.pickup_address.location.province}, ${shipmentRequest.pickup_address.location.country}`,
                  }}
                  hideName
                />
              </Col>
            </Row>
            <div className={classnames('semi-bold', style.segregate_div)}>
              {shipmentRequest?.pickup_address?.location?.post_code}
              &nbsp;&nbsp;&nbsp;{shipmentRequest?.pickup_address?.location?.post_code && '-'}&nbsp;&nbsp;&nbsp;
              {shipmentRequest.pickup_address.location.city},{' '}
              {shipmentRequest.pickup_address.location.province},{' '}
              {shipmentRequest.pickup_address.location.country}
            </div>
            <Form.Item
              noStyle={true}
              shouldUpdate={
                (prev, curr) => prev.pickup_typology !== curr.pickup_typology
              }
            >
              {({ getFieldValue }) => getFieldValue('pickup_typology') === 'Company' && <Form.Item
                label={t('forms.shipmentDetails.businessName')}
                name="pickup_company_name"
                rules={
                  [
                    { required: true, message: t('validation.companyName') },
                    { min: 3, message: t('forms.newShipment.name_min_length') },
                  ]
                }
              >
                <Input />
              </Form.Item>}
            </Form.Item>
            <Form.Item
              label={t('forms.shipmentDetails.referenceName')}
              name="pickup_name_and_surname_reference"
              rules={
                [
                  { required: true, message: t('validation.nameAndSurname') },
                ]
              }
            >
              <Input />
            </Form.Item>
            {(shipmentRequest?.delivery_type === DeliveryType.RITIRO
              || shipmentRequest?.delivery_type === DeliveryType.TRIANG)
              && nonStopExtra?.isActive && (
              <Form.Item
                label={t('forms.shipmentRequest.non_stop_date_ritiro')}
                name="non_stop_date_ritiro"
                rules={
                  [
                    ({ getFieldValue }) => ({
                      validator(rules, value) {
                        if (getFieldValue('non_stop_date_consegna') && value && moment(getFieldValue('non_stop_date_consegna')) < value) {
                          return Promise.reject(new Error(t('validation.non_stop_date_ritiro')));
                        }
                        if (!value) {
                          return Promise.resolve();
                        }
                        return Promise.resolve();
                      },
                    })]}
              >
                <DatePicker
                  disabledDate={(current) => (
                    current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                  )}
                  format="DD/MM/YYYY"
                  className={style.non_stop_date_input}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={11} offset={2}>
            <div className={style.title}>
              {t('forms.shipmentDetails.destinationAddress')}
            </div>
            <Form.Item
              name="shipping_typology"
            >
              <Row className={style.radio_group}>
                <Col span={6}>
                  <RadioButton
                    checked={true}
                    title={t('forms.shipmentDetails.agency')}
                    value="Company"
                    id="3"
                    name="group_2"
                  />
                </Col>
                <Col span={6} offset={1}>
                  <RadioButton
                    title={t('forms.shipmentDetails.private')}
                    value="Private_person"
                    id="4"
                    name="group_2"
                  />
                </Col>
              </Row>
            </Form.Item>
            <Row>
              <Col span={24}>
                <GooglePlacesComponent
                  addressField={AddressFields.SHIPPING_ADDRESS}
                  deliveryType={DeliveryType.CONSEGNA}
                  orderData={{
                    options: filteredOptions || [],
                    delivery_type: shipmentRequest?.delivery_type || '',
                    city: `${shipmentRequest.shipping_address.location.city}, ${shipmentRequest.shipping_address.location.province}, ${shipmentRequest.shipping_address.location.country}`,
                  }}
                  hideName
                />
              </Col>
            </Row>
            <div className={classnames('semi-bold', style.segregate_div)}>{shipmentRequest?.shipping_address?.location?.post_code}
              &nbsp;&nbsp;&nbsp;{shipmentRequest?.shipping_address?.location?.post_code && '-'} &nbsp;&nbsp;&nbsp;
              {shipmentRequest.shipping_address.location.city},{' '}
              {shipmentRequest.shipping_address.location.province},{' '}
              {shipmentRequest.shipping_address.location.country}
            </div>
            <Form.Item
              noStyle={true}
              shouldUpdate={
                (prev, curr) => prev.shipping_typology !== curr.shipping_typology
              }
            >
              {({ getFieldValue }) => getFieldValue('shipping_typology') === 'Company' && <Form.Item
                label={t('forms.shipmentDetails.businessName')}
                name="shipping_company_name"
                rules={
                  [
                    { required: true, message: t('validation.companyName') },
                    { min: 3, message: t('forms.newShipment.name_min_length') },
                  ]
                }
              >
                <Input />
              </Form.Item>}
            </Form.Item>
            <Form.Item
              label={t('forms.shipmentDetails.referenceName')}
              name="shipping_name_and_surname_reference"
              rules={
                [
                  { required: true, message: t('validation.nameAndSurname') },
                ]
              }
            >
              <Input />
            </Form.Item>
            {(shipmentRequest?.delivery_type === DeliveryType.CONSEGNA
              || shipmentRequest?.delivery_type === DeliveryType.TRIANG)
              && nonStopExtra?.isActive && (
              <Form.Item
                label={t('forms.shipmentRequest.non_stop_date_consegna')}
                name="non_stop_date_consegna"
                rules={
                  [
                    ({ getFieldValue }) => ({
                      validator(rules, value) {
                        if (getFieldValue('non_stop_date_ritiro') && value && moment(getFieldValue('non_stop_date_ritiro')) > value) {
                          return Promise.reject(new Error(t('validation.non_stop_date_consegna')));
                        }
                        if (!value) {
                          return Promise.resolve();
                        }
                        return Promise.resolve();
                      },
                    })]}
              >
                <DatePicker
                  disabledDate={(current) => (
                    current && current.valueOf() < new Date().setDate(new Date().getDate() - 1)
                  )}
                  format="DD/MM/YYYY"
                  className={style.non_stop_date_input}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item
              label={<div className={style.note_style}>
                {t('forms.shipmentRequest.note_ritiro')}
              </div>}
              name="note_ritiro"
            >
              <Input.TextArea placeholder={t('placeholder.note')} />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label={<div className={style.note_style}>
                {t('forms.shipmentRequest.note_consegna')}
              </div>}
              name="note_consegna"
            >
              <Input.TextArea placeholder={t('placeholder.note')} />
            </Form.Item>
          </Col>
        </Row>
        {
          thirdStep
            ? null
            : <>
              <CustomButton
                htmlType="submit"
                type="primary"
                className={classnames('extra-bolt-corsivo', style.send)}
                onClick={() => setThirdStep(true)}
              >
                {t('forms.shipmentDetails.twoStepButtonTitle')}
              </CustomButton>
              <div className={style.text_two_step}>
          * {t('forms.shipmentDetails.IVA_text')}
              </div>
            </>
        }
      </div>
    </div>
  );
};

export default ShipmentDetailsTwoStepForm;
