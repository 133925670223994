import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceListTemplate } from '../types';

export const requestGetPriceListTemplates = createAction('requestGetPriceListTemplates');

type State = {
  priceListTemplates: PriceListTemplate[],
  loading: boolean,
}

const initialState: State = {
  priceListTemplates: [],
  loading: false,
};

const priceListTemplatesSlice = createSlice({
  name: 'priceListTemplates',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPriceListTemplateSliceData: (state, action: PayloadAction<PriceListTemplate[]>) => {
      state.priceListTemplates = action.payload;
    },
  },
});

export const { setPriceListTemplateSliceData, setLoading } = priceListTemplatesSlice.actions;

export default priceListTemplatesSlice.reducer;
