import React from 'react';
import { EditFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { EstimationFieldModal } from './hooks';

export const OpenModalButton: React.FC<{
    modal:EstimationFieldModal;
    openModal: (modal:EstimationFieldModal) => void;
    icon?: React.ReactNode;
    className?: string;
}> = ({
  modal,
  openModal,
  icon = <EditFilled />,
  className,
  children,
}) => <Button
  className={className}
  type="link"
  icon={icon}
  onClick={() => openModal(modal)}
>{children}</Button>;
