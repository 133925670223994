import { useMemo } from 'react';
import EditableTable from '../index';
import { AddebitosType } from 'store/types';
import { EditableDetailsTableTypes } from 'enums';

type AddebitosTableProps = {
  dataArray: AddebitosType[],
  updateOptionHandler: (data) => void,
  editable: boolean,
}

const AddebitosTable = ({
  dataArray, updateOptionHandler, editable,
}: AddebitosTableProps) => {
  const newTableData = useMemo(() => (
    dataArray.map((sourceItem, i) => ({
      key: `addebitos_${i}`,
      quantity: sourceItem.quantity,
      description: sourceItem.description,
      price: sourceItem.price,
    }))
  ), [dataArray]);

  const parceSavedData = (row, key: string) => {
    const price = row.priceValue.toString().replace(',', '.') + row.priceUnit;
    let newDataArr:AddebitosType[] = [];
    const rowIsAvailable = dataArray.find((item, i) => `${EditableDetailsTableTypes.addebitos}_${i}` === key);
    if (rowIsAvailable) {
      newDataArr = dataArray.map((item, i) => (
        (`${EditableDetailsTableTypes.addebitos}_${i}` === key) ? {
          quantity: Math.trunc(row.quantity),
          description: row.description,
          price,
        } : item));
    } else {
      newDataArr = [...dataArray, {
        quantity: Math.trunc(row.quantity),
        description: row.description,
        price,
      }];
    }
    updateOptionHandler({ [EditableDetailsTableTypes.addebitos]: newDataArr });
    return newDataArr;
  };

  const deleteHandler = (key:string) => {
    const data = {
      [EditableDetailsTableTypes.addebitos]: dataArray.filter((item, i) => `${EditableDetailsTableTypes.addebitos}_${i}` !== key),
    };
    updateOptionHandler(data);
  };

  return (
    <EditableTable
      tableType={EditableDetailsTableTypes.addebitos}
      dataSource={newTableData}
      parceSavedData={parceSavedData}
      deleteHandler={deleteHandler}
      initialFields={{
        key: `${EditableDetailsTableTypes.addebitos}_${newTableData.length}`,
        quantity: '',
        description: '',
        price: '',
      }}
      editable={editable}
    />
  );
};

export default AddebitosTable;
