import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ShipmentStructure, ShippingRequestListPayload, ShipmentlistStructure,
  PatchEstimation, ApproveShipmentPayload, ShippingRequestRefusePayload,
} from '../types';
import { OrderType } from 'enums';

export const getPrivateAreaShippingRequestListAction = createAction<ShippingRequestListPayload>('getPrivateAreaShippingRequestListAction');
export const deleteShippingRequestAction = createAction<string>('deleteShipmentRequest');
export const getPrivatAreaShippingDetailsAction = createAction<string>('getPrivatAreaShippingDetailsAction');
export const postCreateShippingAction = createAction<Partial<ShipmentStructure>>('postCreateShippingAction');
export const requestUpdateShippingAction = createAction<PatchEstimation>('UpdateShippingAction');
export const requestPrintLabelAction = createAction<string>('requestPrintLabelAction');
export const requestApprovePrivateAreaShipmentRequestAction = createAction<ApproveShipmentPayload>('requestApprovePrivateAreaShipmentRequestAction');
export const requestRefusedPrivateAreaShippingRequest = createAction<ShippingRequestRefusePayload>('requestRefusedPrivateAreaShippingRequest');

type State = {
  listLoading: boolean,
  shippingInfoLoading: boolean,
  creationLoading: boolean,
  shippingRequestList: ShipmentlistStructure | null,
  privateAreaShippingInfo: ShipmentStructure | null,
  shippingPayloadData: Partial<ShipmentStructure> | null,
  redirectFromDetails: boolean,
  orderType: OrderType | null,
}
const initialState: State = {
  listLoading: false,
  shippingInfoLoading: false,
  creationLoading: false,
  shippingRequestList: null,
  privateAreaShippingInfo: null,
  shippingPayloadData: null,
  redirectFromDetails: false,
  orderType: null,
};

const privateAreaSlice = createSlice({
  name: 'privateArea',
  initialState,
  reducers: {
    setListLoading: (state, action: PayloadAction<boolean>) => {
      state.listLoading = action.payload;
    },
    setShippingInfoLoading: (state, action: PayloadAction<boolean>) => {
      state.shippingInfoLoading = action.payload;
    },
    setCreationLoading: (state, action: PayloadAction<boolean>) => {
      state.creationLoading = action.payload;
    },
    setShipmentListData: (state, action: PayloadAction<ShipmentlistStructure>) => {
      state.shippingRequestList = action.payload;
    },
    setPrivateAreaShippingInfo: (state, action: PayloadAction<ShipmentStructure>) => {
      state.privateAreaShippingInfo = action.payload;
    },
    clearPrivateAreaShippingInfo: (state) => {
      state.privateAreaShippingInfo = null;
    },
    setShippingPayloadData: (state, action: PayloadAction<Partial<ShipmentStructure>>) => {
      state.shippingPayloadData = action.payload;
    },
    clearShippingPayloadData: (state) => {
      state.shippingPayloadData = null;
    },
    setRedirectFromDetails: (state, action: PayloadAction<boolean>) => {
      state.redirectFromDetails = action.payload;
    },
    setOrderType: (state, action: PayloadAction<OrderType | null>) => {
      state.orderType = action.payload;
    },
  },
});

export const {
  setListLoading, setShippingInfoLoading, setShipmentListData, setPrivateAreaShippingInfo,
  clearPrivateAreaShippingInfo, setCreationLoading, setShippingPayloadData,
  clearShippingPayloadData, setRedirectFromDetails, setOrderType,
} = privateAreaSlice.actions;

export default privateAreaSlice.reducer;
